// https://github.com/zeit/next.js/issues/1852#issuecomment-370603560

import Raven from 'raven-js';
import ENV from '../../constants/ENV';

// https://gist.github.com/impressiver/5092952
const clientIgnores = {
  ignoreErrors: [
    'top.GLOBALS',
    'originalCreateNotification',
    'canvas.contentDocument',
    'MyApp_RemoveAllHighlights',
    'http://tt.epicplay.com',
    "Can't find variable: ZiteReader",
    'jigsaw is not defined',
    'ComboSearch is not defined',
    'http://loading.retry.widdit.com/',
    'atomicFindClose',
    'fb_xd_fragment',
    'bmi_SafeAddOnload',
    'EBCallBackMessageReceived',
    'conduitPage',
    'Script error.',
  ],
  ignoreUrls: [
    /graph\.facebook\.com/i,
    /connect\.facebook\.net\/en_US\/all\.js/i,
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    /extensions\//i,
    /^chrome:\/\//i,
    /127\.0\.0\.1:4001\/isrunning/i,
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  ],
  // Facebook flakiness
};
// Facebook blocked
const options = {
  // Woopra flakiness
  autoBreadcrumbs: true,
  captureUnhandledRejections: true,
  // Chrome extensions
};
const PUBLIC_DSN = ENV.SENTRY_PUBLIC_DSN_YADO_WEB;
// Other plugins
// eslint-disable-next-line import/no-mutable-exports
let IsomorphicRaven: Raven.RavenStatic; // Cacaoweb

if (typeof window !== 'undefined') {
  IsomorphicRaven = Raven;

  if (PUBLIC_DSN) {
    IsomorphicRaven.config(PUBLIC_DSN, {
      ...clientIgnores,
      ...options,
    }).install();
  }
} else {
  // eslint-disable-next-line no-eval
  IsomorphicRaven = eval("require('raven')") as Raven.RavenStatic;

  if (PUBLIC_DSN) {
    IsomorphicRaven.config(PUBLIC_DSN, options).install();
  }
}
// https://arunoda.me/blog/ssr-and-server-only-modules

export default IsomorphicRaven;
