import SentryReporter from '../../models/trackings/SentryReporter';

export default {
  get(obj: { [key: string]: () => string }, prop: string) {
    if (prop in obj) {
      return obj[prop];
    }
    // TODO: $$typeofがどこから来た？
    // Error: i18n translation key [$$typeof] is not defined
    if (prop === '$$typeof') {
      return '';
    }
    SentryReporter.captureException(`Error: i18n translation key [${prop}] is not defined`);
    return () => '';
  },
};
