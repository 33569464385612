import qs from 'qs';

const DUMMY_FQDN = 'https://dummy.wamazing.com/';

class RelativeUrl {
  path: string;

  search: string;

  searchParams: any;

  hash: string;

  hashParams: any;

  constructor(absoluteOrRelativeUrl: string) {
    const urlStr = absoluteOrRelativeUrl;
    const url = new URL(urlStr.replace('childages', 'childAges'), DUMMY_FQDN);
    this.path = url.pathname;
    this.search = url.search;
    this.hash = url.hash;
    this.searchParams = qs.parse(url.search, { ignoreQueryPrefix: true });
    this.hashParams = qs.parse(url.hash.replace(/^#/, ''));
  }

  get relativeUrl(): string {
    return `${this.path}${this.search}${this.hash}`;
  }
}

export default RelativeUrl;
