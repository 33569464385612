import * as React from 'react';
import Head from 'next/head';

const CornerRibbon = ({ label }: { label: string }) => {
  const [shown, setShown] = React.useState(true);
  return (
    <React.Fragment>
      <Head>
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/github-fork-ribbon-css/0.2.2/gh-fork-ribbon.min.css"
        />
      </Head>
      {shown && (
        <a
          onClick={e => {
            e.preventDefault();
            setShown(false);
          }}
          className="github-fork-ribbon"
          style={{
            position: 'fixed',
            zIndex: 1,
          }}
          role="button"
          data-ribbon={label}
          title={label}
        >
          {label}
        </a>
      )}
    </React.Fragment>
  );
};

export default CornerRibbon;
