import SentryReporter from '../models/trackings/SentryReporter';
import {
  Suggestion,
  SuggestionsObj,
  Area,
  Region,
  Prefecture,
  Feature,
  Yado,
  AreaSuggestion,
  RegionSuggestion,
  PrefectureSuggestion,
  FeatureSuggestion,
  YadoSuggestion,
} from '../types/SuggestionTypes';

export function mergeSuggestions(suggestionsObj: SuggestionsObj): Suggestion[] {
  return [
    ...suggestionsObj.areas.map(area => areaToSuggestion(area)),
    ...suggestionsObj.regions.map(region => regionToSuggestion(region)),
    ...suggestionsObj.prefectures.map(prefecture => prefectureToSuggestion(prefecture)),
    ...suggestionsObj.yados.map(yado => yadoToSuggestion(yado)),
    ...suggestionsObj.features.map(feature => featureToSuggestion(feature)),
  ];
}

export function areaToSuggestion(area: Area): AreaSuggestion {
  return {
    suggestionType: 'area',
    slug: area.areaSlug,
    label: area.areaLabel,
    prefectureLabel: area.prefectureLabel,
    landmarks: area.landmarks,
  };
}

export function regionToSuggestion(region: Region): RegionSuggestion {
  return {
    suggestionType: 'region',
    slug: region.regionSlug,
    label: region.regionLabel,
    landmarks: region.landmarks,
  };
}

export function prefectureToSuggestion(prefecture: Prefecture): PrefectureSuggestion {
  return {
    suggestionType: 'prefecture',
    slug: prefecture.prefectureId,
    label: prefecture.label,
  };
}

export function featureToSuggestion(feature: Feature): FeatureSuggestion {
  return {
    suggestionType: 'feature',
    slug: feature.featureId,
    label: feature.label,
  };
}

export function yadoToSuggestion(yado: Yado): YadoSuggestion {
  return {
    suggestionType: 'yado',
    yadoId: yado.yadoId,
    label: yado.yadoName,
    areas: yado.areas,
  };
}

const popularAreaSlugs = ['nikko-kinugawa', 'arima', 'hakone-yugawara', 'kinosaki', 'yufuin'];

export function filterPopularAreas(allSuggestions: Suggestion[]): Suggestion[] {
  const popularAreas = popularAreaSlugs
    .map(slug =>
      allSuggestions.find(suggestion => {
        if (isAreaSuggestion(suggestion)) {
          return suggestion.slug === slug;
        }
        return false;
      }),
    )
    .filter((suggestion, index) => {
      if (!suggestion) {
        SentryReporter.captureException(
          `Cannot find popular area suggestion of ${popularAreaSlugs[index]}`,
        );
      }

      return !!suggestion;
    }) as Suggestion[];
  return popularAreas;
}

const regions = ['hokkaido', 'tohoku', 'kanto', 'chubu', 'kansai', 'chugoku', 'shikoku', 'kyushu'];

export function filterRegions(allSuggestions: Suggestion[]): Suggestion[] {
  const regionList = allSuggestions.filter((suggestion, index) => {
    if (isRegionSuggestion(suggestion) && regions.includes(suggestion.slug)) {
      return true;
    } else {
      if (!suggestion) {
        SentryReporter.captureException(`Cannot find region suggestion of ${regions[index]}`);
      }
      return false;
    }
  });
  const sortedRegionList = regionList.sort(
    (a, b) =>
      regions.indexOf((a as RegionSuggestion).slug) - regions.indexOf((b as RegionSuggestion).slug),
  );

  return sortedRegionList;
}

export function isAreaSuggestion(suggestion: Suggestion): suggestion is AreaSuggestion {
  return suggestion.suggestionType === 'area';
}

export function isRegionSuggestion(suggestion: Suggestion): suggestion is RegionSuggestion {
  return suggestion.suggestionType === 'region';
}

export function isPrefectureSuggestion(suggestion: Suggestion): suggestion is PrefectureSuggestion {
  return suggestion.suggestionType === 'prefecture';
}

export function isFeatureSuggestion(suggestion: Suggestion): suggestion is FeatureSuggestion {
  return suggestion.suggestionType === 'feature';
}

export function isYadoSuggestion(suggestion: Suggestion): suggestion is YadoSuggestion {
  return suggestion.suggestionType === 'yado';
}
