import { ServerResponse } from 'http';

export function isServer() {
  return typeof window === 'undefined';
}

export function isBrowser() {
  return typeof window !== 'undefined';
}

export function render404(res: ServerResponse) {
  res.writeHead(404);
  res.end('Not Found.');
}
