import { action, computed, observable, reaction } from 'mobx';

import ModelErrorIntarface from '../ModelErrorInterface';
import { BookingRoom } from '../../data_sources/networks/wamazing/YadoBookingApi';
import { RoomParams } from '../../data_sources/networks/wamazing/YadoApi';
import R from '../../../R';

export default class YadoRoom extends ModelErrorIntarface {
  @observable
  males: number;

  @observable
  females: number;

  @observable
  children: number = 0;

  @observable.shallow
  childAges: number[] = [];

  constructor(props: BookingRoom = {}) {
    super();
    reaction(
      () => this.children,
      () => this.changeChildAgesArray(),
    );
    const {
      males,
      females,
      children,

      child_ages,
    } = props;

    this.males = males || 0;
    this.females = females || 0;
    this.children = children || 0;
    this.childAges = child_ages || [];
  }

  @action
  changeChildAgesArray() {
    if (
      this.childAges.length > this.children
      // zero fill
    ) {
      this.childAges.splice(this.children);
    } else if (this.childAges.length < this.children) {
      while (this.childAges.length < this.children) {
        this.childAges.push(0);
      }
    }
  }

  @computed
  get isEmpty(): boolean {
    return this.males === 0 && this.females === 0 && this.children === 0;
  }

  get childrenCountPerAges(): Map<number, number> {
    const map = new Map();
    this.childAges.forEach(age => {
      const current = map.get(age) || 0;
      map.set(age, current + 1);
    });
    return map;
  }

  @action
  isValid(): boolean {
    this.errors.clear();

    if (this.males === 0 && this.females === 0) {
      this.errors.set('males', R.i18n.validationYadoBookingRoomEmpty());
    }

    if (this.children > 0 && !this.childAges.every(age => age > 0)) {
      this.errors.set('children', R.i18n.validationYadoBookingRoomEmptyChildAge());
    }

    return !this.hasError;
  }

  toParams(): RoomParams {
    return {
      males: this.males,
      females: this.females,
      ...(this.children > 0
        ? {
            children: this.children,
            child_ages: Array.from(this.childAges),
          }
        : null),
    };
  }
}
