import { EnvType } from './EnvType';

const ENV: EnvType = {
  // PRODUCTION ENV
  ENVIRONMENT: 'production',
  WAMAZING_API_ENDPOINT: 'https://api.wamazing.jp',
  WAMAZING_API_JEST_ENDPOINT: 'https://api.wamazing.jp',
  WAMAZING_SIM_API_ENDPOINT: 'http://example.com',
  WAMAZING_WWW_ENDPOINT: 'https://www.wamazing.jp',
  WAMAZING_COM_ENDPOINT: 'https://%s.wamazing.com',
  WAMAZING_SNOW_API_ENDPOINT: 'https://example.com',
  WAMAZING_YADO_API_ENDPOINT: 'https://yado-backend-for-onsen-web.wamazing.com',
  WAMAZING_YADO_REPLACE_API_ENDPOINT: 'https://yado-backend-replace.wamazing.com',
  WAMAZING_YADO_CN_API_ENDPOINT: 'https://yado-backend.p.wamazing-cn.com',
  WAMAZING_YADO_REPLACE_CN_API_ENDPOINT: 'https://yado-replace-backend.p.wamazing-cn.com',
  WAMAZING_MEDIA_ENDPOINT: 'https://%s.wamazing.com/media',
  WAMAZING_CAMPAIGN_ENDPOINT: 'https://campaign.wamazing.com',
  WAMAZING_ASSETS_ENDPOINT_CN: 'https://asset.yado-web-production.qiniu.wamazing-cn.com',
  WAMAZING_AFTEE_API_URL: 'https://auth.aftee.tw',
  WAMAZING_FACEBOOK_URL: {
    tw: 'https://www.facebook.com/wamazing.tw',
    hk: 'https://www.facebook.com/WAmazingHongkong',
    cn: '',
    www: 'https://www.facebook.com/wamazing.tw',
    jp: 'https://www.facebook.com/wamazing.tw',
  },
  YADO_LINE_OFFICIAL_ACCOUNR_URL: 'https://lin.ee/dcFEo1u',
  YADO_CN_URL: 'https://p.wamazing-cn.com',
  X_API_KEY:
    '8fcf42201b2394860393ecf7c1d6d964baa73f6ae2396477e5c904e3f2ec44292a0aeb6bd10b40aa818b2b891216c32344a488a9cdfebcf2649a419cf8cc7e76',
  GA_TRACKING_IDS: {
    tw: 'G-BGY15RE6QH',
    hk: 'G-6B3YXV782G',
    cn: 'NONE',
    www: 'G-VFYFQT7757',
    jp: 'NONE',
  },
  GTM_IDS: {
    tw: 'GTM-TGLN2BW',
    hk: 'GTM-ML5Z8D4',
    cn: 'NONE',
    www: 'GTM-5NPNZ7T',
    jp: 'NONE',
  },
  ADWORDS_IDS: {
    tw: '816771881',
    hk: '815128200',
    www: '759776276',
    cn: 'NONE',
    jp: 'NONE',
    ONSEN: {
      tw: '691636650',
      hk: '691636650',
      www: 'NONE',
      cn: 'NONE',
      jp: 'NONE',
    },
  },
  FB_PIXEL_IDS: {
    tw: '1607112362691130',
    hk: '2028291784092343',
    www: '2512475028827398',
    cn: 'NONE',
    jp: 'NONE',
    ONSEN: {
      tw: '498271857461216',
      hk: '498271857461216',
      www: 'NONE',
      cn: 'NONE',
      jp: 'NONE',
    },
  },
  BAIDU_TONGJI_ID: '293e738dac90a2a200952dfecb5d51b2',
  HOTJAR_ID: '1367433',
  HOTJAR_SV: '6',
  ALPHA_VANTAGE_API_KEY: '7LNETD4B5SFRKU22',
  SENTRY_PUBLIC_DSN_YADO_WEB: 'https://61b961629df24332afa73a447c6ac22f@sentry.io/295804',
  ZENDESK_URL: 'https://wamazing.zendesk.com',
  ZENDESK_EMAIL: 'info@wamazing.jp',
  ZENDESK_API_TOKEN: 'eEcHz4lAs6X3ovjy1YiPZAd7i9JL3aQSf7v6qMIQ', // ステージングがないため本番と同じ
  // CI_BUILD_NUMBERの値はCI上ではCIRCLE_BUILD_NUMで置換される
  CI_BUILD_NUMBER: '1',
  GOOGLE_CLIENT_ID: '807334028004-1ee0jve45kl70ufkf50m63h3omiakc2t.apps.googleusercontent.com',
  GOOGLE_MAPS_API_KEY: 'AIzaSyBhg79od3gIK2XOUMMUMwH4MyNPWJXzf5s',
  COUPON_IDS: {
    MISASA_KINOSAKI_KYOTO_201911: 314,
  },
  SIFT_BEACON_KEY: '',
};

export default ENV;
