import { pink, blue } from '@material-ui/core/colors';
import { createTheme } from '@material-ui/core/styles';

export const muiTheme = createTheme({
  palette: {
    primary: pink,
    secondary: blue,
    background: {
      default: '#fff',
    },
  },
});

export function removeMuiServerSideInjectedStyles() {
  if (!document) return;
  // Remove the server-side injected CSS.
  const jssStyles = document.querySelector('#jss-server-side');

  if (jssStyles && jssStyles.parentElement) {
    jssStyles.parentElement.removeChild(jssStyles);
  }
}
