import * as React from 'react';
import App, { AppContext } from 'next/app';
import { ThemeProvider } from '@material-ui/core/styles';
// import CssBaseline from '@material-ui/core/CssBaseline';
import NProgress from 'nprogress';
import Router from 'next/router';

import 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';

import 'leaflet/dist/leaflet.css';

import '../page_styles/tailwind.css';

import '../page_styles/global.scss';
import '../page_styles/breakpoints.scss';
import 'react-dates/lib/css/_datepicker.css';
import 'rc-pagination/assets/index.css';

import '../page_styles/onsen_top.scss';
import '../page_styles/onsen_yado_bookings.scss';
import '../page_styles/onsen_yado_order.scss';
import '../page_styles/onsen_yado_show.scss';
import '../page_styles/onsen_yado_new_top/index.scss';
import '../page_styles/onsen_area_list.scss';
import '../page_styles/insurance_price_table.scss';
import '../page_styles/onsen_yado_questionnaire.scss';
import '../page_styles/activity_carousel.scss';
import '../page_styles/onsen_yado_campaign.scss';
import '../page_styles/country_top.scss';
import '../page_styles/component/index.scss';

import 'react-multi-carousel/lib/styles.css';
import 'react-image-gallery/styles/scss/image-gallery.scss';
import '../wamazing-UI-components-react/src/assets/icomoon/style.css';
import {
  removeMuiServerSideInjectedStyles,
  muiTheme,
} from '../src/shared/utils/material_ui_helper';
import CornerRibbon from '../src/components/common/CornerRibbon';
import ENV from '../src/constants/ENV';
import SentryReporter from '../src/models/trackings/SentryReporter';
import { getSubDomainFromHost } from '../src/shared/utils/url_helper';
import { getWamazingSubdomain } from '../src/config/locales/i18n';
import { closeZendesk } from '../src/shared/utils/zendesk';

Router.events.on('routeChangeStart', url => {
  console.log(`Loading: ${url}`);
  NProgress.start();
});
Router.events.on('routeChangeComplete', () => {
  NProgress.done();
});
Router.events.on('routeChangeError', () => NProgress.done());

export default class MyApp extends App {
  // Automatic Static Optimizationを無効化するためにgetInitialPropsを定義
  // 無効化する理由は<Html>のlang属性をSSRでもセットするために_documentのgetInitialPropsでreqが必要なため
  // (Automatic Static Optimizationが有効だと_documentのgetInitialPropsでreqがundefinedになる)
  // ref: https://github.com/zeit/next.js#custom-app
  static async getInitialProps(appContext: AppContext) {
    // calls page's `getInitialProps` and fills `appProps.pageProps`
    const appProps = await App.getInitialProps(appContext);

    return { ...appProps };
  }

  componentDidMount() {
    removeMuiServerSideInjectedStyles();
    closeZendesk();
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    if (super.componentDidCatch) {
      super.componentDidCatch(error, errorInfo);
    }
    SentryReporter.captureException(
      `App catch error ${error}, ${error.message}, ${errorInfo}, ${errorInfo.componentStack}`,
    );
  }

  render() {
    const { Component, pageProps } = this.props;
    const host = pageProps.href?.split('/')[2];
    const subdomain = getSubDomainFromHost(host) || getWamazingSubdomain();
    return (
      <ThemeProvider theme={muiTheme}>
        {ENV.ENVIRONMENT === 'staging' && <CornerRibbon label="staging" />}
        {(subdomain === 'tw' || subdomain === 'hk') && (
          <script
            dangerouslySetInnerHTML={{
              __html: `
              (function () {
                var VARemoteLoadOptions = {
                whiteLabel: { id: 8, siteId: 2614, domain: 't.adotone.com' },
                locale: "en-US", mkt: true
              };
              (function (c, o, n, v, e, r, l, y) {
                c['VARemoteLoadOptions'] = e; r = o.createElement(n), l = o.getElementsByTagName(n)[0];
                r.async = 1; r.src = v; l.parentNode.insertBefore(r, l);
                })(window, document, 'script', 'https://cdn.adotone.com/javascripts/va.js', VARemoteLoadOptions);
              })();`,
            }}
          />
        )}
        {(subdomain === 'tw' || subdomain === 'hk' || subdomain === 'www') && (
          <script
            dangerouslySetInnerHTML={{
              __html: `   
          (function(){ var uqid = "c5e3c193y273353e"; var gid  = "3"; var a=document.createElement("script");
          a.dataset.uqid=uqid;a.dataset.gid=gid;a.id="afadfpc-c5e3c193y273353egid3-"+Date.now();
          a.src="//af-wamazing.catsys.jp/fpc/cookie_js.php?scriptId="+encodeURIComponent(a.id);
          document.head.appendChild(a); })();`,
            }}
          />
        )}
        <Component {...pageProps} />
      </ThemeProvider>
    );
  }
}
