import cloneDeep from 'lodash.clonedeep';

type Constructor<T> = new (...args: any[]) => T;

/**
 * classにcopyメソッドやdeepCopyメソッドを作成するのに利用できる。
 * 適用可能なclassはconstructorがParameter propertiesを用いた初期化のみになっているもの（初期化時にthis.z = x + yのような計算をやっていないもの）
 * https://www.typescriptlang.org/docs/handbook/classes.html#parameter-properties
 * @param instance コピーしたいインスタンス（copyメソッド作成時はthis）
 * @param params constructorのパラメータの一部をオブジェクト形式で与える
 *               paramsがundefinedであれば、変更なしでインスタンスがcopyされる
 * @param deep deep copyしたい場合はtrue
 */
export function copyInstance<T extends Constructor<T>>(
  instance: InstanceType<T>,
  params: Partial<T> = {},
  deep: boolean = false,
): InstanceType<T> {
  const props = deep ? cloneDeep(instance) : instance;
  const newParams = deep ? cloneDeep(params) : params;
  return Object.assign(Object.create(instance), props, newParams);
}
