import { action, computed, observable, toJS } from 'mobx';
import { addDays, differenceInDays } from 'date-fns';

import { BookingRoom } from '../../../data_sources/networks/wamazing/YadoBookingApi';
import { CommonYadoParams } from '../../../data_sources/networks/wamazing/YadoApi';
import { formatDateParam, now } from '../../../shared/utils/date';
import YadoRoomStore, { YadoRoomProps } from './YadoRoomStore';

export type YadoSearchMode = 'area' | 'keyword' | 'prefecture' | 'city' | 'spot' | 'feature';

type StoreProps = {
  area: string;
  spot: string;
  prefecture: string;
  city: string;
  feature: string;
  searchMode: YadoSearchMode;
  keyword: string;
  areaName: string;
  checkinDate: Date;
  checkoutDate: Date;
  rooms: any[];
};

const STATION_REGEXP = /(站|Station)/;

export type YadoSearchPrimaryQuery = {
  searchMode: YadoSearchMode;
  area?: string;
  areaName?: string | Null;
  prefecture?: string;
  prefectureName?: string | Null;
  city?: string;
  cityName?: string | Null;
  spot?: string;
  spotName?: string | Null;
  keyword?: string;
};

export default class YadoSearchState {
  @observable
  searchMode: YadoSearchMode = 'area';

  @observable
  keyword: string = '';

  @observable
  area: string = '';

  @observable
  areaName: string = '';

  @observable
  prefecture: string = '';

  @observable
  prefectureName: string = '';

  @observable
  city: string = '';

  @observable
  cityName: string = '';

  @observable
  spot: string = '';

  @observable
  spotName: string = '';

  @observable
  checkinDate: Date;

  @observable
  checkoutDate: Date;

  @observable
  feature: string = '';

  @observable.ref
  rooms: YadoRoomStore[];

  constructor() {
    this.checkinDate = addDays(now(), 30);
    this.checkoutDate = addDays(now(), 31);

    this.rooms = observable.array([YadoRoomStore.default]);
  }

  @action
  replaceRooms(roomsProps: YadoRoomProps[]) {
    const newRooms = roomsProps.map(
      roomProp =>
        new YadoRoomStore({
          males: roomProp.males,
          females: roomProp.females,
          childAges: roomProp.childAges,
        }),
    );
    this.rooms = observable.array(newRooms);
  }

  @computed
  get keywordOrAreaName(): string {
    if (this.searchMode === 'keyword') {
      return this.keyword;
    }

    return this.areaName;
  }

  @computed
  get hasKeyword(): boolean {
    return !!this.keyword;
  }

  @computed
  get totalStaying(): number {
    return differenceInDays(this.checkoutDate, this.checkinDate);
  }

  @computed
  get totalAdults(): number {
    return this.rooms.reduce((num, room) => num + room.adults, 0);
  }

  @computed
  get totalMales(): number {
    return this.rooms.reduce((num, room) => num + room.males, 0);
  }

  @computed
  get totalFemales(): number {
    return this.rooms.reduce((num, room) => num + room.females, 0);
  }

  @computed
  get totalChildren(): number {
    return this.rooms.reduce((num, room) => num + room.children, 0);
  }

  @computed
  get totalPeople(): number {
    return this.totalAdults + this.totalChildren;
  }

  @computed
  get totalRooms(): number {
    return this.rooms.length;
  }

  @computed
  get isStation(): boolean {
    return STATION_REGEXP.test(this.prettyPrimaryQuery);
  }

  @action
  updatePrimaryQuery(query: YadoSearchPrimaryQuery): void {
    const {
      searchMode,
      area,
      areaName,
      spot,
      spotName,
      prefecture,
      prefectureName,
      city,
      cityName,
      keyword,
    } = query as {
      searchMode: string;
      area: string;
      areaName: string;
      spot: string;
      spotName: string;
      prefecture: string;
      prefectureName: string;
      city: string;
      cityName: string;
      keyword: string;
    };

    const assertNull = (message: string) => {
      throw new Error(message || `Invalid query: ${JSON.stringify(query)}`);
    };

    switch (searchMode) {
      case 'area':
        if (area == null) {
          assertNull(`area should not be null! ${JSON.stringify(query)}`);
          return;
        }

        this.area = area;
        this.areaName = areaName || '';
        break;

      case 'spot':
        if (spot == null) {
          assertNull(`spot should not be null! ${JSON.stringify(query)}`);
          return;
        }

        this.spot = spot;
        this.spotName = spotName || '';
        break;

      case 'prefecture':
        if (prefecture == null) {
          assertNull(`prefecture should not be null! ${JSON.stringify(query)}`);
          return;

          /**
           * primary query の readable な文字列を取得.
           */
        }

        this.prefecture = prefecture;
        this.prefectureName = prefectureName || '';
        break;

      case 'city':
        if (city == null || prefecture == null) {
          assertNull(`city nor prefecture should not be null! ${JSON.stringify(query)}`);
          return;
        }

        this.city = city;
        this.cityName = cityName || '';

        this.prefecture = prefecture;
        this.prefectureName = prefectureName || '';
        break;

      case 'keyword':
        if (keyword == null) {
          assertNull(`keyword should not be null! ${JSON.stringify(query)}`);
          return;
        }

        this.keyword = keyword;
        break;

      default:
        throw new Error(`Unknown YadoSearchMode: ${searchMode}`);
    }

    this.searchMode = searchMode;
  }

  @computed
  get prettyPrimaryQuery(): string {
    switch (this.searchMode) {
      case 'area':
        return this.areaName;

      case 'spot':
        return this.spotName;

      case 'prefecture':
        return this.prefectureName;

      case 'city':
        return this.cityName;

      case 'keyword':
        return this.keyword;

      default:
        throw new Error(`Unknown mode ${this.searchMode}`);
    }
  }

  @computed
  get adults(): number {
    return this.rooms.reduce((acc, room) => {
      // eslint-disable-next-line no-param-reassign
      acc += room.adults;
      return acc;
    }, 0);
  }

  @computed
  get children(): number {
    return this.rooms.reduce((acc, room) => {
      // eslint-disable-next-line no-param-reassign
      acc += room.children;
      return acc;
    }, 0);
  }

  toAdultParams(): CommonYadoParams {
    return {
      checkin_date: formatDateParam(this.checkinDate),
      checkout_date: formatDateParam(this.checkoutDate),
      rooms: Array.from(this.rooms.map(room => room.toAdultParams())),
    };
  }

  toBookingParams(): {
    checkinDate: string;
    checkoutDate: string;
    rooms: BookingRoom[];
  } {
    return {
      checkinDate: formatDateParam(this.checkinDate),
      checkoutDate: formatDateParam(this.checkoutDate),
      rooms: Array.from(this.rooms.map(room => room.toBookingParams())),
    };
  }

  toPrimaryQueryParams(): any {
    switch (this.searchMode) {
      case 'prefecture':
        return {
          searchMode: 'prefecture',
          prefecture: this.prefecture,
        };

      case 'city':
        return {
          searchMode: 'city',
          prefecture: this.prefecture,
          city: this.city,
        };

      case 'spot':
        return {
          searchMode: 'spot',
          spot: this.spot,
        };

      case 'area':
        return {
          searchMode: 'area',
          area: this.area,
        };

      default:
        throw new Error('YadoSearchState.toPrimaryQueryParams: Implement me!');
    }
  }

  toTrackingParams(): {
    people: number;
    days: number;
    rooms: number;
    adults: number;
    males: number;
    females: number;
    children: number;
    area: string;
    areaName: string;
    checkin: string;
    checkout: string;
    keyword: string;
    prefecture: string;
    city: string;

    spot: string;
    feature: string;
    searchMode: string;
  } {
    return {
      people: this.totalPeople,
      days: this.totalStaying,
      rooms: this.totalRooms,
      adults: this.totalAdults,
      males: this.totalMales,
      females: this.totalFemales,
      children: this.totalChildren,
      areaName: this.areaName,
      area: this.area,
      checkin: formatDateParam(this.checkinDate),
      checkout: formatDateParam(this.checkoutDate),
      keyword: this.keyword,
      prefecture: this.prefecture,
      city: this.city,
      spot: this.spot,
      feature: this.feature,
      searchMode: this.searchMode,
    };
  }

  get uniqueKey(): string {
    const tp = this.toTrackingParams();
    return [
      tp.people,
      tp.checkin,
      tp.checkout,
      tp.area,
      tp.rooms,
      tp.days,
      tp.keyword,
      tp.prefecture,
      tp.city,
      tp.spot,
      tp.feature,
    ].join('-');
  }

  toProperties(): object {
    return {
      ...this,
      rooms: this.rooms.map(room => toJS(room)),
    };
  }

  static fromPropeties(storeProperties: StoreProps): YadoSearchState {
    const store = new YadoSearchState();
    store.area = storeProperties.area;
    store.spot = storeProperties.spot;
    store.feature = storeProperties.feature;
    store.prefecture = storeProperties.prefecture;
    store.city = storeProperties.city;
    store.searchMode = storeProperties.searchMode;
    store.keyword = storeProperties.keyword;
    store.areaName = storeProperties.areaName;
    store.checkinDate = storeProperties.checkinDate;
    store.checkoutDate = storeProperties.checkoutDate;
    store.rooms = storeProperties.rooms.map(room => new YadoRoomStore(room));
    return store;
  }
}
