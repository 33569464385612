/* eslint-disable @typescript-eslint/no-explicit-any */
/*
 * This code auto generated by R.rake
 * R.js is looks like R.java
 */

import { translation } from '../../config/locales/i18n';
import i18nProxyHandler from './i18nProxyHandler';

type I18nArgumentType = { [key: string]: string | number };

/* i18n */
const i18n = {
  /* 閉じる */
  alertInformationCloseButton: (obj?: I18nArgumentType) =>
    translation('AlertInformation-CloseButton', obj),
  /* 詳細を見る */
  alertInformationDetailButton: (obj?: I18nArgumentType) =>
    translation('AlertInformation-DetailButton', obj),
  /* アップデートする */
  alertInformationUpdateRequiredPositive: (obj?: I18nArgumentType) =>
    translation('AlertInformation-UpdateRequiredPositive', obj),
  /* 中国 */
  areaSelectAreaChina: (obj?: I18nArgumentType) => translation('AreaSelect-AreaChina', obj),
  /* 香港 */
  areaSelectAreaHongKong: (obj?: I18nArgumentType) => translation('AreaSelect-AreaHongKong', obj),
  /* 台湾 */
  areaSelectAreaTaiwan: (obj?: I18nArgumentType) => translation('AreaSelect-AreaTaiwan', obj),
  /* 簡体字 */
  areaSelectChineseSimplified: (obj?: I18nArgumentType) =>
    translation('AreaSelect-ChineseSimplified', obj),
  /* 繁体字 */
  areaSelectChineseTraditional: (obj?: I18nArgumentType) =>
    translation('AreaSelect-ChineseTraditional', obj),
  /* 決定後の変更はできません。
お住いの地域は{{area}}でよろしいですか？ */
  areaSelectConfirmAreaMessage: (obj?: I18nArgumentType) =>
    translation('AreaSelect-ConfirmAreaMessage', obj),
  /* メールアドレスでログインまたは登録 */
  authAuthWithEmailLabelTitle: (obj?: I18nArgumentType) =>
    translation('Auth-AuthWithEmailLabelTitle', obj),
  /* Facebookでログインまたは登録 */
  authAuthWithFacebookLabelTitle: (obj?: I18nArgumentType) =>
    translation('Auth-AuthWithFacebookLabelTitle', obj),
  /* Googleでログインまたは登録 */
  authAuthWithGoogleLabelTitle: (obj?: I18nArgumentType) =>
    translation('Auth-AuthWithGoogleLabelTitle', obj),
  /* キャンペーンに参加するには
ユーザー登録（無料）が必要です。 */
  authCampaignRequireRegistration: (obj?: I18nArgumentType) =>
    translation('Auth-CampaignRequireRegistration', obj),
  /* メールアドレスに誤りがある、または登録済みです。 */
  authEmailAgreementBadAddress: (obj?: I18nArgumentType) =>
    translation('Auth-EmailAgreementBadAddress', obj),
  /* 登録に失敗しました。 */
  authEmailAgreementConfirmationFailed: (obj?: I18nArgumentType) =>
    translation('Auth-EmailAgreementConfirmationFailed', obj),
  /* 次のメールアドレスで、WAmazing に会員登録します。 */
  authEmailAgreementDescription: (obj?: I18nArgumentType) =>
    translation('Auth-EmailAgreementDescription', obj),
  /* メールアドレスの入力は必須です。 */
  authEmailAgreementEmptyWarning: (obj?: I18nArgumentType) =>
    translation('Auth-EmailAgreementEmptyWarning', obj),
  /* WAmazing に登録するメールアドレスを入力してください。 */
  authEmailAgreementRequired: (obj?: I18nArgumentType) =>
    translation('Auth-EmailAgreementRequired', obj),
  /* さあ、始めよう！ */
  authGettingStartedButton: (obj?: I18nArgumentType) =>
    translation('Auth-GettingStartedButton', obj),
  /* アカウントお持ちの場合 */
  authIfAlreadyHaveAccount: (obj?: I18nArgumentType) =>
    translation('Auth-IfAlreadyHaveAccount', obj),
  /* メールアドレスの確認がまだ行われていません。 */
  authMailAddressNotVerifiedErrorMessage: (obj?: I18nArgumentType) =>
    translation('Auth-MailAddressNotVerifiedErrorMessage', obj),
  /* メールアドレス */
  authMailInputViewTitle: (obj?: I18nArgumentType) => translation('Auth-MailInputViewTitle', obj),
  /* パスワード */
  authPasswordInputViewTitle: (obj?: I18nArgumentType) =>
    translation('Auth-PasswordInputViewTitle', obj),
  /* パスワードをお忘れの方はこちら */
  authPasswordReminderButtonTitle: (obj?: I18nArgumentType) =>
    translation('Auth-PasswordReminderButtonTitle', obj),
  /* パスワード再設定 */
  authPasswordReminderNavigationBarTitle: (obj?: I18nArgumentType) =>
    translation('Auth-PasswordReminderNavigationBarTitle', obj),
  /* パスワードの再設定メールを送信しました。 */
  authPasswordResetEmailHasBeenSent: (obj?: I18nArgumentType) =>
    translation('Auth-PasswordResetEmailHasBeenSent', obj),
  /* 入力されたメールアドレスは登録されていません。 */
  authPasswordResetEmailNotFound: (obj?: I18nArgumentType) =>
    translation('Auth-PasswordResetEmailNotFound', obj),
  /* アドレス確認メールは既に再送されています。時間を置いて再度お試しください。 */
  authResendMailTooShortIntervalErrorMessage: (obj?: I18nArgumentType) =>
    translation('Auth-ResendMailTooShortIntervalErrorMessage', obj),
  /* メールアドレス確認メールを再送しましたので、メールアプリをご確認ください。 */
  authResendVerificationMailAlertMessage: (obj?: I18nArgumentType) =>
    translation('Auth-ResendVerificationMailAlertMessage', obj),
  /* アドレス確認メールを再送する */
  authResendVerificationMailButtonTitle: (obj?: I18nArgumentType) =>
    translation('Auth-ResendVerificationMailButtonTitle', obj),
  /* 申込する */
  authSendSimRequestApplyButtonTitle: (obj?: I18nArgumentType) =>
    translation('Auth-SendSimRequestApplyButtonTitle', obj),
  /* ログイン */
  authSignInButtonTitle: (obj?: I18nArgumentType) => translation('Auth-SignInButtonTitle', obj),
  /* WeChatアカウントでログイン */
  authSignInWithWeChatLabelTitle: (obj?: I18nArgumentType) =>
    translation('Auth-SignInWithWeChatLabelTitle', obj),
  /* 閉じる */
  authSignUpAlertCloseButtonText: (obj?: I18nArgumentType) =>
    translation('Auth-SignUpAlertCloseButtonText', obj),
  /* 登録 */
  authSignUpButtonTitle: (obj?: I18nArgumentType) => translation('Auth-SignUpButtonTitle', obj),
  /* 登録が完了しました。 */
  authSignUpCompleteThanksMessage: (obj?: I18nArgumentType) =>
    translation('Auth-SignUpCompleteThanksMessage', obj),
  /* 登録ありがとうございます！ */
  authSignUpCompleteThanksTitle: (obj?: I18nArgumentType) =>
    translation('Auth-SignUpCompleteThanksTitle', obj),
  /* アカウント情報を再度ご確認ください */
  authSignUpFailedAlertBody: (obj?: I18nArgumentType) =>
    translation('Auth-SignUpFailedAlertBody', obj),
  /* 認証に失敗しました */
  authSignUpFailedAlertTitle: (obj?: I18nArgumentType) =>
    translation('Auth-SignUpFailedAlertTitle', obj),
  /* 申し訳ございません。WeChatでの新規会員登録は現在サポートしておりません。お手数ですが、他の会員登録方法をお試し下さい。 */
  authSignUpFailureAlertWeChatLoginForbidden: (obj?: I18nArgumentType) =>
    translation('Auth-SignUpFailureAlertWeChatLoginForbidden', obj),
  /* メールを確認し、本文に記載されているURLをタップしてください。 */
  authSignUpMailSentDescriptionText: (obj?: I18nArgumentType) =>
    translation('Auth-SignUpMailSentDescriptionText', obj),
  /* 以下のメールアドレスにメールアドレス確認メールを送信しました。 */
  authSignUpMailSentTitle: (obj?: I18nArgumentType) => translation('Auth-SignUpMailSentTitle', obj),
  /* 通信サービス利用規約 */
  authSignUpNotesCommunicationServiceTermsText: (obj?: I18nArgumentType) =>
    translation('Auth-SignUpNotesCommunicationServiceTermsText', obj),
  /* 会員利用規約 */
  authSignUpNotesMemberTermsText: (obj?: I18nArgumentType) =>
    translation('Auth-SignUpNotesMemberTermsText', obj),
  /* プライバシーポリシー */
  authSignUpNotesPrivacyPolicyText: (obj?: I18nArgumentType) =>
    translation('Auth-SignUpNotesPrivacyPolicyText', obj),
  /* 登録ボタンを押すと、会員利用規約、プライバシーポリシー、通信サービス利用規約に同意したことになります。 */
  authSignUpNotesText: (obj?: I18nArgumentType) => translation('Auth-SignUpNotesText', obj),
  /* メールアドレスで登録 */
  authSignUpWithMailLabelTitle: (obj?: I18nArgumentType) =>
    translation('Auth-SignUpWithMailLabelTitle', obj),
  /* 別のメールアドレスで登録する */
  authSignUpWithOtherEmailButtonTitle: (obj?: I18nArgumentType) =>
    translation('Auth-SignUpWithOtherEmailButtonTitle', obj),
  /* ユーザ登録 / ログイン */
  authWelcomeSignInOrSignUpButtonTitle: (obj?: I18nArgumentType) =>
    translation('Auth-WelcomeSignInOrSignUpButtonTitle', obj),
  /* マカオ航空 */
  commonAirlineAmu: (obj?: I18nArgumentType) => translation('Common-AirlineAmu', obj),
  /* 全日本空輸 */
  commonAirlineAna: (obj?: I18nArgumentType) => translation('Common-AirlineAna', obj),
  /* Peach */
  commonAirlineApj: (obj?: I18nArgumentType) => translation('Common-AirlineApj', obj),
  /* チャイナエアライン */
  commonAirlineCal: (obj?: I18nArgumentType) => translation('Common-AirlineCal', obj),
  /* 中国国際航空 */
  commonAirlineCca: (obj?: I18nArgumentType) => translation('Common-AirlineCca', obj),
  /* 山東航空 */
  commonAirlineCdg: (obj?: I18nArgumentType) => translation('Common-AirlineCdg', obj),
  /* 中国東方航空 */
  commonAirlineCes: (obj?: I18nArgumentType) => translation('Common-AirlineCes', obj),
  /* 海南航空 */
  commonAirlineChh: (obj?: I18nArgumentType) => translation('Common-AirlineChh', obj),
  /* キャセイパシフィック航空 */
  commonAirlineCpa: (obj?: I18nArgumentType) => translation('Common-AirlineCpa', obj),
  /* 四川航空 */
  commonAirlineCsc: (obj?: I18nArgumentType) => translation('Common-AirlineCsc', obj),
  /* 中国南方航空 */
  commonAirlineCsn: (obj?: I18nArgumentType) => translation('Common-AirlineCsn', obj),
  /* 深圳航空 */
  commonAirlineCsz: (obj?: I18nArgumentType) => translation('Common-AirlineCsz', obj),
  /* 厦門航空 */
  commonAirlineCxa: (obj?: I18nArgumentType) => translation('Common-AirlineCxa', obj),
  /* デルタ航空 */
  commonAirlineDal: (obj?: I18nArgumentType) => translation('Common-AirlineDal', obj),
  /* エチオピア航空 */
  commonAirlineEth: (obj?: I18nArgumentType) => translation('Common-AirlineEth', obj),
  /* エバー航空 */
  commonAirlineEva: (obj?: I18nArgumentType) => translation('Common-AirlineEva', obj),
  /* 香港エクスプレス */
  commonAirlineHke: (obj?: I18nArgumentType) => translation('Common-AirlineHke', obj),
  /* JAL(日本航空) */
  commonAirlineJal: (obj?: I18nArgumentType) => translation('Common-AirlineJal', obj),
  /* ジェットスター・ジャパン */
  commonAirlineJjp: (obj?: I18nArgumentType) => translation('Common-AirlineJjp', obj),
  /* ジェットスター航空 */
  commonAirlineJst: (obj?: I18nArgumentType) => translation('Common-AirlineJst', obj),
  /* 大韓航空 */
  commonAirlineKal: (obj?: I18nArgumentType) => translation('Common-AirlineKal', obj),
  /* ロイヤルブルネイ航空 */
  commonAirlineRba: (obj?: I18nArgumentType) => translation('Common-AirlineRba', obj),
  /* スクート */
  commonAirlineSco: (obj?: I18nArgumentType) => translation('Common-AirlineSco', obj),
  /* 春秋航空日本 */
  commonAirlineSjo: (obj?: I18nArgumentType) => translation('Common-AirlineSjo', obj),
  /* タイガーエア台湾 */
  commonAirlineTtw: (obj?: I18nArgumentType) => translation('Common-AirlineTtw', obj),
  /* バニラエア */
  commonAirlineVnl: (obj?: I18nArgumentType) => translation('Common-AirlineVnl', obj),
  /* 旭川空港 */
  commonAirportAkj: (obj?: I18nArgumentType) => translation('Common-AirportAkj', obj),
  /* 旭川 */
  commonAirportAkjNearCityName: (obj?: I18nArgumentType) =>
    translation('Common-AirportAkjNearCityName', obj),
  /* 北海道 */
  commonAirportAkjNearFavAreas: (obj?: I18nArgumentType) =>
    translation('Common-AirportAkjNearFavAreas', obj),
  /* 青森空港 */
  commonAirportAoj: (obj?: I18nArgumentType) => translation('Common-AirportAoj', obj),
  /* 青森 */
  commonAirportAojNearCityName: (obj?: I18nArgumentType) =>
    translation('Common-AirportAojNearCityName', obj),
  /* 青森,弘前 */
  commonAirportAojNearFavAreas: (obj?: I18nArgumentType) =>
    translation('Common-AirportAojNearFavAreas', obj),
  /* 秋田空港 */
  commonAirportAxt: (obj?: I18nArgumentType) => translation('Common-AirportAxt', obj),
  /* 秋田 */
  commonAirportAxtNearCityName: (obj?: I18nArgumentType) =>
    translation('Common-AirportAxtNearCityName', obj),
  /* 秋田,田沢湖・角館・大曲,十和田湖・大館・鹿角,白神・男鹿,横手・鳥海 */
  commonAirportAxtNearFavAreas: (obj?: I18nArgumentType) =>
    translation('Common-AirportAxtNearFavAreas', obj),
  /* 新千歳空港 */
  commonAirportCts: (obj?: I18nArgumentType) => translation('Common-AirportCts', obj),
  /* 札幌 */
  commonAirportCtsNearCityName: (obj?: I18nArgumentType) =>
    translation('Common-AirportCtsNearCityName', obj),
  /*  */
  commonAirportCtsNearFavAreas: (obj?: I18nArgumentType) =>
    translation('Common-AirportCtsNearFavAreas', obj),
  /* 国際線ターミナル */
  commonAirportDomesticTerminal: (obj?: I18nArgumentType) =>
    translation('Common-AirportDomesticTerminal', obj),
  /* 富士山静岡空港 */
  commonAirportFsz: (obj?: I18nArgumentType) => translation('Common-AirportFsz', obj),
  /* 静岡 */
  commonAirportFszNearCityName: (obj?: I18nArgumentType) =>
    translation('Common-AirportFszNearCityName', obj),
  /* 静岡,浜松・浜北,浜松・浜名湖 */
  commonAirportFszNearFavAreas: (obj?: I18nArgumentType) =>
    translation('Common-AirportFszNearFavAreas', obj),
  /* 福岡空港 */
  commonAirportFuk: (obj?: I18nArgumentType) => translation('Common-AirportFuk', obj),
  /* 博多 */
  commonAirportFukNearCityName: (obj?: I18nArgumentType) =>
    translation('Common-AirportFukNearCityName', obj),
  /*  */
  commonAirportFukNearFavAreas: (obj?: I18nArgumentType) =>
    translation('Common-AirportFukNearFavAreas', obj),
  /* 広島空港 */
  commonAirportHij: (obj?: I18nArgumentType) => translation('Common-AirportHij', obj),
  /* 広島 */
  commonAirportHijNearCityName: (obj?: I18nArgumentType) =>
    translation('Common-AirportHijNearCityName', obj),
  /* 広島駅,尾道駅,呉駅 */
  commonAirportHijNearFavAreas: (obj?: I18nArgumentType) =>
    translation('Common-AirportHijNearFavAreas', obj),
  /* 函館空港 */
  commonAirportHkd: (obj?: I18nArgumentType) => translation('Common-AirportHkd', obj),
  /* 函館 */
  commonAirportHkdNearCityName: (obj?: I18nArgumentType) =>
    translation('Common-AirportHkdNearCityName', obj),
  /*  */
  commonAirportHkdNearFavAreas: (obj?: I18nArgumentType) =>
    translation('Common-AirportHkdNearFavAreas', obj),
  /* いわて花巻空港 */
  commonAirportHna: (obj?: I18nArgumentType) => translation('Common-AirportHna', obj),
  /* 花巻 */
  commonAirportHnaNearCityName: (obj?: I18nArgumentType) =>
    translation('Common-AirportHnaNearCityName', obj),
  /* 花巻・北上・遠野,盛岡,奥州・平泉・一関 */
  commonAirportHnaNearFavAreas: (obj?: I18nArgumentType) =>
    translation('Common-AirportHnaNearFavAreas', obj),
  /* 羽田空港 */
  commonAirportHnd: (obj?: I18nArgumentType) => translation('Common-AirportHnd', obj),
  /* 東京 */
  commonAirportHndNearCityName: (obj?: I18nArgumentType) =>
    translation('Common-AirportHndNearCityName', obj),
  /* 新宿駅,上野駅,浅草駅,池袋駅 */
  commonAirportHndNearFavAreas: (obj?: I18nArgumentType) =>
    translation('Common-AirportHndNearFavAreas', obj),
  /* 茨城空港 */
  commonAirportIbr: (obj?: I18nArgumentType) => translation('Common-AirportIbr', obj),
  /* 茨城 */
  commonAirportIbrNearCityName: (obj?: I18nArgumentType) =>
    translation('Common-AirportIbrNearCityName', obj),
  /* 水戸駅 */
  commonAirportIbrNearFavAreas: (obj?: I18nArgumentType) =>
    translation('Common-AirportIbrNearFavAreas', obj),
  /* 国際線ターミナル */
  commonAirportInternationalTerminal: (obj?: I18nArgumentType) =>
    translation('Common-AirportInternationalTerminal', obj),
  /* 新潟空港 */
  commonAirportKij: (obj?: I18nArgumentType) => translation('Common-AirportKij', obj),
  /* 新潟 */
  commonAirportKijNearCityName: (obj?: I18nArgumentType) =>
    translation('Common-AirportKijNearCityName', obj),
  /* 新潟 */
  commonAirportKijNearFavAreas: (obj?: I18nArgumentType) =>
    translation('Common-AirportKijNearFavAreas', obj),
  /* 関西国際空港 */
  commonAirportKix: (obj?: I18nArgumentType) => translation('Common-AirportKix', obj),
  /* 大阪 */
  commonAirportKixNearCityName: (obj?: I18nArgumentType) =>
    translation('Common-AirportKixNearCityName', obj),
  /* 大阪駅,京都駅,難波駅,関西空港駅 */
  commonAirportKixNearFavAreas: (obj?: I18nArgumentType) =>
    translation('Common-AirportKixNearFavAreas', obj),
  /* 北九州空港 */
  commonAirportKkj: (obj?: I18nArgumentType) => translation('Common-AirportKkj', obj),
  /* 北九州 */
  commonAirportKkjNearCityName: (obj?: I18nArgumentType) =>
    translation('Common-AirportKkjNearCityName', obj),
  /* 北九州,福岡市（博多駅周辺・天神周辺）,久留米・原鶴・筑後川,太宰府・宗像,柳川・八女・筑後,糸島・前原 */
  commonAirportKkjNearFavAreas: (obj?: I18nArgumentType) =>
    translation('Common-AirportKkjNearFavAreas', obj),
  /* 小松空港 */
  commonAirportKmq: (obj?: I18nArgumentType) => translation('Common-AirportKmq', obj),
  /* 金沢 */
  commonAirportKmqNearCityName: (obj?: I18nArgumentType) =>
    translation('Common-AirportKmqNearCityName', obj),
  /* 金沢・羽咋,金沢・湯涌,金沢・小松・辰口,輪島・能登 */
  commonAirportKmqNearFavAreas: (obj?: I18nArgumentType) =>
    translation('Common-AirportKmqNearFavAreas', obj),
  /* 鹿児島空港 */
  commonAirportKoj: (obj?: I18nArgumentType) => translation('Common-AirportKoj', obj),
  /* 鹿児島 */
  commonAirportKojNearCityName: (obj?: I18nArgumentType) =>
    translation('Common-AirportKojNearCityName', obj),
  /* 鹿児島 */
  commonAirportKojNearFavAreas: (obj?: I18nArgumentType) =>
    translation('Common-AirportKojNearFavAreas', obj),
  /* 中部国際空港 セントレア */
  commonAirportNgo: (obj?: I18nArgumentType) => translation('Common-AirportNgo', obj),
  /* 名古屋 */
  commonAirportNgoNearCityName: (obj?: I18nArgumentType) =>
    translation('Common-AirportNgoNearCityName', obj),
  /* 名古屋駅,金山駅,栄駅,伏見駅 */
  commonAirportNgoNearFavAreas: (obj?: I18nArgumentType) =>
    translation('Common-AirportNgoNearFavAreas', obj),
  /* 成田国際空港 */
  commonAirportNrt: (obj?: I18nArgumentType) => translation('Common-AirportNrt', obj),
  /* 東京 */
  commonAirportNrtNearCityName: (obj?: I18nArgumentType) =>
    translation('Common-AirportNrtNearCityName', obj),
  /* 新宿駅,上野駅,浅草駅,池袋駅 */
  commonAirportNrtNearFavAreas: (obj?: I18nArgumentType) =>
    translation('Common-AirportNrtNearFavAreas', obj),
  /* 成田第1ターミナル */
  commonAirportNrtTerminalOne: (obj?: I18nArgumentType) =>
    translation('Common-AirportNrtTerminalOne', obj),
  /* 成田第2ターミナル */
  commonAirportNrtTerminalTwo: (obj?: I18nArgumentType) =>
    translation('Common-AirportNrtTerminalTwo', obj),
  /* 那覇空港 */
  commonAirportOka: (obj?: I18nArgumentType) => translation('Common-AirportOka', obj),
  /* 那覇 */
  commonAirportOkaNearCityName: (obj?: I18nArgumentType) =>
    translation('Common-AirportOkaNearCityName', obj),
  /* 沖縄 */
  commonAirportOkaNearFavAreas: (obj?: I18nArgumentType) =>
    translation('Common-AirportOkaNearFavAreas', obj),
  /* 岡山空港 */
  commonAirportOkj: (obj?: I18nArgumentType) => translation('Common-AirportOkj', obj),
  /* 岡山 */
  commonAirportOkjNearCityName: (obj?: I18nArgumentType) =>
    translation('Common-AirportOkjNearCityName', obj),
  /* 岡山駅 */
  commonAirportOkjNearFavAreas: (obj?: I18nArgumentType) =>
    translation('Common-AirportOkjNearFavAreas', obj),
  /* 仙台国際空港 */
  commonAirportSdj: (obj?: I18nArgumentType) => translation('Common-AirportSdj', obj),
  /* 仙台 */
  commonAirportSdjNearCityName: (obj?: I18nArgumentType) =>
    translation('Common-AirportSdjNearCityName', obj),
  /* 仙台,蔵王 */
  commonAirportSdjNearFavAreas: (obj?: I18nArgumentType) =>
    translation('Common-AirportSdjNearFavAreas', obj),
  /* 下地島空港 */
  commonAirportShi: (obj?: I18nArgumentType) => translation('Common-AirportShi', obj),
  /* 宮古島 */
  commonAirportShiNearCityName: (obj?: I18nArgumentType) =>
    translation('Common-AirportShiNearCityName', obj),
  /* 宮古島 */
  commonAirportShiNearFavAreas: (obj?: I18nArgumentType) =>
    translation('Common-AirportShiNearFavAreas', obj),
  /* その他 */
  commonAirportX: (obj?: I18nArgumentType) => translation('Common-AirportX', obj),
  /* 米子空港 */
  commonAirportYgj: (obj?: I18nArgumentType) => translation('Common-AirportYgj', obj),
  /* 米子 */
  commonAirportYgjNearCityName: (obj?: I18nArgumentType) =>
    translation('Common-AirportYgjNearCityName', obj),
  /* 鳥取 */
  commonAirportYgjNearFavAreas: (obj?: I18nArgumentType) =>
    translation('Common-AirportYgjNearFavAreas', obj),
  /* キャンセル */
  commonAlertCancelButtonTitle: (obj?: I18nArgumentType) =>
    translation('Common-AlertCancelButtonTitle', obj),
  /* いいえ */
  commonAlertNoButtonTitle: (obj?: I18nArgumentType) =>
    translation('Common-AlertNoButtonTitle', obj),
  /* OK */
  commonAlertOKButtonTitle: (obj?: I18nArgumentType) =>
    translation('Common-AlertOKButtonTitle', obj),
  /* はい */
  commonAlertYesButtonTitle: (obj?: I18nArgumentType) =>
    translation('Common-AlertYesButtonTitle', obj),
  /* 午前 */
  commonAm: (obj?: I18nArgumentType) => translation('Common-Am', obj),
  /* アプリのバージョン */
  commonAppVersionLabel: (obj?: I18nArgumentType) => translation('Common-AppVersionLabel', obj),
  /* キャンセル */
  commonCancel: (obj?: I18nArgumentType) => translation('Common-Cancel', obj),
  /* 変更する */
  commonChange: (obj?: I18nArgumentType) => translation('Common-Change', obj),
  /* 閉じる */
  commonCloseButtonTitle: (obj?: I18nArgumentType) => translation('Common-CloseButtonTitle', obj),
  /* 確定 */
  commonConfirm: (obj?: I18nArgumentType) => translation('Common-Confirm', obj),
  /* データの取得に失敗しました。 */
  commonDataFetchingFailedErrorMessage: (obj?: I18nArgumentType) =>
    translation('Common-DataFetchingFailedErrorMessage', obj),
  /* Reload */
  commonDataFetchingReload: (obj?: I18nArgumentType) =>
    translation('Common-DataFetchingReload', obj),
  /* HH時mm分 */
  commonDateFormatHHmm: (obj?: I18nArgumentType) => translation('Common-DateFormatHHmm', obj),
  /* M月dd日 */
  commonDateFormatMdd: (obj?: I18nArgumentType) => translation('Common-DateFormatMdd', obj),
  /* MM月 */
  commonDateFormatMM: (obj?: I18nArgumentType) => translation('Common-DateFormatMM', obj),
  /* yyyy年MM月dd日 */
  commonDateFormatyyyyMMdd: (obj?: I18nArgumentType) =>
    translation('Common-DateFormatyyyyMMdd', obj),
  /* 明後日 */
  commonDayAfterTomorrow: (obj?: I18nArgumentType) => translation('Common-DayAfterTomorrow', obj),
  /* [object Object] */
  commonDays: (obj?: I18nArgumentType) => translation('Common-Days', obj),
  /* 許可しない */
  commonDisallow: (obj?: I18nArgumentType) => translation('Common-Disallow', obj),
  /* Off */
  commonDiscount: (obj?: I18nArgumentType) => translation('Common-Discount', obj),
  /* 完了 */
  commonDone: (obj?: I18nArgumentType) => translation('Common-Done', obj),
  /* 金 */
  commonFridayLabel: (obj?: I18nArgumentType) => translation('Common-FridayLabel', obj),
  /* {{amount}}GB */
  commonGigaByteSuffix: (obj?: I18nArgumentType) => translation('Common-GigaByteSuffix', obj),
  /* [object Object] */
  commonHours: (obj?: I18nArgumentType) => translation('Common-Hours', obj),
  /* 選択してください */
  commonInputPlaceholderText: (obj?: I18nArgumentType) =>
    translation('Common-InputPlaceholderText', obj),
  /* インターネット接続をご確認ください。 */
  commonIsNotConnectingErrorMessage: (obj?: I18nArgumentType) =>
    translation('Common-IsNotConnectingErrorMessage', obj),
  /* JPY {{yen}} */
  commonJPYPrefix: (obj?: I18nArgumentType) => translation('Common-JPYPrefix', obj),
  /* 最終更新 */
  commonLastUpdated: (obj?: I18nArgumentType) => translation('Common-LastUpdated', obj),
  /* 、 */
  commonListPunctuation: (obj?: I18nArgumentType) => translation('Common-ListPunctuation', obj),
  /* {{amount}}MB */
  commonMegaByteSuffix: (obj?: I18nArgumentType) => translation('Common-MegaByteSuffix', obj),
  /* 月 */
  commonMondayLabel: (obj?: I18nArgumentType) => translation('Common-MondayLabel', obj),
  /* 次へ */
  commonNext: (obj?: I18nArgumentType) => translation('Common-Next', obj),
  /* 次へ */
  commonNextButtonTitle: (obj?: I18nArgumentType) => translation('Common-NextButtonTitle', obj),
  /* OK */
  commonOkButtonTitle: (obj?: I18nArgumentType) => translation('Common-OkButtonTitle', obj),
  /* % OFF */
  commonPercentDiscount: (obj?: I18nArgumentType) => translation('Common-PercentDiscount', obj),
  /* 設定画面で{{permissionType}}の使用を許可してください */
  commonPermissionOpenSettingsMessage: (obj?: I18nArgumentType) =>
    translation('Common-PermissionOpenSettingsMessage', obj),
  /* {{permissionType}}の使用許可設定 */
  commonPermissionOpenSettingsTitle: (obj?: I18nArgumentType) =>
    translation('Common-PermissionOpenSettingsTitle', obj),
  /* {{permissionType}}は使用できません */
  commonPermissionRestrictedMessage: (obj?: I18nArgumentType) =>
    translation('Common-PermissionRestrictedMessage', obj),
  /* {{permissionType}}の使用不可 */
  commonPermissionRestrictedTitle: (obj?: I18nArgumentType) =>
    translation('Common-PermissionRestrictedTitle', obj),
  /* カメラ */
  commonPermissionTypeCamera: (obj?: I18nArgumentType) =>
    translation('Common-PermissionTypeCamera', obj),
  /* 位置情報 */
  commonPermissionTypeLocation: (obj?: I18nArgumentType) =>
    translation('Common-PermissionTypeLocation', obj),
  /* 通知 */
  commonPermissionTypeNotification: (obj?: I18nArgumentType) =>
    translation('Common-PermissionTypeNotification', obj),
  /* 写真 */
  commonPermissionTypePhoto: (obj?: I18nArgumentType) =>
    translation('Common-PermissionTypePhoto', obj),
  /* 午後 */
  commonPm: (obj?: I18nArgumentType) => translation('Common-Pm', obj),
  /* {{airportName}}の受取可能日は{{year}}年{{month}}月{{date}}日{{ampm}}{{hours}}時{{minutes}}分以降です。 */
  commonReceivableDateInSelectedAirport: (obj?: I18nArgumentType) =>
    translation('Common-ReceivableDateInSelectedAirport', obj),
  /* {{airportName}}の受取可能日は{{year}}年{{month}}月{{date}}日{{ampm}}{{hours}}時{{minutes}}分までです。 */
  commonReceivableDueDateInSelectedAirport: (obj?: I18nArgumentType) =>
    translation('Common-ReceivableDueDateInSelectedAirport', obj),
  /* 再起動 */
  commonRestart: (obj?: I18nArgumentType) => translation('Common-Restart', obj),
  /* 土 */
  commonSaturdayLabel: (obj?: I18nArgumentType) => translation('Common-SaturdayLabel', obj),
  /* ご購入ありがとうございます */
  commonSavedPopUpViewCompletedPayLabelTitle: (obj?: I18nArgumentType) =>
    translation('Common-SavedPopUpViewCompletedPayLabelTitle', obj),
  /* 並び替え */
  commonSort: (obj?: I18nArgumentType) => translation('Common-Sort', obj),
  /* 送信 */
  commonSubmitButtonTitle: (obj?: I18nArgumentType) => translation('Common-SubmitButtonTitle', obj),
  /* 日 */
  commonSundayLabel: (obj?: I18nArgumentType) => translation('Common-SundayLabel', obj),
  /* TTE台北旅展 */
  commonTaipeiTourismExpo: (obj?: I18nArgumentType) => translation('Common-TaipeiTourismExpo', obj),
  /* 木 */
  commonThursdayLabel: (obj?: I18nArgumentType) => translation('Common-ThursdayLabel', obj),
  /* 東京国際クルーズターミナル */
  commonTict: (obj?: I18nArgumentType) => translation('Common-Tict', obj),
  /* 今日 */
  commonToday: (obj?: I18nArgumentType) => translation('Common-Today', obj),
  /* 明日 */
  commonTomorrow: (obj?: I18nArgumentType) => translation('Common-Tomorrow', obj),
  /* 火 */
  commonTuesdayLabel: (obj?: I18nArgumentType) => translation('Common-TuesdayLabel', obj),
  /* 現在、一時的にご利用いただけません。申し訳ありませんが、時間をおいた後にご利用下さい */
  commonUnknownReasonErrorMessage: (obj?: I18nArgumentType) =>
    translation('Common-UnknownReasonErrorMessage', obj),
  /* 水 */
  commonWednesdayLabel: (obj?: I18nArgumentType) => translation('Common-WednesdayLabel', obj),
  /* YYYY年M月D日{{ampm}}h時m分 */
  commonWithoutSecondWithAMPM: (obj?: I18nArgumentType) =>
    translation('Common-WithoutSecondWithAMPM', obj),
  /* yyyy年 MM月 */
  commonYearMonthLabel: (obj?: I18nArgumentType) => translation('Common-YearMonthLabel', obj),
  /* 新型コロナウィルス（COVID-19）感染拡大に伴い、新規の宿泊予約の受付を停止させていただいております。 */
  covidAlertText: (obj?: I18nArgumentType) => translation('Covid-AlertText', obj),
  /* デバッグ */
  drawerDebugLabel: (obj?: I18nArgumentType) => translation('Drawer-DebugLabel', obj),
  /* ホーム */
  drawerHomeLabel: (obj?: I18nArgumentType) => translation('Drawer-HomeLabel', obj),
  /* マイページ */
  drawerProfileLabel: (obj?: I18nArgumentType) => translation('Drawer-ProfileLabel', obj),
  /* SIMカード */
  drawerSimLabel: (obj?: I18nArgumentType) => translation('Drawer-SimLabel', obj),
  /* 予約済み宿一覧 */
  drawerYadoBookingHistoryLabel: (obj?: I18nArgumentType) =>
    translation('Drawer-YadoBookingHistoryLabel', obj),
  /* 閲覧履歴 */
  drawerYadoBrowsingHistoryLabel: (obj?: I18nArgumentType) =>
    translation('Drawer-YadoBrowsingHistoryLabel', obj),
  /* お気に入りの宿一覧 */
  drawerYadoFavoriteListLabel: (obj?: I18nArgumentType) =>
    translation('Drawer-YadoFavoriteListLabel', obj),
  /* 宿 */
  drawerYadoLabel: (obj?: I18nArgumentType) => translation('Drawer-YadoLabel', obj),
  /* 大人の人数を入力してください */
  errorAdultCount: (obj?: I18nArgumentType) => translation('Error-AdultCount', obj),
  /* 大人の男性人数を入力してください */
  errorAdultMaleCount: (obj?: I18nArgumentType) => translation('Error-AdultMaleCount', obj),
  /* 大人の女性人数を入力してください */
  errorAdultFemaleCount: (obj?: I18nArgumentType) => translation('Error-AdultFemaleCount', obj),
  /* 着便航空会社名を入力してください */
  errorBookingArrivalAirline: (obj?: I18nArgumentType) =>
    translation('Error-BookingArrivalAirline', obj),
  /* 着便日時(日本時間)を入力してください */
  errorBookingArrivalDate: (obj?: I18nArgumentType) => translation('Error-BookingArrivalDate', obj),
  /* 着便フライト番号を入力してください */
  errorBookingArrivalFlight: (obj?: I18nArgumentType) =>
    translation('Error-BookingArrivalFlight', obj),
  /* 誕生日を入力してください */
  errorBookingBirthDate: (obj?: I18nArgumentType) => translation('Error-BookingBirthDate', obj),
  /* 発便航空会社名を入力してください */
  errorBookingDepartureAirline: (obj?: I18nArgumentType) =>
    translation('Error-BookingDepartureAirline', obj),
  /* 発便日時(日本時間)を入力してください */
  errorBookingDepartureDate: (obj?: I18nArgumentType) =>
    translation('Error-BookingDepartureDate', obj),
  /* 発便フライト番号を入力してください */
  errorBookingDepartureFlight: (obj?: I18nArgumentType) =>
    translation('Error-BookingDepartureFlight', obj),
  /* 利用終了日を入力してください */
  errorBookingEndUsageDate: (obj?: I18nArgumentType) =>
    translation('Error-BookingEndUsageDate', obj),
  /* パスポートの名前を入力してください。 */
  errorBookingFirstNameEmpty: (obj?: I18nArgumentType) =>
    translation('Error-BookingFirstNameEmpty', obj),
  /* パスポートの名字を入力してください。 */
  errorBookingFirstNameInvalid: (obj?: I18nArgumentType) =>
    translation('Error-BookingFirstNameInvalid', obj),
  /* 性別を入力してください。 */
  errorBookingGenderInvalid: (obj?: I18nArgumentType) =>
    translation('Error-BookingGenderInvalid', obj),
  /* 名を入力してください */
  errorBookingHokenFirstName: (obj?: I18nArgumentType) =>
    translation('Error-BookingHokenFirstName', obj),
  /* 姓を入力してください */
  errorBookingHokenLastName: (obj?: I18nArgumentType) =>
    translation('Error-BookingHokenLastName', obj),
  /* パスポートの名字を入力してください。 */
  errorBookingLastNameEmpty: (obj?: I18nArgumentType) =>
    translation('Error-BookingLastNameEmpty', obj),
  /* パスポートの名前を入力してください。 */
  errorBookingLastNameInvalid: (obj?: I18nArgumentType) =>
    translation('Error-BookingLastNameInvalid', obj),
  /* パスポートの氏名を入力してください。 */
  errorBookingNameEmpty: (obj?: I18nArgumentType) => translation('Error-BookingNameEmpty', obj),
  /* パスポートの氏名を入力してください。 */
  errorBookingNameInvalid: (obj?: I18nArgumentType) => translation('Error-BookingNameInvalid', obj),
  /* 国籍を入力してください */
  errorBookingNationality: (obj?: I18nArgumentType) => translation('Error-BookingNationality', obj),
  /* 受取日を入力してください */
  errorBookingPickupDate: (obj?: I18nArgumentType) => translation('Error-BookingPickupDate', obj),
  /* チケットの受け取り場所を入力してください */
  errorBookingPickupLocation: (obj?: I18nArgumentType) =>
    translation('Error-BookingPickupLocation', obj),
  /* 利用開始日を入力してください */
  errorBookingStartUsageDate: (obj?: I18nArgumentType) =>
    translation('Error-BookingStartUsageDate', obj),
  /* チェックイン時間を入力してください */
  errorCheckinTime: (obj?: I18nArgumentType) => translation('Error-CheckinTime', obj),
  /* 子供の人数を入力してください */
  errorChildCount: (obj?: I18nArgumentType) => translation('Error-ChildCount', obj),
  /* 性別を入力してください */
  errorGender: (obj?: I18nArgumentType) => translation('Error-Gender', obj),
  /* 終了日を入力してください */
  errorHokenEndDate: (obj?: I18nArgumentType) => translation('Error-HokenEndDate', obj),
  /* 開始日を入力してください */
  errorHokenStartDate: (obj?: I18nArgumentType) => translation('Error-HokenStartDate', obj),
  /* カメラ、家電、時計、おもちゃ、ドラッグなど */
  homeCouponBicCameraWithItems: (obj?: I18nArgumentType) =>
    translation('Home-CouponBicCameraWithItems', obj),
  /* ビックカメラ */
  homeCouponBiccamera: (obj?: I18nArgumentType) => translation('Home-CouponBiccamera', obj),
  /* 免税 */
  homeCouponDutyFree: (obj?: I18nArgumentType) => translation('Home-CouponDutyFree', obj),
  /* 有効期限：{{expiration}} */
  homeCouponExpirationDateLabelWithTemplate: (obj?: I18nArgumentType) =>
    translation('Home-CouponExpirationDateLabelWithTemplate', obj),
  /* 有効期限：なし */
  homeCouponExpirationDateNoneLabel: (obj?: I18nArgumentType) =>
    translation('Home-CouponExpirationDateNoneLabel', obj),
  /* クーポン */
  homeCouponLabel: (obj?: I18nArgumentType) => translation('Home-CouponLabel', obj),
  /* 最大{{discountPercent}}%引き */
  homeCouponMaxDiscountPercent: (obj?: I18nArgumentType) =>
    translation('Home-CouponMaxDiscountPercent', obj),
  /* 最大{{discount}}円引き */
  homeCouponMaxDiscountYen: (obj?: I18nArgumentType) =>
    translation('Home-CouponMaxDiscountYen', obj),
  /* 訪日60日以前にSIMを申し込みいただいた方に
500MBを追加でプレゼントしております。
さらに宿泊予約で追加容量を獲得できますので、
ホテル検索もご覧ください。 */
  homeEarlySimRequestCampaignModalMessage: (obj?: I18nArgumentType) =>
    translation('Home-EarlySimRequestCampaignModalMessage', obj),
  /* おめでとうございます！ */
  homeEarlySimRequestCampaignModalTitle: (obj?: I18nArgumentType) =>
    translation('Home-EarlySimRequestCampaignModalTitle', obj),
  /* 無料SIMを受け取る */
  homeGetFreeSim: (obj?: I18nArgumentType) => translation('Home-GetFreeSim', obj),
  /* 羽田空港特別キャンペーン */
  homeHndCampaignBannerTitle: (obj?: I18nArgumentType) =>
    translation('Home-HndCampaignBannerTitle', obj),
  /* 高速通信を有効化しよう！ */
  homeInformationBannerActivateSim: (obj?: I18nArgumentType) =>
    translation('Home-InformationBannerActivateSim', obj),
  /* 到着予定日変更 */
  homeInformationBannerChangeItinerary: (obj?: I18nArgumentType) =>
    translation('Home-InformationBannerChangeItinerary', obj),
  /* SIMを受け取ろう！ */
  homeInformationBannerReceiveSim: (obj?: I18nArgumentType) =>
    translation('Home-InformationBannerReceiveSim', obj),
  /* SIMを申し込もう！ */
  homeInformationBannerRequestSim: (obj?: I18nArgumentType) =>
    translation('Home-InformationBannerRequestSim', obj),
  /* 日本旅行まであと{{daysToGo}}日 */
  homeInformationBannerXdaysToGoMessage: (obj?: I18nArgumentType) =>
    translation('Home-InformationBannerXdaysToGoMessage', obj),
  /* もっとみる */
  homeMediaPickupMore: (obj?: I18nArgumentType) => translation('Home-MediaPickupMore', obj),
  /* ピックアップ */
  homeMediaPickupTitle: (obj?: I18nArgumentType) => translation('Home-MediaPickupTitle', obj),
  /* 3月26日までの期間限定販売 */
  homeRailTicketBannerLabel: (obj?: I18nArgumentType) =>
    translation('Home-RailTicketBannerLabel', obj),
  /* 体験 */
  homeServiceLinkActivity: (obj?: I18nArgumentType) => translation('Home-ServiceLinkActivity', obj),
  /* 日本でしか体験できないアクティビティをお得に、便利に。 */
  homeServiceLinkDescriptionActivity: (obj?: I18nArgumentType) =>
    translation('Home-ServiceLinkDescriptionActivity', obj),
  /* オンラインで欲しい物を探して、免税価格で購入予約。その商品を出国直前に空港で受け取ることができるサービスです。 */
  homeServiceLinkDescriptionKaimono: (obj?: I18nArgumentType) =>
    translation('Home-ServiceLinkDescriptionKaimono', obj),
  /* 選べる夕食、お迎え手配、貸切風呂予約であなたの温泉旅行をより贅沢、より快適に。 */
  homeServiceLinkDescriptionOnsen: (obj?: I18nArgumentType) =>
    translation('Home-ServiceLinkDescriptionOnsen', obj),
  /* 宿予約やリフト券・レッスン・保険の購入など、日本スキー旅行の全てが揃う！ */
  homeServiceLinkDescriptionSnow: (obj?: I18nArgumentType) =>
    translation('Home-ServiceLinkDescriptionSnow', obj),
  /* WAmazingで交通チケットを事前に購入すれば受け取りもスムーズ、浮いた時間で日本旅行をお楽しみください。 */
  homeServiceLinkDescriptionTicket: (obj?: I18nArgumentType) =>
    translation('Home-ServiceLinkDescriptionTicket', obj),
  /* 免税 */
  homeServiceLinkKaimono: (obj?: I18nArgumentType) => translation('Home-ServiceLinkKaimono', obj),
  /* 温泉 */
  homeServiceLinkOnsen: (obj?: I18nArgumentType) => translation('Home-ServiceLinkOnsen', obj),
  /* スキー */
  homeServiceLinkSnow: (obj?: I18nArgumentType) => translation('Home-ServiceLinkSnow', obj),
  /* 交通 */
  homeServiceLinkTicket: (obj?: I18nArgumentType) => translation('Home-ServiceLinkTicket', obj),
  /* アクティビティ予約 */
  homeServiceLinkTitleActivity: (obj?: I18nArgumentType) =>
    translation('Home-ServiceLinkTitleActivity', obj),
  /* オンライン免税店 */
  homeServiceLinkTitleKaimono: (obj?: I18nArgumentType) =>
    translation('Home-ServiceLinkTitleKaimono', obj),
  /* 温泉旅館・ホテル予約 */
  homeServiceLinkTitleOnsen: (obj?: I18nArgumentType) =>
    translation('Home-ServiceLinkTitleOnsen', obj),
  /* ホテル予約・スノーアクティビティ購入 */
  homeServiceLinkTitleSnow: (obj?: I18nArgumentType) =>
    translation('Home-ServiceLinkTitleSnow', obj),
  /* 交通チケット購入 */
  homeServiceLinkTitleTicket: (obj?: I18nArgumentType) =>
    translation('Home-ServiceLinkTitleTicket', obj),
  /* 使い放題SIMがもらえる!! */
  homeSimCapacityInfinityPhraseEmphasized: (obj?: I18nArgumentType) =>
    translation('Home-SimCapacityInfinityPhraseEmphasized', obj),
  /* ホテル予約で */
  homeSimCapacityInfinityPhrasePrefix: (obj?: I18nArgumentType) =>
    translation('Home-SimCapacityInfinityPhrasePrefix', obj),
  /* WAmazing 編集部 */
  homeYataiAuthorLabel: (obj?: I18nArgumentType) => translation('Home-YataiAuthorLabel', obj),
  /* すべて見る */
  homeYataiItemSeeAll: (obj?: I18nArgumentType) => translation('Home-YataiItemSeeAll', obj),
  /* Airport Special Edition */
  homeYataiItemTitle: (obj?: I18nArgumentType) => translation('Home-YataiItemTitle', obj),
  /* 最新のお知らせがありません */
  inAppNewsEmpty: (obj?: I18nArgumentType) => translation('InAppNews-Empty', obj),
  /* お知らせが見つかりませんでした */
  inAppNewsNotFound: (obj?: I18nArgumentType) => translation('InAppNews-NotFound', obj),
  /* お知らせ */
  inAppNewsTitle: (obj?: I18nArgumentType) => translation('InAppNews-Title', obj),
  /* 保险购买记录 */
  insuranceBookingHistoryHeaderLabel: (obj?: I18nArgumentType) =>
    translation('Insurance-BookingHistoryHeaderLabel', obj),
  /* 買い物購入履歴 */
  kaimonoPurchaseHistoryHeaderLabel: (obj?: I18nArgumentType) =>
    translation('Kaimono-PurchaseHistoryHeaderLabel', obj),
  /* 広島、岡山、山口の人気観光地の定期観光バス */
  lPActivityKankouBus: (obj?: I18nArgumentType) => translation('LP-ActivityKankouBus', obj),
  /* 定番必見の観光地でも、交通しにくい観光スポットでも、定期観光バスと鉄道パスで気軽に超お得に旅行を楽しむことができます。 */
  lPActivityKankouBusDescription: (obj?: I18nArgumentType) =>
    translation('LP-ActivityKankouBusDescription', obj),
  /* 関西·北陸地方の観光アクティビティー */
  lPActivityKansaiHokuriku: (obj?: I18nArgumentType) =>
    translation('LP-ActivityKansaiHokuriku', obj),
  /* 大阪や京都をはじめ、関西北陸は魅力的な行程が多い。関西、北陸は絶対にいい思い出を作るいいところです！ */
  lPActivityKansaiHokurikuDescription: (obj?: I18nArgumentType) =>
    translation('LP-ActivityKansaiHokurikuDescription', obj),
  /* 関西·北陸地方の観光アクティビティーをチェック */
  lPActivityKansaiHokurikuLink: (obj?: I18nArgumentType) =>
    translation('LP-ActivityKansaiHokurikuLink', obj),
  /* 瀬戸内の観光アクティビティー */
  lPActivitySetouchi: (obj?: I18nArgumentType) => translation('LP-ActivitySetouchi', obj),
  /* 水上活動と自転車旅行などを通じて、美しい水岸都市、最も前衛的な芸術と歴史の長い温泉街を鑑賞します。 */
  lPActivitySetouchiDescription: (obj?: I18nArgumentType) =>
    translation('LP-ActivitySetouchiDescription', obj),
  /* 瀬戸内の観光アクティビティーをチェック */
  lPActivitySetouchiLink: (obj?: I18nArgumentType) => translation('LP-ActivitySetouchiLink', obj),
  /* 予約すると通信容量がプラス */
  lPAdditionalDataByBooking: (obj?: I18nArgumentType) =>
    translation('LP-AdditionalDataByBooking', obj),
  /* 会社情報 */
  lPCompanyInformation: (obj?: I18nArgumentType) => translation('LP-CompanyInformation', obj),
  /* あなたにぴったりなホテルを見つけよう */
  lPDiscoverGreatHotel: (obj?: I18nArgumentType) => translation('LP-DiscoverGreatHotel', obj),
  /* 最安値のホテル予約やアクティビティ予約、無料SIMカードなど、日本に来るならWAmazingを使わないともったいない！ */
  lPDoNotMissOutGreatOffersFromUs: (obj?: I18nArgumentType) =>
    translation('LP-DoNotMissOutGreatOffersFromUs', obj),
  /* 日本旅行のホテル、アクティビティ、無料SIMカード */
  lPEverythingYouNeedToTravelJapan: (obj?: I18nArgumentType) =>
    translation('LP-EverythingYouNeedToTravelJapan', obj),
  /* 日本を楽しみ尽くそう */
  lPExcitingJapan: (obj?: I18nArgumentType) => translation('LP-ExcitingJapan', obj),
  /* 無料SIMカード */
  lPFreeSimCard: (obj?: I18nArgumentType) => translation('LP-FreeSimCard', obj),
  /* ホテル予約 */
  lPHotelBooking: (obj?: I18nArgumentType) => translation('LP-HotelBooking', obj),
  /* 次の日本旅行へ */
  lPNextJapanTravel: (obj?: I18nArgumentType) => translation('LP-NextJapanTravel', obj),
  /* 検索する */
  lPSearch: (obj?: I18nArgumentType) => translation('LP-Search', obj),
  /* 日本,旅行,観光,ホテル予約,旅館予約,アクティビティ予約,無料SIMカード */
  lPSiteKeywords: (obj?: I18nArgumentType) => translation('LP-SiteKeywords', obj),
  /* 東京、京都、広島など、すでに知名度のある都市以外にも、日本には魅力に満ちたエリアがたくさんあります。 */
  lPThereAreManyAttractiveAreaInJapan: (obj?: I18nArgumentType) =>
    translation('LP-ThereAreManyAttractiveAreaInJapan', obj),
  /* 交通チケット */
  lPTicketBooking: (obj?: I18nArgumentType) => translation('LP-TicketBooking', obj),
  /* 様々なきっかけを作り出し、驚きと感動に満ちた出会いを提供することでヒトとコト、ヒトとヒトを繋ぎます。 */
  lPWeConnectPeopleToThingsAndPeopleToPeople: (obj?: I18nArgumentType) =>
    translation('LP-WeConnectPeopleToThingsAndPeopleToPeople', obj),
  /* 私たちは、日本の魅力のある産業や文化を掘り起こし、可能性を磨いて、価値を再構築します。 */
  lPWeRebuildValueForJapanTourismIndustry: (obj?: I18nArgumentType) =>
    translation('LP-WeRebuildValueForJapanTourismIndustry', obj),
  /* あなたのために、次の日本旅行を提案するのが WAmazing です。 */
  lPWeSuggestYourNextJapanTravel: (obj?: I18nArgumentType) =>
    translation('LP-WeSuggestYourNextJapanTravel', obj),
  /* ホテル予約の金額に応じて、最大、無制限のデータ容量がもらるので、ホテル宿泊料が実質割引になります。 */
  lPYouWillGetFreeDataUsageAccordingToBookingPrice: (obj?: I18nArgumentType) =>
    translation('LP-YouWillGetFreeDataUsageAccordingToBookingPrice', obj),
  /* ネットワークに接続していません。 */
  mediaBrowserOfflineErrorTitle: (obj?: I18nArgumentType) =>
    translation('MediaBrowser-OfflineErrorTitle', obj),
  /* キャンペーンを実施中！{{month}}月中の予約がおすすめです */
  notificationNoBookingAfterSearchBody: (obj?: I18nArgumentType) =>
    translation('Notification-NoBookingAfterSearchBody', obj),
  /* 早期予約がオトク！ */
  notificationNoBookingAfterSearchTitle: (obj?: I18nArgumentType) =>
    translation('Notification-NoBookingAfterSearchTitle', obj),
  /* 実はWAmazingのホテル予約のほうがオトクかも…！？今すぐチェック！ */
  notificationNoBookingAfterYadoDetailBody: (obj?: I18nArgumentType) =>
    translation('Notification-NoBookingAfterYadoDetailBody', obj),
  /* ホテル予約で使い放題SIMがもらえる！ */
  notificationNoBookingAfterYadoDetailInfinityCampaignBody: (obj?: I18nArgumentType) =>
    translation('Notification-NoBookingAfterYadoDetailInfinityCampaignBody', obj),
  /* 【期間限定！12月31日まで！】 */
  notificationNoBookingAfterYadoDetailInfinityCampaignTitle: (obj?: I18nArgumentType) =>
    translation('Notification-NoBookingAfterYadoDetailInfinityCampaignTitle', obj),
  /* {{date}}に宿泊可能な{{areaName}}のホテルを見る */
  notificationRecommendAroundStayingDayBody: (obj?: I18nArgumentType) =>
    translation('Notification-RecommendAroundStayingDayBody', obj),
  /* 温泉宿予約履歴 */
  onsenBookingHistoryHeaderLabel: (obj?: I18nArgumentType) =>
    translation('Onsen-BookingHistoryHeaderLabel', obj),
  /* 現在地の天気予報を表示するために、位置情報を使用します。 */
  permissionRequireLocationMessage: (obj?: I18nArgumentType) =>
    translation('Permission-RequireLocationMessage', obj),
  /* 位置情報を使用します。 */
  permissionRequireLocationTitle: (obj?: I18nArgumentType) =>
    translation('Permission-RequireLocationTitle', obj),
  /* 日本旅行についてのお得な情報をお届けするために、通知機能を使用します。 */
  permissionRequireNotificationMessage: (obj?: I18nArgumentType) =>
    translation('Permission-RequireNotificationMessage', obj),
  /* 通知機能を使用します。 */
  permissionRequireNotificationTitle: (obj?: I18nArgumentType) =>
    translation('Permission-RequireNotificationTitle', obj),
  /* ホテルを予約して無制限SIMカードをゲット！ */
  popularAreaDescription: (obj?: I18nArgumentType) => translation('PopularArea-Description', obj),
  /* 神戸 */
  popularAreaLocationKobe: (obj?: I18nArgumentType) => translation('PopularArea-LocationKobe', obj),
  /* 京都 */
  popularAreaLocationKyoto: (obj?: I18nArgumentType) =>
    translation('PopularArea-LocationKyoto', obj),
  /* 難波 */
  popularAreaLocationNamba: (obj?: I18nArgumentType) =>
    translation('PopularArea-LocationNamba', obj),
  /* 奈良 */
  popularAreaLocationNara: (obj?: I18nArgumentType) => translation('PopularArea-LocationNara', obj),
  /* 大阪 */
  popularAreaLocationOsaka: (obj?: I18nArgumentType) =>
    translation('PopularArea-LocationOsaka', obj),
  /* 新宿 */
  popularAreaLocationShinjuku: (obj?: I18nArgumentType) =>
    translation('PopularArea-LocationShinjuku', obj),
  /* 天王寺 */
  popularAreaLocationTennoji: (obj?: I18nArgumentType) =>
    translation('PopularArea-LocationTennoji', obj),
  /* 東京 */
  popularAreaLocationTokyo: (obj?: I18nArgumentType) =>
    translation('PopularArea-LocationTokyo', obj),
  /* 上野 */
  popularAreaLocationUeno: (obj?: I18nArgumentType) => translation('PopularArea-LocationUeno', obj),
  /* 人気のエリア */
  popularAreaTitle: (obj?: I18nArgumentType) => translation('PopularArea-Title', obj),
  /* Email：{{email}} */
  profileAccountSectionDescription: (obj?: I18nArgumentType) =>
    translation('Profile-AccountSectionDescription', obj),
  /* ID：{{userId}} */
  profileAccountSectionUserId: (obj?: I18nArgumentType) =>
    translation('Profile-AccountSectionUserId', obj),
  /* SIM受取日・日本出国日の変更 */
  profileChangeItineraryRowTitle: (obj?: I18nArgumentType) =>
    translation('Profile-ChangeItineraryRowTitle', obj),
  /* SIM受取日の変更 */
  profileChangeItineraryTitle: (obj?: I18nArgumentType) =>
    translation('Profile-ChangeItineraryTitle', obj),
  /* {{card}} を削除しますか？ */
  profileCreditCardDeleteConfirmationMessage: (obj?: I18nArgumentType) =>
    translation('Profile-CreditCardDeleteConfirmationMessage', obj),
  /* 新規追加 */
  profileEditCreditCardAddButtonTitle: (obj?: I18nArgumentType) =>
    translation('Profile-EditCreditCardAddButtonTitle', obj),
  /* 削除する */
  profileEditCreditCardDeleteButtonTitle: (obj?: I18nArgumentType) =>
    translation('Profile-EditCreditCardDeleteButtonTitle', obj),
  /* 有効期限 */
  profileEditCreditCardExpirationDateLabelText: (obj?: I18nArgumentType) =>
    translation('Profile-EditCreditCardExpirationDateLabelText', obj),
  /* 決済で使用するクレジットカードを
変更することができます */
  profileEditCreditCardExplanationText: (obj?: I18nArgumentType) =>
    translation('Profile-EditCreditCardExplanationText', obj),
  /* 下4桁 */
  profileEditCreditCardLastFourDigitsLabelText: (obj?: I18nArgumentType) =>
    translation('Profile-EditCreditCardLastFourDigitsLabelText', obj),
  /* 使用する */
  profileEditCreditCardSwitchButtonTitle: (obj?: I18nArgumentType) =>
    translation('Profile-EditCreditCardSwitchButtonTitle', obj),
  /* よくある質問 */
  profileFAQRowTitle: (obj?: I18nArgumentType) => translation('Profile-FAQRowTitle', obj),
  /* お問い合わせ内容・ご意見をご記入ください */
  profileFeedbackCommentInputLabel: (obj?: I18nArgumentType) =>
    translation('Profile-FeedbackCommentInputLabel', obj),
  /* メールアドレス */
  profileFeedbackEmailInputLabel: (obj?: I18nArgumentType) =>
    translation('Profile-FeedbackEmailInputLabel', obj),
  /* 氏名 */
  profileFeedbackNameInputLabel: (obj?: I18nArgumentType) =>
    translation('Profile-FeedbackNameInputLabel', obj),
  /* お問い合わせ・ご意見 */
  profileFeedbackRowTitle: (obj?: I18nArgumentType) => translation('Profile-FeedbackRowTitle', obj),
  /* 送信 */
  profileFeedbackSubmitZendeskButtonTitle: (obj?: I18nArgumentType) =>
    translation('Profile-FeedbackSubmitZendeskButtonTitle', obj),
  /* 言語の設定を反映させるため、アプリを再起動します。 */
  profileLanguageChangesAlertMessage: (obj?: I18nArgumentType) =>
    translation('Profile-LanguageChangesAlertMessage', obj),
  /* 言語 */
  profileLanguageRowTitle: (obj?: I18nArgumentType) => translation('Profile-LanguageRowTitle', obj),
  /* 英語 */
  profileLocaleTypeEn: (obj?: I18nArgumentType) => translation('Profile-LocaleTypeEn', obj),
  /* 日本語 */
  profileLocaleTypeJa: (obj?: I18nArgumentType) => translation('Profile-LocaleTypeJa', obj),
  /* 中国語（簡体字） */
  profileLocaleTypeZhCn: (obj?: I18nArgumentType) => translation('Profile-LocaleTypeZhCn', obj),
  /* 中国語（繁体字、香港） */
  profileLocaleTypeZhHk: (obj?: I18nArgumentType) => translation('Profile-LocaleTypeZhHk', obj),
  /* 中国語（繁体字、台湾） */
  profileLocaleTypeZhTw: (obj?: I18nArgumentType) => translation('Profile-LocaleTypeZhTw', obj),
  /* プライバシーポリシー */
  profilePrivacyPolicyRowTitle: (obj?: I18nArgumentType) =>
    translation('Profile-PrivacyPolicyRowTitle', obj),
  /* この機能を利用するためには
ユーザー登録(無料)が必要です。 */
  profileRequireRegistration: (obj?: I18nArgumentType) =>
    translation('Profile-RequireRegistration', obj),
  /* 登録されたメールアドレスにパスワードリセットのメールを送信します */
  profileResetPasswordAlertMessage: (obj?: I18nArgumentType) =>
    translation('Profile-ResetPasswordAlertMessage', obj),
  /* パスワードリセット */
  profileResetPasswordRowTitle: (obj?: I18nArgumentType) =>
    translation('Profile-ResetPasswordRowTitle', obj),
  /* APNの再設定 */
  profileResettingApnTitle: (obj?: I18nArgumentType) =>
    translation('Profile-ResettingApnTitle', obj),
  /* WAmazingアプリからログアウトします。ログアウトしてよろしいでしょうか？ */
  profileSignOutConfirmationAlertMessage: (obj?: I18nArgumentType) =>
    translation('Profile-SignOutConfirmationAlertMessage', obj),
  /* ログアウト */
  profileSignOutRowTitle: (obj?: I18nArgumentType) => translation('Profile-SignOutRowTitle', obj),
  /* 購入金額 */
  profileSimPurchaseHistoryPriceLabelText: (obj?: I18nArgumentType) =>
    translation('Profile-SimPurchaseHistoryPriceLabelText', obj),
  /* 購入日 */
  profileSimPurchaseHistoryPurchaseDateLabelText: (obj?: I18nArgumentType) =>
    translation('Profile-SimPurchaseHistoryPurchaseDateLabelText', obj),
  /* クレジットカードの管理 */
  profileSwitchCreditCardRowTitle: (obj?: I18nArgumentType) =>
    translation('Profile-SwitchCreditCardRowTitle', obj),
  /* 会員利用規約 */
  profileTermsRowTitle: (obj?: I18nArgumentType) => translation('Profile-TermsRowTitle', obj),
  /* ホテルを予約すると使い放題SIMがもらえる！ */
  profileTopYadoAppealLabelText: (obj?: I18nArgumentType) =>
    translation('Profile-TopYadoAppealLabelText', obj),
  /* 再読込 */
  railTIcketBookingDetailReloadButton: (obj?: I18nArgumentType) =>
    translation('RailTIcket-BookingDetailReloadButton', obj),
  /* チケットの購入履歴はございません。 */
  railTIcketBookingHistoryNoBooking: (obj?: I18nArgumentType) =>
    translation('RailTIcket-BookingHistoryNoBooking', obj),
  /* 以下のQRコードを指定のチケットカウンターでスキャンし、チケットを受け取ってください。 */
  railTIcketBookingThanksScanQRCode: (obj?: I18nArgumentType) =>
    translation('RailTIcket-BookingThanksScanQRCode', obj),
  /* チケット枚数 */
  railTIcketCommonQuantityAbbriviation: (obj?: I18nArgumentType) =>
    translation('RailTIcket-CommonQuantityAbbriviation', obj),
  /* 本当にキャンセルしますか？ */
  railTicketAreYouSureToCancel: (obj?: I18nArgumentType) =>
    translation('RailTicket-AreYouSureToCancel', obj),
  /* 購入情報確認 */
  railTicketBookingConfirmScreenHeaderTitle: (obj?: I18nArgumentType) =>
    translation('RailTicket-BookingConfirmScreenHeaderTitle', obj),
  /* 大人料金のチケットのみ注文できます。本アプリでは、子供料金のチケットは注文いただけません。 */
  railTicketBookingConfirmScreenOnlyAdults: (obj?: I18nArgumentType) =>
    translation('RailTicket-BookingConfirmScreenOnlyAdults', obj),
  /* {{arrangedTravelCondition}}及び{{arrangedTravelAgreement}}をお読みになった上でチェックボックスにチェックをいれてください。 */
  railTicketBookingConfirmValidationAgreement: (obj?: I18nArgumentType) =>
    translation('RailTicket-BookingConfirmValidationAgreement', obj),
  /* クレジットカードを登録してください。 */
  railTicketBookingConfirmValidationCard: (obj?: I18nArgumentType) =>
    translation('RailTicket-BookingConfirmValidationCard', obj),
  /* キャンセル条件をお読みになった上で、チェックボックスにチェックをいれてください。 */
  railTicketBookingConfirmValidationNoCancel: (obj?: I18nArgumentType) =>
    translation('RailTicket-BookingConfirmValidationNoCancel', obj),
  /* 注意事項をお読みになった上で、チェックボックスにチェックをいれてください。 */
  railTicketBookingConfirmValidationUnderstanding: (obj?: I18nArgumentType) =>
    translation('RailTicket-BookingConfirmValidationUnderstanding', obj),
  /* キャンセル条件を確認し、理解しました。 */
  railTicketBookingConfirmationConfirmAgreeToTermsLabelNoCancel: (obj?: I18nArgumentType) =>
    translation('RailTicket-BookingConfirmationConfirmAgreeToTermsLabelNoCancel', obj),
  /* {{arrangedTravelCondition}}及び、{{arrangedTravelAgreement}} に同意します。 */
  railTicketBookingConfirmationConfirmAgreeToTermsLabelTerms: (obj?: I18nArgumentType) =>
    translation('RailTicket-BookingConfirmationConfirmAgreeToTermsLabelTerms', obj),
  /* 注意事項を確認し、理解しました。 */
  railTicketBookingConfirmationConfirmAgreeToTermsLabelUnderstanding: (obj?: I18nArgumentType) =>
    translation('RailTicket-BookingConfirmationConfirmAgreeToTermsLabelUnderstanding', obj),
  /* キャンセル */
  railTicketBookingDetailAboutCancellation: (obj?: I18nArgumentType) =>
    translation('RailTicket-BookingDetailAboutCancellation', obj),
  /* キャンセルする */
  railTicketBookingDetailCancellationButton: (obj?: I18nArgumentType) =>
    translation('RailTicket-BookingDetailCancellationButton', obj),
  /* キャンセルのためには、インターネットに接続後再度下のボタンから再読込をしてください。 */
  railTicketBookingDetailPleaseReloadAgain: (obj?: I18nArgumentType) =>
    translation('RailTicket-BookingDetailPleaseReloadAgain', obj),
  /* 更新 */
  railTicketBookingDetailReloadButton: (obj?: I18nArgumentType) =>
    translation('RailTicket-BookingDetailReloadButton', obj),
  /* 詳細を見る */
  railTicketBookingHistoryCheckDetail: (obj?: I18nArgumentType) =>
    translation('RailTicket-BookingHistoryCheckDetail', obj),
  /* チケット購入履歴 */
  railTicketBookingHistoryHeaderLabel: (obj?: I18nArgumentType) =>
    translation('RailTicket-BookingHistoryHeaderLabel', obj),
  /* 購入したチケットはございません。 */
  railTicketBookingHistoryNoBooking: (obj?: I18nArgumentType) =>
    translation('RailTicket-BookingHistoryNoBooking', obj),
  /* 購入する */
  railTicketBookingScreenHeaderTitle: (obj?: I18nArgumentType) =>
    translation('RailTicket-BookingScreenHeaderTitle', obj),
  /* 料金は次のページで表示されます */
  railTicketBookingScreenPriceWillBeDisplayedLater: (obj?: I18nArgumentType) =>
    translation('RailTicket-BookingScreenPriceWillBeDisplayedLater', obj),
  /* 購入情報確認に進む */
  railTicketBookingScreenProceedToConfirm: (obj?: I18nArgumentType) =>
    translation('RailTicket-BookingScreenProceedToConfirm', obj),
  /* 「利用開始日」を入力するには「チケット受取日」を入力してください。 */
  railTicketBookingScreenStartDateIsDisabled: (obj?: I18nArgumentType) =>
    translation('RailTicket-BookingScreenStartDateIsDisabled', obj),
  /* TOPに戻る */
  railTicketBookingThanksBackToTop: (obj?: I18nArgumentType) =>
    translation('RailTicket-BookingThanksBackToTop', obj),
  /* {{planName}}の購入が完了しました。詳細はお客様のメールアドレスに送信致しました。 */
  railTicketBookingThanksFirstBody: (obj?: I18nArgumentType) =>
    translation('RailTicket-BookingThanksFirstBody', obj),
  /* 購入完了 */
  railTicketBookingThanksHeaderLabel: (obj?: I18nArgumentType) =>
    translation('RailTicket-BookingThanksHeaderLabel', obj),
  /* 下のQRコードを受取窓口にてスキャンすることでチケットを取得できます。 */
  railTicketBookingThanksScanQRCode: (obj?: I18nArgumentType) =>
    translation('RailTicket-BookingThanksScanQRCode', obj),
  /* 尚、上記QRコードは「トップに戻る」をタップした後、「アカウント」タブに移動し、「チケット購入履歴」をタップしていただけると再度確認が可能です。 */
  railTicketBookingThanksSecondBody: (obj?: I18nArgumentType) =>
    translation('RailTicket-BookingThanksSecondBody', obj),
  /* 大人 */
  railTicketCommonAdults: (obj?: I18nArgumentType) => translation('RailTicket-CommonAdults', obj),
  /* キャンセル済み */
  railTicketCommonCanceled: (obj?: I18nArgumentType) =>
    translation('RailTicket-CommonCanceled', obj),
  /* キャンセルポリシー */
  railTicketCommonCancellationPolicy: (obj?: I18nArgumentType) =>
    translation('RailTicket-CommonCancellationPolicy', obj),
  /* WeChatログインアカウント以外のアカウントからご購入可能になります。 */
  railTicketCommonCantProceedWithWeChatSession: (obj?: I18nArgumentType) =>
    translation('RailTicket-CommonCantProceedWithWeChatSession', obj),
  /* チケット購入履歴 */
  railTicketCommonMyTickets: (obj?: I18nArgumentType) =>
    translation('RailTicket-CommonMyTickets', obj),
  /* いいえ */
  railTicketCommonNo: (obj?: I18nArgumentType) => translation('RailTicket-CommonNo', obj),
  /* 注意事項 */
  railTicketCommonNotices: (obj?: I18nArgumentType) => translation('RailTicket-CommonNotices', obj),
  /* 購入日時 */
  railTicketCommonOrderDate: (obj?: I18nArgumentType) =>
    translation('RailTicket-CommonOrderDate', obj),
  /* YYYY/MM/DD */
  railTicketCommonOrderDateFormat: (obj?: I18nArgumentType) =>
    translation('RailTicket-CommonOrderDateFormat', obj),
  /* D MMM YYYY hh:mm aa */
  railTicketCommonOrderDateFormatWithHM: (obj?: I18nArgumentType) =>
    translation('RailTicket-CommonOrderDateFormatWithHM', obj),
  /* {{date}} (日本時間) */
  railTicketCommonOrderDateWithJST: (obj?: I18nArgumentType) =>
    translation('RailTicket-CommonOrderDateWithJST', obj),
  /* 購入ID */
  railTicketCommonOrderId: (obj?: I18nArgumentType) => translation('RailTicket-CommonOrderId', obj),
  /* 受取日時 */
  railTicketCommonPickupDate: (obj?: I18nArgumentType) =>
    translation('RailTicket-CommonPickupDate', obj),
  /* 選択してください */
  railTicketCommonPleaseSelectOne: (obj?: I18nArgumentType) =>
    translation('RailTicket-CommonPleaseSelectOne', obj),
  /* 購入結果の取得に失敗しました。チケット購入履歴より、購入結果を確認してください。 */
  railTicketCommonPossiblyDuplicateError: (obj?: I18nArgumentType) =>
    translation('RailTicket-CommonPossiblyDuplicateError', obj),
  /* 購入 */
  railTicketCommonPurchase: (obj?: I18nArgumentType) =>
    translation('RailTicket-CommonPurchase', obj),
  /* チケット枚数 */
  railTicketCommonQuantityAbbriviation: (obj?: I18nArgumentType) =>
    translation('RailTicket-CommonQuantityAbbriviation', obj),
  /* チケット受取日 */
  railTicketCommonReceiveDate: (obj?: I18nArgumentType) =>
    translation('RailTicket-CommonReceiveDate', obj),
  /* 利用開始日 */
  railTicketCommonStartDate: (obj?: I18nArgumentType) =>
    translation('RailTicket-CommonStartDate', obj),
  /* 「利用開始日」を入力してください。 */
  railTicketCommonStartUsingFromRequired: (obj?: I18nArgumentType) =>
    translation('RailTicket-CommonStartUsingFromRequired', obj),
  /* 「チケット受取場所」を入力してください。 */
  railTicketCommonTicketCounterRequired: (obj?: I18nArgumentType) =>
    translation('RailTicket-CommonTicketCounterRequired', obj),
  /* チケット情報 */
  railTicketCommonTicketInfo: (obj?: I18nArgumentType) =>
    translation('RailTicket-CommonTicketInfo', obj),
  /* チケット名 */
  railTicketCommonTicketName: (obj?: I18nArgumentType) =>
    translation('RailTicket-CommonTicketName', obj),
  /* 「チケット受取日」を入力してください。 */
  railTicketCommonTicketPickupDateRequired: (obj?: I18nArgumentType) =>
    translation('RailTicket-CommonTicketPickupDateRequired', obj),
  /* 更新 */
  railTicketCommonUpgrade: (obj?: I18nArgumentType) => translation('RailTicket-CommonUpgrade', obj),
  /* 有効期限 */
  railTicketCommonValidityPeriod: (obj?: I18nArgumentType) =>
    translation('RailTicket-CommonValidityPeriod', obj),
  /* チケット受取場所 */
  railTicketCommonWhereToReceive: (obj?: I18nArgumentType) =>
    translation('RailTicket-CommonWhereToReceive', obj),
  /* はい */
  railTicketCommonYes: (obj?: I18nArgumentType) => translation('RailTicket-CommonYes', obj),
  /* JRチケットの販売窓口とは場所が異なります。ご注意ください。 */
  railTicketCounterMapNotice: (obj?: I18nArgumentType) =>
    translation('RailTicket-CounterMapNotice', obj),
  /* 成田空港 第1ターミナル */
  railTicketCounterMapTitleNrtT1: (obj?: I18nArgumentType) =>
    translation('RailTicket-CounterMapTitleNrtT1', obj),
  /* 成田空港 第1ターミナル */
  railTicketCounterMapTitleNrtT2: (obj?: I18nArgumentType) =>
    translation('RailTicket-CounterMapTitleNrtT2', obj),
  /* JR Kansai Area Pass */
  railTicketKansaiAreaPass: (obj?: I18nArgumentType) =>
    translation('RailTicket-KansaiAreaPass', obj),
  /* JR Kansai Wide Area Pass */
  railTicketKansaiWideAreaPass: (obj?: I18nArgumentType) =>
    translation('RailTicket-KansaiWideAreaPass', obj),
  /* 国籍 */
  railTicketNationality: (obj?: I18nArgumentType) => translation('RailTicket-Nationality', obj),
  /* チケット料金 */
  railTicketPrice: (obj?: I18nArgumentType) => translation('RailTicket-Price', obj),
  /* 受取窓口にて次のQRコードと、ご利用者全員分のパスポートをご提示ください。 */
  railTicketQRBodyText: (obj?: I18nArgumentType) => translation('RailTicket-QRBodyText', obj),
  /* 機械で読み取りにくい場合は画面を明るくしてください。 */
  railTicketQRBodyText2: (obj?: I18nArgumentType) => translation('RailTicket-QRBodyText2', obj),
  /* ご注文頂いた商品及び受け取り方法についてのご質問は、アプリ内の「ご意見、お問い合わせ」メニューよりお問い合わせくださいませ。JR EAST Travel Service Centerの販売窓口ではご回答できません。 */
  railTicketQRBodyText3: (obj?: I18nArgumentType) => translation('RailTicket-QRBodyText3', obj),
  /* 受取方法 */
  railTicketQRHowToGet: (obj?: I18nArgumentType) => translation('RailTicket-QRHowToGet', obj),
  /* この注文はキャンセル期限を過ぎているため、キャンセルできません。 */
  railTicketSorryWeCannotProceedYourCancellation: (obj?: I18nArgumentType) =>
    translation('RailTicket-SorryWeCannotProceedYourCancellation', obj),
  /* (税込) */
  railTicketTaxIncluded: (obj?: I18nArgumentType) => translation('RailTicket-TaxIncluded', obj),
  /* こちらの注文はキャンセルされました。 */
  railTicketThisOrderIsAlreadyCancelled: (obj?: I18nArgumentType) =>
    translation('RailTicket-ThisOrderIsAlreadyCancelled', obj),
  /* こちらのチケットのご購入には、WAmazingアプリののアップデートが必要になります。 */
  railTicketTicketDetailNeedUpgradeError: (obj?: I18nArgumentType) =>
    translation('RailTicket-TicketDetailNeedUpgradeError', obj),
  /* ネットワークでエラーが起きました。 */
  railTicketTicketDetailNetworkError: (obj?: I18nArgumentType) =>
    translation('RailTicket-TicketDetailNetworkError', obj),
  /*  */
  railTicketTicketDetailWechatProhibited: (obj?: I18nArgumentType) =>
    translation('RailTicket-TicketDetailWechatProhibited', obj),
  /* 購入枚数 */
  railTicketTicketQuantity: (obj?: I18nArgumentType) =>
    translation('RailTicket-TicketQuantity', obj),
  /* JR TOKYO Wide Pass */
  railTicketTokyoWidePass: (obj?: I18nArgumentType) => translation('RailTicket-TokyoWidePass', obj),
  /* 3日間 */
  railTicketTokyoWidePassValidityPeriod: (obj?: I18nArgumentType) =>
    translation('RailTicket-TokyoWidePassValidityPeriod', obj),
  /* 総額 */
  railTicketTotalPrice: (obj?: I18nArgumentType) => translation('RailTicket-TotalPrice', obj),
  /* チケット受取場所 */
  railTicketWhereToReceive: (obj?: I18nArgumentType) =>
    translation('RailTicket-WhereToReceive', obj),
  /* ................ */
  railTicketConfirmTermsAndPolocies: (obj?: I18nArgumentType) =>
    translation('RailTicket-confirmTermsAndPolocies', obj),
  /* 歳以上 */
  sevenAgeOver: (obj?: I18nArgumentType) => translation('Seven-AgeOver', obj),
  /* 年代 */
  sevenAgeRange: (obj?: I18nArgumentType) => translation('Seven-AgeRange', obj),
  /* 歳 */
  sevenAgeUnit: (obj?: I18nArgumentType) => translation('Seven-AgeUnit', obj),
  /* アンケートに答える */
  sevenAnswerQuestionary: (obj?: I18nArgumentType) => translation('Seven-AnswerQuestionary', obj),
  /* 年代と性別を入力して下さい */
  sevenBadRequestError: (obj?: I18nArgumentType) => translation('Seven-BadRequestError', obj),
  /* すでに引換券は発行済です */
  sevenConflictedRequest: (obj?: I18nArgumentType) => translation('Seven-ConflictedRequest', obj),
  /* American Express */
  sevenCreditCardAmex: (obj?: I18nArgumentType) => translation('Seven-CreditCardAmex', obj),
  /* Diners Club */
  sevenCreditCardDiners: (obj?: I18nArgumentType) => translation('Seven-CreditCardDiners', obj),
  /* JCB */
  sevenCreditCardJCB: (obj?: I18nArgumentType) => translation('Seven-CreditCardJCB', obj),
  /* Mastercard */
  sevenCreditCardMastercard: (obj?: I18nArgumentType) =>
    translation('Seven-CreditCardMastercard', obj),
  /* Other credit card */
  sevenCreditCardOther: (obj?: I18nArgumentType) => translation('Seven-CreditCardOther', obj),
  /* UnionPay */
  sevenCreditCardUnionPay: (obj?: I18nArgumentType) => translation('Seven-CreditCardUnionPay', obj),
  /* Visa */
  sevenCreditCardVisa: (obj?: I18nArgumentType) => translation('Seven-CreditCardVisa', obj),
  /* 性別 */
  sevenGender: (obj?: I18nArgumentType) => translation('Seven-Gender', obj),
  /* 女 */
  sevenGenderFemale: (obj?: I18nArgumentType) => translation('Seven-GenderFemale', obj),
  /* クーポンをゲット */
  sevenGetVoucher: (obj?: I18nArgumentType) => translation('Seven-GetVoucher', obj),
  /* ※クーポンはマイページ内でも確認が出来ます。 */
  sevenGetVoucherDescription: (obj?: I18nArgumentType) =>
    translation('Seven-GetVoucherDescription', obj),
  /* 無料でクーポンをゲット！ */
  sevenGetVoucherForFree: (obj?: I18nArgumentType) => translation('Seven-GetVoucherForFree', obj),
  /* 「ほろよい微醉」免費兌換券！ */
  sevenGoGetVoucher: (obj?: I18nArgumentType) => translation('Seven-GoGetVoucher', obj),
  /* 「ほろよい」の楽しみ方 */
  sevenHowToDrink: (obj?: I18nArgumentType) => translation('Seven-HowToDrink', obj),
  /* 今回引き換えた「ほろよい」の飲み方・楽しみ方を教えてください。 */
  sevenHowToDrinkDescription: (obj?: I18nArgumentType) =>
    translation('Seven-HowToDrinkDescription', obj),
  /* お土産（家族・友人等、自分以外の人に渡す）として飲んでもらう */
  sevenHowToDrinkGift: (obj?: I18nArgumentType) => translation('Seven-HowToDrinkGift', obj),
  /* お土産（自分用）として、帰国後に飲む */
  sevenHowToDrinkGiftForMe: (obj?: I18nArgumentType) =>
    translation('Seven-HowToDrinkGiftForMe', obj),
  /* 日本での滞在中に飲む */
  sevenHowToDrinkInJapan: (obj?: I18nArgumentType) => translation('Seven-HowToDrinkInJapan', obj),
  /* その他（自由記入：30文字以内） */
  sevenHowToDrinkOther: (obj?: I18nArgumentType) => translation('Seven-HowToDrinkOther', obj),
  /* 当商品をカウンターへ持ってゆきます */
  sevenHowToUseStepOne: (obj?: I18nArgumentType) => translation('Seven-HowToUseStepOne', obj),
  /* 上記引換券を店員に見せます */
  sevenHowToUseStepTwo: (obj?: I18nArgumentType) => translation('Seven-HowToUseStepTwo', obj),
  /* 無料引換券の利用方法 */
  sevenHowToUseVoucher: (obj?: I18nArgumentType) => translation('Seven-HowToUseVoucher', obj),
  /* メーカー：サントリー */
  sevenMadeIn: (obj?: I18nArgumentType) => translation('Seven-MadeIn', obj),
  /* 日本のセブンイレブンで交換ができます */
  sevenMidLabel: (obj?: I18nArgumentType) => translation('Seven-MidLabel', obj),
  /* アンケートに答えて引換券をゲット */
  sevenMoveToQuestionary: (obj?: I18nArgumentType) => translation('Seven-MoveToQuestionary', obj),
  /* ・未成年者の飲酒は法律で禁じられています。日本の法定飲酒年齢は20歳以上です。台湾と香港では、18歳以上であれば飲酒が認められます。しかし、外国人であっても、日本滞在中は、日本の法律が適用されます。
・本キャンペーンはセブンイレブン・ジャパンとサントリーとWAmazingの共同キャンペーンとなります。
・「ほろよい」無料引換券を受け取るためには、「WAmazing」アプリをインストール・会員登録を済ませた後、アンケートをお答え下さい。
・参加する為には台湾並びに香港のアプリストアから「WAmazing」アプリをダウンロードして下さい
・本キャンペーンに参加するためには、アプリを最新版にアップデートしてください
・「ほろよい」無料引換券は、日本時間の2019/3/29（金）-2019/4/26（金）にかけて受取が可能です。
・「ほろよい」無料引換券は、日本時間の2019/3/29（金）-2019/4/26（金）まで利用が可能です。訪れられた日本7-Eleven店内にあるほろよい商品のみ引換可能。
・本券は日本国内のセブンーイレブンのみご利用になれます。
・1つのバーコードで1回の引き換えができます。1度利用されたバーコードでは引き換えはできません。不正利用、不正取得は禁じられます。
・店舗によっては商品が無い場合がございます。
・商品をお持ち頂いた上で、本クーポン画面をレジご精算前に店員にご提示ください。
・本キャンペーンに関するお問い合わせは、問い合わせ窓口までご連絡下さい。
・当社の個人情報保護方針（個人情報の取り扱い）につきましてはプライバシーポリシーをご覧下さい。
・本キャンペーンの実施にあたり、意図しない不正を確認した場合にはキャンペーンを中止させて頂く場合がございます。
・掲載内容は予告なく変更となる場合がございます。
・ご利用にあたって、又はご利用できなかったことによって発生した一切の損害（機会の損失、結果的損害等を含む）について、いかなる責任も負いません。
・ご利用は各個人での私的な使用に限るものとし、商用又は、営利を目的とした使用を一切禁止します。 */
  sevenNotification: (obj?: I18nArgumentType) => translation('Seven-Notification', obj),
  /* キャンペーン期間はすでに終了いたしました */
  sevenOutOfPromoTerm: (obj?: I18nArgumentType) => translation('Seven-OutOfPromoTerm', obj),
  /* お支払い方法 */
  sevenPayment: (obj?: I18nArgumentType) => translation('Seven-Payment', obj),
  /* 現金 */
  sevenPaymentCash: (obj?: I18nArgumentType) => translation('Seven-PaymentCash', obj),
  /* クレジットカード */
  sevenPaymentCreditCard: (obj?: I18nArgumentType) => translation('Seven-PaymentCreditCard', obj),
  /* 日本のコンビニで商品を買う時、もっともよく使うお支払方法は何ですか？ */
  sevenPaymentDescription: (obj?: I18nArgumentType) => translation('Seven-PaymentDescription', obj),
  /* 交通系ICカード */
  sevenPaymentIcCard: (obj?: I18nArgumentType) => translation('Seven-PaymentIcCard', obj),
  /* その他 */
  sevenPaymentOther: (obj?: I18nArgumentType) => translation('Seven-PaymentOther', obj),
  /* スマホ決済 */
  sevenPaymentSmartphone: (obj?: I18nArgumentType) => translation('Seven-PaymentSmartphone', obj),
  /* 「ほろよい微醉」 */
  sevenProductName: (obj?: I18nArgumentType) => translation('Seven-ProductName', obj),
  /* 販売地域：全国のセブンイレブン店舗
※訪れられた日本7-Eleven店内にあるほろよい商品のみ引換可能。 */
  sevenPromoAcceptingPlace: (obj?: I18nArgumentType) =>
    translation('Seven-PromoAcceptingPlace', obj),
  /* ほろよいは日本でとても人気なアルコール度数の低いお酒です！
春が訪れた日本。ほろよいを飲んで、日本旅行を楽しもう！
超人気、「ほろよい 白いサワー」
乳性飲料の爽やかな甘酸っぱさとすっきりとした後味がお楽しみいただけます。
季節限定、「ほろよい 白いサワー＜ぶどう＞」。乳性飲料の爽やかな味わいをベースに、ぶどうのみずみずしい果実味を楽しめるやさしい味わいに仕上げました。
楽しい時、3人ほどの友達と楽しく飲む時などにお勧め。 */
  sevenPromoBody: (obj?: I18nArgumentType) => translation('Seven-PromoBody', obj),
  /* 2019/4/26(金) 23:59まで */
  sevenPromoEndAt: (obj?: I18nArgumentType) => translation('Seven-PromoEndAt', obj),
  /* イベント期間 */
  sevenPromoPeriodLabel: (obj?: I18nArgumentType) => translation('Seven-PromoPeriodLabel', obj),
  /* 2019/3/29(金) 00:00から */
  sevenPromoStartFrom: (obj?: I18nArgumentType) => translation('Seven-PromoStartFrom', obj),
  /* 「ほろよい」引換券！ */
  sevenPromoTitle: (obj?: I18nArgumentType) => translation('Seven-PromoTitle', obj),
  /* 「ほろよい」をもらう */
  sevenPromotionGoGetVoucher: (obj?: I18nArgumentType) =>
    translation('Seven-PromotionGoGetVoucher', obj),
  /* キャンペーンに進む */
  sevenPromotionGoSevenTop: (obj?: I18nArgumentType) =>
    translation('Seven-PromotionGoSevenTop', obj),
  /* 20歳以上ですか？ */
  sevenRestrictedAlertMessage: (obj?: I18nArgumentType) =>
    translation('Seven-RestrictedAlertMessage', obj),
  /* いいえ */
  sevenRestrictedAlertNoLabel: (obj?: I18nArgumentType) =>
    translation('Seven-RestrictedAlertNoLabel', obj),
  /* 日本の法律では20歳未満はお酒を飲むことができません。 */
  sevenRestrictedAlertTitle: (obj?: I18nArgumentType) =>
    translation('Seven-RestrictedAlertTitle', obj),
  /* はい */
  sevenRestrictedAlertYesLabel: (obj?: I18nArgumentType) =>
    translation('Seven-RestrictedAlertYesLabel', obj),
  /* サーバーでエラーが発生しました。しばらくたってから再度お試しくださいませ。または、カスタマーサポートにお問い合わせください。 */
  sevenServerError: (obj?: I18nArgumentType) => translation('Seven-ServerError', obj),
  /* 7−Eleven x WAmazing */
  sevenTieUp: (obj?: I18nArgumentType) => translation('Seven-TieUp', obj),
  /* 男 */
  sevenGenderMale: (obj?: I18nArgumentType) => translation('Seven-genderMale', obj),
  /* 請於60天內啟動已領取的SIM卡。
如領取後60天內還未啟動，
SIM卡將無法使用。 */
  sim60DaysExpiredText: (obj?: I18nArgumentType) => translation('Sim-60DaysExpiredText', obj),
  /* [注意] この端末では、あなたの WAmazing アプリで利用開始したSIMのみお使いいただけます。他のユーザーが利用開始したSIMはお使いになれません。 */
  simActivationCantUseOthers: (obj?: I18nArgumentType) =>
    translation('Sim-ActivationCantUseOthers', obj),
  /* 箱に入っているWAmazingのSIMカードを切り取ってください。 */
  simActivationChangeCardStepCut: (obj?: I18nArgumentType) =>
    translation('Sim-ActivationChangeCardStepCut', obj),
  /* スマートフォン側部にある小さな穴にピンを差し込みます。 */
  simActivationChangeCardStepHole: (obj?: I18nArgumentType) =>
    translation('Sim-ActivationChangeCardStepHole', obj),
  /* SIMカードを入れ替えて、トレイをスマートフォンに戻しましょう。 */
  simActivationChangeCardStepInsert: (obj?: I18nArgumentType) =>
    translation('Sim-ActivationChangeCardStepInsert', obj),
  /* 箱からピンを取り出してください。 */
  simActivationChangeCardStepPin: (obj?: I18nArgumentType) =>
    translation('Sim-ActivationChangeCardStepPin', obj),
  /* トレイを取り出します。 */
  simActivationChangeCardStepTray: (obj?: I18nArgumentType) =>
    translation('Sim-ActivationChangeCardStepTray', obj),
  /* SIMカードの入れ替え */
  simActivationChangeCardTitle: (obj?: I18nArgumentType) =>
    translation('Sim-ActivationChangeCardTitle', obj),
  /* 高速通信を開始する */
  simActivationCommitActivationButton: (obj?: I18nArgumentType) =>
    translation('Sim-ActivationCommitActivationButton', obj),
  /* 無料の「高速通信」を開始するためには、以下の項目を確認の上、「高速通信の開始」ボタンを押して下さい。 */
  simActivationCommitActivationRequired: (obj?: I18nArgumentType) =>
    translation('Sim-ActivationCommitActivationRequired', obj),
  /* 無料の「高速通信」を開始するためには、上記の項目を確認の上、「高速通信の開始」ボタンを押してください。 */
  simActivationCommitActivationRequiredAbove: (obj?: I18nArgumentType) =>
    translation('Sim-ActivationCommitActivationRequiredAbove', obj),
  /* 全ての条件を満たしたら、下のボタンを押してください。30秒ほどかかりますので、アプリを終了せずにそのままお待ち下さい。 */
  simActivationCommitDescription: (obj?: I18nArgumentType) =>
    translation('Sim-ActivationCommitDescription', obj),
  /* 高速通信を開始できませんでした */
  simActivationCommitFailedTitle: (obj?: I18nArgumentType) =>
    translation('Sim-ActivationCommitFailedTitle', obj),
  /* 最後のステップです! */
  simActivationCommitTitle: (obj?: I18nArgumentType) =>
    translation('Sim-ActivationCommitTitle', obj),
  /* WAmazingが良いサービスだと思った方は、アプリレビューにご協力ください（所要時間：約1分） */
  simActivationCompletedPanelBody: (obj?: I18nArgumentType) =>
    translation('Sim-ActivationCompletedPanelBody', obj),
  /* 設定が完了しました！ */
  simActivationCompletedPanelTitle: (obj?: I18nArgumentType) =>
    translation('Sim-ActivationCompletedPanelTitle', obj),
  /* [ キャリア通信 ] はオンになっていますか？ */
  simActivationDescriptionNetworkCareer: (obj?: I18nArgumentType) =>
    translation('Sim-ActivationDescriptionNetworkCareer', obj),
  /* [ データ通信 ] はオンになっていますか？ */
  simActivationDescriptionNetworkData: (obj?: I18nArgumentType) =>
    translation('Sim-ActivationDescriptionNetworkData', obj),
  /* [ WiFI ] はオフになっていますか？ */
  simActivationDescriptionNetworkWifi: (obj?: I18nArgumentType) =>
    translation('Sim-ActivationDescriptionNetworkWifi', obj),
  /* 端末を再起動しましたか？ */
  simActivationDescriptionRestarted: (obj?: I18nArgumentType) =>
    translation('Sim-ActivationDescriptionRestarted', obj),
  /* [ APN ]の設定は完了していますか？ */
  simActivationDescriptionSimApn: (obj?: I18nArgumentType) =>
    translation('Sim-ActivationDescriptionSimApn', obj),
  /* SIMカードは端末に挿入されていますか？ */
  simActivationDescriptionSimInsert: (obj?: I18nArgumentType) =>
    translation('Sim-ActivationDescriptionSimInsert', obj),
  /* プロファイルのインストールは完了していますか？ */
  simActivationDescriptionSimProfile: (obj?: I18nArgumentType) =>
    translation('Sim-ActivationDescriptionSimProfile', obj),
  /* 高速通信の接続を開始しています。
しばらくお待ちください。
最大で1分ほどかかる場合があります。 */
  simActivationFailesMessageActivation: (obj?: I18nArgumentType) =>
    translation('Sim-ActivationFailesMessageActivation', obj),
  /* 下記についてご確認ください
・SIMカードの種類
・SIMカードの挿入
・APNの設定
・通信電波圏内でのご利用 */
  simActivationFailesMessageNetwork: (obj?: I18nArgumentType) =>
    translation('Sim-ActivationFailesMessageNetwork', obj),
  /* ただいまメンテナンス中です。
しばらく待ってからもう一度お試しください。 */
  simActivationFailesMessageServiceUnavailable: (obj?: I18nArgumentType) =>
    translation('Sim-ActivationFailesMessageServiceUnavailable', obj),
  /* 高速通信のご利用開始の際には、WiFiをオフにしてください */
  simActivationFailesMessageWifi: (obj?: I18nArgumentType) =>
    translation('Sim-ActivationFailesMessageWifi', obj),
  /* 改善点を提案する */
  simActivationInAppReviewButton: (obj?: I18nArgumentType) =>
    translation('Sim-ActivationInAppReviewButton', obj),
  /* 通信の設定のためにプロファイルをインストールします。iPhoneの設定に移動するので“インストール”を押してください。
完了したら再度アプリを起動してください。 */
  simActivationProfileDescription: (obj?: I18nArgumentType) =>
    translation('Sim-ActivationProfileDescription', obj),
  /* 通信の設定 */
  simActivationProfileTitle: (obj?: I18nArgumentType) =>
    translation('Sim-ActivationProfileTitle', obj),
  /* スキップ */
  simActivationSkipReviewButton: (obj?: I18nArgumentType) =>
    translation('Sim-ActivationSkipReviewButton', obj),
  /* 高速通信を開始する */
  simActivationStart: (obj?: I18nArgumentType) => translation('Sim-ActivationStart', obj),
  /* ご利用中の端末にSIMを挿入しましたか？また、再起動はお済みでしょうか？ */
  simActivationStartDescription1: (obj?: I18nArgumentType) =>
    translation('Sim-ActivationStartDescription1', obj),
  /* 再起動を行っていない場合、高速通信ができません。もしまだであれば、もう一度ご確認ください。 */
  simActivationStartDescription2: (obj?: I18nArgumentType) =>
    translation('Sim-ActivationStartDescription2', obj),
  /* レビューする */
  simActivationStoreReviewButton: (obj?: I18nArgumentType) =>
    translation('Sim-ActivationStoreReviewButton', obj),
  /* 高速通信を開始 */
  simActivationTitle: (obj?: I18nArgumentType) => translation('Sim-ActivationTitle', obj),
  /* 利用可能なクレジットカードブランド */
  simAvailableCreditCardLabelTitle: (obj?: I18nArgumentType) =>
    translation('Sim-AvailableCreditCardLabelTitle', obj),
  /* データ容量の追加購入をする */
  simBenefitRecharge: (obj?: I18nArgumentType) => translation('Sim-BenefitRecharge', obj),
  /* プロモコードで{{capacity}}を獲得しました！ */
  simCapacityAcquisitionCampaignCode: (obj?: I18nArgumentType) =>
    translation('Sim-CapacityAcquisitionCampaignCode', obj),
  /* Facebookシェアで{{capacity}}を獲得しました！ */
  simCapacityAcquisitionFacebookShare: (obj?: I18nArgumentType) =>
    translation('Sim-CapacityAcquisitionFacebookShare', obj),
  /* 現在、獲得したSIMデータはありません。 */
  simCapacityAcquisitionHistoryEmpty: (obj?: I18nArgumentType) =>
    translation('Sim-CapacityAcquisitionHistoryEmpty', obj),
  /* SIMデータ獲得履歴 */
  simCapacityAcquisitionHistoryIndexTitle: (obj?: I18nArgumentType) =>
    translation('Sim-CapacityAcquisitionHistoryIndexTitle', obj),
  /* WAmazingから{{capacity}}がプレゼントされました！ */
  simCapacityAcquisitionOthers: (obj?: I18nArgumentType) =>
    translation('Sim-CapacityAcquisitionOthers', obj),
  /* データ購入
購入金額：JPY {{amount}} */
  simCapacityAcquisitionPurchase: (obj?: I18nArgumentType) =>
    translation('Sim-CapacityAcquisitionPurchase', obj),
  /* ホテル予約で{{capacity}}を獲得しました！ */
  simCapacityAcquisitionYadoBooking: (obj?: I18nArgumentType) =>
    translation('Sim-CapacityAcquisitionYadoBooking', obj),
  /* 無制限 */
  simCapacityInfinity: (obj?: I18nArgumentType) => translation('Sim-CapacityInfinity', obj),
  /* MB */
  simCapacityUnitMBLabelText: (obj?: I18nArgumentType) =>
    translation('Sim-CapacityUnitMBLabelText', obj),
  /* SIMデータの購入に失敗しました。 */
  simChargeCapacityErrorModalTitle: (obj?: I18nArgumentType) =>
    translation('Sim-ChargeCapacityErrorModalTitle', obj),
  /* SIMデータの購入には、クレジットカード登録が必要です。 */
  simChargeCapacityNoCreditCardMessage: (obj?: I18nArgumentType) =>
    translation('Sim-ChargeCapacityNoCreditCardMessage', obj),
  /* 確認 */
  simChargeCapacityNoCreditCardTitle: (obj?: I18nArgumentType) =>
    translation('Sim-ChargeCapacityNoCreditCardTitle', obj),
  /* 登録 */
  simChargeCapacityRegisterCreditCardButtonLabel: (obj?: I18nArgumentType) =>
    translation('Sim-ChargeCapacityRegisterCreditCardButtonLabel', obj),
  /* SIMの設定 */
  simChargeCardNavigationTitle: (obj?: I18nArgumentType) =>
    translation('Sim-ChargeCardNavigationTitle', obj),
  /* 購入後のキャンセルはできません。
十分にご確認の上、ご購入ください。

決済には1分ほど時間がかかることがあります。 */
  simChargeConfirmationDescription: (obj?: I18nArgumentType) =>
    translation('Sim-ChargeConfirmationDescription', obj),
  /* SIMデータ  */
  simChargeConfirmationPanelLabelCapacityTitle: (obj?: I18nArgumentType) =>
    translation('Sim-ChargeConfirmationPanelLabelCapacityTitle', obj),
  /* 金額 */
  simChargeConfirmationPanelLabelPriceTitle: (obj?: I18nArgumentType) =>
    translation('Sim-ChargeConfirmationPanelLabelPriceTitle', obj),
  /* ご確認 */
  simChargeConfirmationPanelLabelTitle: (obj?: I18nArgumentType) =>
    translation('Sim-ChargeConfirmationPanelLabelTitle', obj),
  /* 合計 */
  simChargeConfirmationPanelLabelTotalTitle: (obj?: I18nArgumentType) =>
    translation('Sim-ChargeConfirmationPanelLabelTotalTitle', obj),
  /* 支払う */
  simChargeConfirmationPanelPayButtonTitle: (obj?: I18nArgumentType) =>
    translation('Sim-ChargeConfirmationPanelPayButtonTitle', obj),
  /* クレジットカード情報 */
  simCreditCardLabelTitle: (obj?: I18nArgumentType) => translation('Sim-CreditCardLabelTitle', obj),
  /* クレジットカード名義人 */
  simCreditCardNameInputViewTitle: (obj?: I18nArgumentType) =>
    translation('Sim-CreditCardNameInputViewTitle', obj),
  /* クレジットカード番号 */
  simCreditCardNumberInputViewTitle: (obj?: I18nArgumentType) =>
    translation('Sim-CreditCardNumberInputViewTitle', obj),
  /* クレジットカードの読取にカメラを使用します。 */
  simCreditCardPermissionRequestDescription: (obj?: I18nArgumentType) =>
    translation('Sim-CreditCardPermissionRequestDescription', obj),
  /* カメラの使用許可 */
  simCreditCardPermissionRequestTitle: (obj?: I18nArgumentType) =>
    translation('Sim-CreditCardPermissionRequestTitle', obj),
  /* 有効期限 */
  simExpireDateInputViewTitle: (obj?: I18nArgumentType) =>
    translation('Sim-ExpireDateInputViewTitle', obj),
  /* 通信を開始する */
  simIndexMenuItemActivateTitle: (obj?: I18nArgumentType) =>
    translation('Sim-IndexMenuItemActivateTitle', obj),
  /* データ購入・獲得履歴 */
  simIndexMenuItemHistoryTitle: (obj?: I18nArgumentType) =>
    translation('Sim-IndexMenuItemHistoryTitle', obj),
  /* 現在、コロナウイルスの影響でSIMカードの申請を停止しております */
  simIndexMenuItemReceiveDescription: (obj?: I18nArgumentType) =>
    translation('Sim-IndexMenuItemReceiveDescription', obj),
  /* 無料SIMカードを受け取る */
  simIndexMenuItemReceiveTitle: (obj?: I18nArgumentType) =>
    translation('Sim-IndexMenuItemReceiveTitle', obj),
  /* 無料SIMカードを申し込む */
  simIndexMenuItemRequestTitle: (obj?: I18nArgumentType) =>
    translation('Sim-IndexMenuItemRequestTitle', obj),
  /* 無料SIMの申し込みには会員登録が必要です。 */
  simIndexRegistrationRequired: (obj?: I18nArgumentType) =>
    translation('Sim-IndexRegistrationRequired', obj),
  /* SIMを挿入した後、端末を再起動してください。 */
  simIndexStartActivationDescription1: (obj?: I18nArgumentType) =>
    translation('Sim-IndexStartActivationDescription1', obj),
  /* その後、「高速通信を開始」ボタンをタップしてください。 */
  simIndexStartActivationDescription2: (obj?: I18nArgumentType) =>
    translation('Sim-IndexStartActivationDescription2', obj),
  /* 高速通信を開始 */
  simIndexStartActivationLabel: (obj?: I18nArgumentType) =>
    translation('Sim-IndexStartActivationLabel', obj),
  /* ホテルを予約すると、新しいSIMカードを申し込めるようになります。 */
  simIndexTerminatedAlertDescription: (obj?: I18nArgumentType) =>
    translation('Sim-IndexTerminatedAlertDescription', obj),
  /* ご利用ありがとうございました */
  simIndexTerminatedAlertTitle: (obj?: I18nArgumentType) =>
    translation('Sim-IndexTerminatedAlertTitle', obj),
  /* SIMの接続設定 */
  simNavigationSettingActivationTitle: (obj?: I18nArgumentType) =>
    translation('Sim-NavigationSettingActivationTitle', obj),
  /* すでにSIMカードを受取済みです。
インターネットに接続する設定を開始しましょう。 */
  simQRAlreadyReceivedDescriptionText: (obj?: I18nArgumentType) =>
    translation('Sim-QRAlreadyReceivedDescriptionText', obj),
  /* すでに受取済みです。 */
  simQRAlreadyReceivedLabel: (obj?: I18nArgumentType) =>
    translation('Sim-QRAlreadyReceivedLabel', obj),
  /* 上記のQRコードをカメラにかざしてください。
SIMを受け取ったら、インターネットに接続する設定を開始しましょう。 */
  simQRDescriptionText: (obj?: I18nArgumentType) => translation('Sim-QRDescriptionText', obj),
  /* 受取コード */
  simQRLabelTitle: (obj?: I18nArgumentType) => translation('Sim-QRLabelTitle', obj),
  /* 接続設定を開始する */
  simQRStartSimSetupButtonTitle: (obj?: I18nArgumentType) =>
    translation('Sim-QRStartSimSetupButtonTitle', obj),
  /* 受取コードの表示開始時刻 */
  simQRStartSimSetupUnavailableDate: (obj?: I18nArgumentType) =>
    translation('Sim-QRStartSimSetupUnavailableDate', obj),
  /* 上記に受取コードが表示されますので、空港でお使いください。 */
  simQRStartSimSetupUnavailableDescription: (obj?: I18nArgumentType) =>
    translation('Sim-QRStartSimSetupUnavailableDescription', obj),
  /* お選びいただいた場所はイベント専用です。申請後、イベント会場でSIMをお受け取りください。会場以外で申請した場合、SIMカードは受け取れません。 */
  simRequestEventOnlyDescription: (obj?: I18nArgumentType) =>
    translation('Sim-RequestEventOnlyDescription', obj),
  /* 受取方法 */
  simRequestHowToGetText: (obj?: I18nArgumentType) => translation('Sim-RequestHowToGetText', obj),
  /* 受取空港 */
  simRequestInputArrivalAirportLabel: (obj?: I18nArgumentType) =>
    translation('Sim-RequestInputArrivalAirportLabel', obj),
  /* 受取場所 */
  simRequestInputArrivalEventSpotLabel: (obj?: I18nArgumentType) =>
    translation('Sim-RequestInputArrivalEventSpotLabel', obj),
  /* 受取日 */
  simRequestInputArrivalOnLabel: (obj?: I18nArgumentType) =>
    translation('Sim-RequestInputArrivalOnLabel', obj),
  /* お申込みが完了しました */
  simRequestInputCompleted: (obj?: I18nArgumentType) =>
    translation('Sim-RequestInputCompleted', obj),
  /* 受け取る日時と場所を選択してください。 */
  simRequestInputDescription: (obj?: I18nArgumentType) =>
    translation('Sim-RequestInputDescription', obj),
  /* ※SIM受け取りのために、正確な受け取り空港と受取日を入力ください */
  simRequestInputDescriptionText: (obj?: I18nArgumentType) =>
    translation('Sim-RequestInputDescriptionText', obj),
  /* 出国空港 */
  simRequestInputReturnAirportLabel: (obj?: I18nArgumentType) =>
    translation('Sim-RequestInputReturnAirportLabel', obj),
  /* 出国日 */
  simRequestInputReturnOnLabel: (obj?: I18nArgumentType) =>
    translation('Sim-RequestInputReturnOnLabel', obj),
  /* 受取の情報 */
  simRequestInputTitle: (obj?: I18nArgumentType) => translation('Sim-RequestInputTitle', obj),
  /* ホテルを検索する */
  simRequestModalHotelReservationButton: (obj?: I18nArgumentType) =>
    translation('Sim-RequestModalHotelReservationButton', obj),
  /* 了解 */
  simRequirementAdaptButtonLabel: (obj?: I18nArgumentType) =>
    translation('Sim-RequirementAdaptButtonLabel', obj),
  /* 初回ユーザー登録の場合は、登録から4時間経過しなければなりません。 */
  simRequirementCautionFirstUserText: (obj?: I18nArgumentType) =>
    translation('Sim-RequirementCautionFirstUserText', obj),
  /* 次へ */
  simRequirementGotItButtonTitle: (obj?: I18nArgumentType) =>
    translation('Sim-RequirementGotItButtonTitle', obj),
  /* SIMは空港で受け取ることができます。 */
  simRequirementIconDescriptionAirportText: (obj?: I18nArgumentType) =>
    translation('Sim-RequirementIconDescriptionAirportText', obj),
  /* SIMの有効期限は15日間ですが、データの追加購入で延長も可能です */
  simRequirementIconDescriptionSimText: (obj?: I18nArgumentType) =>
    translation('Sim-RequirementIconDescriptionSimText', obj),
  /* 無料SIMの申込 */
  simRequirementTitle: (obj?: I18nArgumentType) => translation('Sim-RequirementTitle', obj),
  /* セキュリティコード */
  simSecurityCodeInputViewTitle: (obj?: I18nArgumentType) =>
    translation('Sim-SecurityCodeInputViewTitle', obj),
  /* 受け取った箱の中の説明書をご覧になりながら、APNの設定を行ってください */
  simSettingCardChangeApnDescription: (obj?: I18nArgumentType) =>
    translation('Sim-SettingCardChangeApnDescription', obj),
  /* APN */
  simSettingCardChangeApnInformationApn: (obj?: I18nArgumentType) =>
    translation('Sim-SettingCardChangeApnInformationApn', obj),
  /* 名前 */
  simSettingCardChangeApnInformationName: (obj?: I18nArgumentType) =>
    translation('Sim-SettingCardChangeApnInformationName', obj),
  /* タップすると設定アプリへ遷移します */
  simSettingCardChangeApnInformationOpenSettings: (obj?: I18nArgumentType) =>
    translation('Sim-SettingCardChangeApnInformationOpenSettings', obj),
  /* パスワード */
  simSettingCardChangeApnInformationPassword: (obj?: I18nArgumentType) =>
    translation('Sim-SettingCardChangeApnInformationPassword', obj),
  /* APN入力情報 */
  simSettingCardChangeApnInformationTitle: (obj?: I18nArgumentType) =>
    translation('Sim-SettingCardChangeApnInformationTitle', obj),
  /* ユーザー名 */
  simSettingCardChangeApnInformationUserName: (obj?: I18nArgumentType) =>
    translation('Sim-SettingCardChangeApnInformationUserName', obj),
  /* APNの設定画面を開く */
  simSettingCardChangeApnSettingButtonText: (obj?: I18nArgumentType) =>
    translation('Sim-SettingCardChangeApnSettingButtonText', obj),
  /* APN（接続先）の設定 */
  simSettingCardChangeApnTitle: (obj?: I18nArgumentType) =>
    translation('Sim-SettingCardChangeApnTitle', obj),
  /* プロファイルをインストール */
  simSettingDescriptionProfileInstallButton: (obj?: I18nArgumentType) =>
    translation('Sim-SettingDescriptionProfileInstallButton', obj),
  /* お得に日本を旅しよう。ツアー予約、割引チケット、国内移動の手配を簡単に。 */
  simShareFacebookDescription: (obj?: I18nArgumentType) =>
    translation('Sim-ShareFacebookDescription', obj),
  /* あと {{day}}日 {{hour}}時間 */
  simTopHeaderButtonExpireDayAndHours: (obj?: I18nArgumentType) =>
    translation('Sim-TopHeaderButtonExpireDayAndHours', obj),
  /* あと {{hour}}時間 */
  simTopHeaderButtonExpireHours: (obj?: I18nArgumentType) =>
    translation('Sim-TopHeaderButtonExpireHours', obj),
  /* あと 1時間未満 */
  simTopHeaderButtonExpireLessThanOneHour: (obj?: I18nArgumentType) =>
    translation('Sim-TopHeaderButtonExpireLessThanOneHour', obj),
  /* 有効期限が切れました */
  simTopHeaderButtonExpiredText: (obj?: I18nArgumentType) =>
    translation('Sim-TopHeaderButtonExpiredText', obj),
  /* 受取から15日間有効 */
  simTopHeaderButtonInitiateText: (obj?: I18nArgumentType) =>
    translation('Sim-TopHeaderButtonInitiateText', obj),
  /* SIM受取待ち */
  simTopHeaderButtonPreparingText: (obj?: I18nArgumentType) =>
    translation('Sim-TopHeaderButtonPreparingText', obj),
  /* 残り {{remaining}} MB */
  simTopHeaderRemainingCapacityLabel: (obj?: I18nArgumentType) =>
    translation('Sim-TopHeaderRemainingCapacityLabel', obj),
  /* 使い放題 */
  simTopHeaderRemainingCapacityLabelInfinity: (obj?: I18nArgumentType) =>
    translation('Sim-TopHeaderRemainingCapacityLabelInfinity', obj),
  /* 5日間延長 */
  simUsageCharge5DaysExtension: (obj?: I18nArgumentType) =>
    translation('Sim-UsageCharge5DaysExtension', obj),
  /* 購入する */
  simUsageChargeButtonTitle: (obj?: I18nArgumentType) =>
    translation('Sim-UsageChargeButtonTitle', obj),
  /* 容量チャージ */
  simUsageChargeHeaderTitle: (obj?: I18nArgumentType) =>
    translation('Sim-UsageChargeHeaderTitle', obj),
  /* 追加容量チャージをすると、有効期限が5日間延長されます。 */
  simUsageDescriptionText: (obj?: I18nArgumentType) => translation('Sim-UsageDescriptionText', obj),
  /* 有効期限 */
  simUsageLabelExpireDateTitle: (obj?: I18nArgumentType) =>
    translation('Sim-UsageLabelExpireDateTitle', obj),
  /* 利用可能データ量 */
  simUsageLabelRemainingTitle: (obj?: I18nArgumentType) =>
    translation('Sim-UsageLabelRemainingTitle', obj),
  /* 取得データ量合計 */
  simUsageLabelTitle: (obj?: I18nArgumentType) => translation('Sim-UsageLabelTitle', obj),
  /* 完了 */
  simWalkthroughEnd: (obj?: I18nArgumentType) => translation('Sim-WalkthroughEnd', obj),
  /* SIMの受け取り方 */
  simWalkthroughGetSimTitle: (obj?: I18nArgumentType) =>
    translation('Sim-WalkthroughGetSimTitle', obj),
  /* ICCIDの形式が誤っています。 */
  simWalkthroughInputIccidValidationErrorMessage: (obj?: I18nArgumentType) =>
    translation('Sim-WalkthroughInputIccidValidationErrorMessage', obj),
  /* 無料SIMカードを受け取るには、まず空港で受取機を見つけてください。 */
  simWalkthroughLabelFindSpotDescriptionText: (obj?: I18nArgumentType) =>
    translation('Sim-WalkthroughLabelFindSpotDescriptionText', obj),
  /* 空港で受取機を見つける */
  simWalkthroughLabelFindSpotTitle: (obj?: I18nArgumentType) =>
    translation('Sim-WalkthroughLabelFindSpotTitle', obj),
  /* お申込みありがとうございます。

空港で上の写真のようなパッケージを受け取ることができます。
次のページへ進んで受取方法を確認してください。 */
  simWalkthroughLabelGetSimDescriptionText: (obj?: I18nArgumentType) =>
    translation('Sim-WalkthroughLabelGetSimDescriptionText', obj),
  /* 受取機の「SIMカードを受取る」ボタンを押し、受取コードをカメラにかざしてください。 */
  simWalkthroughLabelScanCodeDescriptionText: (obj?: I18nArgumentType) =>
    translation('Sim-WalkthroughLabelScanCodeDescriptionText', obj),
  /* 受取コードをかざす */
  simWalkthroughLabelScanCodeTitle: (obj?: I18nArgumentType) =>
    translation('Sim-WalkthroughLabelScanCodeTitle', obj),
  /* バーコードの読取にカメラを使用します。 */
  simWalkthroughPermissionRequestDescription: (obj?: I18nArgumentType) =>
    translation('Sim-WalkthroughPermissionRequestDescription', obj),
  /* カメラの使用許可 */
  simWalkthroughPermissionRequestTitle: (obj?: I18nArgumentType) =>
    translation('Sim-WalkthroughPermissionRequestTitle', obj),
  /* 宿の予約をすると、SIMを受け取るためのQRコードが表示されます。 */
  simWalkthroughPossibleDescription: (obj?: I18nArgumentType) =>
    translation('Sim-WalkthroughPossibleDescription', obj),
  /* SIMを受け取ることができません。 */
  simWalkthroughPossibleTitle: (obj?: I18nArgumentType) =>
    translation('Sim-WalkthroughPossibleTitle', obj),
  /* 受け取ったSIMを端末に挿入した後、端末を再起動してください。その後、WAmazingアプリを立ち上げ高速通信を開始してください。 */
  simWalkthroughRestartAppAfterInserted: (obj?: I18nArgumentType) =>
    translation('Sim-WalkthroughRestartAppAfterInserted', obj),
  /* 読み取りを開始 */
  simWalkthroughScanIccidBeforeScan: (obj?: I18nArgumentType) =>
    translation('Sim-WalkthroughScanIccidBeforeScan', obj),
  /* バーコードが読み取れない場合 */
  simWalkthroughScanIccidCannotScanHelp: (obj?: I18nArgumentType) =>
    translation('Sim-WalkthroughScanIccidCannotScanHelp', obj),
  /* SIMカードのICCIDをカメラで読み取ります。 */
  simWalkthroughScanIccidDescription: (obj?: I18nArgumentType) =>
    translation('Sim-WalkthroughScanIccidDescription', obj),
  /* 読み取りが完了しました。 */
  simWalkthroughScanIccidEnd: (obj?: I18nArgumentType) =>
    translation('Sim-WalkthroughScanIccidEnd', obj),
  /* バーコードが読み取れない場合は、読み取り方式を変更してください。 */
  simWalkthroughScanIccidOptionDialogDescription: (obj?: I18nArgumentType) =>
    translation('Sim-WalkthroughScanIccidOptionDialogDescription', obj),
  /* 読み取り方式 */
  simWalkthroughScanIccidOptionDialogTitle: (obj?: I18nArgumentType) =>
    translation('Sim-WalkthroughScanIccidOptionDialogTitle', obj),
  /* 高速 */
  simWalkthroughScanIccidOptionGoogle: (obj?: I18nArgumentType) =>
    translation('Sim-WalkthroughScanIccidOptionGoogle', obj),
  /* 標準 */
  simWalkthroughScanIccidOptionStandard: (obj?: I18nArgumentType) =>
    translation('Sim-WalkthroughScanIccidOptionStandard', obj),
  /* 一番下のバーコードをカメラにかざしてください。 */
  simWalkthroughScanIccidPleaseScan: (obj?: I18nArgumentType) =>
    translation('Sim-WalkthroughScanIccidPleaseScan', obj),
  /* 再読取 */
  simWalkthroughScanIccidReScan: (obj?: I18nArgumentType) =>
    translation('Sim-WalkthroughScanIccidReScan', obj),
  /* 読み取り中... */
  simWalkthroughScanIccidScanning: (obj?: I18nArgumentType) =>
    translation('Sim-WalkthroughScanIccidScanning', obj),
  /* ICCID読取 */
  simWalkthroughScanIccidTitle: (obj?: I18nArgumentType) =>
    translation('Sim-WalkthroughScanIccidTitle', obj),
  /* 受取機はどこ？ */
  simWalkthroughSeeMapButtonTitle: (obj?: I18nArgumentType) =>
    translation('Sim-WalkthroughSeeMapButtonTitle', obj),
  /* 箱の中のSIMカードを選択してください。 */
  simWalkthroughSelectSimTypeDescription: (obj?: I18nArgumentType) =>
    translation('Sim-WalkthroughSelectSimTypeDescription', obj),
  /* SIMカード選択 */
  simWalkthroughSelectSimTypeTitle: (obj?: I18nArgumentType) =>
    translation('Sim-WalkthroughSelectSimTypeTitle', obj),
  /* 受取コードを表示する */
  simWalkthroughShowQRCodeButton: (obj?: I18nArgumentType) =>
    translation('Sim-WalkthroughShowQRCodeButton', obj),
  /* 宿の検索へ */
  simWalkthroughToYadoSearchButton: (obj?: I18nArgumentType) =>
    translation('Sim-WalkthroughToYadoSearchButton', obj),
  /* プロモコードをお持ちの方は、コードに応じた無料データ容量を獲得することができます。
次のページでコードを入力してください。 */
  simIncentiveCampaignCodeBody: (obj?: I18nArgumentType) =>
    translation('SimIncentive-CampaignCodeBody', obj),
  /* コードを入力 */
  simIncentiveCampaignCodeButton: (obj?: I18nArgumentType) =>
    translation('SimIncentive-CampaignCodeButton', obj),
  /* 一度プロモコードを使用すると、同じコードは使用できなくなります。複数のコードをお持ちの方は、1回につき1コードずつ送信してください。 */
  simIncentiveCampaignCodeFormBody: (obj?: I18nArgumentType) =>
    translation('SimIncentive-CampaignCodeFormBody', obj),
  /* 送信 */
  simIncentiveCampaignCodeFormButton: (obj?: I18nArgumentType) =>
    translation('SimIncentive-CampaignCodeFormButton', obj),
  /* このプロモコードはすでに使用済みです。 */
  simIncentiveCampaignCodeFormDuplicateCode: (obj?: I18nArgumentType) =>
    translation('SimIncentive-CampaignCodeFormDuplicateCode', obj),
  /* このプロモコードの利用期間は終了しています。 */
  simIncentiveCampaignCodeFormExpiredCode: (obj?: I18nArgumentType) =>
    translation('SimIncentive-CampaignCodeFormExpiredCode', obj),
  /* プロモコードが有効でありません。 */
  simIncentiveCampaignCodeFormInvalidCode: (obj?: I18nArgumentType) =>
    translation('SimIncentive-CampaignCodeFormInvalidCode', obj),
  /* プロモコード */
  simIncentiveCampaignCodeFormNavigationTitle: (obj?: I18nArgumentType) =>
    translation('SimIncentive-CampaignCodeFormNavigationTitle', obj),
  /* このコードのご利用条件を満たしていません。このコードが記載されていた画面や記事等を確認し、コードの利用条件をご確認ください。 */
  simIncentiveCampaignCodeFormNotEligible: (obj?: I18nArgumentType) =>
    translation('SimIncentive-CampaignCodeFormNotEligible', obj),
  /* 1234… */
  simIncentiveCampaignCodeFormPlaceholder: (obj?: I18nArgumentType) =>
    translation('SimIncentive-CampaignCodeFormPlaceholder', obj),
  /* コードの入力 */
  simIncentiveCampaignCodeFormTitle: (obj?: I18nArgumentType) =>
    translation('SimIncentive-CampaignCodeFormTitle', obj),
  /* プロモコード */
  simIncentiveCampaignCodeTitle: (obj?: I18nArgumentType) =>
    translation('SimIncentive-CampaignCodeTitle', obj),
  /* {{capacity}}を獲得しました！ */
  simIncentiveCompletionMessage: (obj?: I18nArgumentType) =>
    translation('SimIncentive-CompletionMessage', obj),
  /* おめでとうございます！ */
  simIncentiveCompletionTitle: (obj?: I18nArgumentType) =>
    translation('SimIncentive-CompletionTitle', obj),
  /* 閉じる */
  simIncentiveCompletionVanillaNegative: (obj?: I18nArgumentType) =>
    translation('SimIncentive-CompletionVanillaNegative', obj),
  /* ホテルを予約する */
  simIncentiveCompletionVanillaPositive: (obj?: I18nArgumentType) =>
    translation('SimIncentive-CompletionVanillaPositive', obj),
  /* 日本に行く友達にもWAmazingを教えてあげよう！友達も無料SIMをGETできて、さらに友達もFacebookシェアで500MB追加の1GBに！
下記のボタンを押して、Facebookの投稿するボタンでシェアは完了します。
※このキャンペーンは2018年6月30日23：59（日本時間）までなのでお早めに！ */
  simIncentiveFacebookShareBodyCampaign: (obj?: I18nArgumentType) =>
    translation('SimIncentive-FacebookShareBodyCampaign', obj),
  /* シェアする */
  simIncentiveFacebookShareButton: (obj?: I18nArgumentType) =>
    translation('SimIncentive-FacebookShareButton', obj),
  /* すでに通信容量を獲得済みです。 */
  simIncentiveFacebookShareConflictMessage: (obj?: I18nArgumentType) =>
    translation('SimIncentive-FacebookShareConflictMessage', obj),
  /* 無料データ量を増やす */
  simIncentiveFacebookShareTitle: (obj?: I18nArgumentType) =>
    translation('SimIncentive-FacebookShareTitle', obj),
  /* 有効期限は15日です */
  simIncentiveHeaderDescriptionReceived: (obj?: I18nArgumentType) =>
    translation('SimIncentive-HeaderDescriptionReceived', obj),
  /* SIMカード申請待ち */
  simIncentiveHeaderDescriptionRequestable: (obj?: I18nArgumentType) =>
    translation('SimIncentive-HeaderDescriptionRequestable', obj),
  /* SIMカード受取待ち */
  simIncentiveHeaderDescriptionRequested: (obj?: I18nArgumentType) =>
    translation('SimIncentive-HeaderDescriptionRequested', obj),
  /* 最大500MB獲得！ */
  simIncentiveIndexCampaignCodeSubtitle: (obj?: I18nArgumentType) =>
    translation('SimIncentive-IndexCampaignCodeSubtitle', obj),
  /* プロモコードを入力 */
  simIncentiveIndexCampaignCodeTitle: (obj?: I18nArgumentType) =>
    translation('SimIncentive-IndexCampaignCodeTitle', obj),
  /* 100MB */
  simIncentiveIndexFacebookSubtitle: (obj?: I18nArgumentType) =>
    translation('SimIncentive-IndexFacebookSubtitle', obj),
  /* いまだけ500MB! */
  simIncentiveIndexFacebookSubtitleCampaign: (obj?: I18nArgumentType) =>
    translation('SimIncentive-IndexFacebookSubtitleCampaign', obj),
  /* Facebookでシェアする */
  simIncentiveIndexFacebookTitle: (obj?: I18nArgumentType) =>
    translation('SimIncentive-IndexFacebookTitle', obj),
  /* 「ほろよい微醉」免費兌換券！ */
  simIncentiveIndexSevenElevenPromoSubTitle: (obj?: I18nArgumentType) =>
    translation('SimIncentive-IndexSevenElevenPromoSubTitle', obj),
  /* 7−Eleven x WAmazing */
  simIncentiveIndexSevenElevenPromoTitle: (obj?: I18nArgumentType) =>
    translation('SimIncentive-IndexSevenElevenPromoTitle', obj),
  /* SIM受け取り場所一覧 */
  simIncentiveIndexSimBoxPlaceList: (obj?: I18nArgumentType) =>
    translation('SimIncentive-IndexSimBoxPlaceList', obj),
  /* SIM容量500MBもらえるキャンペーン実施中！ */
  simIncentiveIndexTokyoWidePassSubTitle: (obj?: I18nArgumentType) =>
    translation('SimIncentive-IndexTokyoWidePassSubTitle', obj),
  /* JR TOKYO Wide Pass */
  simIncentiveIndexTokyoWidePassTitle: (obj?: I18nArgumentType) =>
    translation('SimIncentive-IndexTokyoWidePassTitle', obj),
  /* ユーザー登録 */
  simIncentiveIndexUnauthorizedErrorGoSignUp: (obj?: I18nArgumentType) =>
    translation('SimIncentive-IndexUnauthorizedErrorGoSignUp', obj),
  /* データ通信容量を増やすには、ユーザー登録が必要です。 */
  simIncentiveIndexUnauthorizedErrorMessage: (obj?: I18nArgumentType) =>
    translation('SimIncentive-IndexUnauthorizedErrorMessage', obj),
  /* 使い放題 */
  simIncentiveIndexYadoRibbonInInfinityCampaign: (obj?: I18nArgumentType) =>
    translation('SimIncentive-IndexYadoRibbonInInfinityCampaign', obj),
  /* 最大8GBのチャンス！ */
  simIncentiveIndexYadoSubtitle: (obj?: I18nArgumentType) =>
    translation('SimIncentive-IndexYadoSubtitle', obj),
  /* 期間限定のチャンス！ */
  simIncentiveIndexYadoSubtitleInInfinityCampaign: (obj?: I18nArgumentType) =>
    translation('SimIncentive-IndexYadoSubtitleInInfinityCampaign', obj),
  /* もっと増やす */
  simIncentiveIndexYadoTitle: (obj?: I18nArgumentType) =>
    translation('SimIncentive-IndexYadoTitle', obj),
  /* 通信使い放題にする */
  simIncentiveIndexYadoTitleInInfinityCampaign: (obj?: I18nArgumentType) =>
    translation('SimIncentive-IndexYadoTitleInInfinityCampaign', obj),
  /* キャンペーンに参加するには
ユーザー登録（無料）が必要です。 */
  simIncentiveTigerAirRequireRegistration: (obj?: I18nArgumentType) =>
    translation('SimIncentive-TigerAirRequireRegistration', obj),
  /* 無料データ量を増やす */
  simIncentiveTitle: (obj?: I18nArgumentType) => translation('SimIncentive-Title', obj),
  /* 日本最大級の掲載ホテル数、最安値保証のホテル予約サービスです。さらに予約金額に応じて、日本中でインターネット通信できるデータ通信容量をプラス！すでに予約されている方もいつものホテルをチェックしてオトクに予約しませんか？ */
  simIncentiveYadoBody: (obj?: I18nArgumentType) => translation('SimIncentive-YadoBody', obj),
  /* WAmazingでホテルを予約する */
  simIncentiveYadoInfiniteVolumeBody: (obj?: I18nArgumentType) =>
    translation('SimIncentive-YadoInfiniteVolumeBody', obj),
  /* 無料SIMカードが使い放題に */
  simIncentiveYadoInfiniteVolumeTitle: (obj?: I18nArgumentType) =>
    translation('SimIncentive-YadoInfiniteVolumeTitle', obj),
  /* ＋{{capacity}} MB */
  simIncentiveYadoItemCapacityPlus: (obj?: I18nArgumentType) =>
    translation('SimIncentive-YadoItemCapacityPlus', obj),
  /* ＋{{capacity}} MBずつ追加 */
  simIncentiveYadoItemCapacityPlusEvery: (obj?: I18nArgumentType) =>
    translation('SimIncentive-YadoItemCapacityPlusEvery', obj),
  /* 以降{{price}}円ごとに */
  simIncentiveYadoItemPriceEvery: (obj?: I18nArgumentType) =>
    translation('SimIncentive-YadoItemPriceEvery', obj),
  /* {{price}}円まで */
  simIncentiveYadoItemPriceUpper: (obj?: I18nArgumentType) =>
    translation('SimIncentive-YadoItemPriceUpper', obj),
  /* 価格と追加容量 */
  simIncentiveYadoTableHeader: (obj?: I18nArgumentType) =>
    translation('SimIncentive-YadoTableHeader', obj),
  /* スノー購入履歴 */
  snowPurchaseHistoryHeaderLabel: (obj?: I18nArgumentType) =>
    translation('Snow-PurchaseHistoryHeaderLabel', obj),
  /* 既に登録されています。 */
  tigerAirAlreadyRegisteredText: (obj?: I18nArgumentType) =>
    translation('TigerAir-AlreadyRegisteredText', obj),
  /* 台灣虎航直飛東京成田、東京羽田、大阪、旭川、函館、花卷、仙台、茨城、名古屋、小松、岡山、那覇。 */
  tigerAirAvaliableAirportsDescription: (obj?: I18nArgumentType) =>
    translation('TigerAir-AvaliableAirportsDescription', obj),
  /* 適用之機場 */
  tigerAirAvaliableAirportsTitle: (obj?: I18nArgumentType) =>
    translation('TigerAir-AvaliableAirportsTitle', obj),
  /* 台灣虎航 x WAmazing */
  tigerAirCampaignTitle: (obj?: I18nArgumentType) => translation('TigerAir-CampaignTitle', obj),
  /* 2018年7月2日至11月30日 */
  tigerAirDurationDescription: (obj?: I18nArgumentType) =>
    translation('TigerAir-DurationDescription', obj),
  /* 活動日期 */
  tigerAirDurationTitle: (obj?: I18nArgumentType) => translation('TigerAir-DurationTitle', obj),
  /* 進入註冊界面 */
  tigerAirGoCampaignRegistrationLabel: (obj?: I18nArgumentType) =>
    translation('TigerAir-GoCampaignRegistrationLabel', obj),
  /* 在這裡預約機票！ */
  tigerAirGoTigerAirBookingLabel: (obj?: I18nArgumentType) =>
    translation('TigerAir-GoTigerAirBookingLabel', obj),
  /* 送1.5GB */
  tigerAirPresentCapacity1: (obj?: I18nArgumentType) =>
    translation('TigerAir-PresentCapacity1', obj),
  /* 免費SIM */
  tigerAirPresentCapacity2: (obj?: I18nArgumentType) =>
    translation('TigerAir-PresentCapacity2', obj),
  /* 卡 */
  tigerAirPresentCapacity3: (obj?: I18nArgumentType) =>
    translation('TigerAir-PresentCapacity3', obj),
  /* 倒着空港 */
  tigerAirRegistrationAirportCodeLabel: (obj?: I18nArgumentType) =>
    translation('TigerAir-RegistrationAirportCodeLabel', obj),
  /* 登録します。よろしいですか？ */
  tigerAirRegistrationComfirmText: (obj?: I18nArgumentType) =>
    translation('TigerAir-RegistrationComfirmText', obj),
  /* OK */
  tigerAirRegistrationCompleteButtonText: (obj?: I18nArgumentType) =>
    translation('TigerAir-RegistrationCompleteButtonText', obj),
  /* 【注意】申請を忘れると、SIMカードを受け取ることができません。
出発前に必ず申請してください。 */
  tigerAirRegistrationCompleteRemindText: (obj?: I18nArgumentType) =>
    translation('TigerAir-RegistrationCompleteRemindText', obj),
  /* つづいて、SIMカードの受け取りを申請してください。 */
  tigerAirRegistrationCompleteText: (obj?: I18nArgumentType) =>
    translation('TigerAir-RegistrationCompleteText', obj),
  /* 日本到着後に、無料のSIMカードをお受け取りください。 */
  tigerAirRegistrationCompleteTextAlt: (obj?: I18nArgumentType) =>
    translation('TigerAir-RegistrationCompleteTextAlt', obj),
  /* キャンペーン登録完了 */
  tigerAirRegistrationCompleteTitle: (obj?: I18nArgumentType) =>
    translation('TigerAir-RegistrationCompleteTitle', obj),
  /* Tigerair 航空券の予約情報を入力してください。 */
  tigerAirRegistrationDescription1: (obj?: I18nArgumentType) =>
    translation('TigerAir-RegistrationDescription1', obj),
  /* 容量1.5GBのSIMカードを無料でゲットできます。 */
  tigerAirRegistrationDescription2: (obj?: I18nArgumentType) =>
    translation('TigerAir-RegistrationDescription2', obj),
  /* 护照名（英文） */
  tigerAirRegistrationPassportNameLabel: (obj?: I18nArgumentType) =>
    translation('TigerAir-RegistrationPassportNameLabel', obj),
  /* 訂位代碼 */
  tigerAirRegistrationReservationCodeLabel: (obj?: I18nArgumentType) =>
    translation('TigerAir-RegistrationReservationCodeLabel', obj),
  /* ・本活動僅限購買台灣虎航前往日本機票之旅客
・可免費領取SIM卡者為在2019年3月30日之前赴日的旅客
・同行者只要在申請時輸入機票的預約號碼即可獲得SIM卡。
・活動期間內預計多次訪日的旅客，每次搭乘皆可申請獲得15天1.5GB的高速上網SIM卡。
・1人同時只能申請1張SIM卡，假使您持有數張從台灣出發的虎航機票，也必須待「未使用的SIM卡」使用完畢之後才能申請下一張。
・SIM卡僅可在成田機場、羽田機場、關西機場、中部國際機場、函館機場、旭川機場、花卷機場、仙台機場、小松機場、茨城機場、岡山機場、那覇機場中，透過WAmazing SIM的專用設備領取。
・本活動實施當中，若發現有未預期之非法行為，有可能中止活動
・本活動期間為2018年7月2日至2018年11月30日
・活動內容若有修改或變更，恕不另行通知 */
  tigerAirSpecDescription: (obj?: I18nArgumentType) => translation('TigerAir-SpecDescription', obj),
  /* 使用規範 */
  tigerAirSpecTitle: (obj?: I18nArgumentType) => translation('TigerAir-SpecTitle', obj),
  /* 註冊 */
  tigerAirSubmitRegistrationLabel: (obj?: I18nArgumentType) =>
    translation('TigerAir-SubmitRegistrationLabel', obj),
  /* 免費贈送您可在日本使用15天，1.5GB的SIM卡！ */
  tigerAirTakeTigerAirDescription: (obj?: I18nArgumentType) =>
    translation('TigerAir-TakeTigerAirDescription', obj),
  /* 搭乘 */
  tigerAirTakeTigerAirTitle1: (obj?: I18nArgumentType) =>
    translation('TigerAir-TakeTigerAirTitle1', obj),
  /* 台灣虎航 */
  tigerAirTakeTigerAirTitle2: (obj?: I18nArgumentType) =>
    translation('TigerAir-TakeTigerAirTitle2', obj),
  /* 前往日本 */
  tigerAirTakeTigerAirTitle3: (obj?: I18nArgumentType) =>
    translation('TigerAir-TakeTigerAirTitle3', obj),
  /* 已購買台灣虎航班機前往日本之旅客 */
  tigerAirTermsDescription1: (obj?: I18nArgumentType) =>
    translation('TigerAir-TermsDescription1', obj),
  /* (限2019年3月30日前出發之航班) */
  tigerAirTermsDescription2: (obj?: I18nArgumentType) =>
    translation('TigerAir-TermsDescription2', obj),
  /* 活動條件 */
  tigerAirTermsTitle: (obj?: I18nArgumentType) => translation('TigerAir-TermsTitle', obj),
  /* 冬季航班開賣期間限定！提供免費無限流量SIM卡！ */
  tigerAirUnlimitedCampaignDescription: (obj?: I18nArgumentType) =>
    translation('TigerAir-UnlimitedCampaignDescription', obj),
  /* 2018年8月15日至8月19日 */
  tigerAirUnlimitedDurationDescription: (obj?: I18nArgumentType) =>
    translation('TigerAir-UnlimitedDurationDescription', obj),
  /* SIM卡 */
  tigerAirUnlimitedPresentCapacity1: (obj?: I18nArgumentType) =>
    translation('TigerAir-UnlimitedPresentCapacity1', obj),
  /* 免費 */
  tigerAirUnlimitedPresentCapacity2: (obj?: I18nArgumentType) =>
    translation('TigerAir-UnlimitedPresentCapacity2', obj),
  /* 吃到飽！ */
  tigerAirUnlimitedPresentCapacity3: (obj?: I18nArgumentType) =>
    translation('TigerAir-UnlimitedPresentCapacity3', obj),
  /* 可以免費獲得上網吃到飽SIM卡。 */
  tigerAirUnlimitedRegistrationDescription2: (obj?: I18nArgumentType) =>
    translation('TigerAir-UnlimitedRegistrationDescription2', obj),
  /* ・本活動僅限購買台灣虎航前往日本機票之旅客
・可免費領取SIM卡者為在2019年3月30日之前赴日的旅客
・同行者只要在申請時輸入機票的預約號碼即可獲得SIM卡。
・僅限於8/15到8/19間完成活動登錄並成功申請SIM卡之用戶能獲得15天無限量的網路SIM卡。
・1人同時只能申請1張SIM卡，假使您持有數張從台灣出發的虎航機票，也必須待「未使用的SIM卡」使用完畢之後才能申請下一張。
・SIM卡僅可在成田機場、羽田機場、關西機場、中部國際機場、函館機場、旭川機場、花卷機場、仙台機場、小松機場、茨城機場、岡山機場、那覇機場中，透過WAmazing SIM的專用設備領取。
・本活動實施當中，若發現有未預期之非法行為，有可能中止活動
・本活動期間為2018年8月15日至2018年8月19日。
・活動內容若有修改或變更，恕不另行通知 */
  tigerAirUnlimitedSpecDescription: (obj?: I18nArgumentType) =>
    translation('TigerAir-UnlimitedSpecDescription', obj),
  /* 15天日本上網SIM卡免費吃到飽！ */
  tigerAirUnlimitedTakeTigerAirDescription: (obj?: I18nArgumentType) =>
    translation('TigerAir-UnlimitedTakeTigerAirDescription', obj),
  /* 查看優惠券 */
  tokyoMonorailGetCoupon: (obj?: I18nArgumentType) => translation('TokyoMonorail-GetCoupon', obj),
  /* 查看優惠詳情 */
  tokyoMonorailGoPromotion: (obj?: I18nArgumentType) =>
    translation('TokyoMonorail-GoPromotion', obj),
  /* 今はしない */
  topRegistrationModalNegative: (obj?: I18nArgumentType) =>
    translation('Top-RegistrationModalNegative', obj),
  /* 登録する */
  topRegistrationModalPositive: (obj?: I18nArgumentType) =>
    translation('Top-RegistrationModalPositive', obj),
  /* SIMを受け取るためには
無料の会員登録が必要です */
  topRegistrationModalTitle: (obj?: I18nArgumentType) =>
    translation('Top-RegistrationModalTitle', obj),
  /* 家族のように迎えてくれるホストと一緒に料理。料理を通して日本を楽しめます。 */
  uniecoDescriptionAirKitchen: (obj?: I18nArgumentType) =>
    translation('Unieco-DescriptionAirKitchen', obj),
  /* 空港と宿泊施設間の「手荷物当日配送」。業界最安値で簡単申し込み！手ぶらで目的地まで直行！ */
  uniecoDescriptionAirporter: (obj?: I18nArgumentType) =>
    translation('Unieco-DescriptionAirporter', obj),
  /* Beauty Park は コンシェルジュがあなたのご要望に合った日本の人気美容院・サロンを予約してくれるサービスなので、日本語が出来なくても予約することが可能です。 */
  uniecoDescriptionBeautyPark: (obj?: I18nArgumentType) =>
    translation('Unieco-DescriptionBeautyPark', obj),
  /* 駅近のカフェなどのお店を簡単に事前予約して、安心安全に荷物を預けられる！もうコインロッカーを探す必要なし！ */
  uniecoDescriptionEcbo: (obj?: I18nArgumentType) => translation('Unieco-DescriptionEcbo', obj),
  /* 当日荷物配達サービス | 羽田空港→東京周辺のホテルとAirbnb */
  uniecoDescriptionEcboDelivery: (obj?: I18nArgumentType) =>
    translation('Unieco-DescriptionEcboDelivery', obj),
  /* Beauty Park は コンシェルジュがあなたのご要望に合った日本の人気美容院・サロンを予約してくれるサービスなので、日本語が出来なくても予約することが可能です。 */
  uniecoDescriptionEcho: (obj?: I18nArgumentType) => translation('Unieco-DescriptionEcho', obj),
  /* 旅行中のもしもの場合にも、中国語・英語でご対応。 */
  uniecoDescriptionInsurance: (obj?: I18nArgumentType) =>
    translation('Unieco-DescriptionInsurance', obj),
  /* 定額3,980円で都内のご指定の目的地まで乗ることのできる相乗りエアポートシャトルが予約できます。 */
  uniecoDescriptionNearMe: (obj?: I18nArgumentType) => translation('Unieco-DescriptionNearMe', obj),
  /* 定額3,980円で都内のご指定の目的地まで乗ることのできる相乗りエアポートシャトルが予約できます。 */
  uniecoDescriptionNearme: (obj?: I18nArgumentType) => translation('Unieco-DescriptionNearme', obj),
  /* 心を込めて予約手配サービスを提供しております。無駄な並ぶ時間を無くす！言語通じないのもう心配ない！気楽にグルメを楽しもう！ */
  uniecoDescriptionPecotter: (obj?: I18nArgumentType) =>
    translation('Unieco-DescriptionPecotter', obj),
  /* もっと日本旅行を便利に */
  uniecoHeadline1: (obj?: I18nArgumentType) => translation('Unieco-Headline1', obj),
  /* 日本をディープに楽しむ */
  uniecoHeadline2: (obj?: I18nArgumentType) => translation('Unieco-Headline2', obj),
  /* クレジットカード名義人はアルファベットで入力して下さい。 */
  validationAlphabetCreditCardNameErrorMessage: (obj?: I18nArgumentType) =>
    translation('Validation-AlphabetCreditCardNameErrorMessage', obj),
  /* SIMを受け取る空港が選択されていません。 */
  validationEmptyArrivalAirportErrorMessage: (obj?: I18nArgumentType) =>
    translation('Validation-EmptyArrivalAirportErrorMessage', obj),
  /* SIM受取日が入力されていません。 */
  validationEmptyArrivalOnDateErrorMessage: (obj?: I18nArgumentType) =>
    translation('Validation-EmptyArrivalOnDateErrorMessage', obj),
  /* 入力してください。 */
  validationEmptyCommonErrorMessage: (obj?: I18nArgumentType) =>
    translation('Validation-EmptyCommonErrorMessage', obj),
  /* クレジットカード名義人が入力されていません。 */
  validationEmptyCreditCardNameErrorMessage: (obj?: I18nArgumentType) =>
    translation('Validation-EmptyCreditCardNameErrorMessage', obj),
  /* クレジットカード番号が入力されていません。 */
  validationEmptyCreditCardNumberErrorMessage: (obj?: I18nArgumentType) =>
    translation('Validation-EmptyCreditCardNumberErrorMessage', obj),
  /* メールアドレスを入力してください。 */
  validationEmptyEmailErrorMessage: (obj?: I18nArgumentType) =>
    translation('Validation-EmptyEmailErrorMessage', obj),
  /* ご意見が入力されていません。 */
  validationEmptyFeedbackErrorMessage: (obj?: I18nArgumentType) =>
    translation('Validation-EmptyFeedbackErrorMessage', obj),
  /* 氏名が入力されていません。 */
  validationEmptyNameErrorMessage: (obj?: I18nArgumentType) =>
    translation('Validation-EmptyNameErrorMessage', obj),
  /* パスワードが入力されていません。 */
  validationEmptyPasswordErrorMessage: (obj?: I18nArgumentType) =>
    translation('Validation-EmptyPasswordErrorMessage', obj),
  /* 日本出国空港が入力されていません。 */
  validationEmptyReturnAirportErrorMessage: (obj?: I18nArgumentType) =>
    translation('Validation-EmptyReturnAirportErrorMessage', obj),
  /* 日本出国日が入力されていません。 */
  validationEmptyReturnOnDateErrorMessage: (obj?: I18nArgumentType) =>
    translation('Validation-EmptyReturnOnDateErrorMessage', obj),
  /* SIM受取日が過去の日付になっています。 */
  validationInvalidArrivalOnDateErrorMessage: (obj?: I18nArgumentType) =>
    translation('Validation-InvalidArrivalOnDateErrorMessage', obj),
  /* 有効期限をご確認ください。 */
  validationInvalidCreditCardExpirationErrorMessage: (obj?: I18nArgumentType) =>
    translation('Validation-InvalidCreditCardExpirationErrorMessage', obj),
  /* MM(月)の入力が間違っています。 */
  validationInvalidCreditCardMonthErrorMessage: (obj?: I18nArgumentType) =>
    translation('Validation-InvalidCreditCardMonthErrorMessage', obj),
  /* VISAかMasterCardを入力ください。 */
  validationInvalidCreditCardTypeMessage: (obj?: I18nArgumentType) =>
    translation('Validation-InvalidCreditCardTypeMessage', obj),
  /* YY(年)の入力が間違っています。 */
  validationInvalidCreditCardYearErrorMessage: (obj?: I18nArgumentType) =>
    translation('Validation-InvalidCreditCardYearErrorMessage', obj),
  /* クレジットカード番号が間違っています。 */
  validationInvalidDigitCreditCardNumberErrorMessage: (obj?: I18nArgumentType) =>
    translation('Validation-InvalidDigitCreditCardNumberErrorMessage', obj),
  /* セキュリティコードが間違っています。 */
  validationInvalidDigitSecurityCodeErrorMessage: (obj?: I18nArgumentType) =>
    translation('Validation-InvalidDigitSecurityCodeErrorMessage', obj),
  /* メールアドレスが間違っています。 */
  validationInvalidEmailErrorMessage: (obj?: I18nArgumentType) =>
    translation('Validation-InvalidEmailErrorMessage', obj),
  /* 日本出国日がSIM受取日より前になっています。 */
  validationInvalidReturnOnDateErrorMessage: (obj?: I18nArgumentType) =>
    translation('Validation-InvalidReturnOnDateErrorMessage', obj),
  /* 入国空港を入力してください。 */
  validationTigerAirAirportCode: (obj?: I18nArgumentType) =>
    translation('Validation-TigerAirAirportCode', obj),
  /* パスポートの氏名を入力してください。 */
  validationTigerAirPassportName: (obj?: I18nArgumentType) =>
    translation('Validation-TigerAirPassportName', obj),
  /* 予約番号を入力してください。 */
  validationTigerAirReservationCode: (obj?: I18nArgumentType) =>
    translation('Validation-TigerAirReservationCode', obj),
  /* 利用規約に同意してください */
  validationYadoBookingAgreeTermOfService: (obj?: I18nArgumentType) =>
    translation('Validation-YadoBookingAgreeTermOfService', obj),
  /* 名前を入力ください */
  validationYadoBookingEmptyRepFirstName: (obj?: I18nArgumentType) =>
    translation('Validation-YadoBookingEmptyRepFirstName', obj),
  /* 名字を入力ください */
  validationYadoBookingEmptyRepLastName: (obj?: I18nArgumentType) =>
    translation('Validation-YadoBookingEmptyRepLastName', obj),
  /* アルファベットで入力してください */
  validationYadoBookingInvalidCharacter: (obj?: I18nArgumentType) =>
    translation('Validation-YadoBookingInvalidCharacter', obj),
  /* クレジットカードを登録してください */
  validationYadoBookingRegisterCreditCard: (obj?: I18nArgumentType) =>
    translation('Validation-YadoBookingRegisterCreditCard', obj),
  /* 大人の人数を一人以上入力してください。 */
  validationYadoBookingRoomEmpty: (obj?: I18nArgumentType) =>
    translation('Validation-YadoBookingRoomEmpty', obj),
  /* 子供の年齢を入力してください。 */
  validationYadoBookingRoomEmptyChildAge: (obj?: I18nArgumentType) =>
    translation('Validation-YadoBookingRoomEmptyChildAge', obj),
  /* 降水 */
  weatherForecastHourlyForecastHeaderProbability: (obj?: I18nArgumentType) =>
    translation('WeatherForecast-HourlyForecastHeaderProbability', obj),
  /* 気温 */
  weatherForecastHourlyForecastHeaderTemperature: (obj?: I18nArgumentType) =>
    translation('WeatherForecast-HourlyForecastHeaderTemperature', obj),
  /* 時刻 */
  weatherForecastHourlyForecastHeaderTime: (obj?: I18nArgumentType) =>
    translation('WeatherForecast-HourlyForecastHeaderTime', obj),
  /* 天気 */
  weatherForecastHourlyForecastHeaderWeather: (obj?: I18nArgumentType) =>
    translation('WeatherForecast-HourlyForecastHeaderWeather', obj),
  /* M/DD HH:mm */
  weatherForecastLastUpdateTime: (obj?: I18nArgumentType) =>
    translation('WeatherForecast-LastUpdateTime', obj),
  /* 日本の天気 */
  weatherForecastTitleDefault: (obj?: I18nArgumentType) =>
    translation('WeatherForecast-TitleDefault', obj),
  /* 現在地の天気 */
  weatherForecastTitleWithGeoLocation: (obj?: I18nArgumentType) =>
    translation('WeatherForecast-TitleWithGeoLocation', obj),
  /* 晴れ */
  weatherForecastWeatherClearDay: (obj?: I18nArgumentType) =>
    translation('WeatherForecast-WeatherClearDay', obj),
  /* 曇り */
  weatherForecastWeatherCloudy: (obj?: I18nArgumentType) =>
    translation('WeatherForecast-WeatherCloudy', obj),
  /* 霧 */
  weatherForecastWeatherFog: (obj?: I18nArgumentType) =>
    translation('WeatherForecast-WeatherFog', obj),
  /* 晴れ時々曇り */
  weatherForecastWeatherPartlyCloudy: (obj?: I18nArgumentType) =>
    translation('WeatherForecast-WeatherPartlyCloudy', obj),
  /* 雨 */
  weatherForecastWeatherRain: (obj?: I18nArgumentType) =>
    translation('WeatherForecast-WeatherRain', obj),
  /* みぞれ */
  weatherForecastWeatherSleet: (obj?: I18nArgumentType) =>
    translation('WeatherForecast-WeatherSleet', obj),
  /* 雪 */
  weatherForecastWeatherSnow: (obj?: I18nArgumentType) =>
    translation('WeatherForecast-WeatherSnow', obj),
  /* 強風 */
  weatherForecastWeatherWind: (obj?: I18nArgumentType) =>
    translation('WeatherForecast-WeatherWind', obj),
  /* 天気予報機能を使うには
ネットワークに接続してください */
  weatherForecastNetworkError: (obj?: I18nArgumentType) =>
    translation('WeatherForecast-networkError', obj),
  /* 食事の選択方法 */
  webTadoOnsenLpValuesOneHowToUseLink: (obj?: I18nArgumentType) =>
    translation('WebTado-OnsenLpValuesOneHowToUseLink', obj),
  /* 貸切風呂予約方法 */
  webTadoOnsenLpValuesThreeHowToUseLink: (obj?: I18nArgumentType) =>
    translation('WebTado-OnsenLpValuesThreeHowToUseLink', obj),
  /* お迎え予約方法 */
  webTadoOnsenLpValuesTwoHowToUseLink: (obj?: I18nArgumentType) =>
    translation('WebTado-OnsenLpValuesTwoHowToUseLink', obj),
  /* プライバシーポリシー */
  webYado: (obj?: I18nArgumentType) => translation('WebYado-', obj),
  /* お迎えが手配できる */
  webYadoAbleToArrangePickup: (obj?: I18nArgumentType) =>
    translation('WebYado-AbleToArrangePickup', obj),
  /* 貸切風呂が予約できる */
  webYadoAbleToReservePrivateBath: (obj?: I18nArgumentType) =>
    translation('WebYado-AbleToReservePrivateBath', obj),
  /* 夕食が選べる */
  webYadoAbleToSelectDinner: (obj?: I18nArgumentType) =>
    translation('WebYado-AbleToSelectDinner', obj),
  /* プランの注意事項 */
  webYadoPlanCautions: (obj?: I18nArgumentType) => translation('WebYado-PlanCautions', obj),
  /* 貸切風呂について */
  webYadoAboutPrivateBath: (obj?: I18nArgumentType) => translation('WebYado-AboutPrivateBath', obj),
  /* ベジタリアン対応について */
  webYadoAboutVegetarianOption: (obj?: I18nArgumentType) =>
    translation('WebYado-AboutVegetarianOption', obj),
  /* {{price}}以上 */
  webYadoAboveHighestPrice: (obj?: I18nArgumentType) =>
    translation('WebYado-AboveHighestPrice', obj),
  /* アクセス情報 */
  webYadoAccess: (obj?: I18nArgumentType) => translation('WebYado-Access', obj),
  /* バス */
  webYadoAccessDirectionsBus: (obj?: I18nArgumentType) =>
    translation('WebYado-AccessDirectionsBus', obj),
  /* 車 */
  webYadoAccessDirectionsDriving: (obj?: I18nArgumentType) =>
    translation('WebYado-AccessDirectionsDriving', obj),
  /* 電車 */
  webYadoAccessDirectionsRail: (obj?: I18nArgumentType) =>
    translation('WebYado-AccessDirectionsRail', obj),
  /* {{hour}}時間 {{minute}}分 */
  webYadoAccessDirectionsTime: (obj?: I18nArgumentType) =>
    translation('WebYado-AccessDirectionsTime', obj),
  /* {{hour}}時間 */
  webYadoAccessDirectionsTimeHours: (obj?: I18nArgumentType) =>
    translation('WebYado-AccessDirectionsTimeHours', obj),
  /* {{minute}}分 */
  webYadoAccessDirectionsTimeMinutes: (obj?: I18nArgumentType) =>
    translation('WebYado-AccessDirectionsTimeMinutes', obj),
  /* 徒歩 */
  webYadoAccessDirectionsWalking: (obj?: I18nArgumentType) =>
    translation('WebYado-AccessDirectionsWalking', obj),
  /* アクセス情報 */
  webYadoAccessInformation: (obj?: I18nArgumentType) =>
    translation('WebYado-AccessInformation', obj),
  /* アクセス */
  webYadoAccessLabel: (obj?: I18nArgumentType) => translation('WebYado-AccessLabel', obj),
  /* 宿泊施設 */
  webYadoAccommodation: (obj?: I18nArgumentType) => translation('WebYado-Accommodation', obj),
  /* 宿 */
  webYadoAccommodationLabel: (obj?: I18nArgumentType) =>
    translation('WebYado-AccommodationLabel', obj),
  /* 宿泊費用 */
  webYadoAccomodationFee: (obj?: I18nArgumentType) => translation('WebYado-AccomodationFee', obj),
  /* アクティビティ */
  webYadoAcitivity: (obj?: I18nArgumentType) => translation('WebYado-Acitivity', obj),
  /* 取得済み */
  webYadoAcquiredLabel: (obj?: I18nArgumentType) => translation('WebYado-AcquiredLabel', obj),
  /* 日本旅行のホテル、アクティビティ、無料SIMカード */
  webYadoActivity: (obj?: I18nArgumentType) => translation('WebYado-Activity', obj),
  /* カードを追加する */
  webYadoAddCard: (obj?: I18nArgumentType) => translation('WebYado-AddCard', obj),
  /* その他の情報 */
  webYadoAdditionalInformation: (obj?: I18nArgumentType) =>
    translation('WebYado-AdditionalInformation', obj),
  /* 大人 */
  webYadoAdult: (obj?: I18nArgumentType) => translation('WebYado-Adult', obj),
  /* 女性の人数 */
  webYadoAdultFemale: (obj?: I18nArgumentType) => translation('WebYado-AdultFemale', obj),
  /* 男性の人数 */
  webYadoAdultMale: (obj?: I18nArgumentType) => translation('WebYado-AdultMale', obj),
  /* あえの風 */
  webYadoAenokaze: (obj?: I18nArgumentType) => translation('WebYado-Aenokaze', obj),
  /* オーシャンビューの素晴らしい景色と能登の季節の食材をふんだんに使った鮮やかなお料理をお楽しみいただけます。 */
  webYadoAenokazeDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-AenokazeDescription', obj),
  /* 年齢 */
  webYadoAge: (obj?: I18nArgumentType) => translation('WebYado-Age', obj),
  /* 年齢に関する特記事項 */
  webYadoAgePolicySpecialNote: (obj?: I18nArgumentType) =>
    translation('WebYado-AgePolicySpecialNote', obj),
  /* エアコン (冷暖房) */
  webYadoAirConditioner: (obj?: I18nArgumentType) => translation('WebYado-AirConditioner', obj),
  /* 青森空港 */
  webYadoAirportAomori: (obj?: I18nArgumentType) => translation('WebYado-AirportAomori', obj),
  /* 旭川空港 */
  webYadoAirportAsahikawa: (obj?: I18nArgumentType) => translation('WebYado-AirportAsahikawa', obj),
  /* 中部国際空港 */
  webYadoAirportChubu: (obj?: I18nArgumentType) => translation('WebYado-AirportChubu', obj),
  /* 福岡空港 */
  webYadoAirportFukuoka: (obj?: I18nArgumentType) => translation('WebYado-AirportFukuoka', obj),
  /* 福島空港 */
  webYadoAirportFukushima: (obj?: I18nArgumentType) => translation('WebYado-AirportFukushima', obj),
  /* 萩石見空港 */
  webYadoAirportHagiIwami: (obj?: I18nArgumentType) => translation('WebYado-AirportHagiIwami', obj),
  /* 函館空港 */
  webYadoAirportHakodate: (obj?: I18nArgumentType) => translation('WebYado-AirportHakodate', obj),
  /* 花巻空港 */
  webYadoAirportHanamaki: (obj?: I18nArgumentType) => translation('WebYado-AirportHanamaki', obj),
  /* 羽田空港 */
  webYadoAirportHaneda: (obj?: I18nArgumentType) => translation('WebYado-AirportHaneda', obj),
  /* 広島空港 */
  webYadoAirportHiroshima: (obj?: I18nArgumentType) => translation('WebYado-AirportHiroshima', obj),
  /* 茨城空港 */
  webYadoAirportIbaraki: (obj?: I18nArgumentType) => translation('WebYado-AirportIbaraki', obj),
  /* 鹿児島空港 */
  webYadoAirportKagoshima: (obj?: I18nArgumentType) => translation('WebYado-AirportKagoshima', obj),
  /* 関西国際空港 */
  webYadoAirportKansaiKokusai: (obj?: I18nArgumentType) =>
    translation('WebYado-AirportKansaiKokusai', obj),
  /* 北九州空港 */
  webYadoAirportKitakyushu: (obj?: I18nArgumentType) =>
    translation('WebYado-AirportKitakyushu', obj),
  /* 高知空港 */
  webYadoAirportKochi: (obj?: I18nArgumentType) => translation('WebYado-AirportKochi', obj),
  /* 小松空港 */
  webYadoAirportKomatsu: (obj?: I18nArgumentType) => translation('WebYado-AirportKomatsu', obj),
  /* 熊本空港 */
  webYadoAirportKumamoto: (obj?: I18nArgumentType) => translation('WebYado-AirportKumamoto', obj),
  /* 松本空港 */
  webYadoAirportMatsumoto: (obj?: I18nArgumentType) => translation('WebYado-AirportMatsumoto', obj),
  /* 松山空港 */
  webYadoAirportMatsuyama: (obj?: I18nArgumentType) => translation('WebYado-AirportMatsuyama', obj),
  /* 長崎空港 */
  webYadoAirportNagasaki: (obj?: I18nArgumentType) => translation('WebYado-AirportNagasaki', obj),
  /* 南紀白浜空港 */
  webYadoAirportNankiShirahama: (obj?: I18nArgumentType) =>
    translation('WebYado-AirportNankiShirahama', obj),
  /* 成田空港 */
  webYadoAirportNarita: (obj?: I18nArgumentType) => translation('WebYado-AirportNarita', obj),
  /* 新潟空港 */
  webYadoAirportNigata: (obj?: I18nArgumentType) => translation('WebYado-AirportNigata', obj),
  /* 岡山空港 */
  webYadoAirportOkayama: (obj?: I18nArgumentType) => translation('WebYado-AirportOkayama', obj),
  /* 大坂国際空港 */
  webYadoAirportOsakaKokusai: (obj?: I18nArgumentType) =>
    translation('WebYado-AirportOsakaKokusai', obj),
  /* 仙台空港 */
  webYadoAirportSendai: (obj?: I18nArgumentType) => translation('WebYado-AirportSendai', obj),
  /* 新千歳空港 */
  webYadoAirportShinChitose: (obj?: I18nArgumentType) =>
    translation('WebYado-AirportShinChitose', obj),
  /* 信州まつもと空港 */
  webYadoAirportShinshuMatsumoto: (obj?: I18nArgumentType) =>
    translation('WebYado-AirportShinshuMatsumoto', obj),
  /* 富士山静岡空港 */
  webYadoAirportShizuoka: (obj?: I18nArgumentType) => translation('WebYado-AirportShizuoka', obj),
  /* 但馬空港 */
  webYadoAirportTajima: (obj?: I18nArgumentType) => translation('WebYado-AirportTajima', obj),
  /* 高松空港 */
  webYadoAirportTakamatsu: (obj?: I18nArgumentType) => translation('WebYado-AirportTakamatsu', obj),
  /* 徳島空港 */
  webYadoAirportTokushima: (obj?: I18nArgumentType) => translation('WebYado-AirportTokushima', obj),
  /* 鳥取空港 */
  webYadoAirportTottori: (obj?: I18nArgumentType) => translation('WebYado-AirportTottori', obj),
  /* 富山空港 */
  webYadoAirportToyama: (obj?: I18nArgumentType) => translation('WebYado-AirportToyama', obj),
  /* 山形空港 */
  webYadoAirportYamagata: (obj?: I18nArgumentType) => translation('WebYado-AirportYamagata', obj),
  /* 山口宇部空港 */
  webYadoAirportYamaguchiUbe: (obj?: I18nArgumentType) =>
    translation('WebYado-AirportYamaguchiUbe', obj),
  /* 米子空港 */
  webYadoAirportYonago: (obj?: I18nArgumentType) => translation('WebYado-AirportYonago', obj),
  /* 施設全般 */
  webYadoAllFacilities: (obj?: I18nArgumentType) => translation('WebYado-AllFacilities', obj),
  /* 全室Wi-Fi無料 */
  webYadoAllRoomsFreeWifi: (obj?: I18nArgumentType) => translation('WebYado-AllRoomsFreeWifi', obj),
  /* この予約はキャンセル済みです。 */
  webYadoAlreadyCancelled: (obj?: I18nArgumentType) => translation('WebYado-AlreadyCancelled', obj),
  /* すでに会員ですか？ */
  webYadoAlreadyHaveAnAccount: (obj?: I18nArgumentType) =>
    translation('WebYado-AlreadyHaveAnAccount', obj),
  /* ゲームコーナー */
  webYadoAmusementArcade: (obj?: I18nArgumentType) => translation('WebYado-AmusementArcade', obj),
  /* 無料SIMカードアプリ */
  webYadoAppLink: (obj?: I18nArgumentType) => translation('WebYado-AppLink', obj),
  /* 申込期限切れ */
  webYadoApplicationClosed: (obj?: I18nArgumentType) =>
    translation('WebYado-ApplicationClosed', obj),
  /* 申し込む */
  webYadoApplyForPickup: (obj?: I18nArgumentType) => translation('WebYado-ApplyForPickup', obj),
  /* エリア */
  webYadoArea: (obj?: I18nArgumentType) => translation('WebYado-Area', obj),
  /* 有馬温泉 */
  webYadoAreaOnsenArimaOnsen: (obj?: I18nArgumentType) =>
    translation('WebYado-AreaOnsenArimaOnsen', obj),
  /* 熱海温泉 */
  webYadoAreaOnsenAtamiOnsen: (obj?: I18nArgumentType) =>
    translation('WebYado-AreaOnsenAtamiOnsen', obj),
  /* 道後温泉 */
  webYadoAreaOnsenDogoOnsen: (obj?: I18nArgumentType) =>
    translation('WebYado-AreaOnsenDogoOnsen', obj),
  /* 箱根温泉 */
  webYadoAreaOnsenHakoneOnsen: (obj?: I18nArgumentType) =>
    translation('WebYado-AreaOnsenHakoneOnsen', obj),
  /* 伊香保温泉 */
  webYadoAreaOnsenIkahoOnsen: (obj?: I18nArgumentType) =>
    translation('WebYado-AreaOnsenIkahoOnsen', obj),
  /* 伊豆高原温泉 */
  webYadoAreaOnsenIzuKogenOnsen: (obj?: I18nArgumentType) =>
    translation('WebYado-AreaOnsenIzuKogenOnsen', obj),
  /* 城崎温泉 */
  webYadoAreaOnsenKinosakiOnsen: (obj?: I18nArgumentType) =>
    translation('WebYado-AreaOnsenKinosakiOnsen', obj),
  /* 鬼怒川温泉 */
  webYadoAreaOnsenKinugawaOnsen: (obj?: I18nArgumentType) =>
    translation('WebYado-AreaOnsenKinugawaOnsen', obj),
  /* 草津温泉 */
  webYadoAreaOnsenKusatsuOnsen: (obj?: I18nArgumentType) =>
    translation('WebYado-AreaOnsenKusatsuOnsen', obj),
  /* 登別温泉 */
  webYadoAreaOnsenNoboribetsuOnsen: (obj?: I18nArgumentType) =>
    translation('WebYado-AreaOnsenNoboribetsuOnsen', obj),
  /* 由布院温泉 */
  webYadoAreaOnsenYufuinOnsen: (obj?: I18nArgumentType) =>
    translation('WebYado-AreaOnsenYufuinOnsen', obj),
  /* 湯河原温泉 */
  webYadoAreaOnsenYugawaraOnsen: (obj?: I18nArgumentType) =>
    translation('WebYado-AreaOnsenYugawaraOnsen', obj),
  /* 有馬温泉 */
  webYadoAreaOsakaArimaOnsen: (obj?: I18nArgumentType) =>
    translation('WebYado-AreaOsakaArimaOnsen', obj),
  /* 神戸 */
  webYadoAreaOsakaKobe: (obj?: I18nArgumentType) => translation('WebYado-AreaOsakaKobe', obj),
  /* 京都 */
  webYadoAreaOsakaKyoto: (obj?: I18nArgumentType) => translation('WebYado-AreaOsakaKyoto', obj),
  /* 難波 */
  webYadoAreaOsakaNamba: (obj?: I18nArgumentType) => translation('WebYado-AreaOsakaNamba', obj),
  /* 奈良 */
  webYadoAreaOsakaNara: (obj?: I18nArgumentType) => translation('WebYado-AreaOsakaNara', obj),
  /* 大阪 */
  webYadoAreaOsakaOsaka: (obj?: I18nArgumentType) => translation('WebYado-AreaOsakaOsaka', obj),
  /* 天王寺 */
  webYadoAreaOsakaTennoji: (obj?: I18nArgumentType) => translation('WebYado-AreaOsakaTennoji', obj),
  /* 和歌山 */
  webYadoAreaOsakaWakayama: (obj?: I18nArgumentType) =>
    translation('WebYado-AreaOsakaWakayama', obj),
  /* 福岡 */
  webYadoAreaOtherFukuoka: (obj?: I18nArgumentType) => translation('WebYado-AreaOtherFukuoka', obj),
  /* 広島 */
  webYadoAreaOtherHiroshima: (obj?: I18nArgumentType) =>
    translation('WebYado-AreaOtherHiroshima', obj),
  /* 金沢 */
  webYadoAreaOtherKanazawa: (obj?: I18nArgumentType) =>
    translation('WebYado-AreaOtherKanazawa', obj),
  /* 長野 */
  webYadoAreaOtherNagano: (obj?: I18nArgumentType) => translation('WebYado-AreaOtherNagano', obj),
  /* 沖縄 */
  webYadoAreaOtherOkinawa: (obj?: I18nArgumentType) => translation('WebYado-AreaOtherOkinawa', obj),
  /* 札幌 */
  webYadoAreaOtherSapporo: (obj?: I18nArgumentType) => translation('WebYado-AreaOtherSapporo', obj),
  /* 仙台 */
  webYadoAreaOtherSendai: (obj?: I18nArgumentType) => translation('WebYado-AreaOtherSendai', obj),
  /* 静岡 */
  webYadoAreaOtherShizuoka: (obj?: I18nArgumentType) =>
    translation('WebYado-AreaOtherShizuoka', obj),
  /* ご予約の宿があるエリアへのアクセスに便利な交通チケットです */
  webYadoAreaPass: (obj?: I18nArgumentType) => translation('WebYado-AreaPass', obj),
  /* 浅草 */
  webYadoAreaTokyoAsakusa: (obj?: I18nArgumentType) => translation('WebYado-AreaTokyoAsakusa', obj),
  /* 池袋 */
  webYadoAreaTokyoIkebukuro: (obj?: I18nArgumentType) =>
    translation('WebYado-AreaTokyoIkebukuro', obj),
  /* 六本木 */
  webYadoAreaTokyoRoppongi: (obj?: I18nArgumentType) =>
    translation('WebYado-AreaTokyoRoppongi', obj),
  /* 下北沢 */
  webYadoAreaTokyoShimokitazawa: (obj?: I18nArgumentType) =>
    translation('WebYado-AreaTokyoShimokitazawa', obj),
  /* 新宿 */
  webYadoAreaTokyoShinjuku: (obj?: I18nArgumentType) =>
    translation('WebYado-AreaTokyoShinjuku', obj),
  /* 東京駅 */
  webYadoAreaTokyoTokyo: (obj?: I18nArgumentType) => translation('WebYado-AreaTokyoTokyo', obj),
  /* 上野 */
  webYadoAreaTokyoUeno: (obj?: I18nArgumentType) => translation('WebYado-AreaTokyoUeno', obj),
  /* お迎え手配 */
  webYadoArrangePickup: (obj?: I18nArgumentType) => translation('WebYado-ArrangePickup', obj),
  /* WAmazing経由でご予約いただくと、最寄りの集合場所から宿までのお迎えサービスをご利用いただけます。 */
  webYadoArrangePickupDescription1: (obj?: I18nArgumentType) =>
    translation('WebYado-ArrangePickupDescription1', obj),
  /* 当サイト経由で手配が可能なため、事前に宿に電話して日本語で確認をとったり、チェックイン当日に重い荷物を持ちながら宿への移動手段を探したりすることなく、スムーズに移動することができます。 */
  webYadoArrangePickupDescription2: (obj?: I18nArgumentType) =>
    translation('WebYado-ArrangePickupDescription2', obj),
  /* 事前予約型お迎え手配に対応した宿をご利用いただく場合は、宿の予約後にウェブサイト上で希望時間や集合場所を指定するだけで、チェックイン当日に宿のお迎え車がお迎えに上がります。 */
  webYadoArrangePickupDescription3: (obj?: I18nArgumentType) =>
    translation('WebYado-ArrangePickupDescription3', obj),
  /* 当日呼び出し型お迎え手配に対応した宿をご利用いただく場合は、宿の予約後にウェブサイト上に表示される「当日呼び出しボタン」をチェックイン当日に集合場所に着いてから押していただくと、宿のお迎え車がお迎えに上がります。 */
  webYadoArrangePickupDescription4: (obj?: I18nArgumentType) =>
    translation('WebYado-ArrangePickupDescription4', obj),
  /* 詳しいサービス内容は宿によって異なりますので、問い合わせフォームよりお気軽にお問い合わせください。 */
  webYadoArrangePickupDescription5: (obj?: I18nArgumentType) =>
    translation('WebYado-ArrangePickupDescription5', obj),
  /* 約款 */
  webYadoArrangedTravel: (obj?: I18nArgumentType) => translation('WebYado-ArrangedTravel', obj),
  /* 取引時の重要条件説明事項 */
  webYadoArrangedTravelCondition: (obj?: I18nArgumentType) =>
    translation('WebYado-ArrangedTravelCondition', obj),
  /* 着便日時(日本時間) */
  webYadoArrivalDate: (obj?: I18nArgumentType) => translation('WebYado-ArrivalDate', obj),
  /* 着便航空会社名 */
  webYadoArrivingAirlineName: (obj?: I18nArgumentType) =>
    translation('WebYado-ArrivingAirlineName', obj),
  /* 着便フライト番号 */
  webYadoArrivingFlightNumber: (obj?: I18nArgumentType) =>
    translation('WebYado-ArrivingFlightNumber', obj),
  /* あさやホテル */
  webYadoAsayaHotel: (obj?: I18nArgumentType) => translation('WebYado-AsayaHotel', obj),
  /* 季節の食材を厳選し使用した料理を会席やバイキングで満足いくまで食べることができる。 */
  webYadoAsayaHotelDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-AsayaHotelDescription', obj),
  /* ご質問がある場合は、wechatオフィシャルアカウント「玩尽 日本 滑雪・溫泉社」をフォローし、質問してください。 */
  webYadoAskForWechatSubscription: (obj?: I18nArgumentType) =>
    translation('WebYado-AskForWechatSubscription', obj),
  /* 決済期限を過ぎてもお支払いがなかった場合、ご予約は自動的にキャンセルとなります。期限までのお支払いをお願い致します。 */
  webYadoAutoCancellationWhenPaymentNotCompleted: (obj?: I18nArgumentType) =>
    translation('WebYado-AutoCancellationWhenPaymentNotCompleted', obj),
  /* 自動チェックイン・アウトに対応している */
  webYadoAutoCheckinAndCheckout: (obj?: I18nArgumentType) =>
    translation('WebYado-AutoCheckinAndCheckout', obj),
  /* 自動チェックイン・アウトに対応している */
  webYadoAutomaticCheckInCheckOut: (obj?: I18nArgumentType) =>
    translation('WebYado-AutomaticCheckInCheckOut', obj),
  /* 秋 */
  webYadoAutumn: (obj?: I18nArgumentType) => translation('WebYado-Autumn', obj),
  /* 9月〜11月 */
  webYadoAutumnMonths: (obj?: I18nArgumentType) => translation('WebYado-AutumnMonths', obj),
  /* 対応言語 */
  webYadoAvailableLanguage: (obj?: I18nArgumentType) =>
    translation('WebYado-AvailableLanguage', obj),
  /* 現地で利用できるクレジットカード */
  webYadoAvailablePaymentMethods: (obj?: I18nArgumentType) =>
    translation('WebYado-AvailablePaymentMethods', obj),
  /* ロビーの混雑回避につとめている */
  webYadoAvoidCongestion: (obj?: I18nArgumentType) => translation('WebYado-AvoidCongestion', obj),
  /* 戻る */
  webYadoBackToYadoDetailPage: (obj?: I18nArgumentType) =>
    translation('WebYado-BackToYadoDetailPage', obj),
  /* バー */
  webYadoBar: (obj?: I18nArgumentType) => translation('WebYado-Bar', obj),
  /* 浴室 */
  webYadoBath: (obj?: I18nArgumentType) => translation('WebYado-Bath', obj),
  /* アメニティ */
  webYadoBathAmenities: (obj?: I18nArgumentType) => translation('WebYado-BathAmenities', obj),
  /* バスアメニティ */
  webYadoBathAmenity: (obj?: I18nArgumentType) => translation('WebYado-BathAmenity', obj),
  /* バスアメニティ */
  webYadoBathRoomAmenities: (obj?: I18nArgumentType) =>
    translation('WebYado-BathRoomAmenities', obj),
  /* バスタオル */
  webYadoBathTowel: (obj?: I18nArgumentType) => translation('WebYado-BathTowel', obj),
  /* 入浴 */
  webYadoBathing: (obj?: I18nArgumentType) => translation('WebYado-Bathing', obj),
  /* お風呂について */
  webYadoBathingFacilities: (obj?: I18nArgumentType) =>
    translation('WebYado-BathingFacilities', obj),
  /* バスローブ */
  webYadoBathrobe: (obj?: I18nArgumentType) => translation('WebYado-Bathrobe', obj),
  /* バーベキュー */
  webYadoBbq: (obj?: I18nArgumentType) => translation('WebYado-Bbq', obj),
  /* エステ */
  webYadoBeautySalon: (obj?: I18nArgumentType) => translation('WebYado-BeautySalon', obj),
  /* {{price}}未満 */
  webYadoBelowLowestPrice: (obj?: I18nArgumentType) => translation('WebYado-BelowLowestPrice', obj),
  /* 日本国内のホテルが実質最安値で予約できます。 */
  webYadoBestPriceForHotelsInJapan: (obj?: I18nArgumentType) =>
    translation('WebYado-BestPriceForHotelsInJapan', obj),
  /* 最安値保証 */
  webYadoBestPriceGuaranteed: (obj?: I18nArgumentType) =>
    translation('WebYado-BestPriceGuaranteed', obj),
  /* ベイリゾートホテル小豆島 */
  webYadoBeyShodo: (obj?: I18nArgumentType) => translation('WebYado-BeyShodo', obj),
  /* 全てのお部屋から美しい瀬戸内海を望むことができる、絶景のシーサイド・リゾートホテル。 */
  webYadoBeyShodoDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-BeyShodoDescription', obj),
  /* 温水洗浄トイレ */
  webYadoBidetToilet: (obj?: I18nArgumentType) => translation('WebYado-BidetToilet', obj),
  /* ビリヤード */
  webYadoBilliards: (obj?: I18nArgumentType) => translation('WebYado-Billiards', obj),
  /* ボディーソープ */
  webYadoBodyWash: (obj?: I18nArgumentType) => translation('WebYado-BodyWash', obj),
  /* 予約 */
  webYadoBooking: (obj?: I18nArgumentType) => translation('WebYado-Booking', obj),
  /* 期日までに決済がなかった場合は自動キャンセル */
  webYadoBookingAutoCancellationByDeadline: (obj?: I18nArgumentType) =>
    translation('WebYado-BookingAutoCancellationByDeadline', obj),
  /* キャンセル済み */
  webYadoBookingCancelled: (obj?: I18nArgumentType) => translation('WebYado-BookingCancelled', obj),
  /* 予約が完了しました！ */
  webYadoBookingCompleted: (obj?: I18nArgumentType) => translation('WebYado-BookingCompleted', obj),
  /* {{hotel}}の予約が完了しました。 */
  webYadoBookingCompletedHotel: (obj?: I18nArgumentType) =>
    translation('WebYado-BookingCompletedHotel', obj),
  /* 予約完了・決済完了（ご宿泊可能） */
  webYadoBookingCompletedPaymentCompleted: (obj?: I18nArgumentType) =>
    translation('WebYado-BookingCompletedPaymentCompleted', obj),
  /* 予約完了・決済待ち */
  webYadoBookingCompletedPaymentIncompleted: (obj?: I18nArgumentType) =>
    translation('WebYado-BookingCompletedPaymentIncompleted', obj),
  /* 予約確認 */
  webYadoBookingConfirmation: (obj?: I18nArgumentType) =>
    translation('WebYado-BookingConfirmation', obj),
  /* 予約内容 */
  webYadoBookingContent: (obj?: I18nArgumentType) => translation('WebYado-BookingContent', obj),
  /* 予約詳細 */
  webYadoBookingDetail: (obj?: I18nArgumentType) => translation('WebYado-BookingDetail', obj),
  /* ご宿泊までの流れ */
  webYadoBookingFlowUntilCheckin: (obj?: I18nArgumentType) =>
    translation('WebYado-BookingFlowUntilCheckin', obj),
  /* 女性 */
  webYadoBookingGenderFemale: (obj?: I18nArgumentType) =>
    translation('WebYado-BookingGenderFemale', obj),
  /* 男性 */
  webYadoBookingGenderMale: (obj?: I18nArgumentType) =>
    translation('WebYado-BookingGenderMale', obj),
  /* 予約済み宿一覧 */
  webYadoBookingIndexTitle: (obj?: I18nArgumentType) =>
    translation('WebYado-BookingIndexTitle', obj),
  /* お客様のお支払いを受理しましたので、ご予約はただちに処理されます。 */
  webYadoBookingPaymentCompleted: (obj?: I18nArgumentType) =>
    translation('WebYado-BookingPaymentCompleted', obj),
  /* お客様情報を入力してください */
  webYadoBookingPleaseInput: (obj?: I18nArgumentType) =>
    translation('WebYado-BookingPleaseInput', obj),
  /* 予約処理中です… */
  webYadoBookingProcessing: (obj?: I18nArgumentType) =>
    translation('WebYado-BookingProcessing', obj),
  /* 予約状況 */
  webYadoBookingState: (obj?: I18nArgumentType) => translation('WebYado-BookingState', obj),
  /* ご予約ありがとうございます！ */
  webYadoBookingThankyou: (obj?: I18nArgumentType) => translation('WebYado-BookingThankyou', obj),
  /* 東京・大阪・京都 周辺のホテル予約で */
  webYadoBookingTokyoOsakaKyotoArea: (obj?: I18nArgumentType) =>
    translation('WebYado-BookingTokyoOsakaKyotoArea', obj),
  /* 交通チケットの確認方法や使用方法については、WAmazingから届いているメールをご確認ください */
  webYadoBookingTransportationEmail: (obj?: I18nArgumentType) =>
    translation('WebYado-BookingTransportationEmail', obj),
  /* 予約の一覧 */
  webYadoBookings: (obj?: I18nArgumentType) => translation('WebYado-Bookings', obj),
  /* ボーリング */
  webYadoBowling: (obj?: I18nArgumentType) => translation('WebYado-Bowling', obj),
  /* 朝食 */
  webYadoBreakFast: (obj?: I18nArgumentType) => translation('WebYado-BreakFast', obj),
  /* 朝食込み */
  webYadoBreakfastIncluded: (obj?: I18nArgumentType) =>
    translation('WebYado-BreakfastIncluded', obj),
  /* 朝食のみ */
  webYadoBreakfastOnly: (obj?: I18nArgumentType) => translation('WebYado-BreakfastOnly', obj),
  /* 衛星放送 */
  webYadoBroadcastingSatellite: (obj?: I18nArgumentType) =>
    translation('WebYado-BroadcastingSatellite', obj),
  /*  */
  webYadoBuffet: (obj?: I18nArgumentType) => translation('WebYado-Buffet', obj),
  /* ビュッフェ */
  webYadoBuffetDinner: (obj?: I18nArgumentType) => translation('WebYado-BuffetDinner', obj),
  /* ビュッフェ方式をセットメニューに変更している */
  webYadoBuffetReplacedSetMeal: (obj?: I18nArgumentType) =>
    translation('WebYado-BuffetReplacedSetMeal', obj),
  /* ビュッフェ方式をセットメニューに変更している */
  webYadoBuffetReplacedWithSetMenu: (obj?: I18nArgumentType) =>
    translation('WebYado-BuffetReplacedWithSetMenu', obj),
  /* 決定 */
  webYadoButtonDone: (obj?: I18nArgumentType) => translation('WebYado-ButtonDone', obj),
  /* CVV / CVC */
  webYadoCVVCVC: (obj?: I18nArgumentType) => translation('WebYado-CVVCVC', obj),
  /* カフェ */
  webYadoCafe: (obj?: I18nArgumentType) => translation('WebYado-Cafe', obj),
  /* 夕食が選べます */
  webYadoCanChooseDinner: (obj?: I18nArgumentType) => translation('WebYado-CanChooseDinner', obj),
  /* 後から決済可 */
  webYadoCanPayLater: (obj?: I18nArgumentType) => translation('WebYado-CanPayLater', obj),
  /* キャンセルする */
  webYadoCancel: (obj?: I18nArgumentType) => translation('WebYado-Cancel', obj),
  /* 予約をキャンセルする */
  webYadoCancelBooking: (obj?: I18nArgumentType) => translation('WebYado-CancelBooking', obj),
  /* キャンセルが完了しました */
  webYadoCancelBookingCompleted: (obj?: I18nArgumentType) =>
    translation('WebYado-CancelBookingCompleted', obj),
  /* 予約をキャンセルしますか？ */
  webYadoCancelBookingDialogDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-CancelBookingDialogDescription', obj),
  /* 予約キャンセル */
  webYadoCancelBookingDialogTitle: (obj?: I18nArgumentType) =>
    translation('WebYado-CancelBookingDialogTitle', obj),
  /* キャンセルが失敗しました。もう一度お試しください。 */
  webYadoCancelBookingFailedModalDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-CancelBookingFailedModalDescription', obj),
  /* キャンセルが失敗しました */
  webYadoCancelBookingFailedModalTitle: (obj?: I18nArgumentType) =>
    translation('WebYado-CancelBookingFailedModalTitle', obj),
  /* キャンセル処理中です... */
  webYadoCancelBookingProcessing: (obj?: I18nArgumentType) =>
    translation('WebYado-CancelBookingProcessing', obj),
  /* 決済後もキャンセルポリシーに従ってキャンセルが可能 */
  webYadoCancellationAvailableAfterPayment: (obj?: I18nArgumentType) =>
    translation('WebYado-CancellationAvailableAfterPayment', obj),
  /* {{date}}（日本時間）までキャンセル無料 */
  webYadoCancellationAvailableTill: (obj?: I18nArgumentType) =>
    translation('WebYado-CancellationAvailableTill', obj),
  /* {{date}}までに予約をキャンセルすると無料でキャンセルできます。 */
  webYadoCancellationDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-CancellationDescription', obj),
  /* キャンセル費用はホテルや日程により異なります */
  webYadoCancellationFeeDifferDependingOnHotelOrSchedule: (obj?: I18nArgumentType) =>
    translation('WebYado-CancellationFeeDifferDependingOnHotelOrSchedule', obj),
  /* 無料キャンセル */
  webYadoCancellationFree: (obj?: I18nArgumentType) => translation('WebYado-CancellationFree', obj),
  /* キャンセルポリシー */
  webYadoCancellationPolicy: (obj?: I18nArgumentType) =>
    translation('WebYado-CancellationPolicy', obj),
  /* カヌー */
  webYadoCanoeing: (obj?: I18nArgumentType) => translation('WebYado-Canoeing', obj),
  /* このクーポンは取得できません。 */
  webYadoCantGetThisCouponMessage: (obj?: I18nArgumentType) =>
    translation('WebYado-CantGetThisCouponMessage', obj),
  /* カード名義人 */
  webYadoCardHolderName: (obj?: I18nArgumentType) => translation('WebYado-CardHolderName', obj),
  /* カード番号 */
  webYadoCardNumber: (obj?: I18nArgumentType) => translation('WebYado-CardNumber', obj),
  /* 現金引き出し */
  webYadoCashWithdrawal: (obj?: I18nArgumentType) => translation('WebYado-CashWithdrawal', obj),
  /* キャッシュレス決済に対応している */
  webYadoCashlessPayment: (obj?: I18nArgumentType) => translation('WebYado-CashlessPayment', obj),
  /* キャッシュレス決済に対応している */
  webYadoCashlessPaymentAccepted: (obj?: I18nArgumentType) =>
    translation('WebYado-CashlessPaymentAccepted', obj),
  /* 陶芸 */
  webYadoCeramicArt: (obj?: I18nArgumentType) => translation('WebYado-CeramicArt', obj),
  /* 変更する */
  webYadoChangePickup: (obj?: I18nArgumentType) => translation('WebYado-ChangePickup', obj),
  /* 詳細を見る */
  webYadoCheckDetail: (obj?: I18nArgumentType) => translation('WebYado-CheckDetail', obj),
  /* チェックイン&アウト */
  webYadoCheckInCheckOut: (obj?: I18nArgumentType) => translation('WebYado-CheckInCheckOut', obj),
  /* 今すぐチェック!! */
  webYadoCheckNow: (obj?: I18nArgumentType) => translation('WebYado-CheckNow', obj),
  /* 他の日付を見る */
  webYadoCheckOtherDate: (obj?: I18nArgumentType) => translation('WebYado-CheckOtherDate', obj),
  /* 同じ宿の他の日付を見る */
  webYadoCheckOtherDateOfTheHotel: (obj?: I18nArgumentType) =>
    translation('WebYado-CheckOtherDateOfTheHotel', obj),
  /* 宿の詳細を確認 */
  webYadoCheckYadoDetail: (obj?: I18nArgumentType) => translation('WebYado-CheckYadoDetail', obj),
  /* チェックイン */
  webYadoCheckin: (obj?: I18nArgumentType) => translation('WebYado-Checkin', obj),
  /* チェックイン(24時間対応) */
  webYadoCheckin24: (obj?: I18nArgumentType) => translation('WebYado-Checkin24', obj),
  /* チェックイン日 */
  webYadoCheckinDate: (obj?: I18nArgumentType) => translation('WebYado-CheckinDate', obj),
  /* ご宿泊 */
  webYadoCheckinHotel: (obj?: I18nArgumentType) => translation('WebYado-CheckinHotel', obj),
  /* チェックイン予定時間 */
  webYadoCheckinTime: (obj?: I18nArgumentType) => translation('WebYado-CheckinTime', obj),
  /* チェックアウト */
  webYadoCheckout: (obj?: I18nArgumentType) => translation('WebYado-Checkout', obj),
  /* チェックアウト日 */
  webYadoCheckoutDate: (obj?: I18nArgumentType) => translation('WebYado-CheckoutDate', obj),
  /* 子供人数 */
  webYadoChildNumber: (obj?: I18nArgumentType) => translation('WebYado-ChildNumber', obj),
  /* 子供 */
  webYadoChildren: (obj?: I18nArgumentType) => translation('WebYado-Children', obj),
  /*  */
  webYadoChinese: (obj?: I18nArgumentType) => translation('WebYado-Chinese', obj),
  /* 中華料理 */
  webYadoChineseDinner: (obj?: I18nArgumentType) => translation('WebYado-ChineseDinner', obj),
  /* チェックイン日を選択してください */
  webYadoChooseCheckinDate: (obj?: I18nArgumentType) =>
    translation('WebYado-ChooseCheckinDate', obj),
  /* チェックアウト日を選択してください */
  webYadoChooseCheckoutDate: (obj?: I18nArgumentType) =>
    translation('WebYado-ChooseCheckoutDate', obj),
  /* 日付を選択 */
  webYadoChooseDate: (obj?: I18nArgumentType) => translation('WebYado-ChooseDate', obj),
  /* お支払いのタイミングを選択 */
  webYadoChoosePaymentTiming: (obj?: I18nArgumentType) =>
    translation('WebYado-ChoosePaymentTiming', obj),
  /* {{prefecture}}の都市 */
  webYadoCitiesInPrefecture: (obj?: I18nArgumentType) =>
    translation('WebYado-CitiesInPrefecture', obj),
  /* {{city}}({{prefecture}}) | WAmazingでは10,000件を超えるホテルを掲載しています。日本最大規模の掲載数なので、理想のホテルが必ず見つかります。 */
  webYadoCityDescription: (obj?: I18nArgumentType) => translation('WebYado-CityDescription', obj),
  /* {{city}},{{prefecture}},日本,旅行,宿,宿泊,旅館,ホテル,ビジネスホテル,リゾート */
  webYadoCityKeywords: (obj?: I18nArgumentType) => translation('WebYado-CityKeywords', obj),
  /* {{city}}({{prefecture}})の旅館・ホテルをお得に予約 WAmazing */
  webYadoCityTitle: (obj?: I18nArgumentType) => translation('WebYado-CityTitle', obj),
  /* クリア */
  webYadoClearFilter: (obj?: I18nArgumentType) => translation('WebYado-ClearFilter', obj),
  /* プランを閉じる */
  webYadoClosePlan: (obj?: I18nArgumentType) => translation('WebYado-ClosePlan', obj),
  /* コーヒーメーカー */
  webYadoCoffeeMaker: (obj?: I18nArgumentType) => translation('WebYado-CoffeeMaker', obj),
  /* 広東語 */
  webYadoCommonCantoneseLanguage: (obj?: I18nArgumentType) =>
    translation('WebYado-CommonCantoneseLanguage', obj),
  /* 英語 */
  webYadoCommonEnglishLanguage: (obj?: I18nArgumentType) =>
    translation('WebYado-CommonEnglishLanguage', obj),
  /* 日本語 */
  webYadoCommonJapaneseLanguage: (obj?: I18nArgumentType) =>
    translation('WebYado-CommonJapaneseLanguage', obj),
  /* 韓国語 */
  webYadoCommonKoreanLanguage: (obj?: I18nArgumentType) =>
    translation('WebYado-CommonKoreanLanguage', obj),
  /* メールアドレス */
  webYadoCommonMailAddress: (obj?: I18nArgumentType) =>
    translation('WebYado-CommonMailAddress', obj),
  /* 中国語 */
  webYadoCommonMandarinLanguage: (obj?: I18nArgumentType) =>
    translation('WebYado-CommonMandarinLanguage', obj),
  /* 未選択 */
  webYadoCommonNotSelectedYet: (obj?: I18nArgumentType) =>
    translation('WebYado-CommonNotSelectedYet', obj),
  /* 電話番号 */
  webYadoCommonTelephoneNumber: (obj?: I18nArgumentType) =>
    translation('WebYado-CommonTelephoneNumber', obj),
  /* タイ語 */
  webYadoCommonThaiLanguage: (obj?: I18nArgumentType) =>
    translation('WebYado-CommonThaiLanguage', obj),
  /* 会社情報 */
  webYadoCompanyInformation: (obj?: I18nArgumentType) =>
    translation('WebYado-CompanyInformation', obj),
  /* 完了 */
  webYadoComplete: (obj?: I18nArgumentType) => translation('WebYado-Complete', obj),
  /* 予約を完了する */
  webYadoCompleteBooking: (obj?: I18nArgumentType) => translation('WebYado-CompleteBooking', obj),
  /* 決済する */
  webYadoCompletePayment: (obj?: I18nArgumentType) => translation('WebYado-CompletePayment', obj),
  /* {{date}}（日本時間）までにお支払い */
  webYadoCompletePaymentByDeadline: (obj?: I18nArgumentType) =>
    translation('WebYado-CompletePaymentByDeadline', obj),
  /* 消費税 */
  webYadoComsumptionTax: (obj?: I18nArgumentType) => translation('WebYado-ComsumptionTax', obj),
  /* コンシェルジュ */
  webYadoConcierge: (obj?: I18nArgumentType) => translation('WebYado-Concierge', obj),
  /* 確認しました */
  webYadoConfirm: (obj?: I18nArgumentType) => translation('WebYado-Confirm', obj),
  /* 内容をご確認のうえ、予約を完了してください。 */
  webYadoConfirmYourInformationBeforeBooking: (obj?: I18nArgumentType) =>
    translation('WebYado-ConfirmYourInformationBeforeBooking', obj),
  /* 決済が完了しました。当日お気をつけてお越しください。
不明な点がございましたら、予約詳細をご覧になるか、カスタマーサポートまでご連絡ください。 */
  webYadoConnectCustomerServiceIfAnyProblem: (obj?: I18nArgumentType) =>
    translation('WebYado-ConnectCustomerServiceIfAnyProblem', obj),
  /* {{consentContents1}}及び、{{consentContents2}}に同意します。 */
  webYadoConsentConfirmation: (obj?: I18nArgumentType) =>
    translation('WebYado-ConsentConfirmation', obj),
  /* 消費税 */
  webYadoConsumptionTax: (obj?: I18nArgumentType) => translation('WebYado-ConsumptionTax', obj),
  /* お問い合わせ */
  webYadoContact: (obj?: I18nArgumentType) => translation('WebYado-Contact', obj),
  /* 会社情報 */
  webYadoCoorprateInfo: (obj?: I18nArgumentType) => translation('WebYado-CoorprateInfo', obj),
  /* {{day}}日 {{hour}}時間 {{minute}}分 {{second}}秒 */
  webYadoCountDownDateTime: (obj?: I18nArgumentType) =>
    translation('WebYado-CountDownDateTime', obj),
  /* {{hour}}時間 {{minute}}分 {{second}}秒 */
  webYadoCountDownTime: (obj?: I18nArgumentType) => translation('WebYado-CountDownTime', obj),
  /* クーポン */
  webYadoCoupon: (obj?: I18nArgumentType) => translation('WebYado-Coupon', obj),
  /* このクーポンを取得 */
  webYadoCouponAcquisition: (obj?: I18nArgumentType) =>
    translation('WebYado-CouponAcquisition', obj),
  /* クーポン取得に失敗しました。もう一度お試しください。 */
  webYadoCouponAcquisitionErrorMessage: (obj?: I18nArgumentType) =>
    translation('WebYado-CouponAcquisitionErrorMessage', obj),
  /* クーポン取得 */
  webYadoCouponAcquisitionTitle: (obj?: I18nArgumentType) =>
    translation('WebYado-CouponAcquisitionTitle', obj),
  /* 全てのご予約対象 */
  webYadoCouponAllTargetTitle: (obj?: I18nArgumentType) =>
    translation('WebYado-CouponAllTargetTitle', obj),
  /* クーポン割引 */
  webYadoCouponAppliedText: (obj?: I18nArgumentType) =>
    translation('WebYado-CouponAppliedText', obj),
  /* 既存会員もログインでもらえる！ */
  webYadoCouponBannerDescriptionPresentByLogin: (obj?: I18nArgumentType) =>
    translation('WebYado-CouponBannerDescriptionPresentByLogin', obj),
  /* JPY {{amount}}クーポンをお持ちです。今すぐ予約しよう！ */
  webYadoCouponBannerJpyDescriptionBookNow: (obj?: I18nArgumentType) =>
    translation('WebYado-CouponBannerJpyDescriptionBookNow', obj),
  /* 会員登録でJPY {{amount}}クーポンプレゼント */
  webYadoCouponBannerJpyDescriptionForNonMember: (obj?: I18nArgumentType) =>
    translation('WebYado-CouponBannerJpyDescriptionForNonMember', obj),
  /* ▲富士山エリア限定▲ */
  webYadoCouponBannerOnlyFujiArea: (obj?: I18nArgumentType) =>
    translation('WebYado-CouponBannerOnlyFujiArea', obj),
  /* 三朝・城崎・京都エリア */
  webYadoCouponBannerOnlyMisasaKinosakiKyoto: (obj?: I18nArgumentType) =>
    translation('WebYado-CouponBannerOnlyMisasaKinosakiKyoto', obj),
  /* 日光鬼怒川・箱根限定 */
  webYadoCouponBannerOnlyNikkoKinugawaHakone: (obj?: I18nArgumentType) =>
    translation('WebYado-CouponBannerOnlyNikkoKinugawaHakone', obj),
  /* {{percent}}%OFFクーポンをお持ちです。今すぐ予約しよう！ */
  webYadoCouponBannerPercentDescriptionBookNow: (obj?: I18nArgumentType) =>
    translation('WebYado-CouponBannerPercentDescriptionBookNow', obj),
  /* 会員登録で {{percent}}%OFFクーポンプレゼント */
  webYadoCouponBannerPercentDescriptionForNonMember: (obj?: I18nArgumentType) =>
    translation('WebYado-CouponBannerPercentDescriptionForNonMember', obj),
  /* 会員登録するだけですぐもらえる */
  webYadoCouponBannerSignupNow: (obj?: I18nArgumentType) =>
    translation('WebYado-CouponBannerSignupNow', obj),
  /* {{date}}までの宿泊が対象 */
  webYadoCouponConditionsCheckinDateDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-CouponConditionsCheckinDateDescription', obj),
  /* 宿泊費用{{money}}円以上のプラン */
  webYadoCouponConditionsLodgingFloorDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-CouponConditionsLodgingFloorDescription', obj),
  /* 利用条件 */
  webYadoCouponConditionsTitle: (obj?: I18nArgumentType) =>
    translation('WebYado-CouponConditionsTitle', obj),
  /* 対象エリアの温泉宿を予約する場合ご利用いただけるクーポンです。 */
  webYadoCouponDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-CouponDescription', obj),
  /* クーポン詳細 */
  webYadoCouponDetailTitle: (obj?: I18nArgumentType) =>
    translation('WebYado-CouponDetailTitle', obj),
  /* 先着順に達しました */
  webYadoCouponExceededFirstComeFirstServedLabel: (obj?: I18nArgumentType) =>
    translation('WebYado-CouponExceededFirstComeFirstServedLabel', obj),
  /* {{date}}まで（日本時間） */
  webYadoCouponExpirationDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-CouponExpirationDescription', obj),
  /* 有効期限 */
  webYadoCouponExpirationLabel: (obj?: I18nArgumentType) =>
    translation('WebYado-CouponExpirationLabel', obj),
  /* 期限切れ */
  webYadoCouponExpiredLabel: (obj?: I18nArgumentType) =>
    translation('WebYado-CouponExpiredLabel', obj),
  /* 対象の宿を探す */
  webYadoCouponFindTargetInnTitle: (obj?: I18nArgumentType) =>
    translation('WebYado-CouponFindTargetInnTitle', obj),
  /* 決済画面で利用するクーポンを選択し、予約を完了させてください */
  webYadoCouponFinishDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-CouponFinishDescription', obj),
  /* 先着 */
  webYadoCouponFirstArrival: (obj?: I18nArgumentType) =>
    translation('WebYado-CouponFirstArrival', obj),
  /* クーポンを取得します */
  webYadoCouponGetDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-CouponGetDescription', obj),
  /* JPY {{amount}}クーポン */
  webYadoCouponJpy: (obj?: I18nArgumentType) => translation('WebYado-CouponJpy', obj),
  /* JPY {{amount}} OFF */
  webYadoCouponJpyOff: (obj?: I18nArgumentType) => translation('WebYado-CouponJpyOff', obj),
  /* クーポン一覧 */
  webYadoCouponList: (obj?: I18nArgumentType) => translation('WebYado-CouponList', obj),
  /* クーポン一覧 */
  webYadoCouponListTitle: (obj?: I18nArgumentType) => translation('WebYado-CouponListTitle', obj),
  /* もしくは */
  webYadoCouponOrText: (obj?: I18nArgumentType) => translation('WebYado-CouponOrText', obj),
  /* {{percent}}%OFFクーポン */
  webYadoCouponPercent: (obj?: I18nArgumentType) => translation('WebYado-CouponPercent', obj),
  /* 期間 */
  webYadoCouponPeriodTitle: (obj?: I18nArgumentType) =>
    translation('WebYado-CouponPeriodTitle', obj),
  /* {{rate}}%OFF */
  webYadoCouponRateOff: (obj?: I18nArgumentType) => translation('WebYado-CouponRateOff', obj),
  /* 対象となる宿の予約に進みます */
  webYadoCouponTargetInnReservationDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-CouponTargetInnReservationDescription', obj),
  /* 対象 */
  webYadoCouponTargetTitle: (obj?: I18nArgumentType) =>
    translation('WebYado-CouponTargetTitle', obj),
  /* {{count}}枚 */
  webYadoCouponTicketLimitCountDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-CouponTicketLimitCountDescription', obj),
  /* 配布数 */
  webYadoCouponTicketLimitTitle: (obj?: I18nArgumentType) =>
    translation('WebYado-CouponTicketLimitTitle', obj),
  /* 宿予約で利用できるクーポン */
  webYadoCouponUsable: (obj?: I18nArgumentType) => translation('WebYado-CouponUsable', obj),
  /* {{date}}（日本時間23：59まで） */
  webYadoCouponUsagePeriod: (obj?: I18nArgumentType) =>
    translation('WebYado-CouponUsagePeriod', obj),
  /* クーポンご利用の流れ */
  webYadoCouponUseFlowTitle: (obj?: I18nArgumentType) =>
    translation('WebYado-CouponUseFlowTitle', obj),
  /* JPY {{amount}} OFF クーポン */
  webYadoCouponWithDiscountAmount: (obj?: I18nArgumentType) =>
    translation('WebYado-CouponWithDiscountAmount', obj),
  /* {{rate}}% OFF クーポン */
  webYadoCouponWithDiscountRate: (obj?: I18nArgumentType) =>
    translation('WebYado-CouponWithDiscountRate', obj),
  /* お持ちの */
  webYadoCouponYouAlreadyHavePrefix: (obj?: I18nArgumentType) =>
    translation('WebYado-CouponYouAlreadyHavePrefix', obj),
  /* クーポンで今すぐ予約しよう */
  webYadoCouponYouAlreadyHaveSuffix: (obj?: I18nArgumentType) =>
    translation('WebYado-CouponYouAlreadyHaveSuffix', obj),
  /* 新型コロナウィルス（COVID-19）感染拡大に伴い、新規の宿泊予約の受付を停止させていただいております。
WAmazingでは、これからも安心・安全な日本旅行をお楽しみいただけるよう、再開に向けた準備を進めてまいります。
サービス再開については会員登録いただければメール等でご報告いたしますので、この機会にご登録をよろしくお願いいたします。
ご不便をおかけいたしますが、ご理解の程よろしくお願いいたします。 */
  webYadoCovidBannerDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-CovidBannerDescription', obj),
  /* 新型コロナウィルス（COVID-19）感染拡大に伴う対応 */
  webYadoCovidBannerTitle: (obj?: I18nArgumentType) => translation('WebYado-CovidBannerTitle', obj),
  /* 新型コロナウィルス（COVID-19）感染拡大に伴い、新規の宿泊予約の受付を停止させていただいております。 */
  webYadoCovidBeforeBooking: (obj?: I18nArgumentType) =>
    translation('WebYado-CovidBeforeBooking', obj),
  /* クレジットカード */
  webYadoCreditCard: (obj?: I18nArgumentType) => translation('WebYado-CreditCard', obj),
  /* クレジットカード情報 */
  webYadoCreditCardInformation: (obj?: I18nArgumentType) =>
    translation('WebYado-CreditCardInformation', obj),
  /* 登録する */
  webYadoCreditCardModalRegister: (obj?: I18nArgumentType) =>
    translation('WebYado-CreditCardModalRegister', obj),
  /* お客様情報 */
  webYadoCustomerInfo: (obj?: I18nArgumentType) => translation('WebYado-CustomerInfo', obj),
  /* のクチコミ */
  webYadoCustomerReview: (obj?: I18nArgumentType) => translation('WebYado-CustomerReview', obj),
  /* WAmazingカスタマーサポート */
  webYadoCustomerService: (obj?: I18nArgumentType) => translation('WebYado-CustomerService', obj),
  /* 生年月日 */
  webYadoDateOfBirth: (obj?: I18nArgumentType) => translation('WebYado-DateOfBirth', obj),
  /* 年/月/日 */
  webYadoDatePlaceholder: (obj?: I18nArgumentType) => translation('WebYado-DatePlaceholder', obj),
  /* 託児施設 */
  webYadoDayNursery: (obj?: I18nArgumentType) => translation('WebYado-DayNursery', obj),
  /* ウェブ宿のテスト文言です */
  webYadoDebugTest: (obj?: I18nArgumentType) => translation('WebYado-DebugTest', obj),
  /* 予約確定 */
  webYadoDecideBooking: (obj?: I18nArgumentType) => translation('WebYado-DecideBooking', obj),
  /* ・ */
  webYadoDelimiter: (obj?: I18nArgumentType) => translation('WebYado-Delimiter', obj),
  /* 発便航空会社名 */
  webYadoDepartingAirlineName: (obj?: I18nArgumentType) =>
    translation('WebYado-DepartingAirlineName', obj),
  /* 発便日時(日本時間) */
  webYadoDepartingDate: (obj?: I18nArgumentType) => translation('WebYado-DepartingDate', obj),
  /* 発便フライト番号 */
  webYadoDepartingFlightNumber: (obj?: I18nArgumentType) =>
    translation('WebYado-DepartingFlightNumber', obj),
  /* 詳細 */
  webYadoDetail: (obj?: I18nArgumentType) => translation('WebYado-Detail', obj),
  /* の宿について */
  webYadoDetailsAboutAccommodation: (obj?: I18nArgumentType) =>
    translation('WebYado-DetailsAboutAccommodation', obj),
  /* お支払いがなければ安心の自動キャンセル */
  webYadoDetailsAutomaticCancellationNoPayment: (obj?: I18nArgumentType) =>
    translation('WebYado-DetailsAutomaticCancellationNoPayment', obj),
  /* 基本情報 */
  webYadoDetailsBasicInformation: (obj?: I18nArgumentType) =>
    translation('WebYado-DetailsBasicInformation', obj),
  /* 特典 */
  webYadoDetailsBenefits: (obj?: I18nArgumentType) => translation('WebYado-DetailsBenefits', obj),
  /* 特典付き */
  webYadoDetailsBenefitsIncluded: (obj?: I18nArgumentType) =>
    translation('WebYado-DetailsBenefitsIncluded', obj),
  /* 支払いしても【{{date}}】までのキャンセルは全額返金 */
  webYadoDetailsCancelByFullRefund: (obj?: I18nArgumentType) =>
    translation('WebYado-DetailsCancelByFullRefund', obj),
  /* 問い合わせフォーム */
  webYadoDetailsChatInquiryForm: (obj?: I18nArgumentType) =>
    translation('WebYado-DetailsChatInquiryForm', obj),
  /* 設備の情報を閉じる */
  webYadoDetailsCloseFacilitiesInformation: (obj?: I18nArgumentType) =>
    translation('WebYado-DetailsCloseFacilitiesInformation', obj),
  /* 夕食・朝食付き */
  webYadoDetailsDinnerAndBreakfastIncluded: (obj?: I18nArgumentType) =>
    translation('WebYado-DetailsDinnerAndBreakfastIncluded', obj),
  /* イベント観覧 */
  webYadoDetailsEvents: (obj?: I18nArgumentType) => translation('WebYado-DetailsEvents', obj),
  /* 施設設備 */
  webYadoDetailsFacilities: (obj?: I18nArgumentType) =>
    translation('WebYado-DetailsFacilities', obj),
  /* 手荷物無料預かり */
  webYadoDetailsFreeLuggageStorage: (obj?: I18nArgumentType) =>
    translation('WebYado-DetailsFreeLuggageStorage', obj),
  /* 駐車場無料 */
  webYadoDetailsFreeParking: (obj?: I18nArgumentType) =>
    translation('WebYado-DetailsFreeParking', obj),
  /* 卓球無料 */
  webYadoDetailsFreePingPong: (obj?: I18nArgumentType) =>
    translation('WebYado-DetailsFreePingPong', obj),
  /* 貸切風呂無料 */
  webYadoDetailsFreePrivateBath: (obj?: I18nArgumentType) =>
    translation('WebYado-DetailsFreePrivateBath', obj),
  /* 無料送迎 */
  webYadoDetailsFreeShuttleService: (obj?: I18nArgumentType) =>
    translation('WebYado-DetailsFreeShuttleService', obj),
  /* Wi-Fi無料 */
  webYadoDetailsFreeWifi: (obj?: I18nArgumentType) => translation('WebYado-DetailsFreeWifi', obj),
  /* 客室露天風呂 */
  webYadoDetailsGuestRoomOpenAirBath: (obj?: I18nArgumentType) =>
    translation('WebYado-DetailsGuestRoomOpenAirBath', obj),
  /* レイトチェックアウト */
  webYadoDetailsLateCheckout: (obj?: I18nArgumentType) =>
    translation('WebYado-DetailsLateCheckout', obj),
  /* ＋{{count}}の写真を見る */
  webYadoDetailsMorePhotos: (obj?: I18nArgumentType) =>
    translation('WebYado-DetailsMorePhotos', obj),
  /* 今日は支払い不要 */
  webYadoDetailsNoPaymentRequiredNow: (obj?: I18nArgumentType) =>
    translation('WebYado-DetailsNoPaymentRequiredNow', obj),
  /* 露天風呂について */
  webYadoDetailsOpenAirBaths: (obj?: I18nArgumentType) =>
    translation('WebYado-DetailsOpenAirBaths', obj),
  /* 外湯利用チケット */
  webYadoDetailsOpenAitBathTicket: (obj?: I18nArgumentType) =>
    translation('WebYado-DetailsOpenAitBathTicket', obj),
  /* お迎えサービス利用可 */
  webYadoDetailsPickupService: (obj?: I18nArgumentType) =>
    translation('WebYado-DetailsPickupService', obj),
  /* 人気の設備/サービス */
  webYadoDetailsPopularFacilities: (obj?: I18nArgumentType) =>
    translation('WebYado-DetailsPopularFacilities', obj),
  /* こだわり選択 */
  webYadoDetailsPreferences: (obj?: I18nArgumentType) =>
    translation('WebYado-DetailsPreferences', obj),
  /* プレミアムラウンジ */
  webYadoDetailsPremiumLounge: (obj?: I18nArgumentType) =>
    translation('WebYado-DetailsPremiumLounge', obj),
  /* 貸切風呂割引 */
  webYadoDetailsPrivateBathDiscount: (obj?: I18nArgumentType) =>
    translation('WebYado-DetailsPrivateBathDiscount', obj),
  /* 大浴場について */
  webYadoDetailsPublicBaths: (obj?: I18nArgumentType) =>
    translation('WebYado-DetailsPublicBaths', obj),
  /* 貸切風呂予約可 */
  webYadoDetailsReservablePrivateBath: (obj?: I18nArgumentType) =>
    translation('WebYado-DetailsReservablePrivateBath', obj),
  /* 今日予約でも支払いは【{{date}}】 */
  webYadoDetailsReserveNowPayAt: (obj?: I18nArgumentType) =>
    translation('WebYado-DetailsReserveNowPayAt', obj),
  /* 部屋食 / 個室食プラン */
  webYadoDetailsRoomDining: (obj?: I18nArgumentType) =>
    translation('WebYado-DetailsRoomDining', obj),
  /* この条件で検索 */
  webYadoDetailsSetSearchPreference: (obj?: I18nArgumentType) =>
    translation('WebYado-DetailsSetSearchPreference', obj),
  /* すべて表示 */
  webYadoDetailsShowAll: (obj?: I18nArgumentType) => translation('WebYado-DetailsShowAll', obj),
  /* ベジタリアン */
  webYadoDetailsVegetarian: (obj?: I18nArgumentType) =>
    translation('WebYado-DetailsVegetarian', obj),
  /* 施設の情報をすべて見る */
  webYadoDetailsViewAllFacilityInformation: (obj?: I18nArgumentType) =>
    translation('WebYado-DetailsViewAllFacilityInformation', obj),
  /* すべてのプランを見る */
  webYadoDetailsViewAllPlans: (obj?: I18nArgumentType) =>
    translation('WebYado-DetailsViewAllPlans', obj),
  /* すべての部屋を見る */
  webYadoDetailsViewAllRooms: (obj?: I18nArgumentType) =>
    translation('WebYado-DetailsViewAllRooms', obj),
  /* 部屋詳細を見る */
  webYadoDetailsViewRoomDetails: (obj?: I18nArgumentType) =>
    translation('WebYado-DetailsViewRoomDetails', obj),
  /* 浴衣 */
  webYadoDetailsYukata: (obj?: I18nArgumentType) => translation('WebYado-DetailsYukata', obj),
  /* WAmazingの食事カスタマイズ相談サポートについて */
  webYadoDietaryRequirements: (obj?: I18nArgumentType) =>
    translation('WebYado-DietaryRequirements', obj),
  /* WAmazingでは、アレルギー・苦手な食材があるなど、相談費用なしで個別の対応を行っています。対応の可否、対応可能な範囲については施設によって異なります。ご不明な点がございましたら、 {{contactForm}}ください。 */
  webYadoDietarySupport: (obj?: I18nArgumentType) => translation('WebYado-DietarySupport', obj),
  /* 食事 */
  webYadoDining: (obj?: I18nArgumentType) => translation('WebYado-Dining', obj),
  /* 食事会場の人数制限をしている */
  webYadoDiningEntryRestrictions: (obj?: I18nArgumentType) =>
    translation('WebYado-DiningEntryRestrictions', obj),
  /* 夕食の食事場所 */
  webYadoDiningVenue: (obj?: I18nArgumentType) => translation('WebYado-DiningVenue', obj),
  /* 夕食 */
  webYadoDinner: (obj?: I18nArgumentType) => translation('WebYado-Dinner', obj),
  /* 夕朝食付 */
  webYadoDinnerAndBreakfast: (obj?: I18nArgumentType) =>
    translation('WebYado-DinnerAndBreakfast', obj),
  /* 夕食の特徴 */
  webYadoDinnerCharacteristics: (obj?: I18nArgumentType) =>
    translation('WebYado-DinnerCharacteristics', obj),
  /* 夕食あり／なし
を選択できます */
  webYadoDinnerExistsOrNot: (obj?: I18nArgumentType) =>
    translation('WebYado-DinnerExistsOrNot', obj),
  /* 夕食あり／なしを選べる温泉宿 */
  webYadoDinnerExistsOrNotOnsenYado: (obj?: I18nArgumentType) =>
    translation('WebYado-DinnerExistsOrNotOnsenYado', obj),
  /* 夕食込み */
  webYadoDinnerIncluded: (obj?: I18nArgumentType) => translation('WebYado-DinnerIncluded', obj),
  /* [object Object] */
  webYadoDinnerItem: (obj?: I18nArgumentType) => translation('WebYado-DinnerItem', obj),
  /* 夕食の注意事項 */
  webYadoDinnerNote: (obj?: I18nArgumentType) => translation('WebYado-DinnerNote', obj),
  /* 夕食のみ */
  webYadoDinnerOnly: (obj?: I18nArgumentType) => translation('WebYado-DinnerOnly', obj),
  /* {{time}}の時間の範囲内に夕食をお取りいただくプランです */
  webYadoDinnerOpeningTimeNote: (obj?: I18nArgumentType) =>
    translation('WebYado-DinnerOpeningTimeNote', obj),
  /* 食事場所 */
  webYadoDinnerPlace: (obj?: I18nArgumentType) => translation('WebYado-DinnerPlace', obj),
  /* 客室 */
  webYadoDinnerPlaceGuestRoom: (obj?: I18nArgumentType) =>
    translation('WebYado-DinnerPlaceGuestRoom', obj),
  /* 個室 */
  webYadoDinnerPlacePrivateRoom: (obj?: I18nArgumentType) =>
    translation('WebYado-DinnerPlacePrivateRoom', obj),
  /* レストラン */
  webYadoDinnerPlaceRestaurant: (obj?: I18nArgumentType) =>
    translation('WebYado-DinnerPlaceRestaurant', obj),
  /* {{plan}}夕食プラン */
  webYadoDinnerPlan: (obj?: I18nArgumentType) => translation('WebYado-DinnerPlan', obj),
  /* 選べる夕食 */
  webYadoDinnerSelectable: (obj?: I18nArgumentType) => translation('WebYado-DinnerSelectable', obj),
  /* 何を食べることができるのか、どこで食べることができるのか表示されていないサイトが多い中、WAmazingでは予約時に、具体的なメニューの内容や食事場所について事前に知ることができ、好みに応じて選んで予約することが可能です。 */
  webYadoDinnerSelectableDescription1: (obj?: I18nArgumentType) =>
    translation('WebYado-DinnerSelectableDescription1', obj),
  /* これにより、当日に出されたメニューや食事場所がイメージと異なるというトラブルを防ぐことができます。 */
  webYadoDinnerSelectableDescription2: (obj?: I18nArgumentType) =>
    translation('WebYado-DinnerSelectableDescription2', obj),
  /* ご予約時にサイトから夕食のメニューや食事場所をお選びください。 */
  webYadoDinnerSelectableDescription3: (obj?: I18nArgumentType) =>
    translation('WebYado-DinnerSelectableDescription3', obj),
  /* 詳しいサービス内容は宿によって異なりますので、問い合わせフォームよりお気軽にお問い合わせください。 */
  webYadoDinnerSelectableDescription4: (obj?: I18nArgumentType) =>
    translation('WebYado-DinnerSelectableDescription4', obj),
  /* 夕食が選べる温泉宿 */
  webYadoDinnerSelectableOnsenYado: (obj?: I18nArgumentType) =>
    translation('WebYado-DinnerSelectableOnsenYado', obj),
  /* [object Object] */
  webYadoDinnerSelectableWithNumber: (obj?: I18nArgumentType) =>
    translation('WebYado-DinnerSelectableWithNumber', obj),
  /* {{time}}の時間の範囲内に夕食を開始いただくプランです（チェックイン時にお時間を確認いたします） */
  webYadoDinnerStartTimeNote: (obj?: I18nArgumentType) =>
    translation('WebYado-DinnerStartTimeNote', obj),
  /* 夕食時間 */
  webYadoDinnerTime: (obj?: I18nArgumentType) => translation('WebYado-DinnerTime', obj),
  /* JPY {{amount}} */
  webYadoDiscountAmount: (obj?: I18nArgumentType) => translation('WebYado-DiscountAmount', obj),
  /* 客室全体を消毒している */
  webYadoDisinfectAllRooms: (obj?: I18nArgumentType) =>
    translation('WebYado-DisinfectAllRooms', obj),
  /* 不特定多数の方が触れる箇所（エレベーターのボタン、手すりなど）を定期的な除菌 */
  webYadoDisinfectFrequentlyUsedSurfaces: (obj?: I18nArgumentType) =>
    translation('WebYado-DisinfectFrequentlyUsedSurfaces', obj),
  /* 浴室や脱衣所の消毒や換気を実施している */
  webYadoDisinfectVentilateBathingChangingAreas: (obj?: I18nArgumentType) =>
    translation('WebYado-DisinfectVentilateBathingChangingAreas', obj),
  /* 食事会場の消毒や換気を実施している */
  webYadoDisinfectVentilateDiningAreas: (obj?: I18nArgumentType) =>
    translation('WebYado-DisinfectVentilateDiningAreas', obj),
  /* フロントやロビーの消毒や換気を実施している */
  webYadoDisinfectVentilateFrontDesk: (obj?: I18nArgumentType) =>
    translation('WebYado-DisinfectVentilateFrontDesk', obj),
  /* 他の利用者と同乗はあるが車両の消毒や換気を実施している */
  webYadoDisinfectVentilateSharedShuttles: (obj?: I18nArgumentType) =>
    translation('WebYado-DisinfectVentilateSharedShuttles', obj),
  /* 不特定多数の方が触れる箇所（エレベーターのボタン、手すりなど）を定期的な除菌 */
  webYadoDisinfecting: (obj?: I18nArgumentType) => translation('WebYado-Disinfecting', obj),
  /* 1泊料金を日本円(JPY)で表示しています。 */
  webYadoDisplayOneDayFeeInYen: (obj?: I18nArgumentType) =>
    translation('WebYado-DisplayOneDayFeeInYen', obj),
  /* 食事会場の座席間隔を調整している */
  webYadoDistanceBetweenSeats: (obj?: I18nArgumentType) =>
    translation('WebYado-DistanceBetweenSeats', obj),
  /* ダイビング */
  webYadoDiving: (obj?: I18nArgumentType) => translation('WebYado-Diving', obj),
  /* 道後プリンスホテル */
  webYadoDogoPrince: (obj?: I18nArgumentType) => translation('WebYado-DogoPrince', obj),
  /* 瀬戸内の海の幸や愛媛の豊かな山野の新鮮で安心安全な食材を使用したお食事 */
  webYadoDogoPrinceDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-DogoPrinceDescription', obj),
  /* ダブルベッド */
  webYadoDoubleBed: (obj?: I18nArgumentType) => translation('WebYado-DoubleBed', obj),
  /* 羽毛布団 */
  webYadoDuvet: (obj?: I18nArgumentType) => translation('WebYado-Duvet', obj),
  /* ビデオデッキ (DVDプレーヤー) */
  webYadoDvdPlayer: (obj?: I18nArgumentType) => translation('WebYado-DvdPlayer', obj),
  /* アーリーバード割引 */
  webYadoEarlyBird: (obj?: I18nArgumentType) => translation('WebYado-EarlyBird', obj),
  /* 早割 */
  webYadoEarlyBirdDiscount: (obj?: I18nArgumentType) =>
    translation('WebYado-EarlyBirdDiscount', obj),
  /* 早割プラン */
  webYadoEarlyBirdPlan: (obj?: I18nArgumentType) => translation('WebYado-EarlyBirdPlan', obj),
  /*  */
  webYadoEclectic: (obj?: I18nArgumentType) => translation('WebYado-Eclectic', obj),
  /* 折衷料理 */
  webYadoEclecticDinner: (obj?: I18nArgumentType) => translation('WebYado-EclecticDinner', obj),
  /* 電気ポッド */
  webYadoElectricPot: (obj?: I18nArgumentType) => translation('WebYado-ElectricPot', obj),
  /* 館内での従業員・利用者のマスク着用を徹底している */
  webYadoEmployeeAndGuestMaskRequired: (obj?: I18nArgumentType) =>
    translation('WebYado-EmployeeAndGuestMaskRequired', obj),
  /* 従業員の手洗い・消毒を徹底している */
  webYadoEmployeeSanitization: (obj?: I18nArgumentType) =>
    translation('WebYado-EmployeeSanitization', obj),
  /* 従業員の検温及び発熱時の出勤停止を実施している */
  webYadoEmployeeTemperatureCheck: (obj?: I18nArgumentType) =>
    translation('WebYado-EmployeeTemperatureCheck', obj),
  /* 利用終了日 */
  webYadoEndDate: (obj?: I18nArgumentType) => translation('WebYado-EndDate', obj),
  /* 氏名（英語） */
  webYadoEnglishName: (obj?: I18nArgumentType) => translation('WebYado-EnglishName', obj),
  /* 食事会場の人数制限をしている */
  webYadoEntryRestrictions: (obj?: I18nArgumentType) =>
    translation('WebYado-EntryRestrictions', obj),
  /* {{time}}到着予定 */
  webYadoEstimatedArrivalTime: (obj?: I18nArgumentType) =>
    translation('WebYado-EstimatedArrivalTime', obj),
  /* 評価 */
  webYadoEvaluation: (obj?: I18nArgumentType) => translation('WebYado-Evaluation', obj),
  /* 良い */
  webYadoEvaluationFariyGood: (obj?: I18nArgumentType) =>
    translation('WebYado-EvaluationFariyGood', obj),
  /* とても良い */
  webYadoEvaluationVeryGood: (obj?: I18nArgumentType) =>
    translation('WebYado-EvaluationVeryGood', obj),
  /* 有効期限 */
  webYadoExpirationDate: (obj?: I18nArgumentType) => translation('WebYado-ExpirationDate', obj),
  /* エキストラベッド */
  webYadoExtraBed: (obj?: I18nArgumentType) => translation('WebYado-ExtraBed', obj),
  /* 宿泊者特典 */
  webYadoExtraService: (obj?: I18nArgumentType) => translation('WebYado-ExtraService', obj),
  /* フェイスタオル */
  webYadoFaceTowel: (obj?: I18nArgumentType) => translation('WebYado-FaceTowel', obj),
  /* 設備情報 */
  webYadoFacilities: (obj?: I18nArgumentType) => translation('WebYado-Facilities', obj),
  /* 設備 */
  webYadoFacility: (obj?: I18nArgumentType) => translation('WebYado-Facility', obj),
  /* 施設情報 */
  webYadoFacilityInfo: (obj?: I18nArgumentType) => translation('WebYado-FacilityInfo', obj),
  /* {{feature}} | WAmazingでは10,000件を超えるホテルを掲載しています。日本最大規模の掲載数なので、理想のホテルが必ず見つかります。 */
  webYadoFeatureDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-FeatureDescription', obj),
  /* {{feature}},日本,旅行,宿,宿泊,旅館,ホテル,ビジネスホテル,リゾート */
  webYadoFeatureKeywords: (obj?: I18nArgumentType) => translation('WebYado-FeatureKeywords', obj),
  /* WAmazing温泉宿予約の特徴 */
  webYadoFeatureOfWamazingOnsen: (obj?: I18nArgumentType) =>
    translation('WebYado-FeatureOfWamazingOnsen', obj),
  /* {{feature}}の旅館・ホテルをお得に予約 WAmazing */
  webYadoFeatureTitle: (obj?: I18nArgumentType) => translation('WebYado-FeatureTitle', obj),
  /* 文章を閉じる */
  webYadoFeaturedCloseText: (obj?: I18nArgumentType) =>
    translation('WebYado-FeaturedCloseText', obj),
  /* おすすめ温泉宿特集 */
  webYadoFeaturedHeading: (obj?: I18nArgumentType) => translation('WebYado-FeaturedHeading', obj),
  /* 北海道や山陰・北陸地方の日本海側には、カニの産地がたくさんあり、高級食材として日本人に愛されてきました。ズワイガニ、タラバガニ、毛ガニが三大カニと言われ、ズワイガニは産地によって呼び名が変わり、訪日外国人にも「ブランドガニ」として人気です。資源保護のためカニは年間で漁獲できる期間が決まっており、11月から3月がシーズンとなっています。今回は、そんなカニ料理が楽しめる日本の温泉宿を厳選して紹介します。 */
  webYadoFeaturedMetaDescriptionKani201910: (obj?: I18nArgumentType) =>
    translation('WebYado-FeaturedMetaDescriptionKani201910', obj),
  /* 日本の温泉宿では、男女に分かれた大浴場で入浴することが一般的です。カップルや家族だけのプライベートな空間で温泉入浴を楽しみたい人は、時間限定で温泉を貸し切ることができる「貸切風呂・家族風呂」のある宿をオススメします。今回は、そんな貸切風呂・家族風呂が楽しめる日本の温泉宿を厳選して紹介します。なお、本ページに掲載中の宿はWAmazingなら事前予約の手配も可能です。ご希望の場合はお問い合わせください。 */
  webYadoFeaturedMetaDescriptionPrivate201910: (obj?: I18nArgumentType) =>
    translation('WebYado-FeaturedMetaDescriptionPrivate201910', obj),
  /* 四季折々の雄大な自然を楽しみながら過ごす温泉宿でのひとときは、まさに至福です。今回は、露天風呂や大浴場からオーシャンビューやマウンテンビュー、リバービューが楽しめる日本の温泉宿を厳選して紹介します。 */
  webYadoFeaturedMetaDescriptionView201910: (obj?: I18nArgumentType) =>
    translation('WebYado-FeaturedMetaDescriptionView201910', obj),
  /* 大阪府、京都府、神戸市のある兵庫県を有する関西地方には、有馬温泉や城崎温泉、雄琴温泉などの日本を代表する温泉地があります。また、ブランド和牛の産地でもあり、関西三大ブランド牛である「松坂牛」「神戸牛」「近江牛」は、日本を代表する和牛でもあります。今回は、そんな和牛が楽しめる関西エリアの宿を厳選して紹介します。 */
  webYadoFeaturedMetaDescriptionWagyu201911: (obj?: I18nArgumentType) =>
    translation('WebYado-FeaturedMetaDescriptionWagyu201911', obj),
  /* 日本,旅行,宿泊施設,ホテル,リゾート,カニ,ズワイガニ,タラバガニ,毛ガニ */
  webYadoFeaturedMetaKeywordsKani201910: (obj?: I18nArgumentType) =>
    translation('WebYado-FeaturedMetaKeywordsKani201910', obj),
  /* 日本,旅行,宿泊施設,ホテル,リゾート,貸切風呂,家族風呂 */
  webYadoFeaturedMetaKeywordsPrivate201910: (obj?: I18nArgumentType) =>
    translation('WebYado-FeaturedMetaKeywordsPrivate201910', obj),
  /* 日本,旅行,宿泊施設,ホテル,リゾート */
  webYadoFeaturedMetaKeywordsView201910: (obj?: I18nArgumentType) =>
    translation('WebYado-FeaturedMetaKeywordsView201910', obj),
  /* 日本,旅行,宿泊施設,ホテル,リゾート,和牛,有馬温泉,城崎温泉,雄琴温泉,松坂牛,神戸牛,近江牛 */
  webYadoFeaturedMetaKeywordsWagyu201911: (obj?: I18nArgumentType) =>
    translation('WebYado-FeaturedMetaKeywordsWagyu201911', obj),
  /* ついにカニ漁解禁！カニ料理が楽しめる宿 | 厳選された日本の温泉宿を、グルメ・お迎えサービス付きで予約 - WAmazing */
  webYadoFeaturedMetaTitleKani201910: (obj?: I18nArgumentType) =>
    translation('WebYado-FeaturedMetaTitleKani201910', obj),
  /* 事前予約ができる！貸切風呂・家族風呂のある宿 */
  webYadoFeaturedMetaTitlePrivate201910: (obj?: I18nArgumentType) =>
    translation('WebYado-FeaturedMetaTitlePrivate201910', obj),
  /* 雄大な自然を楽しめる！眺望自慢の宿 | 厳選された日本の温泉宿を、グルメ・お迎えサービス付きで予約 - WAmazing */
  webYadoFeaturedMetaTitleView201910: (obj?: I18nArgumentType) =>
    translation('WebYado-FeaturedMetaTitleView201910', obj),
  /* 関西でブランド牛が食べられる宿 */
  webYadoFeaturedMetaTitleWagyu201911: (obj?: I18nArgumentType) =>
    translation('WebYado-FeaturedMetaTitleWagyu201911', obj),
  /* 文章の続きを読む */
  webYadoFeaturedOpenText: (obj?: I18nArgumentType) => translation('WebYado-FeaturedOpenText', obj),
  /* 北海道や山陰・北陸地方の日本海側には、カニの産地がたくさんあり、高級食材として日本人に愛されてきました。ズワイガニ、タラバガニ、毛ガニが三大カニと言われ、ズワイガニは産地によって呼び名が変わり、訪日外国人にも「ブランドガニ」として人気です。資源保護のためカニは年間で漁獲できる期間が決まっており、11月から3月がシーズンとなっています。今回は、そんなカニ料理が楽しめる日本の温泉宿を厳選して紹介します。 */
  webYadoFeaturedTextKani201910: (obj?: I18nArgumentType) =>
    translation('WebYado-FeaturedTextKani201910', obj),
  /* 日本の温泉宿では、男女に分かれた大浴場で入浴することが一般的です。カップルや家族だけのプライベートな空間で温泉入浴を楽しみたい人は、時間限定で温泉を貸し切ることができる「貸切風呂・家族風呂」のある宿をオススメします。今回は、そんな貸切風呂・家族風呂が楽しめる日本の温泉宿を厳選して紹介します。なお、本ページに掲載中の宿はWAmazingなら事前予約の手配も可能です。ご希望の場合はお問い合わせください。 */
  webYadoFeaturedTextPrivate201910: (obj?: I18nArgumentType) =>
    translation('WebYado-FeaturedTextPrivate201910', obj),
  /* 四季折々の雄大な自然を楽しみながら過ごす温泉宿でのひとときは、まさに至福です。今回は、露天風呂や大浴場からオーシャンビューやマウンテンビュー、リバービューが楽しめる日本の温泉宿を厳選して紹介します。 */
  webYadoFeaturedTextView201910: (obj?: I18nArgumentType) =>
    translation('WebYado-FeaturedTextView201910', obj),
  /* 大阪府、京都府、神戸市のある兵庫県を有する関西地方には、有馬温泉や城崎温泉、雄琴温泉などの日本を代表する温泉地があります。また、ブランド和牛の産地でもあり、関西三大ブランド牛である「松坂牛」「神戸牛」「近江牛」は、日本を代表する和牛でもあります。今回は、そんな和牛が楽しめる関西エリアの宿を厳選して紹介します。 */
  webYadoFeaturedTextWagyu201911: (obj?: I18nArgumentType) =>
    translation('WebYado-FeaturedTextWagyu201911', obj),
  /* ついにカニ漁解禁！カニ料理が楽しめる宿 */
  webYadoFeaturedTitleKani201910: (obj?: I18nArgumentType) =>
    translation('WebYado-FeaturedTitleKani201910', obj),
  /* 事前予約ができる！貸切風呂・家族風呂のある宿 */
  webYadoFeaturedTitlePrivate201910: (obj?: I18nArgumentType) =>
    translation('WebYado-FeaturedTitlePrivate201910', obj),
  /* 雄大な自然を楽しめる！眺望自慢の宿 */
  webYadoFeaturedTitleView201910: (obj?: I18nArgumentType) =>
    translation('WebYado-FeaturedTitleView201910', obj),
  /* 関西でブランド牛が食べられる宿 */
  webYadoFeaturedTitleWagyu201911: (obj?: I18nArgumentType) =>
    translation('WebYado-FeaturedTitleWagyu201911', obj),
  /* 費用 */
  webYadoFee: (obj?: I18nArgumentType) => translation('WebYado-Fee', obj),
  /* 女性用 */
  webYadoFemaleBathing: (obj?: I18nArgumentType) => translation('WebYado-FemaleBathing', obj),
  /* 絞り込み検索 */
  webYadoFilterAndSearch: (obj?: I18nArgumentType) => translation('WebYado-FilterAndSearch', obj),
  /* 都道府県で絞り込む */
  webYadoFilterByPrefecture: (obj?: I18nArgumentType) =>
    translation('WebYado-FilterByPrefecture', obj),
  /* 絞り込み */
  webYadoFilterSearchResult: (obj?: I18nArgumentType) =>
    translation('WebYado-FilterSearchResult', obj),
  /* {{subject}}を都道府県で絞り込む */
  webYadoFilterSubjectByPrefecture: (obj?: I18nArgumentType) =>
    translation('WebYado-FilterSubjectByPrefecture', obj),
  /* 空室検索 */
  webYadoFindEmptyRoom: (obj?: I18nArgumentType) => translation('WebYado-FindEmptyRoom', obj),
  /* 名 */
  webYadoFirstName: (obj?: I18nArgumentType) => translation('WebYado-FirstName', obj),
  /* 釣り */
  webYadoFishing: (obj?: I18nArgumentType) => translation('WebYado-Fishing', obj),
  /* フィットネス */
  webYadoFitness: (obj?: I18nArgumentType) => translation('WebYado-Fitness', obj),
  /* 「玩尽日本 滑雪・温泉社」アカウントをフォロー */
  webYadoFollowWechatAccount: (obj?: I18nArgumentType) =>
    translation('WebYado-FollowWechatAccount', obj),
  /* 以下のブランドのカードが利用できます */
  webYadoFollowingCardsAreAvailable: (obj?: I18nArgumentType) =>
    translation('WebYado-FollowingCardsAreAvailable', obj),
  /* 食事&ドリンク */
  webYadoFoodAndDrink: (obj?: I18nArgumentType) => translation('WebYado-FoodAndDrink', obj),
  /* ※お子さまを含むご予約にあたっては、【年齢に関する特記事項】を必ずご確認ください。 */
  webYadoFoodNoticeFour: (obj?: I18nArgumentType) => translation('WebYado-FoodNoticeFour', obj),
  /* ※季節や仕入れ状況により予告なく食材・メニューが変更される場合がございますので、予めご了承のうえご予約ください。 */
  webYadoFoodNoticeOne: (obj?: I18nArgumentType) => translation('WebYado-FoodNoticeOne', obj),
  /* ※お子さまの食事メニューは内容が異なる場合がございます。 */
  webYadoFoodNoticeThree: (obj?: I18nArgumentType) => translation('WebYado-FoodNoticeThree', obj),
  /* ※アレルギーによるメニュー変更対応については、必ず{{deadline}}日前までにWAmazingへお問い合わせください。（対応できない場合がございます） */
  webYadoFoodNoticeTwo: (obj?: I18nArgumentType) => translation('WebYado-FoodNoticeTwo', obj),
  /* 無料 */
  webYadoForFree: (obj?: I18nArgumentType) => translation('WebYado-ForFree', obj),
  /* このまま決済へ進む */
  webYadoForwardPayment: (obj?: I18nArgumentType) => translation('WebYado-ForwardPayment', obj),
  /* 最後のステップに進む */
  webYadoForwardToFinalStep: (obj?: I18nArgumentType) =>
    translation('WebYado-ForwardToFinalStep', obj),
  /* 次のステップに進む */
  webYadoForwardToNextStep: (obj?: I18nArgumentType) =>
    translation('WebYado-ForwardToNextStep', obj),
  /* 4桁の数字 */
  webYadoFourDigit: (obj?: I18nArgumentType) => translation('WebYado-FourDigit', obj),
  /* 無料 */
  webYadoFree: (obj?: I18nArgumentType) => translation('WebYado-Free', obj),
  /* キャンセル無料 */
  webYadoFreeCancellation: (obj?: I18nArgumentType) => translation('WebYado-FreeCancellation', obj),
  /* 予約キャンセルが無料なので日本旅行の計画が柔軟に立てられます */
  webYadoFreeCancellationHelpYourFlexibleTravelPlan: (obj?: I18nArgumentType) =>
    translation('WebYado-FreeCancellationHelpYourFlexibleTravelPlan', obj),
  /* キャンセル料無料のプラン有 */
  webYadoFreeCancellationPlan: (obj?: I18nArgumentType) =>
    translation('WebYado-FreeCancellationPlan', obj),
  /* ドリンク無料 */
  webYadoFreeDrink: (obj?: I18nArgumentType) => translation('WebYado-FreeDrink', obj),
  /* 無料SIMカード */
  webYadoFreeSimCard: (obj?: I18nArgumentType) => translation('WebYado-FreeSimCard', obj),
  /* 無料Wi-Fi */
  webYadoFreeWifi: (obj?: I18nArgumentType) => translation('WebYado-FreeWifi', obj),
  /*  */
  webYadoFrench: (obj?: I18nArgumentType) => translation('WebYado-French', obj),
  /* フランス料理 */
  webYadoFrenchDinner: (obj?: I18nArgumentType) => translation('WebYado-FrenchDinner', obj),
  /* 開始 */
  webYadoFrom: (obj?: I18nArgumentType) => translation('WebYado-From', obj),
  /* フロントデスク(24時間対応) */
  webYadoFrontDesk24: (obj?: I18nArgumentType) => translation('WebYado-FrontDesk24', obj),
  /* 布団 */
  webYadoFutonBed: (obj?: I18nArgumentType) => translation('WebYado-FutonBed', obj),
  /* 性別 */
  webYadoGender: (obj?: I18nArgumentType) => translation('WebYado-Gender', obj),
  /* エラーが発生しました。しばらくたってから再度お試しくださいませ。 */
  webYadoGeneralError: (obj?: I18nArgumentType) => translation('WebYado-GeneralError', obj),
  /* 空港で500MB付きの無料SIMカードを受け取ることができます。 */
  webYadoGetFreeSimCardAtAirport: (obj?: I18nArgumentType) =>
    translation('WebYado-GetFreeSimCardAtAirport', obj),
  /* 売店・ショップ */
  webYadoGiftShop: (obj?: I18nArgumentType) => translation('WebYado-GiftShop', obj),
  /* ゴルフ */
  webYadoGolf: (obj?: I18nArgumentType) => translation('WebYado-Golf', obj),
  /* [object Object] */
  webYadoGotResultOfYado: (obj?: I18nArgumentType) => translation('WebYado-GotResultOfYado', obj),
  /* 宿泊者情報 */
  webYadoGuestInformation: (obj?: I18nArgumentType) => translation('WebYado-GuestInformation', obj),
  /* 大人の人数を性別で入力してください */
  webYadoGuestNumberInputByGender: (obj?: I18nArgumentType) =>
    translation('WebYado-GuestNumberInputByGender', obj),
  /* 部屋食 */
  webYadoGuestRoomMeal: (obj?: I18nArgumentType) => translation('WebYado-GuestRoomMeal', obj),
  /* 客室に専用の温泉がある */
  webYadoGuestRoomPrivateHotSpringAvailable: (obj?: I18nArgumentType) =>
    translation('WebYado-GuestRoomPrivateHotSpringAvailable', obj),
  /* 客室 */
  webYadoGuestRooms: (obj?: I18nArgumentType) => translation('WebYado-GuestRooms', obj),
  /* 利用者の検温を実施している */
  webYadoGuestTemperatureCheck: (obj?: I18nArgumentType) =>
    translation('WebYado-GuestTemperatureCheck', obj),
  /* ジム */
  webYadoGymnasium: (obj?: I18nArgumentType) => translation('WebYado-Gymnasium', obj),
  /* くし */
  webYadoHairbrush: (obj?: I18nArgumentType) => translation('WebYado-Hairbrush', obj),
  /* ドライヤー */
  webYadoHairdryer: (obj?: I18nArgumentType) => translation('WebYado-Hairdryer', obj),
  /* ザ・プリンス箱根芦ノ湖 */
  webYadoHakonePrince: (obj?: I18nArgumentType) => translation('WebYado-HakonePrince', obj),
  /* 箱根の自然を満喫できる客室や地元食材を活かしたお食事。庭園をお散歩したあとは露天風呂で癒しのひととき♪隣接する箱根園には駒ヶ岳ロープウェー、遊覧船、水族館などアクティビティも充実！ */
  webYadoHakonePrinceDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-HakonePrinceDescription', obj),
  /* 半角英字で入力してください。 */
  webYadoHalfWidthRule: (obj?: I18nArgumentType) => translation('WebYado-HalfWidthRule', obj),
  /* 日本の旅館・ホテル宿泊予約 */
  webYadoHeaderLogoCaption: (obj?: I18nArgumentType) =>
    translation('WebYado-HeaderLogoCaption', obj),
  /* 祝日 */
  webYadoHoliday: (obj?: I18nArgumentType) => translation('WebYado-Holiday', obj),
  /* 乗馬 */
  webYadoHorseRiding: (obj?: I18nArgumentType) => translation('WebYado-HorseRiding', obj),
  /* ※脱衣所・浴室内でのカメラ撮影はご遠慮ください。 
また、スマートフォン・携帯電話・カメラ等は持ち込まないようお願いいたします。 */
  webYadoHotSpringFacilityNotice: (obj?: I18nArgumentType) =>
    translation('WebYado-HotSpringFacilityNotice', obj),
  /* {{subject}}の温泉 */
  webYadoHotSpringInSubject: (obj?: I18nArgumentType) =>
    translation('WebYado-HotSpringInSubject', obj),
  /* ホテル */
  webYadoHotel: (obj?: I18nArgumentType) => translation('WebYado-Hotel', obj),
  /* 決済が完了されると、ご宿泊が可能になります。  */
  webYadoHotelAvailableWhenPaymentCompleted: (obj?: I18nArgumentType) =>
    translation('WebYado-HotelAvailableWhenPaymentCompleted', obj),
  /* ホテル予約 */
  webYadoHotelBooking: (obj?: I18nArgumentType) => translation('WebYado-HotelBooking', obj),
  /* SIMがもらえる日本の旅館・ホテル宿泊予約 */
  webYadoHotelBookingWithFreeSimCard: (obj?: I18nArgumentType) =>
    translation('WebYado-HotelBookingWithFreeSimCard', obj),
  /* {{hotelCount}}軒 */
  webYadoHotelCountWithUnit: (obj?: I18nArgumentType) =>
    translation('WebYado-HotelCountWithUnit', obj),
  /* 兵衛向陽閣 */
  webYadoHyoe: (obj?: I18nArgumentType) => translation('WebYado-Hyoe', obj),
  /* 創業700年の歴史と伝統が宿る老舗旅館で、湯巡りと日本のおもてなしを体感してください。 */
  webYadoHyoeDescription: (obj?: I18nArgumentType) => translation('WebYado-HyoeDescription', obj),
  /* 製氷機 */
  webYadoIceMaker: (obj?: I18nArgumentType) => translation('WebYado-IceMaker', obj),
  /* 囲碁 */
  webYadoIgo: (obj?: I18nArgumentType) => translation('WebYado-Igo', obj),
  /* ※掲載画像はイメージとなり、実際と異なる場合があります */
  webYadoImageAnnotation: (obj?: I18nArgumentType) => translation('WebYado-ImageAnnotation', obj),
  /* 客室内でのチェックインに対応している */
  webYadoInRoomCheckIn: (obj?: I18nArgumentType) => translation('WebYado-InRoomCheckIn', obj),
  /* 客室内でのチェックアウトに対応している */
  webYadoInRoomCheckOut: (obj?: I18nArgumentType) => translation('WebYado-InRoomCheckOut', obj),
  /* 客室内でのチェックインに対応している */
  webYadoInRoomCheckin: (obj?: I18nArgumentType) => translation('WebYado-InRoomCheckin', obj),
  /* 客室内でのチェックアウトに対応している */
  webYadoInRoomCheckout: (obj?: I18nArgumentType) => translation('WebYado-InRoomCheckout', obj),
  /* 部屋食・レストラン */
  webYadoInRoomDiningRestaurant: (obj?: I18nArgumentType) =>
    translation('WebYado-InRoomDiningRestaurant', obj),
  /* 客室露天風呂付 */
  webYadoInRoomOpenAirBath: (obj?: I18nArgumentType) =>
    translation('WebYado-InRoomOpenAirBath', obj),
  /* 屋内プール */
  webYadoIndoorSwimmingPool: (obj?: I18nArgumentType) =>
    translation('WebYado-IndoorSwimmingPool', obj),
  /* ご好評につき日本全国にエリア拡大 */
  webYadoInfinityAreaExpandedLong: (obj?: I18nArgumentType) =>
    translation('WebYado-InfinityAreaExpandedLong', obj),
  /* ご好評につきエリア拡大 */
  webYadoInfinityAreaExpandedShort: (obj?: I18nArgumentType) =>
    translation('WebYado-InfinityAreaExpandedShort', obj),
  /* 日本全国が対象 */
  webYadoInfinityJapanAllArea: (obj?: I18nArgumentType) =>
    translation('WebYado-InfinityJapanAllArea', obj),
  /* 使い放題のSIMカード */
  webYadoInfinitySimCard: (obj?: I18nArgumentType) => translation('WebYado-InfinitySimCard', obj),
  /* 予約情報入力① */
  webYadoInputBookingInformationOne: (obj?: I18nArgumentType) =>
    translation('WebYado-InputBookingInformationOne', obj),
  /* 予約情報入力②・最終確認 */
  webYadoInputBookingInformationTwoFinalCheck: (obj?: I18nArgumentType) =>
    translation('WebYado-InputBookingInformationTwoFinalCheck', obj),
  /* お問合わせ */
  webYadoInquiry: (obj?: I18nArgumentType) => translation('WebYado-Inquiry', obj),
  /* 被保険者情報 */
  webYadoInsuranceDetails: (obj?: I18nArgumentType) => translation('WebYado-InsuranceDetails', obj),
  /* 保険者 */
  webYadoInsurancePerson: (obj?: I18nArgumentType) => translation('WebYado-InsurancePerson', obj),
  /* インターネット */
  webYadoInternetInformation: (obj?: I18nArgumentType) =>
    translation('WebYado-InternetInformation', obj),
  /*  */
  webYadoItalian: (obj?: I18nArgumentType) => translation('WebYado-Italian', obj),
  /* イタリア料理 */
  webYadoItalianDinner: (obj?: I18nArgumentType) => translation('WebYado-ItalianDinner', obj),
  /* [object Object] */
  webYadoItem: (obj?: I18nArgumentType) => translation('WebYado-Item', obj),
  /* 三朝温泉 依山楼岩崎 */
  webYadoIzanroIwasaki: (obj?: I18nArgumentType) => translation('WebYado-IzanroIwasaki', obj),
  /* 夏の岩牡蠣や冬の松葉蟹、鳥取で肥育された黒毛和牛など特別な食材はもちろん、地元の野菜も使用し、鳥取のおいしいを堪能。 */
  webYadoIzanroIwasakiDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-IzanroIwasakiDescription', obj),
  /* （日本時間） */
  webYadoJapanStandardTime: (obj?: I18nArgumentType) =>
    translation('WebYado-JapanStandardTime', obj),
  /*  */
  webYadoJapaneseKaiseki: (obj?: I18nArgumentType) => translation('WebYado-JapaneseKaiseki', obj),
  /* 懐石料理 */
  webYadoJapaneseKaisekiDinner: (obj?: I18nArgumentType) =>
    translation('WebYado-JapaneseKaisekiDinner', obj),
  /*  */
  webYadoJapaneseOther: (obj?: I18nArgumentType) => translation('WebYado-JapaneseOther', obj),
  /* 日本料理 */
  webYadoJapaneseOtherDinner: (obj?: I18nArgumentType) =>
    translation('WebYado-JapaneseOtherDinner', obj),
  /* 和室 */
  webYadoJapaneseStyleRoom: (obj?: I18nArgumentType) =>
    translation('WebYado-JapaneseStyleRoom', obj),
  /* 和洋室 */
  webYadoJapaneseWesternStyleRoom: (obj?: I18nArgumentType) =>
    translation('WebYado-JapaneseWesternStyleRoom', obj),
  /* JR-EAST公認サービス */
  webYadoJrEastOfficialService: (obj?: I18nArgumentType) =>
    translation('WebYado-JrEastOfficialService', obj),
  /* この宿に泊まるならJR東京ワイドパスがお得!! */
  webYadoJrTWPDescription: (obj?: I18nArgumentType) => translation('WebYado-JrTWPDescription', obj),
  /* 今すぐ購入 */
  webYadoJrWestBuyNow: (obj?: I18nArgumentType) => translation('WebYado-JrWestBuyNow', obj),
  /* この宿に泊まるならJR西日本鉄道周遊券がお得!! */
  webYadoJrWestDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-JrWestDescription', obj),
  /* JR-WEST公認サービス */
  webYadoJrWestOfficialService: (obj?: I18nArgumentType) =>
    translation('WebYado-JrWestOfficialService', obj),
  /* 加賀屋 */
  webYadoKagaya: (obj?: I18nArgumentType) => translation('WebYado-Kagaya', obj),
  /* 春夏秋冬の食材を吟味し、能登の恵みを加賀屋ならではの味とおもてなしで。 */
  webYadoKagayaDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-KagayaDescription', obj),
  /* カラオケ */
  webYadoKaraoke: (obj?: I18nArgumentType) => translation('WebYado-Karaoke', obj),
  /* キングベッド */
  webYadoKingBed: (obj?: I18nArgumentType) => translation('WebYado-KingBed', obj),
  /* 草津ナウリゾートホテル */
  webYadoKusatsu: (obj?: I18nArgumentType) => translation('WebYado-Kusatsu', obj),
  /* 群馬地産の食材を中心に和・洋・中・フランス料理とバリエーション豊かな料理を用意。ホテル自慢の味です。 */
  webYadoKusatsuDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-KusatsuDescription', obj),
  /* 下4桁 */
  webYadoLastFourDigits: (obj?: I18nArgumentType) => translation('WebYado-LastFourDigits', obj),
  /* 姓 */
  webYadoLastName: (obj?: I18nArgumentType) => translation('WebYado-LastName', obj),
  /* レイトチェックアウト */
  webYadoLateCheckout: (obj?: I18nArgumentType) => translation('WebYado-LateCheckout', obj),
  /* クリーニングサービス */
  webYadoLaundryService: (obj?: I18nArgumentType) => translation('WebYado-LaundryService', obj),
  /* 旅行中のインターネットはWAmazingにおまかせ！ */
  webYadoLeaveItAllToWamazing: (obj?: I18nArgumentType) =>
    translation('WebYado-LeaveItAllToWamazing', obj),
  /* 1泊の最安値 */
  webYadoLowestPriceForOneDay: (obj?: I18nArgumentType) =>
    translation('WebYado-LowestPriceForOneDay', obj),
  /* 他宿泊予約サービスで、WAmazingの提供するものより低い料金が見つかった場合、割引・返品が可能です。 */
  webYadoLowestPriceGuaranteeDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-LowestPriceGuaranteeDescription', obj),
  /* 最低価格保証 */
  webYadoLowestPriceGuaranteed: (obj?: I18nArgumentType) =>
    translation('WebYado-LowestPriceGuaranteed', obj),
  /* 他のサービスより金額が高い場合はお問い合わせ下さい。 */
  webYadoLowestPriceGuaranteedDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-LowestPriceGuaranteedDescription', obj),
  /* 荷物預かり所 */
  webYadoLuggageStorageService: (obj?: I18nArgumentType) =>
    translation('WebYado-LuggageStorageService', obj),
  /* 麻雀 */
  webYadoMahjong: (obj?: I18nArgumentType) => translation('WebYado-Mahjong', obj),
  /* 主な設備 */
  webYadoMainFacilities: (obj?: I18nArgumentType) => translation('WebYado-MainFacilities', obj),
  /* そば打ち */
  webYadoMakingSoba: (obj?: I18nArgumentType) => translation('WebYado-MakingSoba', obj),
  /* 男性用 */
  webYadoMaleBathing: (obj?: I18nArgumentType) => translation('WebYado-MaleBathing', obj),
  /* 運営体制 */
  webYadoManagement: (obj?: I18nArgumentType) => translation('WebYado-Management', obj),
  /* マップ */
  webYadoMap: (obj?: I18nArgumentType) => translation('WebYado-Map', obj),
  /* 南紀白浜マリオットホテル */
  webYadoMarriott: (obj?: I18nArgumentType) => translation('WebYado-Marriott', obj),
  /* 白浜の豊かな海が育んだ魚介や熊野牛、紀州うめぶた、地元の無農薬野菜など旬の食材を生かした料理を大海原を眺めながら堪能。 */
  webYadoMarriottDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-MarriottDescription', obj),
  /* マッサージ */
  webYadoMassage: (obj?: I18nArgumentType) => translation('WebYado-Massage', obj),
  /* [object Object] */
  webYadoMaxCapacity: (obj?: I18nArgumentType) => translation('WebYado-MaxCapacity', obj),
  /* 食事 */
  webYadoMeal: (obj?: I18nArgumentType) => translation('WebYado-Meal', obj),
  /* 食事&ドリンク */
  webYadoMealAndDrink: (obj?: I18nArgumentType) => translation('WebYado-MealAndDrink', obj),
  /* お食事 */
  webYadoMealFilter: (obj?: I18nArgumentType) => translation('WebYado-MealFilter', obj),
  /* 推奨 */
  webYadoMealRecommend: (obj?: I18nArgumentType) => translation('WebYado-MealRecommend', obj),
  /* 医務室 */
  webYadoMedicalOffice: (obj?: I18nArgumentType) => translation('WebYado-MedicalOffice', obj),
  /* 名湯舎 創 */
  webYadoMeitoya: (obj?: I18nArgumentType) => translation('WebYado-Meitoya', obj),
  /* 蔵王の旬の味覚や、山形の郷土料理をふんだんに取り入れた和食膳と山形名物を満足いくまで味わえるバイキングをご用意。 */
  webYadoMeitoyaDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-MeitoyaDescription', obj),
  /* ※メニュー変更には別途費用がかかる場合がございます */
  webYadoMenuChangeCharge: (obj?: I18nArgumentType) => translation('WebYado-MenuChangeCharge', obj),
  /* ミニバー */
  webYadoMinibar: (obj?: I18nArgumentType) => translation('WebYado-Minibar', obj),
  /* [object Object] */
  webYadoMinutes: (obj?: I18nArgumentType) => translation('WebYado-Minutes', obj),
  /* 三朝館 */
  webYadoMisasakan: (obj?: I18nArgumentType) => translation('WebYado-Misasakan', obj),
  /* 鳥取ブランドである「松葉ガニ」や「鳥取和牛」など、贅をつくした地元の食材を美味しくいただける。 */
  webYadoMisasakanDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-MisasakanDescription', obj),
  /* 混浴 */
  webYadoMixedBathing: (obj?: I18nArgumentType) => translation('WebYado-MixedBathing', obj),
  /* 深山山荘 */
  webYadoMiyamaSansou: (obj?: I18nArgumentType) => translation('WebYado-MiyamaSansou', obj),
  /* 熊本県産の素材をふんだんに使ったお料理。素材からおいしいを感じられる。 */
  webYadoMiyamaSansouDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-MiyamaSansouDescription', obj),
  /* {{onsenArea}}について */
  webYadoModelAreaAbout: (obj?: I18nArgumentType) => translation('WebYado-ModelAreaAbout', obj),
  /* アクセス情報 */
  webYadoModelAreaAccess: (obj?: I18nArgumentType) => translation('WebYado-ModelAreaAccess', obj),
  /* 周辺の人気観光都市からのアクセス */
  webYadoModelAreaAccessFromOtherCities: (obj?: I18nArgumentType) =>
    translation('WebYado-ModelAreaAccessFromOtherCities', obj),
  /* {{onsenArea}}で子供連れファミリーによく予約される温泉宿には、{{yadoNames}}などがあります。 */
  webYadoModelAreaAnswerYadoChildFriendly: (obj?: I18nArgumentType) =>
    translation('WebYado-ModelAreaAnswerYadoChildFriendly', obj),
  /* {{onsenArea}}で駅から近く、予約数の多い温泉宿には、{{yadoNames}}などがあります。 */
  webYadoModelAreaAnswerYadoCloseToStation: (obj?: I18nArgumentType) =>
    translation('WebYado-ModelAreaAnswerYadoCloseToStation', obj),
  /* {{onsenArea}}でカップルによく予約される温泉宿には、{{yadoNames}}などがあります。 */
  webYadoModelAreaAnswerYadoCoupleFriendly: (obj?: I18nArgumentType) =>
    translation('WebYado-ModelAreaAnswerYadoCoupleFriendly', obj),
  /* {{onsenArea}}でこだわりのグルメが楽しめ、予約数の多い温泉宿には、{{yadoNames}}などがあります。 */
  webYadoModelAreaAnswerYadoDeliciousDinners: (obj?: I18nArgumentType) =>
    translation('WebYado-ModelAreaAnswerYadoDeliciousDinners', obj),
  /* {{onsenArea}}で評価の高い宿には、{{yadoNames}}などがあります。 */
  webYadoModelAreaAnswerYadoHighRate: (obj?: I18nArgumentType) =>
    translation('WebYado-ModelAreaAnswerYadoHighRate', obj),
  /* {{onsenArea}}でお迎え手配可能で、予約数の多い温泉宿には、{{yadoNames}}などがあります。 */
  webYadoModelAreaAnswerYadoPickupsAvailable: (obj?: I18nArgumentType) =>
    translation('WebYado-ModelAreaAnswerYadoPickupsAvailable', obj),
  /* {{onsenArea}}で貸切風呂が予約でき、予約数の多い温泉宿には、{{yadoNames}}などがあります。 */
  webYadoModelAreaAnswerYadoPrivateBathsReservable: (obj?: I18nArgumentType) =>
    translation('WebYado-ModelAreaAnswerYadoPrivateBathsReservable', obj),
  /* {{onsenArea}}でリーズナプルで、予約数の多い温泉宿には、{{yadoNames}}などがあります。 */
  webYadoModelAreaAnswerYadoReasonablePrice: (obj?: I18nArgumentType) =>
    translation('WebYado-ModelAreaAnswerYadoReasonablePrice', obj),
  /* {{onsenArea}}の温泉宿 */
  webYadoModelAreaDetailHeading: (obj?: I18nArgumentType) =>
    translation('WebYado-ModelAreaDetailHeading', obj),
  /* {{onsenArea}}にある温泉旅館・ホテルの予約ならWAmazing。最低価格保証。会員限定価格。ウェブ上から日本語不要で「無料お迎えサービス」「貸切風呂予約」「こだわりの夕食メニュー選択」が可能です！ */
  webYadoModelAreaDetailMetaDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-ModelAreaDetailMetaDescription', obj),
  /* {{onsenArea}}にある温泉旅館・ホテルの予約ならWAmazingで */
  webYadoModelAreaDetailMetaTitle: (obj?: I18nArgumentType) =>
    translation('WebYado-ModelAreaDetailMetaTitle', obj),
  /* 夕食が選べる宿 */
  webYadoModelAreaDinnerSelectableYado: (obj?: I18nArgumentType) =>
    translation('WebYado-ModelAreaDinnerSelectableYado', obj),
  /* 夕食が選べる宿一覧 */
  webYadoModelAreaDinnerSelectableYadoList: (obj?: I18nArgumentType) =>
    translation('WebYado-ModelAreaDinnerSelectableYadoList', obj),
  /* 宿泊に関するよくある質問 */
  webYadoModelAreaFaqAboutYado: (obj?: I18nArgumentType) =>
    translation('WebYado-ModelAreaFaqAboutYado', obj),
  /* {{onsenArea}}で味わうべきグルメ */
  webYadoModelAreaGourmetRecommendation: (obj?: I18nArgumentType) =>
    translation('WebYado-ModelAreaGourmetRecommendation', obj),
  /* さらに詳しい観光情報を記事で見る */
  webYadoModelAreaMoreMediaArticles: (obj?: I18nArgumentType) =>
    translation('WebYado-ModelAreaMoreMediaArticles', obj),
  /* お迎え手配ができる宿 */
  webYadoModelAreaPickupAvailableYado: (obj?: I18nArgumentType) =>
    translation('WebYado-ModelAreaPickupAvailableYado', obj),
  /* お迎えサービスが手配ができる宿一覧 */
  webYadoModelAreaPickupAvailableYadoList: (obj?: I18nArgumentType) =>
    translation('WebYado-ModelAreaPickupAvailableYadoList', obj),
  /* 人気の宿泊プラン */
  webYadoModelAreaPopularPlans: (obj?: I18nArgumentType) =>
    translation('WebYado-ModelAreaPopularPlans', obj),
  /* 人気宿ランキング */
  webYadoModelAreaPopularYadoRanking: (obj?: I18nArgumentType) =>
    translation('WebYado-ModelAreaPopularYadoRanking', obj),
  /* {{onsenArea}}の人気宿ランキング */
  webYadoModelAreaPopularYadoRankingInOnsenArea: (obj?: I18nArgumentType) =>
    translation('WebYado-ModelAreaPopularYadoRankingInOnsenArea', obj),
  /* 貸切風呂を予約できる宿 */
  webYadoModelAreaPrivateBathReservableYado: (obj?: I18nArgumentType) =>
    translation('WebYado-ModelAreaPrivateBathReservableYado', obj),
  /* 貸切風呂を予約できる宿一覧 */
  webYadoModelAreaPrivateBathReservableYadoList: (obj?: I18nArgumentType) =>
    translation('WebYado-ModelAreaPrivateBathReservableYadoList', obj),
  /* {{onsenArea}}で子供連れファミリーにおすすめの人気宿はどこですか？ */
  webYadoModelAreaQuestionYadoChildFriendly: (obj?: I18nArgumentType) =>
    translation('WebYado-ModelAreaQuestionYadoChildFriendly', obj),
  /* {{onsenArea}}で駅から近い人気宿はどこですか？ */
  webYadoModelAreaQuestionYadoCloseToStation: (obj?: I18nArgumentType) =>
    translation('WebYado-ModelAreaQuestionYadoCloseToStation', obj),
  /* {{onsenArea}}でカップルにおすすめの人気宿はどこですか？ */
  webYadoModelAreaQuestionYadoCoupleFriendly: (obj?: I18nArgumentType) =>
    translation('WebYado-ModelAreaQuestionYadoCoupleFriendly', obj),
  /* {{onsenArea}}でグルメを楽しめる人気宿はどこですか？ */
  webYadoModelAreaQuestionYadoDeliciousDinners: (obj?: I18nArgumentType) =>
    translation('WebYado-ModelAreaQuestionYadoDeliciousDinners', obj),
  /* {{onsenArea}}で評価の高い宿はどこですか？ */
  webYadoModelAreaQuestionYadoHighRate: (obj?: I18nArgumentType) =>
    translation('WebYado-ModelAreaQuestionYadoHighRate', obj),
  /* {{onsenArea}}でお迎え手配可能な人気宿はどこですか？ */
  webYadoModelAreaQuestionYadoPickupsAvailable: (obj?: I18nArgumentType) =>
    translation('WebYado-ModelAreaQuestionYadoPickupsAvailable', obj),
  /* {{onsenArea}}で貸切風呂を予約できる人気宿はどこですか？ */
  webYadoModelAreaQuestionYadoPrivateBathsReservable: (obj?: I18nArgumentType) =>
    translation('WebYado-ModelAreaQuestionYadoPrivateBathsReservable', obj),
  /* {{onsenArea}}でリーズナプルな人気宿はどこですか？ */
  webYadoModelAreaQuestionYadoReasonablePrice: (obj?: I18nArgumentType) =>
    translation('WebYado-ModelAreaQuestionYadoReasonablePrice', obj),
  /* {{number}}位 */
  webYadoModelAreaRankingNumber: (obj?: I18nArgumentType) =>
    translation('WebYado-ModelAreaRankingNumber', obj),
  /* 全ての記事を見る */
  webYadoModelAreaSeeAllArticles: (obj?: I18nArgumentType) =>
    translation('WebYado-ModelAreaSeeAllArticles', obj),
  /* 日本語ができなくても本場のサービスを満喫！ */
  webYadoModelAreaServiceIntroBody1: (obj?: I18nArgumentType) =>
    translation('WebYado-ModelAreaServiceIntroBody1', obj),
  /* 追加手数料なしで、WAmazingがあなたの代わりに宿泊施設へ手配します。 */
  webYadoModelAreaServiceIntroBody2: (obj?: I18nArgumentType) =>
    translation('WebYado-ModelAreaServiceIntroBody2', obj),
  /* 日本の旅館・ホテルが提供するサービスをWEBで簡単予約！ */
  webYadoModelAreaServiceIntroTitle: (obj?: I18nArgumentType) =>
    translation('WebYado-ModelAreaServiceIntroTitle', obj),
  /* WAmazingでは あなたの好みに合わせて温泉宿の夕飯の種類が選択可能です。 */
  webYadoModelAreaServiceValueOneBody: (obj?: I18nArgumentType) =>
    translation('WebYado-ModelAreaServiceValueOneBody', obj),
  /* 1.選べる贅沢な食事 */
  webYadoModelAreaServiceValueOneTitle: (obj?: I18nArgumentType) =>
    translation('WebYado-ModelAreaServiceValueOneTitle', obj),
  /* WAmazingで温泉宿を予約すると簡単に貸切温泉が手配できます。 */
  webYadoModelAreaServiceValueThreeBody: (obj?: I18nArgumentType) =>
    translation('WebYado-ModelAreaServiceValueThreeBody', obj),
  /* 3.貸切風呂で快適な温泉体験 */
  webYadoModelAreaServiceValueThreeTitle: (obj?: I18nArgumentType) =>
    translation('WebYado-ModelAreaServiceValueThreeTitle', obj),
  /* WAmazingで温泉宿を予約するとお迎えの手配が可能になります。 */
  webYadoModelAreaServiceValueTwoBody: (obj?: I18nArgumentType) =>
    translation('WebYado-ModelAreaServiceValueTwoBody', obj),
  /* 2.宿までのお迎えで快適な移動 */
  webYadoModelAreaServiceValueTwoTitle: (obj?: I18nArgumentType) =>
    translation('WebYado-ModelAreaServiceValueTwoTitle', obj),
  /* 周辺の観光スポット・アクティビティ */
  webYadoModelAreaSpotAndActivity: (obj?: I18nArgumentType) =>
    translation('WebYado-ModelAreaSpotAndActivity', obj),
  /* 周辺の温泉地 */
  webYadoModelAreaSurroundingArea: (obj?: I18nArgumentType) =>
    translation('WebYado-ModelAreaSurroundingArea', obj),
  /* {{onsenArea}}の天気 */
  webYadoModelAreaWeather: (obj?: I18nArgumentType) => translation('WebYado-ModelAreaWeather', obj),
  /* 変更期限切れ */
  webYadoModificationApplicationClosed: (obj?: I18nArgumentType) =>
    translation('WebYado-ModificationApplicationClosed', obj),
  /* 両替 */
  webYadoMoneyExchange: (obj?: I18nArgumentType) => translation('WebYado-MoneyExchange', obj),
  /* 月 */
  webYadoMonth: (obj?: I18nArgumentType) => translation('WebYado-Month', obj),
  /* 他 */
  webYadoMoreBenefits: (obj?: I18nArgumentType) => translation('WebYado-MoreBenefits', obj),
  /* 空港で500MBの無料SIMがもらえるだけでなく、WAmazingで宿を予約していただければ、一泊でも無制限SIMにアップグレードさせていただきます！せっかくなのでお早めのご予約を！旅行中のネット通信はWAmazingにお任せください！ */
  webYadoMoreBookingMoreData: (obj?: I18nArgumentType) =>
    translation('WebYado-MoreBookingMoreData', obj),
  /* ホテルの登録数は、日本全国10,000件以上！ */
  webYadoMoreThan10000HotelsRegistered: (obj?: I18nArgumentType) =>
    translation('WebYado-MoreThan10000HotelsRegistered', obj),
  /* [object Object] */
  webYadoNDaysBefore: (obj?: I18nArgumentType) => translation('WebYado-NDaysBefore', obj),
  /* ※半角英字で入力してください。スペースは含めないでください。 */
  webYadoNameValidationRule: (obj?: I18nArgumentType) =>
    translation('WebYado-NameValidationRule', obj),
  /* 近隣の観光スポット */
  webYadoNearbyAttractions: (obj?: I18nArgumentType) =>
    translation('WebYado-NearbyAttractions', obj),
  /* 近隣のホテル */
  webYadoNearbyHotels: (obj?: I18nArgumentType) => translation('WebYado-NearbyHotels', obj),
  /* 次の画面でクレジットカードでの決済を行います */
  webYadoNextStepCreditCardPayment: (obj?: I18nArgumentType) =>
    translation('WebYado-NextStepCreditCardPayment', obj),
  /* {{checkinDate}}〜{{checkoutDate}} / {{number}}泊 */
  webYadoNightsFromCheckinToCheckout: (obj?: I18nArgumentType) =>
    translation('WebYado-NightsFromCheckinToCheckout', obj),
  /* いいえ */
  webYadoNo: (obj?: I18nArgumentType) => translation('WebYado-No', obj),
  /* 朝食なし */
  webYadoNoBreakfast: (obj?: I18nArgumentType) => translation('WebYado-NoBreakfast', obj),
  /* 当サイト経由で手配が可能なため、事前に宿に電話して日本語で確認をとったりの手間がなく、スムーズに利用することができます。 */
  webYadoNoCallPickup: (obj?: I18nArgumentType) => translation('WebYado-NoCallPickup', obj),
  /* ご安心ください。費用は発生しません！ */
  webYadoNoChargeGuarantee: (obj?: I18nArgumentType) =>
    translation('WebYado-NoChargeGuarantee', obj),
  /* 夕食なし */
  webYadoNoDinner: (obj?: I18nArgumentType) => translation('WebYado-NoDinner', obj),
  /* 食事無し */
  webYadoNoMeal: (obj?: I18nArgumentType) => translation('WebYado-NoMeal', obj),
  /* 食事なし */
  webYadoNoMeals: (obj?: I18nArgumentType) => translation('WebYado-NoMeals', obj),
  /* ※場合によっては、対応できない場合もございます */
  webYadoNoMenuChanges: (obj?: I18nArgumentType) => translation('WebYado-NoMenuChanges', obj),
  /* 決済がなかった場合 */
  webYadoNoPaymentByDeadline: (obj?: I18nArgumentType) =>
    translation('WebYado-NoPaymentByDeadline', obj),
  /* 連絡なし */
  webYadoNoShow: (obj?: I18nArgumentType) => translation('WebYado-NoShow', obj),
  /* 禁煙 */
  webYadoNoSmoking: (obj?: I18nArgumentType) => translation('WebYado-NoSmoking', obj),
  /* キャンセルしない */
  webYadoNotCancelBooking: (obj?: I18nArgumentType) => translation('WebYado-NotCancelBooking', obj),
  /* 無し */
  webYadoNotServed: (obj?: I18nArgumentType) => translation('WebYado-NotServed', obj),
  /* クーポン利用なし */
  webYadoNotUseCoupon: (obj?: I18nArgumentType) => translation('WebYado-NotUseCoupon', obj),
  /* 備考 */
  webYadoNote: (obj?: I18nArgumentType) => translation('WebYado-Note', obj),
  /* 施設の補足 */
  webYadoNoteForFacilities: (obj?: I18nArgumentType) =>
    translation('WebYado-NoteForFacilities', obj),
  /* 施設からのお知らせ */
  webYadoNoticeFromAccommodation: (obj?: I18nArgumentType) =>
    translation('WebYado-NoticeFromAccommodation', obj),
  /* 人数 */
  webYadoNumberOfPeople: (obj?: I18nArgumentType) => translation('WebYado-NumberOfPeople', obj),
  /* 総客室数 */
  webYadoNumberOfRooms: (obj?: I18nArgumentType) => translation('WebYado-NumberOfRooms', obj),
  /* 和心の宿オーモリ */
  webYadoOmori: (obj?: I18nArgumentType) => translation('WebYado-Omori', obj),
  /* 群馬県地産地消にこだわり、季節感あふれる食材をふんだんに使った料理をいただける。 */
  webYadoOmoriDescription: (obj?: I18nArgumentType) => translation('WebYado-OmoriDescription', obj),
  /* 当日 */
  webYadoOnTheDay: (obj?: I18nArgumentType) => translation('WebYado-OnTheDay', obj),
  /* 英語のみOK、スペース不許可 */
  webYadoOnlyAcceptAlphabetAndNoSpaceAllowed: (obj?: I18nArgumentType) =>
    translation('WebYado-OnlyAcceptAlphabetAndNoSpaceAllowed', obj),
  /* 東山温泉・会津エリア */
  webYadoOnsenAreaAizu: (obj?: I18nArgumentType) => translation('WebYado-OnsenAreaAizu', obj),
  /* 会津エリアは1300年前に発見されたと言われる「東山温泉」を含む長い歴史のある温泉エリアで、東北地方の日本海側に位置します。福島空港からはバスと電車で、東京や仙台からは東北新幹線とJRでアクセスすることができます。周辺には昔ながらの面影を残す史跡が点在しており、史跡巡りを楽しめるほか、ソースカツ丼や会津喜多方ラーメンなどのグルメを楽しむこともできます。 */
  webYadoOnsenAreaAizuDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaAizuDescription', obj),
  /* 赤湯温泉 */
  webYadoOnsenAreaAkayu: (obj?: I18nArgumentType) => translation('WebYado-OnsenAreaAkayu', obj),
  /* 東京駅から山形新幹線で2.5時間のアクセス。赤湯駅からはホテル送迎で5分程度。山形県内では珍しく町中にあり、新幹線を降りてすぐに行けるアクセス便利な温泉地。ぶどう栽培が盛んで、観光果樹園でのぶどう狩りの他、地元産ブドウと使ったワインの郷としても知られる。 山形県内の多くのワイナリーが赤湯地域にあり、味比べをしながらの町歩きも楽しい。温泉宿、足湯、公衆浴場、ワイナリーなどがすべて徒歩や自転車で回れる距離にある。 赤湯温泉のある南陽市は季節ごとに桜、しゃくやく、バラ、菊などの様々な花を楽しむことができ、特に赤湯・烏帽子山公園の千本桜は日本のさくら100選にも選ばれ人気のスポットである。 */
  webYadoOnsenAreaAkayuDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaAkayuDescription', obj),
  /* 秋保温泉 */
  webYadoOnsenAreaAkiu: (obj?: I18nArgumentType) => translation('WebYado-OnsenAreaAkiu', obj),
  /* 杜の都仙台の奥座敷と称される秋保温泉の歴史は古く､今から約1500年前と伝えられています。当時の天皇の病がこの湯により全快され｢名取の御湯｣の称号を賜っています｡以来､全国にその名を知られ､その地を収めた大名・伊達家の入浴場もおかれました｡仙台中心部より車で30分ほどとアクセスもよく、今では豊かな自然環境と共に庶民の憩いの温泉として広く慕われています。仙台を中心とした周辺観光の拠点にお勧めです。 */
  webYadoOnsenAreaAkiuDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaAkiuDescription', obj),
  /* 有馬温泉 */
  webYadoOnsenAreaArima: (obj?: I18nArgumentType) => translation('WebYado-OnsenAreaArima', obj),
  /* 【大阪・京都周辺】日本の有史前に遡る三古泉の一つであり、太閤秀吉が愛した温泉地としても有名です。電車、バス、車でのアクセスにも恵まれており、大阪より1時間、京阪神地域の奥座敷として親しまれています。温泉街における飲食店、お土産店が充実していることも特徴です。「金泉」「赤湯」とよばれる赤褐色の含鉄強塩泉と、「銀泉」とよばれる無色のラジウム泉の2種類の温泉を楽しめます。世界的にも珍しい多くの成分が含まれた療養泉となっています。 */
  webYadoOnsenAreaArimaDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaArimaDescription', obj),
  /* 旭川・層雲峡エリア */
  webYadoOnsenAreaAsahikawaSounkyo: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaAsahikawaSounkyo', obj),
  /* 旭川空港から旭川駅まではバスで約40分、そこから層雲峡温泉まではバスで1.5時間のアクセス。北海道内で有数の峡谷美も自慢の温泉地。大雪山国立公園内、黒岳山麓の層雲峡に沿って20軒近いホテルや旅館が立ち並ぶ一大温泉街。道内きっての景勝地として知られる層雲峡は、石狩川上流に沿って約24㎞続く渓谷。 高さ600m以上の断崖絶壁と見事な柱状節理、アイヌ語で「ソウウンベツ（滝の多い川）」の地名どおりの「銀河の滝」や「流星の滝」に代表される名瀑など、訪れる者を圧倒する大自然がここにはある。 特に岩壁を真っ赤な紅葉が染める秋や、滝が氷結して巨大な氷柱となる厳冬の景色は圧巻。 */
  webYadoOnsenAreaAsahikawaSounkyoDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaAsahikawaSounkyoDescription', obj),
  /* 足摺・四万十エリア */
  webYadoOnsenAreaAshizuriShimanto: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaAshizuriShimanto', obj),
  /* 足摺温泉は日本の四国地方土佐清水市にある温泉地で、四国最南端に位置します。太平洋に突き出た足摺半島の先端には足摺岬があり、その周辺に温泉宿が点在しています。オーシャンビューの宿が自慢の宿が多く、特に日の出や日の入りの時間帯の温泉入浴は格別です。高知空港からはバスでJR高知駅まで40分程度、JR高知駅から足摺温泉まではバスや電車を乗り継いで3時間ほどでアクセスすることができます。日本最後の清流と呼ばれる四万十川もアクセス圏内にあり、リバークルーズも楽しめます。 */
  webYadoOnsenAreaAshizuriShimantoDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaAshizuriShimantoDescription', obj),
  /* 熱海温泉 */
  webYadoOnsenAreaAtami: (obj?: I18nArgumentType) => translation('WebYado-OnsenAreaAtami', obj),
  /* 熱海温泉は静岡県熱海市にある日本有数の温泉エリアで、日本の三大温泉の一つにも数えられています。相模湾に面する海沿いに旅館やホテルが立ち並ぶ風光明媚な熱海には、東京からは新幹線で35分、名古屋からは90分、大阪からも2時間程度でアクセスすることができます。熱海駅周辺には、温泉まんじゅうやおせんべいなどの食べ歩きが楽しめるほか、イカメンチやアジのたたき丼などの熱海のグルメを満喫することができます。また、ダイビングや星空ウォッチング、釣りなどのアクティビティが楽しめる初島までもフェリーで日帰りで行くことができます。 */
  webYadoOnsenAreaAtamiDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaAtamiDescription', obj),
  /* 淡路島エリア */
  webYadoOnsenAreaAwajishima: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaAwajishima', obj),
  /* アクセスが良く、関西空港や大阪・神戸からフェリーやバスで気軽に訪れることができる淡路島エリアは、関西近郊の一大リゾート地。春は菜の花等花がいっぱい花の島、夏の海水浴や、釣り等で自然を満喫できる。さらに淡路島はグルメが豊富。 鳴門海峡名物のうず潮に揉まれて身が引き締まった鳴門ダイや明石ダイ、伊勢エビが通年を通して楽しめるほか、夏にはハモ料理、冬にはフグ料理が名物。 */
  webYadoOnsenAreaAwajishimaDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaAwajishimaDescription', obj),
  /* あわら温泉 */
  webYadoOnsenAreaAwara: (obj?: I18nArgumentType) => translation('WebYado-OnsenAreaAwara', obj),
  /* あわら温泉は大阪駅から特急で約2時間、東京駅から北陸新幹線＋特急で約3時間のアクセス。100年以上の歴史ある福井県屈指の名温泉地は、多くの文人・著名人に愛された「関西の奥座敷」と呼ばれている。数奇屋造りの宿や独創的な近代旅館が、独特の風情を醸し出す。美しい庭園を誇る老舗宿も必見の価値あり。各宿自慢の庭園を眺めながら、日本海の幸をじっくりと味わう。ゆったり寛ぎの時間を過ごしたい。このエリアの人気スポットは、日本海の荒波が造り出した断崖絶壁「東尋坊」。世界三大奇勝にも選ばれ、国の天然記念物にも指定された福井を代表する名所だ。 */
  webYadoOnsenAreaAwaraDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaAwaraDescription', obj),
  /* 別府温泉 */
  webYadoOnsenAreaBeppu: (obj?: I18nArgumentType) => translation('WebYado-OnsenAreaBeppu', obj),
  /* 【福岡周辺】豊富な湧出量を誇る日本有数の温泉地である温泉の都・別府市には点在する数百の温泉が点在しています。別府温泉をはじめ、浜脇、観海寺、堀田、明礬、鉄輪、柴石、亀川の8つの温泉地からなる「別府八湯」は、それぞれ泉質が異なりいろんな温泉を楽しむことができます。電車、バスのアクセスも良く、夜の歓楽街も賑わいを見せます。近郊には阿蘇くじゅう国立公園が広がりミヤマキリシマの名所鶴見岳や志高湖など自然の見どころも多くあります。 */
  webYadoOnsenAreaBeppuDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaBeppuDescription', obj),
  /* 別所・鹿教湯温泉 */
  webYadoOnsenAreaBesshoKakeyu: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaBesshoKakeyu', obj),
  /* 東京から北陸新幹線「上田駅」から上田電鉄に乗り換えて「別所温泉駅」まで合わせて2時間30分のアクセス。別所温泉は、信州・長野エリアで最も古い歴史をもつ温泉の一つ。古くから仏教・学問の中心地ともなり、多くの「国宝」・「重要文化財」級の文化遺産が残され、”文化財の宝庫””信州の鎌倉”として全国に知られている。また、同じ上田駅を拠点として、バスで行ける山あいの静かな温泉地に鹿教湯温泉もあり、江戸時代から湯治場として栄えた日本で有数の健康保養温泉。町にはクアハウスや温泉センターなど、 保養・湯治を目的とした施設が複数ある。 */
  webYadoOnsenAreaBesshoKakeyuDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaBesshoKakeyuDescription', obj),
  /* WAmazingなら、夕食メニューを自由に選べます。和牛や蟹などの人気料理やベジタリアンでも安心な精進料理など、日本の人気グルメを堪能できます。貸切風呂やお迎え車両の事前予約も可能です。会員限定割引や最低価格保証でお得に予約できます。 */
  webYadoOnsenAreaDetailDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaDetailDescription', obj),
  /* {{onsenArea}},日本,温泉,ホテル,旅館,宿泊,宿泊予約,予約,WAmazing */
  webYadoOnsenAreaDetailKeywords: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaDetailKeywords', obj),
  /* {{onsenArea}}にある、日本のグルメと温泉が堪能できる人気旅館・ホテルを厳選してオススメ */
  webYadoOnsenAreaDetailTitle: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaDetailTitle', obj),
  /*  | WAmazing温泉 */
  webYadoOnsenWAmazingEnd: (obj?: I18nArgumentType) => translation('WebYado-OnsenWAmazingEnd', obj),
  /* {{name}}について詳しく知る */
  webYadoOnsenAreaDetailedInformation: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaDetailedInformation', obj),
  /* 道後温泉 */
  webYadoOnsenAreaDogo: (obj?: I18nArgumentType) => translation('WebYado-OnsenAreaDogo', obj),
  /* 日本三古泉のひとつである道後温泉。そのシンボルは1894年に建築された道後温泉本館です。建物と大浴場はレトロな空間となっており、多くの映画のモチーフや舞台となっています。道後温泉街には商店街もあり、お土産店や飲食店が軒を連ね、ゆかた姿の宿泊客が湯上りの散歩を楽しめます。近くには子規記念博物館や道後公園があり、観光にお勧めです。 */
  webYadoOnsenAreaDogoDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaDogoDescription', obj),
  /* 奈良・洞川温泉 */
  webYadoOnsenAreaDorogawa: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaDorogawa', obj),
  /* 大阪から近鉄特急で下市口駅まで約1時間、そこからバスに乗り終点「洞川温泉」まで約70分のアクセス。古くから大峯山の登山者たちの疲労回復に利用されてきた洞川温泉は無色透明のアルカリ性温泉。標高約820m余りの高地に位置する山里で、渓流と山に囲まれた温泉街は、その冷涼な気候から関西の軽井沢とも呼ばれています。日本の昭和時代にタイムスリップした雰囲気を漂わせる町並みには、旅館・民宿が約20軒、その他に土産物店や陀羅尼助丸を製造販売する店13軒や各種の商店が軒を連ねています。 */
  webYadoOnsenAreaDorogawaDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaDorogawaDescription', obj),
  /* 越後湯沢温泉 */
  webYadoOnsenAreaEchigoyuzawa: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaEchigoyuzawa', obj),
  /* 越後湯沢温泉は、日本の文豪、川端康成の小説｢雪国｣の舞台としても知られています。新幹線、高速道路ともにアクセスがよく、冬のスキー･スノーボード以外にも、トレッキング、郷土料理、地酒など、一年を通して楽しめる温泉地です。また気軽に入れる外湯も充実しており、５つの外湯に入れる共通券が各町営温泉施設または湯沢町観光協会にて販売されています。 */
  webYadoOnsenAreaEchigoyuzawaDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaEchigoyuzawaDescription', obj),
  /* 富士山・河口湖エリア */
  webYadoOnsenAreaFuji: (obj?: I18nArgumentType) => translation('WebYado-OnsenAreaFuji', obj),
  /* 「麗峰の湯」「天水の湯」「霊水の湯」「芙蓉の湯」「秀麗の湯」の５つの源泉が湧出しており、湖畔沿いには富士山と湖を眺めながら温泉が楽しめる露天風呂や展望風呂を備えた旅館、ホテルが点在しています。富士の恵み豊かな温泉を湛えた湯船に身を委ね、春は桜と富士山、夏は夕闇に浮かぶ富士山の山小屋の灯火、秋は紅葉の山並みと初雪の富士山、冬は雪景色の富士山を眺められます。湖畔沿いには魅力的な美術館や博物館、体験スポットも多く、四季を通して観光客で賑わっています。 */
  webYadoOnsenAreaFujiDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaFujiDescription', obj),
  /* 下呂温泉 */
  webYadoOnsenAreaGero: (obj?: I18nArgumentType) => translation('WebYado-OnsenAreaGero', obj),
  /* 名古屋から特急列車で約1.5時間のアクセス。1000年以上の歴史をもつ下呂温泉は、有馬温泉・草津温泉と並び「日本の三名泉」と言われている。ツルツルスベスベした肌触りから別名「美人の湯」とも呼ばれている。 約70軒の宿泊施設があり、地域内外から人が訪れる県指定天然記念物の「苗代桜」や、秋に見事な紅葉が見られる雨情公園もある。 */
  webYadoOnsenAreaGeroDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaGeroDescription', obj),
  /* 岐阜県その他エリア */
  webYadoOnsenAreaGifu: (obj?: I18nArgumentType) => translation('WebYado-OnsenAreaGifu', obj),
  /* 名古屋からアクセスする岐阜県には、下呂温泉、飛騨高山、白川郷以外にも、たくさんの温泉エリアや宿がある。四季折々の美しさを楽しめる国定公園「恵那峡」エリアや、1300年の歴史を誇る「鵜飼」で有名な長良川エリアなど、まだ知らない魅力を発見してみてはいかがでしょうか？ */
  webYadoOnsenAreaGifuDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaGifuDescription', obj),
  /* 銀山温泉 */
  webYadoOnsenAreaGinzan: (obj?: I18nArgumentType) => translation('WebYado-OnsenAreaGinzan', obj),
  /* 東京駅から山形新幹線で「大石田駅」まで3.5時間、そこからホテル送迎やバスで30〜40分のアクセス。銀山温泉は日本人・外国人にも大人気の温泉地。大正ロマンの風情溢れる温泉郷は、日が暮れると温泉街にガス灯が灯ってさらにロマンチックに変わる。温泉はもちろん、そばや尾花沢牛など地元グルメも魅力。それぞれの宿が、個性的で趣のある佇まいで、温泉街を眺めたり歩いたりしているだけで、物語の中に入ってしまったかのように時間を忘れる楽しさ。 */
  webYadoOnsenAreaGinzanDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaGinzanDescription', obj),
  /* 萩エリア */
  webYadoOnsenAreaHagi: (obj?: I18nArgumentType) => translation('WebYado-OnsenAreaHagi', obj),
  /* 萩市は、萩城下町などの歴史ある街並みや、幕末維新の志士を数多く輩出した松下村塾などが「明治日本の産業革命遺産」として世界遺産に登録されている、歴史あふれる街です。その中でも、「はぎ温泉郷」は新しい温泉地で、歴史ある街並みと温泉の両方が楽しめる点が魅力です。鉄道の場合は、東京や大阪、広島や福岡からは新幹線のぞみ号で新山口駅までアクセスし、そこからバスや電車で移動するルートが一般的です。 */
  webYadoOnsenAreaHagiDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaHagiDescription', obj),
  /* 函館湯の川温泉 */
  webYadoOnsenAreaHakodateYunokawa: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaHakodateYunokawa', obj),
  /* 函館空港から約5分と近く、函館の市街地からも電車や車ですぐ行ける人気の温泉街が湯の川温泉。函館のシンボル「函館山」は“日本三大夜景”の1つに数えられる有名な夜景スポットで、眩しい夜景と新鮮な海の幸が満喫できる。函館観光の拠点として、多くの湯客で賑わう“北海道三大温泉郷”の１つ。開湯が1653年と北海道の中でも歴史は古く、大型ホテルや昔ながらの風情ある老舗温泉旅館が両方あり、日本ならではの温泉街の雰囲気を楽しめる。 */
  webYadoOnsenAreaHakodateYunokawaDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaHakodateYunokawaDescription', obj),
  /* 箱根温泉 */
  webYadoOnsenAreaHakone: (obj?: I18nArgumentType) => translation('WebYado-OnsenAreaHakone', obj),
  /* 箱根温泉 */
  webYadoOnsenAreaHakoneYugawara: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaHakoneYugawara', obj),
  /* 箱根温泉は、東京から１時間半と車・電車共にアクセスの良い人気温泉地です。日本有数の温泉街があり、重要文化財級の老舗旅館から純和風旅館、大型リゾートホテルなど、趣の違う旅が楽しめるのも人気の秘密です。あじさい電車で人気の箱根登山鉄道で標高700m付近まで登ることができ、海沿いから様々な風景が楽しめます。新緑や紅葉の時期は特に人気で、箱根美術館や強羅公園、芦ノ湖、仙石原のすすき野原等の名所は賑わいを見せます。 */
  webYadoOnsenAreaHakoneYugawaraDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaHakoneYugawaraDescription', obj),
  /* 白馬・大町・安曇野エリア */
  webYadoOnsenAreaHakubaOmachiAzumino: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaHakubaOmachiAzumino', obj),
  /* 東京駅から北陸新幹線＋バスで3時間30分〜4時間のアクセス。国内外から人気の高いスキーリゾートエリアである白馬・大町エリアには、白馬八方尾根スキー場、白馬五竜スキー場など、たくさんのスキー場がある。温泉地としても有名な大町温泉郷は、近年訪日外国人にも人気の黒部ダムに通じる立山黒部アルペンルート沿いにある温泉地で、春は大山桜、夏は川遊び、秋は紅葉、冬はスキーと1年中楽しめる。北アルプスの麓に広がる安曇野・穂高町にある穂高温泉郷は、牧歌的な風景が魅力で、美術館やガラス工房が点在する。わさびやりんご、そばが名産。 */
  webYadoOnsenAreaHakubaOmachiAzuminoDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaHakubaOmachiAzuminoDescription', obj),
  /* 浜松・浜名湖エリア */
  webYadoOnsenAreaHamamatsuHamanako: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaHamamatsuHamanako', obj),
  /* 東京駅から東海道新幹線で1.5時間、名古屋駅から約35分のアクセス。浜名湖周辺には遊園地や動物園、さらに美しい花々が見られるフラワーパークなど様々な観光スポットがあり、家族連れを中心に温泉地として人気。さらにうなぎやふぐ、浜松餃子にB級グルメ他、四季を通じて美味しいグルメがたくさんあります。東岸にある舘山寺温泉は、浜名湖畔の美しい景観を湖上から楽しめる遊覧船の発着地にもなっており、温泉街の対岸にある展望台へは浜名湖上をロープウェイで約4分で行ける。 */
  webYadoOnsenAreaHamamatsuHamanakoDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaHamamatsuHamanakoDescription', obj),
  /* 花巻温泉 */
  webYadoOnsenAreaHanamaki: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaHanamaki', obj),
  /* 奥羽山脈の豊かな自然に寄り添う花巻温泉郷は、松や桜の並木に囲まれた旅館街です。春には一層華やかさを増し、日本の著名な文豪、芸術家である宮沢賢治や高村光太郎などが愛した温泉地です。周辺には、宮沢賢治ゆかりの記念館や、彼が設計した日時計花壇のあるバラ園、世界遺産である中尊寺金色堂などの観光も楽しめます。東京駅から2時間半ほどの東北新幹線新花巻駅よりアクセスが良いことも魅力です。 */
  webYadoOnsenAreaHanamakiDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaHanamakiDescription', obj),
  /* はわい温泉 */
  webYadoOnsenAreaHawai: (obj?: I18nArgumentType) => translation('WebYado-OnsenAreaHawai', obj),
  /* 米子駅から1.5時間、米子空港から2時間のアクセス「日本のハワイ」として知られるリゾート感覚あふれる温泉地。東郷湖西岸の湖に突き出した岬に湧き出しており、その周辺に並ぶ旅館やホテルはまるで湖畔に浮かぶかのような独特の風情がある。海水浴場からも近く､夏のシーズンは多くの人で賑わっている。周辺では、特産物の「二十世紀梨」の他にもイチゴ、メロン、ぶどうなどの果物栽培が盛んに行われ、目の前に広がる東郷湖畔では桜や紅葉など四季折々の景観を楽しめる。 */
  webYadoOnsenAreaHawaiDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaHawaiDescription', obj),
  /* 飛騨高山エリア */
  webYadoOnsenAreaHidaTakayama: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaHidaTakayama', obj),
  /* 飛騨高山は岐阜県にある山岳都市で、江戸時代からの古い町並みが残る、日本の原風景を感じることができる街の一つです。富山駅や名古屋駅からは特急で、東京からは高速バスでアクセスできます。飛騨牛、伝統工芸品、地元の酒を楽しみに、国内外からたくさんの観光客が訪れます。周辺には、車で1～2時間程度の距離に世界遺産の白川郷や、日本でも有数の山岳リゾートである上高地などの観光名所がいっぱいです。 */
  webYadoOnsenAreaHidaTakayamaDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaHidaTakayamaDescription', obj),
  /* 広島・宮島エリア */
  webYadoOnsenAreaHiroshimaMiyajima: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaHiroshimaMiyajima', obj),
  /* 広島・宮島エリアは日本の中国・四国地方に位置する広島県と、広島県にある有名な観光地である宮島の付近にあるエリアのことです。中心地である広島市は瀬戸内海・中国山地に面する近代都市で、平和記念公園や、第二次世界大戦のさなか 1945 年に投下された原子爆弾の惨禍を今に伝える原爆ドームなどのスポットがあります。また、宮島は広島湾にある島のことで、世界文化遺産にも数えられる厳島神社があることで有名です。東京からは新幹線で約4時間、もしくは羽田空港・成田空港から広島空港まで飛行機で移動し、広島空港からバスで移動するルートがあります。また、大阪や京都からは新幹線で約1時間半、福岡からは博多駅から新幹線で1時間弱でアクセスすることができます。 */
  webYadoOnsenAreaHiroshimaMiyajimaDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaHiroshimaMiyajimaDescription', obj),
  /* 人吉温泉 */
  webYadoOnsenAreaHitoyoshi: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaHitoyoshi', obj),
  /* 人吉温泉は、福岡・博多や熊本空港から2.5時間のアクセス。「九州の小京都」と呼ばれ、城下町として栄えてきた人吉は、JR肥薩線「人吉駅」を中心に狭い路地が行き交い、商店が密集するどこか懐かしい街並みが広がる。 山々に囲まれた人吉盆地の中央には球磨川が流れ、清流から生まれる球磨焼酎は、日本の焼酎文化の中でも古い歴史を持ち、まろやかな口あたりが特徴。人吉温泉の各温泉場は、街中を東西に流れる球磨川沿いに点在し、世界一のウォーターシュート「球磨川下り」はアクティビティとして大人気。 */
  webYadoOnsenAreaHitoyoshiDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaHitoyoshiDescription', obj),
  /* 指宿温泉 */
  webYadoOnsenAreaIbusuki: (obj?: I18nArgumentType) => translation('WebYado-OnsenAreaIbusuki', obj),
  /* 指宿温泉は鹿児島空港から1.5時間、福岡・博多からも2.5時間のアクセス。浜辺沿いにずらりと並んでいる。「砂むし温泉」は、指宿に来たら絶対に味わいたい名物体験。海岸に向かって地下を流れ出る温泉で温められた砂を利用する名物・天然砂むし温泉は、 約80℃の高温泉のため、潮が引いていく時に立ち上る湯煙は幻想的。専用浴衣1枚羽織って、波打ち際で潮の香りとともに味わう砂むしは、まさに極楽。九州新幹線開通でアクセスが便利になり、南国ムードをかもし出す九州の人気温泉地になっている。 */
  webYadoOnsenAreaIbusukiDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaIbusukiDescription', obj),
  /* 飯坂・土湯・磐梯熱海 */
  webYadoOnsenAreaIizakaTsuchiyuBandaiatami: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaIizakaTsuchiyuBandaiatami', obj),
  /* 飯坂温泉は東京駅から東北新幹線で福島駅まで約100分、そこから乗り換えて電車で20分のアクセス。土湯温泉は東京駅から東北新幹線で福島駅まで約100分、そこからバスで約40分のアクセス。磐梯熱海温泉は東京駅から東北新幹線で郡山駅まで約85分、そこから乗り換えて電車で15分のアクセス。いずれも歴史があり、四季を通じて楽しめる、福島県を代表する温泉地。 */
  webYadoOnsenAreaIizakaTsuchiyuBandaiatamiDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaIizakaTsuchiyuBandaiatamiDescription', obj),
  /* 伊香保温泉 */
  webYadoOnsenAreaIkaho: (obj?: I18nArgumentType) => translation('WebYado-OnsenAreaIkaho', obj),
  /* 伊香保温泉は万葉の時代から人々に親しまれてきた、石段街がシンボルの温泉地です。そこはかとなく、大正浪漫の雰囲気が漂い、ノスタルジックな気分にさせてくれる、のんびりそぞろ歩きがおすすめの温泉地です。東京からは新宿駅から直行の高速バスが出ており、2時間半程度でアクセスすることができます。日本三大うどんの一つ「水沢うどん」をはじめ、温泉まんじゅうの元祖「湯の花まんじゅう」を楽しむことができます。 */
  webYadoOnsenAreaIkahoDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaIkahoDescription', obj),
  /* 今治・しまなみ海道エリア */
  webYadoOnsenAreaImabari: (obj?: I18nArgumentType) => translation('WebYado-OnsenAreaImabari', obj),
  /* 今治駅は岡山駅から特急電車で約2時間、松山駅から特急電車で約40分のアクセス。美しい島々とそれらを繋ぐ橋が織り成す海の道「瀬戸内しまなみ海道」。広島県尾道市と愛媛県今治市を結ぶ全長約60kmの道では瀬戸内海に浮かぶ島々の風景を存分に楽しむことができます。「サイクリングロード」は日本で初めて海峡を横断できる自転車道として有名です。今治駅から車・送迎で約20分のアクセスの鈍川渓谷は、「21世紀に残したい四国の自然100選」第2位に選ばれたほどの緑と渓谷に囲まれたエリアで、この渓谷の一角に美人の湯として有名な美肌効果の高い温泉地。 */
  webYadoOnsenAreaImabariDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaImabariDescription', obj),
  /* 石和温泉 */
  webYadoOnsenAreaIsawa: (obj?: I18nArgumentType) => translation('WebYado-OnsenAreaIsawa', obj),
  /* 東京・新宿駅から特急で約1時間30分の近さ。山梨エリア観光の拠点として多くの観光客で賑わう「果物」と「温泉」のまち。夏の桃や秋のぶどうなどの果物狩り、桜や紅葉も楽しめる、春夏秋冬いつの季節も楽しめる。名物グルメ“ほうとう”は身体が芯まで温まる美味しさ。 */
  webYadoOnsenAreaIsawaDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaIsawaDescription', obj),
  /* 伊勢・志摩・鳥羽エリア */
  webYadoOnsenAreaIseShimaToba: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaIseShimaToba', obj),
  /* 電車で大阪から約2時間30分、名古屋から約2時間のアクセスのリゾートエリア。国内外から多くの人が訪れる「伊勢神宮」がある伊勢や、志摩・鳥羽などを合わせたエリアは、あわび・カキ・伊勢海老など海の幸が豊富で、日本有数のリアス式海岸、英虞湾を望むこともでき、夕日の美しさを楽しむ露天風呂もあるなど、絶景でも有名。 */
  webYadoOnsenAreaIseShimaTobaDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaIseShimaTobaDescription', obj),
  /* 磯部温泉・富岡・藤岡エリア */
  webYadoOnsenAreaIsobeTomioka: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaIsobeTomioka', obj),
  /* 東京駅から電車で1.5時間のアクセスエリア。磯部温泉は、日本の温泉マークのj発祥の地として知られ、新幹線「高崎駅」から電車で20分、新幹線「安中榛名駅」から車で15分というアクセスが便利なエリア。碓氷第三橋梁は日本最大のレンガ造りの橋で、その美しさと高い芸術性で多くの観光客が訪れている人気スポット。通称「めがね橋」と呼ばれ、国重要文化財に指定されています。山間の静かな隠れ家一軒宿の猪ノ田温泉や、上野村温泉郷、藤岡温泉、妙義温泉、湯端温泉など、様々な温泉が楽しめる。 */
  webYadoOnsenAreaIsobeTomiokaDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaIsobeTomiokaDescription', obj),
  /* 伊豆温泉 */
  webYadoOnsenAreaIzu: (obj?: I18nArgumentType) => translation('WebYado-OnsenAreaIzu', obj),
  /* 四季を通して温暖な気候、雄大な海岸美と山岳美に恵まれ、素朴で豊かな自然環境に恵まれた温泉地です。数多くの漁港を抱え、新鮮な魚介類と温泉を目当てに多くの観光客で賑わいを見せています。山間では山葵（わさび）やわさび漬け、椎茸などの名産品や、イノシシ鍋などの郷土料理もあります。海水浴から渓流釣り、サイクリングまで、海も山も一度に楽しめることが特徴です。伊豆半島西部では、天気が良ければ八木沢海岸や恋人岬から海に浮かぶ富士山が眺められます。 */
  webYadoOnsenAreaIzuDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaIzuDescription', obj),
  /* 加賀温泉郷 */
  webYadoOnsenAreaKaga: (obj?: I18nArgumentType) => translation('WebYado-OnsenAreaKaga', obj),
  /* 加賀温泉郷は日本の有名な歌人・松尾芭蕉が日本三名湯と称えた湯であり、日本屈指の古湯です。肌にやさしくまろやかな湯はまさに名泉であり、名所「こおろぎ橋」や芸術的な「あやとり橋」のある鶴仙渓遊歩道が有名です。 */
  webYadoOnsenAreaKagaDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaKagaDescription', obj),
  /* 加賀・和倉温泉 */
  webYadoOnsenAreaKagaWakura: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaKagaWakura', obj),
  /* 加賀温泉郷は日本の有名な歌人・松尾芭蕉が日本三名湯と称えた湯であり、日本屈指の古湯です。肌にやさしくまろやかな湯はまさに名泉であり、名所「こおろぎ橋」や芸術的な「あやとり橋」のある鶴仙渓遊歩道が有名です。和倉温泉は七尾湾に面した能登半島最大の温泉地です。歴史ある豊富な食塩泉は、白鷺が傷を癒し、羽を休めた海の出湯が発祥とされています。街の中心には、日帰りの温泉施設として人気の「総湯」があります。 */
  webYadoOnsenAreaKagaWakuraDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaKagaWakuraDescription', obj),
  /* 香川県その他エリア */
  webYadoOnsenAreaKagawa: (obj?: I18nArgumentType) => translation('WebYado-OnsenAreaKagawa', obj),
  /* 高松空港から1時間圏内のアクセス。香川県内には、美合温泉、さぬき瀬戸大橋温泉、東山温泉、東讃温泉などの隠れた名湯・秘湯が数多くある。定番の讃岐うどんから瀬戸内海の新鮮魚介を使った地元料理まで、全国的にも有名なグルメが盛りだくさん。屋島や瀬戸大橋等の観光スポットや映画・ＴＶドラマの撮影が行われたロケ地など、見どころもたくさんある。 */
  webYadoOnsenAreaKagawaDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaKagawaDescription', obj),
  /* 鹿児島中心エリア */
  webYadoOnsenAreaKagoshimaCentralarea: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaKagoshimaCentralarea', obj),
  /* 鹿児島空港からバスで1時間、博多駅から新幹線で2時間のアクセス。明治維新でその名を馳せた西郷隆盛や大久保利通、大河ドラマで有名になった篤姫などを輩出した鹿児島。 九州新幹線が開通し福岡・博多からのアクセスが便利になったエリアで、市街地の眼前には雄大な桜島が見える。鹿児島市の中心地には多くの温泉が湧き出し、多くの温泉客が全国より訪れる。薩摩藩の城下町として栄えた鹿児島は史跡が多く、往時の雰囲気を残している。地元グルメも豊富で、指宿温泉や霧島温泉など鹿児島エリアの観光地・温泉地に行く拠点としても滞在したいエリア。 */
  webYadoOnsenAreaKagoshimaCentralareaDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaKagoshimaCentralareaDescription', obj),
  /* 皆生温泉 */
  webYadoOnsenAreaKaike: (obj?: I18nArgumentType) => translation('WebYado-OnsenAreaKaike', obj),
  /* 皆生温泉は、岡山駅から米子駅まで特急列車で約2時間、そこからバスで約20分のアクセス。米子空港からタクシーで15分でもアクセス可能。“日本のなぎさ百選”にも選ばれた総延長約17㎞の美しい弓ヶ浜沿いに大型旅館が建ち並び、風光明媚な温泉地として人気。隣りには、カニ水揚げでは日本一の境港市がある。 */
  webYadoOnsenAreaKaikeDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaKaikeDescription', obj),
  /* 上高地・乗鞍高原エリア */
  webYadoOnsenAreaKamikochiNorikura: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaKamikochiNorikura', obj),
  /* 東京から松本駅まで電車で向かい、そこからバスに乗り、合わせて約4時間のアクセスの温泉地エリア。乗鞍高原エリアには、100以上の宿泊施設、観光施設が集う温泉リゾートとなっており、乗鞍高原温泉は乳白色が特徴。周辺には、新しい温泉地として、すずらん温泉、さわんど温泉などがあり、四季の自然や、冬にはスキーも楽しめる。 */
  webYadoOnsenAreaKamikochiNorikuraDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaKamikochiNorikuraDescription', obj),
  /* 金沢駅周辺エリア */
  webYadoOnsenAreaKanazawa: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaKanazawa', obj),
  /* 東京駅から北陸新幹線で3時間、小松空港からはバスで40分のアクセス。人気の観光エリアである金沢駅周辺にも温泉がいくつかある。金沢城公園に隣接し、兼六園、21世紀美術館、近江市場、ひがし茶屋街にも近い白鳥路温泉は、美人の湯と呼ばれる天然温泉で、和と洋の魅力を併せ持った金沢文化と大正ロマン情緒漂う新クラシックホテルです。他にも、歴代加賀藩主の湯治湯として栄え、「金沢の奥座敷」と呼ばれる湯涌温泉もある。 */
  webYadoOnsenAreaKanazawaDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaKanazawaDescription', obj),
  /* 勝浦温泉周辺エリア */
  webYadoOnsenAreaKatsuura: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaKatsuura', obj),
  /* 新大阪駅から電車で特急「くろしお」で紀伊勝浦駅まで4時間のアクセス。勝浦温泉は南紀勝浦温泉、湯川温泉からなり、那智勝浦町には和歌山県の中で最も多い200本近い多種多様な源泉がある。古くから遠洋・近海漁業の基地として栄えた勝浦港があり、日本一のマグロの水揚げ量を誇るため、夕食ではマグロの他様々な海の幸が味わえる。世界遺産熊野古道も近いエリア。見渡す限りの大海原をじっくり眺める贅沢な時間や、海水浴や遊覧船めぐりも楽しめる。 */
  webYadoOnsenAreaKatsuuraDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaKatsuuraDescription', obj),
  /* 城崎温泉 */
  webYadoOnsenAreaKinosaki: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaKinosaki', obj),
  /* 【大阪・京都周辺】風情ある多くの旅館が立ち並び、石造りの太鼓橋がかかる小さな川と柳並木の佇まいは城崎温泉の代表的な風景です。温泉街には７軒もの一般入浴が可能な外湯が点在し、展望露天風呂の｢さとの湯｣や岩窟風呂の「一の湯」等､それぞれ趣の違う温泉浴を楽しめます。この｢外湯めぐり｣は温泉街の旅館に泊まると無料になる仕組みで、浴衣姿でのそぞろ歩きが楽しめます。遥か昔から城崎の人々は宿を客間、道を廊下とし湯治に訪れた人々を町全体でもてなしてきました。 */
  webYadoOnsenAreaKinosakiDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaKinosakiDescription', obj),
  /* 鬼怒川温泉 */
  webYadoOnsenAreaKinugawa: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaKinugawa', obj),
  /* 霧島エリア */
  webYadoOnsenAreaKirishima: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaKirishima', obj),
  /* 鹿児島空港から約30分、新幹線「鹿児島中央駅」から30～50分のアクセスで、鹿児島県にある霧島温泉郷、霧島神宮温泉郷、妙見安楽温泉郷、日当山温泉郷の４つの温泉郷からなるエリア。日本神話の神様・ニニギノミコトが天より舞い降りたと伝えられる地で、 10種類の温泉とそれらを引く多くの宿が点在し、泉質はそれぞれ違い、宿や温泉の雰囲気もバラエティ豊かだ。幕末・明治に西郷隆盛もよく訪れた温泉地で、坂本龍馬が日本で初めて新婚旅行で来た温泉地としても有名な、昔から鹿児島の奥座敷として人気のエリアです。 */
  webYadoOnsenAreaKirishimaDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaKirishimaDescription', obj),
  /* 北湯沢温泉 */
  webYadoOnsenAreaKitayuzawa: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaKitayuzawa', obj),
  /* 新千歳空港からホテル送迎バスで1.5時間のアクセス。支笏洞爺国立公園の中央部に湧く温泉。札幌や苫小牧、小樽、千歳からも近く、支笏湖や洞爺湖など観光名所にもアクセス良好。そんな便利なロケーションを誇りながらも、このエリアには清流の長流川、三段の層をなして美しく流れ落ちる三階滝、洞爺やニセコ一帯のパノラマが堪能できるホロホロ山など、雄大な自然スポットが満載。長流川渓谷の紅葉の美しさは天下一品。北湯沢スキー場、ハイキングなどが楽しめる天狗山公園、テニスコートなどもあり、グループやファミリーで楽しめる設備が充実。落ち着ける小さな宿から大規模なリゾートホテルまであり、大露天風呂や温泉プールが人気。 */
  webYadoOnsenAreaKitayuzawaDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaKitayuzawaDescription', obj),
  /* 高知駅周辺エリア */
  webYadoOnsenAreaKochiCity: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaKochiCity', obj),
  /* 高知空港からバスで30分、高松空港からはバスで2時間のアクセス。高知の繁華街近く、国の重要文化財である旧山内家下屋敷跡にあるのが三翠園温泉。泉質はナトリウム塩化物。大浴場の他に露天風呂、高温風呂、打たせ湯などもある。そのほか高知市周辺には高知県立野市動物園や、アンパンマンミュージアム、龍馬記念館、日本三大鍾乳洞のひとつである龍河洞など人気の観光スポットがたくさんある。 */
  webYadoOnsenAreaKochiCityDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaKochiCityDescription', obj),
  /* ことひら温泉 */
  webYadoOnsenAreaKotohira: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaKotohira', obj),
  /* ことひら温泉は四国地方・香川県にある温泉地です。四国随一のパワースポットとして年間400万人が訪れる、国の重要文化財である金刀比羅宮（ことひらぐう）が近隣にあり、日本ならではの観光を楽しむこともできます。東京や大阪からは新幹線で岡山まで、岡山から琴平までは電車で1時間程度で訪れることができます。また、飛行機の場合は高松空港からバスで1時間程度でアクセスできます。 */
  webYadoOnsenAreaKotohiraDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaKotohiraDescription', obj),
  /* 熊野古道・本宮温泉郷 */
  webYadoOnsenAreaKumanoHongu: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaKumanoHongu', obj),
  /* 関西国際空港から特急電車で約2時間、そこからバスで約2時間のアクセス。白浜駅からホテルの送迎がある場合もある。1800年以上前に発見されたという「湯の峰温泉」をはじめ、「川湯温泉」「渡瀬温泉」と3つの温泉が湧く熊野本宮温泉郷。この地域は、世界遺産・熊野本宮大社のお膝元にある温泉郷。山と川に囲まれた大自然の中、熊野古道ウォークと合わせて、歴史ある名湯を存分に味わえる。 */
  webYadoOnsenAreaKumanoHonguDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaKumanoHonguDescription', obj),
  /* 黒川温泉・周辺 */
  webYadoOnsenAreaKurokawa: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaKurokawa', obj),
  /* 【福岡周辺】筑後川の支流である田の原川沿いに、懐かしさあふれる和風旅館が立ち並び、湯治場の雰囲気を残しています。泉質は含食塩芒硝硫化水素泉で、神経痛、リウマチに効果があります。ほとんどの旅館に露天風呂があり、旅行者は「入湯手形」を購入することにより、3カ所まで選んで入浴することができます。2009年版ミシュラン・グリーンガイド・ジャポンで、温泉地としては異例の二つ星で掲載されました。 */
  webYadoOnsenAreaKurokawaDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaKurokawaDescription', obj),
  /* 草津温泉 */
  webYadoOnsenAreaKusatsu: (obj?: I18nArgumentType) => translation('WebYado-OnsenAreaKusatsu', obj),
  /* 草津温泉は日本有数の名湯として名高く、名物「湯畑」を中心に、様々なタイプの宿やみやげ物店が多数ある活気のある温泉地です。殺菌力の強い源泉が町のあちこちから湧き出ていて、無料で湯めぐりができることが特徴です。東京からは上野駅から特急草津で2時間半、そこからバスを乗り継いでアクセス可能です。渋谷やバスタ新宿などからは出ている直行バスでの移動もオススメです。 */
  webYadoOnsenAreaKusatsuDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaKusatsuDescription', obj),
  /* 草津・伊香保温泉 */
  webYadoOnsenAreaKusatsuIkaho: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaKusatsuIkaho', obj),
  /* 草津温泉は日本有数の名湯として名高く、名物「湯畑」を中心に、様々なタイプの宿やみやげ物店が多数ある活気のある温泉地です。殺菌力の強い源泉が町のあちこちから湧き出ていて、無料で湯めぐりができることが特徴です。伊香保温泉は万葉の時代から人々に親しまれてきた、石段街がシンボルの温泉地です。そこはかとなく、大正浪漫の雰囲気が漂い、ノスタルジックな気分にさせてくれる、のんびりそぞろ歩きがおすすめの温泉地です。 */
  webYadoOnsenAreaKusatsuIkahoDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaKusatsuIkahoDescription', obj),
  /* 京都エリア */
  webYadoOnsenAreaKyoto: (obj?: I18nArgumentType) => translation('WebYado-OnsenAreaKyoto', obj),
  /* 京都・天橋立エリア */
  webYadoOnsenAreaKyotoAmanohashidate: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaKyotoAmanohashidate', obj),
  /* 京都駅から約2時間のアクセスの北部エリアには、日本三景のひとつに数えられる「天橋立」にある「天橋立温泉」や、全国にその名を馳せる美しい夕日の名所「夕日ヶ浦温泉」、美しい海岸線と緑豊かな山々に恵まれた久美浜湾を一望できる温泉「久美の浜温泉郷」など、魅力あふれる温泉地がたくさんある。冬から春にかけての松葉カニは特に有名で、全国から人が集まる。 */
  webYadoOnsenAreaKyotoAmanohashidateDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaKyotoAmanohashidateDescription', obj),
  /* 京都駅周辺エリア */
  webYadoOnsenAreaKyotoCity: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaKyotoCity', obj),
  /* 観光客の多い京都駅周辺にも、温泉が楽しめるエリアや宿が存在している。京都駅から電車＋シャトルバスで約30分のアクセスにある、山あいに囲まれた静かで自然豊かな「湯の花温泉」は特におすすめ。その他京都市内には、温泉が楽しめるホテル・旅館がたくさんあるため、滞在中に1泊は温泉に止まってみたはいかがでしょうか？ */
  webYadoOnsenAreaKyotoCityDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaKyotoCityDescription', obj),
  /* 京都は日本の関西地方に位置する都道府県で、国際文化観光都市に指定されています。日本三景のひとつに数えられる「天橋立」にある「天橋立温泉」や、山あいに囲まれた静かで自然豊かな「湯の花温泉」などの温泉エリアがあります。関西国際空港からはJR関西特急はるかで、大阪国際空港からは空港リムジンバスで、東京からは東海道新幹線にて京都駅までアクセスできます。また、京都エリア内の移動は、京都駅を中心としてバスや電車で移動することができます。京都は日本の関西地方に位置する都道府県で、国際文化観光都市に指定されています。日本三景のひとつに数えられる「天橋立」にある「天橋立温泉」や、山あいに囲まれた静かで自然豊かな「湯の花温泉」などの温泉エリアがあります。関西国際空港からはJR関西特急はるかで、大阪国際空港からは空港リムジンバスで、東京からは東海道新幹線にて京都駅までアクセスできます。また、京都エリア内の移動は、京都駅を中心としてバスや電車で移動することができます。 */
  webYadoOnsenAreaKyotoDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaKyotoDescription', obj),
  /* 温泉地一覧 */
  webYadoOnsenAreaList: (obj?: I18nArgumentType) => translation('WebYado-OnsenAreaList', obj),
  /* 斑尾高原・野尻湖エリア */
  webYadoOnsenAreaMadaraoNojiriko: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaMadaraoNojiriko', obj),
  /* 北陸新幹線「飯山駅」周辺には、戸狩温泉や斑尾高原エリアなどの温泉地がある。斑尾高原スキー場、戸狩温泉スキー場の2箇所の人気スキー場があり、その周辺に温泉宿が広がっている。飯山駅から路線バスか、戸狩野沢温泉駅から宿の送迎でアクセス可能。黒姫温泉は、マリンスポーツや釣りが楽しめる野尻湖近くにあり、秋のコスモスなど四季により異なる景色が見られる。 */
  webYadoOnsenAreaMadaraoNojirikoDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaMadaraoNojirikoDescription', obj),
  /* 松本エリア */
  webYadoOnsenAreaMatsumoto: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaMatsumoto', obj),
  /* 松本エリアは日本の中部地方に位置する長野県松本市にある一帯のことで、北アルプスの麓に歴史ある街並みがあります。現存する五重六階の天守としては日本最古の建造物であり、国内外から大勢の観光客が訪れる人気のエリアである「国宝松本城」や、江戸時代に栄えた城下町で現在も古き良き雰囲気の漂う「縄手通り」などの歴史ある観光スポットが楽しめます。東京方面からはJR特急スーパーあずさ号またはあずさ号で、東京駅から北陸新幹線で長野駅まで行き、そこから特急しなのに乗り換える方法があります。特急しなのでは大阪方面や名古屋方面からもアクセス可能です。また東京、大阪、名古屋からは高速バスも運行しています。 */
  webYadoOnsenAreaMatsumotoDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaMatsumotoDescription', obj),
  /* 水上温泉エリア */
  webYadoOnsenAreaMinakami: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaMinakami', obj),
  /* 東京駅から新幹線で「上毛高原駅」まで約70分、そこからバスやホテル送迎で約30分のアクセス。みなかみ町にはたくさんの温泉があり、みなかみ18湯と呼ばれている（水上温泉、谷川温泉、うの瀬温泉、湯檜曽温泉、向山温泉、宝川温泉、湯の小屋温泉、上の原温泉、上牧温泉、月夜野温泉、真沢温泉、奈女沢温泉、湯宿温泉、赤岩温泉、高原千葉村温泉、川小温泉、猿ケ京温泉、法師温泉）夏はラフティングなどの渓谷を利用した体験、秋は紅葉、冬はスキーなど、一年を通じて楽しめる。 */
  webYadoOnsenAreaMinakamiDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaMinakamiDescription', obj),
  /* 三朝温泉 */
  webYadoOnsenAreaMisasa: (obj?: I18nArgumentType) => translation('WebYado-OnsenAreaMisasa', obj),
  /* 温泉街は三徳川の両岸に伝統的な和風旅館が立ち並んでいます。石畳が敷かれた温泉本通りには、こぢんまりした旅館や飲食店、古美術店、スナック、土産物屋、射的場等が並んでおり、情緒ある温泉街となっています。発見されたのは約８００年前といわれ源氏ゆかりの伝説の古湯です。世界屈指のラジウム泉で、日本一のラドン含有量を誇る泉質も自慢です。温泉地を貫く三徳川のせせらぎの音､カジカガエルの鳴き声が響く温泉としても有名で､三朝橋の辺の川辺に湧く混浴露天の「河原風呂」が名物です。 */
  webYadoOnsenAreaMisasaDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaMisasaDescription', obj),
  /* 宮城蔵王エリア */
  webYadoOnsenAreaMiyagiZao: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaMiyagiZao', obj),
  /* 遠刈田温泉は、東北新幹線「白石蔵王」「仙台」駅からバスで約1時間で行ける人気温泉地。豊富な湯を誇り、昔ながらの湯治場の雰囲気を漂わせる。蔵王の豊かな自然に恵まれ、新緑、紅葉など見所が目白押しで、蔵王のシンボルである「御釜」と呼ばれる火口湖（カルデラ湖）が人気。その他、「にごり湯」が有名な鎌先温泉、「美肌の湯」と言われる青根温泉、渓流美が楽しめる小原温泉という開湯400年以上の歴史ある温泉エリア。 */
  webYadoOnsenAreaMiyagiZaoDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaMiyagiZaoDescription', obj),
  /* 長門湯本・油谷湾エリア */
  webYadoOnsenAreaNagatoyumotoYuyawan: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaNagatoyumotoYuyawan', obj),
  /* 長門湯本温泉は、日本の中国地方に位置する山口県長門市にある温泉地です。山口県を訪れる訪日外国人に人気のパワースポットであり、CNNの「日本の最も美しい場所31選」にも選ばれたことのある「元乃隅神社」まは車で30分ほどの距離にあります。山口宇部空港や新幹線駅である新山口駅からJRで1時間半ほどでアクセスできます。また、長門市内から車で20分ほどの距離には油谷湾温泉もあり、日本海を眺めながらゆっくり温泉を楽しむこともできます。 */
  webYadoOnsenAreaNagatoyumotoYuyawanDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaNagatoyumotoYuyawanDescription', obj),
  /* 愛知・名古屋周辺エリア */
  webYadoOnsenAreaNagoya: (obj?: I18nArgumentType) => translation('WebYado-OnsenAreaNagoya', obj),
  /* 名古屋駅から1,2時間で行ける温泉地がたくさんある。海の眺望が楽しめるエリアの西浦温泉、南知多温泉郷や、犬山城がある犬山温泉、600年以上の歴史のある笹戸温泉など。名古屋エリアに滞在する際には、是非足を伸ばして温泉を楽しんではいかがでしょうか？ */
  webYadoOnsenAreaNagoyaDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaNagoyaDescription', obj),
  /* 南紀白浜温泉 */
  webYadoOnsenAreaNankishirahama: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaNankishirahama', obj),
  /* 日本に現存する最古の和歌集である万葉集にもうたわれた日本を代表する温泉郷です。海沿いには大自然が造った千畳敷や三段壁、円月島などの景勝地が点在し、その周辺にはアドベンチャーワールドに代表される大型アミューズメント施設があります。温暖な気候の下、5月から海水浴も楽しめ、マリンスポーツなどのレジャー拠点としても人気です。電車以外にも高速道路の南紀白浜インターチェンジ、南紀白浜空港もあり遠方からの交通の便がよい温泉地です。 */
  webYadoOnsenAreaNankishirahamaDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaNankishirahamaDescription', obj),
  /* 鳴子温泉郷 */
  webYadoOnsenAreaNaruko: (obj?: I18nArgumentType) => translation('WebYado-OnsenAreaNaruko', obj),
  /* 鳴子温泉郷は、仙台駅から電車で1時間のアクセスで、鳴子､東鳴子、川渡、中山平、鬼首の5つの温泉で構成されている温泉郷。日本国内にある11種の泉質のうち9種がここにあり、源泉数は370本以上ある。温泉郷の中で随一の規模と豊富な湯量、効能を誇る鳴子温泉。立ち上る湯煙と漂う硫黄の香り、細い路地と軒を連ねる土産物屋が温泉情緒を一気に盛り上げる。 新緑や紅葉に彩られた鳴子峡の美しも、一度は見たい絶景だ。「鳴子こけし」や「鳴子漆器」が、人気のお土産。昔から東北の湯治場としても有名で温泉の効能や泉質の良さには定評有り。春は新緑、秋は紅葉、冬はスキーも楽しめる。 */
  webYadoOnsenAreaNarukoDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaNarukoDescription', obj),
  /* 近くの温泉地 */
  webYadoOnsenAreaNearby: (obj?: I18nArgumentType) => translation('WebYado-OnsenAreaNearby', obj),
  /* 日光温泉 */
  webYadoOnsenAreaNikko: (obj?: I18nArgumentType) => translation('WebYado-OnsenAreaNikko', obj),
  /* 日光・鬼怒川温泉 */
  webYadoOnsenAreaNikkoKinugawa: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaNikkoKinugawa', obj),
  /* 【東京周辺】雄大な鬼怒川渓谷沿いに旅館・ホテルが立ち並び、「東京の奥座敷」と呼ばれる関東有数の温泉地です。江戸時代の発見当時は日光詣の僧侶や大名のみ利用できましたが、その後近代になり一般開放されました。体に優しいアルカリ性単純温泉は神経痛・疲労回復等に効能あります。周辺は世界遺産である豪華絢爛な日光東照宮をはじめ、日光江戸村や東武ワールドスクウェア等のテーマパーク、春の新緑、秋の紅葉が美しい自然など見どころが多くあります。 */
  webYadoOnsenAreaNikkoKinugawaDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaNikkoKinugawaDescription', obj),
  /* 登別温泉 */
  webYadoOnsenAreaNoboribetsu: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaNoboribetsu', obj),
  /* 新千歳空港や札幌駅から電車やバスや約1時間〜2時間のアクセスで行ける、北海道はもちろん日本を代表する温泉。登別地域を流れる川に沿って広がる温泉街は現代的なホテルや和風旅館、土産物屋が数多く並ぶ。漁港が近く北海道の海の幸が豊富だが、すぐ近くに自然もあり、牧場などのテーマパークも揃っており、飽きることのない温泉観光地となている。 */
  webYadoOnsenAreaNoboribetsuDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaNoboribetsuDescription', obj),
  /* 野沢温泉 */
  webYadoOnsenAreaNozawa: (obj?: I18nArgumentType) => translation('WebYado-OnsenAreaNozawa', obj),
  /* 東京から北陸新幹線「飯山駅」からバスに乗り継いで2.5時間のアクセス。日本有数のスキー場エリアで、その開湯は700年以上にさかのぼる、歴史のある温泉街。温泉街に点在する１３ヵ所の外湯（無料で利用可能）を楽しむことができる。立ち並ぶ土産物屋と地元の人気グルメ“おやき”の湯気に、思わず足が止まる。 村の天然記念物「麻釜（おがま）」の熱泉で山菜をゆでる光景は野沢温泉ならではの風物詩だ。 */
  webYadoOnsenAreaNozawaDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaNozawaDescription', obj),
  /* 乳頭温泉・田沢湖エリア */
  webYadoOnsenAreaNyutoTazawako: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaNyutoTazawako', obj),
  /* 秋田新幹線を利用して東京から約3時間のアクセス。日本一の深さと､高い透明度を誇る田沢湖｡この湖を一望できる田沢湖高原温泉郷と水沢温泉郷｡そして、日本人が一度は行きたいと憧れる秘湯の温泉地「乳頭温泉郷」は、十和田･八幡平国立公園の乳頭山麓に点在する七つの宿の集まり。山菜やきのこ等自然の恵みも溢れていて、宿で味わうことができる。 */
  webYadoOnsenAreaNyutoTazawakoDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaNyutoTazawakoDescription', obj),
  /* 大歩危・祖谷エリア */
  webYadoOnsenAreaObokeIya: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaObokeIya', obj),
  /* 大歩危・小歩危温泉郷は日本の四国地方に位置する徳島県三好市にある温泉郷で、国の名勝・天然記念物の指定を受けている「大歩危峡」を中心に、大歩危温泉、祖谷温泉、新祖谷温泉などのいくつかの温泉地が集まったエリアです。世界遺産にも登録された白川郷と並んで日本三大秘境として知られ、深い山や谷に囲まれた手つかずの大自然が魅力です。高松空港からエアポートリムジンでJR高松駅まで、そこから最寄りの大歩危駅まで電車で約90分でアクセスすることができます。また、JR高知駅からは特急電車で約50分、JR徳島駅からは約1時間半でアクセスできます。 */
  webYadoOnsenAreaObokeIyaDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaObokeIyaDescription', obj),
  /* 雄琴温泉 */
  webYadoOnsenAreaOgoto: (obj?: I18nArgumentType) => translation('WebYado-OnsenAreaOgoto', obj),
  /* 雄琴溫泉は滋賀県大津市にある1200年の歴史を持つ由緒ある温泉地です。日本最大の湖である琵琶湖の西側に位置し、全国でも珍しく、湖と日の出が楽しめる温泉です。人気観光地である京都から車で20分と非常に近く、京都での観光スポット巡りの疲れを温泉で癒すことができます。また、琵琶湖を一日かけてじっくり楽しめる贅沢な観光クルージングや、大パノラマを楽しめる日本一のロープウェイやジップラインなどのアクティビティが楽しめる「びわ湖バレイ」などのスポットも充実しています。 */
  webYadoOnsenAreaOgotoDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaOgotoDescription', obj),
  /* 岡山・倉敷周辺エリア */
  webYadoOnsenAreaOkayamaKurashiki: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaOkayamaKurashiki', obj),
  /* 岡山空港・岡山駅から電車やバスで30分前後のアクセス。岡山県の観光の拠点、岡山市・倉敷市を中心とした温泉地。海にも山にも近く、家族・カップルでの旅行に最適なエリア。主な温泉地としては平地にある湯迫温泉、苫田温泉があり、瀬戸内海を望む立地には、たまの温泉、鷲羽温泉、鷲羽吹上温泉が、山間地域には由加温泉がある。その他、岡山市街地から車で約15分のところには、地下１４５７メートルから湧き出る岡山桃太郎温泉という温泉もある。 */
  webYadoOnsenAreaOkayamaKurashikiDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaOkayamaKurashikiDescription', obj),
  /* 小樽・朝里川温泉 */
  webYadoOnsenAreaOtaruAsarigawa: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaOtaruAsarigawa', obj),
  /* 小樽・朝里川温泉エリアは、新千歳空港から電車とバスで1.5時間のアクセスで札幌からも近い。北海道屈指の観光地の一つ、小樽の市街地から南西に向かい、朝里川が流れる山間に広がる温泉地。港町で、運河や鉄道が早くから開けて貿易品や石炭などを運んだ海運・陸運の町。豊かな自然に囲まれた恵まれた自然環境が人気の秘密で、観光の拠点として静かでのんびりくつろぎたい旅行者に最適。 夏のテニスやゴルフ、冬のスキーとスポーツ施設も揃っている、ゆらぎの里として癒しをテーマに散策路の整備やほたるの飼育などを行っている。 */
  webYadoOnsenAreaOtaruAsarigawaDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaOtaruAsarigawaDescription', obj),
  /* 尾瀬・沼田・老神温泉 */
  webYadoOnsenAreaOzeOigami: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaOzeOigami', obj),
  /* このエリアへは東京から上越新幹線（上毛高原駅）＋バスで約2時間半のアクセス。片品温泉郷は、日本最大の湿原である「尾瀬」へのハイキングやテニス、また冬は地域内にある「かたしな高原スキー場」でのスキーなど、1年を通してスポーツを楽しめるのが魅力。地域内には「花咲温泉」「片品温泉」「土出温泉」「鎌田温泉」「東小川温泉」「白根温泉」「座禅温泉」「丸沼温泉」「尾瀬戸倉温泉」等数多くの温泉があります。老神温泉では、美しい「片品渓谷」や天然記念物の「吹割の滝」などの豊かな自然が魅力。りんご狩りや山菜採りもできる。温泉街の朝市では浴衣姿の宿泊客がお土産を求める姿も見られる。 */
  webYadoOnsenAreaOzeOigamiDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaOzeOigamiDescription', obj),
  /* 人気の温泉地 */
  webYadoOnsenAreaPopular: (obj?: I18nArgumentType) => translation('WebYado-OnsenAreaPopular', obj),
  /* 札幌定山渓温泉 */
  webYadoOnsenAreaSapporoJozankei: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaSapporoJozankei', obj),
  /* 北海道・札幌市内にあり、賑やかな札幌市街地からから札幌駅から約70分で行くことができる渓谷の温泉地。「支笏洞爺国立公園」内に位置し、外国人旅行者も良質の温泉を求めて訪れる人気エリア。開湯から150年を迎える定山渓温泉では、近代的ホテルや風情ある温泉旅館が渓谷の中にあらわれる。美しい紅葉が見られることでも有名で、自然の中で楽しめるアクティビティも充実しており、果樹園や乗馬、カヌー、スキーなどの体験もできる。 */
  webYadoOnsenAreaSapporoJozankeiDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaSapporoJozankeiDescription', obj),
  /* 佐世保・壱岐・平戸エリア */
  webYadoOnsenAreaSaseboIkiHirado: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaSaseboIkiHirado', obj),
  /* 福岡・博多から佐世保まではバスで約2時間のアクセス人気テーマパークのハウステンボスを始め、米軍基地がありアメリカンムード漂う佐世保は、170余りの島々が点在し､特に夕陽が美しい九十九島や､うずしおを見られる西海橋など見所も多い。日本の陸路最西端にある長崎県平戸エリアは、潜伏キリシタン関連の世界遺産スポットも多く、異国情緒あふれる城下町。かつて日本最初の西洋貿易の舞台としてオランダ、イギリスなどと交易が行われた港街。 歴史的な魅力もさることながら、港町ならではの新鮮な魚介や貿易で伝わった南蛮菓子などが味わえるのも魅力の一つ。温泉はトロトロ泉質の「美人の湯」。壱岐島にある温泉は、1700年もの昔から残る古湯と言われ、湯ノ本湾をぐるり囲むように温泉が並んでいるので、夕日を眺めながら温泉に入ることができる。島ならではの海の幸やマリンスポーツも楽しめる人気スポット。 */
  webYadoOnsenAreaSaseboIkiHiradoDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaSaseboIkiHiradoDescription', obj),
  /* 検索結果が見つかりませんでした。 */
  webYadoOnsenAreaSearchingNoResult: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaSearchingNoResult', obj),
  /* 以下はいかがですか？ */
  webYadoOnsenAreaSearchingRecommendation: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaSearchingRecommendation', obj),
  /* {{name}}の温泉旅館・ホテル：{{number}} */
  webYadoOnsenAreaSearchingResultCount: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaSearchingResultCount', obj),
  /* 申し訳ございません。条件に合う旅館が見つかりませんでした。 */
  webYadoOnsenAreaSearchingZeroHit: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaSearchingZeroHit', obj),
  /* 志賀高原エリア */
  webYadoOnsenAreaShigakogen: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaShigakogen', obj),
  /* 東京駅から北陸新幹線で長野駅まで１時間20分。そこから長野電鉄に乗換え湯田中駅まで20分、湯田中駅からバスに乗換え35分。志賀高原エリアまでは合計2時間15分。志賀高原エリアには「発哺温泉」「熊の湯ほたる温泉」「木戸池温泉」「志賀山温泉」「高天ヶ原温泉」「幕岩温泉」「丸池・蓮池温泉」と個性豊かな温泉が多く点在する。周辺には日本屈指のスキー場が多く、夏にはトレッキングなど年中通して自然を楽しむ事ができる。天然記念物のゲンジボタルの生息地としても有名。 */
  webYadoOnsenAreaShigakogenDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaShigakogenDescription', obj),
  /* 四万温泉・沢渡温泉 */
  webYadoOnsenAreaShima: (obj?: I18nArgumentType) => translation('WebYado-OnsenAreaShima', obj),
  /* 東京から車だと約2時間、東京駅から高速バスだと約3.5時間のアクセス。群馬県の四万温泉は上信越高原国立公園の中にあり、周囲を山々に囲まれた温泉地で、温泉街は四万川に沿って広がる。 コバルトブルーの湖面を持つ奥四万湖でのカヌー体験や非常に透明度の高い四万川でのキャニオニング体験などで大自然を満喫することができるほか、地元産の食材を使った食事や四万温泉ならではのお土産を買うこともできる。有名なアニメ映画のモデルになったと言われている旅館や、歴史がありフォトジェニックな旅館など、特長的な旅館が多い。 */
  webYadoOnsenAreaShimaDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaShimaDescription', obj),
  /* 下関・角島・川棚温泉 */
  webYadoOnsenAreaShimonoseki: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaShimonoseki', obj),
  /* 福岡・博多駅から下関駅まで新幹線で50分、川棚温泉駅までは下関駅で乗り換えて合計1時間30分のアクセス。九州（福岡県）と本州（山口県）と境にある下関エリアは、高級魚「ふぐ」が有名で、その他にも「瓦そば」などのご当地グルメがある。また、エメラルドグリーンの海に浮かぶ、白い砂浜が美しい島「角島」は屈指の観光スポット。川棚温泉は800年以上の歴史を誇る温泉地で、無色透明のラジウム泉で、肌に優しく幅広い効能が期待できる。 */
  webYadoOnsenAreaShimonosekiDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaShimonosekiDescription', obj),
  /* 信州高山エリア（小布施周辺） */
  webYadoOnsenAreaShinshutakayama: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaShinshutakayama', obj),
  /* 信州高山エリアは長野県北部の信州高山村の一帯のことで、大自然の中にいくつかの温泉地が点在しています。その中心には松川が流れる松川渓谷があり、大自然に囲まれた四季折々の風景が楽しめるスポットとして大変人気です。特に秋は、日本紅葉百選にも選ばれたことのある信州でも指折りの紅葉を楽しみに、ファンが訪れます。近隣の温泉宿では温泉に浸かりながらゆっくり紅葉を眺めることができます。長野市の中心部からはバスや電車で約一時間でアクセスすることができます。 */
  webYadoOnsenAreaShinshutakayamaDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaShinshutakayamaDescription', obj),
  /* 知床温泉 */
  webYadoOnsenAreaShiretoko: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaShiretoko', obj),
  /* 世界遺産知床を満喫するならここが拠点としておすすめです。オホーツク海に沈む夕日をお風呂から眺められる宿が多く、源泉100%の温泉を引いている宿も多数ある人気の温泉地です。泉質は褐色のにごり湯のナトリウム塩化泉で美肌の湯といわれています。ウトロの観光船などが発着して知床観光の拠点にもなっていることから、規模の大きいホテルが多くあることも特徴です。また、羅臼ではホエールウォッチングなども楽しめます。 */
  webYadoOnsenAreaShiretokoDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaShiretokoDescription', obj),
  /* 静岡・焼津エリア */
  webYadoOnsenAreaShizuokaYaizu: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaShizuokaYaizu', obj),
  /* 東京駅から東海道新幹線で約1時間、名古屋駅からも約1時間のアクセス。静岡駅の市街地に一番近い温泉地で、静岡駅からバスで30分のアクセスである「油山温泉」は、山河の自然に包まれながら、自慢の手作り料理を囲炉裏で楽しめる「元湯館」が人気。静岡駅から電車で約10分の焼津は、カツオの街として知られるエリア。焼津駅から程近い場所にある「焼津黒潮温泉」は、カツオを始めとしたグルメと温泉を両方楽しみたいという人にはおすすめの温泉地。 */
  webYadoOnsenAreaShizuokaYaizuDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaShizuokaYaizuDescription', obj),
  /* 小豆島温泉 */
  webYadoOnsenAreaShodoshima: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaShodoshima', obj),
  /* 瀬戸内に浮かぶ小豆島。大阪からは高速艇で2時間半ほど、岡山、高松からも穏やかな瀬戸内海の島並みを見ながら船でアクセスできます。立ち寄り湯もあり島内湯めぐりも可能です。日本でのオリーブ発祥の地としても有名で、オリーブを原料とした食用油やクレンジングオイル、ハンドクリームなどの土産物も充実しています。観光では紅葉の時期の寒霞渓が賑わいを見せます。 */
  webYadoOnsenAreaShodoshimaDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaShodoshimaDescription', obj),
  /* 小豆島温泉 */
  webYadoOnsenAreaShoudoshima: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaShoudoshima', obj),
  /* 諏訪湖エリア */
  webYadoOnsenAreaSuwako: (obj?: I18nArgumentType) => translation('WebYado-OnsenAreaSuwako', obj),
  /* 長野・信州で一番大きな湖「諏訪湖」が目の前に広がる温泉地。諏訪湖畔には大型ホテルや観光施設が並び、多くの人たちで賑わう。諏訪湖を眺めながら浸れる足湯や、100年近く前に建設されたクラシックな建物が目印の「片倉館」では、深さ約1.2mの立って浸かる珍しい温泉もある。周辺には諏訪大社や、昔ながらの造り酒屋や味噌蔵が静かに佇む情緒あふれる街並みなど、 見どころの多い湖畔めぐりが楽しめる。 */
  webYadoOnsenAreaSuwakoDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaSuwakoDescription', obj),
  /* 香川・高松エリア */
  webYadoOnsenAreaTakamatsu: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaTakamatsu', obj),
  /* 高松空港からバスで30分、岡山駅から電車で1時間、新大阪駅から電車で2時間のアクセス。四国の海の玄関口である高松市。高松市内と瀬戸内海を見渡せる高松花樹海温泉。高松市内から車で約1時間のアクセスの塩江温泉郷。石材と漁業が盛んな庵治町にも温泉が湧く。城下町の面影を残した高松内は交通の便も良く、瀬戸内海に浮かぶ島々や観光スポット、人気の讃岐うどん店めぐりの拠点としても最適。 */
  webYadoOnsenAreaTakamatsuDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaTakamatsuDescription', obj),
  /* 玉造温泉 */
  webYadoOnsenAreaTamatsukuri: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaTamatsukuri', obj),
  /* 米子空港から車で約1時間、バスなどを利用する場合は玉造温泉駅まで約1時間のアクセス。日本でも最古の歴史を持つ玉造温泉は、古くから美人の湯、薬湯として非常に有名。街の真ん中を流れる玉湯川沿いに、温泉旅館と土産店・飲食店が集まり温泉街を形成。 神社やお寺など散策スポットもあり、気軽に立ち寄れる足湯が3カ所ある。春には一面に桜が咲き誇る。日が沈むと各宿に灯りがともされ、散策に出歩く浴衣姿の宿泊客も。温泉情緒が一気に高まっていく。 */
  webYadoOnsenAreaTamatsukuriDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaTamatsukuriDescription', obj),
  /* 蓼科・白樺湖エリア */
  webYadoOnsenAreaTateshinaShirakabako: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaTateshinaShirakabako', obj),
  /* 高原リゾート地である蓼科・白樺湖エリアには、白樺湖や車山高原、蓼科温泉などの観光スポットが豊富で、また、日本酒や馬刺しなどのご当地グルメも楽しめる。蓼科湖から滝の湯川沿いに続く蓼科温泉郷。広大な自然に囲まれ、古くから温泉保養地として賑わってきた。 美術館やゴルフ場などが揃い高原リゾート地としても人気。 春は聖光寺の桜をはじめとする花々、夏はかわいらしい高原植物、秋は壮大な紅葉、冬は幻想的な雪景色と蓼科・白樺湖エリアでは四季折々の自然が満喫できる。 */
  webYadoOnsenAreaTateshinaShirakabakoDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaTateshinaShirakabakoDescription', obj),
  /* 天童温泉 */
  webYadoOnsenAreaTendo: (obj?: I18nArgumentType) => translation('WebYado-OnsenAreaTendo', obj),
  /* 将棋の駒の生産が全国の９５％を占めることで有名な天童市は、みちのくの旅情あふれる温泉街としてもその名が有名です。温泉の泉質はアルカリ泉で、肌にやさしい女性向きの温泉です。さくらんぼをはじめ、ぶどうやりんごなどのフルーツ狩りや陶芸体験、そば打ち体験なども楽しめます。近郊には日本の有名な歌人・松尾芭蕉も訪れた山寺（立石寺）があり、多くの旅人で賑わっています。 */
  webYadoOnsenAreaTendoDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaTendoDescription', obj),
  /* 天童温泉 */
  webYadoOnsenAreaTendou: (obj?: I18nArgumentType) => translation('WebYado-OnsenAreaTendou', obj),
  /* 十勝川温泉・帯広エリア */
  webYadoOnsenAreaTokachigawaObihiro: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaTokachigawaObihiro', obj),
  /* 新千歳空港から電車やバスで約3時間のアクセス十勝川温泉は北海道を代表する豊かな実りの大地・十勝平野に湧き出す温泉地。見渡す限りの牧場や田園風景が続く平野のほぼ中央、十勝川流域にホテルや旅館が立ち並び、温泉街を楽しむ湯客でいつも賑わう。温泉ともに四季の風景も楽しめ、春は「十勝が丘公園」から温泉街にかけて多くの花々で彩られ、夏は水辺にホタルが飛び交う。秋には川を遡るサケやトウモロコシなど旬の味覚が出揃い、冬には北から白鳥がやって来る。 */
  webYadoOnsenAreaTokachigawaObihiroDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaTokachigawaObihiroDescription', obj),
  /* 戸倉上山田温泉 */
  webYadoOnsenAreaTokurakamiyamada: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaTokurakamiyamada', obj),
  /* 長野・信州エリア最大の温泉街として人気の戸倉上山田温泉。温泉療法医がすすめる温泉にも選ばれた美肌温泉で、千曲川沿いに100年の歴史を持つ温泉は、昔から「善光寺」の参拝客で賑わってきた。 現在では好みに合わせて選べる大小さまざまの温泉宿が並び、華やかな温泉街を形成。近くには上田城・善光寺などの人気スポットがあり、観光も満喫できる。 */
  webYadoOnsenAreaTokurakamiyamadaDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaTokurakamiyamadaDescription', obj),
  /* すべての温泉地 */
  webYadoOnsenAreaTotal: (obj?: I18nArgumentType) => translation('WebYado-OnsenAreaTotal', obj),
  /* 十和田湖・八幡平エリア */
  webYadoOnsenAreaTowadakoHachimantai: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaTowadakoHachimantai', obj),
  /* 東北を代表する有名観光地である十和田湖は青森県と秋田県にまたがり、近くには近年海外からの人気も強い奥入瀬渓流もある。十和田八幡平国立公園に指定される八幡平は、年間を通して楽しめるリゾート地。秋の紅葉はもちろんのこと、春の新緑、夏の涼感あふれる湖面、時が止まったかのような冬の静けさなど、四季折々の違った景色を見ることができる。遊覧船やレジャーボート、乗馬なども楽しめる。 */
  webYadoOnsenAreaTowadakoHachimantaiDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaTowadakoHachimantaiDescription', obj),
  /* 洞爺湖エリア */
  webYadoOnsenAreaToyako: (obj?: I18nArgumentType) => translation('WebYado-OnsenAreaToyako', obj),
  /* 洞爺湖温泉は、新千歳空港や函館から電車で1時間30分、札幌駅からバスでも行けるアクセス。比較的温暖な気候で、雪も少なく冬でも快適に過ごせるのが魅力で、洞爺湖畔沿いに位置するホテルが多く、客室や露天風呂などから洞爺湖の絶景を望める。そして、4月末から10月末まで毎日開催されている「洞爺湖ロングラン花火大会」で打ち上げられる花火を温泉に入りながら眺めるのが大人気。 花火大会に合わせ花火鑑賞船も運行し、温泉街の夜景を背景に湖上から眺める花火鑑賞は絶対に忘れられない思い出になるだろう。 */
  webYadoOnsenAreaToyakoDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaToyakoDescription', obj),
  /* 月岡・咲花・五頭温泉郷 */
  webYadoOnsenAreaTsukioka: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaTsukioka', obj),
  /* 東京駅から上越新幹線＋ローカル線で約3時間のアクセス。月岡温泉は、美肌効果のある温泉として有名で、温泉街はほのかに硫黄の香りに包まれている｡料理には新潟の山の幸・海の幸の旬の食材がたくさん使われ､目と舌を喜ばせてくれる｡辺り一面を染める｢田んぼに沈む夕陽｣は最も新潟らしい風景の一つ。阿賀野川近くにひっそり佇む咲花温泉｡時折眼下を行く川下りの船もまた、この地域ならではの風情だ。毎年夏に開催される｢水中花火大会｣は非常に人気｡各宿自慢の温泉が入れる湯めぐりもおすすめ｡新潟平野を見下ろす五頭山の麓に広がる五頭温泉郷｡３つの異なる温泉が楽しめる静かな温泉地｡湯めぐりもでき、各宿ではこだわりの地元食材を使った自慢の料理を楽しむ事ができる。 */
  webYadoOnsenAreaTsukiokaDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaTsukiokaDescription', obj),
  /* 津山・蒜山高原エリア */
  webYadoOnsenAreaTsuyamaHiruzen: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaTsuyamaHiruzen', obj),
  /* 岡山駅から電車で1.5時間〜2時間でアクセス可能なエリア。岡山県北部は、日本観光地百選にも選ばれ奥津温泉や、百々温泉等の温泉があり、四季を通じて山の幸を堪能できるエリアです。また、蒜山高原のレジャー施設を始めとして体験施設も豊富で、見所の多いエリアです。 */
  webYadoOnsenAreaTsuyamaHiruzenDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaTsuyamaHiruzenDescription', obj),
  /* 宇奈月温泉 */
  webYadoOnsenAreaUnazuki: (obj?: I18nArgumentType) => translation('WebYado-OnsenAreaUnazuki', obj),
  /* 宇奈月温泉は、東京駅から北陸新幹線で約2時間、そこからバスで約20分のアクセス。黒部峡谷の玄関口にあり、富山県随一の規模を誇る温泉郷で、黒部峡谷の見事な絶景を望むトロッコ電車が楽しめる。 トロッコ電車からはエメラルドグリーンに輝く湖面や仏岩、万年雪や紅葉などを堪能でき、その景色は想像を絶する美しさだ。山間に降り注ぐ日差しが絶景をより美しく際立たせ、訪れる者を魅了する。四季折々の表情を見せてくれる黒部峡谷を眺めながら入る露天風呂は最高。 */
  webYadoOnsenAreaUnazukiDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaUnazukiDescription', obj),
  /* 雲仙温泉・小浜・島原エリア */
  webYadoOnsenAreaUnzenObamaShimabara: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaUnzenObamaShimabara', obj),
  /* 福岡・博多から電車で約90分のJR諫早駅からさらにバスで約80分、または長崎空港からバスで約2時間のアクセス。標高700mの地に湧く雲仙温泉は乳白色の硫黄泉で、絶えず吹き上げる噴煙と硫黄の香りがする。日本初の国立公園に指定された温泉保養地で、約350年の歴史を誇る。キリシタン殉教の舞台になった所でも知られており、哀しい歴史と悲話が今に残る。湯上がりにおすすめは、名物「温泉たまご」や島原の乱で天草四郎が考案したという郷土料理「具雑煮（ぐぞうに）」。小浜温泉は海岸沿いに３０軒の旅館・ホテルが建ち並び、新鮮な海の幸が味わえる。 */
  webYadoOnsenAreaUnzenObamaShimabaraDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaUnzenObamaShimabaraDescription', obj),
  /* 嬉野温泉 */
  webYadoOnsenAreaUreshino: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaUreshino', obj),
  /* 嬉野温泉は、福岡空港からバスで1.5時間のアクセス。九州の人気温泉地で、一面の茶畑や桜を楽しめる。嬉野川の清流に沿って60軒余の宿があり、温泉街には充実した施設を誇る宿が多い。「茶風呂」を提供する宿もあり、お茶に含まれるビタミンＣとカテキンが美肌効果をさらに高めると言われている。嬉野温泉の名物はお茶と、とろける温泉湯豆腐。 */
  webYadoOnsenAreaUreshinoDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaUreshinoDescription', obj),
  /* 和倉温泉 */
  webYadoOnsenAreaWakura: (obj?: I18nArgumentType) => translation('WebYado-OnsenAreaWakura', obj),
  /* 和倉温泉は七尾湾に面した能登半島最大の温泉地です。歴史ある豊富な食塩泉は、白鷺が傷を癒し、羽を休めた海の出湯が発祥とされています。街の中心には、日帰りの温泉施設として人気の「総湯」があります。 */
  webYadoOnsenAreaWakuraDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaWakuraDescription', obj),
  /* わめいじんぐ温泉 */
  webYadoOnsenAreaWamazing: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaWamazing', obj),
  /* 日本を代表する名泉の一つであり、万里集九と林羅山は日本三名泉の一つに数えた。江戸時代後期以降何度も作られた温泉番付の格付では、当時の最高位である大関が定位置であった。自噴する温泉の湯量は極めて豊富であり、湯温も摂氏50-90度前後と高い。万代鉱源泉はラジエーターを使い高い湯温を下げて配湯している。時間湯では昔から草津節などを唄いながら木の板（湯もみ板と言う）で温泉をかき回し、湯温を下げるのが特徴的な「湯もみ」が行われる。 */
  webYadoOnsenAreaWamazingDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaWamazingDescription', obj),
  /* 草津温泉 */
  webYadoOnsenAreaWamazingName: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaWamazingName', obj),
  /* 山口県その他エリア */
  webYadoOnsenAreaYamaguchi: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaYamaguchi', obj),
  /* 山口県は日本の中国地方に属する都道府県で、本州最南端に位置します。当時の大名が京都を模した街づくりを行ったことから、「西の京」と呼ばれています。アジアでも有数の巨大な大鍾乳洞である「秋芳洞」や、死ぬまでに行きたい世界の絶景にも選ばれ、コバルトブルーの海が人気の「角島」などの観光スポットが数多くあります。東京からは飛行機で約1時間半、名古屋や大阪からは新幹線で2-3時間、福岡からは新幹線で約40分でアクセス可能です。 */
  webYadoOnsenAreaYamaguchiDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaYamaguchiDescription', obj),
  /* 山梨県その他エリア */
  webYadoOnsenAreaYamanashi: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaYamanashi', obj),
  /* 東京から特急列車で約2時間のアクセス。山梨県には、富士河口湖エリアや、石和温泉以外にも、たくさんの温泉が楽しめる。例えば、地下1000Mから湧き出る16種類の様々な風呂が楽しめる竜王ラドン温泉では、周辺では四季折々の果実狩りも楽しめ、富士山や八ヶ岳などの観光拠点にも便利な立地。 */
  webYadoOnsenAreaYamanashiDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaYamanashiDescription', obj),
  /* 湯原温泉 */
  webYadoOnsenAreaYubara: (obj?: I18nArgumentType) => translation('WebYado-OnsenAreaYubara', obj),
  /* 岡山空港や岡山駅からバスで2.5時間のアクセス。湯原温泉周辺では、高さ110mと中国エリア一の名瀑「神庭（かんば）の滝」をはじめ豊かな渓谷美が見られる。 地域を流れる旭川を中心に元禄時代創業の老舗旅館や射的場、土産物屋が建ち並び昔ながらの温泉街を形成しているのが湯原温泉。シンボルは、河原に湧いた、24時間無料で入浴可能な共同露天風呂「砂湯」。全国露天風呂番付では西日本の最高位「横綱」に選ばれた。 */
  webYadoOnsenAreaYubaraDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaYubaraDescription', obj),
  /* 湯田温泉 */
  webYadoOnsenAreaYuda: (obj?: I18nArgumentType) => translation('WebYado-OnsenAreaYuda', obj),
  /* 山陽路唯一の温泉郷として栄え、今もなお華やかな雰囲気に包まれています。その昔、一匹の白狐が夜な夜な湯に浸かって傷を治したという「白狐伝説」が言い伝えられており、今でもマスコットとして愛されています。萩や津和野といった山口県内の主要観光へは1時間以内で行ける観光拠点です。市街地にあることから飲食店も３００軒以上、レジャー施設もカラオケBOX・ホウリング場・スパ施設など豊富です。お湯は透明の弱アルカリ性単純温泉です。 */
  webYadoOnsenAreaYudaDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaYudaDescription', obj),
  /* 湯田中・渋温泉 */
  webYadoOnsenAreaYudanakaShibu: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaYudanakaShibu', obj),
  /* 湯田中・渋温泉は日本の中部地方に位置する長野県にある温泉地のことで、温泉大国ならではの豊富な湯量と泉質の良さが特長です。湯田中温泉は、開湯1350年を越える、信州を代表する温泉場です。また、渋温泉は、37本もの源泉を持つ人気の温泉街で、全ての旅館と外湯は１００%源泉掛け流しとなっています。野猿が入浴しているのが見られる「地獄谷野猿公苑」や、年間600万人もの参拝者が訪れるパワースポットである「善光寺」などの観光スポットも豊富です。東京駅から長野駅までは北陸新幹線で約1時間半、新潟駅からは約2時間、金沢駅からは約3時間でアクセスすることができます。長野駅からは「長野電鉄」湯田中行き特急で約50分程度で移動できます。 */
  webYadoOnsenAreaYudanakaShibuDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaYudanakaShibuDescription', obj),
  /* 由布院温泉 */
  webYadoOnsenAreaYufuin: (obj?: I18nArgumentType) => translation('WebYado-OnsenAreaYufuin', obj),
  /* 【福岡周辺】豊後富士と呼ばれる美しい由布岳の山麓に広がり、日本全国2位の湯量を誇る人気温泉地です。秋から冬にかけて朝霧に包まれる風景は幻想的であり、四季折々の自然の風景が旅の心を和ませます。個性的な美術館、お洒落なショップやレストランが散在し、女性の憧れの温泉地となっています。湯量が豊富で広い範囲で湯が湧くため、旅館が一箇所に集積する必要が少なかったことから、一軒の敷地も比較的広く、町の造りはゆったりとしていることも特徴的です。【福岡周辺】 */
  webYadoOnsenAreaYufuinDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaYufuinDescription', obj),
  /* 湯河原温泉 */
  webYadoOnsenAreaYugawara: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaYugawara', obj),
  /* 湯村温泉・香住エリア */
  webYadoOnsenAreaYumuraKasumi: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaYumuraKasumi', obj),
  /* 湯村温泉は大阪、神戸からバスで約3時間のアクセス。兵庫県と鳥取県の県境近くにあり、情緒あふれる老舗旅館や近代的な宿が軒を連ねる温泉地。食の魅力で溢れており、きめ細かい霜降りと鮮やかな紅色が自慢の和牛「但馬牛」や、水揚げ量日本一の浜坂港から直送される捕れたてのホタルイカ、さらに、香住温泉郷では、日本人の誰もが食べたいと憧れる松葉ガニが堪能できる。 */
  webYadoOnsenAreaYumuraKasumiDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaYumuraKasumiDescription', obj),
  /* 湯郷温泉 */
  webYadoOnsenAreaYunogo: (obj?: I18nArgumentType) => translation('WebYado-OnsenAreaYunogo', obj),
  /* 岡山駅からバスで約100分のアクセス。湯郷温泉の歴史は古く、1200年ほど前に発見したと伝えられる名湯。美肌づくりの湯としても女性に人気の温泉。 また、美作牛をはじめ、旬の山の幸は美味。特に秋の味覚の王様“マツタケ”の産地として有名。 */
  webYadoOnsenAreaYunogoDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaYunogoDescription', obj),
  /* 山形蔵王・かみのやま温泉 */
  webYadoOnsenAreaZao: (obj?: I18nArgumentType) => translation('WebYado-OnsenAreaZao', obj),
  /* 蔵王温泉は、強い酸性の硫黄泉であり、皮膚に良く効き、肌を白く滑らかにするため「姫の湯」「美人づくりの湯」と言われています。温泉本来の成分を感じてもらおうと、多くの施設で源泉１００％掛け流しの湯を楽しめることも特徴です。標高９００ｍ台にあり、夏は山形市街地より約１０度も涼しいリゾート地です。避暑はもちろん、季節毎に変化する山の色を満喫できるトレッキングが人気です。冬季は国内有数の規模を誇る蔵王温泉スキー場を併設しており、多くのスキー客で賑わっています。 */
  webYadoOnsenAreaZaoDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaZaoDescription', obj),
  /* お風呂の情報 */
  webYadoOnsenBathInformation: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenBathInformation', obj),
  /* {{onsenName}}について詳しく見る */
  webYadoOnsenBlogLinkDescreiption: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenBlogLinkDescreiption', obj),
  /* 決済確認 | WAmazing */
  webYadoOnsenBookingPaylaterPayTitle: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenBookingPaylaterPayTitle', obj),
  /* 決済確認 */
  webYadoOnsenBookingPaylaterPaymentConfirmation: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenBookingPaylaterPaymentConfirmation', obj),
  /* {{name}}の位置 */
  webYadoOnsenCharacteristics: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenCharacteristics', obj),
  /* 温泉がある日本のホテル */
  webYadoOnsenFeatureCategoryHotel: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenFeatureCategoryHotel', obj),
  /* 日本の温泉ホテル・旅館を熟知した大手旅行代理店出身者が、日本の温泉があるホテルの中から、特に訪日外国人に人気があるこだわりのホテルを厳選して紹介！ 銀山温泉や有馬温泉などの人気温泉地から、訪日外国人にはまだあまり知られていない隠れた秘湯まで豊富にラインナップ！ */
  webYadoOnsenFeatureCategoryHotelDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenFeatureCategoryHotelDescription', obj),
  /* 温泉がある日本のホテル | WAmazing温泉 */
  webYadoOnsenFeatureCategoryHotelTitle: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenFeatureCategoryHotelTitle', obj),
  /* 温泉がある日本の旅館 */
  webYadoOnsenFeatureCategoryRyokan: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenFeatureCategoryRyokan', obj),
  /* 日本の温泉ホテル・旅館を熟知した大手旅行代理店出身者が、日本の温泉があるホテルの中から、日本の伝統文化が体験できることから訪日外国人にも大変人気がある「旅館」に特化して厳選して紹介！銀山温泉や有馬温泉などの人気温泉地から、訪日外国人にはまだあまり知られていない隠れた秘湯まで豊富にラインナップ！ */
  webYadoOnsenFeatureCategoryRyokanDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenFeatureCategoryRyokanDescription', obj),
  /* 温泉がある日本の旅館 | WAmazing温泉 */
  webYadoOnsenFeatureCategoryRyokanTitle: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenFeatureCategoryRyokanTitle', obj),
  /* ビュッフェ付きプランあり宿 */
  webYadoOnsenFeatureDinnerGenreBuffet: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenFeatureDinnerGenreBuffet', obj),
  /* 日本の温泉ホテル・旅館を熟知した大手旅行代理店出身者が、日本の温泉があるホテルの中から、ファミリーやカップル・夫婦で好きな食事を自由に楽しめるビュッフェ付きプランあるホテルを厳選して紹介！ */
  webYadoOnsenFeatureDinnerGenreBuffetDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenFeatureDinnerGenreBuffetDescription', obj),
  /* ビュッフェ付きプランがある日本の温泉宿 | WAmazing温泉 */
  webYadoOnsenFeatureDinnerGenreBuffetTitle: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenFeatureDinnerGenreBuffetTitle', obj),
  /* 日本料理（懐石・会席）付きプランあり宿 */
  webYadoOnsenFeatureDinnerGenreJapaneseKaiseki: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenFeatureDinnerGenreJapaneseKaiseki', obj),
  /* 日本の温泉ホテル・旅館を熟知した大手旅行代理店出身者が、日本の温泉があるホテルの中から、訪日外国人にも人気がある日本の伝統的な料理が楽しめる日本料理（懐石・会席）付きプランあるホテルを厳選して紹介！ */
  webYadoOnsenFeatureDinnerGenreJapaneseKaisekiDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenFeatureDinnerGenreJapaneseKaisekiDescription', obj),
  /* 日本料理（懐石・会席）付きプランがある日本の温泉宿 | WAmazing温泉 */
  webYadoOnsenFeatureDinnerGenreJapaneseKaisekiTitle: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenFeatureDinnerGenreJapaneseKaisekiTitle', obj),
  /* 展望風呂あり宿 */
  webYadoOnsenFeatureFineViewBath: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenFeatureFineViewBath', obj),
  /* 日本の温泉ホテル・旅館を熟知した大手旅行代理店出身者が、日本の温泉があるホテルの中から、大浴場からの眺望が綺麗な宿を厳選して紹介！ */
  webYadoOnsenFeatureFineViewBathDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenFeatureFineViewBathDescription', obj),
  /* 展望風呂がある日本の温泉宿 | WAmazing温泉 */
  webYadoOnsenFeatureFineViewBathTitle: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenFeatureFineViewBathTitle', obj),
  /* 禁煙ルームあり宿 */
  webYadoOnsenFeatureNonSmokingRoom: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenFeatureNonSmokingRoom', obj),
  /* 日本の温泉ホテル・旅館を熟知した大手旅行代理店出身者が、日本の温泉があるホテルの中から、禁煙に対応した部屋やフロアがある宿を厳選して紹介！ */
  webYadoOnsenFeatureNonSmokingRoomDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenFeatureNonSmokingRoomDescription', obj),
  /* 禁煙ルームがある日本の温泉宿 | WAmazing温泉 */
  webYadoOnsenFeatureNonSmokingRoomTitle: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenFeatureNonSmokingRoomTitle', obj),
  /* 露天風呂あり宿 */
  webYadoOnsenFeatureOpenAirBath: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenFeatureOpenAirBath', obj),
  /* 日本の温泉ホテル・旅館を熟知した大手旅行代理店出身者が、日本の温泉があるホテルの中から、眺望が楽しめることで訪日外国人にも人気がある露天風呂がある宿を厳選して紹介！ */
  webYadoOnsenFeatureOpenAirBathDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenFeatureOpenAirBathDescription', obj),
  /* 客室露天付きの部屋あり宿 */
  webYadoOnsenFeatureOpenAirBathRoom: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenFeatureOpenAirBathRoom', obj),
  /* 日本の温泉ホテル・旅館を熟知した大手旅行代理店出身者が、日本の温泉があるホテルの中から、客室に露天風呂がついたハイグレードな部屋がある宿を厳選して紹介！ */
  webYadoOnsenFeatureOpenAirBathRoomDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenFeatureOpenAirBathRoomDescription', obj),
  /* 客室露天付きの部屋がある日本の温泉宿 | WAmazing温泉 */
  webYadoOnsenFeatureOpenAirBathRoomTitle: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenFeatureOpenAirBathRoomTitle', obj),
  /* 露天風呂がある日本の温泉宿 | WAmazing温泉 */
  webYadoOnsenFeatureOpenAirBathTitle: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenFeatureOpenAirBathTitle', obj),
  /* 貸切風呂に露天風呂あり宿 */
  webYadoOnsenFeatureOpenAirPrivateBath: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenFeatureOpenAirPrivateBath', obj),
  /* 日本の温泉ホテル・旅館を熟知した大手旅行代理店出身者が、日本の温泉があるホテルの中から、眺望が楽しめることで訪日外国人にも人気がある露天風呂を、貸切風呂として貸し切ることが可能な宿を厳選して紹介！ */
  webYadoOnsenFeatureOpenAirPrivateBathDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenFeatureOpenAirPrivateBathDescription', obj),
  /* 貸切風呂に露天風呂がある日本の温泉宿 | WAmazing温泉 */
  webYadoOnsenFeatureOpenAirPrivateBathTitle: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenFeatureOpenAirPrivateBathTitle', obj),
  /* お迎えサービスあり宿 */
  webYadoOnsenFeaturePickupAvailable: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenFeaturePickupAvailable', obj),
  /* 日本の温泉ホテル・旅館を熟知した大手旅行代理店出身者が、日本の温泉があるホテルの中から、WAmazingの独自サービスである最寄りの駅やバス停からホテルまでのWEBお迎えサービス手配に対応した宿を厳選して紹介！ */
  webYadoOnsenFeaturePickupAvailableDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenFeaturePickupAvailableDescription', obj),
  /* お迎えサービスがある日本の温泉宿 | WAmazing温泉 */
  webYadoOnsenFeaturePickupAvailableTitle: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenFeaturePickupAvailableTitle', obj),
  /* 貸切風呂あり宿 */
  webYadoOnsenFeaturePrivateBath: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenFeaturePrivateBath', obj),
  /* 日本の温泉ホテル・旅館を熟知した大手旅行代理店出身者が、日本の温泉があるホテルの中から、カップルやファミリーでプライベートな温泉入浴を楽しめる貸切風呂がある宿を厳選して紹介！ */
  webYadoOnsenFeaturePrivateBathDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenFeaturePrivateBathDescription', obj),
  /* 貸切風呂がある温泉宿 | WAmazing温泉 */
  webYadoOnsenFeaturePrivateBathTitle: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenFeaturePrivateBathTitle', obj),
  /* 部屋食・個室食プランあり宿 */
  webYadoOnsenFeaturePrivateDinners: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenFeaturePrivateDinners', obj),
  /* 日本の温泉ホテル・旅館を熟知した大手旅行代理店出身者が、日本の温泉があるホテルの中から、客室でゆっくり食事が楽しめる部屋食付きプランや、専用の個室でプライベートに食事を楽しめる部屋食付きプランのあるホテルを厳選して紹介！ */
  webYadoOnsenFeaturePrivateDinnersDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenFeaturePrivateDinnersDescription', obj),
  /* 部屋食・個室食プランがある日本の温泉宿 | WAmazing温泉 */
  webYadoOnsenFeaturePrivateDinnersTitle: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenFeaturePrivateDinnersTitle', obj),
  /* 貸切風呂が事前予約可能宿 */
  webYadoOnsenFeatureReservedPrivateBath: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenFeatureReservedPrivateBath', obj),
  /* 日本の温泉ホテル・旅館を熟知した大手旅行代理店出身者が、日本の温泉があるホテルの中から、WAmazingの独自サービスである貸切風呂の事前予約手配サービスに対応した宿を厳選して紹介！ */
  webYadoOnsenFeatureReservedPrivateBathDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenFeatureReservedPrivateBathDescription', obj),
  /* 貸切風呂が事前予約できる日本の温泉宿 | WAmazing温泉 */
  webYadoOnsenFeatureReservedPrivateBathTitle: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenFeatureReservedPrivateBathTitle', obj),
  /* 客室数の少ない隠れ宿のような宿 */
  webYadoOnsenFeatureSmallNumberOfRooms: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenFeatureSmallNumberOfRooms', obj),
  /* 日本の温泉ホテル・旅館を熟知した大手旅行代理店出身者が、日本の温泉があるホテルの中から、小さな旅館ならではのおもてなしや、特別な時間をゆっくり楽しめる、隠れ宿のような20室以下の小規模旅館・温泉宿を厳選して紹介！ */
  webYadoOnsenFeatureSmallNumberOfRoomsDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenFeatureSmallNumberOfRoomsDescription', obj),
  /* 客室数の少ない隠れ宿のような日本の温泉宿 | WAmazing温泉 */
  webYadoOnsenFeatureSmallNumberOfRoomsTitle: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenFeatureSmallNumberOfRoomsTitle', obj),
  /* 評価の高い日本の温泉宿 */
  webYadoOnsenFeatureTopRated: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenFeatureTopRated', obj),
  /* 日本の温泉ホテル・旅館を熟知した大手旅行代理店出身者が、日本の温泉があるホテルの中から、訪日外国人によるクチコミやレビューでスコアが高い宿を厳選して紹介！ */
  webYadoOnsenFeatureTopRatedDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenFeatureTopRatedDescription', obj),
  /* 評価の高い日本の温泉宿 | WAmazing温泉 */
  webYadoOnsenFeatureTopRatedTitle: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenFeatureTopRatedTitle', obj),
  /* {{title}} | WAmazing */
  webYadoOnsenFeaturedTitle: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenFeaturedTitle', obj),
  /* 展望風呂 */
  webYadoOnsenFineViewBath: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenFineViewBath', obj),
  /* 無料 */
  webYadoOnsenForFree: (obj?: I18nArgumentType) => translation('WebYado-OnsenForFree', obj),
  /* 大浴場 */
  webYadoOnsenInnerBath: (obj?: I18nArgumentType) => translation('WebYado-OnsenInnerBath', obj),
  /* ジャグジー */
  webYadoOnsenJetBath: (obj?: I18nArgumentType) => translation('WebYado-OnsenJetBath', obj),
  /* 温泉宿の予約時にお好きな夕飯を選択します。 */
  webYadoOnsenLPHowToUseFoodSelectionStepOne: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenLPHowToUseFoodSelectionStepOne', obj),
  /* 食事の選択方法 */
  webYadoOnsenLPHowToUseFoodSelectionTitle: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenLPHowToUseFoodSelectionTitle', obj),
  /* 1.安心の中国語、英語のカスタマーサービス */
  webYadoOnsenLpBenefitOne: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenLpBenefitOne', obj),
  /* 温泉旅行が初めてで不安な方も安心！日本在住で温泉に詳しい台湾人・香港人スタッフがお客様がお困りの際にサポート致します！宿の情報や実際の旅行中 の不備等、何かお困りなことが不明点あれば是非左下のチャットボタンよりお問い合わせ下さい。 */
  webYadoOnsenLpBenefitOneBody: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenLpBenefitOneBody', obj),
  /* 3.魅力的な宿の掲載 */
  webYadoOnsenLpBenefitThree: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenLpBenefitThree', obj),
  /* どの温泉がいいかわからない方も安心！WAmazingでは旅行業を長年経験している精通した日本人スタッフが時間をかけて厳選した質が高い温泉宿のみを掲載しております。 */
  webYadoOnsenLpBenefitThreeBody: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenLpBenefitThreeBody', obj),
  /* WAmazingで予約すると他にもこんなメリットが */
  webYadoOnsenLpBenefitTitle: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenLpBenefitTitle', obj),
  /* 2.最低価格保証 */
  webYadoOnsenLpBenefitTwo: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenLpBenefitTwo', obj),
  /* WAmazingは温泉旅行の手厚いサポートだけでなく、最低価格保証をしております。同一プランにも関わらず、より安い金額で予約可能な他のサービスを見つけた場合は、お問い合わせください。宿泊金額の割引、または返金をさせていただきます。 */
  webYadoOnsenLpBenefitTwoBody: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenLpBenefitTwoBody', obj),
  /* 温泉ホテル・旅館を探す */
  webYadoOnsenLpConversion: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenLpConversion', obj),
  /* 食事がおすすめな宿特集 */
  webYadoOnsenLpCuratedByDinner: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenLpCuratedByDinner', obj),
  /* お迎えがおすすめな宿特集 */
  webYadoOnsenLpCuratedByPickup: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenLpCuratedByPickup', obj),
  /* 貸切風呂がおすすめな宿特集 */
  webYadoOnsenLpCuratedByPrivateBath: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenLpCuratedByPrivateBath', obj),
  /* トータル面がおすすめな宿特集 */
  webYadoOnsenLpCuratedByTotal: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenLpCuratedByTotal', obj),
  /* WAmazingなら、夕食メニューを自由に選べます。和牛や蟹などの人気料理やベジタリアンでも安心な精進料理など、日本の人気グルメを堪能できます。貸切風呂やお迎え車両の事前予約も可能です。会員限定割引や最低価格保証でお得に予約できます。 */
  webYadoOnsenLpDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenLpDescription', obj),
  /* 贅沢・快適な日本温泉旅行を */
  webYadoOnsenLpHeadCopy: (obj?: I18nArgumentType) => translation('WebYado-OnsenLpHeadCopy', obj),
  /* 豪華な夕食 */
  webYadoOnsenLpHeadSubCopyItemOne: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenLpHeadSubCopyItemOne', obj),
  /* 貸切風呂予約 */
  webYadoOnsenLpHeadSubCopyItemThree: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenLpHeadSubCopyItemThree', obj),
  /* お迎えサポート */
  webYadoOnsenLpHeadSubCopyItemTwo: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenLpHeadSubCopyItemTwo', obj),
  /* を通じてあなたをサポートします。 */
  webYadoOnsenLpHeadSubCopyOne: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenLpHeadSubCopyOne', obj),
  /* 安心して最高のサービスをお楽しみ下さい。 */
  webYadoOnsenLpHeadSubCopyTweo: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenLpHeadSubCopyTweo', obj),
  /* 安心して最高のサービスをお楽しみ下さい。 */
  webYadoOnsenLpHeadSubCopyTwo: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenLpHeadSubCopyTwo', obj),
  /* 日本の温泉旅館・ホテル予約なら会員数30万人突破のWAmazingで。 */
  webYadoOnsenLpHeading: (obj?: I18nArgumentType) => translation('WebYado-OnsenLpHeading', obj),
  /* 日本の温泉旅館・ホテル予約なら */
  webYadoOnsenLpHeading1: (obj?: I18nArgumentType) => translation('WebYado-OnsenLpHeading1', obj),
  /* 会員数30万人突破のWAmazingで。 */
  webYadoOnsenLpHeading2: (obj?: I18nArgumentType) => translation('WebYado-OnsenLpHeading2', obj),
  /* ※ 一部の宿では食事が選択できません。予めご了承下さい。 */
  webYadoOnsenLpHowToUseFoodSelectionNoticeOne: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenLpHowToUseFoodSelectionNoticeOne', obj),
  /* 温泉宿の予約時にお好きな夕飯を選択します。 */
  webYadoOnsenLpHowToUseFoodSelectionStepOne: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenLpHowToUseFoodSelectionStepOne', obj),
  /* 宿泊当日に指定された夕飯をお楽しみいただけます。 */
  webYadoOnsenLpHowToUseFoodSelectionStepTwo: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenLpHowToUseFoodSelectionStepTwo', obj),
  /* 食事の選択方法 */
  webYadoOnsenLpHowToUseFoodSelectionTitle: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenLpHowToUseFoodSelectionTitle', obj),
  /* ※一部の宿ではお迎えの申込み方法が異なります。詳細はカスタマーサポートにお問い合わせ下さい。 */
  webYadoOnsenLpHowToUsePickupNoticeOne: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenLpHowToUsePickupNoticeOne', obj),
  /* ※お迎えサービスが無い宿もございます予めご了承下さい。 */
  webYadoOnsenLpHowToUsePickupNoticeTwo: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenLpHowToUsePickupNoticeTwo', obj),
  /* 予約後、予約確認ページよりお迎えの申込みを行います。 */
  webYadoOnsenLpHowToUsePickupStepOne: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenLpHowToUsePickupStepOne', obj),
  /* 記載のお時間以内にお迎えの車が宿から参ります。 */
  webYadoOnsenLpHowToUsePickupStepThree: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenLpHowToUsePickupStepThree', obj),
  /* 当日指定の待合わせ場所に着きましたら、お迎え呼び出しボタンを押します。 */
  webYadoOnsenLpHowToUsePickupStepTwo: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenLpHowToUsePickupStepTwo', obj),
  /* お迎えの御利用方法 */
  webYadoOnsenLpHowToUsePickupTitle: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenLpHowToUsePickupTitle', obj),
  /* ※一部の宿ではお迎えの申込み方法が異なります。詳細はカスタマーサポートにお問い合わせ下さい。 */
  webYadoOnsenLpHowToUsePrivateBathNoticeOne: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenLpHowToUsePrivateBathNoticeOne', obj),
  /* ※貸切風呂サービスが無い宿もございます。予めご了承下さい。 */
  webYadoOnsenLpHowToUsePrivateBathNoticeTwo: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenLpHowToUsePrivateBathNoticeTwo', obj),
  /* 貸切風呂がを提供している旅館・ホテルを予約します。 */
  webYadoOnsenLpHowToUsePrivateBathStepOne: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenLpHowToUsePrivateBathStepOne', obj),
  /* 宿泊当日に貸切風呂をお楽しみ下さい。 */
  webYadoOnsenLpHowToUsePrivateBathStepThree: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenLpHowToUsePrivateBathStepThree', obj),
  /*  予約後、画面左下のチャットボタンよりに貸切風呂の予約を申込みます。 */
  webYadoOnsenLpHowToUsePrivateBathStepTwo: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenLpHowToUsePrivateBathStepTwo', obj),
  /* 貸切風呂のご利用方法 */
  webYadoOnsenLpHowToUsePrivateBathTitle: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenLpHowToUsePrivateBathTitle', obj),
  /* 日本,温泉,ホテル,旅館,宿泊,宿泊予約,予約,WAmazing */
  webYadoOnsenLpKeywords: (obj?: I18nArgumentType) => translation('WebYado-OnsenLpKeywords', obj),
  /* 日本のグルメと温泉が堪能できる人気旅館・ホテルを厳選してオススメ | WAmazing温泉 */
  webYadoOnsenLpTitle: (obj?: I18nArgumentType) => translation('WebYado-OnsenLpTitle', obj),
  /* せっかくの温泉旅行だからご当地の贅沢な食事を食べたい！WAmazingでは あなたの好みに合わせて温泉宿の夕飯の種類が選択可能です。苦手な食材がある方も安心して夕食をお楽しみいただけます。 */
  webYadoOnsenLpValuesOneBody: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenLpValuesOneBody', obj),
  /* 夕飯 */
  webYadoOnsenLpValuesOneName: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenLpValuesOneName', obj),
  /* 1.選べる贅沢な食事 */
  webYadoOnsenLpValuesOneTitle: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenLpValuesOneTitle', obj),
  /* せっかくの旅行だし快適に温泉を味わいたい！WAmazingで温泉宿を予約すると簡単に貸切温泉が手配できます。温泉で人の目気になるかたも、友人や家族と会話をしながらリラックスして温泉を楽しめます。 */
  webYadoOnsenLpValuesThreeBody: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenLpValuesThreeBody', obj),
  /* 貸切風呂予約方法 */
  webYadoOnsenLpValuesThreeHowToUseLink: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenLpValuesThreeHowToUseLink', obj),
  /* お迎え */
  webYadoOnsenLpValuesThreeName: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenLpValuesThreeName', obj),
  /* 3.貸切風呂で快適な温泉体験 */
  webYadoOnsenLpValuesThreeTitle: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenLpValuesThreeTitle', obj),
  /* 温泉予約３大サポート */
  webYadoOnsenLpValuesTitle: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenLpValuesTitle', obj),
  /* せっかくの温泉旅行で宿までは快適に移動したい！WAmazingで温泉宿を予約するとお迎えの手配が可能になります。荷物が多くて移動が大変、最寄り駅からたどり着けるか不安な方も快適に温泉宿へ向かうことができます。 */
  webYadoOnsenLpValuesTwoBody: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenLpValuesTwoBody', obj),
  /* 貸切風呂 */
  webYadoOnsenLpValuesTwoName: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenLpValuesTwoName', obj),
  /* 2.宿までのお迎えで快適な移動 */
  webYadoOnsenLpValuesTwoTitle: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenLpValuesTwoTitle', obj),
  /* WAmazing ピックアップ温泉宿・旅館 */
  webYadoOnsenLpWamazingCurating: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenLpWamazingCurating', obj),
  /* ※清掃、入れ替えなど、お風呂営業していない時間帯、曜日によって異なる場合があります。ご容赦ください。 */
  webYadoOnsenOpeningTimeNote: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenOpeningTimeNote', obj),
  /* 有料 */
  webYadoOnsenPaid: (obj?: I18nArgumentType) => translation('WebYado-OnsenPaid', obj),
  /* WAmazingなら、温泉がある人気ホテルを無料SIM付き、最低価格保証付き、夕食メニューが選べるサービス付き、送迎サービス付き、貸切風呂事前予約付きで予約できます。 */
  webYadoOnsenPrefectureDetailDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenPrefectureDetailDescription', obj),
  /* {{prefecture}},日本,温泉,ホテル,旅館,宿泊,宿泊予約,予約,WAmazing */
  webYadoOnsenPrefectureDetailKeywords: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenPrefectureDetailKeywords', obj),
  /* {{prefecture}}の温泉がある人気ホテル */
  webYadoOnsenPrefectureDetailTitle: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenPrefectureDetailTitle', obj),
  /* 中部 */
  webYadoOnsenRegionChubu: (obj?: I18nArgumentType) => translation('WebYado-OnsenRegionChubu', obj),
  /* 中国・四国 */
  webYadoOnsenRegionChugokuShikoku: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenRegionChugokuShikoku', obj),
  /* WAmazingなら、夕食メニューを自由に選べます。和牛や蟹などの人気料理やベジタリアンでも安心な精進料理など、日本の人気グルメを堪能できます。貸切風呂やお迎え車両の事前予約も可能です。会員限定割引や最低価格保証でお得に予約できます。 */
  webYadoOnsenRegionDetailDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenRegionDetailDescription', obj),
  /* {{onsenRegion}},日本,温泉,ホテル,旅館,宿泊,宿泊予約,予約,WAmazing */
  webYadoOnsenRegionDetailKeywords: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenRegionDetailKeywords', obj),
  /* {{onsenRegion}}にある、日本のグルメと温泉が堪能できる人気旅館・ホテルを厳選してオススメ */
  webYadoOnsenRegionDetailTitle: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenRegionDetailTitle', obj),
  /* 北海道 */
  webYadoOnsenRegionHokkaido: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenRegionHokkaido', obj),
  /* 北海道・東北 */
  webYadoOnsenRegionHokkaidoTohoku: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenRegionHokkaidoTohoku', obj),
  /* 関西 */
  webYadoOnsenRegionKansai: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenRegionKansai', obj),
  /* 関東 */
  webYadoOnsenRegionKanto: (obj?: I18nArgumentType) => translation('WebYado-OnsenRegionKanto', obj),
  /* 九州 */
  webYadoOnsenRegionKyushu: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenRegionKyushu', obj),
  /* 東北 */
  webYadoOnsenRegionTohoku: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenRegionTohoku', obj),
  /* サウナ */
  webYadoOnsenSauna: (obj?: I18nArgumentType) => translation('WebYado-OnsenSauna', obj),
  /* WAmazing温泉宿予約 */
  webYadoOnsenSitename: (obj?: I18nArgumentType) => translation('WebYado-OnsenSitename', obj),
  /* スパ */
  webYadoOnsenSpa: (obj?: I18nArgumentType) => translation('WebYado-OnsenSpa', obj),
  /* ※大浴場では、刺青・タトゥをされている方の入浴はお断りしております。 */
  webYadoOnsenTattooNote: (obj?: I18nArgumentType) => translation('WebYado-OnsenTattooNote', obj),
  /* [object Object] */
  webYadoOnsenTaxWarning: (obj?: I18nArgumentType) => translation('WebYado-OnsenTaxWarning', obj),
  /* 貸切風呂予約 */
  webYadoOnsenToCopyPrivateOnsen: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenToCopyPrivateOnsen', obj),
  /* プライベート入浴を希望する方向けに、貸切風呂の事前予約をサポートいたします。（一部宿のみ） */
  webYadoOnsenToCopyPrivateOnsenCopy: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenToCopyPrivateOnsenCopy', obj),
  /* 厳選された温泉宿 */
  webYadoOnsenTopCopyCarefullySelected: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenTopCopyCarefullySelected', obj),
  /* 日本中の温泉旅館との関係性を活かし、最も信頼できる施設を最低価格保証付きで掲載しています。 */
  webYadoOnsenTopCopyCarefullySelectedBody: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenTopCopyCarefullySelectedBody', obj),
  /* 宿での夕食は旅の一部 */
  webYadoOnsenTopCopyDinner: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenTopCopyDinner', obj),
  /* 日本の伝統料理や地元の特産など、お好きな夕食をお選びください。 */
  webYadoOnsenTopCopyDinnerBody: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenTopCopyDinnerBody', obj),
  /* お迎えに上がります */
  webYadoOnsenTopCopyOfferRide: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenTopCopyOfferRide', obj),
  /* アクセスが難しい温泉宿でも安心。旅館スタッフが最寄り駅までお迎えに参ります。 */
  webYadoOnsenTopCopyOfferRideBody: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenTopCopyOfferRideBody', obj),
  /* WAmazing 厳選された日本の温泉宿でグルメを楽しもう。食事内容・食事場所が事前にわかる・えらべる。お迎えサポート付き。最低価格保証。 */
  webYadoOnsenTopDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenTopDescription', obj),
  /* 日本各地の有名温泉旅館 */
  webYadoOnsenTopFamousOnsenRyokan: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenTopFamousOnsenRyokan', obj),
  /* 日本各地の有名温泉 */
  webYadoOnsenTopFamousOnsens: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenTopFamousOnsens', obj),
  /* 日本,旅行,宿泊,温泉,温泉宿 */
  webYadoOnsenTopKeywords: (obj?: I18nArgumentType) => translation('WebYado-OnsenTopKeywords', obj),
  /* （温泉エリア）{{number}}個 */
  webYadoOnsenTopOnsenAreaNumber: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenTopOnsenAreaNumber', obj),
  /* 厳選された日本の温泉宿を、グルメ・お迎えサービス付きで予約 - WAmazing */
  webYadoOnsenTopTitle: (obj?: I18nArgumentType) => translation('WebYado-OnsenTopTitle', obj),
  /* [object Object] */
  webYadoOnsenUnit: (obj?: I18nArgumentType) => translation('WebYado-OnsenUnit', obj),
  /* 温泉宿 */
  webYadoOnsenYado: (obj?: I18nArgumentType) => translation('WebYado-OnsenYado', obj),
  /* WAmazing 厳選された日本の温泉宿でグルメを楽しもう。食事内容・食事場所が事前にわかる・えらべる。お迎えサポート付き。最低価格保証。 */
  webYadoOnsenYadoBookingDetailDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenYadoBookingDetailDescription', obj),
  /* 日本,旅行,宿泊,温泉,温泉宿 */
  webYadoOnsenYadoBookingDetailKeywords: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenYadoBookingDetailKeywords', obj),
  /* 予約履歴 |  厳選された日本の温泉宿を、グルメ・お迎えサービス付きで予約 - WAmazing */
  webYadoOnsenYadoBookingDetailTitle: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenYadoBookingDetailTitle', obj),
  /* WAmazing 厳選された日本の温泉宿でグルメを楽しもう。食事内容・食事場所が事前にわかる・えらべる。お迎えサポート付き。最低価格保証。 */
  webYadoOnsenYadoBookingIndexDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenYadoBookingIndexDescription', obj),
  /* 日本,旅行,宿泊,温泉,温泉宿 */
  webYadoOnsenYadoBookingIndexKeywords: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenYadoBookingIndexKeywords', obj),
  /* 予約一覧 |  厳選された日本の温泉宿を、グルメ・お迎えサービス付きで予約 - WAmazing */
  webYadoOnsenYadoBookingIndexTitle: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenYadoBookingIndexTitle', obj),
  /* 支払い完了 |  厳選された日本の温泉宿を、グルメ・お迎えサービス付きで予約 - WAmazing */
  webYadoOnsenYadoBookingPaylaterCompleteTitle: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenYadoBookingPaylaterCompleteTitle', obj),
  /* WAmazingなら、夕食メニューを自由に選べます。和牛や蟹などの人気料理やベジタリアンでも安心な精進料理など、日本の人気グルメを堪能できます。貸切風呂やお迎え車両の事前予約も可能です。会員限定割引や最低価格保証でお得に予約できます。 */
  webYadoOnsenYadoDetailDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenYadoDetailDescription', obj),
  /* {{yado}},{{onsenArea}},{{onsenRegion}},日本,温泉,ホテル,旅館,宿泊,宿泊予約,予約,WAmazing */
  webYadoOnsenYadoDetailKeywords: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenYadoDetailKeywords', obj),
  /* {{yado}}（日本・{{prefecture}}・{{area}}） | WAmazing温泉 */
  webYadoOnsenYadoDetailTitle: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenYadoDetailTitle', obj),
  /* [object Object] */
  webYadoOnsenYadoFoundResults: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenYadoFoundResults', obj),
  /* の周辺の宿 */
  webYadoOnsenYadoNearby: (obj?: I18nArgumentType) => translation('WebYado-OnsenYadoNearby', obj),
  /* 周辺の宿 */
  webYadoOnsenYadoNearbyNav: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenYadoNearbyNav', obj),
  /* WAmazing 厳選された日本の温泉宿でグルメを楽しもう。食事内容・食事場所が事前にわかる・えらべる。お迎えサポート付き。最低価格保証。 */
  webYadoOnsenYadoOrderCompleteDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenYadoOrderCompleteDescription', obj),
  /* 日本,旅行,宿泊,温泉,温泉宿 */
  webYadoOnsenYadoOrderCompleteKeywords: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenYadoOrderCompleteKeywords', obj),
  /* 予約完了 |  厳選された日本の温泉宿を、グルメ・お迎えサービス付きで予約 - WAmazing */
  webYadoOnsenYadoOrderCompleteTitle: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenYadoOrderCompleteTitle', obj),
  /* 日本,旅行,宿泊,温泉,温泉宿 */
  webYadoOnsenYadoOrderCreateKeywords: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenYadoOrderCreateKeywords', obj),
  /* {{yado}}予約 |  厳選された日本の温泉宿を、グルメ・お迎えサービス付きで予約 - WAmazing */
  webYadoOnsenYadoOrderCreateTitle: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenYadoOrderCreateTitle', obj),
  /* 予約に関する重要なお知らせ */
  webYadoOnsenYadoOrderNoteTitle: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenYadoOrderNoteTitle', obj),
  /* 日本,旅行,宿泊,温泉,温泉宿 */
  webYadoOnsenYadoOrderPaymentKeywords: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenYadoOrderPaymentKeywords', obj),
  /* {{yado}}決済確認 |  厳選された日本の温泉宿を、グルメ・お迎えサービス付きで予約 - WAmazing */
  webYadoOnsenYadoOrderPaymentTitle: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenYadoOrderPaymentTitle', obj),
  /* WAmazing 厳選された日本の温泉宿でグルメを楽しもう。食事内容・食事場所が事前にわかる・えらべる。お迎えサポート付き。最低価格保証。 */
  webYadoOnsenYadoPickupRequestDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenYadoPickupRequestDescription', obj),
  /* 日本,旅行,宿泊,温泉,温泉宿 */
  webYadoOnsenYadoPickupRequestKeywords: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenYadoPickupRequestKeywords', obj),
  /* お迎え申し込み履歴 |  厳選された日本の温泉宿を、グルメ・お迎えサービス付きで予約 - WAmazing */
  webYadoOnsenYadoPickupRequestTitle: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenYadoPickupRequestTitle', obj),
  /* 温泉宿予約 */
  webYadoOnsenYadoReservation: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenYadoReservation', obj),
  /* 露天風呂 */
  webYadoOpenAirBath: (obj?: I18nArgumentType) => translation('WebYado-OpenAirBath', obj),
  /* Google Mapで開く */
  webYadoOpenGoogleMap: (obj?: I18nArgumentType) => translation('WebYado-OpenGoogleMap', obj),
  /* オプション */
  webYadoOptions: (obj?: I18nArgumentType) => translation('WebYado-Options', obj),
  /*  */
  webYadoOther: (obj?: I18nArgumentType) => translation('WebYado-Other', obj),
  /* その他特典付き */
  webYadoOtherBenefit: (obj?: I18nArgumentType) => translation('WebYado-OtherBenefit', obj),
  /* その他 */
  webYadoOtherDinner: (obj?: I18nArgumentType) => translation('WebYado-OtherDinner', obj),
  /* その他の設備 */
  webYadoOtherFacilities: (obj?: I18nArgumentType) => translation('WebYado-OtherFacilities', obj),
  /* その他 */
  webYadoOtherInformation: (obj?: I18nArgumentType) => translation('WebYado-OtherInformation', obj),
  /* その他注意事項 */
  webYadoOtherSpecialNote: (obj?: I18nArgumentType) => translation('WebYado-OtherSpecialNote', obj),
  /* 屋外プール */
  webYadoOutdoorSwimmingPool: (obj?: I18nArgumentType) =>
    translation('WebYado-OutdoorSwimmingPool', obj),
  /* 有料 */
  webYadoPaid: (obj?: I18nArgumentType) => translation('WebYado-Paid', obj),
  /* 駐車場(有料) */
  webYadoPaidParkingLot: (obj?: I18nArgumentType) => translation('WebYado-PaidParkingLot', obj),
  /* パジャマ */
  webYadoPajama: (obj?: I18nArgumentType) => translation('WebYado-Pajama', obj),
  /* パラグライダー */
  webYadoParagliding: (obj?: I18nArgumentType) => translation('WebYado-Paragliding', obj),
  /* 駐車場 */
  webYadoParkingInformation: (obj?: I18nArgumentType) =>
    translation('WebYado-ParkingInformation', obj),
  /* 駐車場の場所 */
  webYadoParkingLotLocation: (obj?: I18nArgumentType) =>
    translation('WebYado-ParkingLotLocation', obj),
  /* 敷地内・近隣 */
  webYadoParkingLotLocationBoth: (obj?: I18nArgumentType) =>
    translation('WebYado-ParkingLotLocationBoth', obj),
  /* 近隣 */
  webYadoParkingLotLocationNeighboring: (obj?: I18nArgumentType) =>
    translation('WebYado-ParkingLotLocationNeighboring', obj),
  /* 敷地内 */
  webYadoParkingLotLocationOnPremise: (obj?: I18nArgumentType) =>
    translation('WebYado-ParkingLotLocationOnPremise', obj),
  /* 敷地内・近隣 */
  webYadoParkingLotLocationOnPremiseAndNeighboring: (obj?: I18nArgumentType) =>
    translation('WebYado-ParkingLotLocationOnPremiseAndNeighboring', obj),
  /* フロントにパーテーションを設置している */
  webYadoPartitionAtFrontDesk: (obj?: I18nArgumentType) =>
    translation('WebYado-PartitionAtFrontDesk', obj),
  /* パスワードが異なります。 */
  webYadoPasswordConfirmationFailedErrorMessage: (obj?: I18nArgumentType) =>
    translation('WebYado-PasswordConfirmationFailedErrorMessage', obj),
  /* 後から決済 */
  webYadoPayLater: (obj?: I18nArgumentType) => translation('WebYado-PayLater', obj),
  /* 【決済期限】{{date}}（日本時間） */
  webYadoPayLaterDeadline: (obj?: I18nArgumentType) => translation('WebYado-PayLaterDeadline', obj),
  /* 決済は後ほど、予約詳細画面からでも行なっていただけます。 */
  webYadoPayLaterFromBookingDetail: (obj?: I18nArgumentType) =>
    translation('WebYado-PayLaterFromBookingDetail', obj),
  /* チェックイン日が直前のため、この決済はご利用できません */
  webYadoPayLaterNotAvailable: (obj?: I18nArgumentType) =>
    translation('WebYado-PayLaterNotAvailable', obj),
  /* {{date}}（日本時間） */
  webYadoPayLaterPaymentDeadline: (obj?: I18nArgumentType) =>
    translation('WebYado-PayLaterPaymentDeadline', obj),
  /* 期日までのお支払いがなければご予約は自動的にキャンセルされるため、予定が決まっていない方も安心してご予約いただけます。 */
  webYadoPayLaterServiceBody: (obj?: I18nArgumentType) =>
    translation('WebYado-PayLaterServiceBody', obj),
  /* 「後から決済可」の記載があるプランは、予約時にクレジットカード情報の入力が不要です！ */
  webYadoPayLaterServiceByPlan: (obj?: I18nArgumentType) =>
    translation('WebYado-PayLaterServiceByPlan', obj),
  /* ご予約時にクレジット情報入力が不要になる「後から決済」が利用できるようになりました！ */
  webYadoPayLaterServiceHeader: (obj?: I18nArgumentType) =>
    translation('WebYado-PayLaterServiceHeader', obj),
  /* 「後から決済」が適用されているため、クレジット情報入力の必要はありません。 */
  webYadoPayLaterWithoutCreditCard: (obj?: I18nArgumentType) =>
    translation('WebYado-PayLaterWithoutCreditCard', obj),
  /* 支払い完了 */
  webYadoPaylaterPaymentCompleted: (obj?: I18nArgumentType) =>
    translation('WebYado-PaylaterPaymentCompleted', obj),
  /* お支払い */
  webYadoPayment: (obj?: I18nArgumentType) => translation('WebYado-Payment', obj),
  /* 自動キャンセル */
  webYadoPaymentAutoCancellation: (obj?: I18nArgumentType) =>
    translation('WebYado-PaymentAutoCancellation', obj),
  /* 決済が完了しました！ */
  webYadoPaymentCompleted: (obj?: I18nArgumentType) => translation('WebYado-PaymentCompleted', obj),
  /* 決済期限 */
  webYadoPaymentDeadline: (obj?: I18nArgumentType) => translation('WebYado-PaymentDeadline', obj),
  /* 決済方法 */
  webYadoPaymentMethod: (obj?: I18nArgumentType) => translation('WebYado-PaymentMethod', obj),
  /* Alipay */
  webYadoPaymentMethodsAlipay: (obj?: I18nArgumentType) =>
    translation('WebYado-PaymentMethodsAlipay', obj),
  /* Amex */
  webYadoPaymentMethodsAmex: (obj?: I18nArgumentType) =>
    translation('WebYado-PaymentMethodsAmex', obj),
  /* クレジットカード決済 */
  webYadoPaymentMethodsCreditCard: (obj?: I18nArgumentType) =>
    translation('WebYado-PaymentMethodsCreditCard', obj),
  /* Diners */
  webYadoPaymentMethodsDiners: (obj?: I18nArgumentType) =>
    translation('WebYado-PaymentMethodsDiners', obj),
  /* JCB */
  webYadoPaymentMethodsJcb: (obj?: I18nArgumentType) =>
    translation('WebYado-PaymentMethodsJcb', obj),
  /* Master */
  webYadoPaymentMethodsMaster: (obj?: I18nArgumentType) =>
    translation('WebYado-PaymentMethodsMaster', obj),
  /* UnionPay */
  webYadoPaymentMethodsUnionPay: (obj?: I18nArgumentType) =>
    translation('WebYado-PaymentMethodsUnionPay', obj),
  /* Visa */
  webYadoPaymentMethodsVisa: (obj?: I18nArgumentType) =>
    translation('WebYado-PaymentMethodsVisa', obj),
  /* WeChatPay */
  webYadoPaymentMethodsWechatPay: (obj?: I18nArgumentType) =>
    translation('WebYado-PaymentMethodsWechatPay', obj),
  /* 予約と同時に決済は必要ありません */
  webYadoPaymentNotRequiredForBooking: (obj?: I18nArgumentType) =>
    translation('WebYado-PaymentNotRequiredForBooking', obj),
  /* お支払い期間 */
  webYadoPaymentPeriod: (obj?: I18nArgumentType) => translation('WebYado-PaymentPeriod', obj),
  /* 本日〜{{date}} */
  webYadoPaymentPeriodToDeadline: (obj?: I18nArgumentType) =>
    translation('WebYado-PaymentPeriodToDeadline', obj),
  /* 今すぐ決済 */
  webYadoPaymentTimingNow: (obj?: I18nArgumentType) => translation('WebYado-PaymentTimingNow', obj),
  /* 後から決済（今すぐクレジット情報入力の必要なし） */
  webYadoPaymentTimingPayLater: (obj?: I18nArgumentType) =>
    translation('WebYado-PaymentTimingPayLater', obj),
  /* 支払いは日本円で決済されます。日本円以外の表示価格は、現時点の為替に基づいて計算した参考価格です。 */
  webYadoPaymentWillBeSettledInJapaneseYen: (obj?: I18nArgumentType) =>
    translation('WebYado-PaymentWillBeSettledInJapaneseYen', obj),
  /* 期限内支払い */
  webYadoPaymentWithinPeriod: (obj?: I18nArgumentType) =>
    translation('WebYado-PaymentWithinPeriod', obj),
  /* [object Object] */
  webYadoPeopleNumber: (obj?: I18nArgumentType) => translation('WebYado-PeopleNumber', obj),
  /* 一人あたり */
  webYadoPerPerson: (obj?: I18nArgumentType) => translation('WebYado-PerPerson', obj),
  /* {{percent}}% */
  webYadoPercent: (obj?: I18nArgumentType) => translation('WebYado-Percent', obj),
  /* 人数 */
  webYadoPersonCount: (obj?: I18nArgumentType) => translation('WebYado-PersonCount', obj),
  /* ペット同伴OK */
  webYadoPetFriendly: (obj?: I18nArgumentType) => translation('WebYado-PetFriendly', obj),
  /* 写真と概要 */
  webYadoPhotoAndSummary: (obj?: I18nArgumentType) => translation('WebYado-PhotoAndSummary', obj),
  /* 送迎 */
  webYadoPickUp: (obj?: I18nArgumentType) => translation('WebYado-PickUp', obj),
  /* 申し込み済みです。現地の集合場所に到着したら、詳細ページの「呼び出しボタン」を押してください。 */
  webYadoPickupAppliedWithCall: (obj?: I18nArgumentType) =>
    translation('WebYado-PickupAppliedWithCall', obj),
  /* 申し込み済みです。{{time}}に集合場所にお越しください。 */
  webYadoPickupAppliedWithTime: (obj?: I18nArgumentType) =>
    translation('WebYado-PickupAppliedWithTime', obj),
  /* 申し込み済みです。時刻表の時間通りに車がお迎えに来ます。 */
  webYadoPickupAppliedWithTimeTable: (obj?: I18nArgumentType) =>
    translation('WebYado-PickupAppliedWithTimeTable', obj),
  /* 申し込み済みです。詳細ページをご確認の上、集合場所にお越しください。 */
  webYadoPickupAppliedWithoutTime: (obj?: I18nArgumentType) =>
    translation('WebYado-PickupAppliedWithoutTime', obj),
  /* 申し込み済みです。現地の集合場所に到着したら、詳細ページの「呼び出しボタン」を押してください。 */
  webYadoPickupApplyedWithCall: (obj?: I18nArgumentType) =>
    translation('WebYado-PickupApplyedWithCall', obj),
  /* 申し込み済みです。{{time}}に車がお迎えに来ます。 */
  webYadoPickupApplyedWithTime: (obj?: I18nArgumentType) =>
    translation('WebYado-PickupApplyedWithTime', obj),
  /* 申し込み済みです。時刻表の時間通りに車がお迎えに来ます。 */
  webYadoPickupApplyedWithTimeTable: (obj?: I18nArgumentType) =>
    translation('WebYado-PickupApplyedWithTimeTable', obj),
  /* お迎えサービスをご利用できる宿です */
  webYadoPickupAvailable: (obj?: I18nArgumentType) => translation('WebYado-PickupAvailable', obj),
  /* {{place}}までお迎え */
  webYadoPickupAvailablePlace: (obj?: I18nArgumentType) =>
    translation('WebYado-PickupAvailablePlace', obj),
  /* 対応時間 */
  webYadoPickupAvailableTime: (obj?: I18nArgumentType) =>
    translation('WebYado-PickupAvailableTime', obj),
  /* 呼び出す */
  webYadoPickupCallButtonTitle: (obj?: I18nArgumentType) =>
    translation('WebYado-PickupCallButtonTitle', obj),
  /* 当日、集合場所に到着してからお呼び出しください。 */
  webYadoPickupCallCaption: (obj?: I18nArgumentType) =>
    translation('WebYado-PickupCallCaption', obj),
  /* お迎えを呼びますか？ */
  webYadoPickupCallConfirmationTitle: (obj?: I18nArgumentType) =>
    translation('WebYado-PickupCallConfirmationTitle', obj),
  /* 申し訳ございません。チェックイン日以外お迎えの手配はできませんので、チェックイン日{{date}}にもう一度お呼び出しください。 */
  webYadoPickupCallingBeforeCheckinCaption: (obj?: I18nArgumentType) =>
    translation('WebYado-PickupCallingBeforeCheckinCaption', obj),
  /* 車が集合場所に向かっています。そのまましばらくお待ちください。 */
  webYadoPickupCallingCompetedCaption1: (obj?: I18nArgumentType) =>
    translation('WebYado-PickupCallingCompetedCaption1', obj),
  /* お迎え車両の手配が完了しました。 */
  webYadoPickupCallingCompleted: (obj?: I18nArgumentType) =>
    translation('WebYado-PickupCallingCompleted', obj),
  /* [object Object] */
  webYadoPickupCallingCompletedCaption2: (obj?: I18nArgumentType) =>
    translation('WebYado-PickupCallingCompletedCaption2', obj),
  /* 画面を閉じずにお待ちください */
  webYadoPickupCallingDoNotClose: (obj?: I18nArgumentType) =>
    translation('WebYado-PickupCallingDoNotClose', obj),
  /* お迎えの手配ができませんでした。 */
  webYadoPickupCallingFailed: (obj?: I18nArgumentType) =>
    translation('WebYado-PickupCallingFailed', obj),
  /* 申し訳ございません。担当者が応答しなかったため、お迎えが手配できませんでした。もう一度呼び出しボタンを押してお待ちください。 */
  webYadoPickupCallingFailedCaption: (obj?: I18nArgumentType) =>
    translation('WebYado-PickupCallingFailedCaption', obj),
  /* お迎え車両の手配を依頼しています... */
  webYadoPickupCallingMessage: (obj?: I18nArgumentType) =>
    translation('WebYado-PickupCallingMessage', obj),
  /* 受取日 */
  webYadoPickupDate: (obj?: I18nArgumentType) => translation('WebYado-PickupDate', obj),
  /* {{date}}のお迎え時間 */
  webYadoPickupDateTime: (obj?: I18nArgumentType) => translation('WebYado-PickupDateTime', obj),
  /* ご希望の時間に指定の集合場所までお迎えに上がります。日本語は不要です。 */
  webYadoPickupDescriptionNeedReservation: (obj?: I18nArgumentType) =>
    translation('WebYado-PickupDescriptionNeedReservation', obj),
  /* チェックイン当日に「予約一覧表＞予約詳細」より呼び出しボタンを押すと、指定の集合場所までお迎えに上がります。日本語は不要です。 */
  webYadoPickupDescriptionOnDemand1: (obj?: I18nArgumentType) =>
    translation('WebYado-PickupDescriptionOnDemand1', obj),
  /* 呼び出し機能のご利用にはインターネット環境が必要となります。 */
  webYadoPickupDescriptionOnDemand2: (obj?: I18nArgumentType) =>
    translation('WebYado-PickupDescriptionOnDemand2', obj),
  /* 運行スケジュールに沿って指定の集合場所までお迎えに上がります。日本語は不要です。 */
  webYadoPickupDescriptionTimeTableNoReservation: (obj?: I18nArgumentType) =>
    translation('WebYado-PickupDescriptionTimeTableNoReservation', obj),
  /* お迎え詳細 */
  webYadoPickupDetails: (obj?: I18nArgumentType) => translation('WebYado-PickupDetails', obj),
  /* 到着場所 */
  webYadoPickupDetailsArrival: (obj?: I18nArgumentType) =>
    translation('WebYado-PickupDetailsArrival', obj),
  /* お迎え場所をすべて閉じる */
  webYadoPickupDetailsCloseAllLocations: (obj?: I18nArgumentType) =>
    translation('WebYado-PickupDetailsCloseAllLocations', obj),
  /* WAmazingで宿をご予約いただくと、最寄りの場所まで日本語不要でお迎えに参ります。ぜひご利用ください。 */
  webYadoPickupDetailsNoJapaneseRequired: (obj?: I18nArgumentType) =>
    translation('WebYado-PickupDetailsNoJapaneseRequired', obj),
  /* WAmazingで宿をご予約いただくと、最寄りの場所まで日本語不要でお迎えに参ります。ぜひご利用ください。呼び出し機能のご利用にはインターネット環境が必要となります。 */
  webYadoPickupDetailsNoJapaneseRequiredInternetRequired: (obj?: I18nArgumentType) =>
    translation('WebYado-PickupDetailsNoJapaneseRequiredInternetRequired', obj),
  /* お迎えにかかる所要時間は、交通事情により遅れる場合があります。 */
  webYadoPickupDetailsPickupDelay: (obj?: I18nArgumentType) =>
    translation('WebYado-PickupDetailsPickupDelay', obj),
  /* お迎え場所をすべて見る */
  webYadoPickupDetailsViewAllLocations: (obj?: I18nArgumentType) =>
    translation('WebYado-PickupDetailsViewAllLocations', obj),
  /* 料金 */
  webYadoPickupFee: (obj?: I18nArgumentType) => translation('WebYado-PickupFee', obj),
  /* 日本滞在中に連絡が取れる連絡先（任意入力） */
  webYadoPickupFormTelMailDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-PickupFormTelMailDescription', obj),
  /* 送迎 */
  webYadoPickupInformation: (obj?: I18nArgumentType) =>
    translation('WebYado-PickupInformation', obj),
  /* 集合場所 */
  webYadoPickupMeetingPlace: (obj?: I18nArgumentType) =>
    translation('WebYado-PickupMeetingPlace', obj),
  /* 選択されていません */
  webYadoPickupNotSelected: (obj?: I18nArgumentType) =>
    translation('WebYado-PickupNotSelected', obj),
  /* WAmazingで宿をご予約いただくと、最寄りの場所までお迎えに参ります。ぜひご利用ください。 */
  webYadoPickupOfferMessage: (obj?: I18nArgumentType) =>
    translation('WebYado-PickupOfferMessage', obj),
  /* お迎えサービスの申し込み */
  webYadoPickupRequest: (obj?: I18nArgumentType) => translation('WebYado-PickupRequest', obj),
  /* お迎えサービスの申し込みが完了しました！ */
  webYadoPickupRequestCompleted: (obj?: I18nArgumentType) =>
    translation('WebYado-PickupRequestCompleted', obj),
  /* {{date}}の17:30（日本時間）までにお申し込みください。 */
  webYadoPickupRequestDeadline: (obj?: I18nArgumentType) =>
    translation('WebYado-PickupRequestDeadline', obj),
  /* 後で申し込む */
  webYadoPickupRequestLater: (obj?: I18nArgumentType) =>
    translation('WebYado-PickupRequestLater', obj),
  /* 所要時間 */
  webYadoPickupRidingTime: (obj?: I18nArgumentType) => translation('WebYado-PickupRidingTime', obj),
  /* 運行スケジュール */
  webYadoPickupSchedule: (obj?: I18nArgumentType) => translation('WebYado-PickupSchedule', obj),
  /* お迎えサービス */
  webYadoPickupService: (obj?: I18nArgumentType) => translation('WebYado-PickupService', obj),
  /* この宿を当サイト経由でご予約いただくと、指定の集合場所までお迎えに上がります。日本語は不要です。 */
  webYadoPickupTagline: (obj?: I18nArgumentType) => translation('WebYado-PickupTagline', obj),
  /* 呼び出してからお迎えにかかる時間 */
  webYadoPickupWatingTime: (obj?: I18nArgumentType) => translation('WebYado-PickupWatingTime', obj),
  /* プラン紹介 */
  webYadoPlanDetails: (obj?: I18nArgumentType) => translation('WebYado-PlanDetails', obj),
  /* プラン */
  webYadoPlanFilter: (obj?: I18nArgumentType) => translation('WebYado-PlanFilter', obj),
  /* プランが見つかりませんでした。条件を変えて再検索してください。 */
  webYadoPlanNotFound: (obj?: I18nArgumentType) => translation('WebYado-PlanNotFound', obj),
  /* 大阪周辺の人気エリア */
  webYadoPopularAreasAroundOsaka: (obj?: I18nArgumentType) =>
    translation('WebYado-PopularAreasAroundOsaka', obj),
  /* 東京の人気エリア */
  webYadoPopularAreasInTokyo: (obj?: I18nArgumentType) =>
    translation('WebYado-PopularAreasInTokyo', obj),
  /* 日本各地の有名温泉 */
  webYadoPopularHotSprings: (obj?: I18nArgumentType) =>
    translation('WebYado-PopularHotSprings', obj),
  /* 人気のスポット */
  webYadoPopularSpots: (obj?: I18nArgumentType) => translation('WebYado-PopularSpots', obj),
  /* 日本各地の名所 */
  webYadoPopularTouristDestinations: (obj?: I18nArgumentType) =>
    translation('WebYado-PopularTouristDestinations', obj),
  /* 人気 */
  webYadoPopularity: (obj?: I18nArgumentType) => translation('WebYado-Popularity', obj),
  /* 愛知県 */
  webYadoPrefectureAichi: (obj?: I18nArgumentType) => translation('WebYado-PrefectureAichi', obj),
  /* 秋田県 */
  webYadoPrefectureAkita: (obj?: I18nArgumentType) => translation('WebYado-PrefectureAkita', obj),
  /* 青森県 */
  webYadoPrefectureAomori: (obj?: I18nArgumentType) => translation('WebYado-PrefectureAomori', obj),
  /* {{prefecture}} | WAmazingでは10,000件を超えるホテルを掲載しています。日本最大規模の掲載数なので、理想のホテルが必ず見つかります。 */
  webYadoPrefectureDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-PrefectureDescription', obj),
  /* 写真映えするスポットとして人気急上昇中の「オアシス21」や、江戸の将軍・徳川家康が天下統一の最後の布石として築いた「名古屋城」がある愛知県。世界に誇る自動車メーカーTOYOTAの本拠地でもあります。また、独特の食文化が根付いており、八丁味噌を使った「味噌カツ」や「味噌煮込みうどん」、鰻を3種の食べ方で楽しむ「ひつまぶし」、トーストに小倉あんを乗せた「小倉トースト」など、名物グルメは多数。 */
  webYadoPrefectureDescriptionAichi: (obj?: I18nArgumentType) =>
    translation('WebYado-PrefectureDescriptionAichi', obj),
  /* 「美人が多い」と言われる秋田県には、日本一の深さと透明度を誇る「田沢湖」やタイムスリップ気分が味わえる「角館武士屋敷」など、知る人ぞ知る人気観光地が点在。名湯も多く、特に「乳頭温泉」はオススメ。男女混浴なので抵抗があるかもしれませんが挑戦の価値アリです。食では日本有数の米どころとして知られ、潰したゴハンを棒状にして焼いて比内地鶏の出汁で煮込んだ郷土料理「きりたんぽ鍋」は必食。ほか、のどごし抜群の「稲庭うどん」も名物。 */
  webYadoPrefectureDescriptionAkita: (obj?: I18nArgumentType) =>
    translation('WebYado-PrefectureDescriptionAkita', obj),
  /* 青森県は本州の最北部に位置し、雄大な自然や紅葉、スノーアクティビティなども楽しめる自然豊かな環境です。世界遺産の「白神山地」をはじめ、雪が地面から舞い上がる「地吹雪」を体験するツアーや、車内にレトロなストーブが設置された冬限定の観光列車「ストーブ列車」など、日本を代表する豪雪地帯ならではの体験ができます。りんごで知られる津軽やマグロで有名な大間、ねぶた祭りが開催される青森市など観光地も多く、一度は訪れたい県です。 */
  webYadoPrefectureDescriptionAomori: (obj?: I18nArgumentType) =>
    translation('WebYado-PrefectureDescriptionAomori', obj),
  /* 海外からの玄関口「成田空港」があり、東京へのアクセスも至便な海辺リゾート。南国ムードが漂う「館山」、江戸の町並みが残る「佐原」、潮干狩りシーズンになると多くの家族連れでにぎわう「九十九里」など、その土地その土地で多彩な顔を見せます。「東京ディズニーリゾート」「鴨川シーワールド」「マザー牧場」などの人気テーマパークで1日中遊ぶ尽くすのもオススメ！いすみ鉄道や銚子電鉄などのローカル線も観光名所のひとつ。 */
  webYadoPrefectureDescriptionChiba: (obj?: I18nArgumentType) =>
    translation('WebYado-PrefectureDescriptionChiba', obj),
  /* 絶景のサイクリングロードとして有名な「しまなみ街道」や、昔ながらの街並みが残り温泉情緒溢れる「道後温泉」など、フォトジェニックな観光地が点在。手触り抜群のタオル「今治タオル」はお土産にも最適です。食では、鯛を使った郷土料理「鯛めし」や地魚のすり身を使った「じゃこ天」などが名物。地元のお茶を使った極上スイーツ「霧の森大福」は、ネット通販も行っているけれど毎回抽選で入手困難なので、現地に行ったら是非訪れてみて。 */
  webYadoPrefectureDescriptionEhime: (obj?: I18nArgumentType) =>
    translation('WebYado-PrefectureDescriptionEhime', obj),
  /* ゴツゴツとした岩が1kmにも渡って続く断崖絶壁の名所「東尋坊」は、荒々しい日本海の絶景を見ることができます。また、国内随一の恐竜化石の発掘地でもあり、県内には世界最大級の恐竜博物館も。食では冬の味覚「越前がに」が名物で、解禁期間（11月6日～3月下旬）が限られるため、毎年現地まで「カニ旅」をする日本人も多数訪れます。 */
  webYadoPrefectureDescriptionFukui: (obj?: I18nArgumentType) =>
    translation('WebYado-PrefectureDescriptionFukui', obj),
  /* とんこつスープの「博多ラーメン」、鶏肉の鍋「水炊き」、ガツンとにんにくが効いてクセになる「モツ鍋」、などご当地の名物グルメが豊富な福岡県。初心者には一見とっつきにくく見える中州の屋台街ですが、体験の価値アリ！味はもちろん、その独特の雰囲気はクセになること間違いナシです。ラーメン、天ぷら、ショットバー、フレンチ、アジア料理など多彩なジャンルのメニューが味わえるほか、人との交流も魅力のひとつ。 */
  webYadoPrefectureDescriptionFukuoka: (obj?: I18nArgumentType) =>
    translation('WebYado-PrefectureDescriptionFukuoka', obj),
  /* ミルキーブルーのお湯が幻想的な「土湯温泉」や茅葺屋根が立ち並ぶ「大内宿」、日によって全く違う表情を見せる「五色沼」など、ツウ好みのスポットが多数あります。また、ゲレンデの雪質には定評があり、ふかふかのマイクロファインスノーが体感できると密かな人気。食では、日本三大ラーメンの「喜多方ラーメン」が有名で、朝ごはんにラーメンを食べる「朝ラー」という文化があり、朝7時頃から開いている店も多いので、前泊して楽しんでみてはいかが？ */
  webYadoPrefectureDescriptionFukushima: (obj?: I18nArgumentType) =>
    translation('WebYado-PrefectureDescriptionFukushima', obj),
  /* 大ヒットアニメ映画「君の名は。」の舞台といわれている飛騨高山がある岐阜県。同県内の世界遺産「白川郷」は、合掌造りの屋根に雪が降り積もる冬絶景が特にすばらしく、息を呑む美しさ。岐阜市の長良川で毎年5月から10月まで行われている昔ながらの漁法「鵜飼い」は観光コンテンツとしても非常に人気があります。グルメでは、ブランド牛「飛騨牛」や飛騨高山の「高山ラーメン」が有名。温泉では、日本三名泉に選ばれた名湯「下呂温泉」も同県内。 */
  webYadoPrefectureDescriptionGifu: (obj?: I18nArgumentType) =>
    translation('WebYado-PrefectureDescriptionGifu', obj),
  /* 草津・伊香保・四万・水上など、県内には日本人も好む名湯が勢ぞろい！湯畑を中心に土産店や飲食店が広がる草津は色浴衣で温泉街散策も可能。伊香保は石段の温泉街がなんともフォトジェニック。東京からも近く日帰りで利用できる温泉も多いので、東京宿泊でもサクッと半日使って温泉旅行も可能ですが、温泉街が発達していて湯めぐり散歩ができる温泉地も多いので、せっかくなら泊まってゆっくり旅を楽しみたいところ。 */
  webYadoPrefectureDescriptionGunma: (obj?: I18nArgumentType) =>
    translation('WebYado-PrefectureDescriptionGunma', obj),
  /* 世界で初めて原爆を投下され大きな被害を受けたことでも知られる広島。その惨禍を今に伝える「原爆ドーム」や爆心地の跡地である「広島平和記念公園」は是非訪れたい名所です。海に浮かぶ赤い鳥居が目を惹く世界遺産「厳島神社」がある宮島も同県内。ご当地グルメとして外せないのが、地元民のソウルフード「お好み焼き」。大阪のものとは違い、薄く焼いた生地にキャベツ、豚肉、そばや卵を重ねて焼き上げるのが特徴。 */
  webYadoPrefectureDescriptionHiroshima: (obj?: I18nArgumentType) =>
    translation('WebYado-PrefectureDescriptionHiroshima', obj),
  /* 日本を代表するリゾート地の北海道。冬には、パウダースノーを求めて世界中から旅行者が訪れ、長期滞在する観光客も多い。夏もラベンダーなどの美しい景色が楽しめたり、グルメは一年を通して最上の感動を届けてくれます。カニやいくらなどの海鮮はもちろん、チーズなどの牧場グルメ、ジンギスカンなどもとても有名です。札幌だけでなく、函館や旭川、小樽など魅力的な観光地が揃っているのも魅力です。 */
  webYadoPrefectureDescriptionHokkaido: (obj?: I18nArgumentType) =>
    translation('WebYado-PrefectureDescriptionHokkaido', obj),
  /* 大阪と京都に隣接する兵庫県は、国際的にも有名な「神戸ビーフ」の産地！世界文化遺産に登録されている「姫路城」は、白く美しい外観から「白鷺城」と呼ばれ、多くの人に愛されています。姫路城からほど近い場所にある「書写山円教寺」は映画「ラストサムライ」のロケ地としても有名。県内には有馬温泉や城崎温泉などの街歩きできる温泉地もあるほか、日本のマチュピチュと呼ばれる「竹田城跡」や港町情緒溢れる「神戸」などフォトジェニックな場所も多数。 */
  webYadoPrefectureDescriptionHyogo: (obj?: I18nArgumentType) =>
    translation('WebYado-PrefectureDescriptionHyogo', obj),
  /* 東京から近い観光地として人気上昇中の茨城県。春にはネモフィラで真っ青に染まるＳＮＳ映えスポット「国営ひたち海浜公園」や高さ120m、幅73mの大きさを誇る「袋田の滝」、ブロンズ立像としては世界最大の「牛久大仏」など、フォトジェニックなスポットも多数。太平洋に面した県だけに県内には多くの漁港があり、鮮度抜群＆お得感満載の海鮮グルメが楽しめます。冬に訪れるなら旬の「あんこう鍋」を是非試してみて。 */
  webYadoPrefectureDescriptionIbaraki: (obj?: I18nArgumentType) =>
    translation('WebYado-PrefectureDescriptionIbaraki', obj),
  /* 日本名園のひとつである「兼六園」が県を代表する観光スポット。周囲には美術館としては国内屈指の入館者数を誇る「金沢21世紀美術館」もあります。中心部の金沢市内には、金沢城や武家屋敷、茶屋街など、古きよき時代の面影が残っており、写真映えもばっちり！食では、日本海の新鮮な魚介類は勿論のこと、高級な地物野菜「加賀野菜」も有名です。お土産としては「加賀友禅」や「輪島塗」など、職人による伝統的なものもおすすめ。 */
  webYadoPrefectureDescriptionIshikawa: (obj?: I18nArgumentType) =>
    translation('WebYado-PrefectureDescriptionIshikawa', obj),
  /* 日本国内では、北海道に次いで2番目に広い岩手県。世界遺産「平泉・中尊寺」や国内屈指の大規模農場「小岩井農場」もあります。ほか、絶景スポットとして人気がある「猊鼻渓」は、秋の紅葉シーズンと冬のこたつ舟が特におすすめです。降り積もった雪絶景を、温かいこたつに入りながらのんびり眺めるのは最高の贅沢！写真映えもバッチリなので、思わずSNS投稿したくなること間違いなしです。 */
  webYadoPrefectureDescriptionIwate: (obj?: I18nArgumentType) =>
    translation('WebYado-PrefectureDescriptionIwate', obj),
  /* 「讃岐うどん」はイリコの強い出汁にコシの強い麺で日本人にもファンが多い、全国的に有名なご当地グルメ。3年に1度開催される「瀬戸内国際芸術祭」の拠点としても人気。会場のひとつ「直島」は、アーティスト草間弥生さんのカボチャのオブジェで一躍有名に。奥社までなんと1368段もの石段がある「金刀比羅宮」は、その周辺の参道グルメも魅力的。ちょっと変わった名物「かまたまソフト」もぜひ試してみて。 */
  webYadoPrefectureDescriptionKagawa: (obj?: I18nArgumentType) =>
    translation('WebYado-PrefectureDescriptionKagawa', obj),
  /* 鹿児島のシンボル「桜島」は今でも定期的に小規模な噴火を繰り返す活火山。このほかにも「霧島山」や「口永良部島」などの火山が。これらがあるからこそ、県内にはいくつもの温泉が誕生しています。浜辺に横たわって温泉で温められた砂をかぶせる「指宿の砂むし温泉」や、出水市にある珍しい足元湧出温泉（空気に触れず足の下から温泉が湧き出してくるという贅沢な温泉）など、温泉マニアも喜ぶ名湯も多数。世界遺産「屋久島」も同県内。 */
  webYadoPrefectureDescriptionKagoshima: (obj?: I18nArgumentType) =>
    translation('WebYado-PrefectureDescriptionKagoshima', obj),
  /* 日本人にも人気のブランド温泉地「箱根」を有する神奈川県。高級温泉旅館も数多くあるので、ゆっくり宿での滞在を楽しみたい人にもおすすめ。そのほか、港町風情あふれる人気のデートスポット「横浜」や昔ながらの街並みとおしゃれな飲食店が混在し、町歩きが楽しい「鎌倉」まで、多彩な顔を持ち、シーンに応じて幅広い楽しみ方ができるのが魅力。東京からも近いので、都内泊で日帰り観光も可能です。 */
  webYadoPrefectureDescriptionKanagawa: (obj?: I18nArgumentType) =>
    translation('WebYado-PrefectureDescriptionKanagawa', obj),
  /* 弓形に伸びる海岸「桂浜」や四国最後の清流と呼ばれ「四万十川」など、自然の景勝地が多い高知県。地元の美味しいものが多いと、食に対する評価が高いのが特徴です。中でもおすすめなのは飲食店約40店、土産店などの物販店20店が集まる「ひろめ市場」。新鮮な魚や地元のグルメを、その場で買って食べられる人気の施設で、お客同士の距離が近く、飲みながらコミュニケーションも楽しめるほか、お土産も買えて便利です。 */
  webYadoPrefectureDescriptionKochi: (obj?: I18nArgumentType) =>
    translation('WebYado-PrefectureDescriptionKochi', obj),
  /* 世界的に人気のキャラクター「くまモン」の生まれ故郷。熊本市内の「くまモンスクエア」に行けば、くまモンに会えることも！県内には複数温泉地が点在していますが、中でも有名なのが山間にある「黒川温泉」。ぜひ1枚1300円で3湯に入れる「入湯手形」を使って湯めぐりを楽しみましょう。県内有数の観光地で、世界最大級のカルデラを誇る「阿蘇」では、新緑絶景の中を駆け抜ける乗馬体験が人気。食では、熊本ラーメンや馬肉料理が名物です。 */
  webYadoPrefectureDescriptionKumamoto: (obj?: I18nArgumentType) =>
    translation('WebYado-PrefectureDescriptionKumamoto', obj),
  /* 赤い鳥居で有名な「伏見稲荷」や世界遺産「清水寺」、竹林の小路の「嵐山」など、フォトジェニックなスポットも多数！寺社仏閣が多い京都の中でも、願い事が叶う寺として有名な「鈴虫寺（正式名は華厳寺）」は僧侶による面白い説法も人気です。そのほか、祇園で体験できる舞妓・花魁のコスプレも好評。初夏～夏（5月～9月頃）限定の「川床（料理店が川の上や屋外で川のよく見える場所に座敷を作り料理を提供する文化）」も体験したいもののひとつ。 */
  webYadoPrefectureDescriptionKyoto: (obj?: I18nArgumentType) =>
    translation('WebYado-PrefectureDescriptionKyoto', obj),
  /* 伊勢志摩サミットが開かれた三重県はブランド和牛「松阪牛」の産地としても有名。日本屈指のパワースポット「伊勢神宮」もあり、周辺の「おかげ横丁」で食べ歩きを楽しめることでも人気です。揚げたての和牛コロッケやふわふわの伊勢かまぼこなどをつまみながら散策を楽しみましょう。ある程度時間が取れるなら、店内でゆっくり、名物の「伊勢うどん」や「赤福」などを味わってみては。県内にはイルミネーションの名所「なばなの里」も。 */
  webYadoPrefectureDescriptionMie: (obj?: I18nArgumentType) =>
    translation('WebYado-PrefectureDescriptionMie', obj),
  /* 観光・温泉・買い物・ご当地グルメがコンパクトに楽しめるのが魅力。日本の若者の間で密かなブームを呼んでいる伝統工芸品「こけし」はお土産にも最適で、最近では昔ながらのデザインに加えて、スイーツこけしなどの変り種も登場。独特の食感と絶妙な塩気がクセになる「牛たん」や優しい甘さのスイーツ「ずんだ」などのご当地グルメも豊富です。国内外からファンが訪れる「蔵王キツネ村」では仔ギツネの抱っこ体験もできます！ */
  webYadoPrefectureDescriptionMiyagi: (obj?: I18nArgumentType) =>
    translation('WebYado-PrefectureDescriptionMiyagi', obj),
  /* 宮崎県を代表するパワースポットが「高千穂峡」です。このエリアを走る「高千穂あまてらす鉄道」は、スーパーカートと言う軽トラックを改造したオープンエアのトロッコ車両で、定員が少なく事前予約もできないため争奪戦だとか。国内で冬でも晴れる日が多く日照時間が長いため、暖かい気候を利用して野球やサッカーなどプロスポーツチームのキャンプ地として利用されたり、リゾートゴルフを楽しむ人が多く訪れます。 */
  webYadoPrefectureDescriptionMiyazaki: (obj?: I18nArgumentType) =>
    translation('WebYado-PrefectureDescriptionMiyazaki', obj),
  /* 日本国内第4位の面積を誇る長野県。世界的に有名な“スノーモンキー”がいる「地獄谷野猿公苑」や、別荘リゾートでレベルの高いグルメと買い物が楽しめる「軽井沢」、自然を守るためマイカー規制をしているので空気もキレイな絶景名所「上高地」、江戸の宿場町風情がそのまま残る「木曽」など、地域によって全く違う顔を持っています。ご当地グルメも地域ごとに多数ありますが、総じて水がきれいなので、県内全域で「蕎麦」は有名。 */
  webYadoPrefectureDescriptionNagano: (obj?: I18nArgumentType) =>
    translation('WebYado-PrefectureDescriptionNagano', obj),
  /* 昔、日本が鎖国を行っていた時代にも西洋・中国と唯一の交易を行っていた長崎は、異国情緒たっぷりの町並みが魅力。50もの教会がある「五島列島」、幕末期に数々の貿易商たちが居留した邸宅を集めた「グラバー園」などの観光名所も多数。ヨーロッパを再現したテーマパーク「ハウステンボス」も人気。ご当地グルメも多く、代表的なものとしては「長崎ちゃんぽん」「長崎皿うどん」「トルコライス」「佐世保バーガー」「カステラ」などが挙げられます。 */
  webYadoPrefectureDescriptionNagasaki: (obj?: I18nArgumentType) =>
    translation('WebYado-PrefectureDescriptionNagasaki', obj),
  /* 「法隆寺」、８つの資産全体が世界遺産登録をされている奈良市（「東大寺」「興福寺」「薬師寺」など町全体として登録）、「紀伊山地の霊場と参詣道」の３つの世界遺産を持つ奈良県。「東大寺」にある「奈良の大仏」は像高（座高）15メートルもあり、その迫力に圧倒されます。歴史ある寺社仏閣めぐりのほか、1000頭以上の鹿がいる「奈良公園」も観光名所として人気があります。隣り合う京都・大阪と合わせて観光を楽しむ人も。 */
  webYadoPrefectureDescriptionNara: (obj?: I18nArgumentType) =>
    translation('WebYado-PrefectureDescriptionNara', obj),
  /* 日本有数の米の名産地で「魚沼産コシヒカリ」などブランド米が多数。米どころだけに日本酒造りも盛んなので、酒造めぐりを楽しむのもおすすめ。日本海側は名漁場となっており、鮭や高級魚「ノドグロ」などもよく獲れるので、新鮮な魚介類が食べたいという人は是非訪れて。豪雪地帯でもあるので、冬場はスノーレジャーも楽しめます。3年に1度開催される「越後妻有大地の芸術祭」は自然とアートの共存が楽しめる人気イベント。 */
  webYadoPrefectureDescriptionNiigata: (obj?: I18nArgumentType) =>
    translation('WebYado-PrefectureDescriptionNiigata', obj),
  /* 源泉数・湧出量ともに日本一で「おんせん県」を標榜する大分県。地獄めぐりで有名な「別府」では、浸かるだけでなく温泉の蒸気を調理に用いる「地獄蒸し」体験もできます。高級温泉旅館も多いブランド温泉地「湯布院」は飲食店や土産店が立ち並ぶ湯の坪街道を散策する楽しみも。別府や湯布院などの有名温泉地以外に、世界屈指の炭酸泉で浸かるとラムネのように泡がプツプツと沸いてくる「長湯温泉」など、県内には個性豊かな小さな温泉も多数。 */
  webYadoPrefectureDescriptionOita: (obj?: I18nArgumentType) =>
    translation('WebYado-PrefectureDescriptionOita', obj),
  /* 昔ながらの街並みが残る「倉敷」やジーンズの聖地と呼ばれる「児島」など、エリアごとに多様な魅力がある岡山県。県庁所在地の岡山市内には黒塗りの外観から「烏城」の異名を取る「岡山城」があります。同市は「フルーツパフェの街」としても知られ、個性的なフルーツパフェが市内飲食店で食べられます。カツにデミグラスソースをかけた「デミカツ丼」や味噌ベースの「ひるぜん焼そば」、新鮮な牡蠣を使ったお好み焼き「日生カキオコ」などご当地グルメも豊富。 */
  webYadoPrefectureDescriptionOkayama: (obj?: I18nArgumentType) =>
    translation('WebYado-PrefectureDescriptionOkayama', obj),
  /* 日本屈指のリゾート。沖縄本島・宮古島・石垣島など多くの島々から構成。本島には、ジンベエザメが泳ぐ巨大水槽で有名な「美ら海水族館」や、世界文化遺産「首里城」など観光名所が多数。宿泊施設も多彩なので、本島北部の高級リゾートに泊まってゆったり滞在、那覇周辺のビジネスホテルに泊まって宿泊費は抑え、観光をメインに楽しむ…など、ニーズに応じたすごし方ができます。ダイビングを始め色々なマリンレジャーが楽しめるのも魅力。 */
  webYadoPrefectureDescriptionOkinawa: (obj?: I18nArgumentType) =>
    translation('WebYado-PrefectureDescriptionOkinawa', obj),
  /* スリル満点のアトラクションや映画の世界を再現したテーマパークが人気の「ユニバーサル・スタジオ・ジャパン」がある大阪府。たこ焼きやお好み焼き、串揚げ、かすうどんなど、安くてボリュームがあって美味しいグルメが揃い「食い倒れの町」としても有名です。そのほか、お笑いの聖地としても知られ、国内でも人気の吉本新喜劇が上映される「なんばグランド花月」も。ショッピングを楽しむなら、心斎橋や難波エリアがおすすめ。 */
  webYadoPrefectureDescriptionOsaka: (obj?: I18nArgumentType) =>
    translation('WebYado-PrefectureDescriptionOsaka', obj),
  /* 福岡県からバスや電車でのアクセスも楽なので、福岡県内泊で日帰り観光も可能。県内には、美肌の温泉として名高い「武雄」「嬉野」があります。特に嬉野温泉はまるで乳液のようなトロトロの触感に驚くはず。また、嬉野では忍者衣装を着て忍者体験ができる「元祖忍者村肥前夢街道」もあり家族旅行にも◎。お土産には是非有名な焼き物を。華やかな絵付けの「有田焼」「伊万里焼」、シンプルで使い勝手が良い「唐津焼」があります。 */
  webYadoPrefectureDescriptionSaga: (obj?: I18nArgumentType) =>
    translation('WebYado-PrefectureDescriptionSaga', obj),
  /* 「春日部市」や「鷲宮神社」など、アニメで有名になったスポットが点在しています。江戸の街並みが広がり昔ながらの菓子屋が軒を連ねる「川越」や海外の鉄道ファンからも人気の「鉄道博物館」、パワースポット「三峯神社」なども同県内。春日部市にある世界最大級の地下放水路「首都圏外郭放水路」は、地下に広がる神殿のようでSNS映え抜群で近年人気上昇中です。東京に隣接している県なので行きやすいのも魅力。 */
  webYadoPrefectureDescriptionSaitama: (obj?: I18nArgumentType) =>
    translation('WebYado-PrefectureDescriptionSaitama', obj),
  /* 実は京都の隣にあり、京都観光との組み合わせも可能な滋賀県。見所は日本最大の湖「琵琶湖」と、有名なご当地キャラ”ひこにゃん”に会える「彦根城」。琵琶湖では夏に湖水浴が楽しめ、淡水なので身体がベタベタしないと好評です。また、ビワイチ（琵琶湖1周サイクリング）を推奨しており、湖の周りにはレンタル自転車も多くサイクリングも楽しめます。近江八幡は、バウムクーヘンの人気店「クラブハリエ」の本店があることでも有名。 */
  webYadoPrefectureDescriptionShiga: (obj?: I18nArgumentType) =>
    translation('WebYado-PrefectureDescriptionShiga', obj),
  /* 縁結びの聖地としても有名な「出雲大社」や世界遺産「石見銀山」などがあります。中心部・松江市内には「松江城」があり、その周囲の堀川をゆったりと小船で巡る観光遊覧船がおすすめ。松江から近い温泉「玉造温泉」は美肌の湯としても有名で温泉街のはずれには、気になる箇所の美肌に効果があると言われる「おしろい地蔵」も。食では小さな朱塗りの器（割子）を重ねて提供される「出雲そば」や特産のしじみを使った「しじみバーガー」などが名物。 */
  webYadoPrefectureDescriptionShimane: (obj?: I18nArgumentType) =>
    translation('WebYado-PrefectureDescriptionShimane', obj),
  /* 日本一の山「富士山」を擁する静岡県は、東西に広く、東側と西側でまるで文化が異なります。東側は人気温泉地が多く存在する観光地「伊豆」エリアがあり、西側は自動車・オートバイ・楽器の世界のトップメーカーが集まる工業地帯となっています。食も、Ｂ級グルメの祭典Ｂ－１グランプリに輝いた経歴を持つ「富士宮やきそば」をはじめ、真っ黒いスープが特徴的な「静岡おでん」、浜松エリアの「鰻」など多種多様です。 */
  webYadoPrefectureDescriptionShizuoka: (obj?: I18nArgumentType) =>
    translation('WebYado-PrefectureDescriptionShizuoka', obj),
  /* 世界遺産「日光東照宮」がある栃木県。県内にはライン下りも楽しめるレジャー温泉地「鬼怒川」や高原リゾートとしても有名な「那須湯本」、映画のロケ地にもなったシブイ「北温泉」や徒歩でしか行けない秘湯「加仁湯」など、多種多様な温泉が存在します。「宇都宮餃子」や「佐野ラーメン」から、生産量日本一を誇る「いちご」まで、ご当地グルメも多彩。東京から日光や鬼怒川へは特急が出ているほか、宇都宮や那須へは新幹線が便利。 */
  webYadoPrefectureDescriptionTochigi: (obj?: I18nArgumentType) =>
    translation('WebYado-PrefectureDescriptionTochigi', obj),
  /* 日本三大秘境のひとつでもある「祖谷渓」は、その独特な魅力にはまる人が続出し、外国人観光客も急増中。日本の原風景とも言える景色を見にわざわざ訪れる人が多いとか。日本三奇橋の一つ「かずら橋」は”怖いけど、びっくりするくらいの絶景”といわれ、特に紅葉の時期は多くの人でにぎわう。絶景の渓谷「大歩危」も人気。食では、濃厚なスープに生卵をトッピングした「徳島ラーメン」や不ぞろいな麺とあっさり出汁が特徴の「鳴ちゅるうどん」が有名。 */
  webYadoPrefectureDescriptionTokushima: (obj?: I18nArgumentType) =>
    translation('WebYado-PrefectureDescriptionTokushima', obj),
  /* いわずと知れた日本の首都で、外国人観光客が日本一多いエリア。昔ながらの下町情緒が残る「浅草」、早朝から活気溢れる「築地市場」、“かわいい”ファッションの最先端で国内の若者も多く訪れる「原宿」、高級ブランドショッピングに欠かせない「銀座」など、場所ごとに全く違う表情を見せるのが魅力です。東京タワーや東京スカイツリーといった観光名所も多数あるほか、国内外の人気グルメ店も集まっておりさまざまなニーズに応えてくれます。 */
  webYadoPrefectureDescriptionTokyo: (obj?: I18nArgumentType) =>
    translation('WebYado-PrefectureDescriptionTokyo', obj),
  /* ラクダに乗って歩けることでも有名な、日本最大級の砂丘「鳥取砂丘」がある県。「名探偵コナン」の青山剛昌氏や「ゲゲゲの鬼太郎」の水木しげる氏の出身県で、記念に建てられた博物館も。米子鬼太郎空港からほど近い境港の商店街「水木しげるロード」には、約800mの通りに174体の妖怪ブロンズ像が立ち並び、思わず写真を撮ってしまうこと間違いなし。県内の名湯・三朝温泉は日帰り湯も充実していて湯めぐりも楽しめます。 */
  webYadoPrefectureDescriptionTottori: (obj?: I18nArgumentType) =>
    translation('WebYado-PrefectureDescriptionTottori', obj),
  /* 県内の名所といえば、標高3000m級の山々が連なる山岳観光ルート「立山黒部アルペンルート」。そのルート上にある、日本最大のダム「黒部ダム」の観光放水は圧巻の迫力！食では、県内でしか獲れない幻のエビ「白エビ」や脂ののった「ブリ」、お酒のつまみにピッタリの「ホタルイカ」など一度食べたら忘れられない魚介グルメも多数。世界で一番美しいといわれるスターバックスコーヒーがあることでも有名。ギネスに登録された「世界一長いベンチ」も。 */
  webYadoPrefectureDescriptionToyama: (obj?: I18nArgumentType) =>
    translation('WebYado-PrefectureDescriptionToyama', obj),
  /* パンダがいるテーマパーク「アドベンチャーワールド」と、日本三大名瀑に選ばれている「那智の滝」が有名。「三段壁」「円月島」「千畳敷」など自然が作り出す絶景スポットはＳＮＳ映え抜え群です！是非体感してほしいのは、川底から源泉が湧き出る珍しい温泉「川湯温泉」。水着でも大丈夫なので温泉は気になるけど抵抗がある…という人にもおすすめ。豚骨醤油ベースの「和歌山ラーメン」や高菜でご飯を包んだ「めはりずし」などのご当地グルメも。 */
  webYadoPrefectureDescriptionWakayama: (obj?: I18nArgumentType) =>
    translation('WebYado-PrefectureDescriptionWakayama', obj),
  /* 冬だけの幻想的な風景「スノーモンスター」を見られることで有名なゲレンデ「蔵王温泉スキー場」を有する山形県。樹氷が立ち並ぶ光景は一度見たら忘れられません。そのほか、県内には「千と千尋の神隠し」の舞台と噂されるフォトジェニック温泉「銀山温泉」も。冬に雪が降り積もった温泉街は、さながら映画のワンシーンのよう。さくらんぼの産地としても知られ、毎年6～7月頃にはさくらんぼ狩りを楽しむことができます。 */
  webYadoPrefectureDescriptionYamagata: (obj?: I18nArgumentType) =>
    translation('WebYado-PrefectureDescriptionYamagata', obj),
  /* 高級魚「ふぐ」の産地としても有名な下関がある県。CNNでかつて日本の最も美しい場所31選として挙げられた「元乃隅稲成神社」や日本最大級の鍾乳洞「秋芳洞」、映画やCMのロケ地としても有名な「角島大橋」など、写真映えバッチリの絶景観光地が多数。いまや高級日本酒の代名詞とも言うべき「獺祭」の蔵元「旭酒造」もあり、事前予約をすれば本社蔵の見学もでき、希望すれば試飲（有料）も可能なので是非お試しを。 */
  webYadoPrefectureDescriptionYamaguchi: (obj?: I18nArgumentType) =>
    translation('WebYado-PrefectureDescriptionYamaguchi', obj),
  /* 東京から片道2時間以内で行けるアクセスのよさが魅力。日本を代表する山「富士山」がある県だけに、富士山を背景とする絶景スポットは多数。中でも、富士山の麓に雄大な湖が広がる「富士五湖」エリアからの景色は感動まちがいなし！絶叫マシンが有名な遊園地「富士急ハイランド」では、乗り物に乗って絶叫しながらも富士山が絶景が楽しめます。ぶどうの産地で、有名ワイナリーも多数あるので、ワイナリー巡りをするのもおすすめ。 */
  webYadoPrefectureDescriptionYamanashi: (obj?: I18nArgumentType) =>
    translation('WebYado-PrefectureDescriptionYamanashi', obj),
  /* 愛媛県 */
  webYadoPrefectureEhime: (obj?: I18nArgumentType) => translation('WebYado-PrefectureEhime', obj),
  /* 福井県 */
  webYadoPrefectureFukui: (obj?: I18nArgumentType) => translation('WebYado-PrefectureFukui', obj),
  /* 福島県 */
  webYadoPrefectureFukushima: (obj?: I18nArgumentType) =>
    translation('WebYado-PrefectureFukushima', obj),
  /* 岐阜県 */
  webYadoPrefectureGifu: (obj?: I18nArgumentType) => translation('WebYado-PrefectureGifu', obj),
  /* 群馬県 */
  webYadoPrefectureGunma: (obj?: I18nArgumentType) => translation('WebYado-PrefectureGunma', obj),
  /* 広島県 */
  webYadoPrefectureHiroshima: (obj?: I18nArgumentType) =>
    translation('WebYado-PrefectureHiroshima', obj),
  /* 北海道 */
  webYadoPrefectureHokkaido: (obj?: I18nArgumentType) =>
    translation('WebYado-PrefectureHokkaido', obj),
  /* 兵庫県 */
  webYadoPrefectureHyogo: (obj?: I18nArgumentType) => translation('WebYado-PrefectureHyogo', obj),
  /* 石川県 */
  webYadoPrefectureIshikawa: (obj?: I18nArgumentType) =>
    translation('WebYado-PrefectureIshikawa', obj),
  /* 岩手県 */
  webYadoPrefectureIwate: (obj?: I18nArgumentType) => translation('WebYado-PrefectureIwate', obj),
  /* 香川県 */
  webYadoPrefectureKagawa: (obj?: I18nArgumentType) => translation('WebYado-PrefectureKagawa', obj),
  /* 鹿児島県 */
  webYadoPrefectureKagoshima: (obj?: I18nArgumentType) =>
    translation('WebYado-PrefectureKagoshima', obj),
  /* 神奈川県 */
  webYadoPrefectureKanagawa: (obj?: I18nArgumentType) =>
    translation('WebYado-PrefectureKanagawa', obj),
  /* {{prefecture}},日本,旅行,宿,宿泊,旅館,ホテル,ビジネスホテル,リゾート */
  webYadoPrefectureKeywords: (obj?: I18nArgumentType) =>
    translation('WebYado-PrefectureKeywords', obj),
  /* 高知県 */
  webYadoPrefectureKochi: (obj?: I18nArgumentType) => translation('WebYado-PrefectureKochi', obj),
  /* 熊本県 */
  webYadoPrefectureKumamoto: (obj?: I18nArgumentType) =>
    translation('WebYado-PrefectureKumamoto', obj),
  /* 京都府 */
  webYadoPrefectureKyoto: (obj?: I18nArgumentType) => translation('WebYado-PrefectureKyoto', obj),
  /* 三重県 */
  webYadoPrefectureMie: (obj?: I18nArgumentType) => translation('WebYado-PrefectureMie', obj),
  /* 宮城県 */
  webYadoPrefectureMiyagi: (obj?: I18nArgumentType) => translation('WebYado-PrefectureMiyagi', obj),
  /* 長野県 */
  webYadoPrefectureNagano: (obj?: I18nArgumentType) => translation('WebYado-PrefectureNagano', obj),
  /* 長崎県 */
  webYadoPrefectureNagasaki: (obj?: I18nArgumentType) =>
    translation('WebYado-PrefectureNagasaki', obj),
  /* 奈良県 */
  webYadoPrefectureNara: (obj?: I18nArgumentType) => translation('WebYado-PrefectureNara', obj),
  /* 新潟県 */
  webYadoPrefectureNigata: (obj?: I18nArgumentType) => translation('WebYado-PrefectureNigata', obj),
  /* 大分県 */
  webYadoPrefectureOita: (obj?: I18nArgumentType) => translation('WebYado-PrefectureOita', obj),
  /* 岡山県 */
  webYadoPrefectureOkayama: (obj?: I18nArgumentType) =>
    translation('WebYado-PrefectureOkayama', obj),
  /* 佐賀県 */
  webYadoPrefectureSaga: (obj?: I18nArgumentType) => translation('WebYado-PrefectureSaga', obj),
  /* 滋賀県 */
  webYadoPrefectureShiga: (obj?: I18nArgumentType) => translation('WebYado-PrefectureShiga', obj),
  /* 島根県 */
  webYadoPrefectureShimane: (obj?: I18nArgumentType) =>
    translation('WebYado-PrefectureShimane', obj),
  /* 静岡県 */
  webYadoPrefectureShizuoka: (obj?: I18nArgumentType) =>
    translation('WebYado-PrefectureShizuoka', obj),
  /* {{prefecture}}の旅館・ホテルをお得に予約 WAmazing */
  webYadoPrefectureTitle: (obj?: I18nArgumentType) => translation('WebYado-PrefectureTitle', obj),
  /* 栃木県 */
  webYadoPrefectureTochigi: (obj?: I18nArgumentType) =>
    translation('WebYado-PrefectureTochigi', obj),
  /* 徳島県 */
  webYadoPrefectureTokushima: (obj?: I18nArgumentType) =>
    translation('WebYado-PrefectureTokushima', obj),
  /* 鳥取県 */
  webYadoPrefectureTottori: (obj?: I18nArgumentType) =>
    translation('WebYado-PrefectureTottori', obj),
  /* 富山県 */
  webYadoPrefectureToyama: (obj?: I18nArgumentType) => translation('WebYado-PrefectureToyama', obj),
  /* 和歌山県 */
  webYadoPrefectureWakayama: (obj?: I18nArgumentType) =>
    translation('WebYado-PrefectureWakayama', obj),
  /* 山形県 */
  webYadoPrefectureYamagata: (obj?: I18nArgumentType) =>
    translation('WebYado-PrefectureYamagata', obj),
  /* 山口県 */
  webYadoPrefectureYamaguchi: (obj?: I18nArgumentType) =>
    translation('WebYado-PrefectureYamaguchi', obj),
  /* 山梨県 */
  webYadoPrefectureYamanashi: (obj?: I18nArgumentType) =>
    translation('WebYado-PrefectureYamanashi', obj),
  /* 都道府県 */
  webYadoPrefectures: (obj?: I18nArgumentType) => translation('WebYado-Prefectures', obj),
  /* 日本の都道府県 */
  webYadoPrefecturesInJapan: (obj?: I18nArgumentType) =>
    translation('WebYado-PrefecturesInJapan', obj),
  /* こだわり条件 */
  webYadoPreferenceCondition: (obj?: I18nArgumentType) =>
    translation('WebYado-PreferenceCondition', obj),
  /* 使い放題SIMがもらえる */
  webYadoPresentInfinitySimCard: (obj?: I18nArgumentType) =>
    translation('WebYado-PresentInfinitySimCard', obj),
  /* [object Object] */
  webYadoPriceForNights: (obj?: I18nArgumentType) => translation('WebYado-PriceForNights', obj),
  /* /1泊の最安値 */
  webYadoPriceForOneDay: (obj?: I18nArgumentType) => translation('WebYado-PriceForOneDay', obj),
  /* 一泊料金 */
  webYadoPricePerNight: (obj?: I18nArgumentType) => translation('WebYado-PricePerNight', obj),
  /* 価格帯（毎晩） */
  webYadoPriceRangePerNight: (obj?: I18nArgumentType) =>
    translation('WebYado-PriceRangePerNight', obj),
  /* 価格の検索が完了しました。 */
  webYadoPriceSearchCompleted: (obj?: I18nArgumentType) =>
    translation('WebYado-PriceSearchCompleted', obj),
  /* プライバシーポリシー */
  webYadoPrivacyPolicy: (obj?: I18nArgumentType) => translation('WebYado-PrivacyPolicy', obj),
  /* 貸切風呂 */
  webYadoPrivateBath: (obj?: I18nArgumentType) => translation('WebYado-PrivateBath', obj),
  /* 事前予約必要 */
  webYadoPrivateBathNeedReservationInAdvance: (obj?: I18nArgumentType) =>
    translation('WebYado-PrivateBathNeedReservationInAdvance', obj),
  /* ご予約は不要で、当日空いていればご利用可能です。当日施設にてご確認ください */
  webYadoPrivateBathNeedReservationOnTheDay: (obj?: I18nArgumentType) =>
    translation('WebYado-PrivateBathNeedReservationOnTheDay', obj),
  /* 予約不要 */
  webYadoPrivateBathNoNeedReservation: (obj?: I18nArgumentType) =>
    translation('WebYado-PrivateBathNoNeedReservation', obj),
  /* 貸切風呂予約 */
  webYadoPrivateBathReservation: (obj?: I18nArgumentType) =>
    translation('WebYado-PrivateBathReservation', obj),
  /* 貸切風呂が予約できる宿です */
  webYadoPrivateBathReservationAvailable: (obj?: I18nArgumentType) =>
    translation('WebYado-PrivateBathReservationAvailable', obj),
  /* WAmazing経由でご予約いただくと、貸切風呂の事前予約手配サービスをご利用いただけます。 */
  webYadoPrivateBathReservationDescription1: (obj?: I18nArgumentType) =>
    translation('WebYado-PrivateBathReservationDescription1', obj),
  /* WAmazing経由でご予約いただくと、{{bold}}をご利用いただけます。 */
  webYadoPrivateBathReservationDescription1Bolded: (obj?: I18nArgumentType) =>
    translation('WebYado-PrivateBathReservationDescription1Bolded', obj),
  /* 貸切風呂の事前予約手配サービス */
  webYadoPrivateBathReservationDescription1BoldedText: (obj?: I18nArgumentType) =>
    translation('WebYado-PrivateBathReservationDescription1BoldedText', obj),
  /* 貸切風呂は人気のため、宿泊当日の予約では既に利用可能な時間が埋まっている場合がありますので、事前の予約がおすすめです。 */
  webYadoPrivateBathReservationDescription2: (obj?: I18nArgumentType) =>
    translation('WebYado-PrivateBathReservationDescription2', obj),
  /* 当サイト経由で手配が可能なため、事前に宿に電話して日本語で確認をとったり、チェックイン当日に宿のフロントで依頼するなどの手間がなく、スムーズに利用することができます。 */
  webYadoPrivateBathReservationDescription3: (obj?: I18nArgumentType) =>
    translation('WebYado-PrivateBathReservationDescription3', obj),
  /* 詳しいサービス内容は宿によって異なりますので、問い合わせフォームよりお気軽にお問い合わせください。 */
  webYadoPrivateBathReservationDescription4: (obj?: I18nArgumentType) =>
    translation('WebYado-PrivateBathReservationDescription4', obj),
  /* 貸切風呂の利用料金は税込料金になります。 */
  webYadoPrivateBathTaxIncluded: (obj?: I18nArgumentType) =>
    translation('WebYado-PrivateBathTaxIncluded', obj),
  /* (10月1日の消費税引き上げにともない、料金が変更になる可能性があります) */
  webYadoPrivateBathTaxMightGetRaised: (obj?: I18nArgumentType) =>
    translation('WebYado-PrivateBathTaxMightGetRaised', obj),
  /* 施設に貸切風呂がある */
  webYadoPrivateHotSpringAvailable: (obj?: I18nArgumentType) =>
    translation('WebYado-PrivateHotSpringAvailable', obj),
  /* お部屋食、個室食の提供ができる */
  webYadoPrivateMeal: (obj?: I18nArgumentType) => translation('WebYado-PrivateMeal', obj),
  /* 貸切露天風呂 */
  webYadoPrivateOpenAirBath: (obj?: I18nArgumentType) =>
    translation('WebYado-PrivateOpenAirBath', obj),
  /* 他の利用者と同乗なしの専用車でのお迎えに対応している */
  webYadoPrivatePickUp: (obj?: I18nArgumentType) => translation('WebYado-PrivatePickUp', obj),
  /* お部屋食、個室食の提供ができる */
  webYadoPrivateRoomDining: (obj?: I18nArgumentType) =>
    translation('WebYado-PrivateRoomDining', obj),
  /* 個室食 */
  webYadoPrivateRoomMeal: (obj?: I18nArgumentType) => translation('WebYado-PrivateRoomMeal', obj),
  /* 予約へ進む */
  webYadoProceedToBook: (obj?: I18nArgumentType) => translation('WebYado-ProceedToBook', obj),
  /* 決済に進む */
  webYadoProceedToPayment: (obj?: I18nArgumentType) => translation('WebYado-ProceedToPayment', obj),
  /* 完了 */
  webYadoProgressBookingCompleted: (obj?: I18nArgumentType) =>
    translation('WebYado-ProgressBookingCompleted', obj),
  /* {{age}}代 */
  webYadoPromo202003Age: (obj?: I18nArgumentType) => translation('WebYado-Promo202003Age', obj),
  /* 予約時にお迎えサービスが手配できます */
  webYadoPromo202003Characteristic1: (obj?: I18nArgumentType) =>
    translation('WebYado-Promo202003Characteristic1', obj),
  /* 予約時に夕食が選べます */
  webYadoPromo202003Characteristic2: (obj?: I18nArgumentType) =>
    translation('WebYado-Promo202003Characteristic2', obj),
  /* 予約時に貸切風呂が手配できます */
  webYadoPromo202003Characteristic3: (obj?: I18nArgumentType) =>
    translation('WebYado-Promo202003Characteristic3', obj),
  /* 女性 */
  webYadoPromo202003Female: (obj?: I18nArgumentType) =>
    translation('WebYado-Promo202003Female', obj),
  /* 食事や送迎についての情報が他のサイトよりも充実しているから、信頼できた。 */
  webYadoPromo202003Impression1: (obj?: I18nArgumentType) =>
    translation('WebYado-Promo202003Impression1', obj),
  /* ベジタリアンなので、夕食の内容を予約時に知ることができて、安心して予約できた。 */
  webYadoPromo202003Impression2: (obj?: I18nArgumentType) =>
    translation('WebYado-Promo202003Impression2', obj),
  /* 日本の温泉ではタトゥー禁止であることが多いが、家族風呂の予約を手配してもらえたので、プライベート入浴を楽しめた。 */
  webYadoPromo202003Impression3: (obj?: I18nArgumentType) =>
    translation('WebYado-Promo202003Impression3', obj),
  /* 「玩尽日本」は日本の事業者だからこそ提供できる、厳選された温泉宿をオンラインで予約できるサービスです。 */
  webYadoPromo202003MainDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-Promo202003MainDescription', obj),
  /* 会員限定クーポン */
  webYadoPromo202003MainEnvelopeText: (obj?: I18nArgumentType) =>
    translation('WebYado-Promo202003MainEnvelopeText', obj),
  /* WeChat公式アカウントをフォローしてゲット */
  webYadoPromo202003MainFollowText: (obj?: I18nArgumentType) =>
    translation('WebYado-Promo202003MainFollowText', obj),
  /* 男性 */
  webYadoPromo202003Male: (obj?: I18nArgumentType) => translation('WebYado-Promo202003Male', obj),
  /* 日本の事業者が運営しています */
  webYadoPromo202003Reason1: (obj?: I18nArgumentType) =>
    translation('WebYado-Promo202003Reason1', obj),
  /* 中国語でのサポートに対応しています */
  webYadoPromo202003Reason2: (obj?: I18nArgumentType) =>
    translation('WebYado-Promo202003Reason2', obj),
  /* 予約時にクレジットカード不要です */
  webYadoPromo202003Reason3: (obj?: I18nArgumentType) =>
    translation('WebYado-Promo202003Reason3', obj),
  /* 他社よりお得なプランもあります */
  webYadoPromo202003Reason4: (obj?: I18nArgumentType) =>
    translation('WebYado-Promo202003Reason4', obj),
  /* 最低価格を保証しています */
  webYadoPromo202003Reason5: (obj?: I18nArgumentType) =>
    translation('WebYado-Promo202003Reason5', obj),
  /* 日本全国80以上の温泉地を取り扱っています */
  webYadoPromo202003Reason6: (obj?: I18nArgumentType) =>
    translation('WebYado-Promo202003Reason6', obj),
  /* WeChat公式アカウントをフォローすると */
  webYadoPromo202003RegistrationSubTitle: (obj?: I18nArgumentType) =>
    translation('WebYado-Promo202003RegistrationSubTitle', obj),
  /* 今なら会員限定のクーポンもらえる！ */
  webYadoPromo202003RegistrationTitle: (obj?: I18nArgumentType) =>
    translation('WebYado-Promo202003RegistrationTitle', obj),
  /* 「玩尽日本」温泉宿予約が選ばれる理由 */
  webYadoPromo202003SubTitle1: (obj?: I18nArgumentType) =>
    translation('WebYado-Promo202003SubTitle1', obj),
  /* 「玩尽日本」3つの特徴 */
  webYadoPromo202003SubTitle2: (obj?: I18nArgumentType) =>
    translation('WebYado-Promo202003SubTitle2', obj),
  /* ご利用者様の声 */
  webYadoPromo202003SubTitle3: (obj?: I18nArgumentType) =>
    translation('WebYado-Promo202003SubTitle3', obj),
  /* 期間限定 会員登録でもれなく全員に特別なクーポンを配布中！WAmazingは日本中の厳選された温泉旅館を会員限定価格で提供しています。（年会費、会員登録は完全無料です。登録にクレジットカードの登録などは不要。簡単１５秒で会員登録） */
  webYadoPromoMetaDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-PromoMetaDescription', obj),
  /* 日本,温泉,ホテル,旅館,宿泊,宿泊予約,予約,WAmazing */
  webYadoPromoMetaKeywords: (obj?: I18nArgumentType) =>
    translation('WebYado-PromoMetaKeywords', obj),
  /* 日本の温泉旅館・ホテル予約なら会員数30万人突破のWAmazingで */
  webYadoPromoMetaTitle: (obj?: I18nArgumentType) => translation('WebYado-PromoMetaTitle', obj),
  /* 大浴場の混雑を回避するために、入場の分散を行っている */
  webYadoPublicBathCongestionEntryRestrictions: (obj?: I18nArgumentType) =>
    translation('WebYado-PublicBathCongestionEntryRestrictions', obj),
  /* 大浴場の人数制限をしている */
  webYadoPublicBathEntryRestrictions: (obj?: I18nArgumentType) =>
    translation('WebYado-PublicBathEntryRestrictions', obj),
  /* 大浴場の混雑状況をモニタリングできる仕組みがある */
  webYadoPublicBathGuestCountMonitoring: (obj?: I18nArgumentType) =>
    translation('WebYado-PublicBathGuestCountMonitoring', obj),
  /* パターゴルフ */
  webYadoPutterGolf: (obj?: I18nArgumentType) => translation('WebYado-PutterGolf', obj),
  /* クイーンベッド */
  webYadoQueenBed: (obj?: I18nArgumentType) => translation('WebYado-QueenBed', obj),
  /* ラフティング */
  webYadoRafting: (obj?: I18nArgumentType) => translation('WebYado-Rafting', obj),
  /* 清潔感 */
  webYadoRatingCleanliness: (obj?: I18nArgumentType) =>
    translation('WebYado-RatingCleanliness', obj),
  /* 風呂 */
  webYadoRatingHotSpring: (obj?: I18nArgumentType) => translation('WebYado-RatingHotSpring', obj),
  /* サービス */
  webYadoRatingService: (obj?: I18nArgumentType) => translation('WebYado-RatingService', obj),
  /* 人気・おすすめ */
  webYadoRecommend: (obj?: I18nArgumentType) => translation('WebYado-Recommend', obj),
  /* おすすめ */
  webYadoRecommended: (obj?: I18nArgumentType) => translation('WebYado-Recommended', obj),
  /* オプション購入できるおすすめ交通チケット */
  webYadoRecommendedTransportation: (obj?: I18nArgumentType) =>
    translation('WebYado-RecommendedTransportation', obj),
  /* 冷蔵庫 */
  webYadoRefrigerator: (obj?: I18nArgumentType) => translation('WebYado-Refrigerator', obj),
  /* 夕食について */
  webYadoRegardingDinner: (obj?: I18nArgumentType) => translation('WebYado-RegardingDinner', obj),
  /* カード情報を登録する */
  webYadoRegisterCard: (obj?: I18nArgumentType) => translation('WebYado-RegisterCard', obj),
  /* クレジットカード情報を登録する */
  webYadoRegisterCreditCardInfo: (obj?: I18nArgumentType) =>
    translation('WebYado-RegisterCreditCardInfo', obj),
  /* ご登録のクレジットカード */
  webYadoRegisteredCreditCards: (obj?: I18nArgumentType) =>
    translation('WebYado-RegisteredCreditCards', obj),
  /* 再読込 */
  webYadoReload: (obj?: I18nArgumentType) => translation('WebYado-Reload', obj),
  /* 残り{{count}}枚 */
  webYadoRemainingCount: (obj?: I18nArgumentType) => translation('WebYado-RemainingCount', obj),
  /* 売切れを削除して予約可能な宿泊施設のみを表示します。 */
  webYadoRemoveSoldOutShowVacancyOnly: (obj?: I18nArgumentType) =>
    translation('WebYado-RemoveSoldOutShowVacancyOnly', obj),
  /* 自転車レンタル */
  webYadoRentalBicycle: (obj?: I18nArgumentType) => translation('WebYado-RentalBicycle', obj),
  /* 自転車レンタル */
  webYadoRentalBycycle: (obj?: I18nArgumentType) => translation('WebYado-RentalBycycle', obj),
  /* 代表者の名 */
  webYadoRepresentativeLastName: (obj?: I18nArgumentType) =>
    translation('WebYado-RepresentativeLastName', obj),
  /* 代表者の姓 */
  webYadoRepresentativeFirstName: (obj?: I18nArgumentType) =>
    translation('WebYado-RepresentativeFirstName', obj),
  /* 貸切風呂予約可 */
  webYadoReservablePrivateBath: (obj?: I18nArgumentType) =>
    translation('WebYado-ReservablePrivateBath', obj),
  /* ご予約 */
  webYadoReservation: (obj?: I18nArgumentType) => translation('WebYado-Reservation', obj),
  /* 予約一覧 */
  webYadoReservationHistory: (obj?: I18nArgumentType) =>
    translation('WebYado-ReservationHistory', obj),
  /* レストラン食 */
  webYadoRestaurantMeal: (obj?: I18nArgumentType) => translation('WebYado-RestaurantMeal', obj),
  /* レビュー */
  webYadoReview: (obj?: I18nArgumentType) => translation('WebYado-Review', obj),
  /* 出典 */
  webYadoReviewSource: (obj?: I18nArgumentType) => translation('WebYado-ReviewSource', obj),
  /* コンディショナー */
  webYadoRinse: (obj?: I18nArgumentType) => translation('WebYado-Rinse', obj),
  /* 部屋 */
  webYadoRoom: (obj?: I18nArgumentType) => translation('WebYado-Room', obj),
  /* 部屋の詳細 */
  webYadoRoomDetail: (obj?: I18nArgumentType) => translation('WebYado-RoomDetail', obj),
  /* 部屋の施設 */
  webYadoRoomFacility: (obj?: I18nArgumentType) => translation('WebYado-RoomFacility', obj),
  /* お部屋 */
  webYadoRoomFilter: (obj?: I18nArgumentType) => translation('WebYado-RoomFilter', obj),
  /* デラックス */
  webYadoRoomGradeDeluxe: (obj?: I18nArgumentType) => translation('WebYado-RoomGradeDeluxe', obj),
  /* エコノミー */
  webYadoRoomGradeEconomy: (obj?: I18nArgumentType) => translation('WebYado-RoomGradeEconomy', obj),
  /* スペシャル */
  webYadoRoomGradeSpecial: (obj?: I18nArgumentType) => translation('WebYado-RoomGradeSpecial', obj),
  /* スタンダード */
  webYadoRoomGradeStandardRegular: (obj?: I18nArgumentType) =>
    translation('WebYado-RoomGradeStandardRegular', obj),
  /* スーペリア */
  webYadoRoomGradeSuperior: (obj?: I18nArgumentType) =>
    translation('WebYado-RoomGradeSuperior', obj),
  /* ルームサービス */
  webYadoRoomService: (obj?: I18nArgumentType) => translation('WebYado-RoomService', obj),
  /* ルームサービス(24時間) */
  webYadoRoomService24: (obj?: I18nArgumentType) => translation('WebYado-RoomService24', obj),
  /* その他 */
  webYadoRoomTypeOther: (obj?: I18nArgumentType) => translation('WebYado-RoomTypeOther', obj),
  /* 選択された日付は売り切れです */
  webYadoRoomsSoldOutOnSelectedDate: (obj?: I18nArgumentType) =>
    translation('WebYado-RoomsSoldOutOnSelectedDate', obj),
  /* 元湯龍泉閣 */
  webYadoRyusenkaku: (obj?: I18nArgumentType) => translation('WebYado-Ryusenkaku', obj),
  /* 神戸有馬温泉の家族で楽しめる自家金泉の宿。お子様・赤ちゃんサービスは５０種以上。ご夕食は嬉しいお部屋食。 */
  webYadoRyusenkakuDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-RyusenkakuDescription', obj),
  /* セーフティボックス（客室内） */
  webYadoSafeDepositBox: (obj?: I18nArgumentType) => translation('WebYado-SafeDepositBox', obj),
  /* セーフティボックス（フロント） */
  webYadoSafeDepositBoxFront: (obj?: I18nArgumentType) =>
    translation('WebYado-SafeDepositBoxFront', obj),
  /* 安全衛生対策を見る */
  webYadoSanitationMeasures: (obj?: I18nArgumentType) =>
    translation('WebYado-SanitationMeasures', obj),
  /* フロントやロビーに消毒液を設置している */
  webYadoSanitizerAtFrontDesk: (obj?: I18nArgumentType) =>
    translation('WebYado-SanitizerAtFrontDesk', obj),
  /* 脱衣所に消毒液を設置している */
  webYadoSanitizerInChangingRoom: (obj?: I18nArgumentType) =>
    translation('WebYado-SanitizerInChangingRoom', obj),
  /* 消毒液を設置している */
  webYadoSanitizerInstallation: (obj?: I18nArgumentType) =>
    translation('WebYado-SanitizerInstallation', obj),
  /* 土曜日 */
  webYadoSaturday: (obj?: I18nArgumentType) => translation('WebYado-Saturday', obj),
  /* 検索する */
  webYadoSearch: (obj?: I18nArgumentType) => translation('WebYado-Search', obj),
  /* 空港から探す */
  webYadoSearchByAirport: (obj?: I18nArgumentType) => translation('WebYado-SearchByAirport', obj),
  /* 温泉地・空港名・町・宿名で探す */
  webYadoSearchByOnsenAirport: (obj?: I18nArgumentType) =>
    translation('WebYado-SearchByOnsenAirport', obj),
  /* 人気スポットから探す */
  webYadoSearchByPopularDestination: (obj?: I18nArgumentType) =>
    translation('WebYado-SearchByPopularDestination', obj),
  /* 駅から探す */
  webYadoSearchByStation: (obj?: I18nArgumentType) => translation('WebYado-SearchByStation', obj),
  /* 部屋の検索 */
  webYadoSearchRoom: (obj?: I18nArgumentType) => translation('WebYado-SearchRoom', obj),
  /* 宿の検索 */
  webYadoSearchYado: (obj?: I18nArgumentType) => translation('WebYado-SearchYado', obj),
  /* 最安値を検索中 */
  webYadoSearchingBestPrice: (obj?: I18nArgumentType) =>
    translation('WebYado-SearchingBestPrice', obj),
  /* すべての{{onsenArea}}の宿を見る */
  webYadoSeeAllYadosInTheArea: (obj?: I18nArgumentType) =>
    translation('WebYado-SeeAllYadosInTheArea', obj),
  /* 予約詳細を見る */
  webYadoSeeBookingDetail: (obj?: I18nArgumentType) => translation('WebYado-SeeBookingDetail', obj),
  /* 予約一覧を見る */
  webYadoSeeBookings: (obj?: I18nArgumentType) => translation('WebYado-SeeBookings', obj),
  /* 詳細を見る */
  webYadoSeeDetail: (obj?: I18nArgumentType) => translation('WebYado-SeeDetail', obj),
  /* もっと見る */
  webYadoSeeMore: (obj?: I18nArgumentType) => translation('WebYado-SeeMore', obj),
  /* この宿の詳細をもっと見る */
  webYadoSeeMoreAboutYado: (obj?: I18nArgumentType) => translation('WebYado-SeeMoreAboutYado', obj),
  /* プランを見る */
  webYadoSeePlan: (obj?: I18nArgumentType) => translation('WebYado-SeePlan', obj),
  /* 食事をお選びください */
  webYadoSelectDinner: (obj?: I18nArgumentType) => translation('WebYado-SelectDinner', obj),
  /* コインランドリー */
  webYadoSelfLaundry: (obj?: I18nArgumentType) => translation('WebYado-SelfLaundry', obj),
  /* セミダブルベッド */
  webYadoSemiDoubleBed: (obj?: I18nArgumentType) => translation('WebYado-SemiDoubleBed', obj),
  /* 箱根仙石原プリンスホテル */
  webYadoSengokuharaPrince: (obj?: I18nArgumentType) =>
    translation('WebYado-SengokuharaPrince', obj),
  /* 箱根仙石原高原は周囲を箱根外輪山に囲まれ、客室から望む迫力のある眺望が大きな魅力のひとつ。ひっそりとたたずむお城のような外観が特徴的なホテルです。 */
  webYadoSengokuharaPrinceDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-SengokuharaPrinceDescription', obj),
  /* 有り */
  webYadoServed: (obj?: I18nArgumentType) => translation('WebYado-Served', obj),
  /* WAmazingで予約することのメリット */
  webYadoServiceIntro: (obj?: I18nArgumentType) => translation('WebYado-ServiceIntro', obj),
  /* WAmazing */
  webYadoServiceProvider: (obj?: I18nArgumentType) => translation('WebYado-ServiceProvider', obj),
  /* シャンプー */
  webYadoShampoo: (obj?: I18nArgumentType) => translation('WebYado-Shampoo', obj),
  /* この記事をシェアする */
  webYadoShareArticle: (obj?: I18nArgumentType) => translation('WebYado-ShareArticle', obj),
  /* 部屋共通の設備 */
  webYadoSharedFacilities: (obj?: I18nArgumentType) => translation('WebYado-SharedFacilities', obj),
  /* Wi-Fi共有エリア */
  webYadoSharedSpaceFreeWifi: (obj?: I18nArgumentType) =>
    translation('WebYado-SharedSpaceFreeWifi', obj),
  /* 髭剃り */
  webYadoShaving: (obj?: I18nArgumentType) => translation('WebYado-Shaving', obj),
  /* 将棋 */
  webYadoShogi: (obj?: I18nArgumentType) => translation('WebYado-Shogi', obj),
  /* 西村屋ホテル招月庭 */
  webYadoShotsuki: (obj?: I18nArgumentType) => translation('WebYado-Shotsuki', obj),
  /* 但馬が誇る山海の恵み・四季折々の食材を西村屋伝統の味と技であしらった逸品料理を堪能。 */
  webYadoShotsukiDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-ShotsukiDescription', obj),
  /* 表示する */
  webYadoShow: (obj?: I18nArgumentType) => translation('WebYado-Show', obj),
  /* 選択された日付は売り切れのため、{{checkinDate}}で利用可能なプランを表示しています */
  webYadoShowOtherCheckinDateForThePlan: (obj?: I18nArgumentType) =>
    translation('WebYado-ShowOtherCheckinDateForThePlan', obj),
  /* シャワー */
  webYadoShower: (obj?: I18nArgumentType) => translation('WebYado-Shower', obj),
  /* シャワーキャップ */
  webYadoShowerCap: (obj?: I18nArgumentType) => translation('WebYado-ShowerCap', obj),
  /* 1万円割引！ */
  webYadoShunsetsu1ManYen: (obj?: I18nArgumentType) => translation('WebYado-Shunsetsu1ManYen', obj),
  /* 通常なら海外向けには販売されていない商品も！ */
  webYadoShunsetsuDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-ShunsetsuDescription', obj),
  /* 温泉宿の最大3万円クーポンプレゼント！ */
  webYadoShunsetsuNewCampaignDescription1: (obj?: I18nArgumentType) =>
    translation('WebYado-ShunsetsuNewCampaignDescription1', obj),
  /* 無料SIMカードの容量が「無制限」になる特典も！ */
  webYadoShunsetsuNewCampaignDescription2: (obj?: I18nArgumentType) =>
    translation('WebYado-ShunsetsuNewCampaignDescription2', obj),
  /* 先着！ */
  webYadoShunsetsuSenchaku: (obj?: I18nArgumentType) =>
    translation('WebYado-ShunsetsuSenchaku', obj),
  /* WAmazing限定のお得なプランを販売中！ */
  webYadoShunsetsuTitle: (obj?: I18nArgumentType) => translation('WebYado-ShunsetsuTitle', obj),
  /* ログイン */
  webYadoSignIn: (obj?: I18nArgumentType) => translation('WebYado-SignIn', obj),
  /* ログアウト */
  webYadoSignOut: (obj?: I18nArgumentType) => translation('WebYado-SignOut', obj),
  /* 会員登録 */
  webYadoSignUp: (obj?: I18nArgumentType) => translation('WebYado-SignUp', obj),
  /* ご注文の前に会員登録またはログインが必要です。 */
  webYadoSignUpOrLogInRequired: (obj?: I18nArgumentType) =>
    translation('WebYado-SignUpOrLogInRequired', obj),
  /* シングルベッド */
  webYadoSingleBed: (obj?: I18nArgumentType) => translation('WebYado-SingleBed', obj),
  /* スキー保管室 */
  webYadoSkiStoreroom: (obj?: I18nArgumentType) => translation('WebYado-SkiStoreroom', obj),
  /* 喫煙所 */
  webYadoSmokingArea: (obj?: I18nArgumentType) => translation('WebYado-SmokingArea', obj),
  /* 喫煙ルール不明 */
  webYadoSmokingRegulationUnknown: (obj?: I18nArgumentType) =>
    translation('WebYado-SmokingRegulationUnknown', obj),
  /* 石鹸 */
  webYadoSoap: (obj?: I18nArgumentType) => translation('WebYado-Soap', obj),
  /* 空きがありません */
  webYadoSoldOut: (obj?: I18nArgumentType) => translation('WebYado-SoldOut', obj),
  /* エラーが発生しました */
  webYadoSomethingWentWrong: (obj?: I18nArgumentType) =>
    translation('WebYado-SomethingWentWrong', obj),
  /* 食事会場の座席間隔を調整している */
  webYadoSpacedSeatingDiningAreas: (obj?: I18nArgumentType) =>
    translation('WebYado-SpacedSeatingDiningAreas', obj),
  /* 料金に関する特記事項 */
  webYadoSpecialNoteForFee: (obj?: I18nArgumentType) =>
    translation('WebYado-SpecialNoteForFee', obj),
  /* 子供{{key}}の年齢 */
  webYadoSpecificChildAge: (obj?: I18nArgumentType) => translation('WebYado-SpecificChildAge', obj),
  /* グラウンド */
  webYadoSportsGround: (obj?: I18nArgumentType) => translation('WebYado-SportsGround', obj),
  /* {{spot}} | WAmazingでは10,000件を超えるホテルを掲載しています。日本最大規模の掲載数なので、理想のホテルが必ず見つかります。 */
  webYadoSpotDescription: (obj?: I18nArgumentType) => translation('WebYado-SpotDescription', obj),
  /* {{spot}},日本,旅行,宿,宿泊,旅館,ホテル,ビジネスホテル,リゾート */
  webYadoSpotKeywords: (obj?: I18nArgumentType) => translation('WebYado-SpotKeywords', obj),
  /* {{spot}}の旅館・ホテルをお得に予約 WAmazing */
  webYadoSpotTitle: (obj?: I18nArgumentType) => translation('WebYado-SpotTitle', obj),
  /* 春 */
  webYadoSpring: (obj?: I18nArgumentType) => translation('WebYado-Spring', obj),
  /* 3月〜5月 */
  webYadoSpringMonths: (obj?: I18nArgumentType) => translation('WebYado-SpringMonths', obj),
  /* 利用開始日 */
  webYadoStartDate: (obj?: I18nArgumentType) => translation('WebYado-StartDate', obj),
  /* 利用開始日(日本時間) */
  webYadoStartDateOfUse: (obj?: I18nArgumentType) => translation('WebYado-StartDateOfUse', obj),
  /* ご質問等ございましたら、wechatオフィシャルアカウント「玩盡日本 滑雪・溫泉社」をフォローした後、お問い合わせ下さい。 */
  webYadoSubscribe: (obj?: I18nArgumentType) => translation('WebYado-Subscribe', obj),
  /* 夏 */
  webYadoSummer: (obj?: I18nArgumentType) => translation('WebYado-Summer', obj),
  /* 6月〜8月 */
  webYadoSummerMonths: (obj?: I18nArgumentType) => translation('WebYado-SummerMonths', obj),
  /* 日曜日 */
  webYadoSunday: (obj?: I18nArgumentType) => translation('WebYado-Sunday', obj),
  /* 詳しいサービス内容は宿によって異なりますので、{{link}}よりお気軽にお問い合わせください。 */
  webYadoSupportChatLinkedChokuWithoutPrivateBath: (obj?: I18nArgumentType) =>
    translation('WebYado-SupportChatLinkedChokuWithoutPrivateBath', obj),
  /* 貸切風呂についての詳しいサービス内容は宿によって異なりますので、{{link}}よりお気軽にお問い合わせください。 */
  webYadoSupportChatLinkedChokuWithPrivateBath: (obj?: I18nArgumentType) =>
    translation('WebYado-SupportChatLinkedChokuWithPrivateBath', obj),
  /* 問い合わせフォーム */
  webYadoSupportChatLinkedText: (obj?: I18nArgumentType) =>
    translation('WebYado-SupportChatLinkedText', obj),
  /* 綿棒 */
  webYadoSwab: (obj?: I18nArgumentType) => translation('WebYado-Swab', obj),
  /* 松園荘 保津川亭 */
  webYadoSyoenso: (obj?: I18nArgumentType) => translation('WebYado-Syoenso', obj),
  /* 丹波篠山名物の猪肉、丹波牛等の銘柄牛や市場直送の魚介など、旬の食材の味わいをいいとこどり。 */
  webYadoSyoensoDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-SyoensoDescription', obj),
  /* 卓球 */
  webYadoTableTennis: (obj?: I18nArgumentType) => translation('WebYado-TableTennis', obj),
  /* ゆわいの宿 竹乃井 */
  webYadoTakenoi: (obj?: I18nArgumentType) => translation('WebYado-Takenoi', obj),
  /* 大人も子どもも楽しめる、色どり鮮やかな大分の山海の幸を使った創作料理を食べることができる。 */
  webYadoTakenoiDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-TakenoiDescription', obj),
  /* 対象 */
  webYadoTarget: (obj?: I18nArgumentType) => translation('WebYado-Target', obj),
  /* 税込 */
  webYadoTaxIncluded: (obj?: I18nArgumentType) => translation('WebYado-TaxIncluded', obj),
  /* 電話 */
  webYadoTelephone: (obj?: I18nArgumentType) => translation('WebYado-Telephone', obj),
  /* テニスコート */
  webYadoTennisCourt: (obj?: I18nArgumentType) => translation('WebYado-TennisCourt', obj),
  /* 利用規約 */
  webYadoTerm: (obj?: I18nArgumentType) => translation('WebYado-Term', obj),
  /* 会員利用規約 */
  webYadoTerms: (obj?: I18nArgumentType) => translation('WebYado-Terms', obj),
  /* 3桁の数字 */
  webYadoThreeDigit: (obj?: I18nArgumentType) => translation('WebYado-ThreeDigit', obj),
  /* 種類 */
  webYadoTicketType: (obj?: I18nArgumentType) => translation('WebYado-TicketType', obj),
  /* Never fill in your nickname, Please fill in the Passport name. */
  webYadoTicketUserAttention: (obj?: I18nArgumentType) =>
    translation('WebYado-TicketUserAttention', obj),
  /* {{time}}から */
  webYadoTimeFrom: (obj?: I18nArgumentType) => translation('WebYado-TimeFrom', obj),
  /* 残り時間 */
  webYadoTimeRemaining: (obj?: I18nArgumentType) => translation('WebYado-TimeRemaining', obj),
  /* {{time}}まで */
  webYadoTimeTo: (obj?: I18nArgumentType) => translation('WebYado-TimeTo', obj),
  /* 終了 */
  webYadoTo: (obj?: I18nArgumentType) => translation('WebYado-To', obj),
  /* トイレ */
  webYadoToilet: (obj?: I18nArgumentType) => translation('WebYado-Toilet', obj),
  /* 歯ブラシ・歯磨き粉 */
  webYadoToothbrush: (obj?: I18nArgumentType) => translation('WebYado-Toothbrush', obj),
  /* WAmazingでは10,000件を超えるホテルを掲載しています。日本最大規模の掲載数なので、理想のホテルが必ず見つかります。 */
  webYadoTopDescription: (obj?: I18nArgumentType) => translation('WebYado-TopDescription', obj),
  /* 日本の温泉に泊まろう */
  webYadoTopHeadCopy: (obj?: I18nArgumentType) => translation('WebYado-TopHeadCopy', obj),
  /* 日本,旅行,宿,宿泊,旅館,ホテル,ビジネスホテル,リゾート */
  webYadoTopKeywords: (obj?: I18nArgumentType) => translation('WebYado-TopKeywords', obj),
  /* 日本の旅館・ホテルをお得に予約 WAmazing */
  webYadoTopTitle: (obj?: I18nArgumentType) => translation('WebYado-TopTitle', obj),
  /* 合計金額 */
  webYadoTotalPrice: (obj?: I18nArgumentType) => translation('WebYado-TotalPrice', obj),
  /* タオル */
  webYadoTowel: (obj?: I18nArgumentType) => translation('WebYado-Towel', obj),
  /* 旅行業登録票 */
  webYadoTravelAgencyRegistration: (obj?: I18nArgumentType) =>
    translation('WebYado-TravelAgencyRegistration', obj),
  /* 電車での移動について */
  webYadoTravelByTrain: (obj?: I18nArgumentType) => translation('WebYado-TravelByTrain', obj),
  /* 旅行保険 */
  webYadoTravelInsurance: (obj?: I18nArgumentType) => translation('WebYado-TravelInsurance', obj),
  /* 最安値で日本を旅しよう */
  webYadoTravelJapanWithBestHotelDeals: (obj?: I18nArgumentType) =>
    translation('WebYado-TravelJapanWithBestHotelDeals', obj),
  /* {{term}}、{{privacy_policy}}に同意した後、再度予約をして下さい。 */
  webYadoTreatedAsAgreedOnTerms: (obj?: I18nArgumentType) =>
    translation('WebYado-TreatedAsAgreedOnTerms', obj),
  /* ズボンプレッサー */
  webYadoTrouserPresser: (obj?: I18nArgumentType) => translation('WebYado-TrouserPresser', obj),
  /* テレビ */
  webYadoTv: (obj?: I18nArgumentType) => translation('WebYado-Tv', obj),
  /* 2桁の数字 */
  webYadoTwoDigit: (obj?: I18nArgumentType) => translation('WebYado-TwoDigit', obj),
  /* ユーザーID */
  webYadoUserId: (obj?: I18nArgumentType) => translation('WebYado-UserId', obj),
  /* 合計金額 JPY {{amount}} 以上でご利用可能。 */
  webYadoUsingCouponConditionDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-UsingCouponConditionDescription', obj),
  /* ご利用条件 */
  webYadoUsingCouponConditionLabel: (obj?: I18nArgumentType) =>
    translation('WebYado-UsingCouponConditionLabel', obj),
  /* ベジタリアン */
  webYadoVegetarianFriendly: (obj?: I18nArgumentType) =>
    translation('WebYado-VegetarianFriendly', obj),
  /* ベジタリアン対応 */
  webYadoVegetarianOption: (obj?: I18nArgumentType) => translation('WebYado-VegetarianOption', obj),
  /* 自動販売機 */
  webYadoVendingMachine: (obj?: I18nArgumentType) => translation('WebYado-VendingMachine', obj),
  /* 食事会場の消毒や換気を実施している */
  webYadoVentilating: (obj?: I18nArgumentType) => translation('WebYado-Ventilating', obj),
  /* 詳しく見る */
  webYadoViewDetails: (obj?: I18nArgumentType) => translation('WebYado-ViewDetails', obj),
  /* プラン({{planCount}}件) */
  webYadoViewMorePlans: (obj?: I18nArgumentType) => translation('WebYado-ViewMorePlans', obj),
  /* プランをみる({{planCount}}) */
  webYadoViewPlans: (obj?: I18nArgumentType) => translation('WebYado-ViewPlans', obj),
  /* 部屋 ({{roomCount}}件) */
  webYadoViewRooms: (obj?: I18nArgumentType) => translation('WebYado-ViewRooms', obj),
  /* WAmazing アプリ */
  webYadoWamazingApp: (obj?: I18nArgumentType) => translation('WebYado-WamazingApp', obj),
  /* WAmazing サービス */
  webYadoWamazingService: (obj?: I18nArgumentType) => translation('WebYado-WamazingService', obj),
  /* 全角モードになっています。半角で入力してください。 */
  webYadoWarnIME: (obj?: I18nArgumentType) => translation('WebYado-WarnIME', obj),
  /* 日本,旅行,宿泊施設,ホテル,リゾート */
  webYadoWechatKeywords: (obj?: I18nArgumentType) => translation('WebYado-WechatKeywords', obj),
  /* 「玩尽日本 温泉酒店・旅馆」は日本発のサービスで、中国の日本旅行愛好家に向けた、日本の温泉宿の予約プラットフォームです。こだわりの夕食が選べる機能や、オンラインでのお迎え手配サービスなどの独自サービスを提供しています。 */
  webYadoWechatQrlinkDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-WechatQrlinkDescription', obj),
  /* 「玩尽日本 温泉酒店・旅馆」は日本発のサービスで、中国の日本旅行愛好家に向けた、日本の温泉宿の予約プラットフォームです。北海道などの人気エリアを含む、日本全国の60以上の温泉地の、500以上の厳選された温泉宿を取り扱っています。こだわりの夕食が選べる機能や、オンラインでのお迎え手配サービスなどの独自サービスを提供しています。 */
  webYadoWechatSubscribeDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-WechatSubscribeDescription', obj),
  /* 「玩尽日本 温泉宿予約」のWeChat公式アカウントをフォロー | 玩尽日本 */
  webYadoWechatTitle: (obj?: I18nArgumentType) => translation('WebYado-WechatTitle', obj),
  /* 平日 */
  webYadoWeekday: (obj?: I18nArgumentType) => translation('WebYado-Weekday', obj),
  /* WAmazingの3つのオトク */
  webYadoWhyWamazing: (obj?: I18nArgumentType) => translation('WebYado-WhyWamazing', obj),
  /* Wi-Fi共有エリア */
  webYadoWiFiMutualArea: (obj?: I18nArgumentType) => translation('WebYado-WiFiMutualArea', obj),
  /* 冬 */
  webYadoWinter: (obj?: I18nArgumentType) => translation('WebYado-Winter', obj),
  /* 12月〜2月 */
  webYadoWinterMonths: (obj?: I18nArgumentType) => translation('WebYado-WinterMonths', obj),
  /* {{distance}}km 先 */
  webYadoXkmAway: (obj?: I18nArgumentType) => translation('WebYado-XkmAway', obj),
  /* 住所 */
  webYadoYadoAddress: (obj?: I18nArgumentType) => translation('WebYado-YadoAddress', obj),
  /* {{name}}の特徴 */
  webYadoYadoCharacteristics: (obj?: I18nArgumentType) =>
    translation('WebYado-YadoCharacteristics', obj),
  /* [object Object] */
  webYadoYadoFoundResults: (obj?: I18nArgumentType) => translation('WebYado-YadoFoundResults', obj),
  /* 宿泊施設について */
  webYadoYadoGeneralInformation: (obj?: I18nArgumentType) =>
    translation('WebYado-YadoGeneralInformation', obj),
  /* 旅館の概要 */
  webYadoYadoIntroduction: (obj?: I18nArgumentType) => translation('WebYado-YadoIntroduction', obj),
  /* [object Object] */
  webYadoYadoSmallestAgeToStayIs: (obj?: I18nArgumentType) =>
    translation('WebYado-YadoSmallestAgeToStayIs', obj),
  /* {{location}}のホテル・旅館・民宿 */
  webYadoYadosIn: (obj?: I18nArgumentType) => translation('WebYado-YadosIn', obj),
  /* 年 */
  webYadoYear: (obj?: I18nArgumentType) => translation('WebYado-Year', obj),
  /* はい */
  webYadoYes: (obj?: I18nArgumentType) => translation('WebYado-Yes', obj),
  /* ゆふいんホテル秀峰館 */
  webYadoYufuin: (obj?: I18nArgumentType) => translation('WebYado-Yufuin', obj),
  /* 自社牧場を持つ宿ならではの極上豊後牛をご堪能ください。 */
  webYadoYufuinDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-YufuinDescription', obj),
  /* 浴衣 */
  webYadoYukata: (obj?: I18nArgumentType) => translation('WebYado-Yukata', obj),
  /* 里湯昔話 雄山荘 */
  webYadoYuzanso: (obj?: I18nArgumentType) => translation('WebYado-Yuzanso', obj),
  /* 滋賀近江の味を存分に味わえるよう近江牛や自家菜園の野菜などを取りいれており、近江の「おいしい」をまるごといただける。 */
  webYadoYuzansoDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-YuzansoDescription', obj),
  /* {{number}}折 */
  webYadoZhe: (obj?: I18nArgumentType) => translation('WebYado-Zhe', obj),
  /* クレジットカードを登録する */
  yadoAddCreditCardLabel: (obj?: I18nArgumentType) => translation('Yado-AddCreditCardLabel', obj),
  /* [object Object] */
  yadoAdultCountLabel: (obj?: I18nArgumentType) => translation('Yado-AdultCountLabel', obj),
  /* [object Object] */
  yadoAdultLabel: (obj?: I18nArgumentType) => translation('Yado-AdultLabel', obj),
  /* [object Object] */
  yadoAdultWithNumLabel: (obj?: I18nArgumentType) => translation('Yado-AdultWithNumLabel', obj),
  /* 手配旅行約款 */
  yadoArrangedTravelAgreement: (obj?: I18nArgumentType) =>
    translation('Yado-ArrangedTravelAgreement', obj),
  /* 取引時の重要条件説明事項（手配旅行） */
  yadoArrangedTravelCondition: (obj?: I18nArgumentType) =>
    translation('Yado-ArrangedTravelCondition', obj),
  /* 風呂 */
  yadoBath: (obj?: I18nArgumentType) => translation('Yado-Bath', obj),
  /* 展望風呂 */
  yadoBathFeatureFineView: (obj?: I18nArgumentType) => translation('Yado-BathFeatureFineView', obj),
  /* 温泉 */
  yadoBathFeatureHotSpring: (obj?: I18nArgumentType) =>
    translation('Yado-BathFeatureHotSpring', obj),
  /* 内湯 */
  yadoBathFeatureIndoor: (obj?: I18nArgumentType) => translation('Yado-BathFeatureIndoor', obj),
  /* ジャグジー */
  yadoBathFeatureJacuzzi: (obj?: I18nArgumentType) => translation('Yado-BathFeatureJacuzzi', obj),
  /* 天然温泉掛け流し */
  yadoBathFeatureNaturalHotSpring: (obj?: I18nArgumentType) =>
    translation('Yado-BathFeatureNaturalHotSpring', obj),
  /* 露天風呂 */
  yadoBathFeatureOpenAir: (obj?: I18nArgumentType) => translation('Yado-BathFeatureOpenAir', obj),
  /* 貸切風呂 */
  yadoBathFeaturePrivate: (obj?: I18nArgumentType) => translation('Yado-BathFeaturePrivate', obj),
  /* サウナ */
  yadoBathFeatureSauna: (obj?: I18nArgumentType) => translation('Yado-BathFeatureSauna', obj),
  /* 最安値保証 */
  yadoBestRateGuarantee: (obj?: I18nArgumentType) => translation('Yado-BestRateGuarantee', obj),
  /* キャンセル済み */
  yadoBookingAlreadyCanceled: (obj?: I18nArgumentType) =>
    translation('Yado-BookingAlreadyCanceled', obj),
  /* 予約をキャンセルする */
  yadoBookingCancel: (obj?: I18nArgumentType) => translation('Yado-BookingCancel', obj),
  /* 予約をキャンセルしますか？ */
  yadoBookingCancelConfirmMessage: (obj?: I18nArgumentType) =>
    translation('Yado-BookingCancelConfirmMessage', obj),
  /* 予約をキャンセルできませんでした */
  yadoBookingCancelFailMessage: (obj?: I18nArgumentType) =>
    translation('Yado-BookingCancelFailMessage', obj),
  /* 目的地が変更になった */
  yadoBookingCancelInputReasonChangeDistination: (obj?: I18nArgumentType) =>
    translation('Yado-BookingCancelInputReasonChangeDistination', obj),
  /* 日程が変更になった */
  yadoBookingCancelInputReasonChangeSchedule: (obj?: I18nArgumentType) =>
    translation('Yado-BookingCancelInputReasonChangeSchedule', obj),
  /* もともとたくさん取っていた中で１つに決めた */
  yadoBookingCancelInputReasonDecidedOne: (obj?: I18nArgumentType) =>
    translation('Yado-BookingCancelInputReasonDecidedOne', obj),
  /* よろしければキャンセルの理由を選択してください */
  yadoBookingCancelInputReasonDescription: (obj?: I18nArgumentType) =>
    translation('Yado-BookingCancelInputReasonDescription', obj),
  /* よい条件のホテルが他のサービスで見つかった */
  yadoBookingCancelInputReasonGoodConditionsWithOthers: (obj?: I18nArgumentType) =>
    translation('Yado-BookingCancelInputReasonGoodConditionsWithOthers', obj),
  /* よい条件のホテルがWAmazing内で見つかった */
  yadoBookingCancelInputReasonGoodConditionsWithWAmazing: (obj?: I18nArgumentType) =>
    translation('Yado-BookingCancelInputReasonGoodConditionsWithWAmazing', obj),
  /* 同じホテルが他のサービスでより安い価格で見つかった */
  yadoBookingCancelInputReasonLowerPriceSomeHotel: (obj?: I18nArgumentType) =>
    translation('Yado-BookingCancelInputReasonLowerPriceSomeHotel', obj),
  /* その他 */
  yadoBookingCancelInputReasonOthers: (obj?: I18nArgumentType) =>
    translation('Yado-BookingCancelInputReasonOthers', obj),
  /* ご入力ありがとうございました。 */
  yadoBookingCancelInputReasonPickerComplatedMessage: (obj?: I18nArgumentType) =>
    translation('Yado-BookingCancelInputReasonPickerComplatedMessage', obj),
  /* キャンセル理由 */
  yadoBookingCancelInputReasonPickerLabel: (obj?: I18nArgumentType) =>
    translation('Yado-BookingCancelInputReasonPickerLabel', obj),
  /* 選択してください */
  yadoBookingCancelInputReasonPickerPlaceHolder: (obj?: I18nArgumentType) =>
    translation('Yado-BookingCancelInputReasonPickerPlaceHolder', obj),
  /* 旅行がなくなった */
  yadoBookingCancelInputReasonTripGone: (obj?: I18nArgumentType) =>
    translation('Yado-BookingCancelInputReasonTripGone', obj),
  /* 予約をキャンセルしました */
  yadoBookingCancelSucceedMessage: (obj?: I18nArgumentType) =>
    translation('Yado-BookingCancelSucceedMessage', obj),
  /* キャンセルポリシー */
  yadoBookingCancellationPolicySectionTitle: (obj?: I18nArgumentType) =>
    translation('Yado-BookingCancellationPolicySectionTitle', obj),
  /* チェックイン時刻: {{time}} */
  yadoBookingCheckinTime: (obj?: I18nArgumentType) => translation('Yado-BookingCheckinTime', obj),
  /* ホームに戻る */
  yadoBookingCompleteBackToHome: (obj?: I18nArgumentType) =>
    translation('Yado-BookingCompleteBackToHome', obj),
  /* 予約内容 */
  yadoBookingCompleteBookingContent: (obj?: I18nArgumentType) =>
    translation('Yado-BookingCompleteBookingContent', obj),
  /* 今は申し込まない */
  yadoBookingCompleteSimActionDoNotApply: (obj?: I18nArgumentType) =>
    translation('Yado-BookingCompleteSimActionDoNotApply', obj),
  /* 申込する */
  yadoBookingCompleteSimApplicationButtonTitle: (obj?: I18nArgumentType) =>
    translation('Yado-BookingCompleteSimApplicationButtonTitle', obj),
  /* 無料SIMカードをお申し込みください
お申し込みされたSIMカードに{{capacity}}が付与されます。 */
  yadoBookingCompleteSimApplicationMessage: (obj?: I18nArgumentType) =>
    translation('Yado-BookingCompleteSimApplicationMessage', obj),
  /* 無料SIMカードをお申し込みください */
  yadoBookingCompleteSimApplicationTitle: (obj?: I18nArgumentType) =>
    translation('Yado-BookingCompleteSimApplicationTitle', obj),
  /* この予約で{{capacity}}を獲得しました */
  yadoBookingCompleteSimPresentCongratsMessage: (obj?: I18nArgumentType) =>
    translation('Yado-BookingCompleteSimPresentCongratsMessage', obj),
  /* おめでとうございます！ */
  yadoBookingCompleteSimPresentCongratsTitle: (obj?: I18nArgumentType) =>
    translation('Yado-BookingCompleteSimPresentCongratsTitle', obj),
  /* 予約特典として{{capacity}}のSIMデータ容量が追加されました。現在のデータ容量はアプリホームでご確認ください。 */
  yadoBookingCompleteSimPresentNotice: (obj?: I18nArgumentType) =>
    translation('Yado-BookingCompleteSimPresentNotice', obj),
  /* 登録されているメールアドレスに予約の詳細を送信しました。
プリントアウトして当日ご持参ください。
アプリのホームからも、左上メニューからいつでも予約内容をご確認いただけます。 */
  yadoBookingCompleteThanksMessage: (obj?: I18nArgumentType) =>
    translation('Yado-BookingCompleteThanksMessage', obj),
  /* ご予約ありがとうございます */
  yadoBookingCompleteThanksTitle: (obj?: I18nArgumentType) =>
    translation('Yado-BookingCompleteThanksTitle', obj),
  /* 予約完了 */
  yadoBookingCompleteTitle: (obj?: I18nArgumentType) =>
    translation('Yado-BookingCompleteTitle', obj),
  /* 予約を確認する */
  yadoBookingConfirmReservation: (obj?: I18nArgumentType) =>
    translation('Yado-BookingConfirmReservation', obj),
  /* こちらのクレジットカードからご宿泊金額を引き落としさせていただきます */
  yadoBookingCreditCardDescription: (obj?: I18nArgumentType) =>
    translation('Yado-BookingCreditCardDescription', obj),
  /* お客様情報 */
  yadoBookingCustomerInformationSectionTitle: (obj?: I18nArgumentType) =>
    translation('Yado-BookingCustomerInformationSectionTitle', obj),
  /* 宿の情報 */
  yadoBookingDefaultHeaderTitle: (obj?: I18nArgumentType) =>
    translation('Yado-BookingDefaultHeaderTitle', obj),
  /* チェックイン時刻 */
  yadoBookingFormCheckinTimeLabel: (obj?: I18nArgumentType) =>
    translation('Yado-BookingFormCheckinTimeLabel', obj),
  /* 名（英語） */
  yadoBookingFormFirstNameLabel: (obj?: I18nArgumentType) =>
    translation('Yado-BookingFormFirstNameLabel', obj),
  /* 姓（英語） */
  yadoBookingFormLastNameLabel: (obj?: I18nArgumentType) =>
    translation('Yado-BookingFormLastNameLabel', obj),
  /* お客様情報を入力してください */
  yadoBookingFormSectionTitle: (obj?: I18nArgumentType) =>
    translation('Yado-BookingFormSectionTitle', obj),
  /* 現在、宿のご予約はありません。 */
  yadoBookingIndexEmpty: (obj?: I18nArgumentType) => translation('Yado-BookingIndexEmpty', obj),
  /* ご予約の宿を取得しています */
  yadoBookingIndexLoading: (obj?: I18nArgumentType) => translation('Yado-BookingIndexLoading', obj),
  /* 予約済み宿一覧 */
  yadoBookingIndexTitle: (obj?: I18nArgumentType) => translation('Yado-BookingIndexTitle', obj),
  /* 予約ID */
  yadoBookingInqueryIdLabel: (obj?: I18nArgumentType) =>
    translation('Yado-BookingInqueryIdLabel', obj),
  /* 残り {{leftRooms}} 部屋 */
  yadoBookingLeftRoomsBodyText: (obj?: I18nArgumentType) =>
    translation('Yado-BookingLeftRoomsBodyText', obj),
  /* 予約にはクレジットカードの登録が必要です */
  yadoBookingNoCreditCardTitle: (obj?: I18nArgumentType) =>
    translation('Yado-BookingNoCreditCardTitle', obj),
  /* サービス料 */
  yadoBookingPriceServiceCharge: (obj?: I18nArgumentType) =>
    translation('Yado-BookingPriceServiceCharge', obj),
  /* 消費税 */
  yadoBookingPriceTaxLabel: (obj?: I18nArgumentType) =>
    translation('Yado-BookingPriceTaxLabel', obj),
  /* ホテルの予約にはユーザー登録またはログインが必要です。 */
  yadoBookingRequirementNote: (obj?: I18nArgumentType) =>
    translation('Yado-BookingRequirementNote', obj),
  /* 宿泊日: {{from}} ~ {{to}} */
  yadoBookingStayingDates: (obj?: I18nArgumentType) => translation('Yado-BookingStayingDates', obj),
  /* 申し訳ありません、エラーが発生しました。もう一度プランを選択ください。 */
  yadoBookingSubmitFailedMessage: (obj?: I18nArgumentType) =>
    translation('Yado-BookingSubmitFailedMessage', obj),
  /* 合計料金が変更されたので予約を完了できませんでした。
プラン情報を更新します。 */
  yadoBookingTotalAmountChangedMessage: (obj?: I18nArgumentType) =>
    translation('Yado-BookingTotalAmountChangedMessage', obj),
  /* 朝食 */
  yadoBreakfast: (obj?: I18nArgumentType) => translation('Yado-Breakfast', obj),
  /* 朝食・夕食あり */
  yadoBreakfastAndDinnerIncluded: (obj?: I18nArgumentType) =>
    translation('Yado-BreakfastAndDinnerIncluded', obj),
  /* 朝食あり */
  yadoBreakfastIncluded: (obj?: I18nArgumentType) => translation('Yado-BreakfastIncluded', obj),
  /* 閲覧した宿がありません */
  yadoBrowsingListItemNothing: (obj?: I18nArgumentType) =>
    translation('Yado-BrowsingListItemNothing', obj),
  /* 宿泊日程 */
  yadoCalendarHeaderLabel: (obj?: I18nArgumentType) => translation('Yado-CalendarHeaderLabel', obj),
  /* 泊 */
  yadoCalendarNightsLabel: (obj?: I18nArgumentType) => translation('Yado-CalendarNightsLabel', obj),
  /* 現在は{{max}}泊以上のご予約は承っておりません */
  yadoCalendarTooLongStayingDaysMessage: (obj?: I18nArgumentType) =>
    translation('Yado-CalendarTooLongStayingDaysMessage', obj),
  /* ビジネスホテル */
  yadoCategoryBusinessHotel: (obj?: I18nArgumentType) =>
    translation('Yado-CategoryBusinessHotel', obj),
  /* コンドミニアム */
  yadoCategoryCondominium: (obj?: I18nArgumentType) => translation('Yado-CategoryCondominium', obj),
  /* ゲストハウス */
  yadoCategoryGuestHouse: (obj?: I18nArgumentType) => translation('Yado-CategoryGuestHouse', obj),
  /* ホテル */
  yadoCategoryHotel: (obj?: I18nArgumentType) => translation('Yado-CategoryHotel', obj),
  /* ロッジ */
  yadoCategoryLodge: (obj?: I18nArgumentType) => translation('Yado-CategoryLodge', obj),
  /* ペンション */
  yadoCategoryPension: (obj?: I18nArgumentType) => translation('Yado-CategoryPension', obj),
  /* 公共の宿 */
  yadoCategoryPublicInn: (obj?: I18nArgumentType) => translation('Yado-CategoryPublicInn', obj),
  /* 貸別荘 */
  yadoCategoryRentalVilla: (obj?: I18nArgumentType) => translation('Yado-CategoryRentalVilla', obj),
  /* 旅館 */
  yadoCategoryRyokan: (obj?: I18nArgumentType) => translation('Yado-CategoryRyokan', obj),
  /* チェックイン */
  yadoCheckinLabel: (obj?: I18nArgumentType) => translation('Yado-CheckinLabel', obj),
  /* チェックアウト */
  yadoCheckoutLabel: (obj?: I18nArgumentType) => translation('Yado-CheckoutLabel', obj),
  /* (12歳以下) */
  yadoChildAgeDescriptionLabel: (obj?: I18nArgumentType) =>
    translation('Yado-ChildAgeDescriptionLabel', obj),
  /* [object Object] */
  yadoChildAgeLabel: (obj?: I18nArgumentType) => translation('Yado-ChildAgeLabel', obj),
  /* [object Object] */
  yadoChildCountLabel: (obj?: I18nArgumentType) => translation('Yado-ChildCountLabel', obj),
  /* 1歳以下 */
  yadoChildInfantAgeLabel: (obj?: I18nArgumentType) => translation('Yado-ChildInfantAgeLabel', obj),
  /* [object Object] */
  yadoChildrenLabel: (obj?: I18nArgumentType) => translation('Yado-ChildrenLabel', obj),
  /* [object Object] */
  yadoChildrenWithNumLabel: (obj?: I18nArgumentType) =>
    translation('Yado-ChildrenWithNumLabel', obj),
  /* 宿の検索 */
  yadoCommonTitle: (obj?: I18nArgumentType) => translation('Yado-CommonTitle', obj),
  /* 夕食 */
  yadoDinner: (obj?: I18nArgumentType) => translation('Yado-Dinner', obj),
  /* 夕食あり */
  yadoDinnerIncluded: (obj?: I18nArgumentType) => translation('Yado-DinnerIncluded', obj),
  /* 閲覧履歴 */
  yadoDotsMenuContentBrowseHistory: (obj?: I18nArgumentType) =>
    translation('Yado-DotsMenuContentBrowseHistory', obj),
  /* お気に入りリスト */
  yadoDotsMenuContentFavorite: (obj?: I18nArgumentType) =>
    translation('Yado-DotsMenuContentFavorite', obj),
  /* ※部屋タイプによってはご用意がない場合があります */
  yadoFacilitiesDescriptionEquipment: (obj?: I18nArgumentType) =>
    translation('Yado-FacilitiesDescriptionEquipment', obj),
  /* 無料 */
  yadoFacilitiesFree: (obj?: I18nArgumentType) => translation('Yado-FacilitiesFree', obj),
  /* アメニティ */
  yadoFacilitiesSectionAmenity: (obj?: I18nArgumentType) =>
    translation('Yado-FacilitiesSectionAmenity', obj),
  /* お部屋の設備 */
  yadoFacilitiesSectionEquipment: (obj?: I18nArgumentType) =>
    translation('Yado-FacilitiesSectionEquipment', obj),
  /* サービス */
  yadoFacilitiesSectionService: (obj?: I18nArgumentType) =>
    translation('Yado-FacilitiesSectionService', obj),
  /* お部屋の情報 */
  yadoFacilitiesTitle: (obj?: I18nArgumentType) => translation('Yado-FacilitiesTitle', obj),
  /* 風呂あり */
  yadoFacilitiesWithBath: (obj?: I18nArgumentType) => translation('Yado-FacilitiesWithBath', obj),
  /* 露天風呂あり */
  yadoFacilitiesWithOpenAirBath: (obj?: I18nArgumentType) =>
    translation('Yado-FacilitiesWithOpenAirBath', obj),
  /* エステ */
  yadoFacilityBeautySalon: (obj?: I18nArgumentType) => translation('Yado-FacilityBeautySalon', obj),
  /* 無料駐車場 */
  yadoFacilityFreeParkingLot: (obj?: I18nArgumentType) =>
    translation('Yado-FacilityFreeParkingLot', obj),
  /* マッサージ */
  yadoFacilityMassage: (obj?: I18nArgumentType) => translation('Yado-FacilityMassage', obj),
  /* 両替 */
  yadoFacilityMoneyExchange: (obj?: I18nArgumentType) =>
    translation('Yado-FacilityMoneyExchange', obj),
  /* 屋外プール */
  yadoFacilityOutdoorPool: (obj?: I18nArgumentType) => translation('Yado-FacilityOutdoorPool', obj),
  /* 貸しスキー */
  yadoFacilitySkiRental: (obj?: I18nArgumentType) => translation('Yado-FacilitySkiRental', obj),
  /* 貸しスノーボード */
  yadoFacilitySnowboardRental: (obj?: I18nArgumentType) =>
    translation('Yado-FacilitySnowboardRental', obj),
  /* お気に入り */
  yadoFavoriteListCommonTitle: (obj?: I18nArgumentType) =>
    translation('Yado-FavoriteListCommonTitle', obj),
  /* お気に入りの宿がありません */
  yadoFavoriteListItemNothing: (obj?: I18nArgumentType) =>
    translation('Yado-FavoriteListItemNothing', obj),
  /* お気に入りを地図で表示 */
  yadoFavoriteListMapButton: (obj?: I18nArgumentType) =>
    translation('Yado-FavoriteListMapButton', obj),
  /* 総合評価が8.0以上 */
  yadoFeatureHighlyEvaluated: (obj?: I18nArgumentType) =>
    translation('Yado-FeatureHighlyEvaluated', obj),
  /* 11時以降のチェックアウトが可能 */
  yadoFeatureLateCheckout: (obj?: I18nArgumentType) => translation('Yado-FeatureLateCheckout', obj),
  /* 送迎あり */
  yadoFeaturePickUp: (obj?: I18nArgumentType) => translation('Yado-FeaturePickUp', obj),
  /* 女性 */
  yadoFemaleLabel: (obj?: I18nArgumentType) => translation('Yado-FemaleLabel', obj),
  /* 有 */
  yadoIncluded: (obj?: I18nArgumentType) => translation('Yado-Included', obj),
  /* おすすめ宿の取得に失敗しました。 */
  yadoIndexRecommendedYadoSearchFailed: (obj?: I18nArgumentType) =>
    translation('Yado-IndexRecommendedYadoSearchFailed', obj),
  /* ご指定の日程・部屋数でおすすめの宿を
予約することはできません。
検索条件を変えてお試しください。 */
  yadoIndexRecommendedYadoSearchNoResult: (obj?: I18nArgumentType) =>
    translation('Yado-IndexRecommendedYadoSearchNoResult', obj),
  /* ※キャンセルポリシーについてはお部屋の詳細情報をご確認ください */
  yadoListItemFreeCancellationDescription: (obj?: I18nArgumentType) =>
    translation('Yado-ListItemFreeCancellationDescription', obj),
  /* キャンセル無料 */
  yadoListItemFreeCancellationLabel: (obj?: I18nArgumentType) =>
    translation('Yado-ListItemFreeCancellationLabel', obj),
  /* 距離が近い順 */
  yadoListSortOrderDistanceAsc: (obj?: I18nArgumentType) =>
    translation('Yado-ListSortOrderDistanceAsc', obj),
  /* 値段が安い順 */
  yadoListSortOrderPriceAsc: (obj?: I18nArgumentType) =>
    translation('Yado-ListSortOrderPriceAsc', obj),
  /* 値段が高い順 */
  yadoListSortOrderPriceDesc: (obj?: I18nArgumentType) =>
    translation('Yado-ListSortOrderPriceDesc', obj),
  /* 評価が高い順 */
  yadoListSortOrderRatingDesc: (obj?: I18nArgumentType) =>
    translation('Yado-ListSortOrderRatingDesc', obj),
  /* 関連度の高い順 */
  yadoListSortOrderScoreDesc: (obj?: I18nArgumentType) =>
    translation('Yado-ListSortOrderScoreDesc', obj),
  /* 地図を表示するためのアプリが見つかりませんでした */
  yadoLocationOpenWithMapErrorMessage: (obj?: I18nArgumentType) =>
    translation('Yado-LocationOpenWithMapErrorMessage', obj),
  /* 予約する */
  yadoMakeReservationButtonTitle: (obj?: I18nArgumentType) =>
    translation('Yado-MakeReservationButtonTitle', obj),
  /* 予約を確定する */
  yadoMakeYadoBookingButtonTitle: (obj?: I18nArgumentType) =>
    translation('Yado-MakeYadoBookingButtonTitle', obj),
  /* 予約を確定しますか？ */
  yadoMakeYadoBookingConfirmAlertMessage: (obj?: I18nArgumentType) =>
    translation('Yado-MakeYadoBookingConfirmAlertMessage', obj),
  /* このボタンを押して予約を確定することで、
{{arrangedTravelCondition}}、
{{arrangedTravelAgreement}}、{{travelAgencyRegistration}}を確認、同意したことになります。 */
  yadoMakeYadoBookingNote: (obj?: I18nArgumentType) => translation('Yado-MakeYadoBookingNote', obj),
  /* 予約する */
  yadoMakeYadoBookingScrollToBookingButtonTitle: (obj?: I18nArgumentType) =>
    translation('Yado-MakeYadoBookingScrollToBookingButtonTitle', obj),
  /* 【容量制限について】10GBを超えた場合は低速でのご利用になります。 */
  yadoMakeYadoBookingSimRestrictionNote: (obj?: I18nArgumentType) =>
    translation('Yado-MakeYadoBookingSimRestrictionNote', obj),
  /* 男性 */
  yadoMaleLabel: (obj?: I18nArgumentType) => translation('Yado-MaleLabel', obj),
  /* クレジットカードを変更する */
  yadoManageCreditCardButtonTitle: (obj?: I18nArgumentType) =>
    translation('Yado-ManageCreditCardButtonTitle', obj),
  /* 朝食 */
  yadoMealBreakfast: (obj?: I18nArgumentType) => translation('Yado-MealBreakfast', obj),
  /* 夕食 */
  yadoMealDinner: (obj?: I18nArgumentType) => translation('Yado-MealDinner', obj),
  /* 個室で{{meal}} */
  yadoMealFeatureInPrivateRoom: (obj?: I18nArgumentType) =>
    translation('Yado-MealFeatureInPrivateRoom', obj),
  /* 部屋で{{meal}} */
  yadoMealFeatureInRoom: (obj?: I18nArgumentType) => translation('Yado-MealFeatureInRoom', obj),
  /* 朝食あり */
  yadoMealTypeWithBreakfast: (obj?: I18nArgumentType) =>
    translation('Yado-MealTypeWithBreakfast', obj),
  /* 夕食あり */
  yadoMealTypeWithDinner: (obj?: I18nArgumentType) => translation('Yado-MealTypeWithDinner', obj),
  /* ビーチ */
  yadoNearbyFacilityBeach: (obj?: I18nArgumentType) => translation('Yado-NearbyFacilityBeach', obj),
  /* 両替所 */
  yadoNearbyFacilityMoneyExchange: (obj?: I18nArgumentType) =>
    translation('Yado-NearbyFacilityMoneyExchange', obj),
  /* 駅 */
  yadoNearbyFacilityStation: (obj?: I18nArgumentType) =>
    translation('Yado-NearbyFacilityStation', obj),
  /* 観光案内所 */
  yadoNearbyFacilityTouristInformation: (obj?: I18nArgumentType) =>
    translation('Yado-NearbyFacilityTouristInformation', obj),
  /* 周辺の人気スポット */
  yadoNearbyPlaceSectionTitleHotStop: (obj?: I18nArgumentType) =>
    translation('Yado-NearbyPlaceSectionTitleHotStop', obj),
  /* アミューズメント */
  yadoNearbyPlaceTypeStringAmusementPark: (obj?: I18nArgumentType) =>
    translation('Yado-NearbyPlaceTypeStringAmusementPark', obj),
  /* 水族館 */
  yadoNearbyPlaceTypeStringAquarium: (obj?: I18nArgumentType) =>
    translation('Yado-NearbyPlaceTypeStringAquarium', obj),
  /* 美術館 */
  yadoNearbyPlaceTypeStringArtGallery: (obj?: I18nArgumentType) =>
    translation('Yado-NearbyPlaceTypeStringArtGallery', obj),
  /* ベーカリー */
  yadoNearbyPlaceTypeStringBakery: (obj?: I18nArgumentType) =>
    translation('Yado-NearbyPlaceTypeStringBakery', obj),
  /* バー */
  yadoNearbyPlaceTypeStringBar: (obj?: I18nArgumentType) =>
    translation('Yado-NearbyPlaceTypeStringBar', obj),
  /* カフェ */
  yadoNearbyPlaceTypeStringCafe: (obj?: I18nArgumentType) =>
    translation('Yado-NearbyPlaceTypeStringCafe', obj),
  /* コンビニ */
  yadoNearbyPlaceTypeStringConvenienceStore: (obj?: I18nArgumentType) =>
    translation('Yado-NearbyPlaceTypeStringConvenienceStore', obj),
  /* デパート */
  yadoNearbyPlaceTypeStringDepartmentStore: (obj?: I18nArgumentType) =>
    translation('Yado-NearbyPlaceTypeStringDepartmentStore', obj),
  /* 寺 */
  yadoNearbyPlaceTypeStringHinduTemple: (obj?: I18nArgumentType) =>
    translation('Yado-NearbyPlaceTypeStringHinduTemple', obj),
  /* 酒店 */
  yadoNearbyPlaceTypeStringLiquorStore: (obj?: I18nArgumentType) =>
    translation('Yado-NearbyPlaceTypeStringLiquorStore', obj),
  /* 映画館 */
  yadoNearbyPlaceTypeStringMovieTheater: (obj?: I18nArgumentType) =>
    translation('Yado-NearbyPlaceTypeStringMovieTheater', obj),
  /* ミュージアム */
  yadoNearbyPlaceTypeStringMuseum: (obj?: I18nArgumentType) =>
    translation('Yado-NearbyPlaceTypeStringMuseum', obj),
  /* クラブ */
  yadoNearbyPlaceTypeStringNightClub: (obj?: I18nArgumentType) =>
    translation('Yado-NearbyPlaceTypeStringNightClub', obj),
  /* レストラン */
  yadoNearbyPlaceTypeStringRestaurant: (obj?: I18nArgumentType) =>
    translation('Yado-NearbyPlaceTypeStringRestaurant', obj),
  /* ショッピングモール */
  yadoNearbyPlaceTypeStringShoppingMall: (obj?: I18nArgumentType) =>
    translation('Yado-NearbyPlaceTypeStringShoppingMall', obj),
  /* スタジアム */
  yadoNearbyPlaceTypeStringStadium: (obj?: I18nArgumentType) =>
    translation('Yado-NearbyPlaceTypeStringStadium', obj),
  /* 動物園 */
  yadoNearbyPlaceTypeStringZoo: (obj?: I18nArgumentType) =>
    translation('Yado-NearbyPlaceTypeStringZoo', obj),
  /* 禁煙 */
  yadoNonSmokingRoom: (obj?: I18nArgumentType) => translation('Yado-NonSmokingRoom', obj),
  /* 無 */
  yadoNotIncluded: (obj?: I18nArgumentType) => translation('Yado-NotIncluded', obj),
  /* [object Object] */
  yadoNumPeopleSuffix: (obj?: I18nArgumentType) => translation('Yado-NumPeopleSuffix', obj),
  /* Popular onsen areas */
  yadoOnsenAreaListTitle: (obj?: I18nArgumentType) => translation('Yado-OnsenAreaListTitle', obj),
  /* Find onsen hotels now */
  yadoOnsenBannerGotoOnsen: (obj?: I18nArgumentType) =>
    translation('Yado-OnsenBannerGotoOnsen', obj),
  /* Now only! Reserving onsen hotels by */
  yadoOnsenBannerTitle: (obj?: I18nArgumentType) => translation('Yado-OnsenBannerTitle', obj),
  /* Featured Beef */
  yadoOnsenFeaturedBeef: (obj?: I18nArgumentType) => translation('Yado-OnsenFeaturedBeef', obj),
  /* Featured Crab */
  yadoOnsenFeaturedCrab: (obj?: I18nArgumentType) => translation('Yado-OnsenFeaturedCrab', obj),
  /* Featured List */
  yadoOnsenFeaturedListTitle: (obj?: I18nArgumentType) =>
    translation('Yado-OnsenFeaturedListTitle', obj),
  /* Featured Private Bath */
  yadoOnsenFeaturedPrivateBath: (obj?: I18nArgumentType) =>
    translation('Yado-OnsenFeaturedPrivateBath', obj),
  /* Featured Scenery */
  yadoOnsenFeaturedScenery: (obj?: I18nArgumentType) =>
    translation('Yado-OnsenFeaturedScenery', obj),
  /* 露天風呂 */
  yadoOpenAirBath: (obj?: I18nArgumentType) => translation('Yado-OpenAirBath', obj),
  /* 客室サイズ {{size}}㎡ */
  yadoPlanAboutDescriptionRoomSizeWithUnit: (obj?: I18nArgumentType) =>
    translation('Yado-PlanAboutDescriptionRoomSizeWithUnit', obj),
  /* アクセス */
  yadoPlanAccessMapLabel: (obj?: I18nArgumentType) => translation('Yado-PlanAccessMapLabel', obj),
  /* {{size}}㎡ */
  yadoPlanRoomSizeWithUnit: (obj?: I18nArgumentType) =>
    translation('Yado-PlanRoomSizeWithUnit', obj),
  /* このプランを予約すると{{capacity}}のデータ通信容量がもらえる! */
  yadoPlanSimOfferMessage: (obj?: I18nArgumentType) => translation('Yado-PlanSimOfferMessage', obj),
  /* 部屋の詳細情報をロードしています */
  yadoPlanWaitLoadingPlanDetailLabel: (obj?: I18nArgumentType) =>
    translation('Yado-PlanWaitLoadingPlanDetailLabel', obj),
  /* 予約可能な部屋を検索中です */
  yadoPlanWaitLoadingPlanLabel: (obj?: I18nArgumentType) =>
    translation('Yado-PlanWaitLoadingPlanLabel', obj),
  /* 予約可能な部屋 */
  yadoPlansSectionTitle: (obj?: I18nArgumentType) => translation('Yado-PlansSectionTitle', obj),
  /* 通信し放題! */
  yadoPresentDataInfinity: (obj?: I18nArgumentType) => translation('Yado-PresentDataInfinity', obj),
  /* 料金 */
  yadoPriceHeader: (obj?: I18nArgumentType) => translation('Yado-PriceHeader', obj),
  /* 合計 */
  yadoPriceTotal: (obj?: I18nArgumentType) => translation('Yado-PriceTotal', obj),
  /* 風呂 {{rate}} */
  yadoRatingBath: (obj?: I18nArgumentType) => translation('Yado-RatingBath', obj),
  /* 朝食 {{rate}} */
  yadoRatingBreakfast: (obj?: I18nArgumentType) => translation('Yado-RatingBreakfast', obj),
  /* 清潔度 {{rate}} */
  yadoRatingCleanliness: (obj?: I18nArgumentType) => translation('Yado-RatingCleanliness', obj),
  /* 夕食 {{rate}} */
  yadoRatingDinner: (obj?: I18nArgumentType) => translation('Yado-RatingDinner', obj),
  /* 客室 {{rate}} */
  yadoRatingRoom: (obj?: I18nArgumentType) => translation('Yado-RatingRoom', obj),
  /* 接客 {{rate}} */
  yadoRatingService: (obj?: I18nArgumentType) => translation('Yado-RatingService', obj),
  /* 総合評価 */
  yadoRatingTotal: (obj?: I18nArgumentType) => translation('Yado-RatingTotal', obj),
  /* 人気のホテル */
  yadoRecommendationTitle: (obj?: I18nArgumentType) => translation('Yado-RecommendationTitle', obj),
  /* 通信量が使い放題に!! */
  yadoRecommendationTitleInInfinityCampaignEmphasized: (obj?: I18nArgumentType) =>
    translation('Yado-RecommendationTitleInInfinityCampaignEmphasized', obj),
  /* ホテル予約で */
  yadoRecommendationTitleInInfinityCampaignPrefix: (obj?: I18nArgumentType) =>
    translation('Yado-RecommendationTitleInInfinityCampaignPrefix', obj),
  /* 部屋の追加 */
  yadoRoomAppendLabel: (obj?: I18nArgumentType) => translation('Yado-RoomAppendLabel', obj),
  /* [object Object] */
  yadoRoomCountLabel: (obj?: I18nArgumentType) => translation('Yado-RoomCountLabel', obj),
  /* 削除 */
  yadoRoomDeleteLabel: (obj?: I18nArgumentType) => translation('Yado-RoomDeleteLabel', obj),
  /* インターネット接続可能 */
  yadoRoomFeatureInternet: (obj?: I18nArgumentType) => translation('Yado-RoomFeatureInternet', obj),
  /* 禁煙ルーム */
  yadoRoomFeatureNonSmoking: (obj?: I18nArgumentType) =>
    translation('Yado-RoomFeatureNonSmoking', obj),
  /* [object Object] */
  yadoRoomLabel: (obj?: I18nArgumentType) => translation('Yado-RoomLabel', obj),
  /* [object Object] */
  yadoRoomNumLabel: (obj?: I18nArgumentType) => translation('Yado-RoomNumLabel', obj),
  /* 決定 */
  yadoRoomSubmitLabel: (obj?: I18nArgumentType) => translation('Yado-RoomSubmitLabel', obj),
  /* ダブル */
  yadoRoomTypeDouble: (obj?: I18nArgumentType) => translation('Yado-RoomTypeDouble', obj),
  /* 4ベッド */
  yadoRoomTypeFourBeds: (obj?: I18nArgumentType) => translation('Yado-RoomTypeFourBeds', obj),
  /* シングル */
  yadoRoomTypeSingle: (obj?: I18nArgumentType) => translation('Yado-RoomTypeSingle', obj),
  /* トリプル */
  yadoRoomTypeTriple: (obj?: I18nArgumentType) => translation('Yado-RoomTypeTriple', obj),
  /* ツイン */
  yadoRoomTypeTwin: (obj?: I18nArgumentType) => translation('Yado-RoomTypeTwin', obj),
  /* 和室 */
  yadoRoomTypeWashitsu: (obj?: I18nArgumentType) => translation('Yado-RoomTypeWashitsu', obj),
  /* 和洋室 */
  yadoRoomTypeWayoshitsu: (obj?: I18nArgumentType) => translation('Yado-RoomTypeWayoshitsu', obj),
  /* 客室選択 */
  yadoScrollToSelectRoomsButtonTitle: (obj?: I18nArgumentType) =>
    translation('Yado-ScrollToSelectRoomsButtonTitle', obj),
  /* {{minPrice}} 〜 {{maxPrice}} を選択中 */
  yadoSearchConditionPriceRange: (obj?: I18nArgumentType) =>
    translation('Yado-SearchConditionPriceRange', obj),
  /* ホテル名または場所を入力 */
  yadoSearchInputPlaceholder: (obj?: I18nArgumentType) =>
    translation('Yado-SearchInputPlaceholder', obj),
  /* 条件にマッチする宿が見つかりませんでした */
  yadoSearchResultNotFound: (obj?: I18nArgumentType) =>
    translation('Yado-SearchResultNotFound', obj),
  /* おトクなホテルを探す */
  yadoSearchSubmitButton: (obj?: I18nArgumentType) => translation('Yado-SearchSubmitButton', obj),
  /* ベッド */
  yadoServiceBedLabel: (obj?: I18nArgumentType) => translation('Yado-ServiceBedLabel', obj),
  /* 最大人数 */
  yadoServiceCapacityLabel: (obj?: I18nArgumentType) =>
    translation('Yado-ServiceCapacityLabel', obj),
  /* 朝 */
  yadoServiceMealBreakfastShort: (obj?: I18nArgumentType) =>
    translation('Yado-ServiceMealBreakfastShort', obj),
  /* 夕 */
  yadoServiceMealDinnerShort: (obj?: I18nArgumentType) =>
    translation('Yado-ServiceMealDinnerShort', obj),
  /* 食事 */
  yadoServiceMealLabel: (obj?: I18nArgumentType) => translation('Yado-ServiceMealLabel', obj),
  /* 禁煙 */
  yadoServiceSmokingForbidden: (obj?: I18nArgumentType) =>
    translation('Yado-ServiceSmokingForbidden', obj),
  /* たばこ */
  yadoServiceSmokingLabel: (obj?: I18nArgumentType) => translation('Yado-ServiceSmokingLabel', obj),
  /* 喫煙可 */
  yadoServiceSmokingPermitted: (obj?: I18nArgumentType) =>
    translation('Yado-ServiceSmokingPermitted', obj),
  /* Wi-Fi */
  yadoServiceWifiLabel: (obj?: I18nArgumentType) => translation('Yado-ServiceWifiLabel', obj),
  /* 宿の情報 */
  yadoShowDefaultHeaderTitle: (obj?: I18nArgumentType) =>
    translation('Yado-ShowDefaultHeaderTitle', obj),
  /* お気に入り解除 */
  yadoShowFavoriteMenuCancelLabel: (obj?: I18nArgumentType) =>
    translation('Yado-ShowFavoriteMenuCancelLabel', obj),
  /* {{capacity}} 無料 */
  yadoSimFreeCapacity: (obj?: I18nArgumentType) => translation('Yado-SimFreeCapacity', obj),
  /* 予約すると{{capacity}}のデータ通信容量がもらえる! */
  yadoSimOfferMessage: (obj?: I18nArgumentType) => translation('Yado-SimOfferMessage', obj),
  /* 消費税・サービス料別 */
  yadoTaxAndServiceRateExcluded: (obj?: I18nArgumentType) =>
    translation('Yado-TaxAndServiceRateExcluded', obj),
  /* 大人 {{adults}} 子供 {{children}} */
  yadoTotalGuestLabel: (obj?: I18nArgumentType) => translation('Yado-TotalGuestLabel', obj),
  /* 合計{{totalStaying}}泊 */
  yadoTotalStayingPricingLabel: (obj?: I18nArgumentType) =>
    translation('Yado-TotalStayingPricingLabel', obj),
  /* 旅行業登録票 */
  yadoTravelAgencyRegistration: (obj?: I18nArgumentType) =>
    translation('Yado-TravelAgencyRegistration', obj),
  /* {{meal}} 付き */
  yadoWithMealSuffix: (obj?: I18nArgumentType) => translation('Yado-WithMealSuffix', obj),
  /* 無制限SIM */
  yadoSearchListItemPresentInfinity: (obj?: I18nArgumentType) =>
    translation('YadoSearch-ListItemPresentInfinity', obj),
  /* マップ */
  yadoSearchMap: (obj?: I18nArgumentType) => translation('YadoSearch-Map', obj),
  /* 検索結果がありません。 */
  yadoSearchNoSearchSuggestions: (obj?: I18nArgumentType) =>
    translation('YadoSearch-NoSearchSuggestions', obj),
  /* この日は満室です */
  yadoSearchNoStock: (obj?: I18nArgumentType) => translation('YadoSearch-NoStock', obj),
  /* [object Object] */
  yadoSearchPeopleNumber: (obj?: I18nArgumentType) => translation('YadoSearch-PeopleNumber', obj),
  /* 場所名 */
  yadoSearchSearchBoxPlaceholder: (obj?: I18nArgumentType) =>
    translation('YadoSearch-SearchBoxPlaceholder', obj),
  /* 目的地周辺 */
  yadoSearchSectionHeaderDestination: (obj?: I18nArgumentType) =>
    translation('YadoSearch-SectionHeaderDestination', obj),
  /* 最近の検索履歴 */
  yadoSearchSectionHeaderHistory: (obj?: I18nArgumentType) =>
    translation('YadoSearch-SectionHeaderHistory', obj),
  /* 人気のスポット */
  yadoSearchSectionHeaderPopular: (obj?: I18nArgumentType) =>
    translation('YadoSearch-SectionHeaderPopular', obj),
  /* {{input}} */
  yadoSearchSectionHeaderSearch: (obj?: I18nArgumentType) =>
    translation('YadoSearch-SectionHeaderSearch', obj),
  /* HOT */
  yadoSearchSectionHeaderSuffixHot: (obj?: I18nArgumentType) =>
    translation('YadoSearch-SectionHeaderSuffixHot', obj),
  /* [object Object] */
  yadoSearchStayingNight: (obj?: I18nArgumentType) => translation('YadoSearch-StayingNight', obj),
  /* キャンセル */
  yadoSearchYadoSearchCancel: (obj?: I18nArgumentType) =>
    translation('YadoSearch-YadoSearchCancel', obj),
  /* 検索に失敗しました */
  yadoSearchYadoSearchFailedMessage: (obj?: I18nArgumentType) =>
    translation('YadoSearch-YadoSearchFailedMessage', obj),
  /* 再試行 */
  yadoSearchYadoSearchRetry: (obj?: I18nArgumentType) =>
    translation('YadoSearch-YadoSearchRetry', obj),
  /* M.D (dd)  */
  yadoSearchCheckInCheckOutDate: (obj?: I18nArgumentType) =>
    translation('YadoSearch-checkInCheckOutDate', obj),
  /* YYYY.M.D (dd) */
  yadoSearchCheckInCheckOutDateWithYear: (obj?: I18nArgumentType) =>
    translation('YadoSearch-checkInCheckOutDateWithYear', obj),
  /* 無料SIM */
  yataiFreeSIMBarButtonItemTitle: (obj?: I18nArgumentType) =>
    translation('Yatai-FreeSIMBarButtonItemTitle', obj),
  /* データ購入 */
  yataiPurchaseCapacityBarButtonItemTitle: (obj?: I18nArgumentType) =>
    translation('Yatai-PurchaseCapacityBarButtonItemTitle', obj),
  /* お申込みが完了しました */
  yataiSimRequestCompletedPanelTitle: (obj?: I18nArgumentType) =>
    translation('Yatai-SimRequestCompletedPanelTitle', obj),
  /* 月 */
  profileEditCreditCardExpirationMonthLabelText: (obj?: I18nArgumentType) =>
    translation('profile-EditCreditCardExpirationMonthLabelText', obj),
  /* 年 */
  profileEditCreditCardExpirationYearLabelText: (obj?: I18nArgumentType) =>
    translation('profile-EditCreditCardExpirationYearLabelText', obj),
  /* チケットの受け取り場所 */
  webYadoPickupPlace: (obj?: I18nArgumentType) => translation('webYado-PickupPlace', obj),
  /* 過去にみた宿 */
  viewedYados: (obj?: I18nArgumentType) => translation('viewedYados', obj),
  /* 目的地 */
  destination: (obj?: I18nArgumentType) => translation('destination', obj),
  /* 宿泊人数 */
  numberOfGuests: (obj?: I18nArgumentType) => translation('numberOfGuests', obj),
  /* おすすめの特集 */
  recommendedFeature: (obj?: I18nArgumentType) => translation('recommendedFeature', obj),
  /* 最近売れている宿 */
  bestSellersYados: (obj?: I18nArgumentType) => translation('bestSellersYados', obj),
  /* のおすすめ宿 */
  recommendedYadosBySeason: (obj?: I18nArgumentType) =>
    translation('recommendedYadosBySeason', obj),
  /* テーマ別に探す */
  searchByTheme: (obj?: I18nArgumentType) => translation('searchByTheme', obj),
  /* エリアから選ぶ */
  searchByArea: (obj?: I18nArgumentType) => translation('searchByArea', obj),
  /* 観光地にアクセスがいい宿 */
  accessibleYados: (obj?: I18nArgumentType) => translation('accessibleYados', obj),
  /* アクティビティも楽しめる温泉地 */
  activitiesNearYados: (obj?: I18nArgumentType) => translation('activitiesNearYados', obj),
  /* 食事が自慢の宿 */
  bestDiningYados: (obj?: I18nArgumentType) => translation('bestDiningYados', obj),
  /* 写真から選ぶ */
  searchByPhoto: (obj?: I18nArgumentType) => translation('searchByPhoto', obj),
  /* 特集 */
  specialFeature: (obj?: I18nArgumentType) => translation('specialFeature', obj),
  /* 更新日 */
  updateDate: (obj?: I18nArgumentType) => translation('updateDate', obj),
  /* リージョンから選ぶ */
  searchByRegion: (obj?: I18nArgumentType) => translation('searchByRegion', obj),
  /* すべての温泉地をみる */
  viewAllOnsenAreas: (obj?: I18nArgumentType) => translation('viewAllOnsenAreas', obj),
  /* 【2022年7月5日更新】宿泊予約受付再開のお知らせ */
  announcementBannerTitle: (obj?: I18nArgumentType) => translation('announcementBannerTitle', obj),
  /* 長らく停止しておりました本ウェブサイトでの宿泊予約につきまして、2022年7月5日より予約受付を再開いたしました。現時点でお選びいただけるのは2022年10月1日以降の日付となります。それ以前のご予約は現状受け付けておりません。

なお、今後のコロナウイルス感染症の状況により10月以降の宿泊予約に影響が出る場合は、その都度カスタマーサポートよりお知らせさせていただきます。

秋や冬に日本を訪れる際の宿泊を是非WAmazingでご予約ください。
皆様からのご予約をお待ちしております。 */
  announcementBanner: (obj?: I18nArgumentType) => translation('announcementBanner', obj),
  /* 関東 */
  kantoShortName: (obj?: I18nArgumentType) => translation('kantoShortName', obj),
  /* 関西 */
  kansaiShortName: (obj?: I18nArgumentType) => translation('kansaiShortName', obj),
  /* メートル */
  meter: (obj?: I18nArgumentType) => translation('meter', obj),
  /* キロ */
  kilometer: (obj?: I18nArgumentType) => translation('kilometer', obj),
  /* 特集一覧 */
  specialFeatureViewAll: (obj?: I18nArgumentType) => translation('specialFeatureViewAll', obj),
  /* 一覧を見る */
  viewAll: (obj?: I18nArgumentType) => translation('viewAll', obj),
  /* 中国 */
  webYadoOnsenRegionChugoku: (obj?: I18nArgumentType) =>
    translation('webYadoOnsenRegionChugoku', obj),
  /* 四国 */
  webYadoOnsenRegionShikoku: (obj?: I18nArgumentType) =>
    translation('webYadoOnsenRegionShikoku', obj),
  /* 入国日(日本時間) */
  inboundDate: (obj?: I18nArgumentType) => translation('inboundDate', obj),
  /* 出国日 */
  outboundDate: (obj?: I18nArgumentType) => translation('outboundDate', obj),
  /* 入国日 */
  inboundDateShort: (obj?: I18nArgumentType) => translation('inboundDateShort', obj),
  /* 入国日を入力してください */
  errorInboundDate: (obj?: I18nArgumentType) => translation('errorInboundDate', obj),
  /* 入出国日を入力してください */
  errorImmigrationDate: (obj?: I18nArgumentType) => translation('errorImmigrationDate', obj),
  /* 入出国日 */
  immigrationDate: (obj?: I18nArgumentType) => translation('immigrationDate', obj),
  /* リセット */
  reset: (obj?: I18nArgumentType) => translation('reset', obj),
  /* https://wamazing.zendesk.com/hc/ja/requests/new */
  contactFormLink: (obj?: I18nArgumentType) => translation('contactFormLink', obj),
  /* 利用開始日の前日14:59（日本時間）までのキャンセル */
  webYadoInsuranceCancelPolicyDetail1: (obj?: I18nArgumentType) =>
    translation('webYadoInsuranceCancelPolicyDetail1', obj),
  /* 可能（無料） */
  webYadoInsuranceCancelPolicyDetail2: (obj?: I18nArgumentType) =>
    translation('webYadoInsuranceCancelPolicyDetail2', obj),
  /* 利用開始日の前日15:00（日本時間）以降のキャンセル */
  webYadoInsuranceCancelPolicyDetail3: (obj?: I18nArgumentType) =>
    translation('webYadoInsuranceCancelPolicyDetail3', obj),
  /* 不可 */
  webYadoInsuranceCancelPolicyDetail4: (obj?: I18nArgumentType) =>
    translation('webYadoInsuranceCancelPolicyDetail4', obj),
  /* クレジットカード会社の規定により、ご購入日より180日を過ぎるとお取引のキャンセルはできませんので、ご注意ください。 */
  webYadoInsuranceCancelPolicyDetail5: (obj?: I18nArgumentType) =>
    translation('webYadoInsuranceCancelPolicyDetail5', obj),
  /* 保険 */
  webYadoInsuranceLabel: (obj?: I18nArgumentType) => translation('webYadoInsuranceLabel', obj),
  /* 新コロナウィルス関連の治療・移送にも対応！24時間 365日,英語・中国語でサポート
補償上限金額：1000万円 */
  webYadoTravelInsuranceDescription: (obj?: I18nArgumentType) =>
    translation('WebYadoTravelInsuranceDescription', obj),
  /* 訪日外国人向け保険の重要事項 */
  webYadoInsuranceImportantMatters: (obj?: I18nArgumentType) =>
    translation('webYadoInsuranceImportantMatters', obj),
  /* WAmazing訪日外国人向け保険なら、24時間 365日、英語・中国語でサポート！補償上限金額：1000万円。新コロナウィルス関連の治療・移送にも対応！ */
  webYadoTravelInsuranceDetail: (obj?: I18nArgumentType) =>
    translation('WebYadoTravelInsuranceDetail', obj),
  /* {{term}}、{{privacy_policy}}、{{insurance_important_matters}}に同意した後、再度予約をして下さい。 */
  webYadoTreatedAsAgreedOnTermsWithInsurance: (obj?: I18nArgumentType) =>
    translation('webYadoTreatedAsAgreedOnTermsWithInsurance', obj),
  /* 部屋の大きさ: {{roomSize}}平米 */
  roomSizeDescription: (obj?: I18nArgumentType) => translation('roomSizeDescription', obj),
  /* 日数 */
  webYadoInsurancePricesDayCount: (obj?: I18nArgumentType) =>
    translation('WebYadoInsurancePricesDayCount', obj),
  /* 料金 */
  webYadoInsurancePricesPrice: (obj?: I18nArgumentType) =>
    translation('WebYadoInsurancePricesPrice', obj),
  /* ※表示されている日本円以外の価格は、検索時の為替レートに基づいて算出した参考価格です。日本円にて請求いたします。また、決済金額には、クレジットカード会社の海外取引手数料が含まれています。 */
  webYadoInsurancePricesTableDetail: (obj?: I18nArgumentType) =>
    translation('WebYadoInsurancePricesTableDetail', obj),
  /* 日間 */
  webYadoInsuranceDaysCount: (obj?: I18nArgumentType) =>
    translation('WebYadoInsuranceDaysCount', obj),
  /* JPY{{price}} */
  webYadoInsuranceConvertedPrice: (obj?: I18nArgumentType) =>
    translation('WebYadoInsuranceConvertedPrice', obj),
  /* 料金表 */
  webYadoInsurancePricesTitle: (obj?: I18nArgumentType) =>
    translation('WebYadoInsurancePricesTitle', obj),
  /* 料金詳しく見る */
  webYadoInsuranceFeeViewDetails: (obj?: I18nArgumentType) =>
    translation('WebYadoInsuranceFeeViewDetails', obj),
  /* 一日あたり */
  webYadoInsurancePerDay: (obj?: I18nArgumentType) => translation('WebYadoInsurancePerDay', obj),
  /* 日付の入力が間違っています */
  errorMessageIncorrectDate: (obj?: I18nArgumentType) =>
    translation('errorMessageIncorrectDate', obj),
  /* カード番号の形式が正しくありません */
  validationInvalidCreditCardNumberErrorMessage: (obj?: I18nArgumentType) =>
    translation('validationInvalidCreditCardNumberErrorMessage', obj),
  /* 所有者名の形式が正しくありません */
  validationInvalidCreditCardNameErrorMessage: (obj?: I18nArgumentType) =>
    translation('validationInvalidCreditCardNameErrorMessage', obj),
  /* 発行数の形式が正しくありません */
  validationInvalidNumberOfIssuesErrorMessage: (obj?: I18nArgumentType) =>
    translation('validationInvalidNumberOfIssuesErrorMessage', obj),
  /* カード情報を暗号化した情報は必須です */
  validationEmptyEncryptedCardInformation: (obj?: I18nArgumentType) =>
    translation('validationEmptyEncryptedCardInformation', obj),
  /* Shop IDは必須です */
  validationEmptyShopId: (obj?: I18nArgumentType) => translation('validationEmptyShopId', obj),
  /* Shop IDの形式が正しくありません */
  validationInvalidShopId: (obj?: I18nArgumentType) => translation('validationInvalidShopId', obj),
  /* 公開鍵ハッシュ値は必須です */
  validationEmptyPublicKeyHashValue: (obj?: I18nArgumentType) =>
    translation('validationEmptyPublicKeyHashValue', obj),
  /* ショップIDまたは公開鍵ハッシュ値がマスターに存在しません */
  validationEmptyShopIdOrPublicKeyHashValue: (obj?: I18nArgumentType) =>
    translation('validationEmptyShopIdOrPublicKeyHashValue', obj),
  /* 暗号化されたカード情報が正しくありません */
  validationInvalidEncryptedCardInformation: (obj?: I18nArgumentType) =>
    translation('validationInvalidEncryptedCardInformation', obj),
  /* Callbackは必須です */
  validationEmptyCallback: (obj?: I18nArgumentType) => translation('validationEmptyCallback', obj),
  /* Callbackの形式が正しくありません */
  validationInvalidCallback: (obj?: I18nArgumentType) =>
    translation('validationInvalidCallback', obj),
  /* トークン用パラメータの形式が正しくありません */
  validationInvalidTokenParameter: (obj?: I18nArgumentType) =>
    translation('validationInvalidTokenParameter', obj),
  /* トークン用パラメータは必須です */
  validationEmptyTokenParameter: (obj?: I18nArgumentType) =>
    translation('validationEmptyTokenParameter', obj),
  /* マルチペイメント内部のシステムエラーです */
  validationUnknownSystemErrorInMultiPayment: (obj?: I18nArgumentType) =>
    translation('validationUnknownSystemErrorInMultiPayment', obj),
  /* 処理が混み合っています */
  validationCrowdedProcessing: (obj?: I18nArgumentType) =>
    translation('validationCrowdedProcessing', obj),
  /* カードを削除 */
  removeCreditCard: (obj?: I18nArgumentType) => translation('removeCreditCard', obj),
  /* 子供の夕食について */
  dinnerChildDescriptionTitle: (obj?: I18nArgumentType) =>
    translation('dinnerChildDescriptionTitle', obj),
  /* 子供の年齢 */
  webYadoBookingChildAges: (obj?: I18nArgumentType) => translation('webYadoBookingChildAges', obj),
  /* 簡単1分で */
  webYadoQuestionnaireTitle1: (obj?: I18nArgumentType) =>
    translation('webYadoQuestionnaireTitle1', obj),
  /* アンケートご協力のお願い */
  webYadoQuestionnaireTitle2: (obj?: I18nArgumentType) =>
    translation('webYadoQuestionnaireTitle2', obj),
  /* https://jp.surveymonkey.com/r/DK6ZKHQ */
  webYadoQuestionnaireLink: (obj?: I18nArgumentType) =>
    translation('webYadoQuestionnaireLink', obj),
  /* 3Dセキュア失敗しました */
  threeDSecureFailedAlertTitle: (obj?: I18nArgumentType) =>
    translation('threeDSecureFailedAlertTitle', obj),
  /* 近隣のアクティビティ */
  webYadoNearbyActivity: (obj?: I18nArgumentType) => translation('webYadoNearbyActivity', obj),
  /* 新潟県その他エリア */
  webYadoOnsenAreaNiigata: (obj?: I18nArgumentType) => translation('webYadoOnsenAreaNiigata', obj),
  /* 新潟県は東京から北陸新幹線で2時間程度でアクセスできます。新潟県には越後湯沢温泉や月岡温泉などの有名な温泉があります。新鮮な海の幸や、米どころならではの美味しい地酒、スキー場や温泉などを楽しむことができます。 */
  webYadoOnsenAreaNiigataDescription: (obj?: I18nArgumentType) =>
    translation('webYadoOnsenAreaNiigataDescription', obj),
  /* 有効なクーポンコードを入力してください */
  couponCodeNotFoundErrorMsg: (obj?: I18nArgumentType) =>
    translation('couponCodeNotFoundErrorMsg', obj),
  /* クーポンコードの発行最大数を超えました */
  couponCodeTotalLimitExceededErrorMsg: (obj?: I18nArgumentType) =>
    translation('couponCodeTotalLimitExceededErrorMsg', obj),
  /* クーポンコードは1人あたりの発行上限に達しています。 */
  couponCodeLimitPerPersonExceededErrorMsg: (obj?: I18nArgumentType) =>
    translation('couponCodeLimitPerPersonExceededErrorMsg', obj),
  /* クーポンコード */
  couponCode: (obj?: I18nArgumentType) => translation('couponCode', obj),
  /* 登録 */
  couponCodeRedeemBtn: (obj?: I18nArgumentType) => translation('couponCodeRedeemBtn', obj),
  /* クーポン利用 */
  couponRedeem: (obj?: I18nArgumentType) => translation('couponRedeem', obj),
  /* 先着 */
  webYadoHeaderCouponLimitedFirst: (obj?: I18nArgumentType) =>
    translation('webYadoHeaderCouponLimitedFirst', obj),
  /* {{consumeLimit}}名 */
  webYadoHeaderCouponConsumeLimitPeople: (obj?: I18nArgumentType) =>
    translation('webYadoHeaderCouponConsumeLimitPeople', obj),
  /* 当施設は貸切風呂の提供はありません */
  webYadWithoutPrivateBathReservation: (obj?: I18nArgumentType) =>
    translation('webYadWithoutPrivateBathReservation', obj),
  /* 当施設の貸切風呂の提供については、{{link}}までお問い合わせください */
  webYadSupportChatLinkedJalan: (obj?: I18nArgumentType) =>
    translation('webYadSupportChatLinkedJalan', obj),
  /* 他の日付をお選びいただくか、別の宿をご利用ください。 */
  webYadoChooseAnotherDateOrAccommodation: (obj?: I18nArgumentType) =>
    translation('webYadoChooseAnotherDateOrAccommodation', obj),
  /* 金額はJPYです。現時点の為替に基づいて計算した参考価格です。 */
  webYadoLocalPriceShownBasedOnCurrentExchange: (obj?: I18nArgumentType) =>
    translation('webYadoLocalPriceShownBasedOnCurrentExchange', obj),
  /* カレンダーで空室を見る */
  webYadoCheckAvailabilityOnCalendar: (obj?: I18nArgumentType) =>
    translation('webYadoCheckAvailabilityOnCalendar', obj),
  /* 万 */
  webYadoTenThousand: (obj?: I18nArgumentType) => translation('webYadoTenThousand', obj),
  /* 宿泊先を探す */
  webYadoFindYado: (obj?: I18nArgumentType) => translation('webYadoFindYado', obj),
  /* 年齢を選択してください */
  webYadoChildAgeError: (obj?: I18nArgumentType) => translation('webYadoChildAgeError', obj),
  /* 接続中... */
  webYadoCalendarLoading: (obj?: I18nArgumentType) => translation('webYadoCalendarLoading', obj),
  /* 上記は参考価格であり、実際のプランは売り切れている可能性があります。 */
  calendarInfoAlert: (obj?: I18nArgumentType) => translation('calendarInfoAlert', obj),
  /* {{roomCount}}部屋以上 */
  webYadoAboveHighestRoomCount: (obj?: I18nArgumentType) =>
    translation('webYadoAboveHighestRoomCount', obj),
  /* {{roomCount}}部屋未満 */
  webYadoBelowLowestRoomCount: (obj?: I18nArgumentType) =>
    translation('webYadoBelowLowestRoomCount', obj),
  /* 宿タイプ */
  webYadoFilterConditionYadoType: (obj?: I18nArgumentType) =>
    translation('webYadoFilterConditionYadoType', obj),
  /* 部屋数 */
  webYadoFilterConditionRoomCount: (obj?: I18nArgumentType) =>
    translation('webYadoFilterConditionRoomCount', obj),
  /* 絞り込み条件をすべて表示 */
  webYadoFilterConditionExpand: (obj?: I18nArgumentType) =>
    translation('webYadoFilterConditionExpand', obj),
  /* 下限なし */
  noLowerLimit: (obj?: I18nArgumentType) => translation('noLowerLimit', obj),
  /* 上限なし */
  noUpperLimit: (obj?: I18nArgumentType) => translation('noUpperLimit', obj),
  /* {{time}}の時間に夕食を開始いただくプランです（チェックイン時にお時間を確認いたします） */
  webYadoDinnerStartTimeNoteSeparate: (obj?: I18nArgumentType) =>
    translation('WebYado-DinnerStartTimeNoteSeparate', obj),
  /* 満室 */
  fullyOccupied: (obj?: I18nArgumentType) => translation('fullyOccupied', obj),
  /* 地図で見る */
  viewOnMap: (obj?: I18nArgumentType) => translation('viewOnMap', obj),
  /* 一覧へ戻る */
  backToList: (obj?: I18nArgumentType) => translation('backToList', obj),
  /* 空室状況 */
  roomAvailability: (obj?: I18nArgumentType) => translation('roomAvailability', obj),
  /* 空室のある宿のみ表示 */
  displayHotelsWithAvailableRoomsOnly: (obj?: I18nArgumentType) =>
    translation('displayHotelsWithAvailableRoomsOnly', obj),
  /* 周辺ホテル */
  nearbyHotels: (obj?: I18nArgumentType) => translation('nearbyHotels', obj),
  /* 事前予約締め切り */
  advanceReservationDeadline: (obj?: I18nArgumentType) =>
    translation('advanceReservationDeadline', obj),
  /* {{deadlineDay}}日前 {{deadlineTime}}（日本時間） */
  pickupReservationDeadline: (obj?: I18nArgumentType) =>
    translation('pickupReservationDeadline', obj),
  /* 前日 17:30（日本時間） */
  pickupReservationDeadlineDefault: (obj?: I18nArgumentType) =>
    translation('pickupReservationDeadlineDefault', obj),
  /* 絞り込み */
  webYadoFilter: (obj?: I18nArgumentType) => translation('WebYadoFilter', obj),
  /* 絞り込み条件 */
  webYadoFilterCondition: (obj?: I18nArgumentType) => translation('WebYadoFilterCondition', obj),
  /* 並び替え */
  webYadoSort: (obj?: I18nArgumentType) => translation('WebYadoSort', obj),
  /* おすすめ順 */
  webYadoSortRecommend: (obj?: I18nArgumentType) => translation('WebYadoSortRecommend', obj),
  /* 料金が安い順 */
  webYadoSortLowPrice: (obj?: I18nArgumentType) => translation('WebYadoSortLowPrice', obj),
  /* 料金が高い順 */
  webYadoSortHighPrice: (obj?: I18nArgumentType) => translation('WebYadoSortHighPrice', obj),
  /* この施設は販売準備中です。掲載情報は古い可能性があります。ご注意ください。 */
  webYadoPreparing: (obj?: I18nArgumentType) => translation('webYadoPreparing', obj),
  /* ご利用いただけるクレジットカード種類 */
  supportedCreditCards: (obj?: I18nArgumentType) => translation('supportedCreditCards', obj),
  /* お客様の実際の支払金額は支払いサービスによって決められる。 */
  webYadoPaymentDeterminedByPaymentService: (obj?: I18nArgumentType) =>
    translation('webYadoPaymentDeterminedByPaymentService', obj),
  /* About WAmazingStay */
  introAbout: (obj?: I18nArgumentType) => translation('introAbout', obj),
  /* 厳選した日本の温泉宿を、
楽しみ尽くす */
  introEnjoyCarefully: (obj?: I18nArgumentType) => translation('introEnjoyCarefully', obj),
  /* WAmazingStayは温泉宿に特化した宿泊予約サービスです。
温泉宿の宿泊体験を、WAmazingStayのサービスを使えば、
温泉宿の宿泊体験を楽しみつくすことができます。 */
  introAccomReserv: (obj?: I18nArgumentType) => translation('introAccomReserv', obj),
  /* 温泉宿のさまざまな魅力 */
  introRecomFor: (obj?: I18nArgumentType) => translation('introRecomFor', obj),
  /* 温泉旅館で日本文化を体験する */
  introThoseWhoWant: (obj?: I18nArgumentType) => translation('introThoseWhoWant', obj),
  /* 温泉宿の宿泊は、温泉入浴、食事、浴衣、自然など、日本文化を存分に味わえる体験ができます。一方で、日本食や入浴ルールなど、問題なく過ごせるか不安も出てきます。そんな方に、WAmazingStayはおすすめです。 */
  introStayingAt: (obj?: I18nArgumentType) => translation('introStayingAt', obj),
  /* 旅行をより楽しむサービス */
  introCommonConcerns: (obj?: I18nArgumentType) => translation('introCommonConcerns', obj),
  /* 温泉宿までの移動が大変そう */
  introGettingFrom: (obj?: I18nArgumentType) => translation('introGettingFrom', obj),
  /* 温泉宿の多くは、交通の便が発達していない場所にあることも多く、荷物が多さや家族連れの旅行の場合は不便なことも。 */
  introCanBeDifficult: (obj?: I18nArgumentType) => translation('introCanBeDifficult', obj),
  /* 温泉宿のお迎えサービスを、お客様に代わって無料で手配 */
  introWeCanArrange: (obj?: I18nArgumentType) => translation('introWeCanArrange', obj),
  /* サイトから事前に予約をすれば、ボタンひとつで呼び出すことも可能。荷物が多かったり、ご家族での旅行でも楽に温泉宿に行くことができます。 */
  introReservationAdvance: (obj?: I18nArgumentType) => translation('introReservationAdvance', obj),
  /* もっと詳しく知る */
  introFindOut: (obj?: I18nArgumentType) => translation('introFindOut', obj),
  /* 温泉宿の食事内容が食べられるか不安がある */
  introNotSureFood: (obj?: I18nArgumentType) => translation('introNotSureFood', obj),
  /* 温泉宿の食事は事前に決められていることが多く、食事内容に問題がないか、不安を感じるかもしれません。 */
  introMealsPlan: (obj?: I18nArgumentType) => translation('introMealsPlan', obj),
  /* 食事内容の調整を、お客様に代わって事前に対応 */
  introAdjustMenu: (obj?: I18nArgumentType) => translation('introAdjustMenu', obj),
  /* サイトで食事内容をご確認いただけるだけでなく、ネイティブオペレーターを通じて、食事内容の調整ができます。 */
  introModifyMeal: (obj?: I18nArgumentType) => translation('introModifyMeal', obj),
  /* 入浴ルールが多く、正しく入れているか不安がある */
  introNotSureBath: (obj?: I18nArgumentType) => translation('introNotSureBath', obj),
  /* 日本の温泉入浴にはたくさんのルールがあったり、他人と同じ空間で裸で入ることに抵抗があることも多いでしょう。 */
  introGuideBathing: (obj?: I18nArgumentType) => translation('introGuideBathing', obj),
  /* 貸切風呂を事前に予約して、貸切風呂で温泉を楽しむ */
  introPrivateBath: (obj?: I18nArgumentType) => translation('introPrivateBath', obj),
  /* 貸切風呂のある温泉宿で、事前予約を受け付けている宿であれば、ネイティブオペレーターが代わりに予約を代行することが可能です。 */
  introRepresentative: (obj?: I18nArgumentType) => translation('introRepresentative', obj),
  /* ※WAmazingの提供する機能は、温泉宿の提供するサービスにより利用の可否が異なります。 */
  introServiceAvailability: (obj?: I18nArgumentType) =>
    translation('introServiceAvailability', obj),
  /* 温泉宿を検索 */
  introSearchYados: (obj?: I18nArgumentType) => translation('introSearchYados', obj),
  /* 北海道その他エリア */
  webYadoOnsenAreaHokkaido: (obj?: I18nArgumentType) =>
    translation('webYadoOnsenAreaHokkaido', obj),
  /* 青森県その他エリア */
  webYadoOnsenAreaAomori: (obj?: I18nArgumentType) => translation('webYadoOnsenAreaAomori', obj),
  /* 岩手県その他エリア */
  webYadoOnsenAreaIwate: (obj?: I18nArgumentType) => translation('webYadoOnsenAreaIwate', obj),
  /* 宮城県その他エリア */
  webYadoOnsenAreaMiyagi: (obj?: I18nArgumentType) => translation('webYadoOnsenAreaMiyagi', obj),
  /* 秋田県その他エリア */
  webYadoOnsenAreaAkita: (obj?: I18nArgumentType) => translation('webYadoOnsenAreaAkita', obj),
  /* 山形県その他エリア */
  webYadoOnsenAreaYamagata: (obj?: I18nArgumentType) =>
    translation('webYadoOnsenAreaYamagata', obj),
  /* 福島県その他エリア */
  webYadoOnsenAreaFukushima: (obj?: I18nArgumentType) =>
    translation('webYadoOnsenAreaFukushima', obj),
  /* 茨城県その他エリア */
  webYadoOnsenAreaIbaraki: (obj?: I18nArgumentType) => translation('webYadoOnsenAreaIbaraki', obj),
  /* 栃木県その他エリア */
  webYadoOnsenAreaTochigi: (obj?: I18nArgumentType) => translation('webYadoOnsenAreaTochigi', obj),
  /* 群馬県その他エリア */
  webYadoOnsenAreaGunma: (obj?: I18nArgumentType) => translation('webYadoOnsenAreaGunma', obj),
  /* 埼玉県その他エリア */
  webYadoOnsenAreaSaitama: (obj?: I18nArgumentType) => translation('webYadoOnsenAreaSaitama', obj),
  /* 千葉県その他エリア */
  webYadoOnsenAreaChiba: (obj?: I18nArgumentType) => translation('webYadoOnsenAreaChiba', obj),
  /* 東京都その他エリア */
  webYadoOnsenAreaTokyo: (obj?: I18nArgumentType) => translation('webYadoOnsenAreaTokyo', obj),
  /* 神奈川県その他エリア */
  webYadoOnsenAreaKanagawa: (obj?: I18nArgumentType) =>
    translation('webYadoOnsenAreaKanagawa', obj),
  /* 富山県その他エリア */
  webYadoOnsenAreaToyama: (obj?: I18nArgumentType) => translation('webYadoOnsenAreaToyama', obj),
  /* 石川県その他エリア */
  webYadoOnsenAreaIshikawa: (obj?: I18nArgumentType) =>
    translation('webYadoOnsenAreaIshikawa', obj),
  /* 福井県その他エリア */
  webYadoOnsenAreaFukui: (obj?: I18nArgumentType) => translation('webYadoOnsenAreaFukui', obj),
  /* 長野県その他エリア */
  webYadoOnsenAreaNagano: (obj?: I18nArgumentType) => translation('webYadoOnsenAreaNagano', obj),
  /* 静岡県その他エリア */
  webYadoOnsenAreaShizuoka: (obj?: I18nArgumentType) =>
    translation('webYadoOnsenAreaShizuoka', obj),
  /* 愛知県その他エリア */
  webYadoOnsenAreaAichi: (obj?: I18nArgumentType) => translation('webYadoOnsenAreaAichi', obj),
  /* 三重県その他エリア */
  webYadoOnsenAreaMie: (obj?: I18nArgumentType) => translation('webYadoOnsenAreaMie', obj),
  /* 滋賀県その他エリア */
  webYadoOnsenAreaShiga: (obj?: I18nArgumentType) => translation('webYadoOnsenAreaShiga', obj),
  /* 京都府その他エリア */
  webYadoOnsenAreaKyotofu: (obj?: I18nArgumentType) => translation('webYadoOnsenAreaKyotofu', obj),
  /* 大阪府その他エリア */
  webYadoOnsenAreaOsaka: (obj?: I18nArgumentType) => translation('webYadoOnsenAreaOsaka', obj),
  /* 兵庫県その他エリア */
  webYadoOnsenAreaHyogo: (obj?: I18nArgumentType) => translation('webYadoOnsenAreaHyogo', obj),
  /* 奈良県その他エリア */
  webYadoOnsenAreaNara: (obj?: I18nArgumentType) => translation('webYadoOnsenAreaNara', obj),
  /* 和歌山県その他エリア */
  webYadoOnsenAreaWakayama: (obj?: I18nArgumentType) =>
    translation('webYadoOnsenAreaWakayama', obj),
  /* 鳥取県その他エリア */
  webYadoOnsenAreaTottori: (obj?: I18nArgumentType) => translation('webYadoOnsenAreaTottori', obj),
  /* 島根県その他エリア */
  webYadoOnsenAreaShimane: (obj?: I18nArgumentType) => translation('webYadoOnsenAreaShimane', obj),
  /* 岡山県その他エリア */
  webYadoOnsenAreaOkayama: (obj?: I18nArgumentType) => translation('webYadoOnsenAreaOkayama', obj),
  /* 広島県その他エリア */
  webYadoOnsenAreaHiroshima: (obj?: I18nArgumentType) =>
    translation('webYadoOnsenAreaHiroshima', obj),
  /* 徳島県その他エリア */
  webYadoOnsenAreaTokushima: (obj?: I18nArgumentType) =>
    translation('webYadoOnsenAreaTokushima', obj),
  /* 愛媛県その他エリア */
  webYadoOnsenAreaEhime: (obj?: I18nArgumentType) => translation('webYadoOnsenAreaEhime', obj),
  /* 高知県その他エリア */
  webYadoOnsenAreaKochi: (obj?: I18nArgumentType) => translation('webYadoOnsenAreaKochi', obj),
  /* 福岡県その他エリア */
  webYadoOnsenAreaFukuoka: (obj?: I18nArgumentType) => translation('webYadoOnsenAreaFukuoka', obj),
  /* 佐賀県その他エリア */
  webYadoOnsenAreaSaga: (obj?: I18nArgumentType) => translation('webYadoOnsenAreaSaga', obj),
  /* 長崎県その他エリア */
  webYadoOnsenAreaNagasaki: (obj?: I18nArgumentType) =>
    translation('webYadoOnsenAreaNagasaki', obj),
  /* 熊本県その他エリア */
  webYadoOnsenAreaKumamoto: (obj?: I18nArgumentType) =>
    translation('webYadoOnsenAreaKumamoto', obj),
  /* 大分県その他エリア */
  webYadoOnsenAreaOita: (obj?: I18nArgumentType) => translation('webYadoOnsenAreaOita', obj),
  /* 宮崎県その他エリア */
  webYadoOnsenAreaMiyazaki: (obj?: I18nArgumentType) =>
    translation('webYadoOnsenAreaMiyazaki', obj),
  /* 鹿児島県その他エリア */
  webYadoOnsenAreaKagoshima: (obj?: I18nArgumentType) =>
    translation('webYadoOnsenAreaKagoshima', obj),
  /* 大分空港 */
  webYadoAirportOita: (obj?: I18nArgumentType) => translation('WebYadoAirportOita', obj),
  /* 宮崎空港 */
  webYadoAirportMiyazaki: (obj?: I18nArgumentType) => translation('WebYadoAirportMiyazaki', obj),
  /* 茨城県 */
  webYadoPrefectureIbaraki: (obj?: I18nArgumentType) =>
    translation('webYadoPrefectureIbaraki', obj),
  /* 埼玉県 */
  webYadoPrefectureSaitama: (obj?: I18nArgumentType) =>
    translation('webYadoPrefectureSaitama', obj),
  /* 千葉県 */
  webYadoPrefectureChiba: (obj?: I18nArgumentType) => translation('webYadoPrefectureChiba', obj),
  /* 東京都 */
  webYadoPrefectureTokyo: (obj?: I18nArgumentType) => translation('webYadoPrefectureTokyo', obj),
  /* 大阪府 */
  webYadoPrefectureOsaka: (obj?: I18nArgumentType) => translation('webYadoPrefectureOsaka', obj),
  /* 福岡県 */
  webYadoPrefectureFukuoka: (obj?: I18nArgumentType) =>
    translation('webYadoPrefectureFukuoka', obj),
  /* 宮崎県 */
  webYadoPrefectureMiyazaki: (obj?: I18nArgumentType) =>
    translation('webYadoPrefectureMiyazaki', obj),
  /* 温泉宿の特徴 */
  introFeature: (obj?: I18nArgumentType) => translation('introFeature', obj),
  /* お食事 */
  introMeals: (obj?: I18nArgumentType) => translation('introMeals', obj),
  /* 温泉宿のお食事の特徴 */
  mealsInns: (obj?: I18nArgumentType) => translation('mealsInns', obj),
  /* 食事が事前にある程度決まっている */
  mealsDetermined: (obj?: I18nArgumentType) => translation('mealsDetermined', obj),
  /* 一般的なホテルにステイする場合と違って、温泉宿では事前に決められたメニューの食事を提供することが多いです。
そのため、予約時に食事を確認して選択しておく必要があります。食事内容によって調整が必要な場合は事前にWAmazingStayを通して調整を依頼しましょう。 */
  mealsPredefined: (obj?: I18nArgumentType) => translation('mealsPredefined', obj),
  /* よくあるご要望 */
  mealsFrequentRequests: (obj?: I18nArgumentType) => translation('mealsFrequentRequests', obj),
  /* 牛肉など、個人的に食べられないものを他のものに代えてほしい */
  mealsSubstitute: (obj?: I18nArgumentType) => translation('mealsSubstitute', obj),
  /* 食べられないものがある場合、それを他のもので代替できるか、事前に確認できなければ、当日の食事を楽しめるか不安になってしまいます。 */
  mealsCantEat: (obj?: I18nArgumentType) => translation('mealsCantEat', obj),
  /* アレルギーや宗教、ベジタリアンなどに対応した食事に変更したい */
  mealsAccomodate: (obj?: I18nArgumentType) => translation('mealsAccomodate', obj),
  /* アレルギーや宗教、ベジタリアンなど、食べられない食材がある場合、食材を変えてもらえなければ食べられる料理も減ってしまいます。 */
  mealsRestrictions: (obj?: I18nArgumentType) => translation('mealsRestrictions', obj),
  /* 事前の食事確認や調整が可能 */
  mealsAdjust: (obj?: I18nArgumentType) => translation('mealsAdjust', obj),
  /* お食事事前選択、調整代行 */
  mealsAdjustBehalf: (obj?: I18nArgumentType) => translation('mealsAdjustBehalf', obj),
  /* 事前に食事内容を確認して、予約できる */
  mealsConfirm: (obj?: I18nArgumentType) => translation('mealsConfirm', obj),
  /* WAmazingStayのサイトなら、食事の内容をサイトで細かく事前に確認して、予約できます。 */
  mealsReserveOnline: (obj?: I18nArgumentType) => translation('mealsReserveOnline', obj),
  /* 細かいご要望はネイティブのオペレーターが代わりに調整 */
  mealsRepresentative: (obj?: I18nArgumentType) => translation('mealsRepresentative', obj),
  /* 食べれない食材があっても、その食材を避けた対応が可能か、ネイティブオペレーターが代わりに調整します。 */
  mealsRepresentAdjustment: (obj?: I18nArgumentType) =>
    translation('mealsRepresentAdjustment', obj),
  /* 食事が楽しめる温泉宿を探してみる */
  mealsFindYadosMeal: (obj?: I18nArgumentType) => translation('mealsFindYadosMeal', obj),
  /* 温泉宿での食事の仕方 */
  mealsHowTo: (obj?: I18nArgumentType) => translation('mealsHowTo', obj),
  /* 食事場所 */
  mealsVenue: (obj?: I18nArgumentType) => translation('mealsVenue', obj),
  /* 旅館やお食事内容によって、食事場所は主に4パターンあります。 */
  mealsVenueTypes: (obj?: I18nArgumentType) => translation('mealsVenueTypes', obj),
  /* 部屋食 */
  mealsRoom: (obj?: I18nArgumentType) => translation('mealsRoom', obj),
  /* 宿泊する客室内での食事です。 */
  mealsRoomDesc: (obj?: I18nArgumentType) => translation('mealsRoomDesc', obj),
  /* 宿の施設内部にある食事会場での食事です。 */
  mealsRestoDesc: (obj?: I18nArgumentType) => translation('mealsRestoDesc', obj),
  /* 個室 */
  mealsPrivate: (obj?: I18nArgumentType) => translation('mealsPrivate', obj),
  /* 宿の施設内にある会場で、壁や衝立で囲われた場所での食事です。 */
  mealsPrivateDesc: (obj?: I18nArgumentType) => translation('mealsPrivateDesc', obj),
  /* ブッフェ */
  mealsBuffet: (obj?: I18nArgumentType) => translation('mealsBuffet', obj),
  /* 宿の施設内部にある食事会場で、ブッフェ形式でのお食事です。 */
  mealsBuffetDesc: (obj?: I18nArgumentType) => translation('mealsBuffetDesc', obj),
  /* 食事の種類 */
  mealsTypes: (obj?: I18nArgumentType) => translation('mealsTypes', obj),
  /* 日本料理以外にも、さまざまな料理を提供しています。各旅館のページにてご確認ください。 */
  mealsTypesDesc: (obj?: I18nArgumentType) => translation('mealsTypesDesc', obj),
  /* 日本料理 */
  mealsJpn: (obj?: I18nArgumentType) => translation('mealsJpn', obj),
  /* 折衷料理 */
  mealsJpnWstn: (obj?: I18nArgumentType) => translation('mealsJpnWstn', obj),
  /* イタリアン */
  mealsItalian: (obj?: I18nArgumentType) => translation('mealsItalian', obj),
  /* フレンチ */
  mealsFrench: (obj?: I18nArgumentType) => translation('mealsFrench', obj),
  /* 中華 */
  mealsChinese: (obj?: I18nArgumentType) => translation('mealsChinese', obj),
  /* ご利用方法 */
  mealsHowToUse: (obj?: I18nArgumentType) => translation('mealsHowToUse', obj),
  /* STEP1 */
  mealsStep1: (obj?: I18nArgumentType) => translation('mealsStep1', obj),
  /* 温泉宿の予約時に食事のメニューをご確認の上、ご予約ください。 */
  mealsStep1ConfirmMenu: (obj?: I18nArgumentType) => translation('mealsStep1ConfirmMenu', obj),
  /* STEP2 */
  mealsStep2: (obj?: I18nArgumentType) => translation('mealsStep2', obj),
  /* 食事内容を調整されたい方は、お問い合わせフォームよりお問い合わせください。WAmazingStayのオペレーターが対応いたします。 */
  mealsStep2ContactUs: (obj?: I18nArgumentType) => translation('mealsStep2ContactUs', obj),
  /* WAmazingStayの特別なサービス一覧 */
  mealsServicesList: (obj?: I18nArgumentType) => translation('mealsServicesList', obj),
  /* お食事選択、調整代行 */
  introMealsSelection: (obj?: I18nArgumentType) => translation('introMealsSelection', obj),
  /* 貸切風呂事前予約 */
  introPrivateBaths: (obj?: I18nArgumentType) => translation('introPrivateBaths', obj),
  /* お迎えサービス */
  introPickupService: (obj?: I18nArgumentType) => translation('introPickupService', obj),
  /* WAmazingStay なら */
  ifWamazing: (obj?: I18nArgumentType) => translation('ifWamazing', obj),
  /* 温泉への入浴 */
  onsenEntering: (obj?: I18nArgumentType) => translation('onsenEntering', obj),
  /* 温泉入浴の特徴 */
  onsenEnteringFeature: (obj?: I18nArgumentType) => translation('onsenEnteringFeature', obj),
  /* マナーやルールを守りながら、他人と入浴する */
  onsenRules: (obj?: I18nArgumentType) => translation('onsenRules', obj),
  /* 温泉入浴は、日本文化を経験するのに欠かせない体験です。
しかし、温泉入浴の際には、多くのマナーや注意するべきことがあります。また、他人と入浴することが一般的ですが、抵抗を感じることもあるでしょう。マナーを守りながら他人と入浴するのは、温泉を十分に楽しめない人もいるかもしれません。 */
  onsenRulesDesc: (obj?: I18nArgumentType) => translation('onsenRulesDesc', obj),
  /* 何も着ずに温泉に入ることに抵抗がある */
  onsenNoClothes: (obj?: I18nArgumentType) => translation('onsenNoClothes', obj),
  /* 日本の温泉では、何も着ずに他人と入浴することは一般的ですが、初めて温泉を体験するという人には抵抗があることも多いでしょう。 */
  onsenNoClothesDesc: (obj?: I18nArgumentType) => translation('onsenNoClothesDesc', obj),
  /* ルールを気にせず入浴したい */
  onsenRulesNoWorry: (obj?: I18nArgumentType) => translation('onsenRulesNoWorry', obj),
  /* 温泉には、水着を着てはいけない、タオルを浴室に入れてはいけない、など多くのルールが存在。本当に正しく入れているのかは不安になることも。 */
  onsenRulesNoWorryDesc: (obj?: I18nArgumentType) => translation('onsenRulesNoWorryDesc', obj),
  /* 家族でプライベートに温泉を楽しみたい */
  onsenFamily: (obj?: I18nArgumentType) => translation('onsenFamily', obj),
  /* 家族やカップル、友達と旅行している場合、自分たちだけでプライベートな空間の方が、温泉を安心して楽しめるかもしれません。 */
  onsenFamilyDesc: (obj?: I18nArgumentType) => translation('onsenFamilyDesc', obj),
  /* 貸切風呂を事前予約の代行が可能 */
  onsenWamazing: (obj?: I18nArgumentType) => translation('onsenWamazing', obj),
  /* 貸切風呂予約代行 */
  onsenRepresentative: (obj?: I18nArgumentType) => translation('onsenRepresentative', obj),
  /* どんな温泉があるか、事前にサイトで確認できる */
  onsenSiteConfirm: (obj?: I18nArgumentType) => translation('onsenSiteConfirm', obj),
  /* 温泉宿によって、大浴場、露天風呂、貸切風呂の有無が異なります。WAmazingStayなら事前に確認することが可能です。 */
  onsenSiteConfirmDesc: (obj?: I18nArgumentType) => translation('onsenSiteConfirmDesc', obj),
  /* 貸切風呂の予約は、ネイティブオペレーターが代わりに対応 */
  onsenOperator: (obj?: I18nArgumentType) => translation('onsenOperator', obj),
  /* 貸切風呂があり、事前に予約ができる温泉宿であれば、ネイティブオペレーターが予約を代行します */
  onsenOperatorDesc: (obj?: I18nArgumentType) => translation('onsenOperatorDesc', obj),
  /* 温泉が楽しめる温泉宿を探してみる */
  onsenTrySearch: (obj?: I18nArgumentType) => translation('onsenTrySearch', obj),
  /* STEP1 */
  stepOne: (obj?: I18nArgumentType) => translation('stepOne', obj),
  /* 予約前に、対象の温泉宿のページにて、貸切風呂の有無をご確認ください。貸切風呂がある場合、予約方法や宿によって異なります。詳細ページでご確認ください。 */
  onsenBeforeBooking: (obj?: I18nArgumentType) => translation('onsenBeforeBooking', obj),
  /* STEP2 */
  stepTwo: (obj?: I18nArgumentType) => translation('stepTwo', obj),
  /* ※事前予約の場合のみ */
  onsenOnlyBefore: (obj?: I18nArgumentType) => translation('onsenOnlyBefore', obj),
  /* お問い合わせフォーム */
  onsenQuestionForm: (obj?: I18nArgumentType) => translation('onsenQuestionForm', obj),
  /* 温泉が楽しめる温泉宿を探してみる */
  onsenSearch: (obj?: I18nArgumentType) => translation('onsenSearch', obj),
  /* お迎えサービス */
  introPickup: (obj?: I18nArgumentType) => translation('introPickup', obj),
  /* 温泉宿のアクセスの特徴 */
  pickupFeature: (obj?: I18nArgumentType) => translation('pickupFeature', obj),
  /* よい温泉は自然の近くにあるので、駅から遠いことも。多くの温泉宿がお迎えサービスを用意。 */
  pickupFeatureSubtitle: (obj?: I18nArgumentType) => translation('pickupFeatureSubtitle', obj),
  /* 温泉は自然から湧き出ているため、自然の近くにある宿が多いです。そのため、アクセスは、都会に比べて駅から遠い場所にある場合が少なくありません。
公共交通機関もあまり発達していないことも多いので、多くの温泉では無料でお迎えサービスを用意しています。 */
  pickupDesc: (obj?: I18nArgumentType) => translation('pickupDesc', obj),
  /* 荷物が多いので、移動が大変 */
  pickupLuggage: (obj?: I18nArgumentType) => translation('pickupLuggage', obj),
  /* 日本のように海外へ旅行する場合、荷物は多くなることがほとんどです。大きな荷物をもって簡単に移動できる手段を探す必要があります。 */
  pickupLuggageDesc: (obj?: I18nArgumentType) => translation('pickupLuggageDesc', obj),
  /* 子供や高齢者がいるので、楽に移動したい */
  pickupDependant: (obj?: I18nArgumentType) => translation('pickupDependant', obj),
  /* 子供や高齢者がいる場合、歩いて移動することは家族にとって大変なことも。タクシーやレンタカーなど、楽に移動する方法を探す必要があります。 */
  pickupDependantDesc: (obj?: I18nArgumentType) => translation('pickupDependantDesc', obj),
  /* 日本で使える電話番号を持っていないので、呼び出せない */
  pickupJapanesePhone: (obj?: I18nArgumentType) => translation('pickupJapanesePhone', obj),
  /* 海外ではインターネットの繋がるsimを持っていても、電話番号まで持っているという人は少ないため、現地に着いてお迎えを呼び出すことが難しいでしょう。 */
  pickupJapanesePhoneDesc: (obj?: I18nArgumentType) => translation('pickupJapanesePhoneDesc', obj),
  /* WAmazingStay なら */
  ifWamazingPickup: (obj?: I18nArgumentType) => translation('ifWamazingPickup', obj),
  /* 
サイト上でお迎えサービスの手配が可能 */
  pickupPossible: (obj?: I18nArgumentType) => translation('pickupPossible', obj),
  /* お迎え手配サービス機能 */
  pickupService: (obj?: I18nArgumentType) => translation('pickupService', obj),
  /* 電話不要！ボタンひとつで呼び出し */
  pickupNoPhone: (obj?: I18nArgumentType) => translation('pickupNoPhone', obj),
  /* 電話がつながらなくても、ボタン一つでお迎えを呼び出すことが可能です。 */
  pickupNoPhoneDesc: (obj?: I18nArgumentType) => translation('pickupNoPhoneDesc', obj),
  /* サイトから事前予約も可能 */
  pickupReserveSite: (obj?: I18nArgumentType) => translation('pickupReserveSite', obj),
  /* 温泉宿によって違うお迎えサービスの方法にWAmazingStayは対応。サイトから事前予約もできます */
  pickupReserveSiteDesc: (obj?: I18nArgumentType) => translation('pickupReserveSiteDesc', obj),
  /* 当日何かあった場合にも、オペレーターが対応 */
  pickupOperator: (obj?: I18nArgumentType) => translation('pickupOperator', obj),
  /* 機能の利用には、事前予約のものと事前予約不要のものがございます。 */
  pickupHowTo1: (obj?: I18nArgumentType) => translation('pickupHowTo1', obj),
  /* 事前予約あり */
  pickupHowTo2: (obj?: I18nArgumentType) => translation('pickupHowTo2', obj),
  /* 当日到着したら呼び出し */
  pickupHowTo3: (obj?: I18nArgumentType) => translation('pickupHowTo3', obj),
  /* 指定の時刻から選択 */
  pickupHowTo4: (obj?: I18nArgumentType) => translation('pickupHowTo4', obj),
  /* 自由に時刻を選択 */
  pickupHowTo5: (obj?: I18nArgumentType) => translation('pickupHowTo5', obj),
  /* 事前予約なし */
  pickupHowTo6: (obj?: I18nArgumentType) => translation('pickupHowTo6', obj),
  /* 当日集合 */
  pickupHowTo7: (obj?: I18nArgumentType) => translation('pickupHowTo7', obj),
  /* 事前予約 */
  pickupHowTo8: (obj?: I18nArgumentType) => translation('pickupHowTo8', obj),
  /* 当日呼び出し */
  pickupHowTo9: (obj?: I18nArgumentType) => translation('pickupHowTo9', obj),
  /* STEP1 */
  pickupHowTo10: (obj?: I18nArgumentType) => translation('pickupHowTo10', obj),
  /* 予約完了後、予約確認画面から申し込みができます。 */
  pickupHowTo11: (obj?: I18nArgumentType) => translation('pickupHowTo11', obj),
  /* ご希望の送迎方法と時間を選択して、「申請」ボタンをクリックしてください。 */
  pickupHowTo12: (obj?: I18nArgumentType) => translation('pickupHowTo12', obj),
  /* 当日、指定の集合場所に到着したら、呼び出しボタンを押して、送迎をお待ちください。 */
  pickupHowTo13: (obj?: I18nArgumentType) => translation('pickupHowTo13', obj),
  /* 事前予約 */
  pickupHowTo14: (obj?: I18nArgumentType) => translation('pickupHowTo14', obj),
  /* 指定の時刻から選択 */
  pickupHowTo15: (obj?: I18nArgumentType) => translation('pickupHowTo15', obj),
  /* STEP1 */
  pickupHowTo16: (obj?: I18nArgumentType) => translation('pickupHowTo16', obj),
  /* 予約完了後、予約確認画面から申し込みができます。 */
  pickupHowTo17: (obj?: I18nArgumentType) => translation('pickupHowTo17', obj),
  /* STEP2 */
  pickupHowTo18: (obj?: I18nArgumentType) => translation('pickupHowTo18', obj),
  /* 集合場所を選択し、指定された集合時間の中からお迎え時間をお選びください。 */
  pickupHowTo19: (obj?: I18nArgumentType) => translation('pickupHowTo19', obj),
  /* STEP3 */
  pickupHowTo20: (obj?: I18nArgumentType) => translation('pickupHowTo20', obj),
  /* 予約内容は予約詳細画面から確認できます。当日指定した集合場所、お時間でお待ちください。 */
  pickupHowTo21: (obj?: I18nArgumentType) => translation('pickupHowTo21', obj),
  /* 事前予約 */
  pickupHowTo22: (obj?: I18nArgumentType) => translation('pickupHowTo22', obj),
  /* 自由に時刻を選択 */
  pickupHowTo23: (obj?: I18nArgumentType) => translation('pickupHowTo23', obj),
  /* STEP1 */
  pickupHowTo24: (obj?: I18nArgumentType) => translation('pickupHowTo24', obj),
  /* 予約完了後、予約確認画面から申し込みができます。 */
  pickupHowTo25: (obj?: I18nArgumentType) => translation('pickupHowTo25', obj),
  /* STEP2 */
  pickupHowTo26: (obj?: I18nArgumentType) => translation('pickupHowTo26', obj),
  /* 集合場所を選択し、選択肢の中から集合時間の中からお迎え時間をご自由にお選びください。 */
  pickupHowTo27: (obj?: I18nArgumentType) => translation('pickupHowTo27', obj),
  /* STEP3 */
  pickupHowTo28: (obj?: I18nArgumentType) => translation('pickupHowTo28', obj),
  /* 予約内容は予約詳細画面から確認できます。当日指定した集合場所、お時間でお待ちください。 */
  pickupHowTo29: (obj?: I18nArgumentType) => translation('pickupHowTo29', obj),
  /* 事前予約不要 */
  pickupHowTo30: (obj?: I18nArgumentType) => translation('pickupHowTo30', obj),
  /* 当日集合 */
  pickupHowTo31: (obj?: I18nArgumentType) => translation('pickupHowTo31', obj),
  /* WAmazingStayからの予約は必要ございませんので、宿詳細画面から、「お迎えサービス」の箇所をご確認ください。
集合場所と時刻表の記載がございますので、そちらに従ってお待ちください。 */
  pickupHowTo32: (obj?: I18nArgumentType) => translation('pickupHowTo32', obj),
  /* 温泉宿を探してみる */
  introTrySearch: (obj?: I18nArgumentType) => translation('introTrySearch', obj),
  /* 温泉宿を検索 */
  introSearch: (obj?: I18nArgumentType) => translation('introSearch', obj),
  /* WAmazingStayの魅力 */
  wamazingCharm: (obj?: I18nArgumentType) => translation('wamazingCharm', obj),
  /* 日本の温泉宿に特化した
宿泊予約サービス */
  wamazingCharmDesc1: (obj?: I18nArgumentType) => translation('wamazingCharmDesc1', obj),
  /* 温泉宿を楽しみ尽くす
機能やサービスが充実 */
  wamazingCharmDesc2: (obj?: I18nArgumentType) => translation('wamazingCharmDesc2', obj),
  /* WAmazingStayで温泉宿を楽しむ方法を見る→ */
  onsenSeeHowTo: (obj?: I18nArgumentType) => translation('onsenSeeHowTo', obj),
  /* 当日何かトラブルがあっても、ネイティブオペレーターが対応するので、安心です。 */
  pickupOperatorDesc: (obj?: I18nArgumentType) => translation('pickupOperatorDesc', obj),
  /* 食事処・レストラン */
  mealsResto: (obj?: I18nArgumentType) => translation('mealsResto', obj),
  /* 温泉の種類 */
  onsenTypes: (obj?: I18nArgumentType) => translation('onsenTypes', obj),
  /* 温泉宿の温泉の種類は、主に3パターンあります。 */
  onsenTypesDesc: (obj?: I18nArgumentType) => translation('onsenTypesDesc', obj),
  /* 大衆浴場 */
  onsenType1: (obj?: I18nArgumentType) => translation('onsenType1', obj),
  /* 男女に分かれて入浴します。多くの温泉宿で、内風呂と露天風呂の2パターンああります。 */
  onsenType1Desc: (obj?: I18nArgumentType) => translation('onsenType1Desc', obj),
  /* 露天風呂つき個室 */
  onsenType2: (obj?: I18nArgumentType) => translation('onsenType2', obj),
  /* 宿泊部屋に露天風呂がついていれば、好きなときに温泉をいつでも楽しむことができます。 */
  onsenType2Desc: (obj?: I18nArgumentType) => translation('onsenType2Desc', obj),
  /* 貸切風呂 */
  onsenType3: (obj?: I18nArgumentType) => translation('onsenType3', obj),
  /* 時間単位で温泉を貸し切り、プライベートに楽しめます。WAmazingStayでは事前予約の代行が可能です。※温泉宿による */
  onsenType3Desc: (obj?: I18nArgumentType) => translation('onsenType3Desc', obj),
  /* 貸切風呂の予約方法 */
  onsenHowToUse: (obj?: I18nArgumentType) => translation('onsenHowToUse', obj),
  /* から、「貸切風呂の予約がしたい」とお問い合わせください。オペレーターが代わりに予約を代行いたします。 */
  onsenQuestionFormDesc: (obj?: I18nArgumentType) => translation('onsenQuestionFormDesc', obj),
  /* WAmazing Stayの魅力 */
  aboutTopBenefits: (obj?: I18nArgumentType) => translation('aboutTopBenefits', obj),
  /* 日本の温泉宿に特化した宿泊予約サービス */
  aboutTopOnsen: (obj?: I18nArgumentType) => translation('aboutTopOnsen', obj),
  /* 日本の温泉宿を楽しみ尽くす独自の機能やサービスが充実 */
  aboutTopUnique: (obj?: I18nArgumentType) => translation('aboutTopUnique', obj),
  /* お食事選択、調整 */
  aboutTopMeal: (obj?: I18nArgumentType) => translation('aboutTopMeal', obj),
  /* お迎えサービス */
  aboutTopPickup: (obj?: I18nArgumentType) => translation('aboutTopPickup', obj),
  /* 貸切風呂事前予約 */
  aboutTopBathRental: (obj?: I18nArgumentType) => translation('aboutTopBathRental', obj),
  /* サービスの詳細を見る */
  aboutTopSeeDetails: (obj?: I18nArgumentType) => translation('aboutTopSeeDetails', obj),
  /* WAmazing限定プラン */
  wamazingLimitedPlan: (obj?: I18nArgumentType) => translation('wamazingLimitedPlan', obj),
  /* 一部の温泉旅館ではWAmazingStayでの食事内容の調整ができない場合がございます。 */
  introMealAvailability: (obj?: I18nArgumentType) => translation('introMealAvailability', obj),
  /* 全て */
  yadoPhotoCategoryAll: (obj?: I18nArgumentType) => translation('yadoPhotoCategoryAll', obj),
  /* 外観 */
  yadoPhotoCategoryBuilding: (obj?: I18nArgumentType) =>
    translation('yadoPhotoCategoryBuilding', obj),
  /* 写真一覧 */
  yadoPhotoGallery: (obj?: I18nArgumentType) => translation('yadoPhotoGallery', obj),
  /* 送迎サービスの予約方法 */
  pickupHowToUse: (obj?: I18nArgumentType) => translation('pickupHowToUse', obj),
  /* 温泉宿までの{{underline}} */
  introGettingFromPre: (obj?: I18nArgumentType) => translation('introGettingFromPre', obj),
  /* 移動が大変そう */
  introGettingFromUnderline: (obj?: I18nArgumentType) =>
    translation('introGettingFromUnderline', obj),
  /* 温泉宿の{{underline}}を、お客様に代わって無料で手配 */
  introWeCanArrangePre: (obj?: I18nArgumentType) => translation('introWeCanArrangePre', obj),
  /* お迎えサービス */
  introWeCanArrangeUnderline: (obj?: I18nArgumentType) =>
    translation('introWeCanArrangeUnderline', obj),
  /* 温泉宿の食事内容が{{underline}}がある */
  introNotSureFoodPre: (obj?: I18nArgumentType) => translation('introNotSureFoodPre', obj),
  /* 食べられるか不安 */
  introNotSureFoodUnderline: (obj?: I18nArgumentType) =>
    translation('introNotSureFoodUnderline', obj),
  /* {{underline}}を、お客様に代わって事前に対応 */
  introAdjustMenuPre: (obj?: I18nArgumentType) => translation('introAdjustMenuPre', obj),
  /* 食事内容の調整 */
  introAdjustMenuUnderline: (obj?: I18nArgumentType) =>
    translation('introAdjustMenuUnderline', obj),
  /* {{underline}}、正しく入れているか不安がある */
  introNotSureBathPre: (obj?: I18nArgumentType) => translation('introNotSureBathPre', obj),
  /* 入浴ルールが多く */
  introNotSureBathUnderline: (obj?: I18nArgumentType) =>
    translation('introNotSureBathUnderline', obj),
  /* 貸切風呂を事前に予約して、{{underline}}で温泉を楽しむ */
  introPrivateBathPre: (obj?: I18nArgumentType) => translation('introPrivateBathPre', obj),
  /* 貸切風呂 */
  introPrivateBathUnderline: (obj?: I18nArgumentType) =>
    translation('introPrivateBathUnderline', obj),
  /* あり */
  webYadoServiceProvide: (obj?: I18nArgumentType) => translation('webYadoServiceProvide', obj),
  /* なし */
  webYadoServiceNone: (obj?: I18nArgumentType) => translation('webYadoServiceNone', obj),
  /* 限定プラン */
  webYadoWamazingExclusive: (obj?: I18nArgumentType) =>
    translation('webYadoWamazingExclusive', obj),
  /* 関東 (東京) */
  kantoMidName: (obj?: I18nArgumentType) => translation('kantoMidName', obj),
  /* 関西 (大阪) */
  kansaiMidName: (obj?: I18nArgumentType) => translation('kansaiMidName', obj),
  /* 定山渓 */
  pjtJozankei: (obj?: I18nArgumentType) => translation('pjtJozankei', obj),
  /* 北海道 */
  pjtHokkaido: (obj?: I18nArgumentType) => translation('pjtHokkaido', obj),
  /* 新千歳空港からバスで
1時間40分 */
  pjtJozankeiAccess: (obj?: I18nArgumentType) => translation('pjtJozankeiAccess', obj),
  /* 洞爺湖 */
  pjtToya: (obj?: I18nArgumentType) => translation('pjtToya', obj),
  /* 湯の川 */
  pjtYunokawa: (obj?: I18nArgumentType) => translation('pjtYunokawa', obj),
  /* 函館空港からバスで5分 */
  pjtYunokawaAccess: (obj?: I18nArgumentType) => translation('pjtYunokawaAccess', obj),
  /* 湯河原 */
  pjtYugawara: (obj?: I18nArgumentType) => translation('pjtYugawara', obj),
  /* 神奈川県  */
  pjtKanagawaKanto: (obj?: I18nArgumentType) => translation('pjtKanagawaKanto', obj),
  /* 関東 */
  pjtKanto: (obj?: I18nArgumentType) => translation('pjtKanto', obj),
  /* 成田空港から特急、
新幹線で2時間弱 */
  pjtYugawaraAccess: (obj?: I18nArgumentType) => translation('pjtYugawaraAccess', obj),
  /* 富士河口湖 */
  pjtFuji: (obj?: I18nArgumentType) => translation('pjtFuji', obj),
  /* 成田空港から特急、
バスで3時間弱 */
  pjtFujiAccess: (obj?: I18nArgumentType) => translation('pjtFujiAccess', obj),
  /* 加賀温泉郷 */
  pjtKaga: (obj?: I18nArgumentType) => translation('pjtKaga', obj),
  /* 石川県 */
  pjtIshikawa: (obj?: I18nArgumentType) => translation('pjtIshikawa', obj),
  /* 中部 */
  pjtChubu: (obj?: I18nArgumentType) => translation('pjtChubu', obj),
  /* 和倉 */
  pjtWakura: (obj?: I18nArgumentType) => translation('pjtWakura', obj),
  /* 小松空港からバスで40分、
特急で1時間 */
  pjtWakuraAccess: (obj?: I18nArgumentType) => translation('pjtWakuraAccess', obj),
  /* 黒川 */
  pjtKurokawa: (obj?: I18nArgumentType) => translation('pjtKurokawa', obj),
  /* 熊本県  */
  pjtKumamoto: (obj?: I18nArgumentType) => translation('pjtKumamoto', obj),
  /* 九州 */
  pjtKyushu: (obj?: I18nArgumentType) => translation('pjtKyushu', obj),
  /* 熊本空港からバスで3時間 */
  pjtKumamotoAccess: (obj?: I18nArgumentType) => translation('pjtKumamotoAccess', obj),
  /* 別府 */
  pjtBeppu: (obj?: I18nArgumentType) => translation('pjtBeppu', obj),
  /* 大分県  */
  pjtOita: (obj?: I18nArgumentType) => translation('pjtOita', obj),
  /* 福岡空港からバスで2時間15分 */
  pjtBeppuAccess: (obj?: I18nArgumentType) => translation('pjtBeppuAccess', obj),
  /* 由布院温泉 */
  pjtYufuin: (obj?: I18nArgumentType) => translation('pjtYufuin', obj),
  /* 福岡空港からバスで2時間25分 */
  pjtYufuinAccess: (obj?: I18nArgumentType) => translation('pjtYufuinAccess', obj),
  /* 指宿 */
  pjtIbusuki: (obj?: I18nArgumentType) => translation('pjtIbusuki', obj),
  /* 鹿児島県 */
  pjtKagoshima: (obj?: I18nArgumentType) => translation('pjtKagoshima', obj),
  /* 鹿児島空港からバスで
1時間40分 */
  pjtIbusukiAccess: (obj?: I18nArgumentType) => translation('pjtIbusukiAccess', obj),
  /* 小松空港からバスで30分 */
  pjtKagaAccess: (obj?: I18nArgumentType) => translation('pjtKagaAccess', obj),
  /* 熊本空港からバスで3時間 */
  pjtKurokawaAccess: (obj?: I18nArgumentType) => translation('pjtKurokawaAccess', obj),
  /* プラン詳細 */
  planDetail: (obj?: I18nArgumentType) => translation('planDetail', obj),
  /* 施設概要 */
  exclusivePlanDetailBasicInfo: (obj?: I18nArgumentType) =>
    translation('exclusivePlanDetailBasicInfo', obj),
  /* プラン予約 */
  exclusivePlanDetailPlan: (obj?: I18nArgumentType) => translation('exclusivePlanDetailPlan', obj),
  /* 施設詳細 */
  exclusivePlanDetailExperience: (obj?: I18nArgumentType) =>
    translation('exclusivePlanDetailExperience', obj),
  /* 宿泊体験 */
  exclusivePlanDetailLifestyle: (obj?: I18nArgumentType) =>
    translation('exclusivePlanDetailLifestyle', obj),
  /* 概要 */
  exclusivePlanDetailBasicInfoSectionSummary: (obj?: I18nArgumentType) =>
    translation('exclusivePlanDetailBasicInfoSectionSummary', obj),
  /* 特長 */
  exclusivePlanDetailBasicInfoSectionFeatures: (obj?: I18nArgumentType) =>
    translation('exclusivePlanDetailBasicInfoSectionFeatures', obj),
  /* アクセス */
  exclusivePlanDetailBasicInfoSectionAccess: (obj?: I18nArgumentType) =>
    translation('exclusivePlanDetailBasicInfoSectionAccess', obj),
  /* 施設情報 */
  exclusivePlanDetailBasicInfoSectionFacility: (obj?: I18nArgumentType) =>
    translation('exclusivePlanDetailBasicInfoSectionFacility', obj),
  /* 魅力的な宿泊体験 */
  exclusivePlanDetailBasicInfoSectionFeaturesTitle: (obj?: I18nArgumentType) =>
    translation('exclusivePlanDetailBasicInfoSectionFeaturesTitle', obj),
  /* アクセス情報 */
  exclusivePlanDetailBasicInfoSectionAccessTitle: (obj?: I18nArgumentType) =>
    translation('exclusivePlanDetailBasicInfoSectionAccessTitle', obj),
  /* 全ての写真を見る */
  exclusivePlanDetailSeeAllPhotos: (obj?: I18nArgumentType) =>
    translation('exclusivePlanDetailSeeAllPhotos', obj),
  /* 人気の施設 / サービス */
  exclusivePlanDetailPopularFacilityAndService: (obj?: I18nArgumentType) =>
    translation('exclusivePlanDetailPopularFacilityAndService', obj),
  /* 詳しく見る */
  exclusivePlanDetailExperienceBtnText: (obj?: I18nArgumentType) =>
    translation('exclusivePlanDetailExperienceBtnText', obj),
  /* この宿は WAmazing Stay 経由で貸切風呂予約が可能です */
  exclusivePlanDetailFacilityAvailablePrivateBathDescription: (obj?: I18nArgumentType) =>
    translation('exclusivePlanDetailFacilityAvailablePrivateBathDescription', obj),
  /* この宿は WAmazing Stay 経由でお迎えサービスが利用可能です */
  exclusivePlanDetailFacilityAvailablePickupDescription: (obj?: I18nArgumentType) =>
    translation('exclusivePlanDetailFacilityAvailablePickupDescription', obj),
  /* 施設の情報を詳しく見る */
  exclusivePlanDetailSeeAccessInfo: (obj?: I18nArgumentType) =>
    translation('exclusivePlanDetailSeeAccessInfo', obj),
  /* 交通チケットを見る */
  exclusivePlanDetailSeeTickets: (obj?: I18nArgumentType) =>
    translation('exclusivePlanDetailSeeTickets', obj),
  /* お風呂の写真を見る */
  exclusivePlanDetailSeeBathPhotos: (obj?: I18nArgumentType) =>
    translation('exclusivePlanDetailSeeBathPhotos', obj),
  /* WAmazing Stay限定プラン */
  exclusivePlanWaStay: (obj?: I18nArgumentType) => translation('exclusivePlanWaStay', obj),
  /* 温泉 */
  exclusivePlanDetailHotSpring: (obj?: I18nArgumentType) =>
    translation('exclusivePlanDetailHotSpring', obj),
  /* 部屋 */
  exclusivePlanDetailRoom: (obj?: I18nArgumentType) => translation('exclusivePlanDetailRoom', obj),
  /* 食事 */
  exclusivePlanDetailMeal: (obj?: I18nArgumentType) => translation('exclusivePlanDetailMeal', obj),
  /* その他 */
  exclusivePlanDetailOther: (obj?: I18nArgumentType) =>
    translation('exclusivePlanDetailOther', obj),
  /* 詳しく見る */
  exclusivePlanViewDetails: (obj?: I18nArgumentType) =>
    translation('exclusivePlanViewDetails', obj),
  /* おすすめのプラン */
  exclusivePlanRecommendedPlan: (obj?: I18nArgumentType) =>
    translation('exclusivePlanRecommendedPlan', obj),
  /* 投稿日 */
  exclusivePlanLifestyleDatePosted: (obj?: I18nArgumentType) =>
    translation('exclusivePlanLifestyleDatePosted', obj),
  /* 1日目 */
  dayOne: (obj?: I18nArgumentType) => translation('dayOne', obj),
  /* 2日目 */
  dayTwo: (obj?: I18nArgumentType) => translation('dayTwo', obj),
  /* 今回紹介してくれたのは… */
  exclusivePlanLifestyleRecommended: (obj?: I18nArgumentType) =>
    translation('exclusivePlanLifestyleRecommended', obj),
  /* 今回紹介したプラン */
  exclusivePlanLifestyleRecommendedPlan: (obj?: I18nArgumentType) =>
    translation('exclusivePlanLifestyleRecommendedPlan', obj),
  /* 新しい日本を発見 */
  exclusivePlanTopKvText: (obj?: I18nArgumentType) => translation('exclusivePlanTopKvText', obj),
  /* サービス詳細 */
  exclusivePlanTopServiceDetails: (obj?: I18nArgumentType) =>
    translation('exclusivePlanTopServiceDetails', obj),
  /* WAmazing「限定プラン」がある宿 */
  exclusivePlanTopExclusiveYados: (obj?: I18nArgumentType) =>
    translation('exclusivePlanTopExclusiveYados', obj),
  /* すべてを見る */
  exclusivePlanSeeAll: (obj?: I18nArgumentType) => translation('exclusivePlanSeeAll', obj),
  /* 直近1ヶ月間の人気ランキング */
  monthlyPopular: (obj?: I18nArgumentType) => translation('monthlyPopular', obj),
  /* 特集から探す */
  exclusivePlanTopFeatured: (obj?: I18nArgumentType) =>
    translation('exclusivePlanTopFeatured', obj),
  /* 人気温泉地から探す */
  exclusivePlanTopSearchPopularOnsenArea: (obj?: I18nArgumentType) =>
    translation('exclusivePlanTopSearchPopularOnsenArea', obj),
  /* 都道府県から探す */
  exclusivePlanTopSearchByPrefecture: (obj?: I18nArgumentType) =>
    translation('exclusivePlanTopSearchByPrefecture', obj),
  /* 全国 */
  nationwide: (obj?: I18nArgumentType) => translation('nationwide', obj),
  /* 食事調整代行 */
  accommodationsForAllergies: (obj?: I18nArgumentType) =>
    translation('accommodationsForAllergies', obj),
  /* 掲載されている部屋は一部です */
  experienceNoteForRoom: (obj?: I18nArgumentType) => translation('experienceNoteForRoom', obj),
  /* 掲載されている食事は一部であり、選択するプランや仕入などの状況により実際の内容と異なる場合があります */
  experienceNoteForMeal: (obj?: I18nArgumentType) => translation('experienceNoteForMeal', obj),
  /* 約1,300年の歴史をもつ湯河原温泉。古くから「薬師の湯」や「傷の湯」とされ、保養湯治場として利用されていた歴史を持ちます。渓谷に囲まれた静かな地にあり、昔ながらの趣ある街並みが広がっています。都内から1時間半とアクセスもよく、人気の温泉地です。有名な観光スポットの万葉公園をはじめとして、日帰り温泉や足湯など、気軽に楽しめる温泉スポットが充実しているので、自然に囲まれた閑静な土地で温泉を楽しみたい人におすすめです。 */
  webYadoOnsenAreaYugawaraDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaYugawaraDescription', obj),
  /* 箱根温泉は、東京から１時間半と車・電車共にアクセスの良い人気温泉地です。日本有数の温泉街があり、重要文化財級の老舗旅館から純和風旅館、大型リゾートホテルなど、趣の違う旅が楽しめるのも人気の秘密です。あじさい電車で人気の箱根登山鉄道で標高700m付近まで登ることができ、海沿いから様々な風景が楽しめます。新緑や紅葉の時期は特に人気で、箱根美術館や強羅公園、芦ノ湖、仙石原のすすき野原等の名所は賑わいを見せます。 */
  webYadoOnsenAreaHakoneDescription: (obj?: I18nArgumentType) =>
    translation('WebYado-OnsenAreaHakoneDescription', obj),
  /* プランを探す */
  findPlans: (obj?: I18nArgumentType) => translation('findPlans', obj),
  /* 日本最大級の宿泊予約サイトの元創業メンバーを中心に運営する、温泉旅館・ホテルの予約サービスに特化したサービス */
  yadoTopServiceDescription: (obj?: I18nArgumentType) =>
    translation('yadoTopServiceDescription', obj),
  /* WAmazing株式会社とは */
  waCardCompanyInfo1: (obj?: I18nArgumentType) => translation('waCardCompanyInfo1', obj),
  /* 日本最大級の宿泊予約サイトの元創業メンバーを中心に運営する、温泉旅館・ホテルの予約サービスに特化したサービス */
  waCardCompanyInfo2: (obj?: I18nArgumentType) => translation('waCardCompanyInfo2', obj),
  /* 訪日リピーターも満足する、日本の地方を中心に厳選した旅行商品を提供 */
  waCardCompanyInfo3: (obj?: I18nArgumentType) => translation('waCardCompanyInfo3', obj),
  /* 宿泊・観光・買物・交通・飲食の手配サービスを提供 */
  waCardCompanyInfo4: (obj?: I18nArgumentType) => translation('waCardCompanyInfo4', obj),
  /* 全国24の空港（2023年7月時点）で無料SIMカードを配布 */
  waCardCompanyInfo5: (obj?: I18nArgumentType) => translation('waCardCompanyInfo5', obj),
  /* 初めての方 */
  forFirstTimer: (obj?: I18nArgumentType) => translation('forFirstTimer', obj),
  /* ※今回は取材の関係で大浴場や露天風呂で写真を撮りましたが、一般的に大浴場・露天風呂・脱衣場の撮影は禁止されています。 */
  exclusivePlanLifestyleNote: (obj?: I18nArgumentType) =>
    translation('exclusivePlanLifestyleNote', obj),
  /* この日付では完売 */
  yadoSoldOut: (obj?: I18nArgumentType) => translation('yadoSoldOut', obj),
  /* プランの詳細だけ見る */
  yadoShowDetailsOnly: (obj?: I18nArgumentType) => translation('yadoShowDetailsOnly', obj),
  /* 在庫ありのみ表示 */
  webYadoAvailableOnly: (obj?: I18nArgumentType) => translation('webYadoAvailableOnly', obj),
  /* 成人の人数 */
  webYadoLabelNumberOfAdults: (obj?: I18nArgumentType) =>
    translation('webYadoLabelNumberOfAdults', obj),
  /* チケットの受け取り場所の地図はこちら */
  ticketPickupLocationMap: (obj?: I18nArgumentType) => translation('ticketPickupLocationMap', obj),
  /* ※ミドルネームをお持ちの方は名の欄にご記入ください */
  webYadoMiddleNameValidationRule: (obj?: I18nArgumentType) =>
    translation('webYadoMiddleNameValidationRule', obj),
  /* 割引後 */
  yadoAfterDiscountApplied: (obj?: I18nArgumentType) =>
    translation('yadoAfterDiscountApplied', obj),
  /* WAmazingStayで予約できる{{area}}の貸切風呂のある温泉宿！ */
  yadosOnsenMetaExtraDescription: (obj?: I18nArgumentType) =>
    translation('yadosOnsenMetaExtraDescription', obj),
  /* {{area}}の貸切風呂のある人気温泉宿 */
  yadosOnsenTitle: (obj?: I18nArgumentType) => translation('yadosOnsenTitle', obj),
  /* {{area}}の貸切風呂のある温泉宿 */
  yadosOnsenH1: (obj?: I18nArgumentType) => translation('yadosOnsenH1', obj),
  /* 正しい携帯電話番号を入力してください */
  errorMessagePhoneNumberValidation: (obj?: I18nArgumentType) =>
    translation('errorMessagePhoneNumberValidation', obj),
  /* 詳細エリア */
  listBottomLinksTitle: (obj?: I18nArgumentType) => translation('listBottomLinksTitle', obj),
  /* 人気キーワード */
  popularKeywords: (obj?: I18nArgumentType) => translation('popularKeywords', obj),
  /* 人気都道府県 */
  popularPrefectures: (obj?: I18nArgumentType) => translation('popularPrefectures', obj),
  /* LINEで相談する */
  supportConsultationViaLine: (obj?: I18nArgumentType) =>
    translation('supportConsultationViaLine', obj),
  /* 友だち追加 */
  supportAddFriends: (obj?: I18nArgumentType) => translation('supportAddFriends', obj),
  /* チャットで相談する */
  supportConsultationViaChat: (obj?: I18nArgumentType) =>
    translation('supportConsultationViaChat', obj),
  /* チャットを開く */
  supportOpenChat: (obj?: I18nArgumentType) => translation('supportOpenChat', obj),
  /* 施設へのリクエストがありましたら、チャットまたはLINEにてお伝えください。
WAmazing温泉宿サポートが施設に確認をします。 */
  supportConfirmWithYados: (obj?: I18nArgumentType) => translation('supportConfirmWithYados', obj),
  /* 相談する */
  supportConsultation: (obj?: I18nArgumentType) => translation('supportConsultation', obj),
  /* 温泉宿について何か疑問や困ったことがありますか？
海外のお客様でも安心してご利用いただける環境を提供いたします！ */
  supportOnsenYasdoServiceDetail1: (obj?: I18nArgumentType) =>
    translation('supportOnsenYasdoServiceDetail1', obj),
  /* こういった問題はすべて私たちにお任せください！ */
  supportOnsenYasdoServiceDetail2: (obj?: I18nArgumentType) =>
    translation('supportOnsenYasdoServiceDetail2', obj),
  /* 一泊二食 */
  hasTwoMeals: (obj?: I18nArgumentType) => translation('hasTwoMeals', obj),
  /* WAmazingStayで予約できる{{area}}の一泊二食つきの温泉宿！ */
  hasTwoMealsMetaExtraDescription: (obj?: I18nArgumentType) =>
    translation('hasTwoMealsMetaExtraDescription', obj),
  /* {{area}}の一泊二食つきの人気温泉宿 */
  hasTwoMealsTitle: (obj?: I18nArgumentType) => translation('hasTwoMealsTitle', obj),
  /* {{area}}の一泊二食つきの温泉宿 */
  hasTwoMealsH1: (obj?: I18nArgumentType) => translation('hasTwoMealsH1', obj),
  /* 一泊二食つきの温泉宿 */
  hasTwoMealsReservableYado: (obj?: I18nArgumentType) =>
    translation('hasTwoMealsReservableYado', obj),
  /* 一泊二食つきの温泉宿一覧 */
  hasTwoMealsReservableYadoList: (obj?: I18nArgumentType) =>
    translation('hasTwoMealsReservableYadoList', obj),
  /* 一泊二食付きの宿を探す */
  twoMealsKeywords: (obj?: I18nArgumentType) => translation('twoMealsKeywords', obj),
  /* 露天風呂付きの宿を探す */
  privateBathKeywords: (obj?: I18nArgumentType) => translation('privateBathKeywords', obj),
  /* 10:00〜19:00（日本時間） */
  supportLPBusinessHoursText1: (obj?: I18nArgumentType) =>
    translation('supportLPBusinessHoursText1', obj),
  /* ※上記以外の時間帯にもメッセージを送ることは可能です。 */
  supportLPBusinessHoursText2: (obj?: I18nArgumentType) =>
    translation('supportLPBusinessHoursText2', obj),
  /* 営業時間 */
  supportLPBusinessHoursTitle: (obj?: I18nArgumentType) =>
    translation('supportLPBusinessHoursTitle', obj),
  /* その他、宿泊に関して相談したいことがあれば、サポートいたします。 */
  supportLPContentComment: (obj?: I18nArgumentType) => translation('supportLPContentComment', obj),
  /* サポート内容 */
  supportLPContentTItle: (obj?: I18nArgumentType) => translation('supportLPContentTItle', obj),
  /* 利用はすべて無料です */
  supportLPFAQAnswer1: (obj?: I18nArgumentType) => translation('supportLPFAQAnswer1', obj),
  /* 他のホテルなどの宿泊先と比較して、温泉宿に特化させています。温泉宿は独自のルールや習慣があるため、不安なく宿泊していただきたいとの願いから、WAmazing Stayではサポートを行っています。
具体的には、食事や温泉な文化の違いで生じる不安に対するサポート、それ以外にも宿泊に関して相談したいことがあればお気軽にご相談ください。 */
  supportLPFAQAnswer2: (obj?: I18nArgumentType) => translation('supportLPFAQAnswer2', obj),
  /* サポートサービスの利用は無料ですか？ */
  supportLPFAQQuestion1: (obj?: I18nArgumentType) => translation('supportLPFAQQuestion1', obj),
  /* 他の予約サイトとどんな違いがありますか？ */
  supportLPFAQQuestion2: (obj?: I18nArgumentType) => translation('supportLPFAQQuestion2', obj),
  /* よくある質問 */
  supportLPFAQTitle: (obj?: I18nArgumentType) => translation('supportLPFAQTitle', obj),
  /* 日本の温泉旅館には特別な規則や礼儀があります。
海外のお客様でも安心してご滞在いただける環境をご提供いたします！ */
  supportLPIntroductionText1: (obj?: I18nArgumentType) =>
    translation('supportLPIntroductionText1', obj),
  /* サポートサービスの利用は無料です。 */
  supportLPIntroductionText2: (obj?: I18nArgumentType) =>
    translation('supportLPIntroductionText2', obj),
  /* 完美行溫泉飯店・旅館溫泉真人小幫手とは */
  supportLPIntroductionTitle: (obj?: I18nArgumentType) =>
    translation('supportLPIntroductionTitle', obj),
  /* これは日本の旅行専門家「完美行」のサービスであり、完美行温泉ホテル・旅館の支援サービスです。海外の旅行者も安心して滞在できる環境を提供します！私は菜食主義者ですので、食事内容の調整が希望です。大浴場が苦手なので、プライベート温泉の予約が希望です。交通手段に自信がないので、送迎サービスの手配をお願いしたいです、など.. */
  supportLPMetaDescription: (obj?: I18nArgumentType) =>
    translation('supportLPMetaDescription', obj),
  /* 完美行温泉ホテル・旅館支援サービス ｜完美行温泉ホテル・旅館 */
  supportLPMetaTitle: (obj?: I18nArgumentType) => translation('supportLPMetaTitle', obj),
  /* 相談方法を、LINE or チャットから選んでください */
  supportLPUsage1: (obj?: I18nArgumentType) => translation('supportLPUsage1', obj),
  /* ※ブラウザを閉じて返事を待つ場合や、会話の記録を保持したい場合は、LINEを使用して相談することをお勧めします。 */
  supportLPUsage1Comment: (obj?: I18nArgumentType) => translation('supportLPUsage1Comment', obj),
  /* ご相談内容を入力してください。 */
  supportLPUsage2: (obj?: I18nArgumentType) => translation('supportLPUsage2', obj),
  /* 使い方 */
  supportLPUsageTitle: (obj?: I18nArgumentType) => translation('supportLPUsageTitle', obj),
  /* 税・その他費用含む */
  webYadoTaxAndFeesIncluded: (obj?: I18nArgumentType) =>
    translation('webYadoTaxAndFeesIncluded', obj),
  /* 合計金額（税・その他費用含む） */
  webYadoTotalPriceWithDetail: (obj?: I18nArgumentType) =>
    translation('webYadoTotalPriceWithDetail', obj),
  /* 貸切風呂手配 */
  supportServiceprivateBath: (obj?: I18nArgumentType) =>
    translation('supportServiceprivateBath', obj),
  /* お迎え手配 */
  supportServicePickup: (obj?: I18nArgumentType) => translation('supportServicePickup', obj),
  /* 食事カスタマイズ手配 */
  supportServiceMeal: (obj?: I18nArgumentType) => translation('supportServiceMeal', obj),
  /* 在庫確認 */
  supportServiceRoomAvailability: (obj?: I18nArgumentType) =>
    translation('supportServiceRoomAvailability', obj),
  /* 荷物預かり可否確認 */
  supportServiceLuggage: (obj?: I18nArgumentType) => translation('supportServiceLuggage', obj),
  /* チェックイン時間変更連絡 */
  supportServiceCheckIn: (obj?: I18nArgumentType) => translation('supportServiceCheckIn', obj),
  /* ケーキ手配可否確認 */
  supportServiceCake: (obj?: I18nArgumentType) => translation('supportServiceCake', obj),
  /* 宿へのアクセス方法提案 */
  supportServiceAccess: (obj?: I18nArgumentType) => translation('supportServiceAccess', obj),
  /* 宿周辺観光プラン提案 */
  supportServiceNearbyAttractions: (obj?: I18nArgumentType) =>
    translation('supportServiceNearbyAttractions', obj),
  /* 在庫がある場合があります */
  supportInventoryMaybeAvailable: (obj?: I18nArgumentType) =>
    translation('supportInventoryMaybeAvailable', obj),
  /* 宿泊サポートに問い合わせる */
  supportOnsenYasdoContact: (obj?: I18nArgumentType) =>
    translation('supportOnsenYasdoContact', obj),
  /* あなたにぴったりの温泉を見つけよう */
  supportHeader: (obj?: I18nArgumentType) => translation('supportHeader', obj),
  /* LINE
相談も
できる */
  supportHeaderLine: (obj?: I18nArgumentType) => translation('supportHeaderLine', obj),
  /* 温泉に関する疑問や質問に答えます！ */
  supportAnswer: (obj?: I18nArgumentType) => translation('supportAnswer', obj),
  /* 「温泉街で街歩きをしたいけど、どこの温泉地がいいかな？」
「大阪に観光に行く予定だけど、どこの温泉地がいいかな？」
「宿泊したい温泉宿があるんだけど、貸切風呂を事前手配してほしい」 */
  supportQuestions: (obj?: I18nArgumentType) => translation('supportQuestions', obj),
  /* 日本の温泉に精通したコンシェルジュが、日本の温泉を体験する上での疑問や質問に中国語でお答えします！ 
宿泊施設の予約やお迎え、貸切風呂、食事のカスタマイズ相談も可能です。 */
  answerDetails: (obj?: I18nArgumentType) => translation('answerDetails', obj),
  /* サポートは無料で土日祝日含めて全日対応中！ */
  supportFree: (obj?: I18nArgumentType) => translation('supportFree', obj),
  /* どんなことを相談できるの？ */
  supportConsult: (obj?: I18nArgumentType) => translation('supportConsult', obj),
  /* 日本の温泉に興味はあるけど、まだ具体的には決めていない。不安もある。  */
  supportQ1: (obj?: I18nArgumentType) => translation('supportQ1', obj),
  /* あなたにぴったりの温泉地選びをお手伝いします！ */
  supportA1: (obj?: I18nArgumentType) => translation('supportA1', obj),
  /* 今回旅行するエリアの中でベストな温泉地はどこ？（例：大阪周辺） */
  supportAD1: (obj?: I18nArgumentType) => translation('supportAD1', obj),
  /* 今回旅行する季節にベストな温泉地はどこ？（例：6月） */
  supportAD2: (obj?: I18nArgumentType) => translation('supportAD2', obj),
  /* 旅行の目的や同行者からしてベストな温泉地はどこ？（例：子連れの誕生日旅行） */
  supportAD3: (obj?: I18nArgumentType) => translation('supportAD3', obj),
  /* あなたの疑問や不安を解消するお手伝いをします！ */
  supportA2: (obj?: I18nArgumentType) => translation('supportA2', obj),
  /* 日本の温泉はタトゥーがあっても入れる？ */
  supportAD4: (obj?: I18nArgumentType) => translation('supportAD4', obj),
  /* 大浴場での入浴に抵抗があるんだけどどう？ */
  supportAD5: (obj?: I18nArgumentType) => translation('supportAD5', obj),
  /* 行きにくい場所にあることが多いイメージだけどどう？ */
  supportAD6: (obj?: I18nArgumentType) => translation('supportAD6', obj),
  /* 行きたい温泉地は決まったが、温泉宿が決まっていない */
  supportQ2: (obj?: I18nArgumentType) => translation('supportQ2', obj),
  /* あなたにぴったりの温泉宿選びをお手伝いします！ */
  supportA3: (obj?: I18nArgumentType) => translation('supportA3', obj),
  /* おすすめの温泉宿を知りたい */
  supportAD7: (obj?: I18nArgumentType) => translation('supportAD7', obj),
  /* 貸切風呂が手配できる温泉宿を知りたい */
  supportAD8: (obj?: I18nArgumentType) => translation('supportAD8', obj),
  /* ベジタリアンメニューに対応した温泉宿を知りたい */
  supportAD9: (obj?: I18nArgumentType) => translation('supportAD9', obj),
  /* 無料お迎えサービスに対応している温泉宿を知りたい */
  supportAD10: (obj?: I18nArgumentType) => translation('supportAD10', obj),
  /* 幼児・子供向けのサービスがある温泉宿を知りたい */
  supportAD11: (obj?: I18nArgumentType) => translation('supportAD11', obj),
  /* 部屋のベッドサイズが知りたい */
  supportAD12: (obj?: I18nArgumentType) => translation('supportAD12', obj),
  /* 温泉宿を予約はしたが、宿泊をサポートしてほしい */
  supportQ3: (obj?: I18nArgumentType) => translation('supportQ3', obj),
  /* あなたの温泉宿での満足のいく滞在をお手伝いします！ */
  supportA4: (obj?: I18nArgumentType) => translation('supportA4', obj),
  /* 貸切風呂を事前に手配してほしい */
  supportAD13: (obj?: I18nArgumentType) => translation('supportAD13', obj),
  /* ベジタリアンメニューを事前に手配してほしい */
  supportAD14: (obj?: I18nArgumentType) => translation('supportAD14', obj),
  /* 駐車場を事前に手配してほしい */
  supportAD15: (obj?: I18nArgumentType) => translation('supportAD15', obj),
  /* チェックインしたが宿泊施設とのやり取りに困っている */
  supportAD16: (obj?: I18nArgumentType) => translation('supportAD16', obj),
  /* 帰りの送迎について施設に相談したい */
  supportAD17: (obj?: I18nArgumentType) => translation('supportAD17', obj),
  /* 滞在中の観光について施設に相談したい */
  supportAD18: (obj?: I18nArgumentType) => translation('supportAD18', obj),
  /* 相談イメージ */
  supportExampleH: (obj?: I18nArgumentType) => translation('supportExampleH', obj),
  /* 大阪に旅行に行くので、大阪から行きやすいおすすめの温泉宿を教えてほしい */
  supportExample1: (obj?: I18nArgumentType) => translation('supportExample1', obj),
  /* 有馬温泉は大阪駅から1時間で行けるので、大阪から気軽に近場で温泉に行きたいという方にはおすすめの温泉地です。旅館でゆっくり滞在したい、食事を楽しみたいという方には有馬温泉がおすすめです。
大阪から3時間と少し遠いですが、城崎温泉はもおすすめです。こちらもJRパスを使っていくことができます。7つの外湯を巡る湯めぐりは有名で、さまざまな温泉を楽しみたいという人や、風情のある温泉街を歩きたいという人にはおすすめの温泉地です。 */
  supportExample3: (obj?: I18nArgumentType) => translation('supportExample3', obj),
  /* 大川庄の貸切温泉宿の予約を手伝ってもらえますか？優先期間は21:00～21:45です。 */
  supportExample2: (obj?: I18nArgumentType) => translation('supportExample2', obj),
  /* 大川庄の貸切温泉宿の予約を手伝ってもらえますか？優先期間は21:00～21:45です。 */
  supportExample4: (obj?: I18nArgumentType) => translation('supportExample4', obj),
  /* 温泉コンシェルジュの特長 */
  supportFeatureH: (obj?: I18nArgumentType) => translation('supportFeatureH', obj),
  /* 温泉に特化した日本発の温泉宿サイトが運営 */
  supportFeature1: (obj?: I18nArgumentType) => translation('supportFeature1', obj),
  /* 中国語で、LINEで
簡単に相談できる */
  supportFeature2: (obj?: I18nArgumentType) => translation('supportFeature2', obj),
  /* 相談だけでもOK！ */
  supportFeature3: (obj?: I18nArgumentType) => translation('supportFeature3', obj),
  /* Q.温泉コンシェルジュってどんな人？ */
  supportFAQ1: (obj?: I18nArgumentType) => translation('supportFAQ1', obj),
  /* WAmazingの温泉コンシェルジュは、日本の温泉に精通した現地出身のスタッフで構成されています。例えば、中国語を母国語とするスタッフがおり、日本語も流暢ですので、あなたの質問や疑問に的確に応答できるだけでなく、日本の宿泊施設と直接やり取りをして、あなたの確認事項や特別な要望を伝えることが可能です。一人一人のゲストに合わせたカスタマイズされたサービスを提供することを心掛けています。 */
  supportFAQA1: (obj?: I18nArgumentType) => translation('supportFAQA1', obj),
  /* Q.利用可能な曜日や時間は？ */
  supportFAQ2: (obj?: I18nArgumentType) => translation('supportFAQ2', obj),
  /* ご質問は24時間いつでもお受けしておりますので、お気軽にチャットやLINEでご質問ください！
ご質問に対する回答は、土日祝日を含む毎日、日本時間の10時から19時まで迅速に対応させていただいています。営業時間内であれば、お問い合わせいただいてから3時間以内の返答を心掛けております。 */
  supportFAQA2: (obj?: I18nArgumentType) => translation('supportFAQA2', obj),
  /* Q.温泉コンシェルジュの利用にお金はかかりますか？ */
  supportFAQ3: (obj?: I18nArgumentType) => translation('supportFAQ3', obj),
  /* いいえ、当温泉コンシェルジュサービスは完全に無料でご利用いただけます！ 
お客様には、安心して日本の温泉を満喫していただきたいと考えておりますので、費用は一切いただいておりません。温泉選びの相談から予約手続き、おすすめ情報提供まで、どんなことでもお気軽にお問い合わせください。 */
  supportFAQA3: (obj?: I18nArgumentType) => translation('supportFAQA3', obj),
  /* Q.相談して営業されるのが怖い。相談だけでもOK？ */
  supportFAQ4: (obj?: I18nArgumentType) => translation('supportFAQ4', obj),
  /* ご安心ください、WAmazing Stayではお客様に無理な営業を行うことは一切ございません。相談のみでのご利用も大歓迎です！
何か不明点があれば、気軽にお問い合わせください。ご予約はお客様が納得されてからで十分です。私たちはお客様が最良の選択をされるお手伝いをすることを何よりも大切にしています。 */
  supportFAQA4: (obj?: I18nArgumentType) => translation('supportFAQA4', obj),
  /* Q. WAmazing Stayは他のサイトと何が違いますか？ */
  supportFAQ5: (obj?: I18nArgumentType) => translation('supportFAQ5', obj),
  /* WAmazing Stayは、日本の温泉宿に特化した予約プラットフォームで、訪日外国人に向けた独自のサービスを数多く提供しています。当サイトの最大の特徴は、温泉コンシェルジュサービスです。このサービスを通じて、お客様一人ひとりに合った温泉宿の提案や予約手続きをサポートします。

さらに、当サイトでは日本全国の隠れた名湯から人気の温泉地まで、厳選された温泉宿を幅広く取り扱っています。これにより、お客様が求める様々な体験を提供することが可能です。お客様の日本旅行を最高のものにするために全力を尽くしています。 */
  supportFAQA5: (obj?: I18nArgumentType) => translation('supportFAQA5', obj),
  /* 温泉コンシェルジュ */
  supportTitle: (obj?: I18nArgumentType) => translation('supportTitle', obj),
  /* WAmazing推薦 限定プランあり温泉地 */
  exclusiveSuggestionTitle: (obj?: I18nArgumentType) =>
    translation('exclusiveSuggestionTitle', obj),
  /* 温泉宿を探す */
  supportSearchOnsen: (obj?: I18nArgumentType) => translation('supportSearchOnsen', obj),
  /* 温泉コンシェルジュとは */
  aboutOnsenSupport: (obj?: I18nArgumentType) => translation('aboutOnsenSupport', obj),
  /* 日本の温泉に精通したコンシェルジュが
日本の温泉を体験する上での疑問や質問に中国語でお答えします！ */
  supportModal1: (obj?: I18nArgumentType) => translation('supportModal1', obj),
  /* 温泉地や温泉宿のおすすめ */
  supportModal2: (obj?: I18nArgumentType) => translation('supportModal2', obj),
  /* 貸切風呂や送迎の予約サポート */
  supportModal3: (obj?: I18nArgumentType) => translation('supportModal3', obj),
  /* 温泉
コンシェルジュ */
  supportTitleTwoLine: (obj?: I18nArgumentType) => translation('supportTitleTwoLine', obj),
  /* 沖縄県その他エリア */
  webYadoOnsenAreaOkinawa: (obj?: I18nArgumentType) => translation('webYadoOnsenAreaOkinawa', obj),
  /* 沖縄県 */
  webYadoPrefectureOkinawa: (obj?: I18nArgumentType) =>
    translation('webYadoPrefectureOkinawa', obj),
  /* 那覇空港 */
  webYadoAirportNaha: (obj?: I18nArgumentType) => translation('WebYadoAirportNaha', obj),
  /* サービス終了のお知らせ */
  supportServiceEndAnnouncementTitle: (obj?: I18nArgumentType) =>
    translation('supportServiceEndAnnouncementTitle', obj),
  /* いつも「溫泉真人小幫手」をご利用いただきましてありがとうございます。 */
  supportServiceEndAnnouncementText1: (obj?: I18nArgumentType) =>
    translation('supportServiceEndAnnouncementText1', obj),
  /* 多くの皆さまにご利用いただいておりました本サービスですが、2024年7月31日(水) 18時（台湾時間）をもってサービス提供を終了させていただくこととなりました。今後、WAmazing Stayに関するご不明点やご質問は、{{link}}よりご連絡ください。 */
  supportServiceEndAnnouncementText2: (obj?: I18nArgumentType) =>
    translation('supportServiceEndAnnouncementText2', obj),
  /* ご利用いただき、ありがとうございました。 */
  supportServiceEndAnnouncementText3: (obj?: I18nArgumentType) =>
    translation('supportServiceEndAnnouncementText3', obj),
  /* フォーム */
  supportServiceLink: (obj?: I18nArgumentType) => translation('supportServiceLink', obj),
};

type I18nType = typeof i18n;

export type I18nKeyType = keyof I18nType;

export default new Proxy(i18n, i18nProxyHandler) as I18nType;
