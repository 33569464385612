import { computed, observable, toJS, IObservableArray } from 'mobx';
import shortId from 'shortid';

import { RoomParams } from '../../../data_sources/networks/wamazing/YadoApi';
import YadoRoom from '../../networks/YadoRoom';

export type YadoRoomProps = {
  males: number;
  females: number;
  childAges: number[];
};

export default class YadoRoomStore {
  static get default(): YadoRoomStore {
    return new YadoRoomStore({
      males: 1,
      females: 1,
      childAges: [],
    });
  }

  id: string;

  @observable
  males: number = 0;

  @observable
  females: number = 0;

  @observable.shallow
  childAges: IObservableArray<number>;

  constructor({
    males,
    females,
    childAges,
  }: {
    males?: number | string;
    females?: number | string;
    childAges?: number[];
  }) {
    this.id = shortId();

    this.males = Number(males);
    this.females = Number(females);
    this.childAges = observable.array(childAges ? childAges.map(a => Number(a)) : []);
  }

  toProps(): YadoRoomProps {
    return {
      males: Number(this.males),
      females: Number(this.females),
      childAges: toJS(this.childAges),
    };
  }

  @computed
  get adults(): number {
    return this.males + this.females;
  }

  @computed
  get children(): number {
    return this.childAges.length;
  }

  @computed
  get total(): number {
    return this.adults + this.children;
  }

  toBookingParams(): RoomParams {
    return {
      males: this.males,

      females: this.females,
      ...(this.children > 0
        ? {
            children: Number(this.children),
            child_ages: toJS(this.childAges),
          }
        : null),
    };
  }

  toAdultParams(): RoomParams {
    return {
      adults: this.adults,
      ...(this.children > 0
        ? {
            children: this.children,
            child_ages: toJS(this.childAges),
          }
        : null),
    };
  }

  toYadoRoom(): YadoRoom {
    return new YadoRoom(this.toBookingParams());
  }
}
