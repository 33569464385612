/* eslint-disable global-require */
// i18n-next に全面的に置き換える必要あり
import I18n from 'i18n-js';
import moment from 'moment';
import { zhCN, zhHK, zhTW, ja, enUS } from 'date-fns/locale';

import { AcceptLanguageType } from '../../legacy/types/AcceptLanguageType';
import { DefaultLocale, LocaleType } from '../../legacy/types/LocaleType';
import { WamazingSubdomainType } from '../../legacy/types/WamazingSubdomainType';
import { isCnHost, isJpHost, getSubDomainFromHost } from '../../shared/utils/url_helper';

I18n.translations = {
  zh_tw: require('./languages/zh_tw.json'),
  zh_hk: require('./languages/zh_hk.json'),
  zh_cn: require('./languages/zh_cn.json'),
  ja: require('./languages/ja.json'),
  en: require('./languages/en.json'),
};

I18n.defaultLocale = DefaultLocale;
I18n.fallbacks = true; // fallback は defaultLocale に対してされる

let dateFnsLocale = zhTW;

// 注意: 単複の差がない言語に関してはここに追加する。
['zh_tw', 'zh_hk', 'zh_cn', 'ja'].forEach((locale: string): void => {
  I18n.pluralization[locale] = () => ['other'];
});

switchLanguage('zh_tw');

function switchLanguageByHost(host: string) {
  if (isCnHost(host)) {
    return switchLanguage('zh_cn');
  }
  if (isJpHost(host)) {
    return switchLanguage('ja');
  }
  const subdomain = getSubDomainFromHost(host);

  switch (subdomain) {
    case 'tw':
      return switchLanguage('zh_tw');

    case 'hk':
      return switchLanguage('zh_hk');

    case 'www':
      return switchLanguage('en');

    default:
      return switchLanguage('zh_tw');
  }
}

function switchLanguage(code: (string | Null) | LocaleType) {
  setLocale(detectLocale(code));
}

function detectLocale(code: (string | Null) | LocaleType): LocaleType {
  if (code == null) {
    return DefaultLocale;
  }

  if (/^zh.+cn$/i.test(code)) {
    return 'zh_cn';
  }

  if (
    /^zh.+hk$/i.test(code)

    /*
  if (/^zh-Hans.+$/i.test(code)) {
    return 'zh_cn';
  }
  */
  ) {
    return 'zh_hk';
  }

  if (/^zh.+tw$/i.test(code)) {
    return 'zh_tw';
  }

  if (/^zh-Hant.+$/i.test(code)) {
    return 'zh_tw';
  }

  if (/^ja/i.test(code)) {
    return 'ja';
  }

  if (/^en/i.test(code)) {
    return 'en';
  }

  return DefaultLocale;
}

function getLocale(): LocaleType {
  return I18n.locale as LocaleType;
}

function setLocale(locale: LocaleType) {
  I18n.locale = locale;

  switch (locale) {
    case 'ja':
      dateFnsLocale = ja;

      require('moment/locale/ja');

      moment.locale('ja');
      break;

    case 'zh_cn':
      dateFnsLocale = zhCN;

      require('moment/locale/zh-cn');

      moment.locale('zh-cn');
      break;

    case 'zh_hk':
      dateFnsLocale = zhHK;

      require('moment/locale/zh-hk');

      moment.locale('zh-hk');
      break;

    case 'en':
      dateFnsLocale = enUS;

      require('moment/locale/en-gb');

      moment.locale('en-gb');

      break;

    default:
      dateFnsLocale = zhTW;

      require('moment/locale/zh-tw');

      moment.locale('zh-tw');
      break;
  }

  return true;
}

function getAcceptLanguage(): AcceptLanguageType {
  switch (getLocale()) {
    case 'ja':
      return 'ja-JP';

    case 'zh_cn':
      return 'zh-CN';

    case 'zh_hk':
      return 'zh-HK';

    case 'en':
      return 'en-US';

    default:
      return 'zh-TW';
  }
}

function getWamazingSubdomain(): WamazingSubdomainType {
  const locale = getLocale();

  if (locale === 'zh_hk') {
    return 'hk';
  }

  if (locale === 'zh_cn') {
    return 'cn';
  }

  if (locale === 'en') {
    return 'www';
  }

  if (locale === 'ja') {
    return 'jp';
  }

  return 'tw';
}

function LangFormat(subdomain: string) {
  switch (subdomain) {
    case 'www':
      return 'en';

    case 'jp':
      return 'ja';

    case 'tw':
      return 'zh_tw';

    case 'hk':
      return 'zh_hk';

    case 'cn':
      return 'zh_cn';

    case 'au':
      return 'en';

    default:
      return 'ja';
  }
}

function translation(label: string, ...args: any): string {
  return I18n.t(label, ...args);
}

function getDateFnsLocale(): any {
  return dateFnsLocale;
}

function isCn(): boolean {
  return getLocale() === 'zh_cn';
}

function isTw(): boolean {
  return getLocale() === 'zh_tw';
}

function isEnglish(): boolean {
  return getLocale() === 'en';
}

function isJp(): boolean {
  return getLocale() === 'ja';
}
export {
  detectLocale,
  getLocale,
  setLocale,
  switchLanguage,
  switchLanguageByHost,
  getWamazingSubdomain,
  getAcceptLanguage,
  getDateFnsLocale,
  translation,
  isCn,
  isTw,
  isEnglish,
  isJp,
  LangFormat,
};
