import IsomorphicRaven from '../../shared/utils/IsomorphicRaven';

export default class SentryReporter {
  static captureException(error: string, errorInfo?: string) {
    console.error('captureException: ', error);
    IsomorphicRaven.captureException(error, {
      extra: {
        errorInfo,
      },
    });
  }
  // TODO: ユーザID送信等、RNにある機能を実装する
}
