import { PreferenceId } from './Preference';
import { copyInstance } from '../../../shared/utils/copy';
import { YadoTypeId } from './YadoType';
import { ReviewScoreId } from './ReviewScore';
import { SupportedLanguageId } from './SupportedLanguage';
import { RoomCountId } from './RoomCount';
import { FacilityServiceId } from './FacilityService';

export type FilterConditionType = {
  priceRange: [number, number];
  preferenceIds: PreferenceId[];
  yadoTypeIds: YadoTypeId[];
  reviewScoreIds: ReviewScoreId[];
  supportedLanguageIds: SupportedLanguageId[];
  facilityServiceIds: FacilityServiceId[];
  roomCountIds: RoomCountId[];
  hasAvailableRooms: boolean;
  hasExclusivePlan: boolean;
};

export type FilterConditionTypeKeys = keyof FilterConditionType;

export type FilterConditionTypeUnion =
  | [number, number]
  | PreferenceId[]
  | YadoTypeId[]
  | ReviewScoreId[]
  | SupportedLanguageId[]
  | FacilityServiceId[]
  | RoomCountId[]
  | boolean
  | string
  | number;

export const filterConditionKeys: FilterConditionTypeKeys[] = [
  'priceRange',
  'preferenceIds',
  'yadoTypeIds',
  'reviewScoreIds',
  'supportedLanguageIds',
  'facilityServiceIds',
  'roomCountIds',
  'hasAvailableRooms',
  'hasExclusivePlan',
];

class FilterCondition {
  constructor(
    readonly priceRange: [number, number],
    readonly preferenceIds: PreferenceId[],
    readonly yadoTypeIds: YadoTypeId[],
    readonly reviewScoreIds: ReviewScoreId[],
    readonly supportedLanguageIds: SupportedLanguageId[],
    readonly facilityServiceIds: FacilityServiceId[],
    readonly roomCountIds: RoomCountId[],
    readonly hasAvailableRooms: boolean,
    readonly hasExclusivePlan: boolean,
  ) {}

  deepCopy(obj?: Partial<FilterCondition>): FilterCondition {
    return copyInstance(this, obj, true);
  }
}

export default FilterCondition;
