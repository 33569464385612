import { isTw } from '../../config/locales/i18n';

const openZendesk = () => {
  if (!isTw()) return;
  window.zE?.('messenger', 'open');
};

const closeZendesk = () => {
  if (!isTw()) return;
  window.zE?.('messenger', 'close');
};

export { openZendesk, closeZendesk };
