/*
 * This code auto generated by R.rake
 * R.ts is similar to R.java
 */

import { getLocale } from '../config/locales/i18n';
import ENV from '../constants/ENV';

const assetsData = {
  default: {
    jsons: {
      spots: '/yado/onsen/assets/jsons/spots.json?1702986206',
      features: '/yado/onsen/assets/jsons/features.json?1702986206',
      citySpots: '/yado/onsen/assets/jsons/city_spots.json?1702986206',
      prefectureDetails: '/yado/onsen/assets/jsons/prefecture_details.json?1702986206',
      featureRelations: '/yado/onsen/assets/jsons/feature_relations.json?1702986206',
      prefectures: '/yado/onsen/assets/jsons/prefectures.json?1702986206',
      cities: '/yado/onsen/assets/jsons/cities.json?1702986206',
    },
    images: {
      pjtArrowLeftThin: '/yado/onsen/assets/images/pjt/arrow-left-thin.svg?1702986206',
      pjtMealsForGrey: '/yado/onsen/assets/images/pjt/meals_for_grey.svg?1702986206',
      pjtArrowBlueUp: '/yado/onsen/assets/images/pjt/arrow_blue_up.svg?1703567287',
      pjtAddLightBlue: '/yado/onsen/assets/images/pjt/add_light_blue.svg?1702986206',
      pjtMealGrey: '/yado/onsen/assets/images/pjt/meal_grey.svg?1702986206',
      pjtAreaNikkoKinugawa: '/yado/onsen/assets/images/pjt/area/nikko-kinugawa.jpg?1708579723',
      pjtAreaWakura: '/yado/onsen/assets/images/pjt/area/wakura.jpg?1702986206',
      pjtAreaHokkaido: '/yado/onsen/assets/images/pjt/area/hokkaido.jpg?1708579723',
      pjtAreaToyako: '/yado/onsen/assets/images/pjt/area/toyako.jpg?1702986206',
      pjtAreaYugawara: '/yado/onsen/assets/images/pjt/area/yugawara.jpg?1702986206',
      pjtAreaYufuin: '/yado/onsen/assets/images/pjt/area/yufuin.jpg?1713761370',
      pjtAreaKaga: '/yado/onsen/assets/images/pjt/area/kaga.jpg?1702986206',
      pjtAreaFukuoka: '/yado/onsen/assets/images/pjt/area/fukuoka.jpg?1708579723',
      pjtAreaHakoneYugawara: '/yado/onsen/assets/images/pjt/area/hakone-yugawara.jpg?1708579723',
      pjtAreaWakayama: '/yado/onsen/assets/images/pjt/area/wakayama.jpg?1708579723',
      pjtAreaYamaguchi: '/yado/onsen/assets/images/pjt/area/yamaguchi.jpg?1708579723',
      pjtAreaGero: '/yado/onsen/assets/images/pjt/area/gero.jpg?1708579723',
      pjtAreaKusatsu: '/yado/onsen/assets/images/pjt/area/kusatsu.png?1708579723',
      pjtAreaIzu: '/yado/onsen/assets/images/pjt/area/izu.jpg?1708579723',
      pjtAreaFuji: '/yado/onsen/assets/images/pjt/area/fuji.jpg?1708579723',
      pjtAreaNagano: '/yado/onsen/assets/images/pjt/area/nagano.jpg?1708579723',
      pjtAreaArima: '/yado/onsen/assets/images/pjt/area/arima.jpg?1708579723',
      pjtAreaKinosaki: '/yado/onsen/assets/images/pjt/area/kinosaki.jpg?1708579723',
      pjtAreaSapporoJozankei: '/yado/onsen/assets/images/pjt/area/sapporo-jozankei.jpg?1713761370',
      pjtAreaIbusuki: '/yado/onsen/assets/images/pjt/area/ibusuki.jpg?1702986206',
      pjtAreaBeppu: '/yado/onsen/assets/images/pjt/area/beppu.jpg?1702986206',
      pjtAreaKurokawa: '/yado/onsen/assets/images/pjt/area/kurokawa.jpg?1702986206',
      pjtAreaHakodateYunokawa:
        '/yado/onsen/assets/images/pjt/area/hakodate-yunokawa.jpg?1702986206',
      pjtAreaKyoto: '/yado/onsen/assets/images/pjt/area/kyoto.jpg?1708579723',
      pjtAddBlue: '/yado/onsen/assets/images/pjt/add_blue.svg?1702986206',
      pjtDownUpArrowBlue: '/yado/onsen/assets/images/pjt/down_up_arrow_blue.svg?1703567287',
      pjtHelpBlue: '/yado/onsen/assets/images/pjt/help_blue.svg?1703567287',
      pjtArrowBlueDown: '/yado/onsen/assets/images/pjt/arrow_blue_down.svg?1703567287',
      pjtHotSpringGrey: '/yado/onsen/assets/images/pjt/hot_spring_grey.svg?1702986206',
      pjtLimitedYellowTag: '/yado/onsen/assets/images/pjt/limited_yellow_tag.svg?1702986206',
      pjtRoomGrey: '/yado/onsen/assets/images/pjt/room_grey.svg?1702986206',
      pjtArrowRightThin: '/yado/onsen/assets/images/pjt/arrow-right-thin.svg?1702986206',
      pjtOtherGrey: '/yado/onsen/assets/images/pjt/other_grey.svg?1702986206',
      pjtPjtLinkArrow: '/yado/onsen/assets/images/pjt/pjt_link_arrow.svg?1702986206',
      pjtLimitedWhiteTag: '/yado/onsen/assets/images/pjt/limited_white_tag.svg?1702986206',
      faviconsFavicon16x16: '/yado/onsen/assets/images/favicons/favicon-16x16.png?1708579723',
      faviconsMstile310x310: '/yado/onsen/assets/images/favicons/mstile-310x310.png?1708579723',
      faviconsMstile144x144: '/yado/onsen/assets/images/favicons/mstile-144x144.png?1708579723',
      faviconsSafariPinnedTab:
        '/yado/onsen/assets/images/favicons/safari-pinned-tab.svg?1702986206',
      faviconsFavicon: '/yado/onsen/assets/images/favicons/favicon.ico?1708579723',
      faviconsAppleTouchIcon120x120:
        '/yado/onsen/assets/images/favicons/apple-touch-icon-120x120.png?1708579723',
      faviconsAndroidChrome192x192:
        '/yado/onsen/assets/images/favicons/android-chrome-192x192.png?1702986206',
      faviconsFavicon196x196: '/yado/onsen/assets/images/favicons/favicon-196x196.png?1708579723',
      faviconsAppleTouchIcon: '/yado/onsen/assets/images/favicons/apple-touch-icon.png?1702986206',
      faviconsAndroidChrome256x256:
        '/yado/onsen/assets/images/favicons/android-chrome-256x256.png?1702986206',
      faviconsMstile70x70: '/yado/onsen/assets/images/favicons/mstile-70x70.png?1708579723',
      faviconsAppleTouchIcon152x152:
        '/yado/onsen/assets/images/favicons/apple-touch-icon-152x152.png?1708579723',
      faviconsMstile310x150: '/yado/onsen/assets/images/favicons/mstile-310x150.png?1708579723',
      faviconsAppleTouchIcon114x114:
        '/yado/onsen/assets/images/favicons/apple-touch-icon-114x114.png?1708579723',
      faviconsAppleTouchIcon76x76:
        '/yado/onsen/assets/images/favicons/apple-touch-icon-76x76.png?1708579723',
      faviconsFavicon128: '/yado/onsen/assets/images/favicons/favicon-128.png?1708579723',
      faviconsFavicon96x96: '/yado/onsen/assets/images/favicons/favicon-96x96.png?1708579723',
      faviconsAppleTouchIcon57x57:
        '/yado/onsen/assets/images/favicons/apple-touch-icon-57x57.png?1708579723',
      faviconsManifest: '/yado/onsen/assets/images/favicons/manifest.json?1702986206',
      faviconsAppleTouchIcon72x72:
        '/yado/onsen/assets/images/favicons/apple-touch-icon-72x72.png?1708579723',
      faviconsMstile150x150: '/yado/onsen/assets/images/favicons/mstile-150x150.png?1708579723',
      faviconsAppleTouchIcon60x60:
        '/yado/onsen/assets/images/favicons/apple-touch-icon-60x60.png?1708579723',
      faviconsAppleTouchIcon144x144:
        '/yado/onsen/assets/images/favicons/apple-touch-icon-144x144.png?1708579723',
      faviconsBrowserconfig: '/yado/onsen/assets/images/favicons/browserconfig.xml?1702986206',
      faviconsFavicon32x32: '/yado/onsen/assets/images/favicons/favicon-32x32.png?1708579723',
      yadoMapiconOnsen: '/yado/onsen/assets/images/yado/mapicon/onsen.svg?1702986206',
      yadoMapiconHotspring: '/yado/onsen/assets/images/yado/mapicon/hotspring.svg?1702986206',
      yadoMapiconStation: '/yado/onsen/assets/images/yado/mapicon/station.svg?1702986206',
      yadoMapiconDefault: '/yado/onsen/assets/images/yado/mapicon/default.svg?1702986206',
      yadoMapiconAirport: '/yado/onsen/assets/images/yado/mapicon/airport.svg?1702986206',
      yadoIconShape: '/yado/onsen/assets/images/yado/icon/shape.svg?1702986206',
      yadoIconSim: '/yado/onsen/assets/images/yado/icon/sim.svg?1702986206',
      yadoIconSearch: '/yado/onsen/assets/images/yado/icon/search.svg?1702986206',
      yadoIconDinner: '/yado/onsen/assets/images/yado/icon/dinner.svg?1702986206',
      yadoIconBed: '/yado/onsen/assets/images/yado/icon/bed.svg?1702986206',
      yadoIconCalendarBlue: '/yado/onsen/assets/images/yado/icon/calendar-blue.svg?1702986206',
      yadoIconUser: '/yado/onsen/assets/images/yado/icon/user.svg?1702986206',
      yadoIconSmokingOk: '/yado/onsen/assets/images/yado/icon/smoking-ok.svg?1702986206',
      yadoIconRatingGreat: '/yado/onsen/assets/images/yado/icon/rating-great.svg?1702986206',
      yadoIconRoom: '/yado/onsen/assets/images/yado/icon/room.svg?1702986206',
      yadoIconRatingGood: '/yado/onsen/assets/images/yado/icon/rating-good.svg?1702986206',
      yadoIconBath: '/yado/onsen/assets/images/yado/icon/bath.svg?1702986206',
      yadoIconGuestcount: '/yado/onsen/assets/images/yado/icon/guestcount.svg?1702986206',
      yadoIconTrain: '/yado/onsen/assets/images/yado/icon/train.svg?1702986206',
      yadoIconToaster: '/yado/onsen/assets/images/yado/icon/toaster.svg?1702986206',
      yadoIconSmokingUnknown: '/yado/onsen/assets/images/yado/icon/smoking-unknown.svg?1702986206',
      yadoIconCalendarOut: '/yado/onsen/assets/images/yado/icon/calendar-out.svg?1702986206',
      yadoIconPlus: '/yado/onsen/assets/images/yado/icon/plus.svg?1702986206',
      yadoIconWifi: '/yado/onsen/assets/images/yado/icon/wifi.svg?1702986206',
      yadoIconPinpoint: '/yado/onsen/assets/images/yado/icon/pinpoint.svg?1702986206',
      yadoIconAircon: '/yado/onsen/assets/images/yado/icon/aircon.svg?1702986206',
      yadoIconChild: '/yado/onsen/assets/images/yado/icon/child.svg?1702986206',
      yadoIconMarker: '/yado/onsen/assets/images/yado/icon/marker.svg?1702986206',
      yadoIconRoomSpace: '/yado/onsen/assets/images/yado/icon/room-space.svg?1702986206',
      yadoIconAdult: '/yado/onsen/assets/images/yado/icon/adult.svg?1702986206',
      yadoIconDiamond: '/yado/onsen/assets/images/yado/icon/diamond.svg?1702986206',
      yadoIconCalendar: '/yado/onsen/assets/images/yado/icon/calendar.svg?1702986206',
      yadoIconSmokingNg: '/yado/onsen/assets/images/yado/icon/smoking-ng.svg?1702986206',
      yadoIconMinus: '/yado/onsen/assets/images/yado/icon/minus.svg?1702986206',
      yadoIconMoon: '/yado/onsen/assets/images/yado/icon/moon.svg?1702986206',
      yadoIconCalendarIn: '/yado/onsen/assets/images/yado/icon/calendar-in.svg?1702986206',
      onsenYadoSpaIcon: '/yado/onsen/assets/images/onsen_yado/spa_icon.svg?1702986206',
      onsenYadoJetBathIcon: '/yado/onsen/assets/images/onsen_yado/jet_bath_icon.svg?1702986206',
      onsenYadoSaunaIcon: '/yado/onsen/assets/images/onsen_yado/sauna_icon.svg?1702986206',
      onsenYadoFineViewBathIcon:
        '/yado/onsen/assets/images/onsen_yado/fine_view_bath_icon.svg?1702986206',
      onsenYadoOpenAirBathIcon:
        '/yado/onsen/assets/images/onsen_yado/open_air_bath_icon.svg?1702986206',
      onsenYadoAreaShodoshima:
        '/yado/onsen/assets/images/onsen_yado/area/shodoshima.jpg?1702986206',
      onsenYadoAreaShima: '/yado/onsen/assets/images/onsen_yado/area/shima.jpg?1702986206',
      onsenYadoAreaNikkoKinugawa:
        '/yado/onsen/assets/images/onsen_yado/area/nikko-kinugawa.jpg?1702986206',
      onsenYadoAreaWakura: '/yado/onsen/assets/images/onsen_yado/area/wakura.jpg?1702986206',
      onsenYadoAreaAkiu: '/yado/onsen/assets/images/onsen_yado/area/akiu.jpg?1702986206',
      onsenYadoAreaYamanashi: '/yado/onsen/assets/images/onsen_yado/area/yamanashi.jpg?1702986206',
      onsenYadoAreaHitoyoshi: '/yado/onsen/assets/images/onsen_yado/area/hitoyoshi.jpg?1702986206',
      onsenYadoAreaNozawa: '/yado/onsen/assets/images/onsen_yado/area/nozawa.jpg?1702986206',
      onsenYadoAreaAwajishima:
        '/yado/onsen/assets/images/onsen_yado/area/awajishima.jpg?1702986206',
      onsenYadoAreaNaruko: '/yado/onsen/assets/images/onsen_yado/area/naruko.jpg?1702986206',
      onsenYadoAreaNankishirahama:
        '/yado/onsen/assets/images/onsen_yado/area/nankishirahama.jpg?1702986206',
      onsenYadoAreaTendo: '/yado/onsen/assets/images/onsen_yado/area/tendo.jpg?1702986206',
      onsenYadoAreaDorogawa: '/yado/onsen/assets/images/onsen_yado/area/dorogawa.jpg?1702986206',
      onsenYadoAreaNagatoyumotoYuyawan:
        '/yado/onsen/assets/images/onsen_yado/area/nagatoyumoto-yuyawan.jpg?1702986206',
      onsenYadoAreaOkayamaKurashiki:
        '/yado/onsen/assets/images/onsen_yado/area/okayama-kurashiki.jpg?1702986206',
      onsenYadoAreaHagi: '/yado/onsen/assets/images/onsen_yado/area/hagi.jpg?1702986206',
      onsenYadoAreaIsawa: '/yado/onsen/assets/images/onsen_yado/area/isawa.jpg?1702986206',
      onsenYadoAreaMadaraoNojiriko:
        '/yado/onsen/assets/images/onsen_yado/area/madarao-nojiriko.jpg?1702986206',
      onsenYadoAreaToyako: '/yado/onsen/assets/images/onsen_yado/area/toyako.jpg?1702986206',
      onsenYadoAreaKyotoAmanohashidate:
        '/yado/onsen/assets/images/onsen_yado/area/kyoto-amanohashidate.jpg?1702986206',
      onsenYadoAreaYumuraKasumi:
        '/yado/onsen/assets/images/onsen_yado/area/yumura-kasumi.jpg?1702986206',
      onsenYadoAreaKitayuzawa:
        '/yado/onsen/assets/images/onsen_yado/area/kitayuzawa.jpg?1702986206',
      onsenYadoAreaGinzan: '/yado/onsen/assets/images/onsen_yado/area/ginzan.jpg?1702986206',
      onsenYadoAreaYugawara: '/yado/onsen/assets/images/onsen_yado/area/yugawara.jpg?1702986206',
      onsenYadoAreaOzeOigami: '/yado/onsen/assets/images/onsen_yado/area/oze-oigami.jpg?1702986206',
      onsenYadoAreaObokeIya: '/yado/onsen/assets/images/onsen_yado/area/oboke-iya.jpg?1702986206',
      onsenYadoAreaKaike: '/yado/onsen/assets/images/onsen_yado/area/kaike.jpg?1702986206',
      onsenYadoAreaShigakogen:
        '/yado/onsen/assets/images/onsen_yado/area/shigakogen.jpg?1702986206',
      onsenYadoAreaYufuin: '/yado/onsen/assets/images/onsen_yado/area/yufuin.jpg?1702986206',
      onsenYadoAreaYuda: '/yado/onsen/assets/images/onsen_yado/area/yuda.jpg?1702986206',
      onsenYadoAreaTamatsukuri:
        '/yado/onsen/assets/images/onsen_yado/area/tamatsukuri.jpg?1702986206',
      onsenYadoAreaMisasa: '/yado/onsen/assets/images/onsen_yado/area/misasa.jpg?1702986206',
      onsenYadoAreaKaga: '/yado/onsen/assets/images/onsen_yado/area/kaga.jpg?1702986206',
      onsenYadoAreaKanazawa: '/yado/onsen/assets/images/onsen_yado/area/kanazawa.jpg?1702986206',
      onsenYadoAreaShiretoko: '/yado/onsen/assets/images/onsen_yado/area/shiretoko.jpg?1702986206',
      onsenYadoAreaWamazing: '/yado/onsen/assets/images/onsen_yado/area/wamazing.jpg?1702986206',
      onsenYadoAreaAizu: '/yado/onsen/assets/images/onsen_yado/area/aizu.jpg?1702986206',
      onsenYadoAreaSaseboIkiHirado:
        '/yado/onsen/assets/images/onsen_yado/area/sasebo-iki-hirado.jpg?1702986206',
      onsenYadoAreaTokurakamiyamada:
        '/yado/onsen/assets/images/onsen_yado/area/tokurakamiyamada.jpg?1702986206',
      onsenYadoAreaUnzenObamaShimabara:
        '/yado/onsen/assets/images/onsen_yado/area/unzen-obama-shimabara.jpg?1702986206',
      onsenYadoAreaAsahikawaSounkyo:
        '/yado/onsen/assets/images/onsen_yado/area/asahikawa-sounkyo.jpg?1702986206',
      onsenYadoAreaIseShimaToba:
        '/yado/onsen/assets/images/onsen_yado/area/ise-shima-toba.jpg?1702986206',
      onsenYadoAreaOgoto: '/yado/onsen/assets/images/onsen_yado/area/ogoto.jpg?1702986206',
      onsenYadoAreaNagoya: '/yado/onsen/assets/images/onsen_yado/area/nagoya.jpg?1702986206',
      onsenYadoAreaHanamaki: '/yado/onsen/assets/images/onsen_yado/area/hanamaki.jpg?1702986206',
      onsenYadoAreaHakoneYugawara:
        '/yado/onsen/assets/images/onsen_yado/area/hakone-yugawara.jpg?1702986206',
      onsenYadoAreaKagoshimaCentralarea:
        '/yado/onsen/assets/images/onsen_yado/area/kagoshima_centralarea.jpg?1702986206',
      onsenYadoAreaImabari: '/yado/onsen/assets/images/onsen_yado/area/imabari.jpg?1702986206',
      onsenYadoAreaOtaruAsarigawa:
        '/yado/onsen/assets/images/onsen_yado/area/otaru-asarigawa.jpg?1702986206',
      onsenYadoAreaNyutoTazawako:
        '/yado/onsen/assets/images/onsen_yado/area/nyuto-tazawako.jpg?1702986206',
      onsenYadoAreaTokachigawaObihiro:
        '/yado/onsen/assets/images/onsen_yado/area/tokachigawa-obihiro.jpg?1702986206',
      onsenYadoAreaHakubaOmachiAzumino:
        '/yado/onsen/assets/images/onsen_yado/area/hakuba-omachi-azumino.jpg?1702986206',
      onsenYadoAreaIizakaTsuchiyuBandaiatami:
        '/yado/onsen/assets/images/onsen_yado/area/iizaka-tsuchiyu-bandaiatami.jpg?1702986206',
      onsenYadoAreaZao: '/yado/onsen/assets/images/onsen_yado/area/zao.jpg?1702986206',
      onsenYadoAreaShizuokaYaizu:
        '/yado/onsen/assets/images/onsen_yado/area/shizuoka-yaizu.jpg?1702986206',
      onsenYadoAreaIsobeTomioka:
        '/yado/onsen/assets/images/onsen_yado/area/isobe-tomioka.jpg?1702986206',
      onsenYadoAreaKagawa: '/yado/onsen/assets/images/onsen_yado/area/kagawa.jpg?1702986206',
      onsenYadoAreaTakamatsu: '/yado/onsen/assets/images/onsen_yado/area/takamatsu.jpg?1702986206',
      onsenYadoAreaShimonoseki:
        '/yado/onsen/assets/images/onsen_yado/area/shimonoseki.jpg?1702986206',
      onsenYadoAreaShinshutakayama:
        '/yado/onsen/assets/images/onsen_yado/area/shinshutakayama.jpg?1702986206',
      onsenYadoAreaYamaguchi: '/yado/onsen/assets/images/onsen_yado/area/yamaguchi.jpg?1702986206',
      onsenYadoAreaGero: '/yado/onsen/assets/images/onsen_yado/area/gero.jpg?1702986206',
      onsenYadoAreaHamamatsuHamanako:
        '/yado/onsen/assets/images/onsen_yado/area/hamamatsu-hamanako.jpg?1702986206',
      onsenYadoAreaUreshino: '/yado/onsen/assets/images/onsen_yado/area/ureshino.jpg?1702986206',
      onsenYadoAreaKirishima: '/yado/onsen/assets/images/onsen_yado/area/kirishima.jpg?1702986206',
      onsenYadoAreaKusatsu: '/yado/onsen/assets/images/onsen_yado/area/kusatsu.jpg?1702986206',
      onsenYadoAreaIzu: '/yado/onsen/assets/images/onsen_yado/area/izu.jpg?1702986206',
      onsenYadoAreaKatsuura: '/yado/onsen/assets/images/onsen_yado/area/katsuura.jpg?1702986206',
      onsenYadoAreaFuji: '/yado/onsen/assets/images/onsen_yado/area/fuji.jpg?1702986206',
      onsenYadoAreaIkaho: '/yado/onsen/assets/images/onsen_yado/area/ikaho.jpg?1702986206',
      onsenYadoAreaTateshinaShirakabako:
        '/yado/onsen/assets/images/onsen_yado/area/tateshina-shirakabako.jpg?1702986206',
      onsenYadoAreaAshizuriShimanto:
        '/yado/onsen/assets/images/onsen_yado/area/ashizuri-shimanto.jpg?1702986206',
      onsenYadoAreaTsuyamaHiruzen:
        '/yado/onsen/assets/images/onsen_yado/area/tsuyama-hiruzen.jpg?1702986206',
      onsenYadoAreaMinakami: '/yado/onsen/assets/images/onsen_yado/area/minakami.jpg?1702986206',
      onsenYadoAreaYudanakaShibu:
        '/yado/onsen/assets/images/onsen_yado/area/yudanaka-shibu.jpg?1702986206',
      onsenYadoAreaArima: '/yado/onsen/assets/images/onsen_yado/area/arima.jpg?1702986206',
      onsenYadoAreaTsukioka: '/yado/onsen/assets/images/onsen_yado/area/tsukioka.jpg?1702986206',
      onsenYadoAreaHidaTakayama:
        '/yado/onsen/assets/images/onsen_yado/area/hida-takayama.jpg?1702986206',
      onsenYadoAreaKinosaki: '/yado/onsen/assets/images/onsen_yado/area/kinosaki.jpg?1702986206',
      onsenYadoAreaYunogo: '/yado/onsen/assets/images/onsen_yado/area/yunogo.jpg?1702986206',
      onsenYadoAreaYubara: '/yado/onsen/assets/images/onsen_yado/area/yubara.jpg?1702986206',
      onsenYadoAreaKyotoCity: '/yado/onsen/assets/images/onsen_yado/area/kyoto-city.jpg?1702986206',
      onsenYadoAreaEchigoyuzawa:
        '/yado/onsen/assets/images/onsen_yado/area/echigoyuzawa.jpg?1702986206',
      onsenYadoAreaMatsumoto: '/yado/onsen/assets/images/onsen_yado/area/matsumoto.jpg?1702986206',
      onsenYadoAreaGifu: '/yado/onsen/assets/images/onsen_yado/area/gifu.jpg?1702986206',
      onsenYadoAreaTowadakoHachimantai:
        '/yado/onsen/assets/images/onsen_yado/area/towadako-hachimantai.jpg?1702986206',
      onsenYadoAreaKumanoHongu:
        '/yado/onsen/assets/images/onsen_yado/area/kumano-hongu.jpg?1702986206',
      onsenYadoAreaSapporoJozankei:
        '/yado/onsen/assets/images/onsen_yado/area/sapporo-jozankei.jpg?1702986206',
      onsenYadoAreaHawai: '/yado/onsen/assets/images/onsen_yado/area/hawai.jpg?1702986206',
      onsenYadoAreaIbusuki: '/yado/onsen/assets/images/onsen_yado/area/ibusuki.jpg?1702986206',
      onsenYadoAreaMiyagiZao: '/yado/onsen/assets/images/onsen_yado/area/miyagi-zao.jpg?1702986206',
      onsenYadoAreaHiroshimaMiyajima:
        '/yado/onsen/assets/images/onsen_yado/area/hiroshima-miyajima.jpg?1702986206',
      onsenYadoAreaAkayu: '/yado/onsen/assets/images/onsen_yado/area/akayu.jpg?1702986206',
      onsenYadoAreaSuwako: '/yado/onsen/assets/images/onsen_yado/area/suwako.jpg?1702986206',
      onsenYadoAreaAwara: '/yado/onsen/assets/images/onsen_yado/area/awara.jpg?1702986206',
      onsenYadoAreaUnazuki: '/yado/onsen/assets/images/onsen_yado/area/unazuki.jpg?1702986206',
      onsenYadoAreaBeppu: '/yado/onsen/assets/images/onsen_yado/area/beppu.jpg?1702986206',
      onsenYadoAreaKochiCity: '/yado/onsen/assets/images/onsen_yado/area/kochi-city.jpg?1702986206',
      onsenYadoAreaKotohira: '/yado/onsen/assets/images/onsen_yado/area/kotohira.jpg?1702986206',
      onsenYadoAreaKamikochiNorikura:
        '/yado/onsen/assets/images/onsen_yado/area/kamikochi-norikura.jpg?1702986206',
      onsenYadoAreaKurokawa: '/yado/onsen/assets/images/onsen_yado/area/kurokawa.jpg?1702986206',
      onsenYadoAreaHakodateYunokawa:
        '/yado/onsen/assets/images/onsen_yado/area/hakodate-yunokawa.jpg?1702986206',
      onsenYadoAreaBesshoKakeyu:
        '/yado/onsen/assets/images/onsen_yado/area/bessho-kakeyu.jpg?1702986206',
      onsenYadoAreaAtami: '/yado/onsen/assets/images/onsen_yado/area/atami.jpg?1702986206',
      onsenYadoAreaDogo: '/yado/onsen/assets/images/onsen_yado/area/dogo.jpg?1702986206',
      onsenYadoAreaNoboribetsu:
        '/yado/onsen/assets/images/onsen_yado/area/noboribetsu.jpg?1702986206',
      onsenYadoRibbonsDinnerRibbonEarlyBird2x:
        '/yado/onsen/assets/images/onsen_yado/ribbons/dinner_ribbon_early_bird_2x.png?1702986206',
      onsenYadoRibbonsDinnerRibbonRecommended2x:
        '/yado/onsen/assets/images/onsen_yado/ribbons/dinner_ribbon_recommended_2x.png?1702986206',
      onsenYadoRibbonsDinnerRibbonStandard:
        '/yado/onsen/assets/images/onsen_yado/ribbons/dinner_ribbon_standard.png?1702986206',
      onsenYadoRibbonsDinnerRibbonRecommended:
        '/yado/onsen/assets/images/onsen_yado/ribbons/dinner_ribbon_recommended.png?1702986206',
      onsenYadoRibbonsDinnerRibbonStandard2x:
        '/yado/onsen/assets/images/onsen_yado/ribbons/dinner_ribbon_standard_2x.png?1702986206',
      onsenYadoRibbonsDinnerRibbonEarlyBird:
        '/yado/onsen/assets/images/onsen_yado/ribbons/dinner_ribbon_early_bird.png?1702986206',
      onsenYadoCustomerContact:
        '/yado/onsen/assets/images/onsen_yado/customer_contact.jpg?1713328848',
      onsenYadoLpTopPickup: '/yado/onsen/assets/images/onsen_yado/lp/top_pickup.jpg?1702986206',
      onsenYadoLpFirstViewWavePc:
        '/yado/onsen/assets/images/onsen_yado/lp/first_view_wave_pc.svg?1702986206',
      onsenYadoLpArrow: '/yado/onsen/assets/images/onsen_yado/lp/arrow.svg?1702986206',
      onsenYadoLpRightArrow: '/yado/onsen/assets/images/onsen_yado/lp/right_arrow.svg?1702986206',
      onsenYadoLpLeftArrow: '/yado/onsen/assets/images/onsen_yado/lp/left_arrow.svg?1702986206',
      onsenYadoLpGoToTop: '/yado/onsen/assets/images/onsen_yado/lp/go_to_top.svg?1702986206',
      onsenYadoLpEvaluation: '/yado/onsen/assets/images/onsen_yado/lp/evaluation.svg?1702986206',
      onsenYadoLpTopDinner: '/yado/onsen/assets/images/onsen_yado/lp/top_dinner.jpg?1702986206',
      onsenYadoLpSupport: '/yado/onsen/assets/images/onsen_yado/lp/support.svg?1702986206',
      onsenYadoLpTopPrivateBath:
        '/yado/onsen/assets/images/onsen_yado/lp/top_private_bath.jpg?1702986206',
      onsenYadoLpCover: '/yado/onsen/assets/images/onsen_yado/lp/cover.jpg?1702986206',
      onsenYadoLpSupportLpKeyVisualSp:
        '/yado/onsen/assets/images/onsen_yado/lp/support_LP_key_visual_sp.jpg?1713328848',
      onsenYadoLpOg: '/yado/onsen/assets/images/onsen_yado/lp/og.jpg?1702986206',
      onsenYadoLpFirstViewWaveSp:
        '/yado/onsen/assets/images/onsen_yado/lp/first_view_wave_sp.svg?1702986206',
      onsenYadoLpGoDown: '/yado/onsen/assets/images/onsen_yado/lp/go_down.svg?1702986206',
      onsenYadoLpPjtGoToTop: '/yado/onsen/assets/images/onsen_yado/lp/pjt_go_to_top.svg?1702986206',
      onsenYadoLpSupportLpKeyVisual:
        '/yado/onsen/assets/images/onsen_yado/lp/support_LP_key_visual.jpg?1713328848',
      onsenYadoLpHeart: '/yado/onsen/assets/images/onsen_yado/lp/heart.svg?1702986206',
      onsenYadoCommentEnd: '/yado/onsen/assets/images/onsen_yado/comment_end.svg?1702986206',
      onsenYadoAreaCoverDefault:
        '/yado/onsen/assets/images/onsen_yado/area_cover_default.jpg?1702986206',
      onsenYadoWechatProdQrcode:
        '/yado/onsen/assets/images/onsen_yado/wechat/prod_qrcode.png?1702986206',
      onsenYadoWechatStgQrcode:
        '/yado/onsen/assets/images/onsen_yado/wechat/stg_qrcode.jpg?1702986206',
      onsenYadoWechatWechatIcon:
        '/yado/onsen/assets/images/onsen_yado/wechat/wechat_icon.svg?1702986206',
      onsenYadoWechatSaruonsen:
        '/yado/onsen/assets/images/onsen_yado/wechat/saruonsen.jpg?1702986206',
      onsenYadoAreaCoverWamazing:
        '/yado/onsen/assets/images/onsen_yado/area_cover_wamazing.png?1702986206',
      onsenYadoCommentStart: '/yado/onsen/assets/images/onsen_yado/comment_start.svg?1702986206',
      onsenYadoRegionHokkaido:
        '/yado/onsen/assets/images/onsen_yado/region/hokkaido.jpg?1702986206',
      onsenYadoRegionChugoku: '/yado/onsen/assets/images/onsen_yado/region/chugoku.jpg?1702986206',
      onsenYadoRegionChubu: '/yado/onsen/assets/images/onsen_yado/region/chubu.jpg?1702986206',
      onsenYadoRegionKanto: '/yado/onsen/assets/images/onsen_yado/region/kanto.jpg?1702986206',
      onsenYadoRegionShikoku: '/yado/onsen/assets/images/onsen_yado/region/shikoku.jpg?1702986206',
      onsenYadoRegionKyushu: '/yado/onsen/assets/images/onsen_yado/region/kyushu.jpg?1702986206',
      onsenYadoRegionKansai: '/yado/onsen/assets/images/onsen_yado/region/kansai.jpg?1702986206',
      onsenYadoRegionTohoku: '/yado/onsen/assets/images/onsen_yado/region/tohoku.jpg?1702986206',
      onsenYadoOgDefault: '/yado/onsen/assets/images/onsen_yado/og_default.jpg?1702986206',
      onsenYadoCrossSellingInsuranceImportantMattersSompo1:
        '/yado/onsen/assets/images/onsen_yado/cross_selling/insurance/important_matters_sompo_1.jpg?1702986206',
      onsenYadoCrossSellingInsuranceImportantMattersSompo2:
        '/yado/onsen/assets/images/onsen_yado/cross_selling/insurance/important_matters_sompo_2.jpg?1702986206',
      onsenYadoOrderProgressUserActive:
        '/yado/onsen/assets/images/onsen_yado/order_progress/user_active.svg?1702986206',
      onsenYadoOrderProgressCompleteInactive:
        '/yado/onsen/assets/images/onsen_yado/order_progress/complete_inactive.svg?1702986206',
      onsenYadoOrderProgressCompleteActive:
        '/yado/onsen/assets/images/onsen_yado/order_progress/complete_active.svg?1702986206',
      onsenYadoOrderProgressBusInactive:
        '/yado/onsen/assets/images/onsen_yado/order_progress/bus_inactive.svg?1702986206',
      onsenYadoOrderProgressCardActive:
        '/yado/onsen/assets/images/onsen_yado/order_progress/card_active.svg?1702986206',
      onsenYadoOrderProgressCardInactive:
        '/yado/onsen/assets/images/onsen_yado/order_progress/card_inactive.svg?1702986206',
      onsenYadoOrderProgressBusActive:
        '/yado/onsen/assets/images/onsen_yado/order_progress/bus_active.svg?1702986206',
      onsenYadoOrderProgressUserInactive:
        '/yado/onsen/assets/images/onsen_yado/order_progress/user_inactive.svg?1702986206',
      onsenYadoIconToiletMarkerGray:
        '/yado/onsen/assets/images/onsen_yado/icon/toilet_marker_gray.svg?1702986206',
      onsenYadoIconPjtChatBubble:
        '/yado/onsen/assets/images/onsen_yado/icon/PJT/chat_bubble.svg?1702986206',
      onsenYadoIconPjtMealsBlue:
        '/yado/onsen/assets/images/onsen_yado/icon/PJT/meals_blue.svg?1702986206',
      onsenYadoIconPjtBenefitstag:
        '/yado/onsen/assets/images/onsen_yado/icon/PJT/benefitstag.svg?1702986206',
      onsenYadoIconPjtBenefitstagBrown:
        '/yado/onsen/assets/images/onsen_yado/icon/PJT/benefitstag_brown.svg?1703567287',
      onsenYadoIconPjtBusBlue:
        '/yado/onsen/assets/images/onsen_yado/icon/PJT/bus_blue.svg?1702986206',
      onsenYadoIconPjtBathBlue:
        '/yado/onsen/assets/images/onsen_yado/icon/PJT/bath_blue.svg?1702986206',
      onsenYadoIconPjtShieldBlue:
        '/yado/onsen/assets/images/onsen_yado/icon/PJT/shield_blue.svg?1702986206',
      onsenYadoIconPjtWifiBlue:
        '/yado/onsen/assets/images/onsen_yado/icon/PJT/wifi_blue.svg?1702986206',
      onsenYadoIconPjtClockBlue:
        '/yado/onsen/assets/images/onsen_yado/icon/PJT/clock_blue.svg?1702986206',
      onsenYadoIconImageGalleryRightArrow:
        '/yado/onsen/assets/images/onsen_yado/icon/image_gallery_right_arrow.svg?1702986206',
      onsenYadoIconSanitizerGreen:
        '/yado/onsen/assets/images/onsen_yado/icon/sanitizer_green.svg?1702986206',
      onsenYadoIconChatBubble:
        '/yado/onsen/assets/images/onsen_yado/icon/chat_bubble.svg?1702986206',
      onsenYadoIconMealsWhite:
        '/yado/onsen/assets/images/onsen_yado/icon/meals_white.svg?1702986206',
      onsenYadoIconShieldWhite:
        '/yado/onsen/assets/images/onsen_yado/icon/shield_white.svg?1702986206',
      onsenYadoIconMealsGreen:
        '/yado/onsen/assets/images/onsen_yado/icon/meals_green.svg?1702986206',
      onsenYadoIconImageGalleryLeftArrow:
        '/yado/onsen/assets/images/onsen_yado/icon/image_gallery_left_arrow.svg?1702986206',
      onsenYadoIconSanitizerBlue:
        '/yado/onsen/assets/images/onsen_yado/icon/sanitizer_blue.svg?1702986206',
      onsenYadoIconClockWhite:
        '/yado/onsen/assets/images/onsen_yado/icon/clock_white.svg?1702986206',
      onsenYadoIconMealsBlue: '/yado/onsen/assets/images/onsen_yado/icon/meals_blue.svg?1702986206',
      onsenYadoIconMealFlagRed:
        '/yado/onsen/assets/images/onsen_yado/icon/meal_flag_red.svg?1702986206',
      onsenYadoIconMealFlagBlue:
        '/yado/onsen/assets/images/onsen_yado/icon/meal_flag_blue.svg?1702986206',
      onsenYadoIconCheckGreen:
        '/yado/onsen/assets/images/onsen_yado/icon/check_green.svg?1702986206',
      onsenYadoIconNoSmokingGray:
        '/yado/onsen/assets/images/onsen_yado/icon/no_smoking_gray.svg?1702986206',
      onsenYadoIconBathGreen: '/yado/onsen/assets/images/onsen_yado/icon/bath_green.svg?1702986206',
      onsenYadoIconMoonWhite: '/yado/onsen/assets/images/onsen_yado/icon/moon_white.svg?1702986206',
      onsenYadoIconWifiGray: '/yado/onsen/assets/images/onsen_yado/icon/wifi_gray.svg?1702986206',
      onsenYadoIconBusBlue: '/yado/onsen/assets/images/onsen_yado/icon/bus_blue.svg?1702986206',
      onsenYadoIconBedGray: '/yado/onsen/assets/images/onsen_yado/icon/bed_gray.svg?1702986206',
      onsenYadoIconBathTubGray:
        '/yado/onsen/assets/images/onsen_yado/icon/bath_tub_gray.svg?1702986206',
      onsenYadoIconWifiWhite: '/yado/onsen/assets/images/onsen_yado/icon/wifi_white.svg?1702986206',
      onsenYadoIconBusWhite: '/yado/onsen/assets/images/onsen_yado/icon/bus_white.svg?1702986206',
      onsenYadoIconBreakfastDinnerWhite:
        '/yado/onsen/assets/images/onsen_yado/icon/breakfast_dinner_white.svg?1702986206',
      onsenYadoIconBathBlue: '/yado/onsen/assets/images/onsen_yado/icon/bath_blue.svg?1702986206',
      onsenYadoIconShieldBlue:
        '/yado/onsen/assets/images/onsen_yado/icon/shield_blue.svg?1702986206',
      onsenYadoIconWifiBlue: '/yado/onsen/assets/images/onsen_yado/icon/wifi_blue.svg?1702986206',
      onsenYadoIconBathWhite: '/yado/onsen/assets/images/onsen_yado/icon/bath_white.svg?1702986206',
      onsenYadoIconClockBlue: '/yado/onsen/assets/images/onsen_yado/icon/clock_blue.svg?1702986206',
      onsenYadoInnerBathIcon: '/yado/onsen/assets/images/onsen_yado/inner_bath_icon.svg?1702986206',
      onsenYadoPromoThumb62x:
        '/yado/onsen/assets/images/onsen_yado/promo/thumb-6_2x.jpg?1702986206',
      onsenYadoPromoThumb43x:
        '/yado/onsen/assets/images/onsen_yado/promo/thumb-4_3x.jpg?1702986206',
      onsenYadoPromoPhoto22x:
        '/yado/onsen/assets/images/onsen_yado/promo/photo-2_2x.jpg?1702986206',
      onsenYadoPromoShield: '/yado/onsen/assets/images/onsen_yado/promo/shield.jpg?1702986206',
      onsenYadoPromoTendo: '/yado/onsen/assets/images/onsen_yado/promo/tendo.jpg?1702986206',
      onsenYadoPromo202003Number1:
        '/yado/onsen/assets/images/onsen_yado/promo/202003/number1.svg?1702986206',
      onsenYadoPromo202003SmileIcon:
        '/yado/onsen/assets/images/onsen_yado/promo/202003/smile_icon.svg?1702986206',
      onsenYadoPromo202003KeyVisualSp:
        '/yado/onsen/assets/images/onsen_yado/promo/202003/key_visual_sp.png?1702986206',
      onsenYadoPromo202003FollowLeftDecoration:
        '/yado/onsen/assets/images/onsen_yado/promo/202003/follow_left_decoration.png?1702986206',
      onsenYadoPromo202003Number2:
        '/yado/onsen/assets/images/onsen_yado/promo/202003/number2.svg?1702986206',
      onsenYadoPromo202003FollowRightManyDecoration:
        '/yado/onsen/assets/images/onsen_yado/promo/202003/follow_right_many_decoration.png?1702986206',
      onsenYadoPromo202003Number3:
        '/yado/onsen/assets/images/onsen_yado/promo/202003/number3.svg?1702986206',
      onsenYadoPromo202003Characteristic2Pc:
        '/yado/onsen/assets/images/onsen_yado/promo/202003/characteristic2_pc.png?1702986206',
      onsenYadoPromo202003Envelope:
        '/yado/onsen/assets/images/onsen_yado/promo/202003/envelope.png?1702986206',
      onsenYadoPromo202003Qrcode:
        '/yado/onsen/assets/images/onsen_yado/promo/202003/qrcode.png?1702986206',
      onsenYadoPromo202003JapanMapIcon:
        '/yado/onsen/assets/images/onsen_yado/promo/202003/japan_map_icon.svg?1702986206',
      onsenYadoPromo202003Characteristic1Sp:
        '/yado/onsen/assets/images/onsen_yado/promo/202003/characteristic1_sp.png?1702986206',
      onsenYadoPromo202003JapanFlagIcon:
        '/yado/onsen/assets/images/onsen_yado/promo/202003/japan_flag_icon.svg?1702986206',
      onsenYadoPromo202003FollowLeftManyDecoration:
        '/yado/onsen/assets/images/onsen_yado/promo/202003/follow_left_many_decoration.png?1702986206',
      onsenYadoPromo202003TicketIcon:
        '/yado/onsen/assets/images/onsen_yado/promo/202003/ticket_icon.svg?1702986206',
      onsenYadoPromo202003CardIcon:
        '/yado/onsen/assets/images/onsen_yado/promo/202003/card_icon.svg?1702986206',
      onsenYadoPromo202003EnvelopeLeftDecoration:
        '/yado/onsen/assets/images/onsen_yado/promo/202003/envelope_left_decoration.png?1702986206',
      onsenYadoPromo202003Characteristic3Sp:
        '/yado/onsen/assets/images/onsen_yado/promo/202003/characteristic3_sp.png?1702986206',
      onsenYadoPromo202003KeyVisualLabelSp:
        '/yado/onsen/assets/images/onsen_yado/promo/202003/key_visual_label_sp.png?1702986206',
      onsenYadoPromo202003Characteristic1Pc:
        '/yado/onsen/assets/images/onsen_yado/promo/202003/characteristic1_pc.png?1702986206',
      onsenYadoPromo202003EnvelopeRightDecoration:
        '/yado/onsen/assets/images/onsen_yado/promo/202003/envelope_right_decoration.png?1702986206',
      onsenYadoPromo202003HandshakeIcon:
        '/yado/onsen/assets/images/onsen_yado/promo/202003/handshake_icon.svg?1702986206',
      onsenYadoPromo202003KeyVisualLabelPc:
        '/yado/onsen/assets/images/onsen_yado/promo/202003/key_visual_label_pc.png?1702986206',
      onsenYadoPromo202003Characteristic3Pc:
        '/yado/onsen/assets/images/onsen_yado/promo/202003/characteristic3_pc.png?1702986206',
      onsenYadoPromo202003MaleIcon:
        '/yado/onsen/assets/images/onsen_yado/promo/202003/male_icon.svg?1702986206',
      onsenYadoPromo202003KeyVisualPc:
        '/yado/onsen/assets/images/onsen_yado/promo/202003/key_visual_pc.png?1702986206',
      onsenYadoPromo202003FemaleIcon:
        '/yado/onsen/assets/images/onsen_yado/promo/202003/female_icon.svg?1702986206',
      onsenYadoPromo202003Characteristic2Sp:
        '/yado/onsen/assets/images/onsen_yado/promo/202003/characteristic2_sp.png?1702986206',
      onsenYadoPromo202003FollowRightDecoration:
        '/yado/onsen/assets/images/onsen_yado/promo/202003/follow_right_decoration.png?1702986206',
      onsenYadoPromoPhoto23x:
        '/yado/onsen/assets/images/onsen_yado/promo/photo-2_3x.jpg?1702986206',
      onsenYadoPromoThumb42x:
        '/yado/onsen/assets/images/onsen_yado/promo/thumb-4_2x.jpg?1702986206',
      onsenYadoPromoThumb63x:
        '/yado/onsen/assets/images/onsen_yado/promo/thumb-6_3x.jpg?1702986206',
      onsenYadoPromoPhoto63x:
        '/yado/onsen/assets/images/onsen_yado/promo/photo-6_3x.jpg?1702986206',
      onsenYadoPromoTop3x: '/yado/onsen/assets/images/onsen_yado/promo/top_3x.png?1702986206',
      onsenYadoPromoPhoto42x:
        '/yado/onsen/assets/images/onsen_yado/promo/photo-4_2x.jpg?1702986206',
      onsenYadoPromoThumb23x:
        '/yado/onsen/assets/images/onsen_yado/promo/thumb-2_3x.jpg?1702986206',
      onsenYadoPromoTop22x: '/yado/onsen/assets/images/onsen_yado/promo/top2_2x.png?1702986206',
      onsenYadoPromoBnrPc2x: '/yado/onsen/assets/images/onsen_yado/promo/bnr-pc_2x.png?1702986206',
      onsenYadoPromoBnrPc3x: '/yado/onsen/assets/images/onsen_yado/promo/bnr-pc_3x.png?1702986206',
      onsenYadoPromoNiku: '/yado/onsen/assets/images/onsen_yado/promo/niku.jpg?1702986206',
      onsenYadoPromoFutari: '/yado/onsen/assets/images/onsen_yado/promo/futari.jpg?1702986206',
      onsenYadoPromoTop23x: '/yado/onsen/assets/images/onsen_yado/promo/top2_3x.png?1702986206',
      onsenYadoPromoThumb22x:
        '/yado/onsen/assets/images/onsen_yado/promo/thumb-2_2x.jpg?1702986206',
      onsenYadoPromoMeta: '/yado/onsen/assets/images/onsen_yado/promo/meta.jpg?1702986206',
      onsenYadoPromoBnrSp: '/yado/onsen/assets/images/onsen_yado/promo/bnr-sp.png?1702986206',
      onsenYadoPromoOnsen: '/yado/onsen/assets/images/onsen_yado/promo/onsen.jpg?1702986206',
      onsenYadoPromoPhoto43x:
        '/yado/onsen/assets/images/onsen_yado/promo/photo-4_3x.jpg?1702986206',
      onsenYadoPromoTop2x: '/yado/onsen/assets/images/onsen_yado/promo/top_2x.png?1702986206',
      onsenYadoPromoZao: '/yado/onsen/assets/images/onsen_yado/promo/zao.jpg?1702986206',
      onsenYadoPromoPhoto62x:
        '/yado/onsen/assets/images/onsen_yado/promo/photo-6_2x.jpg?1702986206',
      onsenYadoPromoHeart: '/yado/onsen/assets/images/onsen_yado/promo/heart.jpg?1702986206',
      onsenYadoPromoPhoto13x:
        '/yado/onsen/assets/images/onsen_yado/promo/photo-1_3x.jpg?1702986206',
      onsenYadoPromoPhoto32x:
        '/yado/onsen/assets/images/onsen_yado/promo/photo-3_2x.jpg?1702986206',
      onsenYadoPromoTop: '/yado/onsen/assets/images/onsen_yado/promo/top.png?1702986206',
      onsenYadoPromoTop2: '/yado/onsen/assets/images/onsen_yado/promo/top2.png?1702986206',
      onsenYadoPromoThumb53x:
        '/yado/onsen/assets/images/onsen_yado/promo/thumb-5_3x.jpg?1702986206',
      onsenYadoPromoThumb52x:
        '/yado/onsen/assets/images/onsen_yado/promo/thumb-5_2x.jpg?1702986206',
      onsenYadoPromoJapanMap: '/yado/onsen/assets/images/onsen_yado/promo/japan-map.png?1702986206',
      onsenYadoPromoPhoto33x:
        '/yado/onsen/assets/images/onsen_yado/promo/photo-3_3x.jpg?1702986206',
      onsenYadoPromoHeadphone:
        '/yado/onsen/assets/images/onsen_yado/promo/headphone.jpg?1702986206',
      onsenYadoPromoPhoto12x:
        '/yado/onsen/assets/images/onsen_yado/promo/photo-1_2x.jpg?1702986206',
      onsenYadoPromoBnrPc: '/yado/onsen/assets/images/onsen_yado/promo/bnr-pc.png?1702986206',
      onsenYadoPromoThumb5: '/yado/onsen/assets/images/onsen_yado/promo/thumb-5.jpg?1702986206',
      onsenYadoPromoBnrSp2x: '/yado/onsen/assets/images/onsen_yado/promo/bnr-sp_2x.png?1702986206',
      onsenYadoPromoPhoto3: '/yado/onsen/assets/images/onsen_yado/promo/photo-3.jpg?1702986206',
      onsenYadoPromoThumb12x:
        '/yado/onsen/assets/images/onsen_yado/promo/thumb-1_2x.jpg?1702986206',
      onsenYadoPromoThumb33x:
        '/yado/onsen/assets/images/onsen_yado/promo/thumb-3_3x.jpg?1702986206',
      onsenYadoPromoPhoto2: '/yado/onsen/assets/images/onsen_yado/promo/photo-2.jpg?1702986206',
      onsenYadoPromoThumb4: '/yado/onsen/assets/images/onsen_yado/promo/thumb-4.jpg?1702986206',
      onsenYadoPromoThumb6: '/yado/onsen/assets/images/onsen_yado/promo/thumb-6.jpg?1702986206',
      onsenYadoPromoPhoto52x:
        '/yado/onsen/assets/images/onsen_yado/promo/photo-5_2x.jpg?1702986206',
      onsenYadoPromoJapanMap2x:
        '/yado/onsen/assets/images/onsen_yado/promo/japan-map_2x.png?1702986206',
      onsenYadoPromoBeppu: '/yado/onsen/assets/images/onsen_yado/promo/beppu.jpg?1702986206',
      onsenYadoPromoPhoto1: '/yado/onsen/assets/images/onsen_yado/promo/photo-1.jpg?1702986206',
      onsenYadoPromoPhoto5: '/yado/onsen/assets/images/onsen_yado/promo/photo-5.jpg?1702986206',
      onsenYadoPromoThumb3: '/yado/onsen/assets/images/onsen_yado/promo/thumb-3.jpg?1702986206',
      onsenYadoPromoJapanMap3x:
        '/yado/onsen/assets/images/onsen_yado/promo/japan-map_3x.png?1702986206',
      onsenYadoPromoKurokawa: '/yado/onsen/assets/images/onsen_yado/promo/kurokawa.jpg?1702986206',
      onsenYadoPromoBitmap: '/yado/onsen/assets/images/onsen_yado/promo/bitmap.jpg?1702986206',
      onsenYadoPromoPhoto53x:
        '/yado/onsen/assets/images/onsen_yado/promo/photo-5_3x.jpg?1702986206',
      onsenYadoPromoThumb2: '/yado/onsen/assets/images/onsen_yado/promo/thumb-2.jpg?1702986206',
      onsenYadoPromoPhoto4: '/yado/onsen/assets/images/onsen_yado/promo/photo-4.jpg?1702986206',
      onsenYadoPromoPhoto6: '/yado/onsen/assets/images/onsen_yado/promo/photo-6.jpg?1702986206',
      onsenYadoPromoThumb32x:
        '/yado/onsen/assets/images/onsen_yado/promo/thumb-3_2x.jpg?1702986206',
      onsenYadoPromoThumb13x:
        '/yado/onsen/assets/images/onsen_yado/promo/thumb-1_3x.jpg?1702986206',
      onsenYadoPromoThumb1: '/yado/onsen/assets/images/onsen_yado/promo/thumb-1.jpg?1702986206',
      onsenYadoPromoBnrSp3x: '/yado/onsen/assets/images/onsen_yado/promo/bnr-sp_3x.png?1702986206',
      newTopGalleryKisetsuBannerHorizontal:
        '/yado/onsen/assets/images/new_top/gallery/kisetsu_banner_horizontal.png?1722306608',
      newTopGalleryPrivateBathHorizontal:
        '/yado/onsen/assets/images/new_top/gallery/private_bath_horizontal.jpg?1702986206',
      newTopGallerySnowOnsenHorizontal:
        '/yado/onsen/assets/images/new_top/gallery/snow_onsen_horizontal.jpg?1702986206',
      newTopGalleryLimitedPlan202404:
        '/yado/onsen/assets/images/new_top/gallery/limited_plan_202404.jpg?1712306405',
      newTopGalleryYufuin: '/yado/onsen/assets/images/new_top/gallery/yufuin.jpg?1713761370',
      newTopGalleryKusatsu: '/yado/onsen/assets/images/new_top/gallery/kusatsu.jpg?1713761370',
      newTopKeyvisualAccommodation2:
        '/yado/onsen/assets/images/new_top/keyvisual/accommodation2.jpg?1702986206',
      newTopKeyvisualAccommodation3:
        '/yado/onsen/assets/images/new_top/keyvisual/accommodation3.jpg?1702986206',
      newTopKeyvisualAccommodation1:
        '/yado/onsen/assets/images/new_top/keyvisual/accommodation1.jpg?1702986206',
      newTopKeyvisualAccommodation4:
        '/yado/onsen/assets/images/new_top/keyvisual/accommodation4.jpg?1702986206',
      newTopKeyvisualAccommodation5:
        '/yado/onsen/assets/images/new_top/keyvisual/accommodation5.jpg?1702986206',
      activity206: '/yado/onsen/assets/images/activity/206.jpg?1702986206',
      activity207: '/yado/onsen/assets/images/activity/207.jpg?1702986206',
      activity208: '/yado/onsen/assets/images/activity/208.jpg?1702986206',
      activity182: '/yado/onsen/assets/images/activity/182.jpg?1702986206',
      activity146: '/yado/onsen/assets/images/activity/146.jpg?1702986206',
      aboutIntropickup1: '/yado/onsen/assets/images/about/introPickup1.jpg?1702986206',
      aboutIntrotop2: '/yado/onsen/assets/images/about/introTop2.jpg?1702986206',
      aboutAbouttop: '/yado/onsen/assets/images/about/aboutTop.jpg?1702986206',
      aboutIntropickup2: '/yado/onsen/assets/images/about/introPickup2.jpg?1702986206',
      aboutIntrotop1: '/yado/onsen/assets/images/about/introtop1.jpg?1702986206',
      aboutMealdiningroom: '/yado/onsen/assets/images/about/mealDiningRoom.jpg?1702986206',
      aboutIntroonsen2: '/yado/onsen/assets/images/about/introOnsen2.jpg?1702986206',
      aboutMealjapanese: '/yado/onsen/assets/images/about/mealJapanese.jpg?1702986206',
      aboutIntroonsen1: '/yado/onsen/assets/images/about/introOnsen1.jpg?1702986206',
      aboutIntrotop2Sp: '/yado/onsen/assets/images/about/introtop2-sp.jpg?1702986206',
      aboutMealeclectic: '/yado/onsen/assets/images/about/mealEclectic.jpg?1702986206',
      aboutOnsentype1: '/yado/onsen/assets/images/about/onsenType1.jpg?1702986206',
      aboutIntroonsen: '/yado/onsen/assets/images/about/introOnsen.svg?1702986206',
      aboutOnsentype2: '/yado/onsen/assets/images/about/onsenType2.jpg?1702986206',
      aboutArrowright: '/yado/onsen/assets/images/about/arrowRight.svg?1702986206',
      aboutMealbuffet: '/yado/onsen/assets/images/about/mealBuffet.jpg?1702986206',
      aboutIntromeal: '/yado/onsen/assets/images/about/introMeal.svg?1702986206',
      aboutArrowrightwhite: '/yado/onsen/assets/images/about/arrowRightWhite.svg?1702986206',
      aboutIntromeal2: '/yado/onsen/assets/images/about/introMeal2.jpg?1702986206',
      aboutMealrequest2: '/yado/onsen/assets/images/about/mealrequest2.jpg?1702986206',
      aboutMealitalian: '/yado/onsen/assets/images/about/mealItalian.jpg?1702986206',
      aboutOnsenrequest1: '/yado/onsen/assets/images/about/onsenRequest1.jpg?1702986206',
      aboutIntrorecombelt: '/yado/onsen/assets/images/about/introRecomBelt.jpg?1703567287',
      aboutIntroroomsearch: '/yado/onsen/assets/images/about/introRoomSearch.jpg?1702986206',
      aboutQicon: '/yado/onsen/assets/images/about/QIcon.svg?1702986206',
      aboutMealprivateroom: '/yado/onsen/assets/images/about/mealPrivateRoom.jpg?1702986206',
      aboutMealheader: '/yado/onsen/assets/images/about/mealheader.jpg?1702986206',
      aboutOnsenrequest3: '/yado/onsen/assets/images/about/onsenRequest3.jpg?1702986206',
      aboutPickupdesc: '/yado/onsen/assets/images/about/pickupDesc.jpg?1702986206',
      aboutIntromeal1: '/yado/onsen/assets/images/about/introMeal1.jpg?1702986206',
      aboutMealrequest1: '/yado/onsen/assets/images/about/mealrequest1.jpg?1702986206',
      aboutOnsenrequest2: '/yado/onsen/assets/images/about/onsenRequest2.jpg?1702986206',
      aboutServicelistpickup: '/yado/onsen/assets/images/about/serviceListPickup.jpg?1702986206',
      aboutIntrorecom1: '/yado/onsen/assets/images/about/introRecom1.jpg?1702986206',
      aboutAicon: '/yado/onsen/assets/images/about/AIcon.svg?1702986206',
      aboutIntromeal2sp: '/yado/onsen/assets/images/about/introMeal2Sp.jpg?1702986206',
      aboutIntrorecom2: '/yado/onsen/assets/images/about/introRecom2.jpg?1702986206',
      aboutIntrorecom3: '/yado/onsen/assets/images/about/introRecom3.jpg?1702986206',
      aboutStepsPickupRequestAnser2Sp:
        '/yado/onsen/assets/images/about/steps/pickup-request-anser2-sp.jpg?1702986206',
      aboutStepsPickupHowTo13Sp:
        '/yado/onsen/assets/images/about/steps/pickup-how-to1-3-sp.jpg?1702986206',
      aboutStepsOnsenHowTo1Pc:
        '/yado/onsen/assets/images/about/steps/onsen-how-to1-pc.jpg?1702986206',
      aboutStepsPickupHowTo11Sp:
        '/yado/onsen/assets/images/about/steps/pickup-how-to1-1-sp.jpg?1702986206',
      aboutStepsOnsenRequestAnser2Pc:
        '/yado/onsen/assets/images/about/steps/onsen-request-anser2-pc.jpg?1702986206',
      aboutStepsMealHowTo2Sp:
        '/yado/onsen/assets/images/about/steps/meal-how-to2-sp.jpg?1702986206',
      aboutStepsPickupHowTo22Sp:
        '/yado/onsen/assets/images/about/steps/pickup-how-to2-2-sp.jpg?1702986206',
      aboutStepsPickupHowTo32Sp:
        '/yado/onsen/assets/images/about/steps/pickup-how-to3-2-sp.jpg?1702986206',
      aboutStepsMealRequestAnser2Pc:
        '/yado/onsen/assets/images/about/steps/meal-request-anser2-pc.jpg?1702986206',
      aboutStepsPickupHowTo12Pc:
        '/yado/onsen/assets/images/about/steps/pickup-how-to1-2-pc.jpg?1702986206',
      aboutStepsOnsenRequestAnser1Sp:
        '/yado/onsen/assets/images/about/steps/onsen-request-anser1-sp.jpg?1702986206',
      aboutStepsMealHowTo1Pc:
        '/yado/onsen/assets/images/about/steps/meal-how-to1-pc.jpg?1702986206',
      aboutStepsPickupHowTo31Pc:
        '/yado/onsen/assets/images/about/steps/pickup-how-to3-1-pc.jpg?1702986206',
      aboutStepsPickupHowTo21Pc:
        '/yado/onsen/assets/images/about/steps/pickup-how-to2-1-pc.jpg?1702986206',
      aboutStepsMealRequestAnser1Sp:
        '/yado/onsen/assets/images/about/steps/meal-request-anser1-sp.jpg?1702986206',
      aboutStepsPickupRequestAnser3Pc:
        '/yado/onsen/assets/images/about/steps/pickup-request-anser3-pc.jpg?1702986206',
      aboutStepsPickupRequestAnser1Pc:
        '/yado/onsen/assets/images/about/steps/pickup-request-anser1-pc.jpg?1702986206',
      aboutStepsPickupHowTo33Pc:
        '/yado/onsen/assets/images/about/steps/pickup-how-to3-3-pc.jpg?1702986206',
      aboutStepsPickupHowTo23Pc:
        '/yado/onsen/assets/images/about/steps/pickup-how-to2-3-pc.jpg?1702986206',
      aboutStepsOnsenHowTo2Sp:
        '/yado/onsen/assets/images/about/steps/onsen-how-to2-sp.jpg?1702986206',
      aboutStepsPickupHowTo12Sp:
        '/yado/onsen/assets/images/about/steps/pickup-how-to1-2-sp.jpg?1702986206',
      aboutStepsMealRequestAnser1Pc:
        '/yado/onsen/assets/images/about/steps/meal-request-anser1-pc.jpg?1702986206',
      aboutStepsPickupRequestAnser3Sp:
        '/yado/onsen/assets/images/about/steps/pickup-request-anser3-sp.jpg?1702986206',
      aboutStepsPickupHowTo31Sp:
        '/yado/onsen/assets/images/about/steps/pickup-how-to3-1-sp.jpg?1702986206',
      aboutStepsPickupHowTo21Sp:
        '/yado/onsen/assets/images/about/steps/pickup-how-to2-1-sp.jpg?1702986206',
      aboutStepsMealHowTo1Sp:
        '/yado/onsen/assets/images/about/steps/meal-how-to1-sp.jpg?1702986206',
      aboutStepsOnsenRequestAnser1Pc:
        '/yado/onsen/assets/images/about/steps/onsen-request-anser1-pc.jpg?1702986206',
      aboutStepsPickupHowTo33Sp:
        '/yado/onsen/assets/images/about/steps/pickup-how-to3-3-sp.jpg?1702986206',
      aboutStepsPickupHowTo23Sp:
        '/yado/onsen/assets/images/about/steps/pickup-how-to2-3-sp.jpg?1702986206',
      aboutStepsPickupRequestAnser1Sp:
        '/yado/onsen/assets/images/about/steps/pickup-request-anser1-sp.jpg?1702986206',
      aboutStepsOnsenHowTo2Pc:
        '/yado/onsen/assets/images/about/steps/onsen-how-to2-pc.jpg?1702986206',
      aboutStepsRenameHowto: '/yado/onsen/assets/images/about/steps/rename_howto.js?1702986206',
      aboutStepsPickupRequestAnser2Pc:
        '/yado/onsen/assets/images/about/steps/pickup-request-anser2-pc.jpg?1702986206',
      aboutStepsOnsenHowTo1Sp:
        '/yado/onsen/assets/images/about/steps/onsen-how-to1-sp.jpg?1702986206',
      aboutStepsPickupHowTo13Pc:
        '/yado/onsen/assets/images/about/steps/pickup-how-to1-3-pc.jpg?1702986206',
      aboutStepsPickupHowTo11Pc:
        '/yado/onsen/assets/images/about/steps/pickup-how-to1-1-pc.jpg?1702986206',
      aboutStepsMealRequestAnser2Sp:
        '/yado/onsen/assets/images/about/steps/meal-request-anser2-sp.jpg?1702986206',
      aboutStepsPickupHowTo22Pc:
        '/yado/onsen/assets/images/about/steps/pickup-how-to2-2-pc.jpg?1702986206',
      aboutStepsPickupHowTo32Pc:
        '/yado/onsen/assets/images/about/steps/pickup-how-to3-2-pc.jpg?1702986206',
      aboutStepsMealHowTo2Pc:
        '/yado/onsen/assets/images/about/steps/meal-how-to2-pc.jpg?1702986206',
      aboutStepsOnsenRequestAnser2Sp:
        '/yado/onsen/assets/images/about/steps/onsen-request-anser2-sp.jpg?1702986206',
      aboutOnsenheader: '/yado/onsen/assets/images/about/onsenHeader.jpg?1702986206',
      aboutIntrorecom4: '/yado/onsen/assets/images/about/introRecom4.jpg?1702986206',
      aboutIntrorecom5: '/yado/onsen/assets/images/about/introRecom5.jpg?1702986206',
      aboutIntropickup: '/yado/onsen/assets/images/about/introPickup.svg?1702986206',
      aboutIntrobackgroundpattern:
        '/yado/onsen/assets/images/about/introBackgroundPattern.svg?1702986206',
      aboutPickupheader: '/yado/onsen/assets/images/about/pickupHeader.jpg?1702986206',
      aboutMealfrench: '/yado/onsen/assets/images/about/mealFrench.jpg?1702986206',
      aboutMealchinese: '/yado/onsen/assets/images/about/mealChinese.jpg?1702986206',
      aboutMealdesc: '/yado/onsen/assets/images/about/mealDesc.jpg?1702986206',
      aboutOnsendesc: '/yado/onsen/assets/images/about/onsenDesc.jpg?1702986206',
      aboutMealrestaurant: '/yado/onsen/assets/images/about/mealRestaurant.jpg?1702986206',
      aboutPickuprequest1: '/yado/onsen/assets/images/about/pickupRequest1.jpg?1702986206',
      aboutIntropickup2sp: '/yado/onsen/assets/images/about/introPickup2Sp.jpg?1702986206',
      aboutPickuprequest3: '/yado/onsen/assets/images/about/pickupRequest3.jpg?1702986206',
      aboutPickuprequest2: '/yado/onsen/assets/images/about/pickupRequest2.jpg?1702986206',
      commonStarPink: '/yado/onsen/assets/images/common/star_pink.svg?1702986206',
      commonHeaderAccountIcon:
        '/yado/onsen/assets/images/common/header_account_icon.svg?1702986206',
      commonPayLaterCardTop: '/yado/onsen/assets/images/common/pay_later_card_top.png?1702986206',
      commonAfteeLogo: '/yado/onsen/assets/images/common/aftee_logo.png?1702986206',
      commonFlag: '/yado/onsen/assets/images/common/flag.png?1702986206',
      commonCityMap: '/yado/onsen/assets/images/common/city_map.png?1702986206',
      commonLogoWamazing: '/yado/onsen/assets/images/common/logo_wamazing.svg?1702986206',
      commonCreditCardVisa: '/yado/onsen/assets/images/common/credit_card/visa.svg?1702986206',
      commonCreditCardMaster: '/yado/onsen/assets/images/common/credit_card/master.svg?1702986206',
      commonCreditCardCvv: '/yado/onsen/assets/images/common/credit_card/cvv.svg?1702986206',
      commonCreditCardDiners: '/yado/onsen/assets/images/common/credit_card/diners.svg?1702986206',
      commonCreditCardCreditCardSample:
        '/yado/onsen/assets/images/common/credit_card/credit_card_sample.png?1702986206',
      commonCreditCardUc: '/yado/onsen/assets/images/common/credit_card/uc.svg?1702986206',
      commonCreditCardAmericanExpress:
        '/yado/onsen/assets/images/common/credit_card/american_express.svg?1702986206',
      commonCreditCardWechatpay:
        '/yado/onsen/assets/images/common/credit_card/wechatpay.svg?1702986206',
      commonCreditCardUnionpay:
        '/yado/onsen/assets/images/common/credit_card/unionpay.svg?1702986206',
      commonCreditCardMastercard:
        '/yado/onsen/assets/images/common/credit_card/mastercard.svg?1702986206',
      commonCreditCardAmex: '/yado/onsen/assets/images/common/credit_card/amex.svg?1702986206',
      commonCreditCardJcb: '/yado/onsen/assets/images/common/credit_card/jcb.svg?1702986206',
      commonCreditCardAlipay: '/yado/onsen/assets/images/common/credit_card/alipay.svg?1702986206',
      commonCreditCardIccard: '/yado/onsen/assets/images/common/credit_card/iccard.svg?1702986206',
      commonCreditCardLinepay:
        '/yado/onsen/assets/images/common/credit_card/linepay.svg?1702986206',
      commonCreditCardDc: '/yado/onsen/assets/images/common/credit_card/dc.svg?1702986206',
      commonSnsFacebook: '/yado/onsen/assets/images/common/sns_facebook.svg?1702986206',
      commonLogoJrWest: '/yado/onsen/assets/images/common/logo_jr_west.png?1702986206',
      commonSnsInstagram: '/yado/onsen/assets/images/common/sns_instagram.svg?1702986206',
      commonLineSquare: '/yado/onsen/assets/images/common/line_square.svg?1711429709',
      commonInfoWhite: '/yado/onsen/assets/images/common/info_white.svg?1702986206',
      commonChatBubbleLgBlue: '/yado/onsen/assets/images/common/chat_bubble_lg_blue.svg?1715675030',
      commonAppStoreStoreApple:
        '/yado/onsen/assets/images/common/app_store/store_apple.png?1702986206',
      commonAppStoreStoreAndroid:
        '/yado/onsen/assets/images/common/app_store/store_android.png?1702986206',
      commonArrowBlueUp: '/yado/onsen/assets/images/common/arrow_blue_up.svg?1702986206',
      commonAddLightBlue: '/yado/onsen/assets/images/common/add_light_blue.svg?1702986206',
      commonChatBubbleLg: '/yado/onsen/assets/images/common/chat_bubble_lg.svg?1715675030',
      commonMapMarkerGrayThin:
        '/yado/onsen/assets/images/common/map_marker_gray_thin.svg?1714546253',
      commonRightArrow: '/yado/onsen/assets/images/common/right_arrow.svg?1702986206',
      commonLoading: '/yado/onsen/assets/images/common/loading.mp4?1702986206',
      commonArrowBlueRight: '/yado/onsen/assets/images/common/arrow_blue_right.svg?1702986206',
      commonMapMarker: '/yado/onsen/assets/images/common/map_marker.svg?1702986206',
      commonLeftArrow: '/yado/onsen/assets/images/common/left_arrow.svg?1702986206',
      commonQuestionnaire: '/yado/onsen/assets/images/common/questionnaire.svg?1702986206',
      commonPlaceholderWhite: '/yado/onsen/assets/images/common/placeholder_white.png?1702986206',
      commonOpenInNewTab: '/yado/onsen/assets/images/common/open_in_new_tab.svg?1702986206',
      commonPlaceholderGray: '/yado/onsen/assets/images/common/placeholder_gray.png?1702986206',
      commonStarWhite: '/yado/onsen/assets/images/common/star_white.svg?1702986206',
      commonAddBlue: '/yado/onsen/assets/images/common/add_blue.svg?1702986206',
      commonHelpBlue: '/yado/onsen/assets/images/common/help_blue.svg?1702986206',
      commonLogoJrEast: '/yado/onsen/assets/images/common/logo_jr_east.png?1702986206',
      commonPaymentMethodCreditCard:
        '/yado/onsen/assets/images/common/payment_method/credit_card.svg?1702986206',
      commonPaymentMethodMobilePay:
        '/yado/onsen/assets/images/common/payment_method/mobile_pay.svg?1702986206',
      commonMapMarkerGray: '/yado/onsen/assets/images/common/map_marker_gray.svg?1702986206',
      commonArrowBlueDown: '/yado/onsen/assets/images/common/arrow_blue_down.svg?1702986206',
      commonDownArrow: '/yado/onsen/assets/images/common/down_arrow.svg?1702986206',
      commonStartDarkYellow: '/yado/onsen/assets/images/common/start_dark_yellow.svg?1702986206',
      commonCloseBtnGray: '/yado/onsen/assets/images/common/close_btn_gray.svg?1702986206',
      commonSelectTriangles: '/yado/onsen/assets/images/common/select_triangles.svg?1703567287',
      commonModalCloseButtonWhite:
        '/yado/onsen/assets/images/common/modal_close_button_white.svg?1702986206',
      commonWechatpay: '/yado/onsen/assets/images/common/wechatpay.svg?1702986206',
      commonCloseIcon: '/yado/onsen/assets/images/common/close_icon.svg?1702986206',
      commonNewWindowLink: '/yado/onsen/assets/images/common/new_window_link.svg?1702986206',
      commonBenefit: '/yado/onsen/assets/images/common/benefit.svg?1702986206',
      commonPayLaterCard: '/yado/onsen/assets/images/common/pay_later_card.png?1702986206',
      commonModalCloseButton: '/yado/onsen/assets/images/common/modal_close_button.svg?1702986206',
      commonLogoWamazingVertical:
        '/yado/onsen/assets/images/common/logo_wamazing_vertical.svg?1702986206',
      commonMapHotelHovered: '/yado/onsen/assets/images/common/map_hotel_hovered.svg?1702986206',
      commonHelpGreen: '/yado/onsen/assets/images/common/help_green.svg?1702986206',
      commonMapHotel: '/yado/onsen/assets/images/common/map_hotel.svg?1702986206',
      commonWechatLogo: '/yado/onsen/assets/images/common/wechat_logo.svg?1702986206',
      commonStarYellow: '/yado/onsen/assets/images/common/star_yellow.svg?1702986206',
      commonLogoWamazingHorizontal:
        '/yado/onsen/assets/images/common/logo_wamazing_horizontal.svg?1702986206',
      commonImageWhite: '/yado/onsen/assets/images/common/image_white.png?1702986206',
      commonHotelIcon: '/yado/onsen/assets/images/common/hotel_icon.svg?1702986206',
      commonLogoWamazingHorizontalWhite:
        '/yado/onsen/assets/images/common/logo_wamazing_horizontal_white.svg?1702986206',
      commonCrownNum2: '/yado/onsen/assets/images/common/crown_num2.svg?1702986206',
      commonAirport: '/yado/onsen/assets/images/common/airport.svg?1702986206',
      commonMapMainHotel: '/yado/onsen/assets/images/common/map_main_hotel.svg?1702986206',
      commonCrownNum3: '/yado/onsen/assets/images/common/crown_num3.svg?1702986206',
      commonModalCloseButtonBold:
        '/yado/onsen/assets/images/common/modal_close_button_bold.svg?1702986206',
      commonCrownNum1: '/yado/onsen/assets/images/common/crown_num1.svg?1702986206',
      commonMagnifyingGlass: '/yado/onsen/assets/images/common/magnifying_glass.svg?1702986206',
      supportKvIllustSp: '/yado/onsen/assets/images/support/kv-illust-sp.png?1715068475',
      supportSupportBarIllustPc:
        '/yado/onsen/assets/images/support/support-bar-illust-pc.png?1722305990',
      supportSupportBannerPc: '/yado/onsen/assets/images/support/support-banner-pc.png?1715675030',
      supportSupportSubtitle: '/yado/onsen/assets/images/support/support-subtitle.jpg?1715068475',
      supportSupportBarIllustSp:
        '/yado/onsen/assets/images/support/support-bar-illust-sp.png?1722305990',
      supportSupportBannerSp: '/yado/onsen/assets/images/support/support-banner-sp.png?1715675030',
      supportKeyvisual: '/yado/onsen/assets/images/support/keyvisual.jpg?1715068475',
      supportQna1: '/yado/onsen/assets/images/support/qna1.png?1715068475',
      supportKeyvisualSp: '/yado/onsen/assets/images/support/keyvisual-sp.jpg?1715068475',
      supportSupportq: '/yado/onsen/assets/images/support/supportq.png?1715068475',
      supportQna3: '/yado/onsen/assets/images/support/qna3.png?1715068475',
      supportQna2: '/yado/onsen/assets/images/support/qna2.png?1715068475',
      supportKvIllust: '/yado/onsen/assets/images/support/kv-illust.png?1715068475',
      supportCircleChevronYellowGreen:
        '/yado/onsen/assets/images/support/circle-chevron-yellow-green.png?1722305990',
      supportSupportChat1: '/yado/onsen/assets/images/support/support-chat-1.png?1715068475',
      supportNum01: '/yado/onsen/assets/images/support/Num01.svg?1715068475',
      supportNum03: '/yado/onsen/assets/images/support/Num03.svg?1715068475',
      supportSupportChat2: '/yado/onsen/assets/images/support/support-chat-2.png?1715068475',
      supportNum02: '/yado/onsen/assets/images/support/Num02.svg?1715068475',
      supportIntroIllust: '/yado/onsen/assets/images/support/intro-illust.png?1715068475',
      campaignActivityBannerHorizontal:
        '/yado/onsen/assets/images/campaign/activity/banner_horizontal.png?1709702960',
      campaignAfteeBannerHorizontal:
        '/yado/onsen/assets/images/campaign/aftee/banner_horizontal.jpg?1702986206',
      campaignAfteeBannerVertical:
        '/yado/onsen/assets/images/campaign/aftee/banner_vertical.jpg?1702986206',
      campaignNissanBannerHorizontal:
        '/yado/onsen/assets/images/campaign/nissan/banner_horizontal.png?1708579723',
      campaignClock: '/yado/onsen/assets/images/campaign/clock.svg?1702986206',
      countryTopLogoHorizontalPink:
        '/yado/onsen/assets/images/country_top/logo_horizontal_pink.svg?1702986206',
      countryTopHero: '/yado/onsen/assets/images/country_top/hero.jpg?1702986206',
      countryTopActivityFeature01:
        '/yado/onsen/assets/images/country_top/activity/feature01.png?1702986206',
      countryTopActivityFeature03:
        '/yado/onsen/assets/images/country_top/activity/feature03.png?1702986206',
      countryTopActivityFeature02:
        '/yado/onsen/assets/images/country_top/activity/feature02.png?1702986206',
      countryTopLogoHorizontalWhite:
        '/yado/onsen/assets/images/country_top/logo_horizontal_white.svg?1702986206',
      countryTopKaimonoFeature01:
        '/yado/onsen/assets/images/country_top/kaimono/feature01.png?1702986206',
      countryTopKaimonoFeature03:
        '/yado/onsen/assets/images/country_top/kaimono/feature03.png?1702986206',
      countryTopKaimonoFeature02:
        '/yado/onsen/assets/images/country_top/kaimono/feature02.png?1702986206',
      countryTopHeroOkinawa: '/yado/onsen/assets/images/country_top/hero_okinawa.jpg?1702986206',
      countryTopTicketFeature01:
        '/yado/onsen/assets/images/country_top/ticket/feature01.png?1702986206',
      countryTopTicketFeature03:
        '/yado/onsen/assets/images/country_top/ticket/feature03.png?1702986206',
      countryTopTicketFeature02:
        '/yado/onsen/assets/images/country_top/ticket/feature02.png?1702986206',
      countryTopSnowFeature01:
        '/yado/onsen/assets/images/country_top/snow/feature01.png?1702986206',
      countryTopSnowFeature03:
        '/yado/onsen/assets/images/country_top/snow/feature03.png?1702986206',
      countryTopSnowFeature02:
        '/yado/onsen/assets/images/country_top/snow/feature02.png?1702986206',
      countryTopSimFeature01: '/yado/onsen/assets/images/country_top/sim/feature01.png?1702986206',
      countryTopSimFeature03: '/yado/onsen/assets/images/country_top/sim/feature03.png?1702986206',
      countryTopSimFeature02: '/yado/onsen/assets/images/country_top/sim/feature02.png?1702986206',
      countryTopInsuranceFeature01:
        '/yado/onsen/assets/images/country_top/insurance/feature01.png?1702986206',
      countryTopInsuranceFeature03:
        '/yado/onsen/assets/images/country_top/insurance/feature03.png?1702986206',
      countryTopInsuranceFeature02:
        '/yado/onsen/assets/images/country_top/insurance/feature02.png?1702986206',
      countryTopOnsenFeature01:
        '/yado/onsen/assets/images/country_top/onsen/feature01.png?1702986206',
      countryTopOnsenFeature03:
        '/yado/onsen/assets/images/country_top/onsen/feature03.png?1702986206',
      countryTopOnsenFeature02:
        '/yado/onsen/assets/images/country_top/onsen/feature02.png?1702986206',
      countryTopJapan: '/yado/onsen/assets/images/country_top/japan.png?1702986206',
      countryTopMediaFeature01:
        '/yado/onsen/assets/images/country_top/media/feature01.png?1702986206',
      countryTopMediaFeature03:
        '/yado/onsen/assets/images/country_top/media/feature03.png?1702986206',
      countryTopMediaFeature02:
        '/yado/onsen/assets/images/country_top/media/feature02.png?1702986206',
      featuredLimited202312LimitedPlan:
        '/yado/onsen/assets/images/featured/limited202312/limited_plan.jpg?1702986206',
      featured202311NewListing2023nov:
        '/yado/onsen/assets/images/featured/202311/NEW_listing_2023Nov.jpg?1702986206',
      featured202311Kyoritsu: '/yado/onsen/assets/images/featured/202311/kyoritsu.jpg?1702986206',
      featured202402NewListing2024feb:
        '/yado/onsen/assets/images/featured/202402/NEW_listing_2024Feb.jpg?1710821062',
      featured202403NewListing2024mar:
        '/yado/onsen/assets/images/featured/202403/NEW_listing_2024Mar.jpg?1712306405',
      featured202304WagyuOnsen:
        '/yado/onsen/assets/images/featured/202304/wagyu_onsen.png?1702986206',
      featured202304PhotoSpot:
        '/yado/onsen/assets/images/featured/202304/photo_spot.png?1702986206',
      featured202304FireworksOnsen:
        '/yado/onsen/assets/images/featured/202304/fireworks_onsen.png?1702986206',
      featured202304NewListing:
        '/yado/onsen/assets/images/featured/202304/new_listing.png?1702986206',
      featured202304LocalFood:
        '/yado/onsen/assets/images/featured/202304/local_food.png?1702986206',
      featured202303JapanOnsen100:
        '/yado/onsen/assets/images/featured/202303/japan_onsen_100.jpg?1702986206',
      featured202303Private201910:
        '/yado/onsen/assets/images/featured/202303/private201910.jpg?1702986206',
      featured202303CostPerformance:
        '/yado/onsen/assets/images/featured/202303/cost_performance.jpg?1702986206',
      featured202303SakuraOnsen:
        '/yado/onsen/assets/images/featured/202303/sakura_onsen.jpg?1702986206',
      featured202303ChildOnsen:
        '/yado/onsen/assets/images/featured/202303/child_onsen.jpg?1702986206',
      featured202305RelaxOnsen:
        '/yado/onsen/assets/images/featured/202305/relax_onsen.jpg?1702986206',
      featured202305PoolOnsen:
        '/yado/onsen/assets/images/featured/202305/pool_onsen.jpg?1702986206',
      featured202305Themepark: '/yado/onsen/assets/images/featured/202305/themepark.jpg?1702986206',
      featured202305JapanFestivals:
        '/yado/onsen/assets/images/featured/202305/japan_festivals.jpg?1702986206',
      featured202312NewListing2023dec:
        '/yado/onsen/assets/images/featured/202312/NEW_listing_2023Dec.jpg?1703567287',
      featuredFacebook: '/yado/onsen/assets/images/featured/facebook.svg?1705570164',
      featuredLine: '/yado/onsen/assets/images/featured/line.svg?1705570164',
      featured202401NewListing2024jan:
        '/yado/onsen/assets/images/featured/202401/NEW_listing_2024Jan.jpg?1708579723',
      featuredLimited202404LimitedPlan:
        '/yado/onsen/assets/images/featured/limited202404/limited_plan.jpg?1712306405',
      featuredTwitter: '/yado/onsen/assets/images/featured/twitter.svg?1705570164',
      featuredKani202312Crab: '/yado/onsen/assets/images/featured/kani202312/crab.jpg?1704944546',
      featuredKani202312BannerCrab:
        '/yado/onsen/assets/images/featured/kani202312/banner_crab.jpg?1704944546',
      featured202309NewListing2023julysep:
        '/yado/onsen/assets/images/featured/202309/NEW_listing_2023JulySep.jpg?1702986206',
      featured202309SnowOnsen:
        '/yado/onsen/assets/images/featured/202309/snow_onsen.jpg?1702986206',
      featured202306AutumnleavesSpot:
        '/yado/onsen/assets/images/featured/202306/autumnleaves_spot.jpg?1702986206',
      featured202306NewListing:
        '/yado/onsen/assets/images/featured/202306/new_listing.jpg?1702986206',
    },
    stylesheets: {
      bootstrapBootstrapGrid:
        '/yado/onsen/assets/stylesheets/bootstrap/bootstrap-grid.min.css?1702986206',
      bootstrapBootstrapReboot:
        '/yado/onsen/assets/stylesheets/bootstrap/bootstrap-reboot.min.css?1702986206',
      reactIntlTelInputMain:
        '/yado/onsen/assets/stylesheets/react-intl-tel-input/main.css?1702986206',
      reactIntlTelInputFlags:
        '/yado/onsen/assets/stylesheets/react-intl-tel-input/flags.png?1702986206',
    },
  },
  ja: {
    images: {
      onsenYadoRibbonsDinnerRibbonRecommended2x:
        '/yado/onsen/assets/images/onsen_yado/ribbons/dinner_ribbon_recommended_2x.ja.png?1702986206',
      onsenYadoRibbonsDinnerRibbonStandard:
        '/yado/onsen/assets/images/onsen_yado/ribbons/dinner_ribbon_standard.ja.png?1702986206',
      onsenYadoRibbonsDinnerRibbonEarlyBird2x:
        '/yado/onsen/assets/images/onsen_yado/ribbons/dinner_ribbon_early_bird_2x.ja.png?1702986206',
      onsenYadoRibbonsDinnerRibbonStandard2x:
        '/yado/onsen/assets/images/onsen_yado/ribbons/dinner_ribbon_standard_2x.ja.png?1702986206',
      onsenYadoRibbonsDinnerRibbonRecommended:
        '/yado/onsen/assets/images/onsen_yado/ribbons/dinner_ribbon_recommended.ja.png?1702986206',
      onsenYadoRibbonsDinnerRibbonEarlyBird:
        '/yado/onsen/assets/images/onsen_yado/ribbons/dinner_ribbon_early_bird.ja.png?1702986206',
      onsenYadoCrossSellingInsuranceImportantMattersSompo1:
        '/yado/onsen/assets/images/onsen_yado/cross_selling/insurance/important_matters_sompo_1.ja.jpg?1702986206',
      onsenYadoCrossSellingInsuranceImportantMattersSompo2:
        '/yado/onsen/assets/images/onsen_yado/cross_selling/insurance/important_matters_sompo_2.ja.jpg?1702986206',
      onsenYadoPromo202003KeyVisualLabelPc:
        '/yado/onsen/assets/images/onsen_yado/promo/202003/key_visual_label_pc.ja.png?1702986206',
      onsenYadoPromo202003KeyVisualLabelSp:
        '/yado/onsen/assets/images/onsen_yado/promo/202003/key_visual_label_sp.ja.png?1702986206',
      commonFlag: '/yado/onsen/assets/images/common/flag.ja.png?1702986206',
    },
  },
  zh_cn: {
    images: {
      onsenYadoRibbonsDinnerRibbonStandard2x:
        '/yado/onsen/assets/images/onsen_yado/ribbons/dinner_ribbon_standard_2x.zh_cn.png?1702986206',
      onsenYadoRibbonsDinnerRibbonStandard:
        '/yado/onsen/assets/images/onsen_yado/ribbons/dinner_ribbon_standard.zh_cn.png?1702986206',
      onsenYadoRibbonsDinnerRibbonEarlyBird2x:
        '/yado/onsen/assets/images/onsen_yado/ribbons/dinner_ribbon_early_bird_2x.zh_cn.png?1702986206',
      onsenYadoRibbonsDinnerRibbonRecommended2x:
        '/yado/onsen/assets/images/onsen_yado/ribbons/dinner_ribbon_recommended_2x.zh_cn.png?1702986206',
      onsenYadoRibbonsDinnerRibbonRecommended:
        '/yado/onsen/assets/images/onsen_yado/ribbons/dinner_ribbon_recommended.zh_cn.png?1702986206',
      onsenYadoRibbonsDinnerRibbonEarlyBird:
        '/yado/onsen/assets/images/onsen_yado/ribbons/dinner_ribbon_early_bird.zh_cn.png?1702986206',
      onsenYadoLpOg: '/yado/onsen/assets/images/onsen_yado/lp/og.zh_cn.jpg?1702986206',
      onsenYadoCrossSellingInsuranceImportantMattersSompo2:
        '/yado/onsen/assets/images/onsen_yado/cross_selling/insurance/important_matters_sompo_2.zh_cn.jpg?1702986206',
      onsenYadoCrossSellingInsuranceImportantMattersSompo1:
        '/yado/onsen/assets/images/onsen_yado/cross_selling/insurance/important_matters_sompo_1.zh_cn.jpg?1702986206',
      onsenYadoPromo202003KeyVisualLabelSp:
        '/yado/onsen/assets/images/onsen_yado/promo/202003/key_visual_label_sp.zh_cn.png?1702986206',
      onsenYadoPromo202003KeyVisualLabelPc:
        '/yado/onsen/assets/images/onsen_yado/promo/202003/key_visual_label_pc.zh_cn.png?1702986206',
      newTopGallerySnowOnsenHorizontal:
        '/yado/onsen/assets/images/new_top/gallery/snow_onsen_horizontal.zh_cn.jpg?1702986206',
      newTopGalleryPrivateBathHorizontal:
        '/yado/onsen/assets/images/new_top/gallery/private_bath_horizontal.zh_cn.jpg?1702986206',
      newTopGalleryKusatsu:
        '/yado/onsen/assets/images/new_top/gallery/kusatsu.zh_cn.jpg?1713761370',
      newTopGalleryKisetsuBannerHorizontal:
        '/yado/onsen/assets/images/new_top/gallery/kisetsu_banner_horizontal.zh_cn.png?1722305990',
      newTopGalleryLimitedPlan202404:
        '/yado/onsen/assets/images/new_top/gallery/limited_plan_202404.zh_cn.jpg?1712306405',
      newTopGalleryYufuin: '/yado/onsen/assets/images/new_top/gallery/yufuin.zh_cn.jpg?1713761370',
      aboutStepsOnsenHowTo2Pc:
        '/yado/onsen/assets/images/about/steps/onsen-how-to2-pc.zh_cn.jpg?1702986206',
      aboutStepsOnsenHowTo1Sp:
        '/yado/onsen/assets/images/about/steps/onsen-how-to1-sp.zh_cn.jpg?1702986206',
      aboutStepsPickupHowTo13Pc:
        '/yado/onsen/assets/images/about/steps/pickup-how-to1-3-pc.zh_cn.jpg?1702986206',
      aboutStepsPickupHowTo12Pc:
        '/yado/onsen/assets/images/about/steps/pickup-how-to1-2-pc.zh_cn.jpg?1702986206',
      aboutStepsPickupHowTo11Sp:
        '/yado/onsen/assets/images/about/steps/pickup-how-to1-1-sp.zh_cn.jpg?1702986206',
      aboutStepsMealHowTo2Pc:
        '/yado/onsen/assets/images/about/steps/meal-how-to2-pc.zh_cn.jpg?1702986206',
      aboutStepsMealHowTo1Sp:
        '/yado/onsen/assets/images/about/steps/meal-how-to1-sp.zh_cn.jpg?1702986206',
      aboutStepsMealRequestAnser2Pc:
        '/yado/onsen/assets/images/about/steps/meal-request-anser2-pc.zh_cn.jpg?1702986206',
      aboutStepsMealRequestAnser1Sp:
        '/yado/onsen/assets/images/about/steps/meal-request-anser1-sp.zh_cn.jpg?1702986206',
      aboutStepsPickupHowTo32Pc:
        '/yado/onsen/assets/images/about/steps/pickup-how-to3-2-pc.zh_cn.jpg?1702986206',
      aboutStepsPickupHowTo31Sp:
        '/yado/onsen/assets/images/about/steps/pickup-how-to3-1-sp.zh_cn.jpg?1702986206',
      aboutStepsPickupRequestAnser3Sp:
        '/yado/onsen/assets/images/about/steps/pickup-request-anser3-sp.zh_cn.jpg?1702986206',
      aboutStepsPickupHowTo23Pc:
        '/yado/onsen/assets/images/about/steps/pickup-how-to2-3-pc.zh_cn.jpg?1702986206',
      aboutStepsPickupRequestAnser1Pc:
        '/yado/onsen/assets/images/about/steps/pickup-request-anser1-pc.zh_cn.jpg?1702986206',
      aboutStepsPickupRequestAnser2Sp:
        '/yado/onsen/assets/images/about/steps/pickup-request-anser2-sp.zh_cn.jpg?1702986206',
      aboutStepsPickupHowTo33Pc:
        '/yado/onsen/assets/images/about/steps/pickup-how-to3-3-pc.zh_cn.jpg?1702986206',
      aboutStepsOnsenRequestAnser1Pc:
        '/yado/onsen/assets/images/about/steps/onsen-request-anser1-pc.zh_cn.jpg?1702986206',
      aboutStepsOnsenRequestAnser2Sp:
        '/yado/onsen/assets/images/about/steps/onsen-request-anser2-sp.zh_cn.jpg?1702986206',
      aboutStepsPickupHowTo21Sp:
        '/yado/onsen/assets/images/about/steps/pickup-how-to2-1-sp.zh_cn.jpg?1702986206',
      aboutStepsPickupHowTo22Pc:
        '/yado/onsen/assets/images/about/steps/pickup-how-to2-2-pc.zh_cn.jpg?1702986206',
      aboutStepsPickupRequestAnser3Pc:
        '/yado/onsen/assets/images/about/steps/pickup-request-anser3-pc.zh_cn.jpg?1702986206',
      aboutStepsMealRequestAnser1Pc:
        '/yado/onsen/assets/images/about/steps/meal-request-anser1-pc.zh_cn.jpg?1702986206',
      aboutStepsMealRequestAnser2Sp:
        '/yado/onsen/assets/images/about/steps/meal-request-anser2-sp.zh_cn.jpg?1702986206',
      aboutStepsPickupHowTo31Pc:
        '/yado/onsen/assets/images/about/steps/pickup-how-to3-1-pc.zh_cn.jpg?1702986206',
      aboutStepsPickupHowTo32Sp:
        '/yado/onsen/assets/images/about/steps/pickup-how-to3-2-sp.zh_cn.jpg?1702986206',
      aboutStepsPickupHowTo23Sp:
        '/yado/onsen/assets/images/about/steps/pickup-how-to2-3-sp.zh_cn.jpg?1702986206',
      aboutStepsPickupHowTo33Sp:
        '/yado/onsen/assets/images/about/steps/pickup-how-to3-3-sp.zh_cn.jpg?1702986206',
      aboutStepsPickupRequestAnser2Pc:
        '/yado/onsen/assets/images/about/steps/pickup-request-anser2-pc.zh_cn.jpg?1702986206',
      aboutStepsPickupRequestAnser1Sp:
        '/yado/onsen/assets/images/about/steps/pickup-request-anser1-sp.zh_cn.jpg?1702986206',
      aboutStepsPickupHowTo22Sp:
        '/yado/onsen/assets/images/about/steps/pickup-how-to2-2-sp.zh_cn.jpg?1702986206',
      aboutStepsPickupHowTo21Pc:
        '/yado/onsen/assets/images/about/steps/pickup-how-to2-1-pc.zh_cn.jpg?1702986206',
      aboutStepsOnsenRequestAnser2Pc:
        '/yado/onsen/assets/images/about/steps/onsen-request-anser2-pc.zh_cn.jpg?1702986206',
      aboutStepsOnsenRequestAnser1Sp:
        '/yado/onsen/assets/images/about/steps/onsen-request-anser1-sp.zh_cn.jpg?1702986206',
      aboutStepsPickupHowTo13Sp:
        '/yado/onsen/assets/images/about/steps/pickup-how-to1-3-sp.zh_cn.jpg?1702986206',
      aboutStepsOnsenHowTo1Pc:
        '/yado/onsen/assets/images/about/steps/onsen-how-to1-pc.zh_cn.jpg?1702986206',
      aboutStepsOnsenHowTo2Sp:
        '/yado/onsen/assets/images/about/steps/onsen-how-to2-sp.zh_cn.jpg?1702986206',
      aboutStepsPickupHowTo11Pc:
        '/yado/onsen/assets/images/about/steps/pickup-how-to1-1-pc.zh_cn.jpg?1702986206',
      aboutStepsPickupHowTo12Sp:
        '/yado/onsen/assets/images/about/steps/pickup-how-to1-2-sp.zh_cn.jpg?1702986206',
      aboutStepsMealHowTo1Pc:
        '/yado/onsen/assets/images/about/steps/meal-how-to1-pc.zh_cn.jpg?1702986206',
      aboutStepsMealHowTo2Sp:
        '/yado/onsen/assets/images/about/steps/meal-how-to2-sp.zh_cn.jpg?1702986206',
      commonLogoWamazingHorizontal:
        '/yado/onsen/assets/images/common/logo_wamazing_horizontal.zh_cn.svg?1702986206',
      commonLogoWamazingVertical:
        '/yado/onsen/assets/images/common/logo_wamazing_vertical.zh_cn.svg?1702986206',
      commonFlag: '/yado/onsen/assets/images/common/flag.zh_cn.png?1702986206',
      commonLogoWamazingHorizontalWhite:
        '/yado/onsen/assets/images/common/logo_wamazing_horizontal_white.zh_cn.svg?1702986206',
      countryTopLogoHorizontalWhite:
        '/yado/onsen/assets/images/country_top/logo_horizontal_white.zh_cn.svg?1702986206',
      countryTopLogoHorizontalPink:
        '/yado/onsen/assets/images/country_top/logo_horizontal_pink.zh_cn.svg?1702986206',
      featuredLimited202312LimitedPlan:
        '/yado/onsen/assets/images/featured/limited202312/limited_plan.zh_cn.jpg?1702986206',
      featured202311Kyoritsu:
        '/yado/onsen/assets/images/featured/202311/kyoritsu.zh_cn.jpg?1702986206',
      featured202311NewListing2023nov:
        '/yado/onsen/assets/images/featured/202311/NEW_listing_2023Nov.zh_cn.jpg?1702986206',
      featured202402NewListing2024feb:
        '/yado/onsen/assets/images/featured/202402/NEW_listing_2024Feb.zh_cn.jpg?1710821062',
      featured202403NewListing2024mar:
        '/yado/onsen/assets/images/featured/202403/NEW_listing_2024Mar.zh_cn.jpg?1712306405',
      featured202304FireworksOnsen:
        '/yado/onsen/assets/images/featured/202304/fireworks_onsen.zh_cn.png?1702986206',
      featured202304LocalFood:
        '/yado/onsen/assets/images/featured/202304/local_food.zh_cn.png?1702986206',
      featured202304PhotoSpot:
        '/yado/onsen/assets/images/featured/202304/photo_spot.zh_cn.png?1702986206',
      featured202304WagyuOnsen:
        '/yado/onsen/assets/images/featured/202304/wagyu_onsen.zh_cn.png?1702986206',
      featured202304NewListing:
        '/yado/onsen/assets/images/featured/202304/new_listing.zh_cn.png?1702986206',
      featured202303JapanOnsen100:
        '/yado/onsen/assets/images/featured/202303/japan_onsen_100.zh_cn.jpg?1702986206',
      featured202303ChildOnsen:
        '/yado/onsen/assets/images/featured/202303/child_onsen.zh_cn.jpg?1702986206',
      featured202303SakuraOnsen:
        '/yado/onsen/assets/images/featured/202303/sakura_onsen.zh_cn.jpg?1702986206',
      featured202303CostPerformance:
        '/yado/onsen/assets/images/featured/202303/cost_performance.zh_cn.jpg?1702986206',
      featured202303Private201910:
        '/yado/onsen/assets/images/featured/202303/private201910.zh_cn.jpg?1702986206',
      featured202305PoolOnsen:
        '/yado/onsen/assets/images/featured/202305/pool_onsen.zh_cn.jpg?1702986206',
      featured202305RelaxOnsen:
        '/yado/onsen/assets/images/featured/202305/relax_onsen.zh_cn.jpg?1702986206',
      featured202305Themepark:
        '/yado/onsen/assets/images/featured/202305/themepark.zh_cn.jpg?1702986206',
      featured202305JapanFestivals:
        '/yado/onsen/assets/images/featured/202305/japan_festivals.zh_cn.jpg?1702986206',
      featured202312NewListing2023dec:
        '/yado/onsen/assets/images/featured/202312/NEW_listing_2023Dec.zh_cn.jpg?1703567287',
      featured202401NewListing2024jan:
        '/yado/onsen/assets/images/featured/202401/NEW_listing_2024Jan.zh_cn.jpg?1708579723',
      featuredLimited202404LimitedPlan:
        '/yado/onsen/assets/images/featured/limited202404/limited_plan.zh_cn.jpg?1712306405',
      featuredKani202312BannerCrab:
        '/yado/onsen/assets/images/featured/kani202312/banner_crab.zh_cn.jpg?1704944546',
      featuredKani202312Crab:
        '/yado/onsen/assets/images/featured/kani202312/crab.zh_cn.jpg?1704944546',
      featured202309SnowOnsen:
        '/yado/onsen/assets/images/featured/202309/snow_onsen.zh_cn.jpg?1702986206',
      featured202309NewListing2023julysep:
        '/yado/onsen/assets/images/featured/202309/NEW_listing_2023JulySep.zh_cn.jpg?1702986206',
      featured202306AutumnleavesSpot:
        '/yado/onsen/assets/images/featured/202306/autumnleaves_spot.zh_cn.jpg?1702986206',
      featured202306NewListing:
        '/yado/onsen/assets/images/featured/202306/new_listing.zh_cn.jpg?1702986206',
    },
  },
  zh_tw: {
    images: {
      onsenYadoRibbonsDinnerRibbonStandard:
        '/yado/onsen/assets/images/onsen_yado/ribbons/dinner_ribbon_standard.zh_tw.png?1702986206',
      onsenYadoRibbonsDinnerRibbonStandard2x:
        '/yado/onsen/assets/images/onsen_yado/ribbons/dinner_ribbon_standard_2x.zh_tw.png?1702986206',
      onsenYadoRibbonsDinnerRibbonRecommended2x:
        '/yado/onsen/assets/images/onsen_yado/ribbons/dinner_ribbon_recommended_2x.zh_tw.png?1702986206',
      onsenYadoRibbonsDinnerRibbonEarlyBird2x:
        '/yado/onsen/assets/images/onsen_yado/ribbons/dinner_ribbon_early_bird_2x.zh_tw.png?1702986206',
      onsenYadoRibbonsDinnerRibbonRecommended:
        '/yado/onsen/assets/images/onsen_yado/ribbons/dinner_ribbon_recommended.zh_tw.png?1702986206',
      onsenYadoRibbonsDinnerRibbonEarlyBird:
        '/yado/onsen/assets/images/onsen_yado/ribbons/dinner_ribbon_early_bird.zh_tw.png?1702986206',
      onsenYadoCrossSellingInsuranceImportantMattersSompo2:
        '/yado/onsen/assets/images/onsen_yado/cross_selling/insurance/important_matters_sompo_2.zh_tw.jpg?1702986206',
      onsenYadoCrossSellingInsuranceImportantMattersSompo1:
        '/yado/onsen/assets/images/onsen_yado/cross_selling/insurance/important_matters_sompo_1.zh_tw.jpg?1702986206',
      onsenYadoPromo202003KeyVisualLabelSp:
        '/yado/onsen/assets/images/onsen_yado/promo/202003/key_visual_label_sp.zh_tw.png?1702986206',
      onsenYadoPromo202003KeyVisualLabelPc:
        '/yado/onsen/assets/images/onsen_yado/promo/202003/key_visual_label_pc.zh_tw.png?1702986206',
      newTopGalleryPrivateBathHorizontal:
        '/yado/onsen/assets/images/new_top/gallery/private_bath_horizontal.zh_tw.jpg?1702986206',
      newTopGallerySnowOnsenHorizontal:
        '/yado/onsen/assets/images/new_top/gallery/snow_onsen_horizontal.zh_tw.jpg?1702986206',
      newTopGalleryKisetsuBannerHorizontal:
        '/yado/onsen/assets/images/new_top/gallery/kisetsu_banner_horizontal.zh_tw.png?1722306597',
      newTopGalleryKusatsu:
        '/yado/onsen/assets/images/new_top/gallery/kusatsu.zh_tw.jpg?1713761370',
      newTopGalleryLimitedPlan202404:
        '/yado/onsen/assets/images/new_top/gallery/limited_plan_202404.zh_tw.jpg?1712306405',
      newTopGalleryYufuin: '/yado/onsen/assets/images/new_top/gallery/yufuin.zh_tw.jpg?1713761370',
      aboutStepsPickupHowTo12Pc:
        '/yado/onsen/assets/images/about/steps/pickup-how-to1-2-pc.zh_tw.jpg?1702986206',
      aboutStepsPickupHowTo11Sp:
        '/yado/onsen/assets/images/about/steps/pickup-how-to1-1-sp.zh_tw.jpg?1702986206',
      aboutStepsMealHowTo2Pc:
        '/yado/onsen/assets/images/about/steps/meal-how-to2-pc.zh_tw.jpg?1702986206',
      aboutStepsMealHowTo1Sp:
        '/yado/onsen/assets/images/about/steps/meal-how-to1-sp.zh_tw.jpg?1702986206',
      aboutStepsPickupHowTo13Pc:
        '/yado/onsen/assets/images/about/steps/pickup-how-to1-3-pc.zh_tw.jpg?1702986206',
      aboutStepsOnsenHowTo2Pc:
        '/yado/onsen/assets/images/about/steps/onsen-how-to2-pc.zh_tw.jpg?1702986206',
      aboutStepsOnsenHowTo1Sp:
        '/yado/onsen/assets/images/about/steps/onsen-how-to1-sp.zh_tw.jpg?1702986206',
      aboutStepsPickupHowTo33Pc:
        '/yado/onsen/assets/images/about/steps/pickup-how-to3-3-pc.zh_tw.jpg?1702986206',
      aboutStepsPickupRequestAnser1Pc:
        '/yado/onsen/assets/images/about/steps/pickup-request-anser1-pc.zh_tw.jpg?1702986206',
      aboutStepsPickupRequestAnser2Sp:
        '/yado/onsen/assets/images/about/steps/pickup-request-anser2-sp.zh_tw.jpg?1702986206',
      aboutStepsPickupHowTo21Sp:
        '/yado/onsen/assets/images/about/steps/pickup-how-to2-1-sp.zh_tw.jpg?1702986206',
      aboutStepsPickupHowTo22Pc:
        '/yado/onsen/assets/images/about/steps/pickup-how-to2-2-pc.zh_tw.jpg?1702986206',
      aboutStepsOnsenRequestAnser1Pc:
        '/yado/onsen/assets/images/about/steps/onsen-request-anser1-pc.zh_tw.jpg?1702986206',
      aboutStepsOnsenRequestAnser2Sp:
        '/yado/onsen/assets/images/about/steps/onsen-request-anser2-sp.zh_tw.jpg?1702986206',
      aboutStepsPickupRequestAnser3Sp:
        '/yado/onsen/assets/images/about/steps/pickup-request-anser3-sp.zh_tw.jpg?1702986206',
      aboutStepsMealRequestAnser2Pc:
        '/yado/onsen/assets/images/about/steps/meal-request-anser2-pc.zh_tw.jpg?1702986206',
      aboutStepsMealRequestAnser1Sp:
        '/yado/onsen/assets/images/about/steps/meal-request-anser1-sp.zh_tw.jpg?1702986206',
      aboutStepsPickupHowTo32Pc:
        '/yado/onsen/assets/images/about/steps/pickup-how-to3-2-pc.zh_tw.jpg?1702986206',
      aboutStepsPickupHowTo31Sp:
        '/yado/onsen/assets/images/about/steps/pickup-how-to3-1-sp.zh_tw.jpg?1702986206',
      aboutStepsPickupHowTo23Pc:
        '/yado/onsen/assets/images/about/steps/pickup-how-to2-3-pc.zh_tw.jpg?1702986206',
      aboutStepsPickupRequestAnser2Pc:
        '/yado/onsen/assets/images/about/steps/pickup-request-anser2-pc.zh_tw.jpg?1702986206',
      aboutStepsPickupRequestAnser1Sp:
        '/yado/onsen/assets/images/about/steps/pickup-request-anser1-sp.zh_tw.jpg?1702986206',
      aboutStepsPickupHowTo33Sp:
        '/yado/onsen/assets/images/about/steps/pickup-how-to3-3-sp.zh_tw.jpg?1702986206',
      aboutStepsOnsenRequestAnser2Pc:
        '/yado/onsen/assets/images/about/steps/onsen-request-anser2-pc.zh_tw.jpg?1702986206',
      aboutStepsOnsenRequestAnser1Sp:
        '/yado/onsen/assets/images/about/steps/onsen-request-anser1-sp.zh_tw.jpg?1702986206',
      aboutStepsPickupHowTo22Sp:
        '/yado/onsen/assets/images/about/steps/pickup-how-to2-2-sp.zh_tw.jpg?1702986206',
      aboutStepsPickupHowTo21Pc:
        '/yado/onsen/assets/images/about/steps/pickup-how-to2-1-pc.zh_tw.jpg?1702986206',
      aboutStepsMealRequestAnser1Pc:
        '/yado/onsen/assets/images/about/steps/meal-request-anser1-pc.zh_tw.jpg?1702986206',
      aboutStepsMealRequestAnser2Sp:
        '/yado/onsen/assets/images/about/steps/meal-request-anser2-sp.zh_tw.jpg?1702986206',
      aboutStepsPickupHowTo31Pc:
        '/yado/onsen/assets/images/about/steps/pickup-how-to3-1-pc.zh_tw.jpg?1702986206',
      aboutStepsPickupHowTo32Sp:
        '/yado/onsen/assets/images/about/steps/pickup-how-to3-2-sp.zh_tw.jpg?1702986206',
      aboutStepsPickupRequestAnser3Pc:
        '/yado/onsen/assets/images/about/steps/pickup-request-anser3-pc.zh_tw.jpg?1702986206',
      aboutStepsPickupHowTo23Sp:
        '/yado/onsen/assets/images/about/steps/pickup-how-to2-3-sp.zh_tw.jpg?1702986206',
      aboutStepsPickupHowTo11Pc:
        '/yado/onsen/assets/images/about/steps/pickup-how-to1-1-pc.zh_tw.jpg?1702986206',
      aboutStepsPickupHowTo12Sp:
        '/yado/onsen/assets/images/about/steps/pickup-how-to1-2-sp.zh_tw.jpg?1702986206',
      aboutStepsMealHowTo1Pc:
        '/yado/onsen/assets/images/about/steps/meal-how-to1-pc.zh_tw.jpg?1702986206',
      aboutStepsMealHowTo2Sp:
        '/yado/onsen/assets/images/about/steps/meal-how-to2-sp.zh_tw.jpg?1702986206',
      aboutStepsOnsenHowTo1Pc:
        '/yado/onsen/assets/images/about/steps/onsen-how-to1-pc.zh_tw.jpg?1702986206',
      aboutStepsOnsenHowTo2Sp:
        '/yado/onsen/assets/images/about/steps/onsen-how-to2-sp.zh_tw.jpg?1702986206',
      aboutStepsPickupHowTo13Sp:
        '/yado/onsen/assets/images/about/steps/pickup-how-to1-3-sp.zh_tw.jpg?1702986206',
      commonAppStoreStoreApple:
        '/yado/onsen/assets/images/common/app_store/store_apple.zh_tw.png?1702986206',
      commonAppStoreStoreAndroid:
        '/yado/onsen/assets/images/common/app_store/store_android.zh_tw.png?1702986206',
      commonFlag: '/yado/onsen/assets/images/common/flag.zh_tw.png?1702986206',
      campaignActivityBannerHorizontal:
        '/yado/onsen/assets/images/campaign/activity/banner_horizontal.zh_tw.png?1709702960',
      campaignNissanBannerHorizontal:
        '/yado/onsen/assets/images/campaign/nissan/banner_horizontal.zh_tw.png?1708579723',
      featuredLimited202312LimitedPlan:
        '/yado/onsen/assets/images/featured/limited202312/limited_plan.zh_tw.jpg?1702986206',
      featured202311NewListing2023nov:
        '/yado/onsen/assets/images/featured/202311/NEW_listing_2023Nov.zh_tw.jpg?1702986206',
      featured202311Kyoritsu:
        '/yado/onsen/assets/images/featured/202311/kyoritsu.zh_tw.jpg?1702986206',
      featured202402NewListing2024feb:
        '/yado/onsen/assets/images/featured/202402/NEW_listing_2024Feb.zh_tw.jpg?1710821062',
      featured202403NewListing2024mar:
        '/yado/onsen/assets/images/featured/202403/NEW_listing_2024Mar.zh_tw.jpg?1712306405',
      featured202304LocalFood:
        '/yado/onsen/assets/images/featured/202304/local_food.zh_tw.png?1702986206',
      featured202304PhotoSpot:
        '/yado/onsen/assets/images/featured/202304/photo_spot.zh_tw.png?1702986206',
      featured202304FireworksOnsen:
        '/yado/onsen/assets/images/featured/202304/fireworks_onsen.zh_tw.png?1702986206',
      featured202304WagyuOnsen:
        '/yado/onsen/assets/images/featured/202304/wagyu_onsen.zh_tw.png?1702986206',
      featured202304NewListing:
        '/yado/onsen/assets/images/featured/202304/new_listing.zh_tw.png?1702986206',
      featured202303JapanOnsen100:
        '/yado/onsen/assets/images/featured/202303/japan_onsen_100.zh_tw.jpg?1702986206',
      featured202303SakuraOnsen:
        '/yado/onsen/assets/images/featured/202303/sakura_onsen.zh_tw.jpg?1702986206',
      featured202303ChildOnsen:
        '/yado/onsen/assets/images/featured/202303/child_onsen.zh_tw.jpg?1702986206',
      featured202303CostPerformance:
        '/yado/onsen/assets/images/featured/202303/cost_performance.zh_tw.jpg?1702986206',
      featured202303Private201910:
        '/yado/onsen/assets/images/featured/202303/private201910.zh_tw.jpg?1702986206',
      featured202305PoolOnsen:
        '/yado/onsen/assets/images/featured/202305/pool_onsen.zh_tw.jpg?1702986206',
      featured202305RelaxOnsen:
        '/yado/onsen/assets/images/featured/202305/relax_onsen.zh_tw.jpg?1702986206',
      featured202305Themepark:
        '/yado/onsen/assets/images/featured/202305/themepark.zh_tw.jpg?1702986206',
      featured202305JapanFestivals:
        '/yado/onsen/assets/images/featured/202305/japan_festivals.zh_tw.jpg?1702986206',
      featured202312NewListing2023dec:
        '/yado/onsen/assets/images/featured/202312/NEW_listing_2023Dec.zh_tw.jpg?1703567287',
      featured202401NewListing2024jan:
        '/yado/onsen/assets/images/featured/202401/NEW_listing_2024Jan.zh_tw.jpg?1708579723',
      featuredLimited202404LimitedPlan:
        '/yado/onsen/assets/images/featured/limited202404/limited_plan.zh_tw.jpg?1712306405',
      featuredKani202312BannerCrab:
        '/yado/onsen/assets/images/featured/kani202312/banner_crab.zh_tw.jpg?1704944546',
      featuredKani202312Crab:
        '/yado/onsen/assets/images/featured/kani202312/crab.zh_tw.jpg?1704944546',
      featured202309SnowOnsen:
        '/yado/onsen/assets/images/featured/202309/snow_onsen.zh_tw.jpg?1702986206',
      featured202309NewListing2023julysep:
        '/yado/onsen/assets/images/featured/202309/NEW_listing_2023JulySep.zh_tw.jpg?1702986206',
      featured202306AutumnleavesSpot:
        '/yado/onsen/assets/images/featured/202306/autumnleaves_spot.zh_tw.jpg?1702986206',
      featured202306NewListing:
        '/yado/onsen/assets/images/featured/202306/new_listing.zh_tw.jpg?1702986206',
    },
  },
  zh_hk: {
    images: {
      onsenYadoRibbonsDinnerRibbonStandard2x:
        '/yado/onsen/assets/images/onsen_yado/ribbons/dinner_ribbon_standard_2x.zh_hk.png?1702986206',
      onsenYadoRibbonsDinnerRibbonStandard:
        '/yado/onsen/assets/images/onsen_yado/ribbons/dinner_ribbon_standard.zh_hk.png?1702986206',
      onsenYadoRibbonsDinnerRibbonRecommended:
        '/yado/onsen/assets/images/onsen_yado/ribbons/dinner_ribbon_recommended.zh_hk.png?1702986206',
      onsenYadoRibbonsDinnerRibbonEarlyBird:
        '/yado/onsen/assets/images/onsen_yado/ribbons/dinner_ribbon_early_bird.zh_hk.png?1702986206',
      onsenYadoRibbonsDinnerRibbonEarlyBird2x:
        '/yado/onsen/assets/images/onsen_yado/ribbons/dinner_ribbon_early_bird_2x.zh_hk.png?1702986206',
      onsenYadoRibbonsDinnerRibbonRecommended2x:
        '/yado/onsen/assets/images/onsen_yado/ribbons/dinner_ribbon_recommended_2x.zh_hk.png?1702986206',
      onsenYadoCrossSellingInsuranceImportantMattersSompo2:
        '/yado/onsen/assets/images/onsen_yado/cross_selling/insurance/important_matters_sompo_2.zh_hk.jpg?1702986206',
      onsenYadoCrossSellingInsuranceImportantMattersSompo1:
        '/yado/onsen/assets/images/onsen_yado/cross_selling/insurance/important_matters_sompo_1.zh_hk.jpg?1702986206',
      onsenYadoPromo202003KeyVisualLabelSp:
        '/yado/onsen/assets/images/onsen_yado/promo/202003/key_visual_label_sp.zh_hk.png?1702986206',
      onsenYadoPromo202003KeyVisualLabelPc:
        '/yado/onsen/assets/images/onsen_yado/promo/202003/key_visual_label_pc.zh_hk.png?1702986206',
      newTopGalleryKisetsuBannerHorizontal:
        '/yado/onsen/assets/images/new_top/gallery/kisetsu_banner_horizontal.zh_hk.png?1722306597',
      newTopGalleryKusatsu:
        '/yado/onsen/assets/images/new_top/gallery/kusatsu.zh_hk.jpg?1713761370',
      newTopGallerySnowOnsenHorizontal:
        '/yado/onsen/assets/images/new_top/gallery/snow_onsen_horizontal.zh_hk.jpg?1702986206',
      newTopGalleryPrivateBathHorizontal:
        '/yado/onsen/assets/images/new_top/gallery/private_bath_horizontal.zh_hk.jpg?1702986206',
      newTopGalleryYufuin: '/yado/onsen/assets/images/new_top/gallery/yufuin.zh_hk.jpg?1713761370',
      newTopGalleryLimitedPlan202404:
        '/yado/onsen/assets/images/new_top/gallery/limited_plan_202404.zh_hk.jpg?1712306405',
      aboutStepsPickupRequestAnser3Sp:
        '/yado/onsen/assets/images/about/steps/pickup-request-anser3-sp.zh_hk.jpg?1702986206',
      aboutStepsMealRequestAnser1Sp:
        '/yado/onsen/assets/images/about/steps/meal-request-anser1-sp.zh_hk.jpg?1702986206',
      aboutStepsPickupHowTo31Sp:
        '/yado/onsen/assets/images/about/steps/pickup-how-to3-1-sp.zh_hk.jpg?1702986206',
      aboutStepsPickupHowTo32Pc:
        '/yado/onsen/assets/images/about/steps/pickup-how-to3-2-pc.zh_hk.jpg?1702986206',
      aboutStepsPickupHowTo23Pc:
        '/yado/onsen/assets/images/about/steps/pickup-how-to2-3-pc.zh_hk.jpg?1702986206',
      aboutStepsPickupHowTo33Pc:
        '/yado/onsen/assets/images/about/steps/pickup-how-to3-3-pc.zh_hk.jpg?1702986206',
      aboutStepsPickupRequestAnser2Sp:
        '/yado/onsen/assets/images/about/steps/pickup-request-anser2-sp.zh_hk.jpg?1702986206',
      aboutStepsPickupRequestAnser1Pc:
        '/yado/onsen/assets/images/about/steps/pickup-request-anser1-pc.zh_hk.jpg?1702986206',
      aboutStepsPickupHowTo22Pc:
        '/yado/onsen/assets/images/about/steps/pickup-how-to2-2-pc.zh_hk.jpg?1702986206',
      aboutStepsPickupHowTo21Sp:
        '/yado/onsen/assets/images/about/steps/pickup-how-to2-1-sp.zh_hk.jpg?1702986206',
      aboutStepsOnsenRequestAnser2Sp:
        '/yado/onsen/assets/images/about/steps/onsen-request-anser2-sp.zh_hk.jpg?1702986206',
      aboutStepsOnsenRequestAnser1Pc:
        '/yado/onsen/assets/images/about/steps/onsen-request-anser1-pc.zh_hk.jpg?1702986206',
      aboutStepsPickupHowTo13Pc:
        '/yado/onsen/assets/images/about/steps/pickup-how-to1-3-pc.zh_hk.jpg?1702986206',
      aboutStepsOnsenHowTo1Sp:
        '/yado/onsen/assets/images/about/steps/onsen-how-to1-sp.zh_hk.jpg?1702986206',
      aboutStepsOnsenHowTo2Pc:
        '/yado/onsen/assets/images/about/steps/onsen-how-to2-pc.zh_hk.jpg?1702986206',
      aboutStepsPickupHowTo11Sp:
        '/yado/onsen/assets/images/about/steps/pickup-how-to1-1-sp.zh_hk.jpg?1702986206',
      aboutStepsPickupHowTo12Pc:
        '/yado/onsen/assets/images/about/steps/pickup-how-to1-2-pc.zh_hk.jpg?1702986206',
      aboutStepsMealHowTo1Sp:
        '/yado/onsen/assets/images/about/steps/meal-how-to1-sp.zh_hk.jpg?1702986206',
      aboutStepsMealHowTo2Pc:
        '/yado/onsen/assets/images/about/steps/meal-how-to2-pc.zh_hk.jpg?1702986206',
      aboutStepsOnsenHowTo2Sp:
        '/yado/onsen/assets/images/about/steps/onsen-how-to2-sp.zh_hk.jpg?1702986206',
      aboutStepsOnsenHowTo1Pc:
        '/yado/onsen/assets/images/about/steps/onsen-how-to1-pc.zh_hk.jpg?1702986206',
      aboutStepsPickupHowTo13Sp:
        '/yado/onsen/assets/images/about/steps/pickup-how-to1-3-sp.zh_hk.jpg?1702986206',
      aboutStepsPickupHowTo12Sp:
        '/yado/onsen/assets/images/about/steps/pickup-how-to1-2-sp.zh_hk.jpg?1702986206',
      aboutStepsPickupHowTo11Pc:
        '/yado/onsen/assets/images/about/steps/pickup-how-to1-1-pc.zh_hk.jpg?1702986206',
      aboutStepsMealHowTo2Sp:
        '/yado/onsen/assets/images/about/steps/meal-how-to2-sp.zh_hk.jpg?1702986206',
      aboutStepsMealHowTo1Pc:
        '/yado/onsen/assets/images/about/steps/meal-how-to1-pc.zh_hk.jpg?1702986206',
      aboutStepsMealRequestAnser2Sp:
        '/yado/onsen/assets/images/about/steps/meal-request-anser2-sp.zh_hk.jpg?1702986206',
      aboutStepsMealRequestAnser1Pc:
        '/yado/onsen/assets/images/about/steps/meal-request-anser1-pc.zh_hk.jpg?1702986206',
      aboutStepsPickupHowTo32Sp:
        '/yado/onsen/assets/images/about/steps/pickup-how-to3-2-sp.zh_hk.jpg?1702986206',
      aboutStepsPickupHowTo31Pc:
        '/yado/onsen/assets/images/about/steps/pickup-how-to3-1-pc.zh_hk.jpg?1702986206',
      aboutStepsPickupRequestAnser3Pc:
        '/yado/onsen/assets/images/about/steps/pickup-request-anser3-pc.zh_hk.jpg?1702986206',
      aboutStepsPickupHowTo23Sp:
        '/yado/onsen/assets/images/about/steps/pickup-how-to2-3-sp.zh_hk.jpg?1702986206',
      aboutStepsPickupRequestAnser1Sp:
        '/yado/onsen/assets/images/about/steps/pickup-request-anser1-sp.zh_hk.jpg?1702986206',
      aboutStepsPickupRequestAnser2Pc:
        '/yado/onsen/assets/images/about/steps/pickup-request-anser2-pc.zh_hk.jpg?1702986206',
      aboutStepsPickupHowTo33Sp:
        '/yado/onsen/assets/images/about/steps/pickup-how-to3-3-sp.zh_hk.jpg?1702986206',
      aboutStepsOnsenRequestAnser1Sp:
        '/yado/onsen/assets/images/about/steps/onsen-request-anser1-sp.zh_hk.jpg?1702986206',
      aboutStepsOnsenRequestAnser2Pc:
        '/yado/onsen/assets/images/about/steps/onsen-request-anser2-pc.zh_hk.jpg?1702986206',
      aboutStepsPickupHowTo21Pc:
        '/yado/onsen/assets/images/about/steps/pickup-how-to2-1-pc.zh_hk.jpg?1702986206',
      aboutStepsPickupHowTo22Sp:
        '/yado/onsen/assets/images/about/steps/pickup-how-to2-2-sp.zh_hk.jpg?1702986206',
      commonFlag: '/yado/onsen/assets/images/common/flag.zh_hk.png?1702986206',
      campaignActivityBannerHorizontal:
        '/yado/onsen/assets/images/campaign/activity/banner_horizontal.zh_hk.png?1709702960',
      campaignNissanBannerHorizontal:
        '/yado/onsen/assets/images/campaign/nissan/banner_horizontal.zh_hk.png?1708579723',
      featuredLimited202312LimitedPlan:
        '/yado/onsen/assets/images/featured/limited202312/limited_plan.zh_hk.jpg?1702986206',
      featured202311Kyoritsu:
        '/yado/onsen/assets/images/featured/202311/kyoritsu.zh_hk.jpg?1702986206',
      featured202311NewListing2023nov:
        '/yado/onsen/assets/images/featured/202311/NEW_listing_2023Nov.zh_hk.jpg?1702986206',
      featured202402NewListing2024feb:
        '/yado/onsen/assets/images/featured/202402/NEW_listing_2024Feb.zh_hk.jpg?1710821062',
      featured202403NewListing2024mar:
        '/yado/onsen/assets/images/featured/202403/NEW_listing_2024Mar.zh_hk.jpg?1712306405',
      featured202304WagyuOnsen:
        '/yado/onsen/assets/images/featured/202304/wagyu_onsen.zh_hk.png?1702986206',
      featured202304FireworksOnsen:
        '/yado/onsen/assets/images/featured/202304/fireworks_onsen.zh_hk.png?1702986206',
      featured202304LocalFood:
        '/yado/onsen/assets/images/featured/202304/local_food.zh_hk.png?1702986206',
      featured202304PhotoSpot:
        '/yado/onsen/assets/images/featured/202304/photo_spot.zh_hk.png?1702986206',
      featured202304NewListing:
        '/yado/onsen/assets/images/featured/202304/new_listing.zh_hk.png?1702986206',
      featured202303JapanOnsen100:
        '/yado/onsen/assets/images/featured/202303/japan_onsen_100.zh_hk.jpg?1702986206',
      featured202303Private201910:
        '/yado/onsen/assets/images/featured/202303/private201910.zh_hk.jpg?1702986206',
      featured202303CostPerformance:
        '/yado/onsen/assets/images/featured/202303/cost_performance.zh_hk.jpg?1702986206',
      featured202303ChildOnsen:
        '/yado/onsen/assets/images/featured/202303/child_onsen.zh_hk.jpg?1702986206',
      featured202303SakuraOnsen:
        '/yado/onsen/assets/images/featured/202303/sakura_onsen.zh_hk.jpg?1702986206',
      featured202305RelaxOnsen:
        '/yado/onsen/assets/images/featured/202305/relax_onsen.zh_hk.jpg?1702986206',
      featured202305PoolOnsen:
        '/yado/onsen/assets/images/featured/202305/pool_onsen.zh_hk.jpg?1702986206',
      featured202305JapanFestivals:
        '/yado/onsen/assets/images/featured/202305/japan_festivals.zh_hk.jpg?1702986206',
      featured202305Themepark:
        '/yado/onsen/assets/images/featured/202305/themepark.zh_hk.jpg?1702986206',
      featured202312NewListing2023dec:
        '/yado/onsen/assets/images/featured/202312/NEW_listing_2023Dec.zh_hk.jpg?1703567287',
      featured202401NewListing2024jan:
        '/yado/onsen/assets/images/featured/202401/NEW_listing_2024Jan.zh_hk.jpg?1708579723',
      featuredLimited202404LimitedPlan:
        '/yado/onsen/assets/images/featured/limited202404/limited_plan.zh_hk.jpg?1712306405',
      featuredKani202312Crab:
        '/yado/onsen/assets/images/featured/kani202312/crab.zh_hk.jpg?1704944546',
      featuredKani202312BannerCrab:
        '/yado/onsen/assets/images/featured/kani202312/banner_crab.zh_hk.jpg?1704944546',
      featured202309SnowOnsen:
        '/yado/onsen/assets/images/featured/202309/snow_onsen.zh_hk.jpg?1702986206',
      featured202309NewListing2023julysep:
        '/yado/onsen/assets/images/featured/202309/NEW_listing_2023JulySep.zh_hk.jpg?1702986206',
      featured202306AutumnleavesSpot:
        '/yado/onsen/assets/images/featured/202306/autumnleaves_spot.zh_hk.jpg?1702986206',
      featured202306NewListing:
        '/yado/onsen/assets/images/featured/202306/new_listing.zh_hk.jpg?1702986206',
    },
  },
  en: {
    images: {
      onsenYadoRibbonsDinnerRibbonRecommended2x:
        '/yado/onsen/assets/images/onsen_yado/ribbons/dinner_ribbon_recommended_2x.en.png?1702986206',
      onsenYadoRibbonsDinnerRibbonStandard:
        '/yado/onsen/assets/images/onsen_yado/ribbons/dinner_ribbon_standard.en.png?1702986206',
      onsenYadoRibbonsDinnerRibbonEarlyBird2x:
        '/yado/onsen/assets/images/onsen_yado/ribbons/dinner_ribbon_early_bird_2x.en.png?1702986206',
      onsenYadoRibbonsDinnerRibbonStandard2x:
        '/yado/onsen/assets/images/onsen_yado/ribbons/dinner_ribbon_standard_2x.en.png?1702986206',
      onsenYadoRibbonsDinnerRibbonRecommended:
        '/yado/onsen/assets/images/onsen_yado/ribbons/dinner_ribbon_recommended.en.png?1702986206',
      onsenYadoRibbonsDinnerRibbonEarlyBird:
        '/yado/onsen/assets/images/onsen_yado/ribbons/dinner_ribbon_early_bird.en.png?1702986206',
      onsenYadoCrossSellingInsuranceImportantMattersSompo1:
        '/yado/onsen/assets/images/onsen_yado/cross_selling/insurance/important_matters_sompo_1.en.jpg?1702986206',
      onsenYadoCrossSellingInsuranceImportantMattersSompo2:
        '/yado/onsen/assets/images/onsen_yado/cross_selling/insurance/important_matters_sompo_2.en.jpg?1702986206',
      newTopGallerySnowOnsenHorizontal:
        '/yado/onsen/assets/images/new_top/gallery/snow_onsen_horizontal.en.jpg?1702986206',
      newTopGalleryLimitedPlan202404:
        '/yado/onsen/assets/images/new_top/gallery/limited_plan_202404.en.jpg?1712306405',
      newTopGalleryPrivateBathHorizontal:
        '/yado/onsen/assets/images/new_top/gallery/private_bath_horizontal.en.jpg?1702986206',
      newTopGalleryYufuin: '/yado/onsen/assets/images/new_top/gallery/yufuin.en.jpg?1713761370',
      newTopGalleryKusatsu: '/yado/onsen/assets/images/new_top/gallery/kusatsu.en.jpg?1713761370',
      aboutStepsPickupHowTo31Sp:
        '/yado/onsen/assets/images/about/steps/pickup-how-to3-1-sp.en.jpg?1702986206',
      aboutStepsPickupRequestAnser2Pc:
        '/yado/onsen/assets/images/about/steps/pickup-request-anser2-pc.en.jpg?1702986206',
      aboutStepsPickupHowTo12Sp:
        '/yado/onsen/assets/images/about/steps/pickup-how-to1-2-sp.en.jpg?1702986206',
      aboutStepsPickupHowTo33Pc:
        '/yado/onsen/assets/images/about/steps/pickup-how-to3-3-pc.en.jpg?1702986206',
      aboutStepsOnsenRequestAnser2Sp:
        '/yado/onsen/assets/images/about/steps/onsen-request-anser2-sp.en.jpg?1702986206',
      aboutStepsPickupHowTo21Pc:
        '/yado/onsen/assets/images/about/steps/pickup-how-to2-1-pc.en.jpg?1702986206',
      aboutStepsMealRequestAnser1Sp:
        '/yado/onsen/assets/images/about/steps/meal-request-anser1-sp.en.jpg?1702986206',
      aboutStepsMealHowTo2Pc:
        '/yado/onsen/assets/images/about/steps/meal-how-to2-pc.en.jpg?1702986206',
      aboutStepsPickupHowTo23Sp:
        '/yado/onsen/assets/images/about/steps/pickup-how-to2-3-sp.en.jpg?1702986206',
      aboutStepsOnsenHowTo2Sp:
        '/yado/onsen/assets/images/about/steps/onsen-how-to2-sp.en.jpg?1702986206',
      aboutStepsPickupRequestAnser3Pc:
        '/yado/onsen/assets/images/about/steps/pickup-request-anser3-pc.en.jpg?1702986206',
      aboutStepsOnsenRequestAnser1Pc:
        '/yado/onsen/assets/images/about/steps/onsen-request-anser1-pc.en.jpg?1702986206',
      aboutStepsPickupHowTo13Sp:
        '/yado/onsen/assets/images/about/steps/pickup-how-to1-3-sp.en.jpg?1702986206',
      aboutStepsPickupHowTo32Pc:
        '/yado/onsen/assets/images/about/steps/pickup-how-to3-2-pc.en.jpg?1702986206',
      aboutStepsPickupRequestAnser1Sp:
        '/yado/onsen/assets/images/about/steps/pickup-request-anser1-sp.en.jpg?1702986206',
      aboutStepsPickupHowTo11Pc:
        '/yado/onsen/assets/images/about/steps/pickup-how-to1-1-pc.en.jpg?1702986206',
      aboutStepsOnsenHowTo1Pc:
        '/yado/onsen/assets/images/about/steps/onsen-how-to1-pc.en.jpg?1702986206',
      aboutStepsPickupHowTo22Sp:
        '/yado/onsen/assets/images/about/steps/pickup-how-to2-2-sp.en.jpg?1702986206',
      aboutStepsMealRequestAnser2Pc:
        '/yado/onsen/assets/images/about/steps/meal-request-anser2-pc.en.jpg?1702986206',
      aboutStepsMealHowTo1Sp:
        '/yado/onsen/assets/images/about/steps/meal-how-to1-sp.en.jpg?1702986206',
      aboutStepsOnsenRequestAnser2Pc:
        '/yado/onsen/assets/images/about/steps/onsen-request-anser2-pc.en.jpg?1702986206',
      aboutStepsPickupHowTo33Sp:
        '/yado/onsen/assets/images/about/steps/pickup-how-to3-3-sp.en.jpg?1702986206',
      aboutStepsPickupHowTo12Pc:
        '/yado/onsen/assets/images/about/steps/pickup-how-to1-2-pc.en.jpg?1702986206',
      aboutStepsPickupRequestAnser2Sp:
        '/yado/onsen/assets/images/about/steps/pickup-request-anser2-sp.en.jpg?1702986206',
      aboutStepsPickupHowTo31Pc:
        '/yado/onsen/assets/images/about/steps/pickup-how-to3-1-pc.en.jpg?1702986206',
      aboutStepsOnsenHowTo2Pc:
        '/yado/onsen/assets/images/about/steps/onsen-how-to2-pc.en.jpg?1702986206',
      aboutStepsPickupHowTo23Pc:
        '/yado/onsen/assets/images/about/steps/pickup-how-to2-3-pc.en.jpg?1702986206',
      aboutStepsMealHowTo2Sp:
        '/yado/onsen/assets/images/about/steps/meal-how-to2-sp.en.jpg?1702986206',
      aboutStepsPickupHowTo21Sp:
        '/yado/onsen/assets/images/about/steps/pickup-how-to2-1-sp.en.jpg?1702986206',
      aboutStepsMealRequestAnser1Pc:
        '/yado/onsen/assets/images/about/steps/meal-request-anser1-pc.en.jpg?1702986206',
      aboutStepsPickupHowTo11Sp:
        '/yado/onsen/assets/images/about/steps/pickup-how-to1-1-sp.en.jpg?1702986206',
      aboutStepsPickupRequestAnser1Pc:
        '/yado/onsen/assets/images/about/steps/pickup-request-anser1-pc.en.jpg?1702986206',
      aboutStepsPickupHowTo32Sp:
        '/yado/onsen/assets/images/about/steps/pickup-how-to3-2-sp.en.jpg?1702986206',
      aboutStepsPickupHowTo13Pc:
        '/yado/onsen/assets/images/about/steps/pickup-how-to1-3-pc.en.jpg?1702986206',
      aboutStepsOnsenRequestAnser1Sp:
        '/yado/onsen/assets/images/about/steps/onsen-request-anser1-sp.en.jpg?1702986206',
      aboutStepsPickupRequestAnser3Sp:
        '/yado/onsen/assets/images/about/steps/pickup-request-anser3-sp.en.jpg?1702986206',
      aboutStepsMealHowTo1Pc:
        '/yado/onsen/assets/images/about/steps/meal-how-to1-pc.en.jpg?1702986206',
      aboutStepsPickupHowTo22Pc:
        '/yado/onsen/assets/images/about/steps/pickup-how-to2-2-pc.en.jpg?1702986206',
      aboutStepsMealRequestAnser2Sp:
        '/yado/onsen/assets/images/about/steps/meal-request-anser2-sp.en.jpg?1702986206',
      aboutStepsOnsenHowTo1Sp:
        '/yado/onsen/assets/images/about/steps/onsen-how-to1-sp.en.jpg?1702986206',
      commonAppStoreStoreApple:
        '/yado/onsen/assets/images/common/app_store/store_apple.en.png?1702986206',
      commonAppStoreStoreAndroid:
        '/yado/onsen/assets/images/common/app_store/store_android.en.png?1702986206',
      commonFlag: '/yado/onsen/assets/images/common/flag.en.png?1702986206',
      campaignActivityBannerHorizontal:
        '/yado/onsen/assets/images/campaign/activity/banner_horizontal.en.png?1709702960',
      campaignNissanBannerHorizontal:
        '/yado/onsen/assets/images/campaign/nissan/banner_horizontal.en.png?1708579723',
      featuredLimited202312LimitedPlan:
        '/yado/onsen/assets/images/featured/limited202312/limited_plan.en.jpg?1702986206',
      featured202311Kyoritsu:
        '/yado/onsen/assets/images/featured/202311/kyoritsu.en.jpg?1702986206',
      featured202311NewListing2023nov:
        '/yado/onsen/assets/images/featured/202311/NEW_listing_2023Nov.en.jpg?1702986206',
      featured202402NewListing2024feb:
        '/yado/onsen/assets/images/featured/202402/NEW_listing_2024Feb.en.jpg?1710821062',
      featured202403NewListing2024mar:
        '/yado/onsen/assets/images/featured/202403/NEW_listing_2024Mar.en.jpg?1712306405',
      featured202304NewListing:
        '/yado/onsen/assets/images/featured/202304/new_listing.en.png?1702986206',
      featured202304FireworksOnsen:
        '/yado/onsen/assets/images/featured/202304/fireworks_onsen.en.png?1702986206',
      featured202304PhotoSpot:
        '/yado/onsen/assets/images/featured/202304/photo_spot.en.png?1702986206',
      featured202304WagyuOnsen:
        '/yado/onsen/assets/images/featured/202304/wagyu_onsen.en.png?1702986206',
      featured202304LocalFood:
        '/yado/onsen/assets/images/featured/202304/local_food.en.png?1702986206',
      featured202303CostPerformance:
        '/yado/onsen/assets/images/featured/202303/cost_performance.en.jpg?1702986206',
      featured202303ChildOnsen:
        '/yado/onsen/assets/images/featured/202303/child_onsen.en.jpg?1702986206',
      featured202303JapanOnsen100:
        '/yado/onsen/assets/images/featured/202303/japan_onsen_100.en.jpg?1702986206',
      featured202303Private201910:
        '/yado/onsen/assets/images/featured/202303/private201910.en.jpg?1702986206',
      featured202303SakuraOnsen:
        '/yado/onsen/assets/images/featured/202303/sakura_onsen.en.jpg?1702986206',
      featured202305PoolOnsen:
        '/yado/onsen/assets/images/featured/202305/pool_onsen.en.jpg?1702986206',
      featured202305RelaxOnsen:
        '/yado/onsen/assets/images/featured/202305/relax_onsen.en.jpg?1702986206',
      featured202305Themepark:
        '/yado/onsen/assets/images/featured/202305/themepark.en.jpg?1702986206',
      featured202305JapanFestivals:
        '/yado/onsen/assets/images/featured/202305/japan_festivals.en.jpg?1702986206',
      featured202312NewListing2023dec:
        '/yado/onsen/assets/images/featured/202312/NEW_listing_2023Dec.en.jpg?1703567287',
      featured202401NewListing2024jan:
        '/yado/onsen/assets/images/featured/202401/NEW_listing_2024Jan.en.jpg?1708579723',
      featuredLimited202404LimitedPlan:
        '/yado/onsen/assets/images/featured/limited202404/limited_plan.en.jpg?1712306405',
      featuredKani202312Crab:
        '/yado/onsen/assets/images/featured/kani202312/crab.en.jpg?1704944546',
      featuredKani202312BannerCrab:
        '/yado/onsen/assets/images/featured/kani202312/banner_crab.en.jpg?1704944546',
      featured202309NewListing2023julysep:
        '/yado/onsen/assets/images/featured/202309/NEW_listing_2023JulySep.en.jpg?1702986206',
      featured202309SnowOnsen:
        '/yado/onsen/assets/images/featured/202309/snow_onsen.en.png?1702986206',
      featured202306NewListing:
        '/yado/onsen/assets/images/featured/202306/new_listing.en.jpg?1702986206',
      featured202306AutumnleavesSpot:
        '/yado/onsen/assets/images/featured/202306/autumnleaves_spot.en.jpg?1702986206',
    },
  },
};

function detectAssetData(group: string, key: string): any {
  const assetLocale: any = assetsData[getLocale()];
  if (assetLocale && assetLocale[group] && assetLocale[group][key]) {
    return assetLocale[group][key];
  }
  return (assetsData.default as any)[group][key];
}

function addOriginForCDN(path: string) {
  if (getLocale() === 'zh_cn' && ENV.ENVIRONMENT !== 'development') {
    if (path.includes('/yado/onsen')) {
      return `${ENV.WAMAZING_ASSETS_ENDPOINT_CN}${path.replace('/yado/onsen', '/yado')}`;
    }
    return `${ENV.WAMAZING_ASSETS_ENDPOINT_CN}${path}`;
  }
  return path;
}

const assets = {
  jsons: {
    get spots() {
      return addOriginForCDN(detectAssetData('jsons', 'spots'));
    },
    get features() {
      return addOriginForCDN(detectAssetData('jsons', 'features'));
    },
    get citySpots() {
      return addOriginForCDN(detectAssetData('jsons', 'citySpots'));
    },
    get prefectureDetails() {
      return addOriginForCDN(detectAssetData('jsons', 'prefectureDetails'));
    },
    get featureRelations() {
      return addOriginForCDN(detectAssetData('jsons', 'featureRelations'));
    },
    get prefectures() {
      return addOriginForCDN(detectAssetData('jsons', 'prefectures'));
    },
    get cities() {
      return addOriginForCDN(detectAssetData('jsons', 'cities'));
    },
  },
  images: {
    get pjtArrowLeftThin() {
      return addOriginForCDN(detectAssetData('images', 'pjtArrowLeftThin'));
    },
    get pjtMealsForGrey() {
      return addOriginForCDN(detectAssetData('images', 'pjtMealsForGrey'));
    },
    get pjtArrowBlueUp() {
      return addOriginForCDN(detectAssetData('images', 'pjtArrowBlueUp'));
    },
    get pjtAddLightBlue() {
      return addOriginForCDN(detectAssetData('images', 'pjtAddLightBlue'));
    },
    get pjtMealGrey() {
      return addOriginForCDN(detectAssetData('images', 'pjtMealGrey'));
    },
    get pjtAreaNikkoKinugawa() {
      return addOriginForCDN(detectAssetData('images', 'pjtAreaNikkoKinugawa'));
    },
    get pjtAreaWakura() {
      return addOriginForCDN(detectAssetData('images', 'pjtAreaWakura'));
    },
    get pjtAreaHokkaido() {
      return addOriginForCDN(detectAssetData('images', 'pjtAreaHokkaido'));
    },
    get pjtAreaToyako() {
      return addOriginForCDN(detectAssetData('images', 'pjtAreaToyako'));
    },
    get pjtAreaYugawara() {
      return addOriginForCDN(detectAssetData('images', 'pjtAreaYugawara'));
    },
    get pjtAreaYufuin() {
      return addOriginForCDN(detectAssetData('images', 'pjtAreaYufuin'));
    },
    get pjtAreaKaga() {
      return addOriginForCDN(detectAssetData('images', 'pjtAreaKaga'));
    },
    get pjtAreaFukuoka() {
      return addOriginForCDN(detectAssetData('images', 'pjtAreaFukuoka'));
    },
    get pjtAreaHakoneYugawara() {
      return addOriginForCDN(detectAssetData('images', 'pjtAreaHakoneYugawara'));
    },
    get pjtAreaWakayama() {
      return addOriginForCDN(detectAssetData('images', 'pjtAreaWakayama'));
    },
    get pjtAreaYamaguchi() {
      return addOriginForCDN(detectAssetData('images', 'pjtAreaYamaguchi'));
    },
    get pjtAreaGero() {
      return addOriginForCDN(detectAssetData('images', 'pjtAreaGero'));
    },
    get pjtAreaKusatsu() {
      return addOriginForCDN(detectAssetData('images', 'pjtAreaKusatsu'));
    },
    get pjtAreaIzu() {
      return addOriginForCDN(detectAssetData('images', 'pjtAreaIzu'));
    },
    get pjtAreaFuji() {
      return addOriginForCDN(detectAssetData('images', 'pjtAreaFuji'));
    },
    get pjtAreaNagano() {
      return addOriginForCDN(detectAssetData('images', 'pjtAreaNagano'));
    },
    get pjtAreaArima() {
      return addOriginForCDN(detectAssetData('images', 'pjtAreaArima'));
    },
    get pjtAreaKinosaki() {
      return addOriginForCDN(detectAssetData('images', 'pjtAreaKinosaki'));
    },
    get pjtAreaSapporoJozankei() {
      return addOriginForCDN(detectAssetData('images', 'pjtAreaSapporoJozankei'));
    },
    get pjtAreaIbusuki() {
      return addOriginForCDN(detectAssetData('images', 'pjtAreaIbusuki'));
    },
    get pjtAreaBeppu() {
      return addOriginForCDN(detectAssetData('images', 'pjtAreaBeppu'));
    },
    get pjtAreaKurokawa() {
      return addOriginForCDN(detectAssetData('images', 'pjtAreaKurokawa'));
    },
    get pjtAreaHakodateYunokawa() {
      return addOriginForCDN(detectAssetData('images', 'pjtAreaHakodateYunokawa'));
    },
    get pjtAreaKyoto() {
      return addOriginForCDN(detectAssetData('images', 'pjtAreaKyoto'));
    },
    get pjtAddBlue() {
      return addOriginForCDN(detectAssetData('images', 'pjtAddBlue'));
    },
    get pjtDownUpArrowBlue() {
      return addOriginForCDN(detectAssetData('images', 'pjtDownUpArrowBlue'));
    },
    get pjtHelpBlue() {
      return addOriginForCDN(detectAssetData('images', 'pjtHelpBlue'));
    },
    get pjtArrowBlueDown() {
      return addOriginForCDN(detectAssetData('images', 'pjtArrowBlueDown'));
    },
    get pjtHotSpringGrey() {
      return addOriginForCDN(detectAssetData('images', 'pjtHotSpringGrey'));
    },
    get pjtLimitedYellowTag() {
      return addOriginForCDN(detectAssetData('images', 'pjtLimitedYellowTag'));
    },
    get pjtRoomGrey() {
      return addOriginForCDN(detectAssetData('images', 'pjtRoomGrey'));
    },
    get pjtArrowRightThin() {
      return addOriginForCDN(detectAssetData('images', 'pjtArrowRightThin'));
    },
    get pjtOtherGrey() {
      return addOriginForCDN(detectAssetData('images', 'pjtOtherGrey'));
    },
    get pjtPjtLinkArrow() {
      return addOriginForCDN(detectAssetData('images', 'pjtPjtLinkArrow'));
    },
    get pjtLimitedWhiteTag() {
      return addOriginForCDN(detectAssetData('images', 'pjtLimitedWhiteTag'));
    },
    get faviconsFavicon16x16() {
      return addOriginForCDN(detectAssetData('images', 'faviconsFavicon16x16'));
    },
    get faviconsMstile310x310() {
      return addOriginForCDN(detectAssetData('images', 'faviconsMstile310x310'));
    },
    get faviconsMstile144x144() {
      return addOriginForCDN(detectAssetData('images', 'faviconsMstile144x144'));
    },
    get faviconsSafariPinnedTab() {
      return addOriginForCDN(detectAssetData('images', 'faviconsSafariPinnedTab'));
    },
    get faviconsFavicon() {
      return addOriginForCDN(detectAssetData('images', 'faviconsFavicon'));
    },
    get faviconsAppleTouchIcon120x120() {
      return addOriginForCDN(detectAssetData('images', 'faviconsAppleTouchIcon120x120'));
    },
    get faviconsAndroidChrome192x192() {
      return addOriginForCDN(detectAssetData('images', 'faviconsAndroidChrome192x192'));
    },
    get faviconsFavicon196x196() {
      return addOriginForCDN(detectAssetData('images', 'faviconsFavicon196x196'));
    },
    get faviconsAppleTouchIcon() {
      return addOriginForCDN(detectAssetData('images', 'faviconsAppleTouchIcon'));
    },
    get faviconsAndroidChrome256x256() {
      return addOriginForCDN(detectAssetData('images', 'faviconsAndroidChrome256x256'));
    },
    get faviconsMstile70x70() {
      return addOriginForCDN(detectAssetData('images', 'faviconsMstile70x70'));
    },
    get faviconsAppleTouchIcon152x152() {
      return addOriginForCDN(detectAssetData('images', 'faviconsAppleTouchIcon152x152'));
    },
    get faviconsMstile310x150() {
      return addOriginForCDN(detectAssetData('images', 'faviconsMstile310x150'));
    },
    get faviconsAppleTouchIcon114x114() {
      return addOriginForCDN(detectAssetData('images', 'faviconsAppleTouchIcon114x114'));
    },
    get faviconsAppleTouchIcon76x76() {
      return addOriginForCDN(detectAssetData('images', 'faviconsAppleTouchIcon76x76'));
    },
    get faviconsFavicon128() {
      return addOriginForCDN(detectAssetData('images', 'faviconsFavicon128'));
    },
    get faviconsFavicon96x96() {
      return addOriginForCDN(detectAssetData('images', 'faviconsFavicon96x96'));
    },
    get faviconsAppleTouchIcon57x57() {
      return addOriginForCDN(detectAssetData('images', 'faviconsAppleTouchIcon57x57'));
    },
    get faviconsManifest() {
      return addOriginForCDN(detectAssetData('images', 'faviconsManifest'));
    },
    get faviconsAppleTouchIcon72x72() {
      return addOriginForCDN(detectAssetData('images', 'faviconsAppleTouchIcon72x72'));
    },
    get faviconsMstile150x150() {
      return addOriginForCDN(detectAssetData('images', 'faviconsMstile150x150'));
    },
    get faviconsAppleTouchIcon60x60() {
      return addOriginForCDN(detectAssetData('images', 'faviconsAppleTouchIcon60x60'));
    },
    get faviconsAppleTouchIcon144x144() {
      return addOriginForCDN(detectAssetData('images', 'faviconsAppleTouchIcon144x144'));
    },
    get faviconsBrowserconfig() {
      return addOriginForCDN(detectAssetData('images', 'faviconsBrowserconfig'));
    },
    get faviconsFavicon32x32() {
      return addOriginForCDN(detectAssetData('images', 'faviconsFavicon32x32'));
    },
    get yadoMapiconOnsen() {
      return addOriginForCDN(detectAssetData('images', 'yadoMapiconOnsen'));
    },
    get yadoMapiconHotspring() {
      return addOriginForCDN(detectAssetData('images', 'yadoMapiconHotspring'));
    },
    get yadoMapiconStation() {
      return addOriginForCDN(detectAssetData('images', 'yadoMapiconStation'));
    },
    get yadoMapiconDefault() {
      return addOriginForCDN(detectAssetData('images', 'yadoMapiconDefault'));
    },
    get yadoMapiconAirport() {
      return addOriginForCDN(detectAssetData('images', 'yadoMapiconAirport'));
    },
    get yadoIconShape() {
      return addOriginForCDN(detectAssetData('images', 'yadoIconShape'));
    },
    get yadoIconSim() {
      return addOriginForCDN(detectAssetData('images', 'yadoIconSim'));
    },
    get yadoIconSearch() {
      return addOriginForCDN(detectAssetData('images', 'yadoIconSearch'));
    },
    get yadoIconDinner() {
      return addOriginForCDN(detectAssetData('images', 'yadoIconDinner'));
    },
    get yadoIconBed() {
      return addOriginForCDN(detectAssetData('images', 'yadoIconBed'));
    },
    get yadoIconCalendarBlue() {
      return addOriginForCDN(detectAssetData('images', 'yadoIconCalendarBlue'));
    },
    get yadoIconUser() {
      return addOriginForCDN(detectAssetData('images', 'yadoIconUser'));
    },
    get yadoIconSmokingOk() {
      return addOriginForCDN(detectAssetData('images', 'yadoIconSmokingOk'));
    },
    get yadoIconRatingGreat() {
      return addOriginForCDN(detectAssetData('images', 'yadoIconRatingGreat'));
    },
    get yadoIconRoom() {
      return addOriginForCDN(detectAssetData('images', 'yadoIconRoom'));
    },
    get yadoIconRatingGood() {
      return addOriginForCDN(detectAssetData('images', 'yadoIconRatingGood'));
    },
    get yadoIconBath() {
      return addOriginForCDN(detectAssetData('images', 'yadoIconBath'));
    },
    get yadoIconGuestcount() {
      return addOriginForCDN(detectAssetData('images', 'yadoIconGuestcount'));
    },
    get yadoIconTrain() {
      return addOriginForCDN(detectAssetData('images', 'yadoIconTrain'));
    },
    get yadoIconToaster() {
      return addOriginForCDN(detectAssetData('images', 'yadoIconToaster'));
    },
    get yadoIconSmokingUnknown() {
      return addOriginForCDN(detectAssetData('images', 'yadoIconSmokingUnknown'));
    },
    get yadoIconCalendarOut() {
      return addOriginForCDN(detectAssetData('images', 'yadoIconCalendarOut'));
    },
    get yadoIconPlus() {
      return addOriginForCDN(detectAssetData('images', 'yadoIconPlus'));
    },
    get yadoIconWifi() {
      return addOriginForCDN(detectAssetData('images', 'yadoIconWifi'));
    },
    get yadoIconPinpoint() {
      return addOriginForCDN(detectAssetData('images', 'yadoIconPinpoint'));
    },
    get yadoIconAircon() {
      return addOriginForCDN(detectAssetData('images', 'yadoIconAircon'));
    },
    get yadoIconChild() {
      return addOriginForCDN(detectAssetData('images', 'yadoIconChild'));
    },
    get yadoIconMarker() {
      return addOriginForCDN(detectAssetData('images', 'yadoIconMarker'));
    },
    get yadoIconRoomSpace() {
      return addOriginForCDN(detectAssetData('images', 'yadoIconRoomSpace'));
    },
    get yadoIconAdult() {
      return addOriginForCDN(detectAssetData('images', 'yadoIconAdult'));
    },
    get yadoIconDiamond() {
      return addOriginForCDN(detectAssetData('images', 'yadoIconDiamond'));
    },
    get yadoIconCalendar() {
      return addOriginForCDN(detectAssetData('images', 'yadoIconCalendar'));
    },
    get yadoIconSmokingNg() {
      return addOriginForCDN(detectAssetData('images', 'yadoIconSmokingNg'));
    },
    get yadoIconMinus() {
      return addOriginForCDN(detectAssetData('images', 'yadoIconMinus'));
    },
    get yadoIconMoon() {
      return addOriginForCDN(detectAssetData('images', 'yadoIconMoon'));
    },
    get yadoIconCalendarIn() {
      return addOriginForCDN(detectAssetData('images', 'yadoIconCalendarIn'));
    },
    get onsenYadoSpaIcon() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoSpaIcon'));
    },
    get onsenYadoJetBathIcon() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoJetBathIcon'));
    },
    get onsenYadoSaunaIcon() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoSaunaIcon'));
    },
    get onsenYadoFineViewBathIcon() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoFineViewBathIcon'));
    },
    get onsenYadoOpenAirBathIcon() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoOpenAirBathIcon'));
    },
    get onsenYadoAreaShodoshima() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaShodoshima'));
    },
    get onsenYadoAreaShima() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaShima'));
    },
    get onsenYadoAreaNikkoKinugawa() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaNikkoKinugawa'));
    },
    get onsenYadoAreaWakura() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaWakura'));
    },
    get onsenYadoAreaAkiu() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaAkiu'));
    },
    get onsenYadoAreaYamanashi() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaYamanashi'));
    },
    get onsenYadoAreaHitoyoshi() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaHitoyoshi'));
    },
    get onsenYadoAreaNozawa() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaNozawa'));
    },
    get onsenYadoAreaAwajishima() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaAwajishima'));
    },
    get onsenYadoAreaNaruko() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaNaruko'));
    },
    get onsenYadoAreaNankishirahama() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaNankishirahama'));
    },
    get onsenYadoAreaTendo() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaTendo'));
    },
    get onsenYadoAreaDorogawa() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaDorogawa'));
    },
    get onsenYadoAreaNagatoyumotoYuyawan() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaNagatoyumotoYuyawan'));
    },
    get onsenYadoAreaOkayamaKurashiki() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaOkayamaKurashiki'));
    },
    get onsenYadoAreaHagi() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaHagi'));
    },
    get onsenYadoAreaIsawa() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaIsawa'));
    },
    get onsenYadoAreaMadaraoNojiriko() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaMadaraoNojiriko'));
    },
    get onsenYadoAreaToyako() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaToyako'));
    },
    get onsenYadoAreaKyotoAmanohashidate() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaKyotoAmanohashidate'));
    },
    get onsenYadoAreaYumuraKasumi() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaYumuraKasumi'));
    },
    get onsenYadoAreaKitayuzawa() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaKitayuzawa'));
    },
    get onsenYadoAreaGinzan() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaGinzan'));
    },
    get onsenYadoAreaYugawara() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaYugawara'));
    },
    get onsenYadoAreaOzeOigami() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaOzeOigami'));
    },
    get onsenYadoAreaObokeIya() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaObokeIya'));
    },
    get onsenYadoAreaKaike() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaKaike'));
    },
    get onsenYadoAreaShigakogen() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaShigakogen'));
    },
    get onsenYadoAreaYufuin() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaYufuin'));
    },
    get onsenYadoAreaYuda() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaYuda'));
    },
    get onsenYadoAreaTamatsukuri() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaTamatsukuri'));
    },
    get onsenYadoAreaMisasa() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaMisasa'));
    },
    get onsenYadoAreaKaga() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaKaga'));
    },
    get onsenYadoAreaKanazawa() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaKanazawa'));
    },
    get onsenYadoAreaShiretoko() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaShiretoko'));
    },
    get onsenYadoAreaWamazing() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaWamazing'));
    },
    get onsenYadoAreaAizu() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaAizu'));
    },
    get onsenYadoAreaSaseboIkiHirado() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaSaseboIkiHirado'));
    },
    get onsenYadoAreaTokurakamiyamada() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaTokurakamiyamada'));
    },
    get onsenYadoAreaUnzenObamaShimabara() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaUnzenObamaShimabara'));
    },
    get onsenYadoAreaAsahikawaSounkyo() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaAsahikawaSounkyo'));
    },
    get onsenYadoAreaIseShimaToba() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaIseShimaToba'));
    },
    get onsenYadoAreaOgoto() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaOgoto'));
    },
    get onsenYadoAreaNagoya() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaNagoya'));
    },
    get onsenYadoAreaHanamaki() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaHanamaki'));
    },
    get onsenYadoAreaHakoneYugawara() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaHakoneYugawara'));
    },
    get onsenYadoAreaKagoshimaCentralarea() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaKagoshimaCentralarea'));
    },
    get onsenYadoAreaImabari() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaImabari'));
    },
    get onsenYadoAreaOtaruAsarigawa() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaOtaruAsarigawa'));
    },
    get onsenYadoAreaNyutoTazawako() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaNyutoTazawako'));
    },
    get onsenYadoAreaTokachigawaObihiro() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaTokachigawaObihiro'));
    },
    get onsenYadoAreaHakubaOmachiAzumino() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaHakubaOmachiAzumino'));
    },
    get onsenYadoAreaIizakaTsuchiyuBandaiatami() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaIizakaTsuchiyuBandaiatami'));
    },
    get onsenYadoAreaZao() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaZao'));
    },
    get onsenYadoAreaShizuokaYaizu() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaShizuokaYaizu'));
    },
    get onsenYadoAreaIsobeTomioka() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaIsobeTomioka'));
    },
    get onsenYadoAreaKagawa() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaKagawa'));
    },
    get onsenYadoAreaTakamatsu() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaTakamatsu'));
    },
    get onsenYadoAreaShimonoseki() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaShimonoseki'));
    },
    get onsenYadoAreaShinshutakayama() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaShinshutakayama'));
    },
    get onsenYadoAreaYamaguchi() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaYamaguchi'));
    },
    get onsenYadoAreaGero() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaGero'));
    },
    get onsenYadoAreaHamamatsuHamanako() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaHamamatsuHamanako'));
    },
    get onsenYadoAreaUreshino() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaUreshino'));
    },
    get onsenYadoAreaKirishima() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaKirishima'));
    },
    get onsenYadoAreaKusatsu() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaKusatsu'));
    },
    get onsenYadoAreaIzu() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaIzu'));
    },
    get onsenYadoAreaKatsuura() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaKatsuura'));
    },
    get onsenYadoAreaFuji() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaFuji'));
    },
    get onsenYadoAreaIkaho() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaIkaho'));
    },
    get onsenYadoAreaTateshinaShirakabako() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaTateshinaShirakabako'));
    },
    get onsenYadoAreaAshizuriShimanto() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaAshizuriShimanto'));
    },
    get onsenYadoAreaTsuyamaHiruzen() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaTsuyamaHiruzen'));
    },
    get onsenYadoAreaMinakami() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaMinakami'));
    },
    get onsenYadoAreaYudanakaShibu() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaYudanakaShibu'));
    },
    get onsenYadoAreaArima() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaArima'));
    },
    get onsenYadoAreaTsukioka() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaTsukioka'));
    },
    get onsenYadoAreaHidaTakayama() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaHidaTakayama'));
    },
    get onsenYadoAreaKinosaki() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaKinosaki'));
    },
    get onsenYadoAreaYunogo() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaYunogo'));
    },
    get onsenYadoAreaYubara() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaYubara'));
    },
    get onsenYadoAreaKyotoCity() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaKyotoCity'));
    },
    get onsenYadoAreaEchigoyuzawa() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaEchigoyuzawa'));
    },
    get onsenYadoAreaMatsumoto() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaMatsumoto'));
    },
    get onsenYadoAreaGifu() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaGifu'));
    },
    get onsenYadoAreaTowadakoHachimantai() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaTowadakoHachimantai'));
    },
    get onsenYadoAreaKumanoHongu() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaKumanoHongu'));
    },
    get onsenYadoAreaSapporoJozankei() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaSapporoJozankei'));
    },
    get onsenYadoAreaHawai() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaHawai'));
    },
    get onsenYadoAreaIbusuki() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaIbusuki'));
    },
    get onsenYadoAreaMiyagiZao() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaMiyagiZao'));
    },
    get onsenYadoAreaHiroshimaMiyajima() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaHiroshimaMiyajima'));
    },
    get onsenYadoAreaAkayu() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaAkayu'));
    },
    get onsenYadoAreaSuwako() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaSuwako'));
    },
    get onsenYadoAreaAwara() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaAwara'));
    },
    get onsenYadoAreaUnazuki() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaUnazuki'));
    },
    get onsenYadoAreaBeppu() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaBeppu'));
    },
    get onsenYadoAreaKochiCity() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaKochiCity'));
    },
    get onsenYadoAreaKotohira() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaKotohira'));
    },
    get onsenYadoAreaKamikochiNorikura() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaKamikochiNorikura'));
    },
    get onsenYadoAreaKurokawa() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaKurokawa'));
    },
    get onsenYadoAreaHakodateYunokawa() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaHakodateYunokawa'));
    },
    get onsenYadoAreaBesshoKakeyu() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaBesshoKakeyu'));
    },
    get onsenYadoAreaAtami() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaAtami'));
    },
    get onsenYadoAreaDogo() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaDogo'));
    },
    get onsenYadoAreaNoboribetsu() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaNoboribetsu'));
    },
    get onsenYadoRibbonsDinnerRibbonEarlyBird2x() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoRibbonsDinnerRibbonEarlyBird2x'));
    },
    get onsenYadoRibbonsDinnerRibbonRecommended2x() {
      return addOriginForCDN(
        detectAssetData('images', 'onsenYadoRibbonsDinnerRibbonRecommended2x'),
      );
    },
    get onsenYadoRibbonsDinnerRibbonStandard() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoRibbonsDinnerRibbonStandard'));
    },
    get onsenYadoRibbonsDinnerRibbonRecommended() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoRibbonsDinnerRibbonRecommended'));
    },
    get onsenYadoRibbonsDinnerRibbonStandard2x() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoRibbonsDinnerRibbonStandard2x'));
    },
    get onsenYadoRibbonsDinnerRibbonEarlyBird() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoRibbonsDinnerRibbonEarlyBird'));
    },
    get onsenYadoCustomerContact() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoCustomerContact'));
    },
    get onsenYadoLpTopPickup() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoLpTopPickup'));
    },
    get onsenYadoLpFirstViewWavePc() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoLpFirstViewWavePc'));
    },
    get onsenYadoLpArrow() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoLpArrow'));
    },
    get onsenYadoLpRightArrow() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoLpRightArrow'));
    },
    get onsenYadoLpLeftArrow() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoLpLeftArrow'));
    },
    get onsenYadoLpGoToTop() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoLpGoToTop'));
    },
    get onsenYadoLpEvaluation() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoLpEvaluation'));
    },
    get onsenYadoLpTopDinner() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoLpTopDinner'));
    },
    get onsenYadoLpSupport() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoLpSupport'));
    },
    get onsenYadoLpTopPrivateBath() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoLpTopPrivateBath'));
    },
    get onsenYadoLpCover() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoLpCover'));
    },
    get onsenYadoLpSupportLpKeyVisualSp() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoLpSupportLpKeyVisualSp'));
    },
    get onsenYadoLpOg() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoLpOg'));
    },
    get onsenYadoLpFirstViewWaveSp() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoLpFirstViewWaveSp'));
    },
    get onsenYadoLpGoDown() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoLpGoDown'));
    },
    get onsenYadoLpPjtGoToTop() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoLpPjtGoToTop'));
    },
    get onsenYadoLpSupportLpKeyVisual() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoLpSupportLpKeyVisual'));
    },
    get onsenYadoLpHeart() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoLpHeart'));
    },
    get onsenYadoCommentEnd() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoCommentEnd'));
    },
    get onsenYadoAreaCoverDefault() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaCoverDefault'));
    },
    get onsenYadoWechatProdQrcode() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoWechatProdQrcode'));
    },
    get onsenYadoWechatStgQrcode() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoWechatStgQrcode'));
    },
    get onsenYadoWechatWechatIcon() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoWechatWechatIcon'));
    },
    get onsenYadoWechatSaruonsen() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoWechatSaruonsen'));
    },
    get onsenYadoAreaCoverWamazing() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoAreaCoverWamazing'));
    },
    get onsenYadoCommentStart() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoCommentStart'));
    },
    get onsenYadoRegionHokkaido() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoRegionHokkaido'));
    },
    get onsenYadoRegionChugoku() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoRegionChugoku'));
    },
    get onsenYadoRegionChubu() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoRegionChubu'));
    },
    get onsenYadoRegionKanto() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoRegionKanto'));
    },
    get onsenYadoRegionShikoku() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoRegionShikoku'));
    },
    get onsenYadoRegionKyushu() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoRegionKyushu'));
    },
    get onsenYadoRegionKansai() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoRegionKansai'));
    },
    get onsenYadoRegionTohoku() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoRegionTohoku'));
    },
    get onsenYadoOgDefault() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoOgDefault'));
    },
    get onsenYadoCrossSellingInsuranceImportantMattersSompo1() {
      return addOriginForCDN(
        detectAssetData('images', 'onsenYadoCrossSellingInsuranceImportantMattersSompo1'),
      );
    },
    get onsenYadoCrossSellingInsuranceImportantMattersSompo2() {
      return addOriginForCDN(
        detectAssetData('images', 'onsenYadoCrossSellingInsuranceImportantMattersSompo2'),
      );
    },
    get onsenYadoOrderProgressUserActive() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoOrderProgressUserActive'));
    },
    get onsenYadoOrderProgressCompleteInactive() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoOrderProgressCompleteInactive'));
    },
    get onsenYadoOrderProgressCompleteActive() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoOrderProgressCompleteActive'));
    },
    get onsenYadoOrderProgressBusInactive() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoOrderProgressBusInactive'));
    },
    get onsenYadoOrderProgressCardActive() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoOrderProgressCardActive'));
    },
    get onsenYadoOrderProgressCardInactive() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoOrderProgressCardInactive'));
    },
    get onsenYadoOrderProgressBusActive() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoOrderProgressBusActive'));
    },
    get onsenYadoOrderProgressUserInactive() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoOrderProgressUserInactive'));
    },
    get onsenYadoIconToiletMarkerGray() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoIconToiletMarkerGray'));
    },
    get onsenYadoIconPjtChatBubble() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoIconPjtChatBubble'));
    },
    get onsenYadoIconPjtMealsBlue() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoIconPjtMealsBlue'));
    },
    get onsenYadoIconPjtBenefitstag() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoIconPjtBenefitstag'));
    },
    get onsenYadoIconPjtBenefitstagBrown() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoIconPjtBenefitstagBrown'));
    },
    get onsenYadoIconPjtBusBlue() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoIconPjtBusBlue'));
    },
    get onsenYadoIconPjtBathBlue() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoIconPjtBathBlue'));
    },
    get onsenYadoIconPjtShieldBlue() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoIconPjtShieldBlue'));
    },
    get onsenYadoIconPjtWifiBlue() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoIconPjtWifiBlue'));
    },
    get onsenYadoIconPjtClockBlue() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoIconPjtClockBlue'));
    },
    get onsenYadoIconImageGalleryRightArrow() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoIconImageGalleryRightArrow'));
    },
    get onsenYadoIconSanitizerGreen() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoIconSanitizerGreen'));
    },
    get onsenYadoIconChatBubble() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoIconChatBubble'));
    },
    get onsenYadoIconMealsWhite() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoIconMealsWhite'));
    },
    get onsenYadoIconShieldWhite() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoIconShieldWhite'));
    },
    get onsenYadoIconMealsGreen() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoIconMealsGreen'));
    },
    get onsenYadoIconImageGalleryLeftArrow() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoIconImageGalleryLeftArrow'));
    },
    get onsenYadoIconSanitizerBlue() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoIconSanitizerBlue'));
    },
    get onsenYadoIconClockWhite() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoIconClockWhite'));
    },
    get onsenYadoIconMealsBlue() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoIconMealsBlue'));
    },
    get onsenYadoIconMealFlagRed() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoIconMealFlagRed'));
    },
    get onsenYadoIconMealFlagBlue() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoIconMealFlagBlue'));
    },
    get onsenYadoIconCheckGreen() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoIconCheckGreen'));
    },
    get onsenYadoIconNoSmokingGray() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoIconNoSmokingGray'));
    },
    get onsenYadoIconBathGreen() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoIconBathGreen'));
    },
    get onsenYadoIconMoonWhite() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoIconMoonWhite'));
    },
    get onsenYadoIconWifiGray() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoIconWifiGray'));
    },
    get onsenYadoIconBusBlue() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoIconBusBlue'));
    },
    get onsenYadoIconBedGray() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoIconBedGray'));
    },
    get onsenYadoIconBathTubGray() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoIconBathTubGray'));
    },
    get onsenYadoIconWifiWhite() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoIconWifiWhite'));
    },
    get onsenYadoIconBusWhite() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoIconBusWhite'));
    },
    get onsenYadoIconBreakfastDinnerWhite() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoIconBreakfastDinnerWhite'));
    },
    get onsenYadoIconBathBlue() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoIconBathBlue'));
    },
    get onsenYadoIconShieldBlue() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoIconShieldBlue'));
    },
    get onsenYadoIconWifiBlue() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoIconWifiBlue'));
    },
    get onsenYadoIconBathWhite() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoIconBathWhite'));
    },
    get onsenYadoIconClockBlue() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoIconClockBlue'));
    },
    get onsenYadoInnerBathIcon() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoInnerBathIcon'));
    },
    get onsenYadoPromoThumb62x() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoPromoThumb62x'));
    },
    get onsenYadoPromoThumb43x() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoPromoThumb43x'));
    },
    get onsenYadoPromoPhoto22x() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoPromoPhoto22x'));
    },
    get onsenYadoPromoShield() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoPromoShield'));
    },
    get onsenYadoPromoTendo() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoPromoTendo'));
    },
    get onsenYadoPromo202003Number1() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoPromo202003Number1'));
    },
    get onsenYadoPromo202003SmileIcon() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoPromo202003SmileIcon'));
    },
    get onsenYadoPromo202003KeyVisualSp() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoPromo202003KeyVisualSp'));
    },
    get onsenYadoPromo202003FollowLeftDecoration() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoPromo202003FollowLeftDecoration'));
    },
    get onsenYadoPromo202003Number2() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoPromo202003Number2'));
    },
    get onsenYadoPromo202003FollowRightManyDecoration() {
      return addOriginForCDN(
        detectAssetData('images', 'onsenYadoPromo202003FollowRightManyDecoration'),
      );
    },
    get onsenYadoPromo202003Number3() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoPromo202003Number3'));
    },
    get onsenYadoPromo202003Characteristic2Pc() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoPromo202003Characteristic2Pc'));
    },
    get onsenYadoPromo202003Envelope() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoPromo202003Envelope'));
    },
    get onsenYadoPromo202003Qrcode() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoPromo202003Qrcode'));
    },
    get onsenYadoPromo202003JapanMapIcon() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoPromo202003JapanMapIcon'));
    },
    get onsenYadoPromo202003Characteristic1Sp() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoPromo202003Characteristic1Sp'));
    },
    get onsenYadoPromo202003JapanFlagIcon() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoPromo202003JapanFlagIcon'));
    },
    get onsenYadoPromo202003FollowLeftManyDecoration() {
      return addOriginForCDN(
        detectAssetData('images', 'onsenYadoPromo202003FollowLeftManyDecoration'),
      );
    },
    get onsenYadoPromo202003TicketIcon() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoPromo202003TicketIcon'));
    },
    get onsenYadoPromo202003CardIcon() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoPromo202003CardIcon'));
    },
    get onsenYadoPromo202003EnvelopeLeftDecoration() {
      return addOriginForCDN(
        detectAssetData('images', 'onsenYadoPromo202003EnvelopeLeftDecoration'),
      );
    },
    get onsenYadoPromo202003Characteristic3Sp() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoPromo202003Characteristic3Sp'));
    },
    get onsenYadoPromo202003KeyVisualLabelSp() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoPromo202003KeyVisualLabelSp'));
    },
    get onsenYadoPromo202003Characteristic1Pc() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoPromo202003Characteristic1Pc'));
    },
    get onsenYadoPromo202003EnvelopeRightDecoration() {
      return addOriginForCDN(
        detectAssetData('images', 'onsenYadoPromo202003EnvelopeRightDecoration'),
      );
    },
    get onsenYadoPromo202003HandshakeIcon() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoPromo202003HandshakeIcon'));
    },
    get onsenYadoPromo202003KeyVisualLabelPc() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoPromo202003KeyVisualLabelPc'));
    },
    get onsenYadoPromo202003Characteristic3Pc() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoPromo202003Characteristic3Pc'));
    },
    get onsenYadoPromo202003MaleIcon() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoPromo202003MaleIcon'));
    },
    get onsenYadoPromo202003KeyVisualPc() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoPromo202003KeyVisualPc'));
    },
    get onsenYadoPromo202003FemaleIcon() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoPromo202003FemaleIcon'));
    },
    get onsenYadoPromo202003Characteristic2Sp() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoPromo202003Characteristic2Sp'));
    },
    get onsenYadoPromo202003FollowRightDecoration() {
      return addOriginForCDN(
        detectAssetData('images', 'onsenYadoPromo202003FollowRightDecoration'),
      );
    },
    get onsenYadoPromoPhoto23x() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoPromoPhoto23x'));
    },
    get onsenYadoPromoThumb42x() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoPromoThumb42x'));
    },
    get onsenYadoPromoThumb63x() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoPromoThumb63x'));
    },
    get onsenYadoPromoPhoto63x() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoPromoPhoto63x'));
    },
    get onsenYadoPromoTop3x() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoPromoTop3x'));
    },
    get onsenYadoPromoPhoto42x() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoPromoPhoto42x'));
    },
    get onsenYadoPromoThumb23x() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoPromoThumb23x'));
    },
    get onsenYadoPromoTop22x() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoPromoTop22x'));
    },
    get onsenYadoPromoBnrPc2x() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoPromoBnrPc2x'));
    },
    get onsenYadoPromoBnrPc3x() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoPromoBnrPc3x'));
    },
    get onsenYadoPromoNiku() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoPromoNiku'));
    },
    get onsenYadoPromoFutari() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoPromoFutari'));
    },
    get onsenYadoPromoTop23x() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoPromoTop23x'));
    },
    get onsenYadoPromoThumb22x() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoPromoThumb22x'));
    },
    get onsenYadoPromoMeta() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoPromoMeta'));
    },
    get onsenYadoPromoBnrSp() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoPromoBnrSp'));
    },
    get onsenYadoPromoOnsen() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoPromoOnsen'));
    },
    get onsenYadoPromoPhoto43x() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoPromoPhoto43x'));
    },
    get onsenYadoPromoTop2x() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoPromoTop2x'));
    },
    get onsenYadoPromoZao() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoPromoZao'));
    },
    get onsenYadoPromoPhoto62x() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoPromoPhoto62x'));
    },
    get onsenYadoPromoHeart() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoPromoHeart'));
    },
    get onsenYadoPromoPhoto13x() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoPromoPhoto13x'));
    },
    get onsenYadoPromoPhoto32x() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoPromoPhoto32x'));
    },
    get onsenYadoPromoTop() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoPromoTop'));
    },
    get onsenYadoPromoTop2() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoPromoTop2'));
    },
    get onsenYadoPromoThumb53x() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoPromoThumb53x'));
    },
    get onsenYadoPromoThumb52x() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoPromoThumb52x'));
    },
    get onsenYadoPromoJapanMap() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoPromoJapanMap'));
    },
    get onsenYadoPromoPhoto33x() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoPromoPhoto33x'));
    },
    get onsenYadoPromoHeadphone() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoPromoHeadphone'));
    },
    get onsenYadoPromoPhoto12x() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoPromoPhoto12x'));
    },
    get onsenYadoPromoBnrPc() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoPromoBnrPc'));
    },
    get onsenYadoPromoThumb5() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoPromoThumb5'));
    },
    get onsenYadoPromoBnrSp2x() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoPromoBnrSp2x'));
    },
    get onsenYadoPromoPhoto3() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoPromoPhoto3'));
    },
    get onsenYadoPromoThumb12x() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoPromoThumb12x'));
    },
    get onsenYadoPromoThumb33x() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoPromoThumb33x'));
    },
    get onsenYadoPromoPhoto2() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoPromoPhoto2'));
    },
    get onsenYadoPromoThumb4() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoPromoThumb4'));
    },
    get onsenYadoPromoThumb6() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoPromoThumb6'));
    },
    get onsenYadoPromoPhoto52x() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoPromoPhoto52x'));
    },
    get onsenYadoPromoJapanMap2x() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoPromoJapanMap2x'));
    },
    get onsenYadoPromoBeppu() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoPromoBeppu'));
    },
    get onsenYadoPromoPhoto1() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoPromoPhoto1'));
    },
    get onsenYadoPromoPhoto5() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoPromoPhoto5'));
    },
    get onsenYadoPromoThumb3() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoPromoThumb3'));
    },
    get onsenYadoPromoJapanMap3x() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoPromoJapanMap3x'));
    },
    get onsenYadoPromoKurokawa() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoPromoKurokawa'));
    },
    get onsenYadoPromoBitmap() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoPromoBitmap'));
    },
    get onsenYadoPromoPhoto53x() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoPromoPhoto53x'));
    },
    get onsenYadoPromoThumb2() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoPromoThumb2'));
    },
    get onsenYadoPromoPhoto4() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoPromoPhoto4'));
    },
    get onsenYadoPromoPhoto6() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoPromoPhoto6'));
    },
    get onsenYadoPromoThumb32x() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoPromoThumb32x'));
    },
    get onsenYadoPromoThumb13x() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoPromoThumb13x'));
    },
    get onsenYadoPromoThumb1() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoPromoThumb1'));
    },
    get onsenYadoPromoBnrSp3x() {
      return addOriginForCDN(detectAssetData('images', 'onsenYadoPromoBnrSp3x'));
    },
    get newTopGalleryKisetsuBannerHorizontal() {
      return addOriginForCDN(detectAssetData('images', 'newTopGalleryKisetsuBannerHorizontal'));
    },
    get newTopGalleryPrivateBathHorizontal() {
      return addOriginForCDN(detectAssetData('images', 'newTopGalleryPrivateBathHorizontal'));
    },
    get newTopGallerySnowOnsenHorizontal() {
      return addOriginForCDN(detectAssetData('images', 'newTopGallerySnowOnsenHorizontal'));
    },
    get newTopGalleryLimitedPlan202404() {
      return addOriginForCDN(detectAssetData('images', 'newTopGalleryLimitedPlan202404'));
    },
    get newTopGalleryYufuin() {
      return addOriginForCDN(detectAssetData('images', 'newTopGalleryYufuin'));
    },
    get newTopGalleryKusatsu() {
      return addOriginForCDN(detectAssetData('images', 'newTopGalleryKusatsu'));
    },
    get newTopKeyvisualAccommodation2() {
      return addOriginForCDN(detectAssetData('images', 'newTopKeyvisualAccommodation2'));
    },
    get newTopKeyvisualAccommodation3() {
      return addOriginForCDN(detectAssetData('images', 'newTopKeyvisualAccommodation3'));
    },
    get newTopKeyvisualAccommodation1() {
      return addOriginForCDN(detectAssetData('images', 'newTopKeyvisualAccommodation1'));
    },
    get newTopKeyvisualAccommodation4() {
      return addOriginForCDN(detectAssetData('images', 'newTopKeyvisualAccommodation4'));
    },
    get newTopKeyvisualAccommodation5() {
      return addOriginForCDN(detectAssetData('images', 'newTopKeyvisualAccommodation5'));
    },
    get activity206() {
      return addOriginForCDN(detectAssetData('images', 'activity206'));
    },
    get activity207() {
      return addOriginForCDN(detectAssetData('images', 'activity207'));
    },
    get activity208() {
      return addOriginForCDN(detectAssetData('images', 'activity208'));
    },
    get activity182() {
      return addOriginForCDN(detectAssetData('images', 'activity182'));
    },
    get activity146() {
      return addOriginForCDN(detectAssetData('images', 'activity146'));
    },
    get aboutIntropickup1() {
      return addOriginForCDN(detectAssetData('images', 'aboutIntropickup1'));
    },
    get aboutIntrotop2() {
      return addOriginForCDN(detectAssetData('images', 'aboutIntrotop2'));
    },
    get aboutAbouttop() {
      return addOriginForCDN(detectAssetData('images', 'aboutAbouttop'));
    },
    get aboutIntropickup2() {
      return addOriginForCDN(detectAssetData('images', 'aboutIntropickup2'));
    },
    get aboutIntrotop1() {
      return addOriginForCDN(detectAssetData('images', 'aboutIntrotop1'));
    },
    get aboutMealdiningroom() {
      return addOriginForCDN(detectAssetData('images', 'aboutMealdiningroom'));
    },
    get aboutIntroonsen2() {
      return addOriginForCDN(detectAssetData('images', 'aboutIntroonsen2'));
    },
    get aboutMealjapanese() {
      return addOriginForCDN(detectAssetData('images', 'aboutMealjapanese'));
    },
    get aboutIntroonsen1() {
      return addOriginForCDN(detectAssetData('images', 'aboutIntroonsen1'));
    },
    get aboutIntrotop2Sp() {
      return addOriginForCDN(detectAssetData('images', 'aboutIntrotop2Sp'));
    },
    get aboutMealeclectic() {
      return addOriginForCDN(detectAssetData('images', 'aboutMealeclectic'));
    },
    get aboutOnsentype1() {
      return addOriginForCDN(detectAssetData('images', 'aboutOnsentype1'));
    },
    get aboutIntroonsen() {
      return addOriginForCDN(detectAssetData('images', 'aboutIntroonsen'));
    },
    get aboutOnsentype2() {
      return addOriginForCDN(detectAssetData('images', 'aboutOnsentype2'));
    },
    get aboutArrowright() {
      return addOriginForCDN(detectAssetData('images', 'aboutArrowright'));
    },
    get aboutMealbuffet() {
      return addOriginForCDN(detectAssetData('images', 'aboutMealbuffet'));
    },
    get aboutIntromeal() {
      return addOriginForCDN(detectAssetData('images', 'aboutIntromeal'));
    },
    get aboutArrowrightwhite() {
      return addOriginForCDN(detectAssetData('images', 'aboutArrowrightwhite'));
    },
    get aboutIntromeal2() {
      return addOriginForCDN(detectAssetData('images', 'aboutIntromeal2'));
    },
    get aboutMealrequest2() {
      return addOriginForCDN(detectAssetData('images', 'aboutMealrequest2'));
    },
    get aboutMealitalian() {
      return addOriginForCDN(detectAssetData('images', 'aboutMealitalian'));
    },
    get aboutOnsenrequest1() {
      return addOriginForCDN(detectAssetData('images', 'aboutOnsenrequest1'));
    },
    get aboutIntrorecombelt() {
      return addOriginForCDN(detectAssetData('images', 'aboutIntrorecombelt'));
    },
    get aboutIntroroomsearch() {
      return addOriginForCDN(detectAssetData('images', 'aboutIntroroomsearch'));
    },
    get aboutQicon() {
      return addOriginForCDN(detectAssetData('images', 'aboutQicon'));
    },
    get aboutMealprivateroom() {
      return addOriginForCDN(detectAssetData('images', 'aboutMealprivateroom'));
    },
    get aboutMealheader() {
      return addOriginForCDN(detectAssetData('images', 'aboutMealheader'));
    },
    get aboutOnsenrequest3() {
      return addOriginForCDN(detectAssetData('images', 'aboutOnsenrequest3'));
    },
    get aboutPickupdesc() {
      return addOriginForCDN(detectAssetData('images', 'aboutPickupdesc'));
    },
    get aboutIntromeal1() {
      return addOriginForCDN(detectAssetData('images', 'aboutIntromeal1'));
    },
    get aboutMealrequest1() {
      return addOriginForCDN(detectAssetData('images', 'aboutMealrequest1'));
    },
    get aboutOnsenrequest2() {
      return addOriginForCDN(detectAssetData('images', 'aboutOnsenrequest2'));
    },
    get aboutServicelistpickup() {
      return addOriginForCDN(detectAssetData('images', 'aboutServicelistpickup'));
    },
    get aboutIntrorecom1() {
      return addOriginForCDN(detectAssetData('images', 'aboutIntrorecom1'));
    },
    get aboutAicon() {
      return addOriginForCDN(detectAssetData('images', 'aboutAicon'));
    },
    get aboutIntromeal2sp() {
      return addOriginForCDN(detectAssetData('images', 'aboutIntromeal2sp'));
    },
    get aboutIntrorecom2() {
      return addOriginForCDN(detectAssetData('images', 'aboutIntrorecom2'));
    },
    get aboutIntrorecom3() {
      return addOriginForCDN(detectAssetData('images', 'aboutIntrorecom3'));
    },
    get aboutStepsPickupRequestAnser2Sp() {
      return addOriginForCDN(detectAssetData('images', 'aboutStepsPickupRequestAnser2Sp'));
    },
    get aboutStepsPickupHowTo13Sp() {
      return addOriginForCDN(detectAssetData('images', 'aboutStepsPickupHowTo13Sp'));
    },
    get aboutStepsOnsenHowTo1Pc() {
      return addOriginForCDN(detectAssetData('images', 'aboutStepsOnsenHowTo1Pc'));
    },
    get aboutStepsPickupHowTo11Sp() {
      return addOriginForCDN(detectAssetData('images', 'aboutStepsPickupHowTo11Sp'));
    },
    get aboutStepsOnsenRequestAnser2Pc() {
      return addOriginForCDN(detectAssetData('images', 'aboutStepsOnsenRequestAnser2Pc'));
    },
    get aboutStepsMealHowTo2Sp() {
      return addOriginForCDN(detectAssetData('images', 'aboutStepsMealHowTo2Sp'));
    },
    get aboutStepsPickupHowTo22Sp() {
      return addOriginForCDN(detectAssetData('images', 'aboutStepsPickupHowTo22Sp'));
    },
    get aboutStepsPickupHowTo32Sp() {
      return addOriginForCDN(detectAssetData('images', 'aboutStepsPickupHowTo32Sp'));
    },
    get aboutStepsMealRequestAnser2Pc() {
      return addOriginForCDN(detectAssetData('images', 'aboutStepsMealRequestAnser2Pc'));
    },
    get aboutStepsPickupHowTo12Pc() {
      return addOriginForCDN(detectAssetData('images', 'aboutStepsPickupHowTo12Pc'));
    },
    get aboutStepsOnsenRequestAnser1Sp() {
      return addOriginForCDN(detectAssetData('images', 'aboutStepsOnsenRequestAnser1Sp'));
    },
    get aboutStepsMealHowTo1Pc() {
      return addOriginForCDN(detectAssetData('images', 'aboutStepsMealHowTo1Pc'));
    },
    get aboutStepsPickupHowTo31Pc() {
      return addOriginForCDN(detectAssetData('images', 'aboutStepsPickupHowTo31Pc'));
    },
    get aboutStepsPickupHowTo21Pc() {
      return addOriginForCDN(detectAssetData('images', 'aboutStepsPickupHowTo21Pc'));
    },
    get aboutStepsMealRequestAnser1Sp() {
      return addOriginForCDN(detectAssetData('images', 'aboutStepsMealRequestAnser1Sp'));
    },
    get aboutStepsPickupRequestAnser3Pc() {
      return addOriginForCDN(detectAssetData('images', 'aboutStepsPickupRequestAnser3Pc'));
    },
    get aboutStepsPickupRequestAnser1Pc() {
      return addOriginForCDN(detectAssetData('images', 'aboutStepsPickupRequestAnser1Pc'));
    },
    get aboutStepsPickupHowTo33Pc() {
      return addOriginForCDN(detectAssetData('images', 'aboutStepsPickupHowTo33Pc'));
    },
    get aboutStepsPickupHowTo23Pc() {
      return addOriginForCDN(detectAssetData('images', 'aboutStepsPickupHowTo23Pc'));
    },
    get aboutStepsOnsenHowTo2Sp() {
      return addOriginForCDN(detectAssetData('images', 'aboutStepsOnsenHowTo2Sp'));
    },
    get aboutStepsPickupHowTo12Sp() {
      return addOriginForCDN(detectAssetData('images', 'aboutStepsPickupHowTo12Sp'));
    },
    get aboutStepsMealRequestAnser1Pc() {
      return addOriginForCDN(detectAssetData('images', 'aboutStepsMealRequestAnser1Pc'));
    },
    get aboutStepsPickupRequestAnser3Sp() {
      return addOriginForCDN(detectAssetData('images', 'aboutStepsPickupRequestAnser3Sp'));
    },
    get aboutStepsPickupHowTo31Sp() {
      return addOriginForCDN(detectAssetData('images', 'aboutStepsPickupHowTo31Sp'));
    },
    get aboutStepsPickupHowTo21Sp() {
      return addOriginForCDN(detectAssetData('images', 'aboutStepsPickupHowTo21Sp'));
    },
    get aboutStepsMealHowTo1Sp() {
      return addOriginForCDN(detectAssetData('images', 'aboutStepsMealHowTo1Sp'));
    },
    get aboutStepsOnsenRequestAnser1Pc() {
      return addOriginForCDN(detectAssetData('images', 'aboutStepsOnsenRequestAnser1Pc'));
    },
    get aboutStepsPickupHowTo33Sp() {
      return addOriginForCDN(detectAssetData('images', 'aboutStepsPickupHowTo33Sp'));
    },
    get aboutStepsPickupHowTo23Sp() {
      return addOriginForCDN(detectAssetData('images', 'aboutStepsPickupHowTo23Sp'));
    },
    get aboutStepsPickupRequestAnser1Sp() {
      return addOriginForCDN(detectAssetData('images', 'aboutStepsPickupRequestAnser1Sp'));
    },
    get aboutStepsOnsenHowTo2Pc() {
      return addOriginForCDN(detectAssetData('images', 'aboutStepsOnsenHowTo2Pc'));
    },
    get aboutStepsRenameHowto() {
      return addOriginForCDN(detectAssetData('images', 'aboutStepsRenameHowto'));
    },
    get aboutStepsPickupRequestAnser2Pc() {
      return addOriginForCDN(detectAssetData('images', 'aboutStepsPickupRequestAnser2Pc'));
    },
    get aboutStepsOnsenHowTo1Sp() {
      return addOriginForCDN(detectAssetData('images', 'aboutStepsOnsenHowTo1Sp'));
    },
    get aboutStepsPickupHowTo13Pc() {
      return addOriginForCDN(detectAssetData('images', 'aboutStepsPickupHowTo13Pc'));
    },
    get aboutStepsPickupHowTo11Pc() {
      return addOriginForCDN(detectAssetData('images', 'aboutStepsPickupHowTo11Pc'));
    },
    get aboutStepsMealRequestAnser2Sp() {
      return addOriginForCDN(detectAssetData('images', 'aboutStepsMealRequestAnser2Sp'));
    },
    get aboutStepsPickupHowTo22Pc() {
      return addOriginForCDN(detectAssetData('images', 'aboutStepsPickupHowTo22Pc'));
    },
    get aboutStepsPickupHowTo32Pc() {
      return addOriginForCDN(detectAssetData('images', 'aboutStepsPickupHowTo32Pc'));
    },
    get aboutStepsMealHowTo2Pc() {
      return addOriginForCDN(detectAssetData('images', 'aboutStepsMealHowTo2Pc'));
    },
    get aboutStepsOnsenRequestAnser2Sp() {
      return addOriginForCDN(detectAssetData('images', 'aboutStepsOnsenRequestAnser2Sp'));
    },
    get aboutOnsenheader() {
      return addOriginForCDN(detectAssetData('images', 'aboutOnsenheader'));
    },
    get aboutIntrorecom4() {
      return addOriginForCDN(detectAssetData('images', 'aboutIntrorecom4'));
    },
    get aboutIntrorecom5() {
      return addOriginForCDN(detectAssetData('images', 'aboutIntrorecom5'));
    },
    get aboutIntropickup() {
      return addOriginForCDN(detectAssetData('images', 'aboutIntropickup'));
    },
    get aboutIntrobackgroundpattern() {
      return addOriginForCDN(detectAssetData('images', 'aboutIntrobackgroundpattern'));
    },
    get aboutPickupheader() {
      return addOriginForCDN(detectAssetData('images', 'aboutPickupheader'));
    },
    get aboutMealfrench() {
      return addOriginForCDN(detectAssetData('images', 'aboutMealfrench'));
    },
    get aboutMealchinese() {
      return addOriginForCDN(detectAssetData('images', 'aboutMealchinese'));
    },
    get aboutMealdesc() {
      return addOriginForCDN(detectAssetData('images', 'aboutMealdesc'));
    },
    get aboutOnsendesc() {
      return addOriginForCDN(detectAssetData('images', 'aboutOnsendesc'));
    },
    get aboutMealrestaurant() {
      return addOriginForCDN(detectAssetData('images', 'aboutMealrestaurant'));
    },
    get aboutPickuprequest1() {
      return addOriginForCDN(detectAssetData('images', 'aboutPickuprequest1'));
    },
    get aboutIntropickup2sp() {
      return addOriginForCDN(detectAssetData('images', 'aboutIntropickup2sp'));
    },
    get aboutPickuprequest3() {
      return addOriginForCDN(detectAssetData('images', 'aboutPickuprequest3'));
    },
    get aboutPickuprequest2() {
      return addOriginForCDN(detectAssetData('images', 'aboutPickuprequest2'));
    },
    get commonStarPink() {
      return addOriginForCDN(detectAssetData('images', 'commonStarPink'));
    },
    get commonHeaderAccountIcon() {
      return addOriginForCDN(detectAssetData('images', 'commonHeaderAccountIcon'));
    },
    get commonPayLaterCardTop() {
      return addOriginForCDN(detectAssetData('images', 'commonPayLaterCardTop'));
    },
    get commonAfteeLogo() {
      return addOriginForCDN(detectAssetData('images', 'commonAfteeLogo'));
    },
    get commonFlag() {
      return addOriginForCDN(detectAssetData('images', 'commonFlag'));
    },
    get commonCityMap() {
      return addOriginForCDN(detectAssetData('images', 'commonCityMap'));
    },
    get commonLogoWamazing() {
      return addOriginForCDN(detectAssetData('images', 'commonLogoWamazing'));
    },
    get commonCreditCardVisa() {
      return addOriginForCDN(detectAssetData('images', 'commonCreditCardVisa'));
    },
    get commonCreditCardMaster() {
      return addOriginForCDN(detectAssetData('images', 'commonCreditCardMaster'));
    },
    get commonCreditCardCvv() {
      return addOriginForCDN(detectAssetData('images', 'commonCreditCardCvv'));
    },
    get commonCreditCardDiners() {
      return addOriginForCDN(detectAssetData('images', 'commonCreditCardDiners'));
    },
    get commonCreditCardCreditCardSample() {
      return addOriginForCDN(detectAssetData('images', 'commonCreditCardCreditCardSample'));
    },
    get commonCreditCardUc() {
      return addOriginForCDN(detectAssetData('images', 'commonCreditCardUc'));
    },
    get commonCreditCardAmericanExpress() {
      return addOriginForCDN(detectAssetData('images', 'commonCreditCardAmericanExpress'));
    },
    get commonCreditCardWechatpay() {
      return addOriginForCDN(detectAssetData('images', 'commonCreditCardWechatpay'));
    },
    get commonCreditCardUnionpay() {
      return addOriginForCDN(detectAssetData('images', 'commonCreditCardUnionpay'));
    },
    get commonCreditCardMastercard() {
      return addOriginForCDN(detectAssetData('images', 'commonCreditCardMastercard'));
    },
    get commonCreditCardAmex() {
      return addOriginForCDN(detectAssetData('images', 'commonCreditCardAmex'));
    },
    get commonCreditCardJcb() {
      return addOriginForCDN(detectAssetData('images', 'commonCreditCardJcb'));
    },
    get commonCreditCardAlipay() {
      return addOriginForCDN(detectAssetData('images', 'commonCreditCardAlipay'));
    },
    get commonCreditCardIccard() {
      return addOriginForCDN(detectAssetData('images', 'commonCreditCardIccard'));
    },
    get commonCreditCardLinepay() {
      return addOriginForCDN(detectAssetData('images', 'commonCreditCardLinepay'));
    },
    get commonCreditCardDc() {
      return addOriginForCDN(detectAssetData('images', 'commonCreditCardDc'));
    },
    get commonSnsFacebook() {
      return addOriginForCDN(detectAssetData('images', 'commonSnsFacebook'));
    },
    get commonLogoJrWest() {
      return addOriginForCDN(detectAssetData('images', 'commonLogoJrWest'));
    },
    get commonSnsInstagram() {
      return addOriginForCDN(detectAssetData('images', 'commonSnsInstagram'));
    },
    get commonLineSquare() {
      return addOriginForCDN(detectAssetData('images', 'commonLineSquare'));
    },
    get commonInfoWhite() {
      return addOriginForCDN(detectAssetData('images', 'commonInfoWhite'));
    },
    get commonChatBubbleLgBlue() {
      return addOriginForCDN(detectAssetData('images', 'commonChatBubbleLgBlue'));
    },
    get commonAppStoreStoreApple() {
      return addOriginForCDN(detectAssetData('images', 'commonAppStoreStoreApple'));
    },
    get commonAppStoreStoreAndroid() {
      return addOriginForCDN(detectAssetData('images', 'commonAppStoreStoreAndroid'));
    },
    get commonArrowBlueUp() {
      return addOriginForCDN(detectAssetData('images', 'commonArrowBlueUp'));
    },
    get commonAddLightBlue() {
      return addOriginForCDN(detectAssetData('images', 'commonAddLightBlue'));
    },
    get commonChatBubbleLg() {
      return addOriginForCDN(detectAssetData('images', 'commonChatBubbleLg'));
    },
    get commonMapMarkerGrayThin() {
      return addOriginForCDN(detectAssetData('images', 'commonMapMarkerGrayThin'));
    },
    get commonRightArrow() {
      return addOriginForCDN(detectAssetData('images', 'commonRightArrow'));
    },
    get commonLoading() {
      return addOriginForCDN(detectAssetData('images', 'commonLoading'));
    },
    get commonArrowBlueRight() {
      return addOriginForCDN(detectAssetData('images', 'commonArrowBlueRight'));
    },
    get commonMapMarker() {
      return addOriginForCDN(detectAssetData('images', 'commonMapMarker'));
    },
    get commonLeftArrow() {
      return addOriginForCDN(detectAssetData('images', 'commonLeftArrow'));
    },
    get commonQuestionnaire() {
      return addOriginForCDN(detectAssetData('images', 'commonQuestionnaire'));
    },
    get commonPlaceholderWhite() {
      return addOriginForCDN(detectAssetData('images', 'commonPlaceholderWhite'));
    },
    get commonOpenInNewTab() {
      return addOriginForCDN(detectAssetData('images', 'commonOpenInNewTab'));
    },
    get commonPlaceholderGray() {
      return addOriginForCDN(detectAssetData('images', 'commonPlaceholderGray'));
    },
    get commonStarWhite() {
      return addOriginForCDN(detectAssetData('images', 'commonStarWhite'));
    },
    get commonAddBlue() {
      return addOriginForCDN(detectAssetData('images', 'commonAddBlue'));
    },
    get commonHelpBlue() {
      return addOriginForCDN(detectAssetData('images', 'commonHelpBlue'));
    },
    get commonLogoJrEast() {
      return addOriginForCDN(detectAssetData('images', 'commonLogoJrEast'));
    },
    get commonPaymentMethodCreditCard() {
      return addOriginForCDN(detectAssetData('images', 'commonPaymentMethodCreditCard'));
    },
    get commonPaymentMethodMobilePay() {
      return addOriginForCDN(detectAssetData('images', 'commonPaymentMethodMobilePay'));
    },
    get commonMapMarkerGray() {
      return addOriginForCDN(detectAssetData('images', 'commonMapMarkerGray'));
    },
    get commonArrowBlueDown() {
      return addOriginForCDN(detectAssetData('images', 'commonArrowBlueDown'));
    },
    get commonDownArrow() {
      return addOriginForCDN(detectAssetData('images', 'commonDownArrow'));
    },
    get commonStartDarkYellow() {
      return addOriginForCDN(detectAssetData('images', 'commonStartDarkYellow'));
    },
    get commonCloseBtnGray() {
      return addOriginForCDN(detectAssetData('images', 'commonCloseBtnGray'));
    },
    get commonSelectTriangles() {
      return addOriginForCDN(detectAssetData('images', 'commonSelectTriangles'));
    },
    get commonModalCloseButtonWhite() {
      return addOriginForCDN(detectAssetData('images', 'commonModalCloseButtonWhite'));
    },
    get commonWechatpay() {
      return addOriginForCDN(detectAssetData('images', 'commonWechatpay'));
    },
    get commonCloseIcon() {
      return addOriginForCDN(detectAssetData('images', 'commonCloseIcon'));
    },
    get commonNewWindowLink() {
      return addOriginForCDN(detectAssetData('images', 'commonNewWindowLink'));
    },
    get commonBenefit() {
      return addOriginForCDN(detectAssetData('images', 'commonBenefit'));
    },
    get commonPayLaterCard() {
      return addOriginForCDN(detectAssetData('images', 'commonPayLaterCard'));
    },
    get commonModalCloseButton() {
      return addOriginForCDN(detectAssetData('images', 'commonModalCloseButton'));
    },
    get commonLogoWamazingVertical() {
      return addOriginForCDN(detectAssetData('images', 'commonLogoWamazingVertical'));
    },
    get commonMapHotelHovered() {
      return addOriginForCDN(detectAssetData('images', 'commonMapHotelHovered'));
    },
    get commonHelpGreen() {
      return addOriginForCDN(detectAssetData('images', 'commonHelpGreen'));
    },
    get commonMapHotel() {
      return addOriginForCDN(detectAssetData('images', 'commonMapHotel'));
    },
    get commonWechatLogo() {
      return addOriginForCDN(detectAssetData('images', 'commonWechatLogo'));
    },
    get commonStarYellow() {
      return addOriginForCDN(detectAssetData('images', 'commonStarYellow'));
    },
    get commonLogoWamazingHorizontal() {
      return addOriginForCDN(detectAssetData('images', 'commonLogoWamazingHorizontal'));
    },
    get commonImageWhite() {
      return addOriginForCDN(detectAssetData('images', 'commonImageWhite'));
    },
    get commonHotelIcon() {
      return addOriginForCDN(detectAssetData('images', 'commonHotelIcon'));
    },
    get commonLogoWamazingHorizontalWhite() {
      return addOriginForCDN(detectAssetData('images', 'commonLogoWamazingHorizontalWhite'));
    },
    get commonCrownNum2() {
      return addOriginForCDN(detectAssetData('images', 'commonCrownNum2'));
    },
    get commonAirport() {
      return addOriginForCDN(detectAssetData('images', 'commonAirport'));
    },
    get commonMapMainHotel() {
      return addOriginForCDN(detectAssetData('images', 'commonMapMainHotel'));
    },
    get commonCrownNum3() {
      return addOriginForCDN(detectAssetData('images', 'commonCrownNum3'));
    },
    get commonModalCloseButtonBold() {
      return addOriginForCDN(detectAssetData('images', 'commonModalCloseButtonBold'));
    },
    get commonCrownNum1() {
      return addOriginForCDN(detectAssetData('images', 'commonCrownNum1'));
    },
    get commonMagnifyingGlass() {
      return addOriginForCDN(detectAssetData('images', 'commonMagnifyingGlass'));
    },
    get supportKvIllustSp() {
      return addOriginForCDN(detectAssetData('images', 'supportKvIllustSp'));
    },
    get supportSupportBarIllustPc() {
      return addOriginForCDN(detectAssetData('images', 'supportSupportBarIllustPc'));
    },
    get supportSupportBannerPc() {
      return addOriginForCDN(detectAssetData('images', 'supportSupportBannerPc'));
    },
    get supportSupportSubtitle() {
      return addOriginForCDN(detectAssetData('images', 'supportSupportSubtitle'));
    },
    get supportSupportBarIllustSp() {
      return addOriginForCDN(detectAssetData('images', 'supportSupportBarIllustSp'));
    },
    get supportSupportBannerSp() {
      return addOriginForCDN(detectAssetData('images', 'supportSupportBannerSp'));
    },
    get supportKeyvisual() {
      return addOriginForCDN(detectAssetData('images', 'supportKeyvisual'));
    },
    get supportQna1() {
      return addOriginForCDN(detectAssetData('images', 'supportQna1'));
    },
    get supportKeyvisualSp() {
      return addOriginForCDN(detectAssetData('images', 'supportKeyvisualSp'));
    },
    get supportSupportq() {
      return addOriginForCDN(detectAssetData('images', 'supportSupportq'));
    },
    get supportQna3() {
      return addOriginForCDN(detectAssetData('images', 'supportQna3'));
    },
    get supportQna2() {
      return addOriginForCDN(detectAssetData('images', 'supportQna2'));
    },
    get supportKvIllust() {
      return addOriginForCDN(detectAssetData('images', 'supportKvIllust'));
    },
    get supportCircleChevronYellowGreen() {
      return addOriginForCDN(detectAssetData('images', 'supportCircleChevronYellowGreen'));
    },
    get supportSupportChat1() {
      return addOriginForCDN(detectAssetData('images', 'supportSupportChat1'));
    },
    get supportNum01() {
      return addOriginForCDN(detectAssetData('images', 'supportNum01'));
    },
    get supportNum03() {
      return addOriginForCDN(detectAssetData('images', 'supportNum03'));
    },
    get supportSupportChat2() {
      return addOriginForCDN(detectAssetData('images', 'supportSupportChat2'));
    },
    get supportNum02() {
      return addOriginForCDN(detectAssetData('images', 'supportNum02'));
    },
    get supportIntroIllust() {
      return addOriginForCDN(detectAssetData('images', 'supportIntroIllust'));
    },
    get campaignActivityBannerHorizontal() {
      return addOriginForCDN(detectAssetData('images', 'campaignActivityBannerHorizontal'));
    },
    get campaignAfteeBannerHorizontal() {
      return addOriginForCDN(detectAssetData('images', 'campaignAfteeBannerHorizontal'));
    },
    get campaignAfteeBannerVertical() {
      return addOriginForCDN(detectAssetData('images', 'campaignAfteeBannerVertical'));
    },
    get campaignNissanBannerHorizontal() {
      return addOriginForCDN(detectAssetData('images', 'campaignNissanBannerHorizontal'));
    },
    get campaignClock() {
      return addOriginForCDN(detectAssetData('images', 'campaignClock'));
    },
    get countryTopLogoHorizontalPink() {
      return addOriginForCDN(detectAssetData('images', 'countryTopLogoHorizontalPink'));
    },
    get countryTopHero() {
      return addOriginForCDN(detectAssetData('images', 'countryTopHero'));
    },
    get countryTopActivityFeature01() {
      return addOriginForCDN(detectAssetData('images', 'countryTopActivityFeature01'));
    },
    get countryTopActivityFeature03() {
      return addOriginForCDN(detectAssetData('images', 'countryTopActivityFeature03'));
    },
    get countryTopActivityFeature02() {
      return addOriginForCDN(detectAssetData('images', 'countryTopActivityFeature02'));
    },
    get countryTopLogoHorizontalWhite() {
      return addOriginForCDN(detectAssetData('images', 'countryTopLogoHorizontalWhite'));
    },
    get countryTopKaimonoFeature01() {
      return addOriginForCDN(detectAssetData('images', 'countryTopKaimonoFeature01'));
    },
    get countryTopKaimonoFeature03() {
      return addOriginForCDN(detectAssetData('images', 'countryTopKaimonoFeature03'));
    },
    get countryTopKaimonoFeature02() {
      return addOriginForCDN(detectAssetData('images', 'countryTopKaimonoFeature02'));
    },
    get countryTopHeroOkinawa() {
      return addOriginForCDN(detectAssetData('images', 'countryTopHeroOkinawa'));
    },
    get countryTopTicketFeature01() {
      return addOriginForCDN(detectAssetData('images', 'countryTopTicketFeature01'));
    },
    get countryTopTicketFeature03() {
      return addOriginForCDN(detectAssetData('images', 'countryTopTicketFeature03'));
    },
    get countryTopTicketFeature02() {
      return addOriginForCDN(detectAssetData('images', 'countryTopTicketFeature02'));
    },
    get countryTopSnowFeature01() {
      return addOriginForCDN(detectAssetData('images', 'countryTopSnowFeature01'));
    },
    get countryTopSnowFeature03() {
      return addOriginForCDN(detectAssetData('images', 'countryTopSnowFeature03'));
    },
    get countryTopSnowFeature02() {
      return addOriginForCDN(detectAssetData('images', 'countryTopSnowFeature02'));
    },
    get countryTopSimFeature01() {
      return addOriginForCDN(detectAssetData('images', 'countryTopSimFeature01'));
    },
    get countryTopSimFeature03() {
      return addOriginForCDN(detectAssetData('images', 'countryTopSimFeature03'));
    },
    get countryTopSimFeature02() {
      return addOriginForCDN(detectAssetData('images', 'countryTopSimFeature02'));
    },
    get countryTopInsuranceFeature01() {
      return addOriginForCDN(detectAssetData('images', 'countryTopInsuranceFeature01'));
    },
    get countryTopInsuranceFeature03() {
      return addOriginForCDN(detectAssetData('images', 'countryTopInsuranceFeature03'));
    },
    get countryTopInsuranceFeature02() {
      return addOriginForCDN(detectAssetData('images', 'countryTopInsuranceFeature02'));
    },
    get countryTopOnsenFeature01() {
      return addOriginForCDN(detectAssetData('images', 'countryTopOnsenFeature01'));
    },
    get countryTopOnsenFeature03() {
      return addOriginForCDN(detectAssetData('images', 'countryTopOnsenFeature03'));
    },
    get countryTopOnsenFeature02() {
      return addOriginForCDN(detectAssetData('images', 'countryTopOnsenFeature02'));
    },
    get countryTopJapan() {
      return addOriginForCDN(detectAssetData('images', 'countryTopJapan'));
    },
    get countryTopMediaFeature01() {
      return addOriginForCDN(detectAssetData('images', 'countryTopMediaFeature01'));
    },
    get countryTopMediaFeature03() {
      return addOriginForCDN(detectAssetData('images', 'countryTopMediaFeature03'));
    },
    get countryTopMediaFeature02() {
      return addOriginForCDN(detectAssetData('images', 'countryTopMediaFeature02'));
    },
    get featuredLimited202312LimitedPlan() {
      return addOriginForCDN(detectAssetData('images', 'featuredLimited202312LimitedPlan'));
    },
    get featured202311NewListing2023nov() {
      return addOriginForCDN(detectAssetData('images', 'featured202311NewListing2023nov'));
    },
    get featured202311Kyoritsu() {
      return addOriginForCDN(detectAssetData('images', 'featured202311Kyoritsu'));
    },
    get featured202402NewListing2024feb() {
      return addOriginForCDN(detectAssetData('images', 'featured202402NewListing2024feb'));
    },
    get featured202403NewListing2024mar() {
      return addOriginForCDN(detectAssetData('images', 'featured202403NewListing2024mar'));
    },
    get featured202304WagyuOnsen() {
      return addOriginForCDN(detectAssetData('images', 'featured202304WagyuOnsen'));
    },
    get featured202304PhotoSpot() {
      return addOriginForCDN(detectAssetData('images', 'featured202304PhotoSpot'));
    },
    get featured202304FireworksOnsen() {
      return addOriginForCDN(detectAssetData('images', 'featured202304FireworksOnsen'));
    },
    get featured202304NewListing() {
      return addOriginForCDN(detectAssetData('images', 'featured202304NewListing'));
    },
    get featured202304LocalFood() {
      return addOriginForCDN(detectAssetData('images', 'featured202304LocalFood'));
    },
    get featured202303JapanOnsen100() {
      return addOriginForCDN(detectAssetData('images', 'featured202303JapanOnsen100'));
    },
    get featured202303Private201910() {
      return addOriginForCDN(detectAssetData('images', 'featured202303Private201910'));
    },
    get featured202303CostPerformance() {
      return addOriginForCDN(detectAssetData('images', 'featured202303CostPerformance'));
    },
    get featured202303SakuraOnsen() {
      return addOriginForCDN(detectAssetData('images', 'featured202303SakuraOnsen'));
    },
    get featured202303ChildOnsen() {
      return addOriginForCDN(detectAssetData('images', 'featured202303ChildOnsen'));
    },
    get featured202305RelaxOnsen() {
      return addOriginForCDN(detectAssetData('images', 'featured202305RelaxOnsen'));
    },
    get featured202305PoolOnsen() {
      return addOriginForCDN(detectAssetData('images', 'featured202305PoolOnsen'));
    },
    get featured202305Themepark() {
      return addOriginForCDN(detectAssetData('images', 'featured202305Themepark'));
    },
    get featured202305JapanFestivals() {
      return addOriginForCDN(detectAssetData('images', 'featured202305JapanFestivals'));
    },
    get featured202312NewListing2023dec() {
      return addOriginForCDN(detectAssetData('images', 'featured202312NewListing2023dec'));
    },
    get featuredFacebook() {
      return addOriginForCDN(detectAssetData('images', 'featuredFacebook'));
    },
    get featuredLine() {
      return addOriginForCDN(detectAssetData('images', 'featuredLine'));
    },
    get featured202401NewListing2024jan() {
      return addOriginForCDN(detectAssetData('images', 'featured202401NewListing2024jan'));
    },
    get featuredLimited202404LimitedPlan() {
      return addOriginForCDN(detectAssetData('images', 'featuredLimited202404LimitedPlan'));
    },
    get featuredTwitter() {
      return addOriginForCDN(detectAssetData('images', 'featuredTwitter'));
    },
    get featuredKani202312Crab() {
      return addOriginForCDN(detectAssetData('images', 'featuredKani202312Crab'));
    },
    get featuredKani202312BannerCrab() {
      return addOriginForCDN(detectAssetData('images', 'featuredKani202312BannerCrab'));
    },
    get featured202309NewListing2023julysep() {
      return addOriginForCDN(detectAssetData('images', 'featured202309NewListing2023julysep'));
    },
    get featured202309SnowOnsen() {
      return addOriginForCDN(detectAssetData('images', 'featured202309SnowOnsen'));
    },
    get featured202306AutumnleavesSpot() {
      return addOriginForCDN(detectAssetData('images', 'featured202306AutumnleavesSpot'));
    },
    get featured202306NewListing() {
      return addOriginForCDN(detectAssetData('images', 'featured202306NewListing'));
    },
  },
  stylesheets: {
    get bootstrapBootstrapGrid() {
      return addOriginForCDN(detectAssetData('stylesheets', 'bootstrapBootstrapGrid'));
    },
    get bootstrapBootstrapReboot() {
      return addOriginForCDN(detectAssetData('stylesheets', 'bootstrapBootstrapReboot'));
    },
    get reactIntlTelInputMain() {
      return addOriginForCDN(detectAssetData('stylesheets', 'reactIntlTelInputMain'));
    },
    get reactIntlTelInputFlags() {
      return addOriginForCDN(detectAssetData('stylesheets', 'reactIntlTelInputFlags'));
    },
  },
};

export type AssetsImagesKeyType = keyof typeof assets.images;

export default assets;
