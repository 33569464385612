import i18n from './string/i18n';
import assets from './assets';

export default class R {
  static get i18n() {
    return i18n;
  }

  static get assets() {
    return assets;
  }
}
