import { computed, observable, ObservableMap } from 'mobx';

type ModelErrors = ObservableMap<string, string>;

export default class ModelErrorIntarface {
  errors: ModelErrors = observable.map({});

  @computed
  get hasError(): boolean {
    return this.errors.size !== 0;
  }

  @computed
  get errorStringFirst(): string | Null {
    if (this.hasError) {
      return this.errors.values().next().value;
    }

    return null;
  }

  @computed
  get errorStringAll(): string | Null {
    if (this.hasError) {
      return Array.from(this.errors.values()).join('\n');
    }

    return null;
  }
}
