import R from '../R';
import { AreaSlug } from './onsen_area_helper';

export type RegionSlug =
  | 'hokkaido'
  | 'tohoku'
  | 'kanto'
  | 'chubu'
  | 'kansai'
  | 'chugoku-shikoku'
  | 'chugoku'
  | 'shikoku'
  | 'kyushu';

export type Region = {
  regionSlug: RegionSlug;
  getName: () => string;
  midName?: () => string;
  areaSlugs: AreaSlug[];
};

const shikokuRegion: Region = {
  regionSlug: 'shikoku',
  getName: R.i18n.webYadoOnsenRegionShikoku,
  areaSlugs: [
    'dogo',
    'shodoshima',
    'kotohira',
    'oboke-iya',
    'ashizuri-shimanto',
    'takamatsu',
    'kagawa',
    'kochi-city',
    'imabari',
    'tokushima',
    'ehime',
    'kochi',
  ],
};
const chugokuRegion: Region = {
  regionSlug: 'chugoku',
  getName: R.i18n.webYadoOnsenRegionChugoku,
  areaSlugs: [
    'misasa',
    'yuda',
    'nagatoyumoto-yuyawan',
    'hiroshima-miyajima',
    'hagi',
    'yamaguchi',
    'yubara',
    'kaike',
    'yunogo',
    'tamatsukuri',
    'tsuyama-hiruzen',
    'hawai',
    'okayama-kurashiki',
    'shimonoseki',
    'tottori',
    'shimane',
    'okayama',
    'hiroshima',
  ],
};

export const onsenRegions: Region[] = [
  {
    regionSlug: 'kanto',
    getName: R.i18n.webYadoOnsenRegionKanto,
    midName: R.i18n.kantoMidName,
    areaSlugs: [
      'hakone-yugawara',
      'yugawara',
      'atami',
      // 伊豆も関東に含める（中部にも残す）
      'izu',
      'nikko-kinugawa',
      'kusatsu',
      'ikaho',
      'oze-oigami',
      'minakami',
      'shima',
      'isobe-tomioka',
      'ibaraki',
      'tochigi',
      'gunma',
      'saitama',
      'chiba',
      'tokyo',
      'kanagawa',
    ],
  },
  {
    regionSlug: 'kansai',
    getName: R.i18n.webYadoOnsenRegionKansai,
    midName: R.i18n.kansaiMidName,
    areaSlugs: [
      'arima',
      'kinosaki',
      'ogoto',
      'nankishirahama',
      'kyoto-city',
      'kyoto-amanohashidate',
      'awajishima',
      'yumura-kasumi',
      'dorogawa',
      'kumano-hongu',
      'katsuura',
      'shiga',
      'kyoto',
      'osaka',
      'hyogo',
      'nara',
      'wakayama',
    ],
  },
  {
    regionSlug: 'hokkaido',
    getName: R.i18n.webYadoOnsenRegionHokkaido,
    areaSlugs: [
      'sapporo-jozankei',
      'noboribetsu',
      'hakodate-yunokawa',
      'toyako',
      'shiretoko',
      'otaru-asarigawa',
      'asahikawa-sounkyo',
      'kitayuzawa',
      'tokachigawa-obihiro',
      'hokkaido',
    ],
  },
  {
    regionSlug: 'tohoku',
    getName: R.i18n.webYadoOnsenRegionTohoku,
    areaSlugs: [
      'zao',
      'hanamaki',
      'tendo',
      'akiu',
      'aizu',
      'towadako-hachimantai',
      'nyuto-tazawako',
      'miyagi-zao',
      'naruko',
      'ginzan',
      'iizaka-tsuchiyu-bandaiatami',
      'akayu',
      'aomori',
      'iwate',
      'miyagi',
      'akita',
      'yamagata',
      'fukushima',
    ],
  },
  {
    regionSlug: 'chubu',
    getName: R.i18n.webYadoOnsenRegionChubu,
    areaSlugs: [
      'izu',
      'kaga',
      'wakura',
      'echigoyuzawa',
      'fuji',
      'hida-takayama',
      'matsumoto',
      'shinshutakayama',
      'yudanaka-shibu',
      'tokurakamiyamada',
      'suwako',
      'tateshina-shirakabako',
      'isawa',
      'madarao-nojiriko',
      'kamikochi-norikura',
      'bessho-kakeyu',
      'nozawa',
      'awara',
      'gero',
      'ise-shima-toba',
      'unazuki',
      'gifu',
      'nagoya',
      'yamanashi',
      'hakuba-omachi-azumino',
      'tsukioka',
      'kanazawa',
      'hamamatsu-hamanako',
      'shizuoka-yaizu',
      'shigakogen',
      'niigata',
      'toyama',
      'ishikawa',
      'fukui',
      'nagano',
      'shizuoka',
      'aichi',
      'mie',
    ],
  },
  {
    regionSlug: 'chugoku-shikoku',
    getName: R.i18n.webYadoOnsenRegionChugokuShikoku,
    areaSlugs: [
      'dogo',
      'misasa',
      'shodoshima',
      'yuda',
      'kotohira',
      'nagatoyumoto-yuyawan',
      'oboke-iya',
      'ashizuri-shimanto',
      'hiroshima-miyajima',
      'hagi',
      'yamaguchi',
      'yubara',
      'kaike',
      'yunogo',
      'tamatsukuri',
      'tsuyama-hiruzen',
      'hawai',
      'takamatsu',
      'kagawa',
      'okayama-kurashiki',
      'kochi-city',
      'shimonoseki',
      'imabari',
      'tottori',
      'shimane',
      'okayama',
      'hiroshima',
      'tokushima',
      'ehime',
      'kochi',
    ],
  },
  chugokuRegion,
  shikokuRegion,
  {
    regionSlug: 'kyushu',
    getName: R.i18n.webYadoOnsenRegionKyushu,
    areaSlugs: [
      'yufuin',
      'beppu',
      'kurokawa',
      'kirishima',
      'ibusuki',
      'hitoyoshi',
      'ureshino',
      'unzen-obama-shimabara',
      'sasebo-iki-hirado',
      'kagoshima-centralarea',
      'fukuoka',
      'saga',
      'nagasaki',
      'kumamoto',
      'oita',
      'miyazaki',
      'kagoshima',
      'okinawa',
    ],
  },
];

export const getRegionByAreaSlug = (area: AreaSlug) => {
  return onsenRegions.find(region => region.areaSlugs.includes(area));
};

const extraShikokuList: AreaSlug[] = [
  'dogo',
  'shodoshima',
  'kotohira',
  'oboke-iya',
  'ashizuri-shimanto',
  'takamatsu',
  'kagawa',
  'kochi-city',
  'imabari',
  'tokushima',
  'ehime',
  'kochi',
];

const extraChugokuList: AreaSlug[] = [
  'misasa',
  'yuda',
  'nagatoyumoto-yuyawan',
  'hiroshima-miyajima',
  'hagi',
  'yamaguchi',
  'yubara',
  'kaike',
  'yunogo',
  'tamatsukuri',
  'tsuyama-hiruzen',
  'hawai',
  'okayama-kurashiki',
  'shimonoseki',
  'tottori',
  'shimane',
  'okayama',
  'hiroshima',
];

export function divideChugokuShikokuBreadcrumbRegion(
  region: Region | null | undefined,
  area: AreaSlug,
) {
  if (!region) {
    return undefined;
  }
  if (region.regionSlug === 'chugoku-shikoku') {
    if (extraShikokuList.indexOf(area) > -1) {
      return shikokuRegion;
    } else if (extraChugokuList.indexOf(area) > -1) {
      return chugokuRegion;
    }
  }
  return region;
}
