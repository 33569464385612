export function isEmptyObject(obj: object) {
  return Object.entries(obj).length === 0 && obj.constructor === Object;
}

export function filterObjectByType<T extends object>(
  obj: T,
  keyStrings: Array<keyof T>,
): Partial<T> {
  const filteredObject: Partial<T> = {};

  Object.keys(obj).forEach(key => {
    if (keyStrings.includes(key as keyof T)) {
      filteredObject[key as keyof T] = obj[key as keyof T];
    }
  });

  return filteredObject;
}

export function filterObjectFromType<T extends object>(
  obj: T,
  keyStrings: Array<keyof T>,
): Partial<T> {
  const filteredObject: Partial<T> = {};

  Object.keys(obj).forEach(key => {
    if (!keyStrings.includes(key as keyof T)) {
      filteredObject[key as keyof T] = obj[key as keyof T];
    }
  });

  return filteredObject;
}

export function sortObjectByKey<T>(unordered: Record<string, T>) {
  const ordered: Record<string, T> = Object.keys(unordered)
    .sort()
    .reduce((obj: Record<string, T>, key) => {
      obj[key] = unordered[key]; // eslint-disable-line no-param-reassign
      return obj;
    }, {});
  return ordered;
}
