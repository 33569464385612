import { parseISO, differenceInDays, isAfter, format } from 'date-fns';

import { getDateFnsLocale } from '../../../config/locales/i18n';
import i18n from '../../../R/string/i18n';
import forceJST from './forceJST';

// Pure JS には Timezone をうまく扱う機能が無いため、WAmazing の仕様を考慮し、
// HK/TW時間を無理矢理ずらすことで 日本時刻として扱う。
// https://date-fns.org/ が Timezone をきちんとサポートしたら対応する
// https://github.com/date-fns/tzdata-fns
export function now(): Date {
  return forceJST();
}

export type FormatType =
  | 'API_PARAM_yyyyMMdd'
  | 'yyyyMMdd'
  | 'HHmm'
  | 'MM'
  | 'yyyyMMdd_HHmm'
  | 'MDD'
  | 'yyyy/MM/dd';
export type FormatDateOptions = {
  type?: FormatType;
  string?: string;
};

export const YYYYMMDD = 'YYYY-MM-DD';

export function formatDate(date: Date, options: FormatDateOptions): string {
  const formatTypes = {
    API_PARAM_yyyyMMdd: 'yyyy-MM-dd',
    MDD: `${i18n.commonDateFormatMdd()}`,
    yyyyMMdd: i18n.commonDateFormatyyyyMMdd(),
    yyyyMMdd_HHmm: `${i18n.commonDateFormatyyyyMMdd()} ${i18n.commonDateFormatHHmm()}`,
    MM: i18n.commonDateFormatMM(),
    HHmm: i18n.commonDateFormatHHmm(),
    'yyyy/MM/dd': 'yyyy/MM/dd',
  };
  return format(
    date,
    options.type ? formatTypes[options.type] : options.string ?? formatTypes['yyyy/MM/dd'],
    {
      locale: getDateFnsLocale(),
    },
  );
}

export function formatDateParam(date: Date): string {
  return formatDate(date, {
    type: 'API_PARAM_yyyyMMdd',
  });
}

export function beginningOfDay(date: Date): Date {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}

export function isEarlierThanToday(date: Date): boolean {
  const dateWithoutTime = beginningOfDay(date);
  const beginningOfToday = beginningOfDay(now());
  return isAfter(beginningOfToday, dateWithoutTime);
}

export function diffInDays(left: string | Date, right: string | Date = now()): number {
  const leftDate = typeof left === 'string' ? parseISO(left) : left;
  const rightDate = typeof right === 'string' ? parseISO(right) : right;
  return differenceInDays(leftDate, rightDate);
}
